import { Banner, Typography } from '@partstech/ui';
import type { BannerProps } from '@partstech/ui';

export const SplitOrderMessage = ({ ...bannerProps }: BannerProps) => (
  <Banner fullWidth variant="warning" borderWidth={0} px={2} py={1} textAlign="left" {...bannerProps}>
    <Typography color="secondaryDark10" variant="bodyText2">
      Will be sourced from multiple locations which may impact delivery times.
    </Typography>
  </Banner>
);
