import { get } from '@partstech/ui/utils';
import { useCallback, useMemo } from 'react';
import { useAppNavigate } from 'app/AppRouter/useAppNavigate';
import {
  getDynamicAttributesWithRangesForSection,
  getUpdatedCheckedValues,
  isDynamicAttributeFilterName,
  isRangedAttributeValue,
} from 'utils';
import { useDynamicAttributes } from './useDynamicAttributes';
import { useGetSearchUrl } from './useGetSearchUrl';
import { useSearchParams } from './useSearchParams';
import type { DynamicAttributeName, Filter, FilterOptionType } from 'types/search';

type ChangeEvent = {
  name: Filter['name'];
  value: string;
  checked: boolean;
  cascade?: boolean;
  type?: FilterOptionType;
};

export const useChangeFilter = (filters: Filter[]) => {
  const navigate = useAppNavigate();
  const { searchParams } = useSearchParams();
  const { getSearchUrl } = useGetSearchUrl();
  const { attributes } = useDynamicAttributes();

  const attributesByRange = useMemo(() => getDynamicAttributesWithRangesForSection(attributes, 'search'), [attributes]);

  const checkedFilters = useMemo(
    () =>
      filters
        .filter((filter) => !filter.isHidden)
        .filter((filter) => filter.options.some((option) => option.checked))
        .map((filter) => ({
          ...filter,
          options: filter.options
            .filter((option) => option.checked)
            .map((option, i, options) => ({ ...option, disabled: filter.cascade && options.length - 1 > i })),
        })),
    [filters]
  );

  const changeFilter = useCallback(
    ({ name, checked, value, cascade, type }: ChangeEvent) => {
      const checkedValues = get(searchParams, name) ?? [];
      const changedFilter = filters.find((filter) => filter.name === name);

      const selectedOption = changedFilter?.options.find((option) => option.value === value);
      const selectedOptionValues = selectedOption ? [selectedOption.value] : [value];

      const isDynamicAttribute = isDynamicAttributeFilterName(name);
      const dynamicAttributeName: DynamicAttributeName = isDynamicAttribute ? (name.split('.').pop() ?? '') : '';
      const rangedAttributeValue = attributesByRange[dynamicAttributeName] ?? [];

      const getChangedValues = () => {
        if (type === 'radio') {
          const optionIndex = changedFilter?.options.findIndex((option) => option === selectedOption) ?? -1;

          if (optionIndex === 0) {
            return selectedOption?.value ? [selectedOption.value] : [];
          }

          return changedFilter?.options.slice(1, optionIndex + 1).map((option) => option.value) ?? [];
        }

        if (cascade) {
          const optionIndex = changedFilter?.options.findIndex((option) => option === selectedOption) ?? -1;
          const prevOptions = changedFilter?.options.slice(0, optionIndex).map((option) => option.value) ?? [];

          return [...prevOptions, value];
        }

        return isDynamicAttribute && isRangedAttributeValue(rangedAttributeValue)
          ? rangedAttributeValue.filter((options) => options.includes(selectedOption?.value ?? '')).flat()
          : selectedOptionValues;
      };

      const changedValues = getChangedValues();

      const updatedCheckedValues = getUpdatedCheckedValues({
        checked,
        type,
        checkedValues,
        changedValues,
        changedFilter,
      });

      if (isDynamicAttribute) {
        searchParams.attributes = { ...searchParams.attributes, [dynamicAttributeName]: updatedCheckedValues };
      } else {
        searchParams[name] = updatedCheckedValues;
      }

      navigate(
        getSearchUrl({ ...searchParams, fit: isDynamicAttribute ? undefined : searchParams.fit, page: undefined })
      );
    },
    [searchParams, filters, attributesByRange, navigate, getSearchUrl]
  );

  const clearAll = useCallback(() => {
    checkedFilters.forEach((filter) => {
      filter.options.forEach((option) => {
        changeFilter({ name: filter.name, value: option.value, checked: false });
      });
    });
  }, [changeFilter, checkedFilters]);

  return { changeFilter, clearAll, checkedFilters };
};
