import { Checkbox } from '@partstech/ui';
import { useEmailNotificationsToggle } from './useEmailNotificationsToggle';

export const EmailNotificationsCheckbox = () => {
  const { isToggle, toggle } = useEmailNotificationsToggle();

  return (
    <Checkbox data-testid="checkbox" checked={isToggle} onChange={toggle}>
      Receive order emails for this location
    </Checkbox>
  );
};
