import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { createProductFromData } from 'factories';
import { shopApi } from 'shared/api';
import { getMissingStores, selectStoreById } from 'store/entities/store';
import { getMissingSuppliers, selectSupplierById } from 'store/entities/supplier';
import { selectOutTheDoorPricesByProductId } from 'store/features/search/outTheDoorPrices';
import { logout } from 'store/features/user/account';
import { selectRootState } from 'store/utils';
import { selectId } from 'utils';
import type { EntityId } from '@reduxjs/toolkit';
import type { RootState } from 'store';
import type { Product, ProductEntity, ProductParams, ProductQueryParams } from 'types/product';

export type Rebate = {
  couponLink: string | null;
  description: string;
  legalLink: string | null;
  price: number;
  text: string;
  title: string;
  validDate: string | null;
};

export const productApi = shopApi.injectEndpoints({
  endpoints: (build) => ({
    GetProduct: build.query<Product, ProductQueryParams>({
      query: ({ partnumberid, ...params }) => ({
        url: `/details/${partnumberid}`,
        params: { ...params, vehicle: params.vehicle ? Number(params.vehicle) : undefined },
      }),
      providesTags: () => [{ type: 'Product' }],
      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        try {
          const { data } = await queryFulfilled;

          const { supplierId, storeId } = data;

          if (storeId) {
            dispatch(getMissingStores([storeId]));
          }

          if (supplierId) {
            dispatch(getMissingSuppliers([String(supplierId)]));
          }
        } catch {
          /* empty */
        }
      },
    }),
  }),
});

export const { useLazyGetProductQuery } = productApi;

const productAdapter = createEntityAdapter<ProductEntity, EntityId>({ selectId: (entity) => selectId(entity) });

type State = {
  params: Record<string, ProductParams>;
};

const initialState = productAdapter.getInitialState<State>({ params: {} });

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(logout.fulfilled, productAdapter.removeAll)
      .addMatcher(productApi.endpoints.GetProduct.matchFulfilled, productAdapter.upsertOne);
  },
});

export const product = productSlice.reducer;

const selectState = (state: RootState) => state.entities.product;

const { selectById } = productAdapter.getSelectors(selectState);

const selectProductById = createSelector(
  [selectRootState, selectById, selectOutTheDoorPricesByProductId],
  (state, entity, outTheDoorPrices) => {
    if (!entity) {
      return null;
    }

    return {
      ...entity,
      store: selectStoreById(state, entity.storeId ?? null) ?? null,
      supplier: selectSupplierById(state, entity.supplierId ? String(entity.supplierId) : null) ?? null,
      outTheDoorPrices,
    };
  }
);

export const selectProductModelById = createSelector(
  [
    selectProductById,
    (_: unknown, _productId: string, params: { showRetailPrice?: boolean; filterEnhancements?: boolean }) => params,
  ],
  (entity, { showRetailPrice, filterEnhancements }) =>
    entity
      ? createProductFromData({
          productData: entity,
          supplier: entity.supplier,
          store: entity.store,
          showRetailPrice,
          filterEnhancements,
        })
      : null
);

export const selectProductModelsByIds = createSelector(
  [
    selectRootState,
    (_: unknown, ids: string[], showRetailPrice?: boolean, filterEnhancements?: boolean) => ({
      ids,
      showRetailPrice,
      filterEnhancements,
    }),
  ],
  (state, { ids, showRetailPrice, filterEnhancements }) =>
    ids.map((id) => selectProductModelById(state, id, { showRetailPrice, filterEnhancements }))
);
