/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type DeleteCartItemsMutationVariables = Types.Exact<{
  input: Types.DeleteCartItemsInput;
}>;

export type DeleteCartItemsMutation = {
  __typename?: 'Mutation';
  deleteCartItems?: {
    __typename?: 'DeleteCartItemsPayload';
    cart?: { __typename?: 'Cart'; id: string; active: Types.Scalars['Boolean']['input'] } | null;
  } | null;
};

export const DeleteCartItemsDocument = `
    mutation DeleteCartItems($input: DeleteCartItemsInput!) {
  deleteCartItems(input: $input) {
    cart {
      id
      active
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    DeleteCartItems: build.mutation<DeleteCartItemsMutation, DeleteCartItemsMutationVariables>({
      query: (variables) => ({ document: DeleteCartItemsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useDeleteCartItemsMutation } = injectedRtkApi;
