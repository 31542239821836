import { useMemo } from 'react';
import { DefaultFilter } from '../DefaultFilter';
import type { FilterProps } from '../DefaultFilter/types';

export const AvailabilityFilter = ({ options, ...restProps }: FilterProps) => {
  const availabilityOptions = useMemo(() => options.filter((option) => option.type === 'radio'), [options]);
  const stockOptions = useMemo(() => options.filter((option) => option.type === 'checkbox'), [options]);

  if (options.length <= 1) {
    return null;
  }

  return (
    <div>
      {restProps.mixedSelection ? (
        <>
          <DefaultFilter {...restProps} options={availabilityOptions} />

          {stockOptions.length > 0 && <DefaultFilter {...restProps} label="Stock" options={stockOptions} />}
        </>
      ) : (
        <DefaultFilter {...restProps} options={options} />
      )}
    </div>
  );
};
