import { SearchTypes } from 'types/search';
import { RecentSearchAbstract } from './RecentSearchAbstract';
import type { AbstractParams } from './RecentSearchAbstract';
import type { RecentSearchInterface } from './RecentSearchInterface';
import type { SearchEntryPointInterface } from 'features/searchForm';
import type { TireSize, Vehicle } from 'models';
import type { TiresSearchFormValues } from 'types/search';

type SearchedTire = {
  id?: string | null;
  name?: string | null;
  size: TireSize;
  rearSize: TireSize | null;
};

export class Tires extends RecentSearchAbstract implements RecentSearchInterface, SearchEntryPointInterface {
  type: string = 'Tire Size';

  tire: SearchedTire | null;

  constructor(id: string, vehicle: Vehicle | null, params: AbstractParams & { tire: SearchedTire | null }) {
    super(id, vehicle, params);

    this.tire = params.tire;
  }

  toString(): string {
    if (this.tire?.id) {
      return this.tire.name ?? '';
    }

    if (!this.tire?.rearSize) {
      return `${this.tire?.size}`;
    }

    return `FRONT: ${this.tire.size} & REAR: ${this.tire.rearSize}`;
  }

  private getTireSizeValue() {
    return this.tire?.rearSize === null
      ? this.tire.size.toValue()
      : [this.tire?.size.toValue(), this.tire?.rearSize.toValue()].join(',');
  }

  populateInput(): string {
    return this.toString();
  }

  toSearchValues(): TiresSearchFormValues {
    return {
      type: SearchTypes.TIRES,
      vehicleId: this.vehicle?.id ?? null,
      tireId: this.tire?.id ?? null,
      tiresSize: this.getTireSizeValue(),
      partNumber: null,
      plate: this.plate,
      state: this.state,
      vin: this.vin,
    };
  }
}
