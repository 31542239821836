export type LaborPartType = {
  id: string;
  name: string;
};

type LaborApplicationData = {
  applicationId: string;
  duration: number;
  defaultDuration?: number;
  name: string;
  notes: string[];
  skill: string;
  serviceName?: string;
  description?: string;
  orderItemId?: string | null;
  parentId?: string | null;
  customId?: string;
  vehicleId?: string;
  vin?: string | null;
  partTypes?: LaborPartType[];
};

export class LaborApplication {
  applicationId: string;

  duration: number;

  defaultDuration: number;

  name: string;

  notes: string[];

  skill: string;

  orderItemId: string | null;

  parentId: string | null;

  serviceName: string;

  description: string | null;

  customId: string | null;

  vehicleId: string | null;

  vin: string | null;

  partTypes: LaborPartType[];

  constructor(data: LaborApplicationData) {
    this.applicationId = data.applicationId;
    this.duration = data.duration;
    this.defaultDuration = data.defaultDuration || data.duration;
    this.orderItemId = data.orderItemId || null;
    this.name = data.name;
    this.notes = data.notes;
    this.skill = data.skill;
    this.parentId = data.parentId || null;
    this.serviceName = data.serviceName || '';
    this.description = data.description || null;
    this.customId = data.customId || null;
    this.vehicleId = data.vehicleId || null;
    this.vin = data.vin || null;
    this.partTypes = data.partTypes || [];
  }

  get id() {
    return this.orderItemId ? `${this.orderItemId}-${this.applicationId}` : this.applicationId;
  }

  get title() {
    return this.serviceName ? `${this.serviceName}, ${this.name}` : this.name;
  }
}
