import { useCallback } from 'react';
import { useMutationStatus } from 'shared/api';
import { useUpdateShopMutation } from './UpdateShop.generated';
import type { UpdateShopInput } from 'shared/api';

export const useUpdateShop = () => {
  const [updateShop, status] = useUpdateShopMutation();

  const handleUpdateShop = useCallback((input: UpdateShopInput) => updateShop({ input }), [updateShop]);

  useMutationStatus({ status });

  return [handleUpdateShop, status] as const;
};
