import { Box, Button, ButtonGroup, TextField } from '@partstech/ui';
import { HookForm, FormCheckBoxField } from '@partstech/ui/forms';
import { CARD_NUMBER_FIELD_NAME } from 'integrations/stripe';
import { required } from 'shared/lib/form';
import { FormExpireCardField } from './FormExpireCardField';
import { useEditCreditCardForm } from './useEditCreditCardForm';
import type { UseEditCreditCardPayload } from './useEditCreditCardForm';
import type { PaymentCard } from 'entities/paymentCard';

type Props = UseEditCreditCardPayload & {
  paymentMethod?: PaymentCard | null;
  showCardOptions: boolean;
  onCancel: () => void;
};

export const EditCreditCardForm = ({ paymentMethod, showCardOptions, onCancel, ...props }: Props) => {
  const { card, form, submitForm, isLoading, isDisabled, isChangePaymentDisabled } = useEditCreditCardForm(props);

  return (
    <HookForm form={form} onSubmit={submitForm}>
      <TextField name="holder" label="Name on card" value={card.owner ?? ''} disabled fsExclude />

      <Box mt={4} mb={4}>
        <TextField
          name={CARD_NUMBER_FIELD_NAME}
          label="Card number"
          value={`**** **** **** ${card.name}`}
          disabled
          fsExclude
        />
      </Box>

      <Box display="flex" justifyContent="space-between">
        <Box width="calc(50% - 4px)">
          <FormExpireCardField
            name="expire"
            label="Expiration"
            required
            rules={{ required }}
            fsExclude
            hideRequiredMark
          />
        </Box>

        <Box width="calc(50% - 4px)">
          <TextField name="cvc" label="CVV" value="***" disabled fsExclude />
        </Box>
      </Box>

      {showCardOptions && (
        <Box mt={6}>
          <FormCheckBoxField name="isMovingSubscriptions" disabled={isChangePaymentDisabled} data-testid="checkbox">
            Use card for subscription
          </FormCheckBoxField>
        </Box>
      )}

      <Box mt={6}>
        <ButtonGroup flexDirection="column">
          <Button
            fullWidth
            type="submit"
            isLoading={isLoading}
            disabled={isDisabled}
            data-testid="submitEditCardButton"
          >
            Update card
          </Button>

          <Button fullWidth variant="text" onClick={onCancel} data-testid="cancel">
            Cancel
          </Button>
        </ButtonGroup>
      </Box>
    </HookForm>
  );
};
