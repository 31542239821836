import { makeContext } from '@partstech/ui/utils';
import type { MutableRefObject } from 'react';
import type { UIOffset } from 'types/uiOffset';

type LayoutContext = {
  /**
   * Reference to the header. Required for initialization
   */
  headerRef: MutableRefObject<HTMLDivElement | null>;
  /**
   * Height of the header
   */
  headerHeight: number;
  /**
   * The full bar is visible (with icons and title or only icons)
   */
  isOpenProfileBar: boolean;
  /**
   * Opening the full
   */
  openProfileBar: () => void;
  /**
   * Closing the full
   */
  closeProfileBar: () => void;
  /**
   * Toggling between opening and closing
   */
  toggleProfileBar: () => void;
  /**
   * Setting the offset dimensions
   * Only the left offset is used to account for content padding
   */
  setUIOffsetProfileBar: (offset: UIOffset) => void;
  /**
   * Offset values set using setUIOffset
   * @default { top: 0, left: 57, bottom: 0, right: 0, }
   */
  uiOffsetProfileBar: UIOffset;
};

export const [useLayoutContext, LayoutContextProvider] = makeContext<LayoutContext>();
