/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type ArchiveCartMutationVariables = Types.Exact<{
  input: Types.ArchiveCartInput;
}>;

export type ArchiveCartMutation = {
  __typename?: 'Mutation';
  archiveCart?: {
    __typename?: 'ArchiveCartPayload';
    cart?: {
      __typename?: 'Cart';
      id: string;
      improvedStatus: Types.CartImprovedStatus;
      updatedAt: Types.Scalars['DateTime']['input'];
    } | null;
  } | null;
};

export const ArchiveCartDocument = `
    mutation ArchiveCart($input: ArchiveCartInput!) {
  archiveCart(input: $input) {
    cart {
      id
      improvedStatus
      updatedAt
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    ArchiveCart: build.mutation<ArchiveCartMutation, ArchiveCartMutationVariables>({
      query: (variables) => ({ document: ArchiveCartDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useArchiveCartMutation } = injectedRtkApi;
