import { css } from '@emotion/react';
import type { Theme } from '@partstech/ui';

export const styles = {
  asset: css`
    display: flex;
    align-items: center;
    margin: 0 0 10px 0;
    text-decoration: none;

    &:last-of-type {
      margin: 0;
    }
  `,
  name: (theme: Theme) => css`
    font-size: 14px;
    text-decoration: underline;
    color: ${theme.color.primary};
    word-break: break-all;
    word-wrap: break-word;

    @media (hover), (-ms-high-contrast: none) {
      &:hover {
        color: ${theme.color.primaryLight10};
        text-decoration: none;
      }
    }
  `,
  extension: (theme: Theme) => css`
    position: relative;
    margin-right: 13px;
    color: ${theme.color.secondary};
  `,
  extensionIcon: (theme: Theme) => css`
    width: 100%;
    height: 100%;
    line-height: 28px;
    font-size: 10px;
    text-align: center;
    position: absolute;
    left: 0;
    color: ${theme.color.white};
    text-transform: uppercase;
  `,
};
