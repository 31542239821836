import { useCallback, useEffect } from 'react';
import { SearchTabs } from 'features/searchForm';
import { Diagram, UniversalPartType } from 'models';
import { SearchTypes } from 'types/search';
import { useSearchType } from './useSearchType';
import { useSelectedSearchEntry } from './useSelectedSearchEntry';
import { useSelectedTab } from './useSelectedTab';
import { useSelectedVehicle } from './useSelectedVehicle';
import type { SearchEntryPointInterface } from 'features/searchForm';
import type { Vehicle } from 'models';

type Props = {
  searchEntry: SearchEntryPointInterface | null;
  vehicle: Vehicle | null;
  type: SearchTypes | null;
  onChange: (entry: SearchEntryPointInterface | null, vehicle: Vehicle | null) => void;
};

export const useSearchBarState = ({
  searchEntry: defaultSearchEntry,
  vehicle: defaultVehicle,
  type: defaultSearchType,
  onChange,
}: Props) => {
  const [searchEntry, setSearchEntry] = useSelectedSearchEntry(defaultSearchEntry);
  const [vehicle, setVehicle] = useSelectedVehicle(defaultVehicle);

  const [searchType, setSearchType] = useSearchType(defaultSearchType);
  const [entryPointsTab, changeEntryPointsTab] = useSelectedTab({
    searchType,
    onTabChange: (tab: SearchTabs) => {
      tab === SearchTabs.TIRES ? setSearchType(SearchTypes.TIRES) : setSearchType(SearchTypes.PARTS);
    },
  });

  const changeSearchEntry = useCallback(
    (entry: SearchEntryPointInterface | null) => {
      setSearchEntry(entry);

      if (entry !== null) {
        onChange(entry, vehicle);
      }
    },
    [onChange, setSearchEntry, vehicle]
  );

  const changeVehicle = useCallback(
    (selectedVehicle: Vehicle | null, silent: boolean = false) => {
      setVehicle(selectedVehicle);

      if (searchEntry instanceof Diagram) {
        setSearchEntry(null);
      }

      if (!silent) {
        onChange(
          searchEntry instanceof UniversalPartType ? searchEntry.resetSelectedAttributes() : searchEntry,
          selectedVehicle
        );
      }
    },
    [setVehicle, searchEntry, setSearchEntry, onChange]
  );

  const resetSearch = useCallback(() => {
    changeVehicle(null);
    changeSearchEntry(null);
  }, [changeSearchEntry, changeVehicle]);

  useEffect(() => {
    if (defaultSearchEntry && !defaultVehicle) {
      setVehicle(null);
    }
  }, [defaultSearchEntry, defaultVehicle, setVehicle]);

  return {
    changeEntryPointsTab,
    changeSearchEntry,
    changeSearchType: setSearchType,
    changeVehicle,
    resetSearch,

    entryPointsTab,
    searchEntry,
    searchType,
    vehicle,
  };
};
