import { useMemo } from 'react';
import { createPaymentCardFromData } from 'entities/paymentCard';
import { api } from './GetSubscriptionPayments.generated';
import type { GetSubscriptionPaymentsQuery } from './GetSubscriptionPayments.generated';
import type { PaymentCard } from 'entities/paymentCard';
import type { SubscriptionCustomerInvoice as SubscriptionCustomerInvoiceType } from 'shared/api';

export type SubscriptionCustomerInvoice = Omit<SubscriptionCustomerInvoiceType, 'card'> & { card: PaymentCard | null };

const emptyPayments: SubscriptionCustomerInvoice[] = [];

const enhancedApi = api.enhanceEndpoints({
  addTagTypes: ['SubscriptionPayment'],
  endpoints: {
    GetSubscriptionPayments: {
      providesTags: (response: GetSubscriptionPaymentsQuery | undefined) => {
        const shop = response?.currentUser?.activeMember?.shop;

        if (!shop) {
          return [];
        }

        const payments =
          shop?.subscriptionPayments?.map((payment) => ({
            type: 'SubscriptionPayment' as const,
            id: payment.id,
          })) ?? [];

        return ['SubscriptionPayment', ...payments];
      },
    },
  },
});

const { useGetSubscriptionPaymentsQuery } = enhancedApi;

export const useGetSubscriptionPayments = () => {
  const { data, ...props } = useGetSubscriptionPaymentsQuery();

  const payments = useMemo(
    () =>
      data?.currentUser?.activeMember?.shop?.subscriptionPayments?.map((payment) => {
        const card = payment.card ? createPaymentCardFromData(payment.card) : null;
        return { ...payment, card };
      }) ?? emptyPayments,
    [data]
  );

  return {
    payments,
    ...props,
  };
};
