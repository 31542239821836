import { Tag, Tooltip } from '@partstech/ui';
import { CartImprovedStatus } from 'shared/api';
import type { IconProps } from '@partstech/ui';

const colorMap: Record<CartImprovedStatus, IconProps['color']> = {
  [CartImprovedStatus.Approved]: 'positive',
  [CartImprovedStatus.Created]: 'primaryLight',
  [CartImprovedStatus.Ordered]: 'subtleText',
  [CartImprovedStatus.Shared]: 'secondaryDark10',
  [CartImprovedStatus.Submitted]: 'secondaryDark10',
  [CartImprovedStatus.Archived]: 'mono40',
};

const tooltipMap: Record<CartImprovedStatus, string> = {
  [CartImprovedStatus.Created]: 'Your quote has been auto-saved.',
  [CartImprovedStatus.Submitted]: 'This quote was submitted to your SMS.',
  [CartImprovedStatus.Shared]: 'You shared this quote with your customer.',
  [CartImprovedStatus.Approved]: 'Your customer has approved a tire.',
  [CartImprovedStatus.Archived]: 'This quote was archived. We will store it for you indefinitely.',
  [CartImprovedStatus.Ordered]:
    'Items have been purchased. We will automatically archive this quote 30 days after the order date.',
};

type Props = {
  status: CartImprovedStatus;
  isActive: boolean;
};

export const CartStatusLabel = ({ status, isActive }: Props) => (
  <Tooltip tooltip={isActive ? 'This quote is currently in the cart.' : tooltipMap[status]}>
    <Tag
      leadingIcon={status === CartImprovedStatus.Ordered ? 'check_circle' : 'circle'}
      iconColor={isActive ? 'neutral' : colorMap[status]}
    >
      {isActive ? 'ACTIVE' : status}
    </Tag>
  </Tooltip>
);
