import { api as rootApi } from 'store/queries/currentUser/useGetCurrentUser';
import { api as generatedApi } from './CreateShopManagementSystem.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    CreateShopManagementSystem: {
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        const managementSystem = data.createShopManagementSystemRelation?.shop?.managementSystem;

        if (managementSystem) {
          dispatch(
            rootApi.util.updateQueryData('GetCurrentUser', undefined, (draft) => {
              if (draft.currentUser?.activeMember?.shop) {
                Object.assign(draft.currentUser?.activeMember.shop, { managementSystem });
              }
            })
          );
        }
      },
    },
  },
});

export const { useCreateShopManagementSystemMutation } = api;
