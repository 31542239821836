import { Box, Button, Card, Typography } from '@partstech/ui';
import type { ButtonProps, CardProps } from '@partstech/ui';

type Props = {
  title: string;
  buttonText: string;
  onClick: () => void;
  buttonVariant?: ButtonProps['variant'];
  helperText?: string;
  isLoading?: boolean;
} & CardProps;

export const CardWrapper = ({
  title,
  children,
  buttonText,
  onClick,
  buttonVariant,
  helperText,
  isLoading,
  ...props
}: Props) => (
  <Card flex="1" p={4} minHeight={52} display="flex" flexDirection="column" justifyContent="space-between" {...props}>
    <Box textAlign="center">
      <Typography variant="subtitle1">{title}</Typography>

      <Box mt={2} mb={3}>
        <Typography variant="bodyText2">{children}</Typography>
      </Box>
    </Box>

    <Box textAlign="center">
      <Button
        variant={buttonVariant}
        onClick={onClick}
        isLoading={isLoading}
        disabled={isLoading}
        data-testid="actionButton"
      >
        {buttonText}
      </Button>

      <Box mt={1} height={4}>
        <Typography variant="caption" color="subtleText">
          {helperText}
        </Typography>
      </Box>
    </Box>
  </Card>
);
