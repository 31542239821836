import { createPartTypeFromQuery } from './createPartTypeFromQuery';
import type { PartType } from 'models';
import type { FullPartTypeFragment } from 'store/queries/partTypes/FullPartType.generated';

const emptyPartTypes: PartType[] = [];

export const createPartTypesFromQuery = (partTypes: FullPartTypeFragment[] | null = [], alias?: string): PartType[] =>
  partTypes?.map((partType) => {
    const isAlias = alias !== partType?.name;

    return createPartTypeFromQuery(partType, isAlias ? alias : undefined);
  }) ?? emptyPartTypes;
