import { Box, Icon, Tooltip, Typography } from '@partstech/ui';
import { currency } from 'shared/lib/string';
import { ScrollableContainer } from 'shared/ui';
import type { LaborLine } from '../../../utils';

type Props = {
  laborLines: LaborLine[];
  totalHours: number;
  totalPrice: string;
  rate: number;
};

export const LaborDetails = ({ laborLines, totalHours, totalPrice, rate }: Props) => (
  <Box width={86}>
    <ScrollableContainer maxHeight={40}>
      {laborLines.map((line) => (
        <Box key={line.id} display="flex" justifyContent="space-between" color="defaultText" gap={4} px={4} my={4}>
          <Typography variant="caption" color="inherit">
            {line.name}
          </Typography>

          <Typography variant="caption" component="p" color="inherit" noWrap>
            {line.duration} hrs x {currency(rate || 0)}
          </Typography>
        </Box>
      ))}
    </ScrollableContainer>

    <Box display="flex" justifyContent="space-between" p={4}>
      <Typography variant="subtitle1">Total ({totalHours} hrs):</Typography>

      <Box display="flex" alignItems="center" gap={1}>
        {rate === 0 && (
          <Tooltip
            tooltip="To see the price breakdown, make sure to add a labor rate in the labor page."
            offsetX={-250}
            offsetY={20}
          >
            <Icon name="info" size="medium" color="subtleText" />
          </Tooltip>
        )}
        <Typography variant="subtitle1">{totalPrice}</Typography>
      </Box>
    </Box>
  </Box>
);
