import { Box, Typography } from '@partstech/ui';
import { useCallback } from 'react';
import { useCreateShopContext } from 'components/Layout/SignUpLayout/CreateShopContext/CreateShopContext';
import { StepTitle } from 'features/signUp';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import { SelectManagementSystem } from './SelectManagementSystem';

export const ManagementSystem = () => {
  const { managementSystem, refetch } = useGetCurrentUser();
  const { navigateToNextStep } = useCreateShopContext();

  const handleSubmit = useCallback(async () => {
    await refetch();

    navigateToNextStep();
  }, [navigateToNextStep, refetch]);

  return (
    <Box px={{ sm: 4, md: 6 }}>
      <Box mb={{ sm: 4, md: 6 }} maxWidth={131} width="100%" textAlign={{ sm: 'center', md: 'left' }}>
        <StepTitle mb={2}>Select your Management System</StepTitle>

        <Typography variant="bodyText2" color="subtleText">
          What software do you use to manage your shop’s day-to-day operations? These tools may be referred to as Shop
          Management, Point-of-Sale, or Dealer Management Systems.
        </Typography>
      </Box>

      <SelectManagementSystem selectedManagementSystemId={managementSystem?.id ?? undefined} onSubmit={handleSubmit} />
    </Box>
  );
};
