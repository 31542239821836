/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import { VehicleFragmentDoc } from '../../../../store/queries/vehicle/VehicleFragment.generated';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type GetVehiclesByYearMakeQueryVariables = Types.Exact<{
  year?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  makeId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;

export type GetVehiclesByYearMakeQuery = {
  __typename?: 'Query';
  vehicles?: Array<{
    __typename?: 'Vehicle';
    id: string;
    type: Types.VehicleType;
    regions?: Array<Types.VehicleRegion> | null;
    year: number;
    make: { __typename?: 'VehicleMake'; id: string; name: Types.Scalars['String']['input']; priority: number };
    model: { __typename?: 'VehicleModel'; id: string; name: Types.Scalars['String']['input'] };
    subModel: { __typename?: 'VehicleSubModel'; id: string; name: Types.Scalars['String']['input'] };
    engine: { __typename?: 'VehicleEngine'; id: string; name: Types.Scalars['String']['input'] };
  }> | null;
};

export const GetVehiclesByYearMakeDocument = `
    query GetVehiclesByYearMake($year: Int, $makeId: ID) {
  vehicles(year: $year, makeID: $makeId) {
    ...Vehicle
  }
}
    ${VehicleFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetVehiclesByYearMake: build.query<GetVehiclesByYearMakeQuery, GetVehiclesByYearMakeQueryVariables | void>({
      query: (variables) => ({ document: GetVehiclesByYearMakeDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetVehiclesByYearMakeQuery, useLazyGetVehiclesByYearMakeQuery } = injectedRtkApi;
