import { paymentApi } from '../paymentCards';
import { api as generatedApi } from './ChangePaymentCard.generated';
import type { GetPaymentCardsQuery } from '../paymentCards/GetPaymentCards.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    ChangePaymentCard: {
      onQueryStarted: async ({ input }, { dispatch, queryFulfilled, getState }) => {
        try {
          await queryFulfilled;

          const cardsQuery = getState().api.queries['GetPaymentCards(undefined)']?.data as GetPaymentCardsQuery;
          const cards = cardsQuery.currentUser?.activeMember?.shop?.cards ?? [];
          const selectedCard = cards.find((card) => card.id === input.cardId);

          if (selectedCard) {
            dispatch(
              paymentApi.util.updateQueryData('GetPaymentCards', undefined, ({ currentUser }) => {
                const shopSubscription = currentUser?.activeMember?.shop?.shopSubscription;

                if (shopSubscription) {
                  Object.assign(shopSubscription, { card: selectedCard });
                }
              })
            );
          }
        } catch {
          /* empty */
        }
      },
    },
  },
});

export const { useChangePaymentCardMutation } = api;
