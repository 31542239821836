import { Box, ImageFallback, Typography } from '@partstech/ui';
import upgradeLaborImage from 'images/content/subscriptions/upgrade-labor.gif';

export const Labor = () => (
  <>
    <Box textAlign="center" mb={6}>
      <ImageFallback width={620} src={upgradeLaborImage} alt="Learn about labor" />
    </Box>

    <Typography data-testid="upgradeText">
      Labor can be unlocked with a PartsTech Complete subscription. Add parts to your cart and PartsTech will map you to
      the labor times associated to those parts. You can also store multiple labor rates and use them to create a quick
      quote.
    </Typography>
  </>
);
