import { usePrevious, useToggle } from '@partstech/ui/hooks';
import { useCallback, useEffect, useRef } from 'react';
import { Country } from 'shared/api';
import { useInputValue } from 'shared/lib/form';
import { useGetDetailsByPlace } from '../../lib/useGetDetailsByPlace';
import { useGetPlaces } from '../../lib/useGetPlacesWithGoogle';
import type { DetailsByPlate, Place } from '../../lib/types';

type Args = {
  onSelect: (values: DetailsByPlate) => void;
  onChangeInputValue?: (value: string) => void;
};

export const useGooglePlacesSearch = ({ onSelect, onChangeInputValue }: Args) => {
  const { value, debouncedValue, changeValue } = useInputValue('', {
    onChange: onChangeInputValue,
    debounceDelay: 200,
  });

  const previusDebouncedValue = usePrevious(debouncedValue);

  const { isToggle: isOpenMenu, setToggle: setIsOpen } = useToggle();

  const searchInputRef = useRef<HTMLInputElement>(null);

  const { options, isLoading } = useGetPlaces(
    { value: debouncedValue, element: searchInputRef.current },
    { skip: debouncedValue.length === 0 }
  );
  const [getDetailsByPlace] = useGetDetailsByPlace();

  const selectOption = useCallback(
    async (option: Place) => {
      const response = await getDetailsByPlace(option, searchInputRef.current);

      onSelect(response);
    },
    [getDetailsByPlace, onSelect]
  );

  const selectNotFound = useCallback(() => {
    onSelect({
      address: {
        address1: '',
        city: '',
        country: Country.Us,
        state: '',
        zipCode: '',
      },
      name: value,
      phone: '',
    });
  }, [onSelect, value]);

  useEffect(() => {
    setIsOpen(Boolean(value.length));
  }, [value, setIsOpen]);

  return {
    value,
    searchInputRef,
    isOpenMenu,
    isLoading: previusDebouncedValue !== value || isLoading,
    options,
    changeValue,
    selectOption,
    selectNotFound,
  };
};
