import { Card, ModalView, Typography } from '@partstech/ui';
import type { Bundle } from 'types/product';

type Props = {
  bundles: Bundle[];
  onClose: () => void;
};

export const BundlesModal = ({ bundles, onClose }: Props) => (
  <ModalView title="Bundle Deals" onClose={onClose}>
    {bundles.map((bundle) => (
      <Card
        key={bundle.id}
        mb={4}
        p={4}
        borderWidth={0}
        background="mono2"
        display="flex"
        flexDirection="column"
        data-testid="bundleItem"
        gap={2}
      >
        <Typography variant="subtitle2" color="black">
          {bundle.name}
        </Typography>

        {bundle.description && (
          <Typography variant="bodyText2" color="black">
            {bundle.description}
          </Typography>
        )}

        {bundle.expirationDate && (
          <Typography variant="bodyText2" color="subtleText">
            Offer valid through {bundle.expirationDate}
          </Typography>
        )}
      </Card>
    ))}
  </ModalView>
);
