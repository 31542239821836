import { Box, Icon, Typography, Card } from '@partstech/ui';
import { memo } from 'react';
import type { Supplier } from 'models';

type Props = {
  supplier?: Supplier | null;
};

export const SupplierWarning = memo(({ supplier }: Props) => {
  if (!supplier?.checkoutMessage) {
    return null;
  }

  return (
    <Card
      display="flex"
      background="neutralLight"
      justifyContent="center"
      textAlign="center"
      px={2}
      py={1}
      mt={2}
      radius={4}
      borderColor="neutral"
    >
      <Box mr={1}>
        <Icon name="error" size="large" color="secondary" />
      </Box>

      <Typography variant="subtitle2" color="dark">
        {supplier.checkoutMessage}
      </Typography>
    </Card>
  );
});
