import { useBox } from '@partstech/ui';
import iconCanada from 'images/icons/canada.svg';
import type { BoxProps } from '@partstech/ui';

export const Canada = (props: BoxProps) => {
  const { boxStyle } = useBox({ display: 'inline-block', mx: 1, ...props });

  return (
    <span css={boxStyle}>
      <img alt="canada" src={iconCanada} />
    </span>
  );
};
