import { Box, Typography } from '@partstech/ui';
import { useCreateShopContext } from 'components/Layout/SignUpLayout/CreateShopContext/CreateShopContext';
import { QuestionOptions, StepTitle, numberOfSuppliersOptions, useStepProgress } from 'features/signUp';
import { OnboardStepType } from 'shared/api';

export const PartsSuppliers = () => {
  const { navigateToNextStep } = useCreateShopContext();

  const { defaultOption, selectOption } = useStepProgress({
    step: OnboardStepType.Parts,
    onSuccess: navigateToNextStep,
  });

  return (
    <Box p={4} textAlign={{ sm: 'center', md: 'left' }} width={{ sm: 78, md: '100%' }}>
      <StepTitle>Parts suppliers</StepTitle>

      <Box mt={2}>
        <Typography component="span" variant="bodyText2" color="subtleText">
          How many parts suppliers do you have a business account with?
        </Typography>{' '}
        <Typography component="span" variant="bodyText2" color="negative">
          *
        </Typography>
      </Box>

      <QuestionOptions
        defaultOption={defaultOption}
        options={numberOfSuppliersOptions}
        gap={{ sm: 4, md: 7 }}
        optionMinWidth={{ sm: 33, md: 6 }}
        onSelect={selectOption}
      />
    </Box>
  );
};
