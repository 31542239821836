import { Banner, Box, Typography } from '@partstech/ui';
import { SupportContact } from 'shared/ui';
import { CardWrapper } from './CardWrapper';
import { useDuplicateShop } from './useDuplicateShop';
import type { BoxProps } from '@partstech/ui';

type Props = BoxProps & {
  onSubmit: () => Promise<void>;
  onLogout: () => void;
};

export const DuplicatedShop = ({ onSubmit, onLogout, ...props }: Props) => {
  const { createShop, isLoading } = useDuplicateShop({ onSubmit });

  return (
    <Box pt={{ sm: 0, md: 7 }} pb={{ sm: 4, md: 0 }} {...props} data-testid="duplicatedShop">
      <Banner title="An account already exists with this address." variant="warning" icon="warning" fullWidth>
        If you are the new owner of the business at this address click below to create a new PartsTech account.
      </Banner>

      <Box
        display="flex"
        gap={4}
        flexDirection={{ sm: 'column', md: 'row' }}
        flexWrap="wrap"
        mt={6}
        mb={{ sm: 5, md: 10 }}
      >
        <CardWrapper
          title="New shop owners"
          buttonText="Create new account"
          onClick={createShop}
          helperText="This will not delete the existing account."
          isLoading={isLoading}
          data-testid="createDuplicateShop"
        >
          You can still create another account with the same address.
        </CardWrapper>
        <CardWrapper
          title="Existing users/Shop employee"
          buttonText="Log into existing account"
          buttonVariant="secondary"
          onClick={onLogout}
          data-testid="logIn"
        >
          Please login with your existing account or if you are an employee, ask your shop owner to add you to the
          account.
        </CardWrapper>
      </Box>

      <SupportContact borderWidth={0} radius={0}>
        <Typography variant="bodyText2" color="subtleText">
          Still not sure? Let us help! Give us a call or send us an email.
        </Typography>
      </SupportContact>
    </Box>
  );
};
