import { entries, groupBy, uniqBy } from '@partstech/ui/utils';
import { type AttributeUsageSection, MIN_RECENT_PURCHASE_QUANTITY_FOR_SHOWING } from 'constant';
import { captureException } from 'integrations/sentry';
import { findAttributeByName } from '../utils/product/attributes/findAttributeByName';
import {
  getCustomAttributesForSection,
  makeProductAttribute,
} from '../utils/product/attributes/getCustomAttributesForSection';
import { getCustomAttributesByNames } from '../utils/tire/getCustomAttributesByNames';
import type { Brand } from './Brand';
import type { PartNumber } from './PartNumber';
import type { Quote } from './Quote';
import type { Store } from './Store';
import type { Supplier } from './Supplier';
import type {
  ProductAttachment,
  ProductAttribute,
  ProductDescription,
  ProductExtendedInformation,
  ProductFreightPackage,
  ProductInterchange,
  ProductLink,
  ProductVideo,
  ProductWarning,
} from 'shared/api';
import type { RawAttribute, RewardPoints } from 'shared/api/rest/gen/shop';
import type { Rebate } from 'store/entities/product';
import type { Bundle, ProductFile, ProductImage, Variation } from 'types/product';

export class Product {
  attributes: RawAttribute[];

  private attachments: ProductAttachment[] = [];

  brand: Brand | null = null;

  bundles: Bundle[];

  coreCharge: number | null;

  credentialId: string | null;

  descriptions: ProductDescription[];

  diagrams: { id: string; category?: { id: string } | null }[] | null = null;

  extendedInformation: ProductExtendedInformation[] | null = null;

  fetCharge: number | null;

  freightPackage: ProductFreightPackage | null = null;

  hasVehicleFitment: boolean;

  id: string;

  images: ProductImage;

  interchangePart: { brand: string | null; partNumber: string; type: 'INTCH' | 'SUPERSEDE' | 'ALTERNATE' } | null;

  interchanges: ProductInterchange[] | null = null;

  lineCardId: number | null;

  listPrice: number | null;

  mapPrice: number | null;

  marketingDescriptions: string[] | null = null;

  notes: string[][];

  notesOem: string[];

  partNumber: PartNumber | null = null;

  partTypeId: string | null;

  partTypeName: string | null;

  price: number | null;

  quantityStep: number;

  quote: Quote | null = null;

  rebates: Rebate[];

  retailPrice: number | null;

  rewardPoints: RewardPoints | null;

  showRetailPrice: boolean | null = null;

  sortHash: string;

  sponsorType: 'NONE' | 'SPONSORED' | 'POTENTIALLY_SPONSORED' = 'NONE';

  sponsoredPurchasesCount: number | null = null;

  stockMessage: Record<string, string> | null = null;

  shippingCharge: boolean;

  stocked: boolean;

  store: Store | null = null;

  supplier: Supplier | null = null;

  specificMarketingDescriptions: ProductDescription[] = [];

  productAttributes: ProductAttribute[] = [];

  title: string;

  variations: Variation[];

  vehicleId: number | null;

  videos: ProductVideo[] = [];

  links: ProductLink[] = [];

  warnings: ProductWarning[] = [];

  constructor(
    product: Pick<
      Product,
      | 'attributes'
      | 'bundles'
      | 'coreCharge'
      | 'credentialId'
      | 'descriptions'
      | 'fetCharge'
      | 'hasVehicleFitment'
      | 'id'
      | 'images'
      | 'interchangePart'
      | 'lineCardId'
      | 'listPrice'
      | 'mapPrice'
      | 'notes'
      | 'notesOem'
      | 'partTypeId'
      | 'partTypeName'
      | 'price'
      | 'quantityStep'
      | 'rebates'
      | 'retailPrice'
      | 'rewardPoints'
      | 'shippingCharge'
      | 'sortHash'
      | 'sponsoredPurchasesCount'
      | 'sponsorType'
      | 'stocked'
      | 'stockMessage'
      | 'title'
      | 'variations'
      | 'vehicleId'
    >
  ) {
    this.attributes = product.attributes;
    this.bundles = product.bundles;
    this.coreCharge = product.coreCharge;
    this.credentialId = product.credentialId;
    this.descriptions = product.descriptions;
    this.fetCharge = product.fetCharge;
    this.hasVehicleFitment = product.hasVehicleFitment;
    this.id = product.id;
    this.images = product.images;
    this.interchangePart = product.interchangePart;
    this.lineCardId = product.lineCardId;
    this.listPrice = product.listPrice;
    this.mapPrice = product.mapPrice;
    this.notes = product.notes;
    this.notesOem = product.notesOem;
    this.partTypeId = product.partTypeId;
    this.partTypeName = product.partTypeName;
    this.price = product.price;
    this.quantityStep = product.quantityStep;
    this.rebates = product.rebates;
    this.retailPrice = product.retailPrice;
    this.rewardPoints = product.rewardPoints;
    this.shippingCharge = product.shippingCharge ?? false;
    this.sortHash = product.sortHash;
    this.sortHash = product.sortHash;
    this.sponsoredPurchasesCount = product.sponsoredPurchasesCount ?? null;
    this.sponsorType = product.sponsorType;
    this.stocked = product.stocked;
    this.stockMessage = product.stockMessage;
    this.title = product.title;
    this.variations = product.variations;
    this.vehicleId = product.vehicleId;
  }

  get isBundleAvailable(): boolean {
    return this.bundles.length > 0;
  }

  get isStaggeredTire(): boolean {
    return this.isTire && this.hasPosition;
  }

  get isTire(): boolean {
    return this.supplier?.isTire ?? false;
  }

  get hasPosition(): boolean {
    return Boolean(findAttributeByName('Position', this.attributes));
  }

  get points(): number | null {
    return this.rewardPoints?.points ?? null;
  }

  get shouldApplyFetCharge(): boolean {
    return !this.supplier?.isTireDisconnected && this.isTire && Boolean(this.fetCharge === null || this.fetCharge > 0);
  }

  canBuy() {
    return this.quote !== null && this.quote.isAddToCart();
  }

  extractImages() {
    if (this.images.images.length === 0) {
      return [];
    }

    return this.images.images;
  }

  extractFiles(): ProductFile[] {
    const images = this.extractImages().map((image) => ({ ...image, isVideo: false }));
    const videos = this.videos.length === 0 ? [] : this.videos;
    const modifiedVideos = videos.map((video) => ({ ...video, isVideo: true }));

    return [...images, ...modifiedVideos];
  }

  extract360Images() {
    if (this.images.image360Groups.length === 0) {
      return [];
    }

    return this.images.image360Groups.flatMap((imageGroup) => imageGroup.map((image) => image.full ?? '')).reverse();
  }

  getActualPrice() {
    if (this.showRetailPrice) {
      return this.retailPrice || this.price;
    }

    return this.price;
  }

  getAttachments() {
    return entries(groupBy(this.attachments, 'group'))
      .map(([name, items]) => ({
        name,
        files: items.map((item) => ({
          filename: item.filename,
          full: item.url,
        })),
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  getAttributeByName(name: RawAttribute['name']) {
    return this.getAttributesByNames([name])[0] ?? null;
  }

  getAttributesByNames(names: RawAttribute['name'][]) {
    return getCustomAttributesByNames(names, this.attributes.map(makeProductAttribute));
  }

  getAttributesForSection(section: AttributeUsageSection, variationId: string | null = null) {
    const variation = this.getVariationById(variationId);

    return getCustomAttributesForSection([...(variation?.attributes ?? []), ...this.attributes], section);
  }

  getCoreCharge() {
    if (this.showRetailPrice) {
      return null;
    }

    return this.coreCharge;
  }

  getDefaultQuantity(variationId: string | null = null): number {
    if (this.quote && this.quantityStep > 1) {
      return this.quantityStep;
    }

    if (this.isTire) {
      const availableQuantity = this.quote?.getAvailableQuantity();

      if (this.isStaggeredTire) {
        if (availableQuantity && availableQuantity < 2) {
          return availableQuantity;
        }

        return 2;
      }

      if (availableQuantity && availableQuantity < 4) {
        return availableQuantity;
      }

      return 4;
    }

    const attributes = this.getAttributesForSection('product', variationId);
    const quantityPerVehicle = findAttributeByName('Qty', attributes);

    return quantityPerVehicle ? Number(quantityPerVehicle.value) : 1;
  }

  getDiagramId() {
    if (this.diagrams?.length) {
      return this.diagrams[0]?.id ?? null;
    }

    return null;
  }

  getDiagramCategoryId() {
    if (this.diagrams?.length) {
      return this.diagrams[0]?.category?.id ?? null;
    }

    return null;
  }

  getDescriptionByCode(code: string) {
    return this.descriptions.find((description) => description.code === code);
  }

  getFirstImage() {
    const images = this.extractImages();

    return images[0];
  }

  getSpecificMarketingDescriptionByCode(code: string) {
    return this.specificMarketingDescriptions.find((description) => description.code === code);
  }

  getProductAttributeByName(name: string) {
    return this.productAttributes.find((attribute) => attribute.name === name);
  }

  getFetCharge() {
    return this.fetCharge;
  }

  getId() {
    try {
      return [this.getPartNumberId().replace(/[^\w]/g, ''), this.credentialId, this.lineCardId, this.supplier?.id].join(
        '-'
      );
    } catch (error) {
      captureException(error, {
        data: {
          partNumberId: this.getPartNumberId(),
          typeOfPartNumberId: typeof this.getPartNumberId(),
          productId: this.id,
        },
      });

      return '';
    }
  }

  getPartNumberId() {
    return this.partNumber?.id ?? '';
  }

  getListPrice() {
    if (this.showRetailPrice || this.isTire) {
      return null;
    }

    return this.listPrice;
  }

  getLoadIndex() {
    return Number(this.getAttributeByName('TiresLoadIndex')?.value);
  }

  getMapPrice() {
    if (this.showRetailPrice) {
      return null;
    }

    return this.mapPrice;
  }

  getNotes(variationId: string | null = null) {
    const variation = this.getVariationById(variationId);

    return variation ? [...this.notes, ...variation.notes] : this.notes;
  }

  getRetailPrice() {
    if (this.showRetailPrice) {
      return null;
    }

    return this.retailPrice || this.price;
  }

  getSpeedRating() {
    return this.getAttributeByName('TiresSpeedRating')?.value;
  }

  getTitle(id: string | null = null) {
    const variation = this.getVariationById(id);
    return variation?.partTitle || this.title;
  }

  static getUniqueBundles(bundles?: Bundle[]) {
    return bundles ? uniqBy(bundles, (bundle) => bundle.name.toLowerCase()) : null;
  }

  getVariationAttributes(section: AttributeUsageSection, variationId: string) {
    const variation = this.getVariationById(variationId);

    return getCustomAttributesForSection(variation?.attributes ?? [], section);
  }

  getVariationById(id: string | null) {
    return this.variations?.find((item) => item.variationId === id) || null;
  }

  getRecentPurchasesCount() {
    if (this.sponsoredPurchasesCount && this.sponsoredPurchasesCount <= MIN_RECENT_PURCHASE_QUANTITY_FOR_SHOWING) {
      return null;
    }

    return this.sponsoredPurchasesCount;
  }

  hasEnoughQuantity(quantity: number): boolean {
    if (!this.quote) {
      return false;
    }
    return this.quote?.getAvailableQuantity() >= quantity;
  }

  isPotentiallySponsored() {
    return this.sponsorType === 'POTENTIALLY_SPONSORED';
  }

  hasQuantityExceedingSelectedStore(quantity: number) {
    const shouldDistributeQuantities = this.supplier?.shouldDistributeQuantities;
    const canSelectStore = this.supplier?.canSelectPartStore;
    const hasOneLine = this.quote?.hasOneLine();
    const store = this.quote?.lines[0];

    if (!store || shouldDistributeQuantities || canSelectStore || hasOneLine) {
      return false;
    }

    if (this.getDefaultQuantity() === quantity && this.getDefaultQuantity() > store.qty.value) {
      return false;
    }

    return store.qty.value < quantity;
  }

  isRear() {
    return this.isTire && this.getAttributeByName('Position')?.value === 'Rear';
  }

  isSponsored() {
    return this.sponsorType === 'SPONSORED';
  }

  shouldCallForAvailability() {
    if (this.canBuy() || !this.store || !this.store.phone) {
      return false;
    }

    return !this.quote?.isCallAvailability() || !this.quote?.isCallSupplier();
  }

  setAttachments(attachments: ProductAttachment[]) {
    this.attachments = attachments;
  }

  setBrand(brand: Brand) {
    this.brand = brand;
  }

  setMarketingDescriptions(marketingDescriptions: string[]) {
    this.marketingDescriptions = marketingDescriptions;
  }

  setPartNumber(partNumber: PartNumber) {
    this.partNumber = partNumber;
  }

  setQuote(quote: Quote | null) {
    this.quote = quote;
  }

  setShowRetailPrice(isShowRetailPrice: boolean) {
    this.showRetailPrice = isShowRetailPrice;
  }

  setStore(store: Store) {
    this.store = store;
  }

  setSupplier(supplier: Supplier) {
    this.supplier = supplier;
  }

  setLinks(links: ProductLink[]) {
    this.links = links;
  }

  setSpecificMarketingDescriptions(descriptions: ProductDescription[]) {
    this.specificMarketingDescriptions = descriptions;
  }

  setProductAttributes(attributes: ProductAttribute[]) {
    this.productAttributes = attributes;
  }

  setVideos(videos: ProductVideo[]) {
    this.videos = videos;
  }

  setWarnings(warnings: ProductWarning[]) {
    this.warnings = warnings;
  }

  setFreightPackage(packages: ProductFreightPackage) {
    this.freightPackage = packages;
  }

  setExtendedInformation(extendedInformation: ProductExtendedInformation[]) {
    this.extendedInformation = extendedInformation;
  }

  setDiagrams(diagrams: Product['diagrams']) {
    this.diagrams = diagrams;
  }

  setInterchanges(interchanges: Product['interchanges']) {
    this.interchanges = interchanges;
  }
}
