export const INITIALIZE_KEY = '53nu41g1';

export enum SupportKey {
  Default = 'Sr3G26T',
}

/** @knipignore */
export enum ConnectSmsKey {
  NapaTRACS = 'DIpBhDNrMR',
  Tekmetric = 'pjC1zf7Hwv',
  Mitchell1 = '_K7cc3q3l1',
  Shopmonkey = 'zadU5HAwlg',
  AutoLeap = 'qthNh4aJaw',
  ShopBoss = 'DWUUoEKqNT',
  ShopWare = 'A-mJCDjHL5',
  Other = 'QQ1ma3nwt4',
}
