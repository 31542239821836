import { getCurrentScope } from '@sentry/browser';
import { useEffect } from 'react';

type Args = {
  accountId: string;
  username: string;
  email: string;
};

type Params = {
  skip?: boolean;
};

export const useUpdateSentryUser = ({ accountId: id, email, username }: Args, { skip }: Params = {}) =>
  useEffect(() => {
    if (skip) {
      return;
    }

    getCurrentScope().setUser({ id, username, email });
  }, [email, id, skip, username]);
