import { Box, Button, ButtonGroup, Typography } from '@partstech/ui';
import { PaymentCardDropdown } from './PaymentCardDropdown';
import { useChangePaymentMethod } from './useChangePaymentMethod';

export const ChangePayment = () => {
  const {
    selectedCard,
    setSelectedCard,
    openAddNewCardModal,
    openEditCardModal,
    openUpdatePaymentMethodModal,
    onCancel,
    isLoading,
    isDisabled,
    showAddNewCardButton,
  } = useChangePaymentMethod();

  return (
    <>
      {selectedCard?.isDefault ? (
        <>
          <Typography>This card is being used for your PartsTech subscription and cannot be deleted.</Typography>

          <Box mt={2}>
            <Typography>A different credit card must be chosen to continue.</Typography>
          </Box>
        </>
      ) : (
        <Typography>Select a card to start using it for your PartsTech subscription.</Typography>
      )}

      <Box my={6}>
        <PaymentCardDropdown
          defaultCard={selectedCard}
          onSelectCard={setSelectedCard}
          onAddNewCard={openAddNewCardModal}
          onEditCard={openEditCardModal}
          showAddNewCardButton={showAddNewCardButton}
        />
      </Box>

      <ButtonGroup flexDirection="column">
        <Button
          disabled={isDisabled}
          isLoading={isLoading}
          onClick={openUpdatePaymentMethodModal}
          data-testid="updatePaymentButton"
        >
          Update payment method
        </Button>

        <Button variant="text" onClick={onCancel}>
          Cancel
        </Button>
      </ButtonGroup>
    </>
  );
};
