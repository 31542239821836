import { useMemo } from 'react';
import { useGetCurrentUser } from '../currentUser/useGetCurrentUser';
import { useGetPartTypeGroupQuery } from './GetPartTypeGroup.generated';

type Props = {
  id: string;
  skip: boolean;
};

export const useGetPartTypeGroup = ({ id, skip }: Props) => {
  const { hasCurrentUser } = useGetCurrentUser();
  const { currentData, ...rest } = useGetPartTypeGroupQuery({ id }, { skip: skip || !id || !hasCurrentUser });

  const partTypeGroup = useMemo(() => currentData?.partTypeGroup, [currentData?.partTypeGroup]);

  return {
    partTypeGroup,
    ...rest,
  };
};
