import { Box, Typography } from '@partstech/ui';
import { useMemo } from 'react';
import { findAttributeByName, getTireLoadIndex, getTireSpeedRating, getTireLoadRangePSI } from 'utils';
import type { ProductAttribute } from 'types/product';

type Props = {
  attributes: ProductAttribute[];
};

export const TireSizeTranslation = ({ attributes }: Props) => {
  const loadIndex = useMemo(() => findAttributeByName('TiresLoadIndex', attributes)?.value, [attributes]);
  const loadIndexDual = useMemo(() => findAttributeByName('TiresLoadIndexDual', attributes)?.value, [attributes]);
  const speedRating = useMemo(() => findAttributeByName('TiresSpeedRating', attributes)?.value, [attributes]);
  const loadRange = useMemo(() => findAttributeByName('TiresLoadRange', attributes)?.value, [attributes]);

  if (!loadIndex && !speedRating && !loadRange) {
    return null;
  }

  return (
    <>
      {loadIndex && (
        <>
          <Typography variant="overline" color="white">
            LOAD INDEX{' '}
          </Typography>
          {loadIndexDual ? (
            <>
              <Typography variant="overline" color="white" component="div">
                Single:{' '}
                <Typography variant="caption" color="white">
                  {loadIndex} - {getTireLoadIndex(loadIndex)}{' '}
                </Typography>
              </Typography>
              <Typography variant="overline" color="white" component="div">
                Dual:{' '}
                <Typography variant="caption" color="white">
                  {loadIndexDual} - {getTireLoadIndex(loadIndexDual)}{' '}
                </Typography>
              </Typography>
            </>
          ) : (
            <Typography variant="overline" color="white" component="div">
              {loadIndex}:{' '}
              <Typography variant="caption" color="white">
                {getTireLoadIndex(loadIndex)}{' '}
              </Typography>
            </Typography>
          )}
          <Box mb={2} />
        </>
      )}
      {loadRange && (
        <>
          <Typography variant="overline" color="white">
            LOAD RANGE{' '}
          </Typography>
          <Typography variant="overline" color="white" component="div">
            {loadRange}:{' '}
            <Typography variant="caption" color="white">
              {getTireLoadRangePSI(loadRange)} PSI{' '}
            </Typography>
          </Typography>
          <Box mb={2} />
        </>
      )}
      {speedRating && (
        <>
          <Typography variant="overline" color="white">
            SPEED RATING{' '}
          </Typography>
          <Typography variant="overline" color="white" component="div">
            {speedRating}:{' '}
            <Typography variant="caption" color="white">
              {getTireSpeedRating(speedRating)}
            </Typography>
          </Typography>
        </>
      )}
    </>
  );
};
