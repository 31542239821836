import { HashRoutes, Routes } from 'constant';
import { getMyShopUrl } from 'utils/url/getMyShopUrl';
import { getSignUpUrl } from 'utils/url/getSignUpUrl';
import { getSubscriptionUrl } from 'utils/url/getSubscriptionUrl';
import { Redirect } from './Redirect';
import type { RouteObject } from 'react-router-dom';

type ApplicationRedirects = { from: string; to: Routes | string }[];

export const applicationRedirects: ApplicationRedirects = [
  { from: '/profile/edit/information', to: `/${HashRoutes.PROFILE}` },
  { from: '/profile/change-password', to: `/${HashRoutes.PROFILE}` },
  { from: '/profile/shop/api-options', to: `/${HashRoutes.PROFILE}` },
  { from: '/account', to: `/${HashRoutes.PROFILE}` },
  { from: '/profile/shop/users', to: getMyShopUrl('users') },
  { from: '/users', to: getMyShopUrl('users') },
  { from: '/profile/edit/shop/settings', to: getMyShopUrl('details') },
  { from: '/profile/edit/shop/shipping-settings', to: getMyShopUrl('cart-shipping') },

  { from: '/profile/shop/payment-methods', to: getMyShopUrl('payments') },
  { from: '/profile/edit/shop/tires-pricing', to: getMyShopUrl('retail-pricing') },
  { from: '/profile/reward/1', to: getMyShopUrl('rewards') },
  { from: '/subscription-plans', to: getMyShopUrl('subscription') },
  { from: '/subscriptions', to: getMyShopUrl('subscription') },
  { from: '/my-shop/subscription/billing-history', to: getSubscriptionUrl({ page: 'payment-history' }) },
  { from: '/new-cart', to: Routes.CART },
  { from: '/mechanic/signup', to: getSignUpUrl() },
  { from: '/signup', to: getSignUpUrl() },
  { from: '/supplier/:slug', to: Routes.SUPPLIER_PROFILE },
  { from: '/my-shop', to: getMyShopUrl('details') },
  { from: '/my-shop/preferred-brands', to: getMyShopUrl('brand-preferences') },

  { from: '/profile/edit/shop/companies', to: getMyShopUrl('suppliers') },
  { from: '/profile/edit/shop/companies/tires', to: getMyShopUrl('suppliers') },
  { from: '/my-shop/part-suppliers', to: getMyShopUrl('suppliers') },
  { from: '/my-shop/tire-suppliers', to: getMyShopUrl('suppliers') },
  { from: '/my-shop/shipping', to: getMyShopUrl('cart-shipping') },
  { from: '/invoice/:userId/:hash', to: Routes.ORDER },
  { from: '/maintenance-specs/:vehicleId/:tab', to: Routes.SERVICE_GUIDES },

  { from: '/reports', to: Routes.REPORTS },
  { from: '/reports/spending', to: Routes.REPORTS_SPENDING },
  { from: '/reports/tires', to: Routes.REPORTS_TIRES },
  { from: '/reports/parts', to: Routes.REPORTS_PARTS },
  { from: '/reports/tires/tire-size-page/:tireSize', to: Routes.REPORTS_TIRE_SIZE },
  { from: '/reports/parts/part-type-page/:partType', to: Routes.REPORTS_PART_TYPE },
];

export const getApplicationRedirects = (): RouteObject[] =>
  applicationRedirects.map((redirect) => ({
    path: redirect.from,
    element: <Redirect to={redirect.to} />,
  }));
