import { ShopType } from 'shared/api';

type Option = {
  value: ShopType;
  text: string;
};

export const shopTypeOptions: Option[] = [
  { value: ShopType.GeneralRepairShop, text: 'General repair shop' },
  { value: ShopType.TireShop, text: 'Tire shop' },
  { value: ShopType.QuickLube, text: 'Quick lube' },
  { value: ShopType.HeavyDuty, text: 'Heavy duty' },
  { value: ShopType.MobileMechanic, text: 'Mobile mechanic' },
  { value: ShopType.NewUsedVehicleDealer, text: 'New/used vehicle dealer' },
  { value: ShopType.FleetRepair, text: 'Fleet repair' },
  { value: ShopType.PartsSupplier, text: 'Parts supplier' },
  { value: ShopType.OtherAutomotiveRepair, text: 'Other automotive repair' },
];
