import { FullStory } from '@fullstory/browser';
import { useCallback } from 'react';
import type { FullStoryEventNames } from '../constant';

type FullStoryEventValue =
  | boolean
  | string
  | number
  | Date
  | boolean[]
  | string[]
  | number[]
  | Date[]
  | null
  | undefined
  | Record<string, FullStoryEventValue>[];

export type FullStoryEventPayload = Record<string, FullStoryEventValue> & {
  products?: Record<string, FullStoryEventValue>[];
};

type CommonData = {
  shopID: string;
  userID: string;
};

type Options = {
  isDevMode?: boolean;
};

export const useTracking = (commonData: CommonData, { isDevMode }: Options = {}) =>
  useCallback(
    (eventName: FullStoryEventNames, payload: FullStoryEventPayload = {}) => {
      if (isDevMode) {
        return;
      }

      if (payload.products) {
        FullStory('trackEvent', {
          name: eventName,
          properties: {
            ...commonData,
            ...payload,
            products: payload.products.map((product) => ({
              ...product,
              ...commonData,
            })),
          },
        });
      } else {
        FullStory('trackEvent', {
          name: eventName,
          properties: { ...commonData, ...payload },
        });
      }
    },
    [isDevMode, commonData]
  );
