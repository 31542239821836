import { useCallback, useMemo } from 'react';
import { ProductAlias } from '../constants/products';
import { usePlanAndAddOns } from './usePlanAndAddOns';
import { useSubscriptionEX } from './useSubscriptionEX';
import { useSubscriptionMain } from './useSubscriptionMain';

export const useSubscription = () => {
  const { featuresEX, isRestrictedEX } = useSubscriptionEX();
  const { isFetching, isLoading, subscription: subscriptionMain, getSupplierById } = useSubscriptionMain();

  const { addOns, plan } = usePlanAndAddOns({
    featuresEX,
    isRestrictedEX,
    subscriptionAddOns: subscriptionMain?.addOns,
    subscriptionPlan: subscriptionMain?.plan,
  });

  const subscription = useMemo(() => ({ ...subscriptionMain, addOns, plan }), [addOns, plan, subscriptionMain]);

  const hasSuppliers = useMemo(
    () => Boolean(subscriptionMain?.addOns?.some((addOn) => addOn.alias === ProductAlias.Tires)),
    [subscriptionMain?.addOns]
  );

  const hasPaidProducts = useMemo(
    () => (isRestrictedEX ? hasSuppliers : Boolean(subscriptionMain.startedAt)),
    [hasSuppliers, isRestrictedEX, subscriptionMain]
  );

  const getProductByAlias = useCallback(
    (alias: ProductAlias) => {
      if (plan.alias === alias) {
        return plan;
      }

      return addOns?.find((addOn) => addOn?.alias === alias);
    },
    [addOns, plan]
  );

  return {
    hasPaidProducts,
    hasSuppliers,
    isFetching,
    isLoading,
    isRestrictedEX,
    subscription,
    getProductByAlias,
    getSupplierById,
  };
};
