import { useCallback, useEffect, useState } from 'react';
import { SearchTabs } from 'features/searchForm';
import { SearchTypes } from 'types/search';

const getTabBySearchType = (
  searchType: SearchTypes | null = SearchTypes.PARTS,
  tab = SearchTabs.POPULAR
): SearchTabs => {
  if (searchType === SearchTypes.TIRES) {
    return SearchTabs.TIRES;
  }

  return tab === SearchTabs.TIRES ? SearchTabs.POPULAR : tab;
};

type Props = {
  searchType?: SearchTypes | null;
  onTabChange?: (tab: SearchTabs) => void;
};

export const useSelectedTab = ({ searchType = SearchTypes.PARTS, onTabChange }: Props) => {
  const [tab, setTab] = useState<SearchTabs>(getTabBySearchType(searchType));

  useEffect(() => {
    setTab(getTabBySearchType(searchType, tab));
  }, [searchType, tab]);

  const changeTab = useCallback(
    (selectedTab: SearchTabs) => {
      setTab(selectedTab);
      onTabChange && onTabChange(selectedTab);
    },
    [onTabChange]
  );

  return [tab, changeTab] as const;
};
