import { css } from '@emotion/react';
import { Box, Button, ImageFallback, Modal, ModalButtons, Scrollbar, Status } from '@partstech/ui';
import { useCallback } from 'react';
import { PartInfo } from '../PartInfo';
import { QuantityInput } from '../QuantityInput';
import type { ModalProps, Theme } from '@partstech/ui';
import type { CartOrderItem } from 'models';

const styles = {
  content: (theme: Theme) => css`
    max-height: ${theme.sizing(87)};
  `,
};

type Props = {
  orderItem: CartOrderItem;
  showRetailPrice: boolean;
  disabled: boolean;
  showETA?: boolean;
  onChangeQuantity: (quantity: number) => void;
  onRemove: () => void;
} & ModalProps;

export const PartModal = ({
  onClose,
  orderItem,
  showRetailPrice,
  disabled,
  showETA,
  onChangeQuantity,
  onRemove,
}: Props) => {
  const handleDeleteClick = useCallback(() => {
    onRemove();
    onClose?.();
  }, [onClose, onRemove]);

  return (
    <Modal onClose={onClose} size="small">
      <Scrollbar css={styles.content}>
        <Box textAlign="center" mb={4}>
          <ImageFallback src={orderItem.image} />
        </Box>

        <PartInfo orderItem={orderItem} showRetailPrice={showRetailPrice} showETA={showETA} onTitleClick={onClose} />
      </Scrollbar>

      <ModalButtons justifyContent="space-between" separated>
        <div>
          <QuantityInput quantity={orderItem.quantity} onChangeQuantity={onChangeQuantity} disabled={disabled} />

          {orderItem.error && (
            <Status variant="light" color="negative">
              {orderItem.error}
            </Status>
          )}
        </div>

        <Button variant="secondary" onClick={handleDeleteClick} disabled={disabled} data-testid="delete">
          Delete
        </Button>
      </ModalButtons>
    </Modal>
  );
};
