import { Provider } from 'react-redux';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { routes } from 'app/AppRouter';
import { wrapCreateBrowserRouter } from 'integrations/sentry';
import { store } from 'store';
import { IntegrationWrapper } from './IntegrationWrapper';

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

export const App = () => (
  <Provider store={store}>
    <IntegrationWrapper>
      <RouterProvider router={sentryCreateBrowserRouter(routes)} />
    </IntegrationWrapper>
  </Provider>
);
