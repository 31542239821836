import { css } from '@emotion/react';
import { typographyStyles } from '@partstech/ui';
import { isEmpty } from '@partstech/ui/utils';
import { memo } from 'react';
import { Warning } from './Warning';
import type { LinkProps, Theme } from '@partstech/ui';
import type { Product } from 'models';

const styles = {
  list: css`
    counter-reset: list;
    padding: 0;
    margin: 0;
  `,
  listItem: (color: LinkProps['color']) => (theme: Theme) => css`
    margin-right: ${theme.sizing(1)};
    list-style: none;
    white-space: nowrap;
    display: flex;
    align-items: center;

    &:before {
      content: counter(list) ') ';
      counter-increment: list;

      ${typographyStyles.bodyText1(color)(theme)};
    }

    ${theme.breakpoints.down('md')} {
      white-space: normal;
      position: relative;
      padding-left: ${theme.sizing(5)};

      &:before {
        position: absolute;
        left: 0;
      }
    }
  `,
};

type Props = {
  warnings: Product['warnings'];
  color?: LinkProps['color'];
  variant?: LinkProps['variant'];
};

export const ProductWarnings = memo(({ warnings = [], color = 'primary', variant = 'bodyText2' }: Props) => {
  const [warn] = warnings;

  if (isEmpty(warnings)) {
    return null;
  }

  if (warnings.length === 1 && warn) {
    return (
      <div>
        <Warning warning={warn} color={color} variant={variant} />
      </div>
    );
  }

  return (
    <div>
      <ol css={styles.list}>
        {warnings.map((warning, index) => (
          <li key={index} css={styles.listItem(color)}>
            <Warning warning={warning} color={color} variant={variant} />
          </li>
        ))}
      </ol>
    </div>
  );
});
