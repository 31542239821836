import { Quote } from './Quote';

export class DealerQuote extends Quote {
  isInStock() {
    return true;
  }

  isAvailableOrder() {
    return this.status === 'BACK_ORDER' || this.status === 'OUT_OF_STOCK' || this.status === 'SPECIAL_ORDER';
  }

  isBackOrder() {
    return false;
  }

  isOutOfStock() {
    return false;
  }

  isSpecialOrder() {
    return false;
  }
}
