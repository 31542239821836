/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type DeleteLaborItemsFromCartMutationVariables = Types.Exact<{
  ids: Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input'];
}>;

export type DeleteLaborItemsFromCartMutation = {
  __typename?: 'Mutation';
  batchDeleteActiveCartLaborItems?:
    | { __typename?: 'DeleteActiveCartLaborItemErrorPayload'; errorMessage: Types.Scalars['String']['input'] }
    | { __typename?: 'DeleteActiveCartLaborItemSuccessPayload'; success: Types.Scalars['Boolean']['input'] }
    | null;
};

export const DeleteLaborItemsFromCartDocument = `
    mutation DeleteLaborItemsFromCart($ids: [ID!]!) {
  batchDeleteActiveCartLaborItems(input: {ids: $ids}) {
    ... on DeleteActiveCartLaborItemErrorPayload {
      errorMessage
    }
    ... on DeleteActiveCartLaborItemSuccessPayload {
      success
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    DeleteLaborItemsFromCart: build.mutation<
      DeleteLaborItemsFromCartMutation,
      DeleteLaborItemsFromCartMutationVariables
    >({
      query: (variables) => ({ document: DeleteLaborItemsFromCartDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useDeleteLaborItemsFromCartMutation } = injectedRtkApi;
