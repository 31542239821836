import { useDebouncedCallback } from '@partstech/ui/hooks';
import { useCallback, useMemo, useState } from 'react';
import { api as activeCartApi } from 'features/cart/api/queries/GetActiveCart.generated';
import { minMaxLength } from 'shared/lib/form';
import { useAppDispatch } from 'store';
import { useUpdatePaymentNotes } from '../api/mutations/updatePaymentNotes';
import type { CartOrder } from 'models';
import type { PaymentNotesInput } from 'shared/api';

const emptyOrders: CartOrder[] = [];

type Props = {
  orders?: CartOrder[];
};

export const usePoNumbers = ({ orders = emptyOrders }: Props) => {
  const dispatch = useAppDispatch();

  const filteredOrders = useMemo(
    () => orders.filter((order) => order.supplierAccount?.supplier?.allowCustomPurchaseOrderNumbers),
    [orders]
  );

  const [apiErrors, setApiErrors] = useState<Record<string, string | null>>({});

  const [updatePaymentNotes] = useUpdatePaymentNotes();

  const errors = useMemo(
    () =>
      filteredOrders.reduce(
        (acc, order) => ({
          ...acc,
          [order.id]: minMaxLength(order.poNumber, order.poNumberValidation || undefined) || apiErrors[order.id],
        }),
        {}
      ),
    [apiErrors, filteredOrders]
  );

  const updatePaymentNotesDebounced = useDebouncedCallback(
    async (args: { paymentNotes: PaymentNotesInput; orderId: string }) => {
      const res = await updatePaymentNotes(args);

      const errorMessage =
        res.data?.updateActiveCartOrderPaymentNotes && 'errorMessage' in res.data.updateActiveCartOrderPaymentNotes
          ? res.data.updateActiveCartOrderPaymentNotes.errorMessage
          : null;

      setApiErrors((prev) => ({ ...prev, [args.orderId]: errorMessage }));
    },
    300
  );

  const updateCartData = useCallback(
    (poNumber: string, orderId: string) => {
      dispatch(
        activeCartApi.util.updateQueryData('GetActiveCart', undefined, (draft) => {
          draft?.activeCart?.orders?.forEach((draftOrder) => {
            if (draftOrder.id === orderId) {
              Object.assign(draftOrder, {
                purchaseOrderNumber: poNumber,
              });
            }
          });
        })
      );
    },
    [dispatch]
  );

  const changeGlobalPoNumber = useCallback(
    (newValue: string) => {
      const hasError = filteredOrders.some((order) => minMaxLength(newValue, order.poNumberValidation || undefined));

      filteredOrders.forEach((order) => updateCartData(newValue, order.id));

      if (!hasError) {
        filteredOrders.forEach((order) => {
          updatePaymentNotesDebounced({
            paymentNotes: { purchaseOrderNumber: newValue, customNotes: order.notes },
            orderId: order.id,
          });
        });
      }
    },
    [filteredOrders, updateCartData, updatePaymentNotesDebounced]
  );

  const changePoNumber = useCallback(
    (newValue: string, order: CartOrder) => {
      updateCartData(newValue, order.id);

      const error = minMaxLength(newValue, order.poNumberValidation || undefined);

      if (!error) {
        updatePaymentNotesDebounced({
          paymentNotes: { purchaseOrderNumber: newValue, customNotes: order.notes },
          orderId: order.id,
        });
      }
    },
    [updateCartData, updatePaymentNotesDebounced]
  );

  return {
    changePoNumber,
    changeGlobalPoNumber,
    errors,
  };
};
