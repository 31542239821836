import { Box, Typography, type BoxProps } from '@partstech/ui';
import { useMedia } from '@partstech/ui';
import type { PropsWithChildren } from 'react';

type Props = PropsWithChildren<BoxProps>;

export const StepTitle = ({ children, ...props }: Props) => {
  const { isMobile } = useMedia();

  return (
    <Box {...props}>
      <Typography variant={isMobile ? 'h3' : 'h2'}>{children}</Typography>
    </Box>
  );
};
