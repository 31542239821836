import { Box, Typography } from '@partstech/ui';

export const Tires = () => (
  <>
    <Box mb={4}>
      <Typography>Tire purchasing is only available with a paid PartsTech subscriptions.</Typography>
    </Box>

    <Box mb={4}>
      <Typography>Upgrade your subscription get access to tire suppliers.</Typography>
    </Box>

    <Typography>
      Our paid subscriptions also include many features that save you time and super-charge your shop.
    </Typography>
  </>
);
