import { Country, ShopAddressType } from 'shared/api';
import type { ShopAddress } from 'shared/api';

export const partsTechAddress: ShopAddress = {
  id: 'unknown',
  address: {
    address1: '1 Broadway',
    address2: null,
    city: 'Cambridge',
    country: Country.Us,
    state: 'MA',
    zipCode: '02142',
  },
  isDefault: false,
  type: ShopAddressType.Shipping,
  coordinates: {
    latitude: 42.36358,
    longitude: -71.08246,
  },
};
