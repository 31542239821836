import { css } from '@emotion/react';
import { Box, Typography } from '@partstech/ui';
import type { Theme } from '@partstech/ui';

const styles = {
  title: (theme: Theme) => css`
    p {
      margin: ${theme.sizing(0)};
    }
  `,
};

type Props = {
  htmlContent: string;
};

export const RegistrationInstruction = ({ htmlContent }: Props) => (
  <Box mb={{ sm: 4, md: 6 }} css={styles.title}>
    <Typography component="div">
      <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
    </Typography>
  </Box>
);
