import { Routes } from 'constant';
import { generateUrl } from './generateUrl';

const myShopPages = [
  'details',
  'users',
  'cart-shipping',
  'suppliers',
  'payments',
  'retail-pricing',
  'brand-preferences',
  'rewards',
  'subscription',
] as const;

export type MyShopPageAlias = (typeof myShopPages)[number];

export type MyShopPageParams = { '*': MyShopPageAlias };

type UrlConfig = { hash?: string };

export const getMyShopUrl = (
  page: MyShopPageAlias,
  search?: Record<string, string | number | boolean>,
  { hash }: UrlConfig = {}
) => generateUrl(Routes.MY_SHOP, { page }, search, hash);
