/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import { FullPartTypeFragmentDoc } from './FullPartType.generated';
import type * as Types from '../../../shared/api/graphql/graphql';

export type GetPartTypeGroupQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;

export type GetPartTypeGroupQuery = {
  __typename?: 'Query';
  partTypeGroup?: {
    __typename?: 'PartTypeGroup';
    id: string;
    name: Types.Scalars['String']['input'];
    partTypes?: Array<{
      __typename?: 'PartType';
      id: string;
      name: Types.Scalars['String']['input'];
      application: Types.PartTypeApplication;
      aliases?: Array<Types.Scalars['String']['input']> | null;
      attributes?: Array<{
        __typename?: 'PartTypeAttribute';
        name: Types.Scalars['String']['input'];
        priority: number;
        required: Types.Scalars['Boolean']['input'];
        values?: Array<Types.Scalars['String']['input']> | null;
      }> | null;
      category?: { __typename?: 'PartCategory'; id: string; name: Types.Scalars['String']['input'] } | null;
      linkedPartTypes?: Array<{ __typename?: 'PartType'; application: Types.PartTypeApplication }> | null;
      subCategory?: { __typename?: 'PartSubCategory'; id: string; name: Types.Scalars['String']['input'] } | null;
    }> | null;
  } | null;
};

export const GetPartTypeGroupDocument = `
    query GetPartTypeGroup($id: ID!) {
  partTypeGroup(id: $id) {
    id
    name
    partTypes {
      ...FullPartType
    }
  }
}
    ${FullPartTypeFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetPartTypeGroup: build.query<GetPartTypeGroupQuery, GetPartTypeGroupQueryVariables>({
      query: (variables) => ({ document: GetPartTypeGroupDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetPartTypeGroupQuery, useLazyGetPartTypeGroupQuery } = injectedRtkApi;
