import { useMemo } from 'react';
import { api as generatedApi } from './GetManagementSystems.generated';
import type { GetManagementSystemsQuery } from './GetManagementSystems.generated';
import type { ArrayItemType } from 'types/general';

export type ManagementSystems = NonNullable<GetManagementSystemsQuery['managementSystems']>;
export type EntityManagementSystem = ArrayItemType<ManagementSystems>;

const api = generatedApi.enhanceEndpoints({
  addTagTypes: ['ManagementSystems'],
  endpoints: {
    GetManagementSystems: {
      providesTags: () => ['ManagementSystems'],
    },
  },
});

const { useGetManagementSystemsQuery } = api;

const emptyManagementSystems: ManagementSystems = [];

// TODO: put in a separate feature
export const useGetManagementSystems = () => {
  const { data, ...rest } = useGetManagementSystemsQuery(undefined);

  const managementSystems = useMemo(() => data?.managementSystems ?? emptyManagementSystems, [data?.managementSystems]);

  return { data, managementSystems, ...rest };
};
