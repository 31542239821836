/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import type * as Types from '../graphql';

export type AddressFragment = {
  __typename?: 'Address';
  address1: Types.Scalars['String']['input'];
  address2?: Types.Scalars['String']['input'] | null;
  city: Types.Scalars['String']['input'];
  country: Types.Country;
  state: Types.Scalars['String']['input'];
  zipCode: Types.Scalars['String']['input'];
};

export const AddressFragmentDoc = `
    fragment Address on Address {
  address1
  address2
  city
  country
  state
  zipCode
}
    `;
