import { createOption } from './createOption';
import type { Product } from 'models';

export const createRebateOption =
  (count: number = 0) =>
  (product: Product) =>
    createOption(
      product.rebates.length > 0 ? '1' : '0',
      product.rebates.length > 0 ? 'Has rebate' : 'No rebate',
      count
    );
