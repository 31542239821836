import { useCreateModal } from '@partstech/ui/hooks';
import { useCallback } from 'react';
import { DowngradeModal } from '../components/DowngradeModal';
import { useSubscriptionDenied } from './useSubscriptionDenied';
import type { DowngradeModalProps } from '../components/DowngradeModal';

export const useDowngradeModal = (props: DowngradeModalProps) => {
  const { isDenied, openDeniedDialog } = useSubscriptionDenied();

  const { open } = useCreateModal(DowngradeModal, { ...props, size: 'large' }, { customId: 'downgradeModal' });

  const openDowngradeModal = useCallback(() => {
    if (isDenied) {
      openDeniedDialog();
      return;
    }

    open();
  }, [isDenied, openDeniedDialog, open]);

  return {
    openDowngradeModal,
  };
};
