import { Card } from '@partstech/ui';
import type { CardProps } from '@partstech/ui';

export const BackgroundCard = ({ children, ...props }: CardProps) => (
  <Card
    data-testid="matchingTiresConfigurations"
    background="mono2"
    alignItems="center"
    py={2}
    px={4}
    display="flex"
    justifyContent="space-between"
    flexWrap="wrap"
    gap={4}
    borderColor="mono2"
    {...props}
  >
    {children}
  </Card>
);
