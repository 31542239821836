import { useSessionStorage } from '@partstech/ui/hooks';
import { useEffect } from 'react';
import { SessionStorageKeys } from 'constant';
import { useSelectedSmsFromQuery } from 'hooks/shopManagementSystems';

export const useIsMitchell1SignUp = () => {
  const [isMitchell1SignUp, setIsMitchell1SignUp] = useSessionStorage<boolean>(SessionStorageKeys.IS_MITCHELL1_SIGN_UP);

  const { selectedSms } = useSelectedSmsFromQuery();

  useEffect(() => {
    if (!selectedSms) {
      return;
    }

    setIsMitchell1SignUp(true);
  }, [selectedSms, setIsMitchell1SignUp]);

  return isMitchell1SignUp ?? false;
};
