import { useSessionStorage } from '@partstech/ui/hooks';
import { useCallback } from 'react';
import { SessionStorageKeys } from 'constant';

export const useSkipSignUpWelcomeBack = () => {
  const [isSkipped = false, setSkipped] = useSessionStorage<boolean>(
    SessionStorageKeys.IS_SIGN_UP_WELCOME_BACK_SKIPPED
  );

  const skip = useCallback(() => {
    setSkipped(true);
  }, [setSkipped]);

  return {
    isSkipped,
    skip,
  };
};
