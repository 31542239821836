import { Icon } from '@partstech/ui';
import React from 'react';
import { styles } from '../Carousel.styles';

type NextProps = {
  disabled: boolean;
  isVertical: boolean;
  onClick: (e: React.MouseEvent) => void;
  useBallControls?: boolean;
};

export const Next: React.FunctionComponent<NextProps> = ({ disabled, isVertical, onClick, useBallControls }) => (
  <a
    href="components/Carousel/Next/index#"
    css={styles.control({ type: 'next', isVertical, disabled, useBallControls })}
    onClick={(e) => !disabled && onClick(e)}
  >
    <Icon name={isVertical ? 'expand_more' : 'chevron_right'} />
  </a>
);
