import { keys } from '@partstech/ui/utils';
import { isPlainObject } from 'shared/lib/object';
import { useSearchParams } from './useSearchParams';
import type { CheckedFilters } from 'types/search';

export const usePopulateFiltersFromQuery = () => {
  const { searchParams } = useSearchParams();

  const values = keys(searchParams).reduce((memo: Partial<CheckedFilters>, key) => {
    const checkedValues = searchParams[key] ?? [];

    if (key === 'attributes' && isPlainObject(checkedValues)) {
      return keys(checkedValues).reduce(
        (result, name) => ({
          ...result,
          [`attributes.${name}`]: 'length' in checkedValues ? [] : checkedValues[name],
        }),
        memo
      );
    }

    if (!Array.isArray(checkedValues)) {
      return memo;
    }

    return { ...memo, [key]: checkedValues };
  }, {});

  return { values };
};
