type Field = {
  internalName: string;
  value?: string | null;
};

export const normalizeFieldValue = (credentials: Field[]) =>
  credentials.reduce<Record<string, string>>(
    (values, { internalName, value }) => ({ ...values, [internalName]: value ?? '' }),
    {}
  );
