import { css } from '@emotion/react';
import { Button, Box } from '@partstech/ui';
import { FormTextField, HookForm, useController, useForm } from '@partstech/ui/forms';
import { memo, useCallback } from 'react';
import { MAX_QUANTITY } from 'constant';
import type { KeyboardEvent } from 'react';

const styles = {
  textInput: css`
    .Field-content {
      padding: 0;
    }
    input {
      text-align: center;
      font-size: 18px;
    }
  `,
};

type FormData = {
  quantity: number | string;
};

type Props = {
  quantity: number;
  onChangeQuantity: (quantity: number) => void;
  disabled?: boolean;
};

export const QuantityInput = memo(({ quantity, onChangeQuantity, disabled }: Props) => {
  const form = useForm<FormData>({ defaultValues: { quantity } });

  const {
    field: { value, onChange },
  } = useController<FormData>({ name: 'quantity', control: form.control });

  const handleQuantityChange = useCallback(
    async (newValue: string | number) => {
      const newQuantity = Number(newValue);

      if (!newQuantity) {
        return;
      }

      onChangeQuantity(newQuantity);
    },
    [onChangeQuantity]
  );

  const handleMinusClick = useCallback(() => {
    const newQuantity = Number(value) - 1;
    onChange(newQuantity);
    handleQuantityChange(newQuantity);
  }, [handleQuantityChange, onChange, value]);

  const handlePlusClick = useCallback(() => {
    const newQuantity = Number(value) + 1;
    onChange(newQuantity);
    handleQuantityChange(newQuantity);
  }, [handleQuantityChange, onChange, value]);

  const handleKeyPress = useCallback(
    (e: KeyboardEvent<Element>) => {
      if (e.key !== 'Enter') {
        return;
      }

      handleQuantityChange(value);
    },
    [value, handleQuantityChange]
  );

  const handleBlur = useCallback(() => {
    handleQuantityChange(value);
  }, [value, handleQuantityChange]);

  return (
    <Box display="flex" flexWrap="wrap" position="relative">
      <Box display="flex" mb={2}>
        <Box height={10} width={10} mt={2}>
          <Button
            leadingIcon="remove"
            onClick={handleMinusClick}
            variant="text"
            disabled={disabled || value === 1}
            data-testid="minus"
          />
        </Box>

        <Box mx={1}>
          <HookForm form={form}>
            <FormTextField
              name="quantity"
              type="number"
              disabled={disabled}
              min={1}
              max={MAX_QUANTITY}
              precision={0}
              height={10}
              onKeyDown={handleKeyPress}
              onBlur={handleBlur}
              width={12}
              css={styles.textInput}
            />
          </HookForm>
        </Box>

        <Box height={10} width={10} mt={2}>
          <Button
            leadingIcon="add"
            onClick={handlePlusClick}
            variant="text"
            disabled={disabled || value === MAX_QUANTITY}
            data-testid="plus"
          />
        </Box>
      </Box>
    </Box>
  );
});
