import { useMedia, useSnackbar } from '@partstech/ui';
import { plural } from '@partstech/ui/utils';
import { useCallback, useState } from 'react';
import type { CartOrderItem } from 'models';

export const useUndoableRemoving = () => {
  const { isMobile } = useMedia();
  const { addSnackbar } = useSnackbar();

  const [removingOrderItemIds, setRemovingOrderItemIds] = useState<string[]>([]);

  const startRemoving = useCallback(
    (orderItem: CartOrderItem, onTimeout: () => void) => {
      setRemovingOrderItemIds((prev) => [...prev, orderItem.id]);

      addSnackbar({
        label: `You removed ${orderItem.quantity} ${plural('item', orderItem.quantity)} (${orderItem.partNumber}) from your cart`,
        button: 'Undo',
        closeOnAction: true,
        onClick: () => {
          setRemovingOrderItemIds((prev) => prev.filter((id) => id !== orderItem.id));
        },
        maxCount: null,
        fullWidth: isMobile,
        onTimeout,
        position: { vertical: 'bottom', horizontal: 'middle', offsetY: isMobile ? 0 : 4 },
        duration: 4,
      });
    },
    [addSnackbar, isMobile]
  );

  const onRemoved = useCallback((orderItemId: string) => {
    setRemovingOrderItemIds((prev) => prev.filter((id) => id !== orderItemId));
  }, []);

  return {
    removingOrderItemIds,
    startRemoving,
    onRemoved,
  };
};
