import { useCallback } from 'react';
import { useAppNavigate } from 'app/AppRouter/useAppNavigate';
import { isSearchSequenceCreator, useSearchSequence } from 'features/searchSequence';
import { useLaunchDarkly } from 'integrations/launchDarkly';
import { Diagram, UniversalPartType } from 'models';
import { getDiagramUrl } from 'utils/url/getDiagramUrl';
import { isSearchQueryConvertable, SearchUrl } from '../models';
import type { SearchEntryPointInterface, SearchSequenceQuery, SupplierQuery } from 'features/searchForm';
import type { Vehicle } from 'models';

export const useStartSearch = ({ onVehicleRequired }: { onVehicleRequired?: () => void } = {}) => {
  const navigate = useAppNavigate();

  const {
    flags: { fluidsChemicalsTab },
  } = useLaunchDarkly();

  const { startSearchSequence } = useSearchSequence();

  const tryNavigateToSearch = useCallback(
    (searchRequest: SearchUrl) => {
      try {
        navigate(searchRequest.getUrl(!fluidsChemicalsTab));
      } catch (e) {
        onVehicleRequired && onVehicleRequired();
      }
    },
    [fluidsChemicalsTab, navigate, onVehicleRequired]
  );

  const navigateToDiagram = useCallback(
    (diagram: Diagram, vehicle: Vehicle | null) => {
      if (!diagram.category?.id || vehicle === null) {
        return;
      }

      navigate(getDiagramUrl(vehicle.id, diagram.category?.id, diagram.id));
    },
    [navigate]
  );

  return useCallback(
    (
      searchEntry: SearchEntryPointInterface | null,
      vehicle: Vehicle | null,
      preservedQuery?: Partial<SupplierQuery> & Partial<SearchSequenceQuery>
    ) => {
      if (!searchEntry) {
        return;
      }

      if (
        searchEntry instanceof UniversalPartType &&
        searchEntry.shouldPickAttributes(Boolean(vehicle)) &&
        fluidsChemicalsTab
      ) {
        return;
      }

      if (searchEntry.isVehicleRequired?.() && vehicle === null) {
        onVehicleRequired && onVehicleRequired();
        return;
      }

      if (isSearchQueryConvertable(searchEntry)) {
        tryNavigateToSearch(new SearchUrl(vehicle, searchEntry, preservedQuery));
        return;
      }

      if (isSearchSequenceCreator(searchEntry)) {
        startSearchSequence(searchEntry, vehicle);
        return;
      }

      if (searchEntry instanceof Diagram) {
        navigateToDiagram(searchEntry, vehicle);
      }
    },
    [fluidsChemicalsTab, navigateToDiagram, onVehicleRequired, startSearchSequence, tryNavigateToSearch]
  );
};
