import { useCallback } from 'react';
import { useAppLocation } from 'app/AppRouter/useAppLocation';
import { Routes } from 'constant';
import { parseQuery } from 'shared/lib/query';
import { generateUrl } from 'utils/url/generateUrl';
import { useAppNavigate } from './useAppNavigate';
import type { AppNavigateOptions } from './useAppNavigate';
import type { HashRoutes } from 'constant';
import type { Path } from 'react-router-dom';
import type { QueryParams } from 'types/queryParams';

type GenerateUrlParams = Parameters<typeof generateUrl>;

export const useHashNavigate = () => {
  const appNavigate = useAppNavigate();

  const location = useAppLocation();

  const navigate = useCallback(
    (to: Partial<Path>, options?: AppNavigateOptions) => appNavigate({ ...location, ...to }, options),
    [appNavigate, location]
  );

  const generateHashUrl = useCallback(
    ({
      path,
      hash,
      params,
      search,
    }: {
      path?: GenerateUrlParams[0];
      params?: GenerateUrlParams[1];
      search?: GenerateUrlParams[2];
      hash: HashRoutes;
    }): string => {
      const pathname = path ?? (location.pathname as Routes) ?? Routes.DASHBOARD;

      const cleanedHash = hash.replace('#', '');

      return generateUrl(
        pathname,
        params,
        parseQuery<Record<string, QueryParams>>(location.search) ?? search,
        cleanedHash
      );
    },
    [location.pathname, location.search]
  );

  return { navigate, generateHashUrl };
};
