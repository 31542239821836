/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../../../shared/api/graphql/graphql';

export type UpdateTireQuoteMutationVariables = Types.Exact<{
  input: Types.UpdateTireQuoteInput;
}>;

export type UpdateTireQuoteMutation = {
  __typename?: 'Mutation';
  updateTireQuote?: { __typename?: 'UpdateTireQuotePayload'; hash: string } | null;
};

export const UpdateTireQuoteDocument = `
    mutation UpdateTireQuote($input: UpdateTireQuoteInput!) {
  updateTireQuote(input: $input) {
    hash
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    UpdateTireQuote: build.mutation<UpdateTireQuoteMutation, UpdateTireQuoteMutationVariables>({
      query: (variables) => ({ document: UpdateTireQuoteDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUpdateTireQuoteMutation } = injectedRtkApi;
