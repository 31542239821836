import type { PartType } from './PartType';
import type {
  SearchEntryPointInterface,
  SearchQueryConvertable,
  PartTypeQuery,
  SearchRequestConvertable,
  SearchCatalogRequestConvertable,
  ToRequestParams,
  ToCatalogRequestParams,
} from 'features/searchForm';
import type { CatalogSearchInput, SearchInput } from 'shared/api';

export class LegacyJob
  implements
    SearchEntryPointInterface,
    SearchQueryConvertable,
    SearchRequestConvertable,
    SearchCatalogRequestConvertable
{
  id: string;

  name: string;

  aliases: string[];

  partTypes: PartType[];

  constructor(legacyJob: Pick<LegacyJob, 'id' | 'name' | 'aliases' | 'partTypes'>) {
    this.id = legacyJob.id;
    this.name = legacyJob.name;
    this.aliases = legacyJob.aliases;
    this.partTypes = legacyJob.partTypes ?? [];
  }

  toString() {
    return this.name;
  }

  getColumnTitle(): string {
    return 'part types';
  }

  isVehicleRequired(): boolean {
    return true;
  }

  populateInput(): string {
    return this.name;
  }

  toQuery(): PartTypeQuery {
    return {
      part_text_id: this.id,
      part_text: this.name,
    };
  }

  static isLegacyJobId(id: string): boolean {
    return id.startsWith('j_');
  }

  toRequest({ accountId, attributes, vehicleId, vin, universal }: ToRequestParams): SearchInput {
    return {
      partTypeAttribute: {
        accountId,
        attributes,
        partTypeIds: this.partTypes.map((partType) => partType.id),
        universal: universal ? true : undefined,
        vehicleId,
        vin,
      },
    };
  }

  toCatalogRequest({ attributes, vehicleId, vin, fit }: ToCatalogRequestParams): CatalogSearchInput {
    return {
      partTypeAttribute: {
        attributes,
        partTypeIds: this.partTypes.map((partType) => partType.id),
        universal: fit ? undefined : true,
        vehicleId,
        vin,
      },
    };
  }
}
