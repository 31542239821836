import { useEffect } from 'react';
import { useMutationStatus } from 'shared/api';
import type { FieldValues, Path, UseFormReturn } from '@partstech/ui/forms';
import type { MutationStatusArgs } from 'shared/api';

type Args<FormData extends FieldValues> = MutationStatusArgs & {
  form: UseFormReturn<FormData>;
};

/**  @deprecated -> use useFormMutationCallback */
export const useFormStatus = <FormData extends FieldValues>({ form, ...args }: Args<FormData>) => {
  const { setError } = form;
  const { validationErrors } = useMutationStatus(args);

  useEffect(() => {
    if (validationErrors.length) {
      validationErrors.forEach((validationError) => {
        const { path } = validationError;
        const fieldName = (path && path.length > 3 ? path.slice(2).join('.') : path?.[2]) as Path<FormData>;

        setError(fieldName ?? 'root', validationError);
      });
    }
  }, [validationErrors, setError]);
};
