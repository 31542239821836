import { useCallback } from 'react';
import { useChangePasswordConfirmationMutation } from './ChangePasswordConfirmation.generated';
import type { ChangePasswordConfirmationInput } from 'shared/api';

export const useChangePasswordConfirmation = () => {
  const [changePasswordConfirmation, status] = useChangePasswordConfirmationMutation();

  const handleChangePasswordConfirmation = useCallback(
    (input: ChangePasswordConfirmationInput) => changePasswordConfirmation({ input }),
    [changePasswordConfirmation]
  );

  return [handleChangePasswordConfirmation, status] as const;
};
