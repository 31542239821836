/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type DeleteActiveCartItemsMutationVariables = Types.Exact<{
  itemIds: Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input'];
}>;

export type DeleteActiveCartItemsMutation = {
  __typename?: 'Mutation';
  deleteActiveCartItems?:
    | { __typename?: 'DeleteActiveCartItemsErrorPayload'; errorMessage: Types.Scalars['String']['input'] }
    | { __typename?: 'DeleteActiveCartItemsSuccessPayload'; deletedItemIds: Array<string> }
    | null;
};

export const DeleteActiveCartItemsDocument = `
    mutation DeleteActiveCartItems($itemIds: [ID!]!) {
  deleteActiveCartItems(input: {itemIds: $itemIds}) {
    ... on DeleteActiveCartItemsErrorPayload {
      errorMessage
    }
    ... on DeleteActiveCartItemsSuccessPayload {
      deletedItemIds
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    DeleteActiveCartItems: build.mutation<DeleteActiveCartItemsMutation, DeleteActiveCartItemsMutationVariables>({
      query: (variables) => ({ document: DeleteActiveCartItemsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useDeleteActiveCartItemsMutation } = injectedRtkApi;
