/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../shared/api/graphql/graphql';

export type GetDependentAttributeQueryVariables = Types.Exact<{
  ids?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
  attributeName: Types.Scalars['String']['input'];
  previousAttributes: Array<Types.PreviousAttribute> | Types.PreviousAttribute;
  vehicleId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;

export type GetDependentAttributeQuery = {
  __typename?: 'Query';
  partTypes?: Array<{
    __typename?: 'PartType';
    dependentAttribute?: {
      __typename?: 'PartTypeAttribute';
      name: Types.Scalars['String']['input'];
      values?: Array<Types.Scalars['String']['input']> | null;
    } | null;
  }> | null;
};

export const GetDependentAttributeDocument = `
    query GetDependentAttribute($ids: [ID!], $attributeName: String!, $previousAttributes: [PreviousAttribute!]!, $vehicleId: ID) {
  partTypes(IDs: $ids) {
    dependentAttribute(
      attributeName: $attributeName
      previousAttributes: $previousAttributes
      vehicleID: $vehicleId
    ) {
      name
      values
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetDependentAttribute: build.query<GetDependentAttributeQuery, GetDependentAttributeQueryVariables>({
      query: (variables) => ({ document: GetDependentAttributeDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetDependentAttributeQuery, useLazyGetDependentAttributeQuery } = injectedRtkApi;
