import { stringify } from 'qs';
import { generatePath } from 'react-router-dom';
import type { Routes } from 'constant';
import type { QueryParams } from 'types/queryParams';

export const generateUrl = <Path extends Routes>(
  path: Parameters<typeof generatePath<Path>>[0],
  params?: Parameters<typeof generatePath<Path>>[1],
  search?: QueryParams,
  hash?: string
): string => {
  let link = generatePath(path, params);

  const encodedSearchParams = stringify(search, { encode: true, arrayFormat: 'brackets' });

  if (encodedSearchParams) {
    link += `?${encodedSearchParams}`;
  }

  if (hash) {
    link += `#${hash}`;
  }

  return link;
};
