import { Box, GraphicButton, Typography } from '@partstech/ui';

type Props = {
  isActive: boolean;
  isDisabled: boolean;
  reason: string;
  onClick: () => void;
};

export const ReasonButton = ({ isActive, isDisabled, reason, onClick }: Props) => (
  <GraphicButton
    cardHeight={26}
    cardWidth={32}
    display="flex"
    justifyContent="center"
    alignItems="center"
    disabled={!isActive && isDisabled}
    isActive={isActive}
    onClick={onClick}
    data-testid="reasonButton"
  >
    <Box width={25}>
      <Typography variant="caption">{reason.split('/').join('/ ')}</Typography>
    </Box>
  </GraphicButton>
);
