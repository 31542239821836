import { Box, Link, Typography } from '@partstech/ui';
import { StepTitle } from 'features/signUp';
import { GoogleMapsProvider, GooglePlacesSearch } from 'integrations/google';
import type { DetailsByPlate } from 'integrations/google';
import type { MouseEvent } from 'react';

type Props = {
  onChangeShopName: (value: string) => void;
  onClose: (e: MouseEvent) => void;
  onShopSelect: (values: DetailsByPlate) => void;
};

export const ShopSearch = ({ onChangeShopName, onClose, onShopSelect }: Props) => (
  <Box p={{ sm: 4 }} textAlign={{ sm: 'center', md: 'left' }}>
    <StepTitle>Let's find your shop</StepTitle>

    <Box mt={2}>
      <Typography variant="bodyText2" color="subtleText" component="span">
        Type in your shop name below. If you can't find it, enter your business information
      </Typography>{' '}
      <Link
        to="#"
        variant="bodyText2"
        color="linkText"
        underline="hover"
        onClick={onClose}
        data-testid="createShopLink"
      >
        here.
      </Link>
    </Box>

    <Box mt={4} width={{ sm: '100%', md: 125 }}>
      <GoogleMapsProvider>
        <GooglePlacesSearch
          placeholder="Find your shop"
          onSelect={onShopSelect}
          onChangeInputValue={onChangeShopName}
        />
      </GoogleMapsProvider>
    </Box>
  </Box>
);
