import { css } from '@emotion/react';
import { Box, TextCut, Typography } from '@partstech/ui';
import { useCallback } from 'react';
import { VehicleString } from 'components/VehicleString';
import type { Theme } from '@partstech/ui';
import type { RecentSearchInterface } from 'features/recentSearches';

type Props = {
  recentSearch: RecentSearchInterface;
  onSelect: (recentSearch: RecentSearchInterface) => void;
};

const styles = {
  root: (theme: Theme) => css`
    display: block;
    width: 100%;
    padding: ${theme.sizing(5, 5, 4, 5)};
    color: ${theme.color.dark};
    text-align: left;
    border-top: 1px solid ${theme.color.mono17};
    cursor: pointer;

    @media (hover), (-ms-high-contrast: none) {
      &:hover {
        background: ${theme.color.mono2};
      }
    }

    &:first-of-type {
      border-top: none;
    }

    ${theme.breakpoints.down('sm')} {
      background: none;

      &:first-of-type {
        padding: ${theme.sizing(0, 5, 4, 5)};
      }
    }
  `,
  uppercase: css`
    text-transform: uppercase;
  `,
};

export const RecentSearchItem = ({ recentSearch, onSelect }: Props) => {
  const handleClick = useCallback(() => {
    onSelect(recentSearch);
  }, [onSelect, recentSearch]);

  return (
    <div
      css={styles.root}
      onClick={handleClick}
      onKeyDown={undefined}
      data-testid="recentSearch"
      role="menuitem"
      tabIndex={-1}
    >
      <Typography component="div" variant="overline" css={styles.uppercase}>
        {recentSearch.type}
      </Typography>
      <Box mt={2}>
        <Typography variant="subtitle1">
          <TextCut linesCount={2}>{recentSearch.toString()}</TextCut>
        </Typography>
      </Box>

      {recentSearch.vehicle && (
        <Box pr={10} mt={3} position="relative">
          <VehicleString vehicles={[recentSearch.vehicle]} multiline />

          {recentSearch.vin && !recentSearch.state && !recentSearch.plate && (
            <Box mt={1} ml={8}>
              <Typography variant="caption" color="mono56" css={styles.uppercase}>
                vin: {recentSearch.vin}
              </Typography>
            </Box>
          )}
          {recentSearch.state && recentSearch.plate && (
            <Box mt={1} ml={8}>
              <Typography variant="caption" color="mono56" css={styles.uppercase}>
                plate: {recentSearch.state} {recentSearch.plate}
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </div>
  );
};
