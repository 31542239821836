import { Box, Skeleton, Card, useMedia } from '@partstech/ui';
import { memo } from 'react';

export const Loader = memo(() => {
  const { isMobile } = useMedia();

  return (
    <Card display="flex" gap={1} height={17.5} alignItems="center" p={3} data-testid="cardLoader">
      <Box>
        <Skeleton height="40" width={isMobile ? '60' : '80'} />
      </Box>

      <Box mt={1}>
        <Skeleton height="16" width="30%" />

        <Skeleton height="12" width="90%" />
      </Box>

      <Box display="flex" alignItems="center" gap={1}>
        <Skeleton height="32" width="32" />
      </Box>
    </Card>
  );
});
