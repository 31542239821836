import { useCallback } from 'react';
import { useMutationStatus } from 'shared/api';
import { useCheckDuplicatesMutation } from './CheckDuplicates.generated';
import type { CheckDuplicatesInput } from 'shared/api';

export const useCheckDuplicates = () => {
  const [checkDuplicates, status] = useCheckDuplicatesMutation();

  const handleCheckDuplicates = useCallback(
    async (input: CheckDuplicatesInput) => {
      const response = await checkDuplicates({ input });

      if ('error' in response) {
        return null;
      }

      return response.data.checkDuplicates?.hasDuplicates ?? null;
    },
    [checkDuplicates]
  );

  useMutationStatus({ status });

  return [handleCheckDuplicates, status] as const;
};
