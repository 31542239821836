/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type DeleteStockOrderMutationVariables = Types.Exact<{ [key: string]: never }>;

export type DeleteStockOrderMutation = {
  __typename?: 'Mutation';
  deleteStockOrder?: { __typename?: 'DeleteStockOrderPayload'; success: Types.Scalars['Boolean']['input'] } | null;
};

export const DeleteStockOrderDocument = `
    mutation DeleteStockOrder {
  deleteStockOrder {
    success
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    DeleteStockOrder: build.mutation<DeleteStockOrderMutation, DeleteStockOrderMutationVariables | void>({
      query: (variables) => ({ document: DeleteStockOrderDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useDeleteStockOrderMutation } = injectedRtkApi;
