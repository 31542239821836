import { api } from './GetSubscriptionProducts.generated';
import type { GetSubscriptionProductsQuery } from './GetSubscriptionProducts.generated';
import type { SubscriptionProduct } from 'shared/api';

const emptyProducts: SubscriptionProduct[] = [];

const enhancedApi = api.enhanceEndpoints({
  addTagTypes: ['SubscriptionProduct'],
  endpoints: {
    GetSubscriptionProducts: {
      providesTags: (response: GetSubscriptionProductsQuery | undefined) => {
        const subscriptionProducts = response?.currentUser?.activeMember?.shop?.subscriptionProducts;

        if (!subscriptionProducts) {
          return [];
        }

        const products =
          subscriptionProducts?.map((product) => ({
            type: 'SubscriptionProduct' as const,
            id: product.alias,
          })) ?? [];

        return ['SubscriptionProduct', ...products];
      },
    },
  },
});

const { useGetSubscriptionProductsQuery } = enhancedApi;

export const useGetSubscriptionProducts = (skip: boolean = false) => {
  const { data, isLoading } = useGetSubscriptionProductsQuery(undefined, { skip });

  const products = data?.currentUser?.activeMember?.shop?.subscriptionProducts ?? emptyProducts;

  return {
    isLoading,
    products,
  };
};
