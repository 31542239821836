import { useForm } from '@partstech/ui/forms';
import { useCallback } from 'react';
import { useFormStatus } from 'shared/lib/form';
import { useChangePassword } from '../../../api/changePassword';

type FormData = {
  currentPassword: string;
  newPassword: string;
  repeatPassword: string;
};

const defaultValues: FormData = {
  currentPassword: '',
  newPassword: '',
  repeatPassword: '',
};

type Args = {
  showCurrentPasswordField?: boolean;
  onSubmit: (newPassword: string) => void;
};

export const useChangePasswordForm = ({ showCurrentPasswordField, onSubmit }: Args) => {
  const [changePassword, status] = useChangePassword();
  const { isLoading } = status;

  const form = useForm<FormData>({ defaultValues });
  const { watch, formState, getValues } = form;
  const { dirtyFields } = formState;

  const formValues = watch();

  const handleSuccessForm = useCallback(() => {
    onSubmit(getValues('newPassword'));
  }, [getValues, onSubmit]);

  const submitForm = useCallback(
    async (values: FormData) => {
      changePassword({ currentPassword: showCurrentPasswordField ? values.currentPassword : null });
    },
    [changePassword, showCurrentPasswordField]
  );

  useFormStatus({ status, form, onSuccess: handleSuccessForm });

  return {
    form,
    formValues,
    submitForm,
    isLoading,
    isDisabled: isLoading || !dirtyFields.newPassword || !dirtyFields.repeatPassword,
  };
};
