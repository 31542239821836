import { useCallback } from 'react';
import { useCreateShopContext } from 'components/Layout/SignUpLayout/CreateShopContext/CreateShopContext';
import { useStepProgress } from 'features/signUp';
import { OnboardStepType } from 'shared/api';

export const useSelectShopType = () => {
  const { shopType } = useCreateShopContext();

  const { isLoaded, isStepCompleted, selectOption } = useStepProgress({ step: OnboardStepType.ShopType });

  const selectShopType = useCallback(() => {
    if (shopType) {
      selectOption(shopType);
    }
  }, [shopType, selectOption]);

  return {
    isLoaded,
    isStepCompleted,
    shopType,
    selectShopType,
  };
};
