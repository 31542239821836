import { api as savedCartApi } from '../../queries/GetSavedCart.generated';
import { api as generatedApi } from './UpdateCartItemQuantity.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    UpdateCartItemQuantity: {
      invalidatesTags: (data) => {
        const res = data?.updateCartItemQuantity;
        if (res && 'cart' in res && res.cart?.active) {
          return ['ActiveCart'];
        }
        return [];
      },
      onQueryStarted: async (params, { dispatch, queryFulfilled }) => {
        try {
          const {
            data: { updateCartItemQuantity: res },
          } = await queryFulfilled;

          dispatch(
            savedCartApi.util.updateQueryData('GetSavedCart', { id: params.input.cartId }, (draft) => {
              draft.cart?.orders?.some((order) =>
                order.items?.some((part) => {
                  if (params.input.itemId === part.id && part.builtItem) {
                    if (res && 'cart' in res) {
                      Object.assign(part, { quantity: params.input.quantity });
                      Object.assign(part.builtItem, { errors: [] });
                    }

                    if (res && 'error' in res) {
                      Object.assign(part.builtItem, { errors: [{ message: res.errorMessage }] });
                    }

                    return true;
                  }
                  return false;
                })
              );
            })
          );
        } catch {
          dispatch(
            savedCartApi.util.updateQueryData('GetSavedCart', { id: params.input.cartId }, (draft) => {
              draft.cart?.orders?.some((order) =>
                order.items?.some((part) => {
                  if (params.input.itemId === part.id && part.builtItem) {
                    Object.assign(part.builtItem, { errors: [{ message: 'Something went wrong' }] });
                    return true;
                  }
                  return false;
                })
              );
            })
          );
        }
      },
    },
  },
});

export const { useUpdateCartItemQuantityMutation: useUpdateCartItemQuantity } = api;
