import { useSessionStorage } from '@partstech/ui/hooks';
import { SessionStorageKeys } from 'constant';

export const useIsHiddenSignUpStepper = () => {
  const [isHiddenSignUpStepper, setIsHiddenSignUpStepper] = useSessionStorage<boolean>(
    SessionStorageKeys.HIDDEN_SIGNUP_STEPPER
  );

  return { isHiddenSignUpStepper: isHiddenSignUpStepper ?? false, setIsHiddenSignUpStepper };
};
