import { sortUniq } from '@partstech/ui/utils';
import { useMemo } from 'react';
import { useGetVehiclesByYearMake } from '../../../api';
import { filterParametersBySearch } from '../../../utils';
import type { CommonHookProps } from '../types';

export const useGetEngines = ({
  year,
  makeId,
  modelId,
  submodelId,
  searchValue,
}: Omit<CommonHookProps, 'engineId'>) => {
  const { vehicles } = useGetVehiclesByYearMake(Number(year) ?? null, makeId);

  const byMakeModel = useMemo(() => vehicles.filter((vehicle) => vehicle.model.id === modelId), [vehicles, modelId]);

  const byMakeModelSubModel = useMemo(
    () => byMakeModel.filter((vehicle) => vehicle.subModel.id === submodelId),
    [byMakeModel, submodelId]
  );

  const rawEngines = useMemo(
    () =>
      sortUniq(
        byMakeModelSubModel.map((vehicle) => vehicle.engine),
        'name'
      ),
    [byMakeModelSubModel]
  );

  const engines = filterParametersBySearch(rawEngines, searchValue);

  return { engines };
};
