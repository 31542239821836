import { Box, Skeleton, Divider } from '@partstech/ui';

export const Loader = () => (
  <>
    {Array.from({ length: 5 }).map((_, index) => (
      <Box key={index} p={3} pl={4}>
        <Box height={11}>
          <Skeleton width="50%" height="16px" />

          <Skeleton width="80%" height="14px" />
        </Box>
      </Box>
    ))}

    <Box mt={2}>
      <Divider />
      <Box display="flex" alignItems="center" justifyContent="center" height={13}>
        <Skeleton width="40%" height="16px" />
      </Box>
    </Box>
  </>
);
