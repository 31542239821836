import { ProductType } from 'shared/api';
import { api as generatedApi } from './GetCustomPreferredBrands.generated';
import type { GetCustomPreferredBrandsQuery } from './GetCustomPreferredBrands.generated';
import type { CustomPreferredBrand } from 'shared/api';
import type { BrandHookType, PreferredCustomBrand } from 'types/preferredBrand';

const api = generatedApi.enhanceEndpoints({
  addTagTypes: ['CustomPreferredBrand'],
  endpoints: {
    GetCustomPreferredBrands: {
      providesTags: (response: GetCustomPreferredBrandsQuery | undefined) => {
        const shop = response?.currentUser?.activeMember?.shop;

        if (!shop) {
          return [];
        }

        const preferredBrands =
          shop.customPreferredBrands?.map((brand) => ({
            type: 'CustomPreferredBrand' as const,
            id: brand.brand?.id,
          })) ?? [];

        return ['CustomPreferredBrand', ...preferredBrands];
      },
    },
  },
});

const { useGetCustomPreferredBrandsQuery } = api;

const emptyValue: CustomPreferredBrand[] = [];

export const useGetCustomPreferredBrands = (type: BrandHookType, skip: boolean) => {
  const { data, ...props } = useGetCustomPreferredBrandsQuery({ partType: ProductType[type] }, { skip });

  const preferredBrands = data?.currentUser?.activeMember?.shop?.customPreferredBrands ?? emptyValue;

  const filteredAndMappedPreferredBrands: PreferredCustomBrand[] = preferredBrands
    .filter((brand) => brand.brand?.id && brand.brand?.name)
    .map((brand) => ({
      id: brand.brand!.id,
      name: brand.brand!.name,
      priority: brand.priority,
      matchingBrandsIds: brand.brands?.map(({ id }) => id) ?? [],
    }));

  return { brands: filteredAndMappedPreferredBrands, ...props };
};
