import { useSnackbar, useMedia } from '@partstech/ui';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Routes } from 'constant';
import { getSavedQuotesUrl } from 'utils/url';
import { useSearchBar } from 'widgets/searchBar';
import { useCreateCart } from '../api/mutations';
import { useStartNewQuoteConfirmModal } from './useStartNewQuoteConfirmModal';

type Props = {
  withConfirmation: boolean;
};

export const useStartNewQuote = ({ withConfirmation }: Props) => {
  const navigate = useNavigate();
  const [createCart] = useCreateCart();
  const { addSnackbar } = useSnackbar();
  const { resetSearch } = useSearchBar();
  const { isMobile } = useMedia();

  const createNewQuote = useCallback(() => {
    createCart({ input: {} });

    resetSearch();

    navigate(Routes.DASHBOARD);

    addSnackbar({
      label: 'Your cart has been saved.',
      button: 'View',
      buttonColor: 'primaryLight',
      fullWidth: isMobile,
      onClick: () => navigate(getSavedQuotesUrl('carts')),
    });
  }, [createCart, navigate, addSnackbar, isMobile, resetSearch]);

  const { open } = useStartNewQuoteConfirmModal({ onConfirm: createNewQuote });

  const startNewQuote = useCallback(() => {
    if (withConfirmation) {
      open();
    } else {
      createNewQuote();
    }
  }, [createNewQuote, open, withConfirmation]);

  return {
    onSubmit: startNewQuote,
  };
};
