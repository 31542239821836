import { Specification } from '../../../Specification';
import { AttributeTooltip } from '../AttributeTooltip';
import { TireSizeTranslation } from '../TireSizeTranslation';
import type { ProductAttribute } from 'types/product';

const emptyAttributes: ProductAttribute[] = [];

type Props = {
  attribute: ProductAttribute;
};

export const TireSizeAttribute = ({ attribute }: Props) => (
  <Specification
    label={attribute.label}
    value={attribute.value}
    adornmentAfter={
      <AttributeTooltip tooltip={<TireSizeTranslation attributes={attribute.children || emptyAttributes} />} />
    }
  />
);
