import { ModalView } from '@partstech/ui';
import { MembershipsMenu } from '../MembershipsMenu';
import type { ModalProps } from '@partstech/ui';
import type { ShopMembership, SupplierMembership } from 'models';

type Props = {
  shopMemberships: ShopMembership[];
  supplierMemberships: SupplierMembership[];
} & ModalProps;

export const MembershipsModal = ({ shopMemberships, supplierMemberships, onClose }: Props) => (
  <ModalView title="Shop locations" closeIcon="arrow_back" onClose={onClose} headerDivider={false}>
    <MembershipsMenu shopMemberships={shopMemberships} supplierMemberships={supplierMemberships} />
  </ModalView>
);
