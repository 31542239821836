/**
 * Used to match `RegExp`
 * [syntax characters](http://ecma-international.org/ecma-262/7.0/#sec-patterns).
 */
const reRegExpChar = /[\\^$.*+?()[\]{}|]/g;
const reHasRegExpChar = RegExp(reRegExpChar.source);

/**
 * Escapes the `RegExp` special characters "^", "$", "\", ".", "*", "+",
 * "?", "(", ")", "[", "]", "{", "}", and "|" in `string`.
 */
const escapeRegExp = (string: string) =>
  string && reHasRegExpChar.test(string) ? string.replace(reRegExpChar, '\\$&') : string || '';

export const isMatchingString = (comparable: string, compareBy: string) => {
  const normalize = (str: string) => str.replace(/[-\s]/g, '');

  const normalizedComparable = normalize(comparable);
  const normalizedCompareBy = normalize(compareBy);

  const escapedCompareBy = escapeRegExp(normalizedCompareBy);

  const regCompareBy = new RegExp(escapedCompareBy, 'i');
  return regCompareBy.test(normalizedComparable);
};
