import { api as generatedApi } from './UpdateTaxRate.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    UpdateTaxRate: {
      invalidatesTags: ['TaxRate', 'ActiveCart'],
    },
  },
});

export const { useUpdateTaxRateMutation } = api;
