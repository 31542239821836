import { useTheme } from '@emotion/react';
import { memo } from 'react';
import { AnimatedPath } from './AnimatedPath';
import { variants } from './variants';

type Props = {
  currentIndex: keyof typeof variants;
  nextIndex?: keyof typeof variants;
};

export const HexNutsAndHexagons = memo(({ currentIndex, nextIndex }: Props) => {
  const { color } = useTheme();

  const currentVariant = variants[currentIndex];
  const nextVariant = nextIndex ? variants[nextIndex] : null;

  return (
    <svg width={268} height={460} viewBox="0 0 268 460" fill="none" xmlns="http://www.w3.org/2000/svg">
      <AnimatedPath d={currentVariant.orange4} nextD={nextVariant?.orange4} opacity={0.7} fill={color.secondary} />

      <AnimatedPath d={currentVariant.orange3} nextD={nextVariant?.orange3} opacity={0.7} fill={color.secondary} />

      <AnimatedPath d={currentVariant.grey16} nextD={nextVariant?.grey16} opacity={0.75} fill={color.mono40} />

      <AnimatedPath
        d={currentVariant.grey15}
        nextD={nextVariant?.grey15}
        opacity={0.5}
        stroke={color.mono40}
        strokeWidth={2}
        strokeMiterlimit={10}
      />

      <AnimatedPath
        d={currentVariant.grey14}
        nextD={nextVariant?.grey14}
        opacity={0.5}
        stroke={color.mono40}
        strokeWidth={2}
        strokeMiterlimit={10}
      />

      <AnimatedPath
        d={currentVariant.grey13}
        nextD={nextVariant?.grey13}
        opacity={0.5}
        stroke={color.mono40}
        strokeWidth={2}
        strokeMiterlimit={10}
      />

      <AnimatedPath
        d={currentVariant.orange5}
        nextD={nextVariant?.orange5}
        opacity={0.5}
        stroke={color.secondary}
        strokeMiterlimit={10}
      />

      <AnimatedPath
        d={currentVariant.grey11}
        nextD={nextVariant?.grey11}
        opacity={0.5}
        stroke={color.mono40}
        strokeMiterlimit={10}
      />

      <AnimatedPath d={currentVariant.grey10} nextD={nextVariant?.grey10} opacity={0.5} fill={color.mono40} />

      <AnimatedPath d={currentVariant.grey9} nextD={nextVariant?.grey9} opacity={0.6} fill={color.mono40} />

      <AnimatedPath
        d={currentVariant.grey8}
        nextD={nextVariant?.grey8}
        opacity={0.6}
        stroke={color.mono40}
        strokeWidth={5}
        strokeMiterlimit={10}
      />

      <AnimatedPath
        d={currentVariant.grey7}
        nextD={nextVariant?.grey7}
        opacity={0.6}
        stroke={color.mono40}
        strokeWidth={2}
        strokeMiterlimit={10}
      />

      <AnimatedPath
        d={currentVariant.grey6}
        nextD={nextVariant?.grey6}
        opacity={0.6}
        stroke={color.mono40}
        strokeMiterlimit={10}
      />

      <AnimatedPath
        d={currentVariant.grey5}
        nextD={nextVariant?.grey5}
        opacity={0.6}
        stroke={color.mono40}
        strokeWidth={6}
        strokeMiterlimit={10}
      />

      <AnimatedPath d={currentVariant.orange2} nextD={nextVariant?.orange2} opacity={0.7} fill={color.secondary} />

      <AnimatedPath d={currentVariant.orange1} nextD={nextVariant?.orange1} opacity={0.7} fill={color.secondary} />

      <AnimatedPath
        d={currentVariant.grey4}
        nextD={nextVariant?.grey4}
        opacity={0.5}
        stroke={color.mono40}
        strokeWidth={2}
        strokeMiterlimit={10}
      />

      <AnimatedPath
        d={currentVariant.grey3}
        nextD={nextVariant?.grey3}
        opacity={0.5}
        stroke={color.mono40}
        strokeWidth={2}
        strokeMiterlimit={10}
      />

      <AnimatedPath d={currentVariant.grey2} nextD={nextVariant?.grey2} opacity={0.5} fill={color.mono40} />

      <AnimatedPath d={currentVariant.grey1} nextD={nextVariant?.grey1} opacity={0.75} fill={color.mono40} />

      <AnimatedPath
        d={currentVariant.bighex}
        nextD={nextVariant?.bighex}
        opacity={0.08}
        stroke={color.mono40}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
    </svg>
  );
});
