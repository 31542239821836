import { Typography } from '@partstech/ui';
import { currency } from 'shared/lib/string';
import type { TypographyProps } from '@partstech/ui';

type Props = {
  label: string;
  price: number | null;
  color?: TypographyProps['color'];
  variant?: TypographyProps['variant'];
};

export const Price = ({ label, price, color = 'subtleText', variant = 'caption' }: Props) => {
  if (!price) {
    return null;
  }

  return (
    <Typography component="div" variant={variant} color={color}>
      {label}: {currency(price)}
    </Typography>
  );
};
