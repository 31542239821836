import { Box } from '@partstech/ui';
import { TireSize, TireConfiguration } from 'models';
import { MatchingTireConfigurations } from './MatchingTiresConfigurations';
import { TireSizeSelector } from './TireSizeSelector';

type Props = {
  onSelectVehicleClick?: () => void;
  onChange: (option: TireConfiguration) => void;
  options: Array<TireConfiguration | TireSize>;
  hasVehicle: boolean;
};

export const Tires = ({ onSelectVehicleClick, onChange, options, hasVehicle }: Props) => (
  <Box display="flex" flexDirection="column" data-testid="tiresEntry" gap={4} width="100%">
    <MatchingTireConfigurations
      options={options.filter((option): option is TireConfiguration => option instanceof TireConfiguration)}
      hasVehicle={hasVehicle}
      onSelect={onChange}
      onSelectVehicleClick={onSelectVehicleClick}
    />

    <TireSizeSelector
      options={options.filter((option): option is TireSize => option instanceof TireSize)}
      onSelect={onChange}
    />
  </Box>
);
