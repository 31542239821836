import { useOpen } from '@partstech/ui/hooks';
import { useCallback, useMemo } from 'react';
import { useGetManagementSystems } from 'features/shop/api/managementSystems';
import { useInputValue } from 'shared/lib/form';
import type { EntityManagementSystem } from 'features/shop/api/managementSystems';
import type { ChangeEvent } from 'react';

const sortByPriority = (a: EntityManagementSystem, b: EntityManagementSystem) =>
  (a.priority || Infinity) - (b.priority || Infinity);

type Args = {
  fullCardsSize: number;
  shortCardsSize: number;
};

export const useSearchManagementSystem = ({ fullCardsSize, shortCardsSize }: Args) => {
  const { value: searchValue, changeValue } = useInputValue('');
  const { isOpen: isViewMore, toggle: moreButtonClick } = useOpen();

  const { managementSystems, isLoading } = useGetManagementSystems();

  const managementSystemsBySearch = useMemo(
    () =>
      managementSystems
        .filter(
          (managementSystem) =>
            managementSystem.name.toLowerCase().includes(searchValue) ||
            managementSystem.synonyms?.toLowerCase().includes(searchValue)
        )
        .sort(sortByPriority),
    [managementSystems, searchValue]
  );

  const managementSystemsResult = useMemo(
    () => managementSystemsBySearch.slice(0, isViewMore ? fullCardsSize : shortCardsSize),
    [fullCardsSize, isViewMore, managementSystemsBySearch, shortCardsSize]
  );

  const changeSearchValue = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      changeValue(e.target.value.toLowerCase().trim());
    },
    [changeValue]
  );

  return {
    changeSearchValue,
    isLoading,
    isViewMore,
    managementSystems: managementSystemsResult,
    moreButtonClick,
    showMoreButton: managementSystemsBySearch.length > 9,
  };
};
