/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import type * as Types from '../../../../../shared/api/graphql/graphql';

export type StockOrderFragment = {
  __typename?: 'StockOrder';
  splitByLastPurchases: Types.Scalars['Boolean']['input'];
  store?: { __typename?: 'Store'; id: string } | null;
  supplier?: { __typename?: 'Supplier'; id: string } | null;
  parts: Array<{
    __typename?: 'StockOrderPart';
    lineCardId?: string | null;
    partNumber: Types.Scalars['String']['input'];
    partNumberId?: Types.Scalars['String']['input'] | null;
    quantity: number;
    selectedIndex?: number | null;
    account?: { __typename?: 'Account'; id: string } | null;
    brand?: { __typename?: 'Brand'; id: string; name: Types.Scalars['String']['input'] } | null;
    store?: { __typename?: 'Store'; id: string } | null;
    supplier?: { __typename?: 'Supplier'; id: string } | null;
  }>;
};

export const StockOrderFragmentDoc = `
    fragment StockOrder on StockOrder {
  splitByLastPurchases
  store {
    id
  }
  supplier {
    id
  }
  parts {
    account {
      id
    }
    brand {
      id
      name
    }
    lineCardId
    partNumber
    partNumberId
    quantity
    selectedIndex
    store {
      id
    }
    supplier {
      id
    }
  }
}
    `;
