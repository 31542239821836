/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../../../shared/api/graphql/graphql';

export type RemoveMatrixMutationVariables = Types.Exact<{
  input: Types.DeleteMatrixInput;
}>;

export type RemoveMatrixMutation = {
  __typename?: 'Mutation';
  deleteMatrix?: { __typename?: 'DeleteMatrixPayload'; id: string } | null;
};

export const RemoveMatrixDocument = `
    mutation RemoveMatrix($input: DeleteMatrixInput!) {
  deleteMatrix(input: $input) {
    id
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    RemoveMatrix: build.mutation<RemoveMatrixMutation, RemoveMatrixMutationVariables>({
      query: (variables) => ({ document: RemoveMatrixDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useRemoveMatrixMutation } = injectedRtkApi;
