import { css } from '@emotion/react';
import { Portal, WithClickAway, Card } from '@partstech/ui';
import { SupplierInformationContent } from 'components/SupplierSelector/SupplierTabs/SupplierInformationContent';
import type { Theme } from '@partstech/ui';
import type { SupplierAccount } from 'models';

type Props = {
  supplierAccount: SupplierAccount;
  onClose: () => void;
  rect: {
    left: number;
    top: number;
  };
};

const styles = {
  wrap: (rect: Props['rect']) => (theme: Theme) => css`
    position: absolute;
    width: ${theme.sizing(80)};
    padding-top: ${theme.sizing(2)};
    left: ${rect.left + 125}px;
    top: ${rect.top + 20 * theme.unit}px;
  `,
};

export const SupplierInformationFlyout = ({ supplierAccount, onClose, rect }: Props) => (
  <Portal group="supplier-information-modal">
    <WithClickAway onClickAway={onClose}>
      <div css={styles.wrap(rect)}>
        <Card elevation={2} p={4}>
          <SupplierInformationContent supplierAccount={supplierAccount} onClose={onClose} />
        </Card>
      </div>
    </WithClickAway>
  </Portal>
);
