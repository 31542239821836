import { css } from '@emotion/react';
import type { Theme } from '@partstech/ui';

const transitionProp = (prop: string) => `${prop} 0.3s ease-in-out`;

export const styles = {
  toggle: (theme: Theme) => css`
    position: relative;
    color: ${theme.color.dark};
    padding: ${theme.sizing(2.5, 2)};
    height: 100%;
    min-height: ${theme.sizing(10)};
    max-height: ${theme.sizing(14)};
    width: 100%;
    justify-content: flex-start;

    &:hover {
      color: ${theme.color.primary};

      & .icon {
        color: ${theme.color.primary};
      }
    }

    ${theme.breakpoints.up('md')} {
      padding: ${theme.sizing(1, 2)};
    }
    span:first-of-type:has(.icon) {
      margin-right: 4px;
    }

    ${theme.breakpoints.down('md')} {
      span:first-of-type:has(.icon) {
        min-height: 16px;
        min-width: 16px;
      }
    }
    span:nth-of-type(2) {
      overflow: hidden;
      display: flex;
      width: 100%;
      align-items: center;
    }
    span:last-of-type:has(.icon) {
      margin-left: auto;
      max-width: 100%;
    }
  `,
  vehicle: (theme: Theme) => css`
    transition: ${transitionProp('color')};

    &:hover {
      color: ${theme.color.primary};
    }

    ${theme.breakpoints.down('xl')} {
      text-transform: none;
    }
    &:is(div) > div {
      overflow: hidden;
    }
    &:is(div) > div > div {
      line-height: 16px;
      display: grid;
    }
    &:is(div) > a {
      line-height: 16px;
    }
  `,
  region: (theme: Theme) => css`
    position: relative;
    top: 1px;

    ${theme.breakpoints.between('md', 'xl')} {
      top: 0;
    }
  `,
  vin: (theme: Theme) => css`
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: ${theme.sizing(7)};

    ${theme.breakpoints.down('md')} {
      span {
        display: none;
      }
    }

    ${theme.breakpoints.between('md', 'xl')} {
      position: static;
      margin: ${theme.sizing(0, 0, 0, 5)};
    }
  `,
};
