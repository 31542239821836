import { Box, ModalView, Typography } from '@partstech/ui';
import icon from 'images/content/jobs/vehicle-specific-filled-icon.svg';
import type { ModalProps } from '@partstech/ui';

export const IntroducingModal = ({ onClose }: ModalProps) => (
  <ModalView onClose={onClose} title="New - Vehicle Specific Jobs">
    <Box mb={4} display="flex" gap={2} alignItems="center">
      <img src={icon} alt="Vehicle specific job icon" />
      <Typography variant="subtitle1">Introducing Vehicle Specific Jobs: The Smarter Way to Order Parts</Typography>
    </Box>

    <Box display="flex" flexDirection="column" gap={2}>
      <Typography variant="bodyText2">
        We're excited to introduce Vehicle Specific Jobs – a game-changing feature that eliminates guesswork from parts
        ordering.
      </Typography>

      <Typography variant="bodyText2">
        Unlike our current Popular Jobs feature, which offers a general list of parts for common repairs,{' '}
        <Typography variant="subtitle2" component="span">
          Vehicle Specific Jobs
        </Typography>{' '}
        tailors the parts list to your{' '}
        <Typography variant="subtitle2" component="span">
          exact vehicle
        </Typography>
        . This allows you to see the precise components needed to complete a repair on your{' '}
        <Typography variant="subtitle2" component="span">
          specific make and model
        </Typography>
        .
      </Typography>

      <Typography variant="bodyText2">With Vehicle Specific Jobs you will get:</Typography>

      <Box ml={6}>
        <Typography variant="bodyText2" component="ul">
          <li>Accurate parts lists for your specific vehicle's repair needs</li>
          <li>Clear distinction between essential parts and those that may vary by vehicle</li>
          <li>Increased confidence in ordering the right parts the first time</li>
        </Typography>
      </Box>

      <Typography variant="bodyText2">
        Empower your team to order precisely and confidently, regardless of their experience level.
      </Typography>

      <Typography variant="bodyText2">Start using Vehicle Specific Jobs now!</Typography>
    </Box>
  </ModalView>
);
