import type { PreferredBrandUnified } from 'types/preferredBrand';

export const isPreferredBrand =
  (preferredBrands: PreferredBrandUnified[]) => (brandId: string, brandName: string, partTypeId: string) =>
    preferredBrands.some((preferredBrand) => {
      if (preferredBrand.type === 'custom') {
        return preferredBrand.name === brandName || preferredBrand.matchingBrandsIds?.includes(brandId);
      }

      return (
        preferredBrand.name === brandName &&
        (preferredBrand.partTypeId === partTypeId || preferredBrand.linkedPartTypes?.includes(partTypeId ?? ''))
      );
    });
