import { api as activeCartApi } from 'features/cart/api/queries/GetActiveCart.generated';
import { useMutationStatus } from 'shared/api';
import { api as generatedApi } from './UpdateShippingMethod.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    UpdateShippingMethod: {
      onQueryStarted: async ({ shippingMethodCode, orderId }, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;

        if (data.updateActiveCartOrderShippingMethod && 'updatedOrderId' in data.updateActiveCartOrderShippingMethod) {
          dispatch(
            activeCartApi.util.updateQueryData('GetActiveCart', undefined, (draft) => {
              draft?.activeCart?.orders?.forEach((order) => {
                if (order.id === orderId && order.builtOrder?.selectedFrightTermService) {
                  Object.assign(order.builtOrder.selectedFrightTermService, { methodCode: shippingMethodCode });
                }
              });
            })
          );
        }
      },
    },
  },
});

const { useUpdateShippingMethodMutation } = api;

export const useUpdateShippingMethod = () => {
  const [updateShipping, status] = useUpdateShippingMethodMutation();

  useMutationStatus({ status });

  return [updateShipping, status] as const;
};
