import { css } from '@emotion/react';
import { Icon, Link, Button, Result } from '@partstech/ui';
import { memo } from 'react';
import { useLastUrl } from 'hooks/localStorage';
import { getDashboardUrl } from 'utils/url';
import type { Theme } from '@partstech/ui';

const styles = {
  icon: (theme: Theme) => css`
    font-size: ${theme.sizing(16)};
  `,
};

type Props = {
  showButton?: boolean;
};

export const EmptyCart = memo(({ showButton = false }: Props) => {
  const [lastUrl] = useLastUrl();

  return (
    <Result
      title="Your cart is empty"
      customIcon={<Icon name="shopping_cart" color="mono40" css={styles.icon} />}
      message="Add items to cart to build an order."
      extraButtons={
        showButton
          ? [
              <Button key={1} as={<Link to={lastUrl || getDashboardUrl()} />}>
                Continue shopping
              </Button>,
            ]
          : undefined
      }
      maxWidth="100%"
      m="auto"
      pt={30}
    />
  );
});
