import { isNotNull, sortUniq } from '@partstech/ui/utils';
import { useMemo } from 'react';
import { useSearchDiagrams } from 'store/queries/diagrams/useSearchDiagrams';

type Props = {
  vehicleId: string | null;
};

export const useDiagramsCategories = ({ vehicleId }: Props) => {
  const { diagrams } = useSearchDiagrams({ vehicleId });

  const categories = useMemo(
    () => sortUniq(diagrams.map((diagram) => diagram.category).filter(isNotNull), 'name'),
    [diagrams]
  );

  return {
    categories,
  };
};
