import { useCallback } from 'react';
import { useMutationStatus } from 'shared/api';
import { useDowngradePlanMutation } from '../api/mutations/downgradePlan';
import { useDowngradePlanToFreeMutation } from '../api/mutations/downgradePlanToFree';

export const useDowngradePlan = (planId: string | null) => {
  const [downgradePlan, downgradePlanStatus] = useDowngradePlanMutation();
  const [downgradeToFree, downgradeToFreeStatus] = useDowngradePlanToFreeMutation();

  const downgrade = useCallback(() => {
    if (planId) {
      return downgradePlan({ input: { planId } });
    }

    return downgradeToFree();
  }, [planId, downgradePlan, downgradeToFree]);

  const status = planId ? downgradePlanStatus : downgradeToFreeStatus;

  useMutationStatus({ status });

  return [downgrade, status] as const;
};
