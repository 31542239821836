import { keys } from '@partstech/ui/utils';
import { TIRE_QUALITY_TREADWEAR } from 'constant';

export const getTireQualityTreadwear = (treadwear: string) => {
  const treadwearRating = keys(TIRE_QUALITY_TREADWEAR)
    .map(Number)
    .sort((a, b) => b - a);

  const closestTreadwear = treadwearRating.find((key) => Number(treadwear) >= key) ?? 0;

  return TIRE_QUALITY_TREADWEAR[closestTreadwear]?.mi;
};
