import { Box, Tag } from '@partstech/ui';
import { useCallback } from 'react';
import type { TireConfiguration } from 'models';

type Props = {
  onSelect: (option: TireConfiguration) => void;
  options: TireConfiguration[];
};

export const MatchingList = ({ options, onSelect }: Props) => {
  const handleSelect = useCallback(
    (option: TireConfiguration) => () => {
      onSelect(option);
    },
    [onSelect]
  );

  return (
    <Box width="100%" gap={2} display="flex" flexWrap="wrap">
      {options.map((option) => (
        <Tag key={option.toString()} onChange={handleSelect(option)} data-testid="listItem">
          {option.toString()}
        </Tag>
      ))}
    </Box>
  );
};
