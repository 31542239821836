/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import { StockOrderFragmentDoc } from '../../queries/getStockOrders/StockOrderFragment.generated';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type SetStockOrderMutationVariables = Types.Exact<{
  input: Types.SetStockOrderInput;
}>;

export type SetStockOrderMutation = {
  __typename?: 'Mutation';
  setStockOrder?: {
    __typename?: 'SetStockOrderPayload';
    stockOrder?: {
      __typename?: 'StockOrder';
      splitByLastPurchases: Types.Scalars['Boolean']['input'];
      store?: { __typename?: 'Store'; id: string } | null;
      supplier?: { __typename?: 'Supplier'; id: string } | null;
      parts: Array<{
        __typename?: 'StockOrderPart';
        lineCardId?: string | null;
        partNumber: Types.Scalars['String']['input'];
        partNumberId?: Types.Scalars['String']['input'] | null;
        quantity: number;
        selectedIndex?: number | null;
        account?: { __typename?: 'Account'; id: string } | null;
        brand?: { __typename?: 'Brand'; id: string; name: Types.Scalars['String']['input'] } | null;
        store?: { __typename?: 'Store'; id: string } | null;
        supplier?: { __typename?: 'Supplier'; id: string } | null;
      }>;
    } | null;
  } | null;
};

export const SetStockOrderDocument = `
    mutation SetStockOrder($input: SetStockOrderInput!) {
  setStockOrder(input: $input) {
    stockOrder {
      ...StockOrder
    }
  }
}
    ${StockOrderFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    SetStockOrder: build.mutation<SetStockOrderMutation, SetStockOrderMutationVariables>({
      query: (variables) => ({ document: SetStockOrderDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useSetStockOrderMutation } = injectedRtkApi;
