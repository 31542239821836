import { Box, Typography } from '@partstech/ui';

type Props = {
  label: string;
  description: string;
};

export const StatusTooltip = ({ label, description }: Props) => (
  <Box data-testid="description">
    <Typography variant="subtitle2" color="white">
      {label}
    </Typography>

    <Typography variant="bodyText2" color="white">
      {description}
    </Typography>
  </Box>
);
