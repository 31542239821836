import { Box, Button, Card } from '@partstech/ui';
import { ContinueWithoutSystemButton } from './ContinueWithoutSystemButton';
import { CreateSystemForm } from './CreateSystemForm';

type Props = {
  onSubmit: () => void;
  onSearchClick?: () => void;
};

export const CreateSystem = ({ onSubmit, onSearchClick }: Props) => (
  <Box textAlign="center" data-testid="createSystem">
    <Button variant="text" leadingIcon="autorenew" onClick={onSearchClick}>
      Try searching again
    </Button>

    <Box display="flex" justifyContent="center" my={6}>
      <Card p={6} maxWidth={128}>
        <CreateSystemForm onSubmit={onSubmit} />
      </Card>
    </Box>

    <ContinueWithoutSystemButton onSubmit={onSubmit} />
  </Box>
);
