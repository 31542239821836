import { Box, DialogModal, Typography } from '@partstech/ui';
import { useCreateModal } from '@partstech/ui/hooks';

type Props = {
  onConfirm: () => void;
};

export const useStartNewQuoteConfirmModal = ({ onConfirm }: Props) =>
  useCreateModal(
    DialogModal,
    {
      children: (
        <>
          <Box mb={4}>
            <Typography variant="h3">Are you sure you want to start a new quote?</Typography>
          </Box>
          <Typography>Your current cart will be saved and cleared.</Typography>
        </>
      ),
      primaryText: 'Start new quote',
      onPrimary: onConfirm,
      secondaryText: 'Cancel',
    },
    { customId: 'confirmDialog' }
  );
