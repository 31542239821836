import { css } from '@emotion/react';
import { Button, Box, Typography, Divider, ButtonGroup, BottomSheet, useMedia } from '@partstech/ui';
import { useOpen } from '@partstech/ui/hooks';
import { useCallback } from 'react';
import { useAppNavigate } from 'app/AppRouter/useAppNavigate';
import { currency } from 'shared/lib/string';
import { getSubscriptionUrl } from 'utils/url';
import type { ReactNode } from 'react';

const tireSupplierPrice = 15;

const styles = {
  buttons: css`
    button {
      flex: 1;
    }
  `,
};

type Props = {
  suppliers: string[];
  goBackButton?: ReactNode;
};

export const AccountsCart = ({ suppliers, goBackButton }: Props) => {
  const { isMobile, isTablet } = useMedia();
  const navigate = useAppNavigate();

  const { isOpen, toggle } = useOpen();

  const total = suppliers.length * tireSupplierPrice;

  const handleProceedToCheckoutClick = useCallback(() => navigate(getSubscriptionUrl()), [navigate]);

  if (isMobile || isTablet) {
    return (
      <BottomSheet
        title="Payment for paid tire suppliers"
        pt={2}
        pb={5}
        mode="expandable"
        isExpanded={isOpen}
        onExpandToggle={toggle}
        hasOverlay={false}
        disableDivider
        height={160}
      >
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Typography>Tires</Typography>

          <Typography>{currency(total)}</Typography>
        </Box>

        <ButtonGroup css={styles.buttons}>
          {goBackButton}

          <Button fullWidth={!goBackButton} onClick={handleProceedToCheckoutClick}>
            Proceed to checkout
          </Button>
        </ButtonGroup>
      </BottomSheet>
    );
  }

  return (
    <div>
      <Divider />

      <Box py={2} px={3}>
        <Typography variant="subtitle1">Payment for paid tire suppliers</Typography>

        <Typography variant="caption" component="div">
          {suppliers.join(', ')}
        </Typography>

        <Box textAlign="end">
          <Typography variant="h3">{currency(total)}/mo</Typography>
          <Typography variant="caption" color="subtleText">
            {suppliers.length} x {currency(tireSupplierPrice, '$', 0)}/mo/supplier
          </Typography>
        </Box>
      </Box>

      <Divider />

      <ButtonGroup p={4} css={styles.buttons}>
        {goBackButton}

        <Button fullWidth onClick={handleProceedToCheckoutClick}>
          Proceed to checkout
        </Button>
      </ButtonGroup>
    </div>
  );
};
