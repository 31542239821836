import { Icon, IconButton, SelectBox, useMedia } from '@partstech/ui';
import { useCreateModal } from '@partstech/ui/hooks';
import { useCallback, useMemo } from 'react';
import { CartStatusFilter as CartStatus } from 'shared/api';
import { MobileFilterList } from './MobileFilterList';

type StatusOption = {
  value: CartStatus;
  text: string;
};

const statusOptions: StatusOption[] = [
  { value: CartStatus.Active, text: 'Active' },
  { value: CartStatus.Created, text: 'Created' },
  { value: CartStatus.Ordered, text: 'Ordered' },
  { value: CartStatus.Archived, text: 'Archived' },
];

type Props = {
  statuses: CartStatus[];
  onChangeStatuses: (statuses: CartStatus[]) => void;
};

export const CartStatusFilter = ({ statuses, onChangeStatuses }: Props) => {
  const { isMobile } = useMedia();

  const handleChange = useCallback(
    (value: (string | number)[]) => {
      onChangeStatuses(value as CartStatus[]);
    },
    [onChangeStatuses]
  );

  const value = useMemo(() => (!Array.isArray(statuses) ? [statuses] : statuses), [statuses]);

  const { open } = useCreateModal(MobileFilterList, {
    size: 'bottomSheet',
    onChange: handleChange,
    defaultValue: statuses,
    options: statusOptions,
  });

  const handleOpen = useCallback(() => open(), [open]);

  if (isMobile) {
    return (
      <IconButton onClick={handleOpen}>
        <Icon name="tune" />
      </IconButton>
    );
  }

  return (
    <SelectBox isMultiple value={value} label="Filter by status" onChange={handleChange} options={statusOptions} />
  );
};
