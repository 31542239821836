import { useCallback } from 'react';
import { useAppLocation } from 'app/AppRouter/useAppLocation';
import { useAppNavigate } from 'app/AppRouter/useAppNavigate';
import { parseQuery } from 'shared/lib/query';
import { getSubscriptionUrl } from 'utils/url';
import { useSubscriptionDenied } from './useSubscriptionDenied';
import type { SubscriptionSearchQuery } from 'utils/url';

export const useSubscriptionSearchQuery = () => {
  const navigate = useAppNavigate();
  const { pathname, search } = useAppLocation();

  const query = parseQuery<SubscriptionSearchQuery>(search);
  const { addOns: addOnsAlias = [], isUpdatePeriod, period, plan: planAlias } = query;

  const { isDenied, openDeniedDialog } = useSubscriptionDenied();

  const update = useCallback(
    (searchQuery: SubscriptionSearchQuery) => {
      if (isDenied) {
        openDeniedDialog();
        return;
      }

      navigate(getSubscriptionUrl({ search: { ...query, ...searchQuery } }));
    },
    [isDenied, query, navigate, openDeniedDialog]
  );

  const reset = useCallback(() => {
    if (pathname === getSubscriptionUrl()) {
      navigate(getSubscriptionUrl());
    }
  }, [pathname, navigate]);

  return {
    addOnsAlias,
    isUpdatePeriod,
    period,
    planAlias,
    reset,
    update,
  };
};
