import { ShopMembership } from 'models';

type ShopMembershipPayload = {
  id: string;
  isPrimary: boolean;
  active: boolean;
  shop?: {
    id: string;
    name: string;
  } | null;
};

export const createShopMembershipFromData = (membership: ShopMembershipPayload) => {
  if (!membership.shop) {
    return null;
  }

  // @ts-ignore Error of ts itself, Type '{ id: string; name: string; } | null | undefined' cannot be assigned to type '{ id: string; name: string; }'
  return new ShopMembership(membership);
};
