import { groupBy } from '@partstech/ui/utils';
import { createOption } from './createOption';
import type { FilterOption } from 'types/search';

export const groupFilterOptions = (options: FilterOption[]): FilterOption[] =>
  Object.values(groupBy(options, 'name')).map((option) =>
    option[0]
      ? {
          ...option[0],
          count: option.reduce(
            (sum: number | undefined, value) =>
              typeof value.count === 'number' ? (sum ?? 0) + (value.count ?? 0) : undefined,
            0
          ),
        }
      : createOption('', '', 0)
  );
