import { useMemo } from 'react';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import { useSupplierAccounts } from '../supplierAccounts/useSupplierAccounts';
import { useSearchParams } from './useSearchParams';

export const useHasCatalog = () => {
  const { isTiresSearch } = useSearchParams();
  const { showCatalog } = useGetCurrentUser();
  const { accounts } = useSupplierAccounts({ type: 'parts' });

  const hasApprovedAccounts = useMemo(() => accounts.some((account) => account.isApproved), [accounts]);

  return showCatalog && !isTiresSearch && hasApprovedAccounts;
};
