import { useDebouncedCallback, useToggle } from '@partstech/ui/hooks';
import { useUpdateAllowOrderEmailsMutation } from 'store/mutations/currentUser/allowOrderEmails';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import type { ChangeEvent } from 'react';

export const useEmailNotificationsToggle = () => {
  const { allowOrderEmails: shopAllowOrderEmails } = useGetCurrentUser();

  const { isToggle, setToggle } = useToggle(shopAllowOrderEmails);

  const [updateAllowOrderEmailsMutation] = useUpdateAllowOrderEmailsMutation();

  const toggle = useDebouncedCallback(async (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;

    setToggle(checked);

    updateAllowOrderEmailsMutation({ input: { allowOrderEmails: checked } });
  }, 500);

  return { isToggle, toggle };
};
