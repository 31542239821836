import { shopApi } from 'shared/api';
import { api as generatedApi } from './CreateMatrix.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    CreateMatrix: {
      invalidatesTags: ['Matrix', 'ActiveCart', 'Product'],

      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;

          dispatch(shopApi.util.invalidateTags([{ type: 'Product' }]));
        } catch {
          /* empty */
        }
      },
    },
  },
});

export const { useCreateMatrixMutation } = api;
