import { useCallback, useMemo } from 'react';
import { useGetStatesByCountryQuery } from './GetStatesByCountry.generated';
import type { GetStatesByCountryQuery } from './GetStatesByCountry.generated';
import type { Option } from '@partstech/ui';
import type { Country, State } from 'shared/api';

const emptyStates: NonNullable<GetStatesByCountryQuery['states']> = [];

export const useGetStatesByCountry = (country: Country) => {
  const { data, ...args } = useGetStatesByCountryQuery({ country });

  const states = useMemo(() => data?.states ?? emptyStates, [data?.states]);

  const options = useMemo(
    (): Option[] => states.map((state) => ({ value: state.twoLetterCode, text: state.name })),
    [states]
  );

  const getStateByTwoLetterCode = useCallback(
    (code: string) => states.find((state) => state.twoLetterCode === code),
    [states]
  );

  const getTwoLetterCodePairs = useCallback(
    (): { id: State['twoLetterCode']; name: State['twoLetterCode'] }[] =>
      states.map((state) => ({ id: state.twoLetterCode, name: state.twoLetterCode })),
    [states]
  );

  return {
    ...args,
    states,
    options,
    getStateByTwoLetterCode,
    getTwoLetterCodePairs,
  };
};
