import { useCallback } from 'react';
import { GraphQLError } from '../GraphQLError';
import { useMutationSnackbar } from './useMutationSnackbar';
import type { UseMutationSnackbarArgs } from './useMutationSnackbar';
import type { ResponseError } from '../GraphQLError';
import type { FieldValues } from '@partstech/ui/forms';
import type { SerializedError } from '@reduxjs/toolkit';
import type { BaseEndpointDefinition, BaseQueryFn, QueryArgFrom } from '@reduxjs/toolkit/query';
import type { TypedUseMutation } from '@reduxjs/toolkit/query/react';

const generateMutationError = <FormData extends FieldValues = {}>(
  error: ResponseError<FormData> | SerializedError | undefined
) => {
  if (error === undefined) {
    return null;
  }

  if (!('errors' in error)) {
    return null;
  }

  return new GraphQLError(error);
};

export type UseMutationCallbackArgs<ResultType, FormData extends FieldValues> = {
  onSuccess?: (data: ResultType) => void | Promise<void>;
  onError?: (error: GraphQLError<FormData>) => void | Promise<void>;
  shouldShowErrorMessage?: (error: GraphQLError<FormData>) => void;
} & UseMutationSnackbarArgs;

/**
 * useMutationCallback is a custom hook that helps handle the result of a mutation by triggering
 * success and error callbacks based on the mutation status. It accepts optional success and error
 * callbacks, and provides a function to trigger the mutation.
 * It also provides a `status` object that contains the current status of the mutation.
 *
 * @param {TypedUseMutation} useMutation - The `useMutation` hook from `@reduxjs/toolkit/query/react`.
 * @param {UseMutationCallbackArgs} params - An optional object containing:
 * - `onSuccess` (optional): A callback function that triggers when the mutation succeeds.
 * - `onError` (optional): A callback function that triggers when the mutation fails.
 * - `shouldShowErrorMessage` (optional): A function that determines whether to show the error message.
 * - `successMessage` (optional): A string message to display when the mutation succeeds.
 * - `errorMessage` (optional): A string message to display when the mutation fails. If not provided,
 *   the hook will show a default error message based on the GraphQL error or a generic message.
 *
 * @returns {Array} - Returns an array with two elements:
 * - `mutationTrigger`: A function that triggers the mutation and handles the result.
 * - `status`: An object containing the current status of the mutation.
 *
 * Example usage:
 * ```tsx
 * const [mutateData, status] = useMutationCallback(mutateDataMutation, {
 *   onSuccess: (data) => console.log('Data saved successfully:', data),
 *   onError: (error) => console.error('Failed to save data:', error),
 *   successMessage: 'Data saved successfully!',
 *   errorMessage: 'Failed to save data',
 * });
 * ```
 */
export const useMutationCallback = <
  ResultType,
  BaseQuery extends BaseQueryFn,
  QueryArg,
  FormData extends FieldValues = {},
>(
  useMutation: TypedUseMutation<ResultType, QueryArg, BaseQuery>,
  {
    errorMessage,
    onError,
    onSuccess,
    successMessage,
    shouldShowErrorMessage,
  }: UseMutationCallbackArgs<ResultType, FormData> = {}
) => {
  const [trigger, status] = useMutation();

  const { showErrorMessage, showSuccessMessage } = useMutationSnackbar({ successMessage, errorMessage });

  const showError = useCallback(
    (error: GraphQLError<FormData>) => {
      const isPermissionDeniedError = error.getPermissionDeniedError();
      const hasShowErrorMessage = shouldShowErrorMessage?.(error) ?? true;

      if (isPermissionDeniedError || !hasShowErrorMessage) {
        return;
      }

      showErrorMessage?.(error);
    },
    [shouldShowErrorMessage, showErrorMessage]
  );

  const errorTrigger = useCallback(
    async (error: GraphQLError<FormData>) => {
      const graphQLError = generateMutationError(error);

      if (!graphQLError) {
        return null;
      }

      showError(graphQLError);

      await onError?.(graphQLError);

      return graphQLError;
    },
    [onError, showError]
  );

  const successTrigger = useCallback(
    async (result: ResultType) => {
      showSuccessMessage();

      await onSuccess?.(result);
    },
    [onSuccess, showSuccessMessage]
  );

  const mutationTrigger = useCallback(
    async (args: QueryArgFrom<BaseEndpointDefinition<QueryArg, BaseQuery, ResultType>>) => {
      try {
        const result = await trigger(args).unwrap();

        await successTrigger(result);

        return result;
      } catch (error) {
        const graphQLError = await errorTrigger(error);

        return graphQLError;
      }
    },
    [errorTrigger, trigger, successTrigger]
  );

  return [mutationTrigger, status] as const;
};
