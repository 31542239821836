import { Typography, Icon, Box, TextCut } from '@partstech/ui';
import { memo } from 'react';
import type { TypographyProps, BoxProps, IconProps } from '@partstech/ui';

type Props = {
  name: string | null;
  variant?: TypographyProps['variant'];
  icon?: IconProps['name'];
  linesCount?: number;
} & BoxProps;

export const StoreName = memo(({ name, icon, variant = 'subtitle2', linesCount = 10, ...props }: Props) => (
  <Box display="flex" alignItems="center" {...props}>
    {icon && (
      <Box display="flex" justifyContent="center" alignItems="center" mr={2}>
        <Icon name={icon} size="large" color="dark" />
      </Box>
    )}
    <Box maxWidth={linesCount === 1 ? 41 : undefined}>
      <Typography component="span" variant={variant}>
        <TextCut linesCount={linesCount}>{name}</TextCut>
      </Typography>
    </Box>
  </Box>
));
