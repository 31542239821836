import { css } from '@emotion/react';
import type { Theme, ThemeColorName } from '@partstech/ui';
import type { PropsWithChildren } from 'react';

const styles = {
  wrapper: (background: Props['background']) => (theme: Theme) => css`
    padding: ${theme.sizing(1, 2)};
    border-radius: ${theme.sizing(3)};
    background: ${theme.color[background]};
    max-width: fit-content;
  `,
};

type Props = PropsWithChildren<{
  background: ThemeColorName;
}>;

export const Chip = ({ background, children }: Props) => <div css={styles.wrapper(background)}>{children}</div>;
