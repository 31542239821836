import { isEqual } from '@partstech/ui/utils';
import { useEffect } from 'react';
import { useInputValue } from 'shared/lib/form';
import { useSearchVehiclesByName } from '../api';
import type { Vehicle } from 'models';

type Props = {
  value?: Vehicle | null;
  onChange?: (vehicle: Vehicle) => void;
};

export const useVehicleSuggestionSearch = ({ value: vehicle, onChange }: Props) => {
  const { value, debouncedValue, changeValue } = useInputValue(vehicle ? vehicle.toString() : '', {
    debounceDelay: 300,
  });

  const { vehicleSuggestions, isSearchLoaded } = useSearchVehiclesByName(debouncedValue);

  useEffect(() => {
    if (isSearchLoaded && vehicleSuggestions.length === 1 && onChange) {
      const [singleVehicleSuggestion] = vehicleSuggestions;
      const singleVehicle = singleVehicleSuggestion?.toVehicle();

      if (singleVehicle && !isEqual(singleVehicle.id, vehicle?.id) && value) {
        onChange(singleVehicle);
      }
    }
  }, [isSearchLoaded, onChange, value, vehicle?.id, vehicleSuggestions]);

  return { value, changeValue, vehicleSuggestions };
};
