import { makeContext } from '@partstech/ui/utils';

export type SupplierTypes = 'dealers' | 'afterMarket' | 'catalog';

type SupplierTypeContextValue = {
  activeSupplierType: SupplierTypes | undefined;
  setActiveSupplierType: (supplierType: SupplierTypes) => void;
};

export const [useSupplierType, SupplierTypeContextProvider] = makeContext<SupplierTypeContextValue>();
