import { ImageFallback } from '@partstech/ui';
import { memo, useCallback } from 'react';
import { styles } from '../FileViewerModal.styles';
import { useImages360Navigation } from './useImages360Navigation';

type Props = {
  images: string[];
};

export const Images360 = memo(({ images }: Props) => {
  const { currentIndex, isDragging, onMouseDown } = useImages360Navigation(images);

  const handlePreventDrag = useCallback((e: React.DragEvent) => {
    e.preventDefault();
  }, []);

  return (
    <button
      type="button"
      onMouseDown={onMouseDown}
      onDragStart={handlePreventDrag}
      onTouchStart={onMouseDown}
      css={styles.button360(isDragging)}
    >
      <ImageFallback src={images[currentIndex]} alt="360 view" css={styles.image} />
    </button>
  );
});
