import { Box } from '@partstech/ui';
import { ErrorCode } from 'shared/api';
import { AccountDisabled } from './AccountDisabled';
import { BadCredentials } from './BadCredentials';
import type { BoxProps } from '@partstech/ui';

const errorComponents: Partial<Record<ErrorCode, JSX.Element>> = {
  [ErrorCode.ACCOUNT_DISABLED]: <AccountDisabled />,
  [ErrorCode.BAD_CREDENTIALS]: <BadCredentials />,
};

type Props = BoxProps & {
  errorCode: ErrorCode | null;
};

export const ErrorCodeMessage = ({ errorCode }: Props) => {
  if (!errorCode) {
    return null;
  }

  return (
    <Box textAlign="center" data-testid="errorCodeMessage">
      {errorComponents[errorCode]}
    </Box>
  );
};
