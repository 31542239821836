import { Typography, Button, Box, ModalView } from '@partstech/ui';
import type { ModalComponentProps } from '@partstech/ui';

export const WelcomeBackModal = ({ onClose }: ModalComponentProps<{}>) => (
  <ModalView onClose={onClose}>
    <Box display="flex" flexDirection="column" justifyContent="center" height="100%" data-testid="welcomeBackModal">
      <Typography variant="h3">Welcome back!</Typography>

      <Box mt={4} mb={6}>
        <Typography>You’re almost finished with the set up process.</Typography>
      </Box>

      <Button fullWidth onClick={onClose} data-testid="submitButton">
        Okay
      </Button>
    </Box>
  </ModalView>
);
