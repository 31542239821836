/**
 * Implementing this interface adds possibility to be used
 * as a SearchEntryPoint
 *
 * TODO Move className, columnTitle, toString out to ~OptionInterface
 */
export interface SearchEntryPointInterface {
  getClassName?: () => string;
  getColumnTitle?: () => string;
  isVehicleRequired?: () => boolean;
  toString(): string;
  populateInput(): string;
}

export const isSearchEntryPoint = (model: object): model is SearchEntryPointInterface => 'populateInput' in model;
