import { useForm } from '@partstech/ui/forms';
import { isEqual } from '@partstech/ui/utils';
import { useCallback, useMemo } from 'react';
import { useFormStatus } from 'shared/lib/form';
import { useUpdateUserInformation } from '../../../api/updateUserInformation';
import type { UpdateUserInformationInput } from 'shared/api';

type Args = {
  defaultValues?: UpdateUserInformationInput;
  onSubmit: (newEmail: string | null) => void;
};

export const useEditProfileForm = ({ defaultValues, onSubmit }: Args) => {
  const [updateUserInformation, status] = useUpdateUserInformation();
  const { isLoading } = status;

  const form = useForm<UpdateUserInformationInput>({
    defaultValues: { ...defaultValues, phone: defaultValues?.phone || null },
  });
  const { watch } = form;

  const formValues = watch();

  const isDisabled = useMemo(
    () => isLoading || isEqual(defaultValues, formValues),
    [isLoading, defaultValues, formValues]
  );

  const submitForm = useCallback(
    (values: UpdateUserInformationInput) => {
      updateUserInformation({ ...values, phone: values.phone || null });
    },
    [updateUserInformation]
  );

  const newEmail = defaultValues?.email !== formValues.email ? formValues.email : null;

  const handleSuccessEditProfile = useCallback(() => {
    onSubmit(newEmail);
  }, [newEmail, onSubmit]);

  useFormStatus({
    form,
    status,
    successMessage: !newEmail ? 'Your account has been successfully updated.' : undefined,
    onSuccess: handleSuccessEditProfile,
  });

  return { form, formValues, submitForm, isLoading, isDisabled };
};
