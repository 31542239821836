import { makeContext } from '@partstech/ui/utils';
import type { FullStoryEventNames } from '../../constant';
import type { FSUserVars } from '../../lib/useFullStorySetUserVars';
import type { FullStoryEventPayload } from '../../lib/useTracking';

type FullStoryContext = {
  sendEvent: (eventName: FullStoryEventNames, payload?: FullStoryEventPayload) => void;
  setUserVars: (userVars: FSUserVars) => void;
  isIdentified: boolean;
};

export const [useFullStory, FullStoryContextProvider] = makeContext<FullStoryContext>();
