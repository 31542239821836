import { Box, Card, Tag, Typography, Loader } from '@partstech/ui';
import { useCallback } from 'react';
import type { CardProps } from '@partstech/ui';

type Props = {
  isFetching: boolean;
  onChange: (value: string) => void;
  title: string;
  values: string[];
} & Omit<CardProps, 'onChange'>;

export const DependentAttribute = ({ isFetching, onChange, title, values, ...boxProps }: Props) => {
  const handleTagChange = useCallback(
    (value: string) => () => {
      onChange(value);
    },
    [onChange]
  );

  return (
    <Card background="mono2" elevation={null} px={4} pt={4} pb={2} {...boxProps}>
      <Typography variant="overline" color="subtleText" textTransform="uppercase">
        {title}
      </Typography>

      {isFetching ? (
        <Loader mt={2} />
      ) : (
        <Box mt={4}>
          {values.map((value) => (
            <Box key={value} mr={2} mb={2} display="inline-flex">
              <Tag onChange={handleTagChange(value)}>{value}</Tag>
            </Box>
          ))}
        </Box>
      )}
    </Card>
  );
};
