import { css } from '@emotion/react';
import { Box, Button, ButtonGroup, Icon, ModalView, Typography } from '@partstech/ui';
import type { Theme } from '@partstech/ui';

type Props = {
  onYes: () => void;
  onClose: () => void;
};

const styles = {
  icon: (theme: Theme) => css`
    font-size: ${theme.sizing(14)};
    width: ${theme.sizing(14)};
    height: ${theme.sizing(14)};
  `,
};

export const ClearConfirmDialog = ({ onClose, onYes }: Props) => (
  <ModalView onClose={onClose} title="Quote in progress">
    <Box display="flex" gap={2} justifyContent="center">
      <Icon name="warning" color="negative" css={styles.icon} />
    </Box>

    <Box mb={4} textAlign="center" data-testid="restrictionMessage">
      <Typography color="monoDark">Are you sure you want to clear this quote?</Typography>
    </Box>

    <ButtonGroup>
      <Button onClick={onYes} data-testid="yesButton">
        Yes
      </Button>

      <Button variant="text" onClick={onClose} data-testid="noButton">
        No
      </Button>
    </ButtonGroup>
  </ModalView>
);
