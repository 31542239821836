import { Banner, Box, Card, Tabs, useMedia } from '@partstech/ui';
import { useScroll } from '@partstech/ui/hooks';
import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'hooks/search';
import { useSearchResults } from 'pages/SearchResults/SearchResultsContext';
import { useSupplierSelector } from '../SupplierSelectorContext';
import { SupplierTab } from './SupplierTab';
import type { SupplierAccount } from 'models';

type Props = {
  supplierAccounts: SupplierAccount[];
  selectedId: string | null;
  onSupplierSelect: (id: string) => void;
  isDisabled?: boolean;
  hasCatalog?: boolean;
  hideTotals?: boolean;
};

export const SupplierTabs = ({
  supplierAccounts,
  selectedId,
  onSupplierSelect,
  isDisabled,
  hasCatalog,
  hideTotals = false,
}: Props) => {
  const { isMobile } = useMedia();
  const { isCatalogSelected, isDealersSearch, isTiresSearch, isPartsSearch } = useSearchParams();
  const { scrollY } = useScroll();
  const { isSticky, setIsSticky } = useSupplierSelector();
  const { totals, loadingIds } = useSearchResults();

  const isSupplierLoading = useCallback((id: string) => loadingIds.includes(Number(id)), [loadingIds]);
  const getTotal = useCallback((id: string) => totals[Number(id)] ?? 0, [totals]);

  const [activeTab, setActiveTab] = useState('');
  const [tabTopRect, setTabTopRect] = useState(0);

  const ref = useRef<HTMLDivElement | null>(null);

  const scrollToStickyTab = useCallback(() => {
    if (!tabTopRect || tabTopRect < 0 || isCatalogSelected) {
      return;
    }

    const responsiveRect = isMobile ? tabTopRect - 38 : tabTopRect + 18;

    if (scrollY >= responsiveRect) {
      setIsSticky(true);
      return;
    }

    setIsSticky(false);
  }, [tabTopRect, isCatalogSelected, isMobile, scrollY, setIsSticky]);

  const handleTabChange = useCallback(
    (tab: string) => {
      if (tab === activeTab) {
        return;
      }

      setActiveTab(tab);
      onSupplierSelect(tab);

      if (isMobile && isSticky) {
        window.scrollTo(0, 0);
      }
    },
    [activeTab, onSupplierSelect, isMobile, isSticky]
  );

  const tabWidth = useMemo(() => {
    if (isSticky) {
      return 130;
    }

    if (isMobile && !isSticky) {
      return 120;
    }

    return 148;
  }, [isMobile, isSticky]);

  useEffect(() => {
    setActiveTab(String(selectedId));
  }, [selectedId]);

  useEffect(() => {
    if (ref.current) {
      const { top } = ref.current.getBoundingClientRect();
      setTabTopRect(top);
    }
  }, [tabTopRect]);

  useEffect(() => {
    if (isSticky && scrollY > 0) {
      return;
    }

    scrollToStickyTab();
  }, [isSticky, scrollToStickyTab, scrollY]);

  if (hasCatalog && isCatalogSelected) {
    return (
      <Box pr={{ md: 4 }} py={{ md: 4 }} mt={{ sm: 3, md: 0 }} data-testid="catalogMessage">
        <Banner
          fullWidth
          title="PartsTech Catalog"
          variant="info"
          p={{ sm: 2, md: 4 }}
          icon={isMobile ? undefined : 'info'}
        >
          These parts may be available through additional suppliers not currently connected to your account.
        </Banner>
      </Box>
    );
  }

  return (
    <Card
      pt={{ sm: isSticky ? 1 : 0, md: isSticky ? 2 : 0 }}
      pb={{ sm: isSticky ? 1 : 0 }}
      pl={{ md: isSticky ? 2 : 0 }}
      pr={{ md: isSticky ? 1 : 0 }}
      elevation={isSticky ? 6 : 0}
      borderWidth={0}
      radius={isSticky ? 0 : 8}
      ref={ref}
      className={classNames({
        'fs-dealers': isDealersSearch,
        'fs-tires': isTiresSearch,
        'fs-afterMarket': isPartsSearch && !isDealersSearch,
        'fs-sticky': isSticky,
        'fs-relative': !isSticky,
      })}
    >
      <Tabs
        activeTab={activeTab}
        onChange={handleTabChange}
        scrollButtonsWidth={6}
        scrollButtonVariant="round"
        tabWidth={tabWidth}
        scrollByPage
        ableToSwipe
      >
        {supplierAccounts.map((account) => (
          <SupplierTab
            id={String(account.id)}
            isLoading={isSupplierLoading(account.id)}
            isSelected={account.id === selectedId}
            key={account.id}
            supplierAccount={account}
            total={getTotal(account.id)}
            isDisabled={isDisabled}
            isStickyTab={isSticky}
            hideTotal={hideTotals && getTotal(account.id) === 0}
          />
        ))}
      </Tabs>
    </Card>
  );
};
