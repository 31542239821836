import { Box, SkeletonLoader, Skeleton, useMedia } from '@partstech/ui';

export const SupplierSelectorSkeleton = () => {
  const { isMobile, isTablet } = useMedia();

  return (
    <Box p={{ md: 4 }} mb={{ md: 2 }}>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={4}>
        <Box display="flex" gap={2}>
          <SkeletonLoader
            isVisible
            count={2}
            loader={<Skeleton width={isMobile ? '88px' : '109px'} height="28px" roundsCornersX="12" />}
          />
        </Box>
        <SkeletonLoader isVisible count={1} loader={<Skeleton width={isMobile ? '24px' : '123px'} height="24px" />} />
      </Box>
      <Box display="flex" gap={{ sm: 2, md: 3 }}>
        <SkeletonLoader
          isVisible
          count={isMobile || isTablet ? 4 : 5}
          loader={<Skeleton width={isMobile ? '102px' : '100%'} height={isMobile ? '60px' : '72px'} />}
        />
      </Box>
    </Box>
  );
};
