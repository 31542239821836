import { useForm } from '@partstech/ui/forms';
import { useCallback, useMemo } from 'react';
import { useFormStatus } from 'shared/lib/form';
import { useChangeEmailConfirmation } from '../../../api/changeEmailConfirmation';
import type { ChangeEmailConfirmationInput } from 'shared/api';

type Args = {
  newEmail: string;
  onSubmit: () => void;
};

export const useConfirmEditProfileForm = ({ newEmail, onSubmit }: Args) => {
  const [changeEmailConfirmation, status] = useChangeEmailConfirmation();
  const { isLoading } = status;

  const form = useForm<ChangeEmailConfirmationInput>({ defaultValues: { newEmail, confirmationCode: '' } });
  const { reset, watch } = form;

  const { confirmationCode } = watch();

  const isDisabled = useMemo(
    () => isLoading || confirmationCode.length !== 5 || confirmationCode.split('').some((value) => !value.trim()),
    [isLoading, confirmationCode]
  );

  const submitForm = useCallback(
    (values: ChangeEmailConfirmationInput) => {
      reset({ newEmail });

      changeEmailConfirmation(values);
    },
    [changeEmailConfirmation, newEmail, reset]
  );

  useFormStatus({ form, status, successMessage: 'Your account has been successfully updated.', onSuccess: onSubmit });

  return { form, submitForm, isDisabled, isLoading };
};
