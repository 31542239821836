import { SearchTypes } from 'types/search';
import { RecentSearchAbstract } from './RecentSearchAbstract';
import type { AbstractParams } from './RecentSearchAbstract';
import type { RecentSearchInterface } from './RecentSearchInterface';
import type { SearchEntryPointInterface } from 'features/searchForm';
import type { Vehicle } from 'models';
import type { TiresSearchFormValues } from 'types/search';

export class TirePartNumber extends RecentSearchAbstract implements RecentSearchInterface, SearchEntryPointInterface {
  type: string = 'Tire Part Number';

  partNumber: string;

  constructor(id: string, vehicle: Vehicle | null, params: AbstractParams & { partNumber: string }) {
    super(id, vehicle, params);

    this.partNumber = params.partNumber;
  }

  toString(): string {
    return this.partNumber;
  }

  populateInput(): string {
    return this.toString();
  }

  toSearchValues(): TiresSearchFormValues {
    return {
      type: SearchTypes.TIRES,
      vehicleId: this.vehicle?.id ?? null,
      tireId: null,
      tiresSize: null,
      partNumber: this.partNumber,
      plate: this.plate,
      state: this.state,
      vin: this.vin,
    };
  }
}
