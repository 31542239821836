import { Box, Button, Link, Typography } from '@partstech/ui';
import { getAddSupplierUrl } from 'utils/url';

type Props = {
  supplierType: 'part' | 'tire';
};

export const SupplierInstructions = ({ supplierType }: Props) => (
  <Box
    height="100%"
    justifyContent="center"
    display="flex"
    alignItems="center"
    alignSelf="stretch"
    flexDirection="column"
    gap={4}
    textAlign="center"
    minHeight="302px"
  >
    <Typography color="monoDark">
      Connect your favorite {supplierType} suppliers to
      <br />
      start placing stock orders
    </Typography>

    <Button as={<Link to={getAddSupplierUrl(supplierType === 'tire' ? 'tires' : 'parts')} />} variant="secondary">
      Add {supplierType} suppliers
    </Button>
  </Box>
);
