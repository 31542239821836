import type { BuyActiveCartMitchell1Payload, SubmitActiveCartMitchell1Payload } from 'shared/api';
import type { Mitchell1ShoppingCart } from 'shared/api/rest/gen/shop';

export const getMitchell1Cart = (
  payload: BuyActiveCartMitchell1Payload | SubmitActiveCartMitchell1Payload
): Mitchell1ShoppingCart => ({
  labors:
    payload.labors?.map((labor) => ({
      Description: labor.description,
      Hours: labor.hours,
      Price: labor.price,
      type: labor.type,
    })) || [],
  notes:
    payload.notes?.map((item) => ({
      Note: item.note,
      type: item.type,
    })) || [],
  orders:
    'orders' in payload
      ? payload.orders.map((order) => ({
          ConfirmationNumber: order.confirmationNumber,
          DeliveryOptions: order.deliveryOptions,
          OrderMessage: order.orderMessage,
          ReservedPONumber: order.reservedPoNumber || null,
          TrackingNumber: order.trackingNumber || null,
          type: order.type,
          Parts: order.parts.map((part) => ({
            Description: part.description,
            LocationId: part.locationId,
            LocationName: part.locationName,
            ManufacturerLineCode: part.manufacturerLineCode,
            ManufacturerName: part.manufacturerName,
            Metadata: part.metadata,
            PartCategory: part.partCategory,
            PartNumber: part.partNumber,
            QuantityAvailable: part.quantityAvailable,
            QuantityOrdered: part.quantityOrdered,
            QuantityRequested: part.quantityRequested,
            ShippingCost: part.shippingCost,
            ShippingDescription: part.shippingDescription,
            Size: part.size,
            Status: part.status,
            SupplierName: part.supplierName,
            UnitCore: part.unitCore,
            UnitCost: part.unitCost,
            UnitList: part.unitList,
            type: part.type,
          })),
        }))
      : [],
  partItems:
    'parts' in payload
      ? payload.parts.map((part) => ({
          Description: part.description,
          IsTire: part.isTire,
          ManufacturerLineCode: part.manufacturerLineCode,
          ManufacturerName: part.manufacturerName,
          Metadata: part.metadata,
          PartCategory: part.partCategory,
          PartNumber: part.partNumber,
          Quantity: part.quantity,
          ShippingCost: part.shippingCost,
          ShippingDescription: part.shippingDescription,
          Size: part.size,
          SupplierName: part.supplierName,
          UnitCore: part.unitCore,
          UnitCost: part.unitCost,
          UnitList: part.unitList,
          UpcCode: part.upcCode,
          type: part.type,
        }))
      : [],
});
