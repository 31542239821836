import { usePrevious } from '@partstech/ui/hooks';
import { useMemo } from 'react';
import { matchPath } from 'react-router-dom';
import { Routes } from 'constant';
import { useSaveLastUrl } from 'hooks/app';
import { useUserpilotReload } from 'integrations/userpilot';
import { useAppLocation } from '../useAppLocation';

const excludedScrollToRoutes = [Routes.SEARCH_RESULTS, Routes.STOCK_ORDER_RESULTS];

export const useAppNavigationHandler = () => {
  const location = useAppLocation();
  const prevLocation = usePrevious(location);

  const { pathname, search } = location;

  const hasNeedScrollRestoration = useMemo(() => {
    const isExcludedRoute = excludedScrollToRoutes.some((path) => Boolean(matchPath({ path }, pathname)));
    const isSameLocation = prevLocation?.state?.background?.pathname === pathname;

    return !(isSameLocation || isExcludedRoute);
  }, [pathname, prevLocation?.state?.background?.pathname]);

  useUserpilotReload([pathname, search]);

  useSaveLastUrl();

  return { hasNeedScrollRestoration };
};
