import { useForm } from '@partstech/ui/forms';
import { useCallback } from 'react';
import { useUpdateUserPhoneNumber } from 'features/profile';
import { useCreateOnboardStep } from 'features/signUp';
import { CreateOrUpdateOnboardStepType, UserPhoneType } from 'shared/api';
import { useFormStatus } from 'shared/lib/form';

type FormData = {
  phone: string;
  phoneType: UserPhoneType;
};

type Args = {
  onSuccess: () => void;
};

export const usePhoneNumberForm = ({ onSuccess }: Args) => {
  const [createOnboardStep] = useCreateOnboardStep({ onSuccess });
  const [updateUserPhoneNumber, status] = useUpdateUserPhoneNumber();

  const form = useForm<FormData>({ defaultValues: { phone: '', phoneType: UserPhoneType.Mobile } });
  const {
    formState: { isSubmitting },
    watch,
  } = form;

  const phoneField = watch('phone');
  const phoneTypeField = watch('phoneType');

  const completeOnboardPhoneStep = useCallback(() => {
    createOnboardStep({ step: CreateOrUpdateOnboardStepType.Phone });
  }, [createOnboardStep]);

  const submitForm = useCallback(
    async ({ phone, phoneType }: FormData) => {
      await updateUserPhoneNumber({ phone, phoneType });
    },
    [updateUserPhoneNumber]
  );

  useFormStatus({ form, status, onSuccess: completeOnboardPhoneStep });

  return {
    form,
    isDisabled: !phoneField || !phoneTypeField || isSubmitting,
    isSubmitting,
    skipForm: completeOnboardPhoneStep,
    submitForm,
  };
};
