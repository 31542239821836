import { css } from '@emotion/react';
import { Box, Icon, Typography, useModal } from '@partstech/ui';
import { elevationShadow } from '@partstech/ui/utils';
import { useFeatureHighlight } from '../../../../hooks/useFeatureHighlight';
import { FeatureName } from './FeatureName';
import type { FeatureLink } from '../../../../types';
import type { BoxProps, Theme } from '@partstech/ui';
import type { PropsWithChildren } from 'react';

const styles = {
  wrapper: (isHighlighted: boolean) => (theme: Theme) => css`
    ${isHighlighted ? elevationShadow(6) : ''};
    background-color: ${isHighlighted ? theme.color.upgrade : 'transparent'};
    transition:
      background-color 0.3s ease-in-out,
      box-shadow 0.3s ease-in-out;

    &:last-child {
      border-bottom-left-radius: ${theme.sizing(2)};
      border-bottom-right-radius: ${theme.sizing(2)};
    }
  `,
};

type Props = PropsWithChildren &
  BoxProps & {
    description?: string;
    isHighlighted?: boolean;
    link?: FeatureLink;
    name: string;
  };

export const FeatureRow = ({ children, description, isHighlighted = false, link, name, ...props }: Props) => {
  const { onClose } = useModal();

  const { containerRef, isHighlightActive } = useFeatureHighlight(isHighlighted);

  return (
    <Box
      css={styles.wrapper(isHighlightActive)}
      display="flex"
      height={15}
      ref={containerRef}
      data-testid="featureRow"
      {...props}
    >
      <Box className="feature-column" display="flex" alignItems="center" gap={2}>
        <Icon name="star" color="primary" />

        <Box>
          <FeatureName link={link} name={name} onLinkClick={link?.isInternal ? onClose : undefined} />

          {description && <Typography variant="caption">{description}</Typography>}
        </Box>
      </Box>

      {children}
    </Box>
  );
};
