import { useMatch } from 'react-router-dom';
import { Routes } from 'constant';
import { getSignUpUrl, SignUpPages } from 'utils/url';

export const useIsInvitationPage = () => {
  const isLoginByInvitationPage = Boolean(useMatch(Routes.LOGIN_BY_INVITATION));
  const isSignUpInvitationPage = Boolean(useMatch(Routes.SIGN_UP_INVITATION));
  const isReferralSignUpPage = Boolean(useMatch(getSignUpUrl(SignUpPages.Referral)));

  return isLoginByInvitationPage || isSignUpInvitationPage || isReferralSignUpPage;
};
