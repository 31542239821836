import { keys } from '@partstech/ui/utils';
import { getAttributeOptionsByName } from './getAttributeOptionsByName';
import type { AttributeUsageSection, RangeStep } from 'constant';
import type {
  DynamicAttributeName,
  DynamicAttributeRanges,
  DynamicAttributes,
  DynamicAttributeValues,
} from 'types/search';

export const splitRanges = (array: DynamicAttributeValues, range: RangeStep) => {
  let currentRange = range;

  return array.reduce<DynamicAttributeRanges>(
    (value, currentValue, index) => {
      const isRangedValue =
        parseFloat(currentValue) >= Number(currentRange) &&
        parseFloat(currentValue) - parseFloat(array[index - 1] ?? '') <= Number(range);

      if (isRangedValue) {
        currentRange = String(Number(parseFloat(currentRange) + Number(range)).toFixed(1));

        value[value.length - 1]?.push(currentValue);

        if (index !== array.length - 1) {
          value.push([]);
        }
      }

      if (!isRangedValue) {
        value[value.length - 1]?.push(currentValue);
      }

      return value;
    },
    [[]]
  );
};

export const getAttributeRangeStep = (attributeName: DynamicAttributeName, section: AttributeUsageSection) => {
  const attributeOptions = getAttributeOptionsByName(attributeName);

  return attributeOptions?.rangeStep?.[section] ?? null;
};

export const getDynamicAttributesWithRangesForSection = (
  attributes: DynamicAttributes,
  section: AttributeUsageSection
) => {
  const attributeNames = keys(attributes);

  return attributeNames.reduce<
    Record<DynamicAttributeName, DynamicAttributeValues | DynamicAttributeRanges | undefined>
  >((attr, attributeName) => {
    const rangeStep = getAttributeRangeStep(attributeName, section);

    return {
      ...attr,
      [attributeName]: rangeStep ? splitRanges(attributes[attributeName] ?? [], rangeStep) : attributes[attributeName],
    };
  }, {});
};

export const getAttributeRangeLabels = (attributes: string[][] | string[], range: string) => {
  let startRange = Number(range);
  let endRange = Number(range);

  return [...attributes].reduce<string[]>((ranges, currentValue, index) => {
    if (index === 0) {
      ranges.push(`0 - ${endRange}`);
    }

    if (index > 0) {
      startRange = Number((endRange + 0.01).toFixed(2));
      endRange = Number((endRange + Number(range)).toFixed(1));

      while (endRange < parseFloat(currentValue[0] ?? '')) {
        startRange = Number((endRange + 0.01).toFixed(2));
        endRange = Number((endRange + Number(range)).toFixed(1));
      }

      ranges.push(`${startRange} - ${endRange}`);
    }

    return ranges;
  }, []);
};

export const isRangedAttributeValue = <T>(attribute: (T | T[])[]) => attribute.every(Array.isArray);
