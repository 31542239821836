/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../../../shared/api/graphql/graphql';

export type CreateTireQuoteMutationVariables = Types.Exact<{
  input: Types.CreateTireQuoteInput;
}>;

export type CreateTireQuoteMutation = {
  __typename?: 'Mutation';
  createTireQuote?: { __typename?: 'CreateTireQuotePayload'; hash: string } | null;
};

export const CreateTireQuoteDocument = `
    mutation CreateTireQuote($input: CreateTireQuoteInput!) {
  createTireQuote(input: $input) {
    hash
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    CreateTireQuote: build.mutation<CreateTireQuoteMutation, CreateTireQuoteMutationVariables>({
      query: (variables) => ({ document: CreateTireQuoteDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useCreateTireQuoteMutation } = injectedRtkApi;
