import { Banner, Box, Link, useMedia } from '@partstech/ui';
import type { BoxProps } from '@partstech/ui';

type Props = {
  url: string;
} & BoxProps;

export const TutorialUrl = ({ url, ...props }: Props) => {
  const { isMobile } = useMedia();

  if (isMobile) {
    return (
      <Box mb={6} {...props}>
        <Banner variant="info" icon="info" iconColor="subtleText" fullWidth>
          Need more information?{' '}
          <Link isExternal to={url}>
            View instructions
          </Link>
        </Banner>
      </Box>
    );
  }

  return (
    <Box textAlign="center" {...props}>
      <Link isExternal to={url} underline="hover">
        View instructions
      </Link>
    </Box>
  );
};
