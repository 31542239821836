import type { Option } from '../components/QuestionOptions/useQuestionOptions';

export const rolesOptions: Option[] = [
  { value: 'single shop owner', text: 'Single shop owner' },
  { value: 'multi-shop owner', text: 'Multi-shop owner' },
  { value: 'shop manager', text: 'Shop manager' },
  { value: 'service advisor', text: 'Service advisor' },
  { value: 'technician', text: 'Technician' },
  { value: 'parts manager', text: 'Parts manager' },
  { value: 'other', text: 'Other' },
];
