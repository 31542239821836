import { omit } from '@partstech/ui/utils';
import { Routes } from 'constant';
import { pickDefinedValues } from 'shared/lib/object';
import { urlParamsToQuery } from '../product/urlParamsToQuery';
import { generateUrl } from './generateUrl';
import type { ProductUrlParams as BaseProductUrlParams } from 'shared/api/rest/gen/shop';
import type { ProductUrlParams, ProductUrlQueryParams } from 'types/product';

export const getProductUrl = (
  urlParams: BaseProductUrlParams | ProductUrlParams,
  query?: Partial<ProductUrlQueryParams>
) => {
  const partNumberId = 'part_term' in urlParams ? urlParams.partnumberid : urlParams.partNumberId;
  const searchParams = 'part_term' in urlParams ? urlParams : urlParamsToQuery(urlParams);

  return generateUrl(
    Routes.PRODUCT_PAGE,
    { partNumberId: encodeURIComponent(partNumberId) },
    {
      ...omit(pickDefinedValues({ ...searchParams, ...query }), 'partnumberid'),
    }
  );
};
