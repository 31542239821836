import { Tabs, Tab, useModalHistory, Box } from '@partstech/ui';
import { useCallback, useMemo, useState } from 'react';
import { Details } from './Details';
import { Password } from './Password';
import { Picture } from './Picture';
import type { ProfileModalPaths } from 'types/profileModal';

type ProfileTab = {
  name: 'picture' | 'details' | 'password';
  component: (onClose: () => void) => JSX.Element;
};

const tabs: ProfileTab[] = [
  {
    name: 'picture',
    component: (onClose) => <Picture onClose={onClose} />,
  },
  {
    name: 'details',
    component: (onClose) => <Details onClose={onClose} />,
  },
  {
    name: 'password',
    component: (onClose) => <Password onClose={onClose} />,
  },
];

export const Edit = () => {
  const { reset } = useModalHistory<ProfileModalPaths, 'edit'>();

  const [activeTab, setActiveTab] = useState(tabs[0]?.name);

  const handleClose = useCallback(() => reset('info'), [reset]);

  const activeTabComponent = useMemo(
    () => tabs.find((tab) => tab.name === activeTab)?.component(handleClose),
    [activeTab, handleClose]
  );

  const handleTabChange = useCallback((id: ProfileTab['name']) => {
    setActiveTab(id);
  }, []);

  return (
    <>
      <Box position="absolute" top={0} left={0} width="100%">
        <Tabs activeTab={activeTab} onChange={handleTabChange} data-testid="tabs" tabWidth={1 / tabs.length}>
          {tabs.map((tab) => (
            <Tab key={tab.name} id={tab.name} isActive={tab.name === activeTab}>
              {tab.name}
            </Tab>
          ))}
        </Tabs>
      </Box>

      <Box pt={10}>{activeTabComponent}</Box>
    </>
  );
};
