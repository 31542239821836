import { Box, Button, ButtonGroup, Icon } from '@partstech/ui';
import { FormPhoneField, FormSelectBox, HookForm } from '@partstech/ui/forms';
import { UserPhoneType } from 'shared/api';
import { isSame } from 'shared/lib/form';
import { usePhoneNumberForm } from './usePhoneNumberForm';

const isMatchPhone = (value: string) =>
  isSame(value, 'Enter a phone number different from the shop’s phone, or click Skip to Dashboard');

type Props = {
  shopPhoneNumber: string;
  onSubmit: () => void;
};

export const PhoneNumberForm = ({ shopPhoneNumber, onSubmit }: Props) => {
  const { form, isDisabled, isSubmitting, skipForm, submitForm } = usePhoneNumberForm({ onSuccess: onSubmit });

  return (
    <HookForm form={form} onSubmit={submitForm}>
      <Box display="flex" justifyContent="space-between" flexDirection={{ sm: 'column', md: 'row' }} gap={2}>
        <Box flexBasis="120%">
          <FormPhoneField
            adornmentBefore={<Icon name="call" color="subtleText" />}
            name="phone"
            label="Alternate phone"
            rules={{ validate: isMatchPhone(shopPhoneNumber) }}
          />
        </Box>

        <Box flexBasis="80%">
          <FormSelectBox
            name="phoneType"
            label="Phone type"
            options={[
              { text: 'Mobile', value: UserPhoneType.Mobile },
              { text: 'Direct', value: UserPhoneType.Direct },
            ]}
          />
        </Box>
      </Box>

      <ButtonGroup flexDirection={{ sm: 'column', md: 'row' }} mt={6}>
        <Button type="submit" disabled={isDisabled} isLoading={isSubmitting}>
          Submit
        </Button>

        <Button variant="text" onClick={skipForm}>
          Skip to Dashboard
        </Button>
      </ButtonGroup>
    </HookForm>
  );
};
