import { DialogModal } from '@partstech/ui';
import { useCreateModal } from '@partstech/ui/hooks';

type Args = {
  onSubmit: () => void;
};

export const useIntegrationStatusModal = ({ onSubmit }: Args) =>
  useCreateModal(DialogModal, {
    title: 'Your request has been submitted',
    children: (
      <>
        We’ll reach out to your management system and see if integration is a possibility. If it is, we’ll contact you
        with further information.
      </>
    ),
    primaryText: 'Okay',
    onPrimary: onSubmit,
    unclosable: true,
  });
