import { api as activeCartApi } from 'features/cart/api/queries/GetActiveCart.generated';
import { useMutationStatus } from 'shared/api';
import { api as generatedApi } from './UpdateShippingMethod.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    UpdateAdditionalFields: {
      onQueryStarted: async ({ additionalFields, orderId }, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;

        if (
          data.updateActiveCartOrderAdditionalFields &&
          'updatedOrderId' in data.updateActiveCartOrderAdditionalFields
        ) {
          dispatch(
            activeCartApi.util.updateQueryData('GetActiveCart', undefined, (draft) => {
              draft?.activeCart?.orders?.forEach((order) => {
                if (order.id === orderId && order.additionalFields) {
                  Object.assign(order, { additionalFields });
                }
              });
            })
          );
        }
      },
    },
  },
});

const { useUpdateAdditionalFieldsMutation } = api;

export const useUpdateAdditionalFields = () => {
  const [updateShipping, status] = useUpdateAdditionalFieldsMutation();

  useMutationStatus({ status });

  return [updateShipping, status] as const;
};
