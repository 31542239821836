import { Box, ImageFallback, Typography } from '@partstech/ui';
import { PriceInfo } from './PriceInfo';
import type { Plan } from '../../../types';
import type { BoxProps } from '@partstech/ui';
import type { PropsWithChildren } from 'react';

type PlanContentProps = BoxProps &
  PropsWithChildren & {
    isFree: boolean;
    isLoading: boolean;
    plan: Plan;
    showDiscount?: boolean;
    showPriceInfo?: boolean;
  };

export const PlanContent = ({
  children,
  isFree,
  isLoading,
  plan,
  showDiscount,
  showPriceInfo = true,
  ...props
}: PlanContentProps) => {
  const { alias, description, isYearly, logo, name, priceMonthly, priceYearly } = plan;

  return (
    <Box display="flex" flexDirection="column" height={77} minWidth={40} {...props}>
      <Typography variant="subtitle1">{name}</Typography>

      <Box mt={1} flexBasis={8}>
        <Typography variant="caption">{description}</Typography>
      </Box>

      <Box mt={showPriceInfo ? 2 : 8}>
        <ImageFallback height={120} src={logo} alt={`${alias}Logo`} />
      </Box>

      {showPriceInfo && (
        <PriceInfo
          mt={1}
          isFree={isFree}
          isLoading={isLoading}
          isYearly={isYearly}
          priceMonthly={priceMonthly}
          priceYearly={priceYearly}
          showDiscount={showDiscount}
        />
      )}

      {!isLoading && children}
    </Box>
  );
};
