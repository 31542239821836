import { useCallback } from 'react';
import { useSetShowRetailPricingMutation } from 'store/mutations/currentUser/retailPricing/setShowRetailPricing';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';

export const useToggleRetailPrice = () => {
  const { showRetailPrice } = useGetCurrentUser();

  const [setShopRetailPricing] = useSetShowRetailPricingMutation();

  const handleRetailPricesModeChange = useCallback(
    (isChecked: boolean) => {
      setShopRetailPricing({ input: { showRetailPricing: isChecked } });
    },
    [setShopRetailPricing]
  );

  return { isShown: showRetailPrice, onToggle: handleRetailPricesModeChange };
};
