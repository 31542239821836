/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type DeleteCartOrderMutationVariables = Types.Exact<{
  input: Types.DeleteCartOrderInput;
}>;

export type DeleteCartOrderMutation = {
  __typename?: 'Mutation';
  deleteCartOrder?: {
    __typename?: 'DeleteCartOrderPayload';
    cart?: { __typename?: 'Cart'; id: string; active: Types.Scalars['Boolean']['input'] } | null;
  } | null;
};

export const DeleteCartOrderDocument = `
    mutation DeleteCartOrder($input: DeleteCartOrderInput!) {
  deleteCartOrder(input: $input) {
    cart {
      id
      active
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    DeleteCartOrder: build.mutation<DeleteCartOrderMutation, DeleteCartOrderMutationVariables>({
      query: (variables) => ({ document: DeleteCartOrderDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useDeleteCartOrderMutation } = injectedRtkApi;
