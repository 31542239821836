import axios from 'axios';
import { useCallback, useState, useEffect, useMemo } from 'react';
import { Routes } from 'constant';
import { useLogout } from 'hooks/app';
import { captureException } from 'integrations/sentry';
import { logout as logoutApi } from 'shared/api/rest/gen/shop';
import { checkModuleError } from 'shared/lib/bundling';
import { useAppNavigate } from '../AppRouter/useAppNavigate';
import type { CancelToken } from 'axios';
import type { ResponseError } from 'shared/api';

export const useErrorBoundary = () => {
  const navigate = useAppNavigate();

  const logout = useLogout();

  const [error, setError] = useState<(Error & ResponseError) | null>(null);

  const performLogout = useCallback(
    async (sourceToken?: CancelToken) => {
      await logoutApi({ cancelToken: sourceToken });

      logout();

      captureException(error);

      navigate(Routes.LOGIN);
    },
    [logout, error, navigate]
  );

  const handleErrorOccur = useCallback(
    async (sourceToken?: CancelToken) => {
      if (error && [401, 403].includes(error.code)) {
        performLogout(sourceToken);
      }
    },
    [error, performLogout]
  );

  useEffect(() => {
    let unmounted = false;
    const source = axios.CancelToken.source();

    if (!unmounted) {
      handleErrorOccur(source.token);
    }

    return () => {
      unmounted = true;
      source.cancel('Cancelling in cleanup');
    };
  }, [error, handleErrorOccur]);

  const askRefresh = useMemo(() => {
    if (error) {
      return checkModuleError(error);
    }

    return false;
  }, [error]);

  return { askRefresh, setError };
};
