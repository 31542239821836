import { Box, Button, Tag, Typography } from '@partstech/ui';
import { useCallback } from 'react';
import type { BoxProps } from '@partstech/ui';
import type { Filter } from 'types/search';

type Props = {
  values: Filter[];
  onClear: () => void;
  onValueRemove: (name: Filter['name'], value: string) => void;
} & BoxProps;

export const SelectedFilters = ({ values, onClear, onValueRemove, ...props }: Props) => {
  const handleTagCloseClick = useCallback(
    (name: Filter['name'], value: string) => () => {
      onValueRemove(name, value);
    },
    [onValueRemove]
  );

  return (
    <Box {...props}>
      <Box display="flex" mb={1} alignItems="center" justifyContent="space-between">
        <Typography variant="subtitle1">Selected Filters</Typography>
        <Button size="dense" variant="light" color="primary" onClick={onClear}>
          Clear all
        </Button>
      </Box>
      <Box display="flex" gap={1} flexWrap="wrap">
        {values.map((filter) =>
          filter.options.map((option) => (
            <Tag
              key={option.value}
              onClose={option.disabled ? undefined : handleTagCloseClick(filter.name, option.value)}
              data-testid={filter.name}
              disabled
            >
              {option.name}
            </Tag>
          ))
        )}
      </Box>
    </Box>
  );
};
