import { MenuItem, MenuAction, Menu } from '@partstech/ui';
import { useCallback } from 'react';
import { Loader } from './Loader';
import type { Place } from '../../../lib/types';

type Props = {
  options: Place[];
  onSelect: (values: Place) => void;
  onSelectNotFound: () => void;
  isLoading?: boolean;
};

export const PlacesMenu = ({ options, onSelect, onSelectNotFound, isLoading }: Props) => {
  const handleSelect = useCallback(
    (place: Place) => () => {
      onSelect(place);
    },
    [onSelect]
  );

  return (
    <Menu fullWidth builtIn footer={<MenuAction onClick={onSelectNotFound}>I don't see my shop</MenuAction>}>
      {isLoading ? (
        <Loader />
      ) : (
        options.map((option) => (
          <MenuItem key={option.id} secondaryText={option.address} onClick={handleSelect(option)}>
            {option.name}
          </MenuItem>
        ))
      )}
    </Menu>
  );
};
