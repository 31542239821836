import { subscriptionApi } from '../../queries/subscription';
import { api as generatedApi } from './CreateSubscription.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    CreateSubscription: {
      invalidatesTags: ['CurrentUser', 'Job', 'SubscriptionPayment', 'VehicleById', 'VehicleByPlateVin', 'PaymentCard'],
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        const shopSubscription = data.createSubscription?.shopSubscription;

        if (shopSubscription) {
          dispatch(
            subscriptionApi.util.updateQueryData('GetSubscription', undefined, ({ currentUser }) => {
              const shop = currentUser?.activeMember?.shop;

              if (shop) {
                Object.assign(shop, { shopSubscription });
              }
            })
          );
        }
      },
    },
  },
});

export const { useCreateSubscriptionMutation } = api;
