import { useCallback, useMemo, useState } from 'react';
import { AddingLaborContextProvider } from './AddingLaborContext';
import type { AddingLaborContextValue } from './AddingLaborContext';
import type { LaborApplication, LaborPartType } from 'models';
import type { PropsWithChildren } from 'react';

export const AddingLaborProvider = ({ children }: PropsWithChildren) => {
  const [selectedLaborApplications, setSelectedLaborApplications] = useState<LaborApplication[]>([]);

  const [selectedPartTypes, setSelectedPartTypes] = useState<LaborPartType[]>([]);

  const [customDurations, setCustomDurations] = useState<Record<string, number>>({});

  const toggleLaborApplication = useCallback((application: LaborApplication) => {
    setSelectedLaborApplications((prev) => {
      const isSelected = prev.some((prevApplication) => prevApplication.id === application.id);

      if (isSelected) {
        return prev.filter((prevApplication) => prevApplication.id !== application.id);
      }
      return [...prev, application];
    });
  }, []);

  const togglePartType = useCallback((partType: LaborPartType) => {
    setSelectedPartTypes((prev) => {
      const isSelected = prev.some((prevPartType) => prevPartType.id === partType.id);

      if (isSelected) {
        return prev.filter((prevPartType) => prevPartType.id !== partType.id);
      }
      return [...prev, partType];
    });
  }, []);

  const changeCustomDuration = useCallback((laborApplicationId: string, customDuration: number) => {
    setCustomDurations((prev) => ({ ...prev, [laborApplicationId]: customDuration }));
  }, []);

  const clear = useCallback(() => {
    setSelectedLaborApplications([]);
    setSelectedPartTypes([]);
    setCustomDurations({});
  }, []);

  const totalHours = useMemo(
    () =>
      selectedLaborApplications.reduce(
        (acc, application) => acc + (customDurations[application.id] || application.duration),
        0
      ),
    [customDurations, selectedLaborApplications]
  );

  const totalParts = selectedPartTypes.length;

  const checkedLaborMap = useMemo(
    () =>
      selectedLaborApplications.reduce<Record<string, boolean>>(
        (acc, application) => ({ ...acc, [application.id]: true }),
        {}
      ),
    [selectedLaborApplications]
  );

  const checkedPartTypesMap = useMemo(
    () => selectedPartTypes.reduce<Record<string, boolean>>((acc, partType) => ({ ...acc, [partType.id]: true }), {}),
    [selectedPartTypes]
  );

  const hasSelected = selectedLaborApplications.length > 0 || selectedPartTypes.length > 0;

  const contextValue: AddingLaborContextValue = useMemo(
    () => ({
      selectedLaborApplications,
      selectedPartTypes,
      customDurations,
      totalHours,
      totalParts,
      checkedLaborMap,
      checkedPartTypesMap,
      hasSelected,
      toggleLaborApplication,
      togglePartType,
      changeCustomDuration,
      clear,
    }),
    [
      selectedLaborApplications,
      selectedPartTypes,
      customDurations,
      totalHours,
      totalParts,
      checkedLaborMap,
      checkedPartTypesMap,
      hasSelected,
      toggleLaborApplication,
      togglePartType,
      changeCustomDuration,
      clear,
    ]
  );

  return <AddingLaborContextProvider value={contextValue}>{children}</AddingLaborContextProvider>;
};
