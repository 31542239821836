import { useCallback } from 'react';
import { useChangePasswordMutation } from './ChangePassword.generated';
import type { ChangePasswordInput } from 'shared/api';

export const useChangePassword = () => {
  const [changePassword, status] = useChangePasswordMutation();

  const handleChangePassword = useCallback((input: ChangePasswordInput) => changePassword({ input }), [changePassword]);

  return [handleChangePassword, status] as const;
};
