import { Box, Typography, Tag, Card, Button } from '@partstech/ui';
import React, { useCallback } from 'react';
import { PaidFeatureAlias, UpgradeBanner } from 'features/subscription';
import type { Job } from 'models';

type Props = {
  jobs: Job[];
  onSelect: (job: Job) => void;
};

export const FreeJobs = ({ jobs, onSelect }: Props) => {
  const handleJobClick = useCallback(
    (job: Job) => (e: React.MouseEvent) => {
      e.preventDefault();

      onSelect(job);
    },
    [onSelect]
  );

  return (
    <>
      {jobs.map((job) => (
        <Card
          mb={4}
          px={4}
          py={2}
          maxWidth={87}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          data-testid="freeJob"
          borderColor="mono6"
          key={job.id}
        >
          <Box display="flex" alignItems="center" gap={2}>
            <Typography variant="overline" textTransform="uppercase">
              {job.name}
            </Typography>
            <Tag variant="positive">free</Tag>
          </Box>
          <Button onClick={handleJobClick(job)}>Search</Button>
        </Card>
      ))}

      <UpgradeBanner feature={PaidFeatureAlias.Jobs} mb={2} ml={4}>
        Available with upgrade
      </UpgradeBanner>
    </>
  );
};
