import { SearchTypes } from 'types/search';
import { RecentSearchAbstract } from './RecentSearchAbstract';
import type { AbstractParams } from './RecentSearchAbstract';
import type { RecentSearchInterface } from './RecentSearchInterface';
import type { Vehicle } from 'models';
import type { CategoriesSearchFormValues } from 'types/search';

export class Categories extends RecentSearchAbstract implements RecentSearchInterface {
  type: string = 'Categories';

  vehicle: Vehicle;

  partTypes: Array<{ id: string; name: string }>;

  category: { id: string; name: string } | null;

  subCategory: { id: string; name: string } | null;

  partTextId: string | null | undefined;

  partText: string | null | undefined;

  constructor(
    id: string,
    vehicle: Vehicle,
    params: AbstractParams & {
      partTypes?: Array<{ id: string; name: string }> | null;
      partCategory?: { id: string; name: string } | null;
      partSubCategory?: { id: string; name: string } | null;
      partText?: { id?: string | null; text: string } | null;
    }
  ) {
    super(id, vehicle, params);

    this.vehicle = vehicle;
    this.partTypes = params.partTypes ?? [];
    this.category = params.partCategory ?? null;
    this.subCategory = params.partSubCategory ?? null;
    this.partTextId = params.partText?.id ?? null;
    this.partText = params?.partText?.text ?? '';
  }

  toString(): string {
    if (this.partTypes.length > 0) {
      return this.partTypes.map((partType) => partType.name).join(', ');
    }

    return `${this.category?.name ?? ''} \u2192 ${this.subCategory?.name ?? ''}`;
  }

  toSearchValues(): CategoriesSearchFormValues {
    return {
      type: SearchTypes.PARTS,
      vehicleId: this.vehicle.id,
      plate: this.plate,
      state: this.state,
      vin: this.vin,
      partTypes: this.partTypes.map((partType) => partType.id).map(Number),
      partText: this.partText ?? null,
      partTextId: this.partTextId ?? null,
      partCategory: this.category?.id ?? null,
      partSubCategory: this.subCategory?.id ?? null,
    };
  }
}
