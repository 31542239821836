import { useCallback } from 'react';
import { useAppLocation } from 'app/AppRouter/useAppLocation';
import { useAppNavigate } from 'app/AppRouter/useAppNavigate';
import { getAddSupplierUrl } from 'utils/url';
import type { AddSuppliersSearchParams, AddSuppliersTabs } from 'utils/url';

export const useOpenAddSupplierModal = () => {
  const navigate = useAppNavigate();

  const { state } = useAppLocation();

  const open = useCallback(
    (tabName: AddSuppliersTabs, search?: AddSuppliersSearchParams) => {
      navigate(getAddSupplierUrl(tabName, search), { state, replace: Boolean(search) });

      window.scrollTo(0, 0);
    },
    [navigate, state]
  );

  return { open };
};
