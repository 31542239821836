import { css } from '@emotion/react';
import { Tooltip, Button, Scrollbar, Drawer, Box, useFlyoutManager, useMedia } from '@partstech/ui';
import { memo } from 'react';
import { AppNavigationList } from 'components/AppNavigationList';
import { endOffset, useProfileBar } from './useProfileBar';
import type { Theme } from '@partstech/ui';

const styles = {
  profileBarMenu: (theme: Theme) => css`
    height: 100%;

    ${theme.breakpoints.down('md')} {
      padding: ${theme.sizing(2, 4)};
      max-height: 100%;
    }
  `,
};

export const ProfileBar = memo(() => {
  const { isMobile, isTablet } = useMedia();
  const { opened: openedFlyouts } = useFlyoutManager();

  const { elementRef, startOffset, isOpenProfileBar, selectItem, toggleProfileBar } = useProfileBar({
    disableExpaning: openedFlyouts.length > 0,
  });

  if (isMobile) {
    return null;
  }

  return (
    <Box ref={elementRef} position="fixed" zIndex="overview" data-testid="profileBar">
      <Drawer
        type="permanent"
        id="supplierSidebar"
        size={{ start: startOffset, end: endOffset }}
        position="left"
        isMinified={!isOpenProfileBar}
      >
        {isTablet && (
          <Box width={10} height={10} mb={8}>
            <Tooltip tooltip="Expand menu" disabled={isOpenProfileBar}>
              <Button
                variant="light"
                iconColor="subtleText"
                leadingIcon={isOpenProfileBar ? 'close' : 'menu'}
                onClick={toggleProfileBar}
                data-testid="menuButton"
              />
            </Tooltip>
          </Box>
        )}

        <Scrollbar css={styles.profileBarMenu}>
          <AppNavigationList onItemClick={selectItem} />
        </Scrollbar>
      </Drawer>
    </Box>
  );
});
