import { Box, Button, ButtonGroup, Typography, useMedia } from '@partstech/ui';
import { HookForm, FormTextAreaField, FormTextField, useForm } from '@partstech/ui/forms';
import { useCallback } from 'react';
import { useCreateSubscriptionHelpRequestMutation } from 'features/subscription';
import { useFormStatus, email, maxLength, required } from 'shared/lib/form';
import { useCreateSupportCaseMutation } from 'store/mutations/createSupportCase/CreateSupportCase.generated';
import type { SupportMode } from 'types/contactForm';

const salesForce = {
  text: maxLength(32000),
  fieldShort: maxLength(127),
  field: maxLength(255),
};

type SupportForm = {
  email: string;
  notes: string;
  firstName?: string;
  lastName?: string;
  username?: string;
};

type Props = {
  mode: SupportMode;
  defaultValues: Omit<SupportForm, 'notes'>;
  onCancel?: () => void;
  onSubmit?: () => void;
};

export const ContactForm = ({ defaultValues, mode, onCancel, onSubmit }: Props) => {
  const { isMobile } = useMedia();

  const [createSupportCase, createSupportCaseStatus] = useCreateSupportCaseMutation();
  const [createSubscriptionHelpRequest, createSubscriptionHelpRequestStatus] =
    useCreateSubscriptionHelpRequestMutation();

  const form = useForm<SupportForm>({
    defaultValues: { ...defaultValues, notes: '' },
    mode: 'onBlur',
  });

  const {
    formState: { isValid, isSubmitting },
  } = form;

  useFormStatus<SupportForm>({
    form,
    status: mode === 'globalSupport' ? createSupportCaseStatus : createSubscriptionHelpRequestStatus,
    onSuccess: onSubmit,
  });

  const handleSubmit = useCallback(
    async (values: SupportForm) => {
      if (mode === 'globalSupport') {
        await createSupportCase({
          input: {
            email: values.email,
            username: values?.username ?? '',
            notes: values.notes,
          },
        });
      } else {
        await createSubscriptionHelpRequest({
          input: {
            email: values.email,
            firstName: values?.firstName ?? '',
            lastName: values?.lastName ?? '',
            notes: values.notes,
          },
        });
      }
    },
    [createSubscriptionHelpRequest, createSupportCase, mode]
  );

  return (
    <Box display="flex" flexDirection="column" flexWrap="wrap">
      <Box mb={6}>
        <Typography>
          The PartsTech Support team is always here to help! Just let us know what you need and we’ll get back to you as
          soon as we can.
        </Typography>

        <Box mt={2}>
          <Typography>All fields are required.</Typography>
        </Box>
      </Box>

      <HookForm form={form} onSubmit={handleSubmit}>
        {mode === 'globalSupport' ? (
          <Box mb={{ sm: 5, md: 6 }}>
            <FormTextField name="username" id="username" label="Username" rules={{ required }} />
          </Box>
        ) : (
          <Box display="flex" justifyContent="space-between" flexDirection={{ sm: 'column', md: 'row' }}>
            <Box mb={{ sm: 5, md: 6 }} flex="1" mr={{ md: 2 }}>
              <FormTextField
                name="firstName"
                id="firstName"
                label="First name"
                rules={{ maxLength: salesForce.fieldShort, required }}
              />
            </Box>

            <Box mb={{ sm: 5, md: 6 }} flex="1">
              <FormTextField
                name="lastName"
                id="lastName"
                label="Last name"
                rules={{ maxLength: salesForce.fieldShort, required }}
              />
            </Box>
          </Box>
        )}

        <Box mb={{ sm: 5, md: 6 }}>
          <FormTextField
            name="email"
            id="email"
            label="Email address"
            rules={{ pattern: email, maxLength: salesForce.field, required }}
            normalize={(value) => value.trim()}
          />
        </Box>

        <Box mb={{ sm: 5, md: 6 }}>
          <FormTextAreaField
            name="notes"
            id="notes"
            label="Message the team"
            rules={{ maxLength: salesForce.text, required }}
            height={19}
          />
        </Box>

        <ButtonGroup flexDirection={{ sm: 'column', md: 'row' }}>
          <Button type="submit" disabled={!isValid || isSubmitting} isLoading={isSubmitting} fullWidth={isMobile}>
            Submit message
          </Button>

          <Button onClick={onCancel} variant="text" fullWidth={isMobile}>
            Cancel
          </Button>
        </ButtonGroup>
      </HookForm>
    </Box>
  );
};
