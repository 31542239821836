/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import type * as Types from '../../../../shared/api/graphql/graphql';

export type SupplierAccountFragment = {
  __typename?: 'Account';
  customerDescription?: Types.Scalars['String']['input'] | null;
  id: string;
  nickname?: Types.Scalars['String']['input'] | null;
  priority: number;
  status: Types.AccountStatus;
  additionalInformation: Array<{
    __typename?: 'AccountField';
    name: Types.Scalars['String']['input'];
    value?: Types.Scalars['String']['input'] | null;
  }>;
  credentials: Array<{
    __typename?: 'AccountField';
    name: Types.Scalars['String']['input'];
    value?: Types.Scalars['String']['input'] | null;
  }>;
};

export const SupplierAccountFragmentDoc = `
    fragment SupplierAccount on Account {
  additionalInformation {
    name
    value
  }
  credentials {
    name
    value
  }
  customerDescription
  id
  nickname
  priority
  status
}
    `;
