import { useCreateModal } from '@partstech/ui/hooks';
import { useCallback, useEffect } from 'react';
import { useAppLocation } from 'app/AppRouter/useAppLocation';
import { useAppNavigate } from 'app/AppRouter/useAppNavigate';
import { ProfileModal } from 'components/Modal/ProfileModal';
import { HashRoutes } from 'constant';

export const useProfileModal = () => {
  const navigate = useAppNavigate();
  const { hash, ...rest } = useAppLocation();

  const handleClose = useCallback(() => navigate(rest), [navigate, rest]);

  const { open } = useCreateModal(ProfileModal, { size: 'dialog', onClose: handleClose }, { customId: 'profile' });

  useEffect(() => {
    if (hash !== HashRoutes.PROFILE) {
      return;
    }

    open();
  }, [open, hash]);
};
