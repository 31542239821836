import { isEmpty } from '@partstech/ui/utils';
import { useCallback } from 'react';
import { useMutationCallback, type GraphQLError, type UseMutationCallbackArgs } from 'shared/api';
import type { FieldValues, Path, UseFormSetError } from '@partstech/ui/forms';
import type { BaseQueryFn } from '@reduxjs/toolkit/query';
import type { TypedUseMutation } from '@reduxjs/toolkit/query/react';

const getValidationErrors = <FormData extends FieldValues = {}>(graphQLError: GraphQLError<FormData>) =>
  graphQLError?.getValidationErrors().filter((validationError) => !isEmpty(validationError.path)) || [];

type Args<ResultType, FormData extends FieldValues> = {
  onValidationError: UseFormSetError<FormData>;
} & UseMutationCallbackArgs<ResultType, FormData>;

/**
 * A hook that returns a callback for handling the result of a mutation.
 * It triggers success and error callbacks based on the mutation status, and provides a function to trigger the mutation.
 * It also provides a `status` object that contains the current status of the mutation.
 * This hook is specifically designed to handle form data and validation errors.
 * It accepts an `onValidationError` function that sets form errors based on the GraphQL error.
 * It also accepts optional success and error callbacks, and provides a function to trigger the mutation.
 * It uses the `useMutationCallback` hook internally to handle the mutation status.
 * It also uses the `getValidationErrors` function to extract validation errors from the GraphQL error.
 * If the `shouldShowErrorMessage` function is provided, it will determine whether to show the error message.
 * If not provided, it will show the error message by default.
 * If the GraphQL error contains validation errors, it will not show the error message.
 * @param {TypedUseMutation} useMutation - The `useMutation` hook from `@reduxjs/toolkit/query/react`.
 * @param {Args} params - An object containing:
 * - `onValidationError`: A function that sets form errors based on the GraphQL error.
 * - `onSuccess` (optional): A callback function that triggers when the mutation succeeds.
 * - `onError` (optional): A callback function that triggers when the mutation fails.
 * - `shouldShowErrorMessage` (optional): A function that determines whether to show the error message.
 * - `successMessage` (optional): A string message to display when the mutation succeeds.
 * - `errorMessage` (optional): A string message to display when the mutation fails. If not provided,
 *  the hook will show a default error message based on the GraphQL error or a generic message.
 *
 * @returns {Array} - Returns an array with two elements:
 * - `mutationTrigger`: A function that triggers the mutation and handles the result.
 * - `status`: An object containing the current status of the mutation.
 *
 * @example
 * ```tsx
 * const [mutateData, status] = useFormMutationCallback(mutateDataMutation, {
 *   onValidationError: (fieldName, error) => setError(fieldName, { message: error.message }),
 *   onSuccess: (data) => console.log('Data saved successfully:', data),
 *   onError: (error) => console.error('Failed to save data:', error),
 *   successMessage: 'Data saved successfully!',
 *   errorMessage: 'Failed to save data',
 * });
 * ```
 * @knipignore
 */
export const useFormMutationCallback = <
  ResultType,
  BaseQuery extends BaseQueryFn,
  QueryArg,
  FormData extends FieldValues = {},
>(
  useMutation: TypedUseMutation<ResultType, QueryArg, BaseQuery>,
  { onValidationError, shouldShowErrorMessage, onError, ...args }: Args<ResultType, FormData>
) => {
  const shouldValidateError = useCallback(
    (graphQLError: GraphQLError<FormData>) => {
      const validationErrors = getValidationErrors(graphQLError);

      if (validationErrors.length > 0) {
        return false;
      }

      return shouldShowErrorMessage?.(graphQLError) ?? true;
    },
    [shouldShowErrorMessage]
  );

  const validationErrorHandler = useCallback(
    async (error: GraphQLError<FormData>) => {
      const validationErrors = getValidationErrors(error);

      if (!validationErrors.length) {
        await onError?.(error);
        return;
      }

      validationErrors.forEach((validationError) => {
        const { path } = validationError;
        const fieldName = (path && path.length > 3 ? path.slice(2).join('.') : path?.[2]) as Path<FormData>;

        onValidationError(fieldName ?? 'root', validationError);
      });
    },
    [onError, onValidationError]
  );

  return useMutationCallback(useMutation, {
    ...args,
    shouldShowErrorMessage: shouldValidateError,
    onError: validationErrorHandler,
  });
};
