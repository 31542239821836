import { useState } from 'react';
import { PLAN_FREE } from '../../../constants/plans';
import { PlanTabs } from './PlanTabs';
import { SelectedPlanCard } from './SelectedPlanCard';
import type { PaidFeatureAlias } from '../../../constants/features';
import type { ProductAlias } from '../../../constants/products';
import type { Plan } from '../../../types';

type Props = {
  highlightFeature: PaidFeatureAlias | null;
  isRestrictedEX: boolean;
  plans: Plan[];
  getPlanByAlias: (alias?: ProductAlias) => Plan | undefined;
  periodToggle: () => void;
};

export const MobileView = ({ highlightFeature, isRestrictedEX, plans, getPlanByAlias, periodToggle }: Props) => {
  const [activeTab, setActiveTab] = useState(plans[0]?.alias ?? PLAN_FREE.alias);

  const selectedPlan = getPlanByAlias(activeTab);

  return (
    <>
      {!isRestrictedEX && <PlanTabs activeTab={activeTab} plans={plans} onChange={setActiveTab} />}

      {selectedPlan && (
        <SelectedPlanCard
          mt={6}
          mb={4}
          mx={4}
          highlightFeature={highlightFeature}
          isPlanIncluded={isRestrictedEX}
          plan={selectedPlan}
          periodToggle={periodToggle}
        />
      )}
    </>
  );
};
