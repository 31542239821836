import { resolvePath } from 'react-router-dom';
import { isModalPathname } from './isModalPathname';
import type { To } from 'react-router-dom';

export const isPathNeedBackground = (from: string, to: To) => {
  const prevPageIsModal = isModalPathname(from);
  const nextPageIsModal = isModalPathname(resolvePath(to).pathname);

  return !prevPageIsModal && nextPageIsModal;
};
