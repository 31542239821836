/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../shared/api/graphql/graphql';

export type GetCustomPreferredBrandsQueryVariables = Types.Exact<{
  partType: Types.ProductType;
}>;

export type GetCustomPreferredBrandsQuery = {
  __typename?: 'Query';
  currentUser?: {
    __typename?: 'User';
    activeMember?: {
      __typename?: 'ShopMember';
      shop?: {
        __typename?: 'Shop';
        customPreferredBrands?: Array<{
          __typename?: 'CustomPreferredBrand';
          priority: number;
          type: Types.ProductType;
          brand?: { __typename?: 'Brand'; id: string; name: Types.Scalars['String']['input'] } | null;
          brands?: Array<{ __typename?: 'Brand'; id: string; name: Types.Scalars['String']['input'] }> | null;
        }> | null;
      } | null;
    } | null;
  } | null;
};

export const GetCustomPreferredBrandsDocument = `
    query GetCustomPreferredBrands($partType: ProductType!) {
  currentUser {
    activeMember {
      shop {
        customPreferredBrands(partType: $partType) {
          priority
          type
          brand {
            id
            name
          }
          brands {
            id
            name
          }
        }
      }
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetCustomPreferredBrands: build.query<GetCustomPreferredBrandsQuery, GetCustomPreferredBrandsQueryVariables>({
      query: (variables) => ({ document: GetCustomPreferredBrandsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetCustomPreferredBrandsQuery, useLazyGetCustomPreferredBrandsQuery } = injectedRtkApi;
