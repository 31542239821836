import { api as activeCartApi } from 'features/cart/api/queries/GetActiveCart.generated';
import { useMutationStatus } from 'shared/api';
import { api as generatedApi } from './UpdateLaborRateInCart.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    UpdateLaborRateInCart: {
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;

        dispatch(
          activeCartApi.util.updateQueryData('GetActiveCart', undefined, (draft) => {
            const { activeCart } = draft;

            if (!activeCart) {
              return;
            }

            if (data.updateActiveCartLaborRate && 'cart' in data.updateActiveCartLaborRate) {
              const laborRate = data.updateActiveCartLaborRate.cart?.laborRate;
              Object.assign(activeCart, { laborRate });
            }
          })
        );
      },
    },
  },
});

const { useUpdateLaborRateInCartMutation } = api;

export const useUpdateLaborRateInCart = () => {
  const [mutate, status] = useUpdateLaborRateInCartMutation();

  useMutationStatus({ status });

  return [mutate, status] as const;
};
