import { useMemo } from 'react';
import { usePermissions } from 'store/queries/currentUser/usePermissions';
import { getServiceGuidesUrl } from 'utils/url';

export const useServiceGuidesOptions = (vehicleId: string, vin?: string) => {
  const {
    shop: { schedulesAllowed, specsAllowed, laborAllowed },
  } = usePermissions();

  const options = useMemo(() => {
    const labor = {
      name: 'Labor',
      url: getServiceGuidesUrl({ vehicleId, vin, tab: 'labor' }),
      isAllowed: laborAllowed,
    };

    const maintenance = {
      name: 'Maintenance',
      url: getServiceGuidesUrl({ vehicleId, vin, tab: 'maintenance' }),
      isAllowed: schedulesAllowed,
    };

    const fluids = {
      name: 'Fluids',
      url: getServiceGuidesUrl({ vehicleId, vin, tab: 'fluids' }),
      isAllowed: specsAllowed,
    };

    const specs = {
      name: 'Specifications',
      url: getServiceGuidesUrl({ vehicleId, vin, tab: 'specs' }),
      isAllowed: specsAllowed,
    };

    return [labor, maintenance, fluids, specs].filter((option) => option.isAllowed);
  }, [laborAllowed, schedulesAllowed, specsAllowed, vehicleId, vin]);

  return { schedulesAllowed, specsAllowed, options };
};
