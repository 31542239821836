import { useEffect, useMemo, useState } from 'react';
import { useGetVehicle } from 'store/queries/vehicle/useGetVehicle';
import type { Vehicle } from 'models';

export const useSelectedVehicle = (defaultVehicle: Vehicle | null) => {
  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | null>(defaultVehicle);

  const plate = useMemo(() => {
    if (!selectedVehicle?.plate || !selectedVehicle?.state) {
      return undefined;
    }

    return { plate: selectedVehicle.plate, state: selectedVehicle.state };
  }, [selectedVehicle?.plate, selectedVehicle?.state]);

  const { vehicle, isSuccess } = useGetVehicle({ id: selectedVehicle?.id, plate, vin: selectedVehicle?.vin });

  useEffect(() => {
    if (defaultVehicle) {
      setSelectedVehicle(defaultVehicle);
    }
  }, [defaultVehicle]);

  useEffect(() => {
    if (!isSuccess) {
      return;
    }

    setSelectedVehicle(vehicle);
  }, [isSuccess, vehicle]);

  return [selectedVehicle, setSelectedVehicle] as const;
};
