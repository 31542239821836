import { useResizeObserver } from '@partstech/ui/hooks';
import { useRef, useState } from 'react';

export const useHeaderHeight = () => {
  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const headerRef = useRef<HTMLDivElement | null>(null);

  useResizeObserver(headerRef.current, ({ clientHeight }) => {
    setHeaderHeight(clientHeight);
  });

  return { headerRef, headerHeight };
};
