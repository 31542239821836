/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type ChangeActiveSupplierMembershipMutationVariables = Types.Exact<{
  input: Types.ChangeActiveSupplierMembershipInput;
}>;

export type ChangeActiveSupplierMembershipMutation = {
  __typename?: 'Mutation';
  changeActiveSupplierMembership?: { __typename: 'ChangeActiveSupplierMembershipPayload' } | null;
};

export const ChangeActiveSupplierMembershipDocument = `
    mutation ChangeActiveSupplierMembership($input: ChangeActiveSupplierMembershipInput!) {
  changeActiveSupplierMembership(input: $input) {
    __typename
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    ChangeActiveSupplierMembership: build.mutation<
      ChangeActiveSupplierMembershipMutation,
      ChangeActiveSupplierMembershipMutationVariables
    >({
      query: (variables) => ({ document: ChangeActiveSupplierMembershipDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useChangeActiveSupplierMembershipMutation } = injectedRtkApi;
