import { css } from '@emotion/react';
import { Box, Scrollbar, Typography, useMedia } from '@partstech/ui';
import { useEffect } from 'react';
import { OptionsList } from '../../OptionsList';
import { useParametersList } from '../useParametersList';
import { useGetMakes } from './useGetMakes';
import type { CommonParametersListProps } from '../types';
import type { Theme } from '@partstech/ui';

const styles = {
  button: css`
    outline: none;
    border: none;
    background: none;
    cursor: pointer;
    text-align: left;
  `,
  scrollbar: (theme: Theme) => css`
    flex: 0 1 100%;
    max-height: calc(100vh - ${theme.sizing(67)});
    margin: ${theme.sizing(0, -4, 0, 0)};
    padding: ${theme.sizing(0, 4, 0, 4)};

    ${theme.breakpoints.down('md')} {
      max-height: none;
      margin: 0;
      padding: 0;
    }
  `,
};

export const MakesList = ({
  searchValue,
  onChange,
  isUserFocused,
  makeId: selectedValue,
  ...props
}: CommonParametersListProps) => {
  const { isMobile } = useMedia();

  const { category: currentCategory, categories, onCategoryClick, makes } = useGetMakes({ searchValue, ...props });
  const { updateValue } = useParametersList({
    fieldName: 'makeId',
    options: makes,
    onChange,
    isUserFocused,
    searchValue,
    selectedValue,
  });

  useEffect(() => {
    if (currentCategory !== 'default' && isMobile) {
      onCategoryClick('default')();
    }
  }, [currentCategory, isMobile, onCategoryClick]);

  return (
    <Box display="flex" alignItems="baseline" width="100%">
      {!isMobile && (
        <Box display="flex" flexDirection="column" width={40}>
          {categories.map((category) => (
            <button css={styles.button} onClick={onCategoryClick(category.value)} key={category.value}>
              <Typography bold={currentCategory === category.value}>{category.name}</Typography>
            </button>
          ))}
        </Box>
      )}

      <Scrollbar css={styles.scrollbar}>
        <OptionsList options={makes} onClick={updateValue} columnCount={isMobile ? 1 : undefined} />
      </Scrollbar>
    </Box>
  );
};
