import { css } from '@emotion/react';
import { Box, Typography, useMedia } from '@partstech/ui';
import { memo } from 'react';
import { Price as PriceView } from 'components/Product/Pricing/Price';
import { currency } from 'shared/lib/string';
import {
  getAdditionalCoreCharge,
  getAdditionalFetCharge,
  getAdditionalInSavings,
  getAdditionalListPrice,
  getAdditionalMAPPrice,
  getAdditionalRetailPrice,
} from 'utils';
import type { TypographyProps } from '@partstech/ui';
import type { PricingFlags } from 'types/product';

type Props = {
  coreCharge?: number | null;
  fetCharge?: number | null;
  listPrice?: number | null;
  price: number;
  retailPrice?: number | null;
  mapPrice?: number | null;
  size?: 'default' | 'small';
  fetPricePosition?: 'left' | 'right';
  retailPricingAllowed: boolean;
} & Partial<PricingFlags>;

const styles = {
  fetWrapper: css`
    white-space: nowrap;
  `,
};

export const AdditionalPrices: React.FunctionComponent<Props> = memo(
  ({
    coreCharge,
    price,
    fetCharge,
    listPrice,
    retailPrice,
    mapPrice,
    size = 'default',
    hasSavings = false,
    showOnlyRetailPrice = false,
    isTire = false,
    fetPricePosition = 'left',
    retailPricingAllowed,
  }) => {
    const { isMobile } = useMedia();

    const flags = {
      showOnlyRetailPrice,
      isTire,
      hasSavings,
    };

    const additionalRetailPrice = getAdditionalRetailPrice(retailPrice, price, flags);

    const additionalListPrice = getAdditionalListPrice(listPrice, flags);
    const additionalCoreCharge = getAdditionalCoreCharge(coreCharge, flags);
    const additionalInSavings = getAdditionalInSavings(retailPrice, price, flags);
    const additionalFetCharge = getAdditionalFetCharge(fetCharge);
    const additionalMAPPrice = getAdditionalMAPPrice(mapPrice, flags);

    const gap = size === 'default' && !isMobile ? 1 : 0;
    const additionalPriceVariant: TypographyProps['variant'] =
      size === 'default' && !isMobile ? 'bodyText2' : 'caption';

    return (
      <>
        {Boolean(additionalFetCharge) && (
          <Box
            display="flex"
            alignItems="baseline"
            mt={1}
            css={styles.fetWrapper}
            data-testid="fetCharge"
            justifyContent={fetPricePosition === 'left' ? 'flex-start' : 'flex-end'}
          >
            <PriceView label="FET" price={Number(fetCharge)} color="mono40" variant={additionalPriceVariant} />
          </Box>
        )}
        {Boolean(additionalInSavings) && (
          <Box data-testid="inSavings" mt={gap}>
            <Typography variant={additionalPriceVariant} color="mono40">
              in savings: {currency(Number(additionalInSavings))}
            </Typography>
          </Box>
        )}
        {Boolean(additionalCoreCharge) && !showOnlyRetailPrice && (
          <Box data-testid="coreCharge" mt={gap}>
            <PriceView label="Core" price={Number(coreCharge)} color="mono40" variant={additionalPriceVariant} />
          </Box>
        )}
        {Boolean(additionalListPrice) && (
          <Box data-testid="listPrice" mt={gap}>
            <PriceView label="List" price={Number(listPrice)} color="mono40" variant={additionalPriceVariant} />
          </Box>
        )}

        {Boolean(additionalRetailPrice) && retailPricingAllowed && (
          <Box data-testid="retailPrice" mt={gap}>
            <PriceView
              label="Retail"
              price={Number(additionalRetailPrice)}
              color="mono40"
              variant={additionalPriceVariant}
            />
          </Box>
        )}

        {Boolean(additionalMAPPrice) && (
          <Box data-testid="mapPrice" mt={gap}>
            <PriceView label="MAP" price={Number(additionalMAPPrice)} color="mono40" variant={additionalPriceVariant} />
          </Box>
        )}

        {showOnlyRetailPrice && (
          <Typography variant="caption" color="mono40" component="div">
            Retail
          </Typography>
        )}
      </>
    );
  }
);
