export enum PagePermission {
  /**
   * Only for public users
   */
  PUBLIC = 'PUBLIC',
  /**
   * Only for unauthorized users
   */
  UNAUTHORIZED = 'UNAUTHORIZED',
  /**
   * Only for authorized users
   */
  AUTHORIZED = 'AUTHORIZED',
  /**
   * Only for creating shops
   */
  AUTHORIZED_NO_SHOP = 'AUTHORIZED_NO_SHOP',
}
