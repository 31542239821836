import { css } from '@emotion/react';
import { Box, Button, Card, DialogModal, Icon, Typography, useMedia } from '@partstech/ui';
import { useCreateModal } from '@partstech/ui/hooks';
import { voidFunctionStub } from '@partstech/ui/utils';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { KeywordSearch, SearchForm, SearchInputSize, SearchTabs } from 'features/searchForm';
import {
  PartNumber,
  TireSize,
  type AvailabilityLine,
  type Product,
  type SupplierAccount,
  TireConfiguration,
} from 'models';
import { SearchTypes } from 'types/search';
import { StockOrderRow } from '../StockOrderRow';
import { SupplierAccountSelector } from '../SupplierAccountSelector';
import { NoResultsBlock } from './components';
import type { CardProps, Theme } from '@partstech/ui';
import type { SearchEntryPointInterface, SearchFormRef } from 'features/searchForm';
import type { StockOrder } from 'types/stockOrders';

export type StockOrderCardProps = {
  isFirst: boolean;
  isLoading?: boolean;
  onLineRemove: (data: { stockOrderId: string; index: number }) => void;
  onMoreResultsClick: (data: { stockOrder: StockOrder; index: number }) => void;
  onQuantityChange: (data: { stockOrderId: string; value: number; index: number }) => void;
  onRemove: (stockOrderId: string) => void;
  onSearch: (data: { stockOrderId: string; supplierAccountId: string; value: string; brandName?: string }) => void;
  onStoreChange: (data: { stockOrderId: string; value: AvailabilityLine; index: number }) => void;
  onSupplierAccountChange: (data: { stockOrder: StockOrder; value: SupplierAccount }) => void;
  results?: Record<string, { isLoading: boolean; products: Product[]; errors?: string[] | undefined }>;
  stockOrder: StockOrder;
  supplierAccountList: SupplierAccount[];
};

const styles = {
  quantityInput: (theme: Theme) => css`
    margin: 0 0 ${theme.sizing(4)};
  `,

  tableHeader: (theme: Theme) => css`
    display: grid;
    grid-template-columns: ${theme.sizing(14)} 1fr 1fr 1fr ${theme.sizing(18)} 1fr;

    & > p:not(:first-of-type) {
      text-align: end;
    }
  `,

  mainCol: css`
    grid-column: 1/5;
  `,
};

const searchFormCardProps: CardProps = {
  elevation: 6,
  radius: 8,
  height: 68,
};

export const StockOrderCard = ({
  isFirst,
  isLoading = false,
  onLineRemove,
  onMoreResultsClick,
  onQuantityChange,
  onRemove,
  onSearch,
  onStoreChange,
  onSupplierAccountChange,
  results,
  stockOrder,
  supplierAccountList,
}: StockOrderCardProps) => {
  const { isMobile } = useMedia();

  const searchFormRef = useRef<SearchFormRef | null>(null);

  useEffect(() => {
    window.scrollTo(0, document.body.scrollHeight);
  }, []);

  const handleSupplierAccountChange = useCallback(
    (value: SupplierAccount) => onSupplierAccountChange({ value, stockOrder }),
    [onSupplierAccountChange, stockOrder]
  );

  const { open } = useCreateModal(
    DialogModal,
    {
      children: (
        <>
          <Box mb={4}>
            <Typography variant={isMobile ? 'subtitle1' : 'h3'}>
              Are you sure you want to delete this stock order?
            </Typography>
          </Box>
          <Typography variant="bodyText1">
            This will delete all of the items in this card. You cannot undo this action.
          </Typography>
        </>
      ),
      size: isMobile ? 'bottomSheet' : 'small',
      primaryText: 'Delete',
      primaryColor: 'negative',
      secondaryText: 'Cancel',
      onPrimary: () => onRemove(stockOrder.id),
    },
    { customId: 'forbiddenDialog' }
  );

  const handleSearchNew = useCallback(
    (searchEntry: SearchEntryPointInterface) => {
      if (!stockOrder.supplierAccount?.id) {
        return;
      }

      const commonPayload = {
        stockOrderId: stockOrder.id,
        supplierAccountId: stockOrder.supplierAccount.id,
      };

      if (searchEntry instanceof KeywordSearch) {
        onSearch({
          ...commonPayload,
          value: searchEntry.keyword,
        });
      }

      if (searchEntry instanceof PartNumber) {
        onSearch({
          ...commonPayload,
          value: searchEntry.partNumber,
          brandName: searchEntry.brandName || undefined,
        });
      }

      if (searchEntry instanceof TireSize || searchEntry instanceof TireConfiguration) {
        onSearch({
          ...commonPayload,
          value: searchEntry.toString(),
        });
      }

      searchFormRef.current?.clear();
    },
    [onSearch, stockOrder.id, stockOrder.supplierAccount?.id]
  );

  const handleStoreChange = useCallback(
    (value: AvailabilityLine, index: number) => {
      onStoreChange({ stockOrderId: stockOrder.id, index, value });
    },
    [onStoreChange, stockOrder.id]
  );

  const handleQuantityChange = useCallback(
    (value: string, index: number) => {
      onQuantityChange({ stockOrderId: stockOrder.id, index, value: Number(value) });
    },
    [onQuantityChange, stockOrder.id]
  );

  const isTiresSupplierAccount = Boolean(stockOrder.supplierAccount?.supplier?.isTire);

  const emptySearchQueries = useMemo(
    () =>
      stockOrder.lines
        .filter((_, index) => results && results[index]?.products.length === 0 && !results[index]?.isLoading)
        .map((line) => line.searchQuery ?? ''),
    [results, stockOrder.lines]
  );

  const handleMoreResultsClick = useCallback(
    (index: number) => {
      onMoreResultsClick({ stockOrder, index });
    },
    [onMoreResultsClick, stockOrder]
  );

  const handleRowRemove = useCallback(
    (index: number) => {
      onLineRemove({ stockOrderId: stockOrder.id, index });
    },
    [onLineRemove, stockOrder.id]
  );

  const handleSearchButtonClickNew = useCallback(() => {
    searchFormRef.current?.submit();
  }, []);

  const allLinesHasEmptyResults = stockOrder.lines.length > 0 && stockOrder.lines.length === emptySearchQueries.length;

  const checkSuggestionType = useCallback(
    (suggestion: SearchEntryPointInterface) => suggestion instanceof TireSize || suggestion instanceof PartNumber,
    []
  );

  const placeholder = isTiresSupplierAccount
    ? 'Search by part # or tire size (ex: 2256517 or 225/65R17)'
    : 'Search by part number';

  return (
    <Card p={{ sm: 2, md: 5 }} mb={6} data-testid="stockOrderCard">
      <Box display="flex" justifyContent="space-between" gap={2} mb={2}>
        <SupplierAccountSelector
          canChangeSupplierType={stockOrder.lines.length === 0}
          isLoading={isLoading}
          onChange={handleSupplierAccountChange}
          supplierAccounts={supplierAccountList}
          selectedSupplierAccountId={stockOrder.supplierAccount?.id ?? null}
        />

        <Button
          leadingIcon="delete"
          variant="text"
          color="negative"
          data-testid="deleteCardButton"
          onClick={() => open()}
          disabled={isFirst && stockOrder.lines.length === 0}
        />
      </Box>

      <Box mb={4}>
        <SearchForm
          adornmentAfter={
            <Box mr={{ sm: 0, md: 2 }}>
              <Button
                onClick={handleSearchButtonClickNew}
                data-testid="searchButton"
                variant={isMobile ? 'light' : 'primary'}
              >
                <Icon name="search" />
              </Button>
            </Box>
          }
          extendsOnFocus={false}
          fullSize={false}
          onChange={handleSearchNew}
          onTabChange={voidFunctionStub}
          ref={searchFormRef}
          size={SearchInputSize.DENSE}
          tab={isTiresSupplierAccount ? SearchTabs.TIRES : SearchTabs.POPULAR}
          type={isTiresSupplierAccount ? SearchTypes.TIRES : SearchTypes.PARTS}
          vehicle={null}
          withSearchEntryPoints={false}
          suggestionFilter={checkSuggestionType}
          placeholder={placeholder}
          offset={4}
          usePortal={false}
          cardProps={searchFormCardProps}
        />
      </Box>

      {stockOrder.lines.length === 0 && (
        <Box p={{ sm: 3, md: 5 }} textAlign="center" data-testid="emptyCardMessage">
          <Typography variant={isMobile ? 'subtitle2' : 'subtitle1'} color="subtleText">
            Your stock order is empty
          </Typography>

          <Typography color="subtleText" variant={isMobile ? 'bodyText2' : 'bodyText1'}>
            {isTiresSupplierAccount
              ? 'Search for tires to start building your stock order.'
              : 'Search for parts to start building your stock order.'}
          </Typography>
        </Box>
      )}

      {emptySearchQueries.length > 0 && <NoResultsBlock items={emptySearchQueries} />}

      {stockOrder.lines.length > 0 && !allLinesHasEmptyResults && !isMobile && (
        <Box gap={2} css={styles.tableHeader}>
          <Typography variant="bodyText2" css={styles.mainCol}>
            {isTiresSupplierAccount ? 'Tire' : 'Part'}
          </Typography>
          <Typography variant="bodyText2">Qty.</Typography>
          <Typography variant="bodyText2">Price</Typography>
        </Box>
      )}

      {stockOrder.lines.map((line, index) => {
        const moreResults = results && results[index];
        const isProductListEmpty = !moreResults?.isLoading && moreResults?.products.length === 0;

        if (!moreResults || isProductListEmpty) {
          return null;
        }

        return (
          <StockOrderRow
            key={line.searchQuery + index}
            hasMoreResults={moreResults.products.length > 1 || !line.selectedProduct}
            isLoading={moreResults.isLoading}
            line={line}
            index={index}
            onAvailableStoreChange={handleStoreChange}
            onMoreResultsClick={handleMoreResultsClick}
            onQuantityChange={handleQuantityChange}
            onRemoveRow={handleRowRemove}
          />
        );
      })}
    </Card>
  );
};
