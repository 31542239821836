import { useCallback } from 'react';
import type { KeyboardEvent } from 'react';

export const useDisableEnterPress = () => {
  const disableEnterPress = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }, []);

  return { disableEnterPress };
};
