import { useModalHistory } from '@partstech/ui';
import { useCallback } from 'react';
import type { PaymentCardModalData, PaymentCardModalPaths } from '../router';
import type { PaymentCard } from 'entities/paymentCard';

export const useAddCard = () => {
  const { reset, onClose, onSubmit, setData, data, state } = useModalHistory<
    PaymentCardModalPaths,
    'addCard',
    PaymentCardModalData
  >();

  const changePaymentMethod = useCallback(
    (card: PaymentCard) => {
      setData({ card, paymentMethod: card });

      if (data.paymentMethod) {
        reset('paymentCardUpdated', {
          ...state,
          stripeKey: state?.stripeKey ?? '',
          cardForRemoving: data.paymentMethod,
        });
      }
    },
    [data.paymentMethod, reset, setData, state]
  );

  const submitForm = useCallback(
    (card: PaymentCard) => {
      onSubmit({ card, paymentMethod: null });

      onClose();
    },
    [onClose, onSubmit]
  );

  return {
    stripeKey: state?.stripeKey ?? '',
    paymentMethod: data?.paymentMethod ?? null,
    showCardOptions: state?.showCardOptions ?? false,
    changePaymentMethod,
    submitForm,
    close: onClose,
  };
};
