import { forwardRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

type Props = {
  reCaptchaKey: string;
  onChange: (token: string | null) => void;
};

export const GoogleReCaptcha = forwardRef<ReCAPTCHA, Props>(({ reCaptchaKey, onChange }, ref) => (
  <ReCAPTCHA ref={ref} sitekey={reCaptchaKey} onChange={onChange} data-testid="reCaptcha" />
));
