import { Availability, AvailabilityType } from 'types/search';
import type { Product } from 'models';
import type { CheckedFilters } from 'types/search';

export const filterByEnhancedAvailability = (
  product: Product,
  values: CheckedFilters['availability'] | undefined
): boolean =>
  values?.some((value) => {
    if (value === Availability.OUT_OF_STOCK) {
      return product.quote?.isOutOfStock();
    }

    if (value === Availability.BACK_ORDER) {
      return product.quote?.isBackOrder();
    }

    if (value === Availability.IN_STOCK) {
      return product.quote?.isInStock();
    }

    if (value === AvailabilityType.MAIN) {
      return product.quote?.isInStock() && !product.quote?.isInNetwork();
    }

    if (value === AvailabilityType.NETWORK) {
      return product.quote?.isInNetwork() || product.quote?.isInStock();
    }

    return product.quote?.lines?.some((line) =>
      line.group
        ? values?.some((val) => line.isMatchingGroup({ group: val }))
        : values?.some((val) => line.isEqual({ id: Number(val), name: val }))
    );
  }) ?? false;
