import { Box, Button, ButtonGroup, useMedia } from '@partstech/ui';
import { Outlet } from 'app/AppRouter/Outlet';
import { useSignUpWelcomeBack } from 'features/signUp';
import { Header } from './Header';
import { StepDescription } from './StepDescription';
import { useLayout } from './useLayout';
import type { PropsWithChildren } from 'react';

export const Layout = ({ children }: PropsWithChildren) => {
  const { isMobile, isTablet } = useMedia();

  const isMobileDevice = isMobile || isTablet;

  const { isDuplicatedShopStep, nextStepName, showNextButton, navigateToNextStep } = useLayout();

  useSignUpWelcomeBack();

  return (
    <Box display="flex" flexDirection="column" width="100%" minHeight="100dvh" background="white">
      <Header />

      <Box
        display="flex"
        justifyContent={{ sm: 'center', xl: isDuplicatedShopStep ? 'center' : 'space-between' }}
        alignItems="flex-start"
        flexDirection="row-reverse"
        width="100%"
        maxWidth={228}
        mx="auto"
      >
        {!isMobileDevice && !isDuplicatedShopStep && <StepDescription />}

        <Box display="flex" flexDirection="column" justifyContent="flex-start">
          <Outlet>{children}</Outlet>
        </Box>
      </Box>

      {showNextButton && (
        <ButtonGroup
          justifyContent="center"
          position={{ sm: 'relative', md: 'absolute' }}
          right={0}
          top={{ sm: 0, md: 3 }}
        >
          <Button variant="text" trailingIcon="chevron_right" onClick={navigateToNextStep} noHover>
            Continue to {nextStepName}
          </Button>
        </ButtonGroup>
      )}
    </Box>
  );
};
