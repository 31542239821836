import { css } from '@emotion/react';
import { Box, Icon, Typography } from '@partstech/ui';
import { isString } from '@partstech/ui/utils';
import { Region } from 'components/Region';
import type { Theme } from '@partstech/ui';
import type { Vehicle } from 'models';

const styles = {
  icon: (theme: Theme) => css`
    margin-right: ${theme.sizing(2)};
  `,
  name: (multiline: boolean) =>
    multiline
      ? css`
          display: flex;
          flex-direction: column;
          width: 100%;

          @media print {
            display: block;
          }
        `
      : undefined,
};

type Props = {
  vehicle: Vehicle | string;
  multiline?: boolean;
  hasFlag?: boolean;
  hasVin?: boolean;
  size?: 'dense' | 'default';
};

export const VehicleName = ({
  multiline = false,
  vehicle,
  hasFlag = true,
  hasVin = false,
  size = 'default',
}: Props) => (
  <Box display="flex" alignItems="center" data-testid="vehicleLabel">
    <Icon size={size === 'dense' ? 'medium' : 'default'} name="directions_car" color="subtleText" css={styles.icon} />

    {isString(vehicle) ? (
      <Typography variant="bodyText2">{vehicle}</Typography>
    ) : (
      <Box position="relative" css={styles.name(multiline)}>
        <Typography
          textTransform={size === 'dense' ? 'uppercase' : undefined}
          variant={size === 'dense' ? 'overline' : 'subtitle2'}
          component="span"
        >
          {vehicle.year} {vehicle.make.name} {vehicle.model.name} {vehicle.subModel.name}{' '}
        </Typography>
        <Typography variant={size === 'dense' ? 'caption' : 'bodyText2'} color="subtleText" component="span">
          {vehicle.engine.name}
        </Typography>

        {hasVin && vehicle.vin && (
          <Typography variant="caption" color="subtleText" component="div">
            {'VIN: '}
            <Typography variant="caption" color="linkText" component="span">
              {vehicle.vin}
            </Typography>
          </Typography>
        )}

        {hasFlag && (
          <Box display="flex" position="absolute" right={0} bottom={1}>
            <Region regions={vehicle.regions} />
          </Box>
        )}
      </Box>
    )}
  </Box>
);
