import { changePauseRequest } from 'store/entities/supplierAccounts';
import { updateSupplierAccountAction } from 'utils';
import { api as rootSupplierAccountsApi } from '../../../../queries/currentUser/supplierAccounts/GetSupplierAccounts.generated';
import { api as generatedApi } from './ChangeSupplierAccountPriorities.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    ChangeSupplierAccountPriorities: {
      onQueryStarted: async ({ input }, { dispatch, queryFulfilled }) => {
        dispatch(changePauseRequest(true));

        const { accountPriorities } = input;

        const updatedPriorities = dispatch(
          rootSupplierAccountsApi.util.updateQueryData('GetSupplierAccounts', {}, (draft) => {
            const accounts = draft.currentUser?.activeMember?.shop?.accounts;

            accounts?.forEach((account) => {
              Object.assign(account, {
                priority:
                  accountPriorities.find((priority) => String(priority.accountId) === account.id)?.priority ??
                  account.priority,
              });
            });
          })
        );

        try {
          const { data } = await queryFulfilled;

          const accounts = data.changeAccountPriorities?.accounts;

          if (accounts) {
            accounts.forEach((account) => dispatch(updateSupplierAccountAction(account.id, account)));
          }
        } catch (e) {
          if (e.error.name === 'AbortError') {
            return;
          }

          updatedPriorities.undo();

          throw e;
        } finally {
          dispatch(changePauseRequest(false));
        }
      },
    },
  },
});

export const { useChangeSupplierAccountPrioritiesMutation } = api;
