import { useCallback, useEffect } from 'react';
import { useMutationStatus } from 'shared/api';
import { useChangePaymentCardMutation } from '../api/changeCard';
import type { ChangePaymentMethodInput } from 'shared/api';

type Args = {
  onSuccess?: () => void;
  onError?: () => void;
};

export const useChangePaymentCard = ({ onSuccess, onError }: Args = {}) => {
  const [changeCard, status] = useChangePaymentCardMutation();

  const changePaymentCard = useCallback(
    (cardId: ChangePaymentMethodInput['cardId']) => changeCard({ input: { cardId } }),
    [changeCard]
  );

  useEffect(() => {
    if (!status?.isError || !onError) {
      return;
    }

    onError();
  }, [status?.isError, onError]);

  useMutationStatus({ status, onSuccess });

  return [changePaymentCard, status] as const;
};
