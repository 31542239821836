import { Box, Button, ModalView, Tooltip, Typography } from '@partstech/ui';
import { HookForm, useForm, FormTextField } from '@partstech/ui/forms';
import { useCallback } from 'react';

type FormData = { hours: string };

type Props = {
  onSave?: (value: number) => void;
  onClose?: () => void;
  defaultValue?: number;
  estimatedValue?: number;
};

export const EditHoursModal = ({ onClose, onSave, defaultValue, estimatedValue }: Props) => {
  const form = useForm<FormData>({ defaultValues: { hours: String(defaultValue) } });
  const { setValue, watch, formState } = form;
  const value = Number(watch('hours'));
  const isEdited = value !== estimatedValue;

  const handleSubmit = useCallback(
    ({ hours }: FormData) => {
      if (onSave) {
        onSave(Number(hours));
      }
    },
    [onSave]
  );

  const handleResetHours = useCallback(() => {
    setValue('hours', String(estimatedValue), { shouldDirty: true });
  }, [setValue, estimatedValue]);

  return (
    <ModalView title="Edit hours" onClose={onClose}>
      <HookForm form={form} onSubmit={handleSubmit}>
        <Box display="flex" alignItems="flex-end">
          <FormTextField
            name="hours"
            type="number"
            max={999}
            adornmentAfter={
              <Box mr={2}>
                <Typography color="subtleText">hours</Typography>
              </Box>
            }
          />

          <Box ml={2}>
            <Tooltip tooltip="Revert to MOTOR labor" isHidden={!isEdited}>
              <Button variant="text" onClick={handleResetHours} disabled={!isEdited} data-testid="resetButton">
                Revert
              </Button>
            </Tooltip>
          </Box>
        </Box>

        <Box display="flex" flexDirection="column" gap={2} mt={6}>
          <Button fullWidth type="submit" disabled={!value || !formState.isDirty} data-testid="saveButton">
            Save
          </Button>
          <Button fullWidth variant="text" onClick={onClose} data-testid="cancelButton">
            Cancel
          </Button>
        </Box>
      </HookForm>
    </ModalView>
  );
};
