/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type UpdateAdditionalFieldsMutationVariables = Types.Exact<{
  additionalFields: Array<Types.AdditionalFieldValue> | Types.AdditionalFieldValue;
  orderId: Types.Scalars['ID']['input'];
}>;

export type UpdateAdditionalFieldsMutation = {
  __typename?: 'Mutation';
  updateActiveCartOrderAdditionalFields?:
    | {
        __typename?: 'UpdateActiveCartOrderAdditionalFieldsErrorPayload';
        errorMessage: Types.Scalars['String']['input'];
      }
    | { __typename?: 'UpdateActiveCartOrderAdditionalFieldsSuccessPayload'; updatedOrderId: string }
    | null;
};

export const UpdateAdditionalFieldsDocument = `
    mutation UpdateAdditionalFields($additionalFields: [AdditionalFieldValue!]!, $orderId: ID!) {
  updateActiveCartOrderAdditionalFields(
    input: {additionalFields: $additionalFields, orderId: $orderId}
  ) {
    ... on UpdateActiveCartOrderAdditionalFieldsErrorPayload {
      errorMessage
    }
    ... on UpdateActiveCartOrderAdditionalFieldsSuccessPayload {
      updatedOrderId
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    UpdateAdditionalFields: build.mutation<UpdateAdditionalFieldsMutation, UpdateAdditionalFieldsMutationVariables>({
      query: (variables) => ({ document: UpdateAdditionalFieldsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUpdateAdditionalFieldsMutation } = injectedRtkApi;
