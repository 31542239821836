import { useUIContext } from '@partstech/ui';
import { useCallback, useEffect } from 'react';

type Args = Partial<{
  skip: boolean;
}>;

/**
 * Custom React hook for closing all opened modals.
 * @param {Object} options - Optional argument object.
 * @param {boolean} options.skip - If true, skip closing modals.
 */
export const useModalCloser = ({ skip = false }: Args = {}) => {
  const {
    modal: { opened: modals, close },
  } = useUIContext();

  const closeAllModals = useCallback(() => {
    modals.forEach((modal) => close(modal.id));
  }, [close, modals]);

  useEffect(() => {
    if (skip) {
      return;
    }

    closeAllModals();
  }, [closeAllModals, skip]);
};
