import { useSubscriptionEX } from '../../../../../../hooks/useSubscriptionEX';
import { FeatureTableRow } from '../FeatureTableRow';

export const FeaturesEX = () => {
  const { featuresEX, isRestrictedEX } = useSubscriptionEX();

  if (!isRestrictedEX) {
    return null;
  }

  return (
    <>
      {featuresEX.map((featureEX, index) => (
        <FeatureTableRow key={featureEX.alias} feature={featureEX} title={index === 0 ? 'EX Features' : undefined} />
      ))}
    </>
  );
};
