import { isRejected, isRejectedWithValue } from '@reduxjs/toolkit';
import { GraphQLError } from 'shared/api';
import { logout, showForbiddenDialog } from 'store/features/user/account';
import type { Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import type { GraphQLResponseError } from 'shared/api';
import type { RootDispatch, RootState } from 'store';

export const permissionsMiddleware: Middleware<{}, RootState> =
  (store: MiddlewareAPI<RootDispatch>) => (next) => (action) => {
    if (isRejectedWithValue(action) && action.type.startsWith('api/')) {
      const error = new GraphQLError(action.payload as GraphQLResponseError<{}>);

      const isUnauthenticated = Boolean(error.getUnauthenticatedError());
      const isPermissionDenied = Boolean(error.getPermissionDeniedError());

      if (isUnauthenticated) {
        return store.dispatch(logout());
      }

      if (isPermissionDenied) {
        store.dispatch(showForbiddenDialog());
      }

      return next(action);
    }

    if (isRejected(action) && action.error.message === 'Unauthorized') {
      return store.dispatch(logout());
    }

    if (isRejected(action) && action.error.message === 'Forbidden') {
      return store.dispatch(showForbiddenDialog());
    }

    return next(action);
  };
