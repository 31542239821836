import { useDeepCompareEffect } from '@partstech/ui/hooks';
import { isEmpty } from '@partstech/ui/utils';
import { useAppDispatch, useAppSelector } from 'store';
import { getTrackingStats, selectIsLoadedSmsValidatedDate, selectSmsValidatedDate } from 'store/features/trackingStats';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';

export const useSmsValidatedDate = () => {
  const dispatch = useAppDispatch();

  const smsValidatedDate = useAppSelector(selectSmsValidatedDate);
  const isLoaded = useAppSelector(selectIsLoadedSmsValidatedDate);

  const { shop, isPrimary } = useGetCurrentUser();

  useDeepCompareEffect(() => {
    if (!isPrimary || isEmpty(shop) || isLoaded) {
      return;
    }

    dispatch(getTrackingStats());
  }, [dispatch, isLoaded, isPrimary, shop]);

  return { smsValidatedDate, isLoaded };
};
