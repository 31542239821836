import { css } from '@emotion/react';
import { Box, Logo, Typography } from '@partstech/ui';
import React from 'react';
import type { Theme } from '@emotion/react';

const styles = {
  logo: (theme: Theme) => css`
    span {
      opacity: 6%;
      width: ${theme.sizing(40)};
      max-height: ${theme.sizing(30)};
      text-align: center;
    }
  `,
  filtersEmptyButton: (theme: Theme) => css`
    margin: ${theme.sizing(0, 1)};
    padding: 0;
    text-decoration: underline;
    cursor: pointer;
  `,
};

type Props = {
  onStartNewSearchClick: (e: React.MouseEvent) => void;
};

export const EmptyFilters = ({ onStartNewSearchClick }: Props) => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    pt={12}
    pb={11}
    width="100%"
    height="100%"
    textAlign="center"
    data-testid="filtersBlock"
  >
    <Box mx="auto" mb={3} css={styles.logo}>
      <Logo size="emblem" variant="dark" fullSize />
    </Box>

    <Typography variant="h2" color="dark">
      No options
    </Typography>
    <Typography variant="h2" color="dark">
      are available
    </Typography>
    <Box mt={8}>
      <Typography variant="caption" color="subtleText">
        try
      </Typography>
      <button css={styles.filtersEmptyButton} onClick={onStartNewSearchClick}>
        <Typography variant="overline" color="secondary">
          start new search
        </Typography>
      </button>
    </Box>
  </Box>
);
