import { useBrowserTabActivity, useTimeout } from '@partstech/ui/hooks';
import { useEffect, useState } from 'react';

const reloadTime = 1000 * 60 * 60 * 6; // 6 hours

export const useInactivityReload = () => {
  const isActive = useBrowserTabActivity();

  const [isRestart, setIsRestart] = useState(false);

  useTimeout(() => setIsRestart(true), isActive ? null : reloadTime);

  useEffect(() => {
    if (!isActive || !isRestart) {
      return;
    }

    window.location.reload();
  }, [isActive, isRestart]);
};
