import { Typography, Box, ModalView, Icon, Divider, TextCut } from '@partstech/ui';
import React, { useMemo } from 'react';
import type { Vehicle } from 'models';

type Props = {
  vehicle: Vehicle;
  onClose: () => void;
};

type VehicleDetailsRow = {
  name: string;
  value: string | number | null;
  disabled?: boolean;
};

export const VehicleDetailsModal: React.FunctionComponent<Props> = ({ onClose, vehicle }) => {
  const vehicleDetailsColumns: VehicleDetailsRow[][] = useMemo(
    () => [
      [
        {
          name: 'Year',
          value: vehicle.year,
        },
        {
          name: 'Make',
          value: vehicle.make.name,
        },
        {
          name: 'Model',
          value: vehicle.model.name,
        },
        {
          name: 'Sub Model',
          value: vehicle.subModel.name,
        },
        {
          name: 'Body',
          value: vehicle.body?.name ?? null,
          disabled: !vehicle.body?.name,
        },
        {
          name: 'Aspiration Type',
          value: vehicle.engine.aspiration ?? null,
          disabled: !vehicle.engine.aspiration,
        },
      ],
      [
        {
          name: 'Plate Number',
          value: `${vehicle.state} ${vehicle.plate}`,
          disabled: !vehicle.state && !vehicle.plate,
        },
        {
          name: 'Engine',
          value: vehicle.engine.name,
        },
        {
          name: 'Doors',
          value: vehicle.body?.doors ?? null,
          disabled: !vehicle.body?.doors,
        },
        {
          name: 'Engine Designation',
          value: vehicle.engine.designation ?? null,
          disabled: !vehicle.engine.designation,
        },
        {
          name: 'Drive',
          value: vehicle.body?.drive ?? null,
          disabled: !vehicle.body?.drive,
        },
        {
          name: 'Fuel Type',
          value: vehicle.engine.fuel ?? null,
          disabled: !vehicle.engine.fuel,
        },
      ],
    ],
    [vehicle]
  );

  return (
    <ModalView onClose={onClose} title="Vehicle details">
      <Box display="flex" alignItems="center" gap={2}>
        <Icon color="subtleText" name="directions_car" />

        <Box display="flex" alignItems="baseline" flexDirection="column">
          <Typography bold>
            {vehicle.year} {vehicle.make.name} {vehicle.model.name}
          </Typography>

          <Typography color="subtleText" variant="bodyText2">
            {vehicle.subModel.name} {vehicle.engine.name}
          </Typography>

          <Typography color="subtleText" variant="bodyText2">
            VIN: {vehicle.vin}
          </Typography>
        </Box>
      </Box>

      <Divider mt={6} mb={5} />

      <Box display="flex" flexWrap="wrap" justifyContent="space-between" width="100%">
        {vehicleDetailsColumns.map((column, index) => (
          <Box display="flex" flexDirection="column" gap={2} width="auto" maxWidth="50%" key={index}>
            {column
              .filter((row) => !row.disabled)
              .map((row) => (
                <Box key={row.name} data-testid="row">
                  <Typography>{row.name}</Typography>
                  <Typography variant="subtitle2">
                    <TextCut>{row.value}</TextCut>
                  </Typography>
                </Box>
              ))}
          </Box>
        ))}
      </Box>
    </ModalView>
  );
};
