import { Box, SelectBox, Typography } from '@partstech/ui';
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { useTireSizeOptions } from './useTireSizeOptions';
import { useTireSizeValue } from './useTireSizeValue';
import type { BoxProps } from '@partstech/ui';
import type { TireSizeFieldName, TireSizeFormData } from 'features/searchForm';
import type { TireSize } from 'models';
import type { MutableRefObject } from 'react';

const fieldLabels: Record<TireSizeFieldName, string> = {
  width: 'Width',
  height: 'Profile',
  rim: 'Size',
};

type Props = BoxProps & {
  tireSizes: TireSize[];
  label?: string;
  defaultValue: TireSizeFormData;
  onChange?: (tireSize: TireSize) => void;
};

type FieldsRef = Record<TireSizeFieldName, { focus: () => void } | null>;

export type TireSizeFormRef = {
  fields: MutableRefObject<FieldsRef>;
  focus: () => void;
};

export const TireSizeForm = forwardRef<TireSizeFormRef, Props>(
  ({ tireSizes, defaultValue, label, onChange, ...props }, ref) => {
    const { value, setValue, setHeight, setRim, setWidth } = useTireSizeValue(defaultValue, onChange);

    const fields = useRef<FieldsRef>({ width: null, height: null, rim: null });

    useImperativeHandle(ref, () => ({
      fields,
      focus: () => fields.current?.width?.focus(),
    }));

    const { widths, heights, rims } = useTireSizeOptions(tireSizes, value);

    useEffect(() => {
      if (heights.length === 1) {
        setHeight(heights[0] ? `${heights[0]?.value}` : '');
        return;
      }

      if (!value.height && heights.length > 1) {
        fields.current?.height?.focus();
      }
    }, [heights, setHeight, value.height]);

    useEffect(() => {
      if (rims.length === 1) {
        setRim(rims[0] ? `${rims[0]?.value}` : '');
        return;
      }

      if (!value.rim && rims.length > 1) {
        fields.current?.rim?.focus();
      }
    }, [rims, setRim, setValue, value.rim]);

    return (
      <Box {...props}>
        {label && <Typography variant="subtitle1">{label}</Typography>}

        <Box display="flex" gap={4}>
          <SelectBox
            label={fieldLabels.width}
            isSearchable
            name="width"
            ref={(selectBox) => {
              fields.current.width = selectBox;
            }}
            onChange={setWidth}
            onClear={() => {
              setWidth(null);
            }}
            value={value.width ?? ''}
            options={widths}
            isClearable
          />

          <SelectBox
            label={fieldLabels.height}
            isSearchable
            name="height"
            ref={(selectBox) => {
              fields.current.height = selectBox;
            }}
            onChange={setHeight}
            onClear={() => {
              setHeight(null);
            }}
            value={value.height ?? ''}
            options={heights}
            disabled={value.width === null}
            isClearable
          />

          <SelectBox
            label={fieldLabels.rim}
            isSearchable
            name="rim"
            ref={(selectBox) => {
              fields.current.rim = selectBox;
            }}
            onChange={setRim}
            onClear={() => {
              setRim(null);
            }}
            value={value.rim ?? ''}
            options={rims}
            disabled={value.width === null || value.height === null}
            isClearable
          />
        </Box>
      </Box>
    );
  }
);
