import { useMemo } from 'react';
import { useFeatureFlag } from 'store/queries/currentUser/useFeatureFlag';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';

export const useGetFullStoryContext = () => {
  const { fullName, email, username, accountId, isSupplier, managementSystem, isDemo, shop } = useGetCurrentUser();

  const { availableFeatureFlags } = useFeatureFlag();

  return useMemo(
    () => ({
      fullName,
      email: email ?? '',
      username: username ?? '',
      id: accountId ?? '',
      shopId: shop?.id ?? '',
      isSupplier,
      isDemo: isDemo ?? false,
      shopManagementSystemName: managementSystem?.name ?? '',
      featureFlag: availableFeatureFlags,
      smsValidatedDate: null,
    }),
    [accountId, availableFeatureFlags, email, fullName, isDemo, isSupplier, managementSystem?.name, shop?.id, username]
  );
};
