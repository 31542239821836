import { PartType } from '../../models/PartType';
import type { PreferredBrandUnified, PreferredCustomBrand } from 'types/preferredBrand';

export const unifyCustomBrands = (customBrands: PreferredCustomBrand[]): PreferredBrandUnified[] =>
  customBrands.map((customBrand) => ({
    id: customBrand.id ?? null,
    name: customBrand.name ?? null,
    partTypeId: PartType.TIRE_ID,
    priority: customBrand.priority,
    type: 'custom',
    purchaseCount: 0,
    matchingBrandsIds: customBrand.matchingBrandsIds,
  }));
