/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type GetStatesByCountryQueryVariables = Types.Exact<{
  country?: Types.InputMaybe<Types.Country>;
}>;

export type GetStatesByCountryQuery = {
  __typename?: 'Query';
  states?: Array<{
    __typename?: 'State';
    id: string;
    name: Types.Scalars['String']['input'];
    twoLetterCode: Types.Scalars['String']['input'];
  }> | null;
};

export const GetStatesByCountryDocument = `
    query GetStatesByCountry($country: Country) {
  states(country: $country) {
    id
    name
    twoLetterCode
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetStatesByCountry: build.query<GetStatesByCountryQuery, GetStatesByCountryQueryVariables | void>({
      query: (variables) => ({ document: GetStatesByCountryDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetStatesByCountryQuery, useLazyGetStatesByCountryQuery } = injectedRtkApi;
