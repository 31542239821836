import { Icon, Typography } from '@partstech/ui';

export type ValueProps = {
  isEnabled?: boolean;
  partialEnabled?: string;
};

export const Value = ({ isEnabled, partialEnabled }: ValueProps) => {
  if (isEnabled) {
    return <Icon name="check_circle" color="positive" />;
  }

  if (partialEnabled) {
    return <Typography>{partialEnabled}</Typography>;
  }

  return <Icon name="cancel" color="negative" />;
};
