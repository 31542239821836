import { entries } from '@partstech/ui/utils';
import type { CatalogSearchInput, SearchInput } from 'shared/api';

export const getSearchInputWithoutAttributes = (searchInput: SearchInput | CatalogSearchInput) =>
  entries(searchInput).reduce((acc, [_, params]) => {
    if (!searchInput || !params) {
      return acc;
    }

    return { ...params, universal: undefined, attributes: undefined };
  }, {});
