import { useMedia } from '@partstech/ui';
import { useOpen } from '@partstech/ui/hooks';
import { useCallback } from 'react';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import { usePunchoutInfo } from 'store/queries/punchoutInfo/usePunchoutInfo';
import { useMembershipsModal } from './MembershipsModal';

type Args = {
  onClick?: () => void;
};

export const useMembershipsButton = ({ onClick }: Args) => {
  const { isMobile } = useMedia();

  const { shopName, shopMemberships, supplierMemberships, isSuccess } = useGetCurrentUser();
  const { punchoutEnabled } = usePunchoutInfo();

  const showButton = isSuccess && !punchoutEnabled && (shopMemberships.length > 1 || supplierMemberships.length > 0);

  const { isOpen: isOpenList, open: openList, close: closeList } = useOpen();

  const { open } = useMembershipsModal({ shopMemberships, supplierMemberships });

  const handleOpenList = useCallback(() => {
    if (isMobile) {
      open();

      return;
    }

    openList();

    onClick?.();
  }, [isMobile, onClick, open, openList]);

  return {
    shopName,
    shopMemberships,
    supplierMemberships,
    showButton,
    isOpenList,
    openList: handleOpenList,
    closeList,
  };
};
