import {
  FormPasswordField,
  Controller,
  FormTextField,
  useFormContext,
  FormPhoneField,
  FormSelectBox,
} from '@partstech/ui/forms';
import { isTypeOf } from '@partstech/ui/utils';
import { useCallback, useMemo } from 'react';
import { ConfigurationFieldType, ValidationInputType } from 'shared/api';
import { alphabet, email, number, rangeLength, required } from 'shared/lib/form';
import { CheckBoxField } from './CheckBoxField';
import type { CredentialValues } from '../../../../../types';
import type { FieldPath, Rules, FieldValues, Validate, ValidationRule } from '@partstech/ui/forms';
import type { ConfigurationField } from 'types/supplier';

const validatePatterns: Record<ValidationInputType, ValidationRule<RegExp> | undefined> = {
  [ValidationInputType.Alphabetic]: alphabet,
  [ValidationInputType.Numeric]: number,
  [ValidationInputType.Email]: email,
  [ValidationInputType.Any]: undefined,
};

type Validator = Validate<string, FieldValues>;

type Props = {
  type: ConfigurationFieldType;
  name: FieldPath<CredentialValues>;
  label: string;
  hint?: string;
  link?: string;
  validator?: Validator;
  validationRules?: ConfigurationField['validationRules'];
  options?: ConfigurationField['options'];
  isRequired?: boolean;
};

export const CredentialFormField = ({
  type,
  hint,
  link,
  label,
  name,
  options,
  validator,
  validationRules,
  isRequired,
}: Props) => {
  const { formState, control } = useFormContext<CredentialValues>();
  const { dirtyFields } = formState;

  const validate = useCallback(
    (value: string, formValues: FieldValues) => {
      if (!dirtyFields.credentials) {
        return true;
      }

      if (validationRules?.length) {
        const rangeLengthValidator = rangeLength(validationRules.length);

        return isTypeOf(rangeLengthValidator, 'function')
          ? rangeLengthValidator(value, formValues)
          : rangeLengthValidator;
      }

      if (validator) {
        return validator(value, formValues);
      }

      return true;
    },
    [dirtyFields, validationRules, validator]
  ) as Validator;

  const rules: Rules | undefined = useMemo(
    () => ({
      pattern:
        validationRules && validationRules.inputType !== ValidationInputType.Any
          ? validatePatterns[validationRules.inputType]
          : undefined,
      validate,
      required: isRequired ? required : undefined,
    }),
    [validationRules, validate, isRequired]
  );

  const getOptions = useCallback(() => options?.map((option) => ({ value: option, text: option })) ?? [], [options]);

  if (type === ConfigurationFieldType.Password) {
    return <FormPasswordField autoComplete="new-password" hint={hint} label={label} name={name} rules={rules} />;
  }

  if (type === ConfigurationFieldType.Select && options) {
    return <FormSelectBox options={getOptions()} label={label} name={name} isSearchable />;
  }

  if (type === ConfigurationFieldType.Checkbox) {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field }) => <CheckBoxField field={field} hint={hint} link={link} name={name} />}
      />
    );
  }

  if (type === ConfigurationFieldType.Phone) {
    return <FormPhoneField autoComplete="off" hint={hint} label={label} name={name} rules={rules} />;
  }

  return <FormTextField autoComplete="off" hint={hint} label={label} name={name} rules={rules} />;
};
