/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type DeleteActiveCartOrderMutationVariables = Types.Exact<{
  orderId: Types.Scalars['ID']['input'];
}>;

export type DeleteActiveCartOrderMutation = {
  __typename?: 'Mutation';
  deleteActiveCartOrder?:
    | { __typename?: 'DeleteActiveCartOrderErrorPayload'; errorMessage: Types.Scalars['String']['input'] }
    | { __typename?: 'DeleteActiveCartOrderSuccessPayload'; deletedOrderId: string }
    | null;
};

export const DeleteActiveCartOrderDocument = `
    mutation DeleteActiveCartOrder($orderId: ID!) {
  deleteActiveCartOrder(input: {orderId: $orderId}) {
    ... on DeleteActiveCartOrderErrorPayload {
      errorMessage
    }
    ... on DeleteActiveCartOrderSuccessPayload {
      deletedOrderId
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    DeleteActiveCartOrder: build.mutation<DeleteActiveCartOrderMutation, DeleteActiveCartOrderMutationVariables>({
      query: (variables) => ({ document: DeleteActiveCartOrderDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useDeleteActiveCartOrderMutation } = injectedRtkApi;
