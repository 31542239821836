import { useMemo } from 'react';
import { useSearchParams } from '../search/useSearchParams';
import { useSupplierAccounts } from './useSupplierAccounts';
import type { SuppliersFiltersMap } from 'types/search';

export const useSupplierAccountFiltersMap = () => {
  const { isTiresSearch } = useSearchParams();

  const { accounts } = useSupplierAccounts({ type: isTiresSearch ? 'tires' : 'parts' });

  const accountsMap = useMemo(
    () =>
      accounts.reduce<SuppliersFiltersMap>(
        (map, entity) => ({
          ...map,
          [entity.id]: {
            isTiresDisconnected: Boolean(entity.supplier?.isTireDisconnected),
            supplierId: entity.supplier?.id ?? '',
          },
        }),
        {}
      ),
    [accounts]
  );

  return { accountsMap };
};
