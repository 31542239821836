import type { Option } from '../components/QuestionOptions/useQuestionOptions';

export const hearAboutUsOptions: Option[] = [
  { text: 'Shop management system', value: 'shop management system' },
  { text: 'Shop referral', value: 'shop referral' },
  { text: 'Local parts rep', value: 'local parts rep' },
  { text: 'Email', value: 'email' },
  { text: 'Demo/webinar', value: 'demo/webinar' },
  { text: 'Google/search engine', value: 'google/search engine' },
  { text: 'My shop coach', value: 'my shop coach' },
  { text: 'Review site', value: 'review site' },
  { text: 'Direct mailer', value: 'direct mailer' },
  { text: 'Social media', value: 'social media' },
  { text: 'Other', value: 'other' },
];
