import { css } from '@emotion/react';
import type { Theme } from '@partstech/ui';

export const styles = {
  tableRow:
    (isHighlighted: boolean = false) =>
    (theme: Theme) => css`
      background-color: ${isHighlighted ? theme.color.upgrade : 'transparent'};
      transition: background-color 0.3s ease-in-out;

      .table-col {
        border-color: ${theme.color.mono6};
        padding: ${theme.sizing(2, 3, 2, 0)};

        &:last-of-type {
          min-width: ${theme.sizing(21)};
          width: ${theme.sizing(21)};
          padding: ${theme.sizing(2, 1)};
          background-color: ${theme.color.mono2};
          text-align: center;
          ${isHighlighted && 'background-color: transparent !important'};
          transition: background-color 0.3s ease-in-out;
        }
      }

      &:last-of-type {
        .table-col {
          border: none;
        }
      }
    `,

  headerRow: (theme: Theme) => css`
    .table-col {
      border-color: ${theme.color.black};
      border-top: 1px solid;

      &:last-of-type {
        padding: ${theme.sizing(2, 0)};
      }
    }
  `,
};
