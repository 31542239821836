import { useSnackbar, useMedia } from '@partstech/ui';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'store';
import { selectLastAddedId } from 'store/entities/supplierAccounts';
import { useGetSupplierAccounts } from 'store/queries/currentUser/supplierAccounts/useGetSupplierAccounts';
import { useOpenAddSupplierModal } from './useOpenAddSupplierModal';
import type { PathParams } from 'utils/url';

export const useLastAddedSupplierAccount = () => {
  const { isMobile, isTablet } = useMedia();

  const { tab: activeTab = 'all' } = useParams<PathParams>();
  const { addSnackbar } = useSnackbar();

  const { getAccountById } = useGetSupplierAccounts();

  const supplierAccountId = useAppSelector(selectLastAddedId);

  const account = useMemo(
    () => getAccountById(String(supplierAccountId) ?? '') ?? null,
    [getAccountById, supplierAccountId]
  );

  const { open: openAddSupplierModal } = useOpenAddSupplierModal();

  const openAddAccountSnackbar = useCallback(() => {
    if ((!isMobile && !isTablet) || !supplierAccountId) {
      return;
    }

    addSnackbar({
      label: 'Supplier added',
      button: 'View',
      buttonColor: 'primary',
      position: { horizontal: 'right', vertical: 'bottom', offsetY: 22 },
      dense: true,
      onClick: () => openAddSupplierModal(activeTab, { activeScreen: 'my-suppliers' }),
    });
  }, [activeTab, addSnackbar, openAddSupplierModal, isMobile, isTablet, supplierAccountId]);

  return { account, openAddAccountSnackbar };
};
