import { Box } from '@partstech/ui';
import { FormTextField } from '@partstech/ui/forms';
import { required } from 'shared/lib/form';
import { CARD_NUMBER_FIELD_NAME } from '../../constant';
import { FormCardField } from './FormCardField';

export const CardForm = () => (
  <>
    <FormTextField name="holder" label="Name on card" rules={{ required }} fsExclude hideRequiredMark />

    <Box mt={4}>
      <FormCardField name={CARD_NUMBER_FIELD_NAME} type="cardNumber" label="Card number" />
    </Box>

    <Box mt={4} display="flex" justifyContent="space-between">
      <Box width="calc(60% - 4px)">
        <FormCardField name="expire" type="expire" label="Expiration" />
      </Box>

      <Box width="calc(40% - 4px)">
        <FormCardField name="cvc" type="cvc" label="CVV" />
      </Box>
    </Box>
  </>
);
