import { PartType } from './PartType';
import type { SearchQuery } from 'features/searchForm';
import type { FullPartTypeFragment } from 'store/queries/partTypes/FullPartType.generated';

export class PartTypeAlias extends PartType {
  constructor(
    partTypeData: FullPartTypeFragment,
    private alias: string
  ) {
    super(partTypeData);

    this.name = alias;
  }

  toString(): string {
    return this.alias;
  }

  populateInput(): string {
    return this.alias;
  }

  toQuery(): SearchQuery {
    return {
      part_text_id: this.id,
      part_text: this.alias,
    };
  }
}
