import { sortUniq } from '@partstech/ui/utils';
import { useMemo } from 'react';
import { useGetVehiclesByYearMake } from '../../../api';
import { filterParametersBySearch } from '../../../utils';
import type { CommonHookProps } from '../types';

export const useGetSubmodels = ({ searchValue, makeId, year, modelId }: Omit<CommonHookProps, 'submodelId'>) => {
  const { vehicles } = useGetVehiclesByYearMake(Number(year) ?? null, makeId);

  const byMakeModel = useMemo(() => vehicles.filter((vehicle) => vehicle.model.id === modelId), [vehicles, modelId]);

  const rawSubmodels = useMemo(
    () =>
      sortUniq(
        byMakeModel.map((vehicle) => vehicle.subModel),
        'name'
      ),
    [byMakeModel]
  );

  const submodels = filterParametersBySearch(rawSubmodels, searchValue);

  return { submodels };
};
