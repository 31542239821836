import { parse } from 'qs';
import { Navigate, useParams } from 'react-router-dom';
import { useAppLocation } from 'app/AppRouter/useAppLocation';
import { Routes } from 'constant';
import { generateUrl } from 'utils/url/generateUrl';
import type { generatePath } from 'react-router-dom';

type Props<Path> = {
  to: Path | string;
  replace?: boolean;
  preservedParams?: boolean;
};

export const Redirect = <Path extends Routes>({ to, replace, preservedParams = true }: Props<Path>) => {
  const { search } = useAppLocation();
  const params = useParams() as Parameters<typeof generatePath<Path>>[1];

  if (Object.values(Routes).includes(to as Path) && preservedParams) {
    return (
      <Navigate to={generateUrl(to as Path, params, parse(search, { ignoreQueryPrefix: true }))} replace={replace} />
    );
  }

  return <Navigate to={to} replace={replace} />;
};
