import { useEffect, useState, useMemo } from 'react';
import { NavigationType, useNavigationType } from 'react-router-dom';
import { useAppLocation, type UseAppLocationReturnType } from 'app/AppRouter/useAppLocation';

export const useAppLocationsHistory = () => {
  const location = useAppLocation();
  const navigationType = useNavigationType();

  const [locations, setLocations] = useState<UseAppLocationReturnType[]>([]);

  useEffect(() => {
    if (navigationType === NavigationType.Push) {
      setLocations((prev) => [...prev, location]);

      return;
    }

    if (navigationType === NavigationType.Replace) {
      setLocations((prev) => [...prev.slice(0, prev.length - 1), location]);
      return;
    }

    if (navigationType === NavigationType.Pop) {
      setLocations((prev) => {
        const currentLocationIndex = prev.findIndex((item) => item.key === location.key);

        if (currentLocationIndex === -1) {
          return [...prev, location];
        }

        return prev;
      });
    }
  }, [location, navigationType]);

  const currentAppLocationIndex = useMemo(
    () => locations.findIndex((item) => item.key === location.key),
    [location.key, locations]
  );

  return {
    nextAppLocation: locations[currentAppLocationIndex + 1] ?? null,
    previousAppLocation: locations[currentAppLocationIndex - 1] ?? null,
  };
};
