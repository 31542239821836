import { css } from '@emotion/react';
import { Box, Icon, Typography } from '@partstech/ui';
import { useCallback } from 'react';
import type { Theme } from '@partstech/ui';
import type { AvailabilityLine } from 'models';

const styles = {
  root: (allowSelect: boolean) => (theme: Theme) => css`
    display: flex;
    padding: ${theme.sizing(1, 2)};

    ${allowSelect &&
    `&:hover {
      cursor: pointer;
      background: ${theme.color.mono2};
    }`}
  `,
  icon: (theme: Theme) => css`
    font-size: ${theme.sizing(3)};
    width: ${theme.sizing(3)};
    height: ${theme.sizing(3)};
  `,
};

type Props = {
  allowSelect: boolean;
  isSelected: boolean;
  onClick: (store: AvailabilityLine) => void;
  store: AvailabilityLine;
};

export const AvailabilityMenuItem = ({ allowSelect, isSelected, onClick, store }: Props) => {
  const handleClick = useCallback(() => {
    if (!allowSelect) {
      return;
    }

    onClick(store);
  }, [allowSelect, onClick, store]);

  return (
    <Box css={styles.root(allowSelect)} onClick={handleClick} data-testid="store">
      {allowSelect && (
        <Box width={5} mr={1}>
          {isSelected && <Icon name="check" size="large" />}
        </Box>
      )}

      <div>
        <Typography variant="subtitle2" component="span">
          <Box mr={2} display="inline-flex">
            <Icon name="circle" color={store.quantityColor} css={styles.icon} />
          </Box>
          {store.formattedQuantity} - {store.displayName}
        </Typography>

        {store.address && (
          <Typography variant="bodyText2" component="span">
            {' '}
            &mdash; {store.address}
          </Typography>
        )}
      </div>
    </Box>
  );
};
