import { api as rootApi } from 'features/stockOrders/api/queries/getStockOrders/GetStockOrders.generated';
import { useMutationStatus } from 'shared/api';
import { api as generatedApi } from './DeleteStockOrder.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    DeleteStockOrder: {
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        await queryFulfilled;

        dispatch(
          rootApi.util.updateQueryData('GetStockOrders', undefined, (draft) => {
            if (draft.stockOrder) {
              Object.assign(draft, { stockOrder: null });
            }
          })
        );
      },
    },
  },
});

const { useDeleteStockOrderMutation } = api;

export const useDeleteStockOrder = () => {
  const [deleteStockOrder, status] = useDeleteStockOrderMutation();

  useMutationStatus({ status });

  return [deleteStockOrder, status] as const;
};
