import { entries } from '@partstech/ui/utils';
import { useMemo } from 'react';
import useCookie from 'react-use-cookie';
import { useAppLocation } from 'app/AppRouter/useAppLocation';
import { parseQuery } from 'shared/lib/query';
import type { UtmParams } from 'shared/types';

export const useUtmParams = (defaultUtmParams?: UtmParams) => {
  const { search } = useAppLocation();

  const query = useMemo(() => parseQuery<UtmParams>(search), [search]);

  const [MSCLKID] = useCookie('msclkid', query.msclkid || defaultUtmParams?.msclkid || undefined);
  const [FBCLID] = useCookie('fbclid', query.fbclid || defaultUtmParams?.fbclid || undefined);
  const [GCLID] = useCookie('gclid', query.gclid || defaultUtmParams?.gclid || undefined);
  const [utm_campaign] = useCookie('utm_campaign', query.utm_campaign || defaultUtmParams?.utm_campaign || undefined);
  const [utm_source] = useCookie('utm_source', query.utm_source || defaultUtmParams?.utm_source || undefined);
  const [utm_term] = useCookie('utm_term', query.utm_term || defaultUtmParams?.utm_term || undefined);
  const [utm_medium] = useCookie('utm_medium', query.utm_medium || defaultUtmParams?.utm_medium || undefined);
  const [utm_content] = useCookie('utm_content', query.utm_content || defaultUtmParams?.utm_content || undefined);

  const utmParams = useMemo(
    () => ({ MSCLKID, FBCLID, GCLID, utm_campaign, utm_source, utm_term, utm_medium, utm_content }),
    [FBCLID, GCLID, MSCLKID, utm_campaign, utm_content, utm_medium, utm_source, utm_term]
  );

  const utmEntries = useMemo(() => entries(utmParams).map(([key, value]) => ({ key, value })), [utmParams]);

  return { utmParams, utmEntries };
};
