import { sortUniq } from '@partstech/ui/utils';
import { useCallback, useMemo, useState } from 'react';
import { VehicleType } from 'shared/api';
import { useVehicleMakeGroups } from '../../../api';
import { filterParametersBySearch } from '../../../utils';
import type { Make } from '../../../api';
import type { CommonHookProps } from '../types';

type Category = 'popular' | 'heavyDuty' | 'default';
type Categories = { name: string; value: Category; isShown: boolean }[];

export const useGetMakes = ({ searchValue, year }: Omit<CommonHookProps, 'makeId'>) => {
  const { makes: rawMakes } = useVehicleMakeGroups();

  const unfilteredMakes = useMemo(
    () =>
      sortUniq<Make>(
        rawMakes.filter((make) => (year ? make.years.includes(Number(year)) : false)),
        'name'
      ),
    [rawMakes, year]
  );

  const makes = filterParametersBySearch(unfilteredMakes, searchValue);

  const popularList = useMemo(
    () =>
      (makes.length === 1 ? unfilteredMakes : makes).filter(
        (item) => item.priority === 100 && item.types.includes(VehicleType.LightDuty)
      ),
    [makes, unfilteredMakes]
  );

  const mediumHeavyDutyList = useMemo(
    () => (makes.length === 1 ? unfilteredMakes : makes).filter((item) => item.types.includes(VehicleType.HeavyTruck)),
    [makes, unfilteredMakes]
  );

  const defaultCategory: Category =
    (popularList.length > 0 && 'popular') || (mediumHeavyDutyList.length > 0 && 'heavyDuty') || 'default';

  const [category, setCategory] = useState<Category>(defaultCategory);

  const onCategoryClick = useCallback(
    (value: Category) => () => {
      setCategory(value);
    },
    []
  );

  const categories = useMemo(
    () =>
      [
        { name: 'Popular', value: 'popular', isShown: popularList.length > 0 },
        { name: 'Medium/Heavy duty', value: 'heavyDuty', isShown: mediumHeavyDutyList.length > 0 },
        { name: 'Show all', value: 'default', isShown: true },
      ].filter((item) => item.isShown) as Categories,
    [mediumHeavyDutyList.length, popularList.length]
  );

  const makesByCategory = useMemo(() => {
    if (category === 'popular' && popularList.length > 0) {
      return popularList;
    }

    if (category === 'heavyDuty' && mediumHeavyDutyList.length > 0) {
      return mediumHeavyDutyList;
    }

    return makes.length === 1 ? unfilteredMakes : makes;
  }, [category, makes, mediumHeavyDutyList, popularList, unfilteredMakes]);

  return { makes: makesByCategory, category, categories, onCategoryClick };
};
