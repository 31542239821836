import { css } from '@emotion/react';
import { Box, Card, Dropdown } from '@partstech/ui';
import { useCallback, useRef } from 'react';
import { RECENT_SEARCHES_ANIMATION, TOP_POSITION } from '../../constants';
import { RecentSearchesList } from '../RecentSearchesList';
import { OpenButton } from './OpenButton';
import type { DropdownProps, DropdownRef, Theme } from '@partstech/ui';
import type { RecentSearchInterface } from 'features/recentSearches';

const styles = {
  root: (theme: Theme) => css`
    animation: ${RECENT_SEARCHES_ANIMATION.frames} ${RECENT_SEARCHES_ANIMATION.effect};

    .ui-card-inner {
      overflow: hidden;
    }

    ${theme.breakpoints.down('md')} {
      position: relative;
      top: 0;
      left: 0;
      min-width: 100%;
      animation: none;
      border: none;
      border-radius: 0;
    }

    ${theme.breakpoints.up('md')} {
      position: absolute;
      top: ${TOP_POSITION}px;
      right: 0;
      left: auto;
      min-width: ${theme.sizing(87)};
    }
  `,
  scroll: (theme: Theme) => css`
    max-height: ${theme.sizing(120)};
  `,
};

type Props = Pick<DropdownProps, 'usePortal' | 'onClose' | 'onOpen'> & {
  onSelect?: (recentSearch: RecentSearchInterface) => void;
};

export const RecentSearchesDropdown = ({ usePortal = false, onClose, onOpen, onSelect }: Props) => {
  const dropdown = useRef<DropdownRef | null>(null);

  const handleSelect = useCallback(
    (recentSearch: RecentSearchInterface) => {
      onSelect && onSelect(recentSearch);
      dropdown.current?.close();
    },
    [onSelect]
  );

  return (
    <Dropdown
      ref={dropdown}
      data-testid="recentSearchesDropdown"
      renderButton={({ isOpened, toggleHandler }) => (
        <Box position="relative" zIndex="initial" display="flex">
          <OpenButton isOpen={isOpened} onClick={toggleHandler} />
        </Box>
      )}
      preferPlacement="bottom-end"
      disableAutoPlacement
      usePortal={usePortal}
      onClose={onClose}
      onEntered={onOpen}
    >
      {() => (
        <Box width="100%" position={{ md: 'absolute' }} bottom={{ md: 0 }} right={{ md: 0 }}>
          <Card elevation={24} css={styles.root}>
            <RecentSearchesList onSelect={handleSelect} />
          </Card>
        </Box>
      )}
    </Dropdown>
  );
};
