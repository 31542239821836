/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import { CardFragmentDoc } from '../../../../shared/api/graphql/fragment/Card.generated';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type GetPaymentCardsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetPaymentCardsQuery = {
  __typename?: 'Query';
  currentUser?: {
    __typename?: 'User';
    activeMember?: {
      __typename?: 'ShopMember';
      shop?: {
        __typename?: 'Shop';
        cards?: Array<{
          __typename?: 'Card';
          brand: Types.Scalars['String']['input'];
          expMonth: number;
          expYear: number;
          name: Types.Scalars['String']['input'];
          owner?: Types.Scalars['String']['input'] | null;
          id: string;
        }> | null;
        shopSubscription?: {
          __typename?: 'ShopSubscription';
          card?: {
            __typename?: 'Card';
            brand: Types.Scalars['String']['input'];
            expMonth: number;
            expYear: number;
            name: Types.Scalars['String']['input'];
            owner?: Types.Scalars['String']['input'] | null;
            id: string;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export const GetPaymentCardsDocument = `
    query GetPaymentCards {
  currentUser {
    activeMember {
      shop {
        cards {
          ...Card
        }
        shopSubscription {
          card {
            ...Card
          }
        }
      }
    }
  }
}
    ${CardFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetPaymentCards: build.query<GetPaymentCardsQuery, GetPaymentCardsQueryVariables | void>({
      query: (variables) => ({ document: GetPaymentCardsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetPaymentCardsQuery, useLazyGetPaymentCardsQuery } = injectedRtkApi;
