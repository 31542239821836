import { CHECK_STATUS_REQUEST_LIMIT } from 'constant';
import { AccountStatus } from 'shared/api';
import { changePauseRequest, setLastAddedId, setRequestCount } from 'store/entities/supplierAccounts';
import { updateSupplierAccountAction } from 'utils';
import { api as rootSupplierAccountsApi } from '../../../../queries/currentUser/supplierAccounts/GetSupplierAccounts.generated';
import { api as generatedApi } from './AddSupplierAccount.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    AddSupplierAccount: {
      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        dispatch(changePauseRequest(true));

        dispatch(
          rootSupplierAccountsApi.util.updateQueryData('GetSupplierAccounts', {}, (draft) => {
            const accounts = draft.currentUser?.activeMember?.shop?.accounts;

            if (accounts) {
              accounts.push({
                additionalInformation: [],
                credentials: [],
                id: 'unknown',
                priority: accounts.length + 1,
                status: AccountStatus.Processing,
              });
            }
          })
        );

        try {
          const { data } = await queryFulfilled;
          const account = data.addShopAccount?.account;

          if (!account) {
            return;
          }

          dispatch(updateSupplierAccountAction('unknown', account));

          dispatch(setLastAddedId(account.id));

          dispatch(setRequestCount(CHECK_STATUS_REQUEST_LIMIT));
        } finally {
          dispatch(changePauseRequest(false));
        }
      },
    },
  },
});

export const { useAddSupplierAccountMutation } = api;
