import { Box, Button, Typography, useMedia } from '@partstech/ui';
import { useCreatePage } from '@partstech/ui/hooks';
import { Outlet } from 'app/AppRouter/Outlet';
import { useLayoutContext } from 'app/contexts/LayoutProvider/LayoutContext';
import { useCart } from 'features/cart';
import { useStartNewQuote } from 'features/savedQuotes';
import { usePunchoutInfo } from 'store/queries/punchoutInfo/usePunchoutInfo';
import type { PropsWithChildren } from 'react';

export const SavedQuotesLayout = ({ children }: PropsWithChildren) => {
  const { cart } = useCart();
  const { headerHeight } = useLayoutContext();
  const { isMobile } = useMedia();
  const { punchoutEnabled } = usePunchoutInfo();

  const { onSubmit: startNewQuote } = useStartNewQuote({ withConfirmation: Boolean(cart && !cart.isEmpty) });

  useCreatePage({
    title: 'Saved quotes',
    actions: punchoutEnabled
      ? undefined
      : [
          {
            title: 'Start new quote',
            onClick: startNewQuote,
            variant: 'button',
            buttonVariant: 'secondary',
          },
        ],
  });

  return (
    <Box background="white" minHeight={`calc(100vh - ${headerHeight}px)`} height="100%" px={{ md: 4 }}>
      <Box
        pt={{ md: 6 }}
        pb={6}
        display="flex"
        flexDirection="column"
        minHeight={`calc(100vh - ${headerHeight}px)`}
        maxWidth={240}
        m="auto"
        height="100%"
      >
        {!isMobile && (
          <Box display="flex" justifyContent="space-between" alignItems="center" minHeight={10}>
            <Typography variant="h2">Saved quotes</Typography>

            {!punchoutEnabled && (
              <Button onClick={startNewQuote} variant="secondary" leadingIcon="shopping_cart">
                Start new quote
              </Button>
            )}
          </Box>
        )}

        <Box mt={6} px={{ sm: 2, md: 0 }}>
          <Outlet>{children}</Outlet>
        </Box>
      </Box>
    </Box>
  );
};
