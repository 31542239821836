import { isEmpty } from '@partstech/ui/utils';
import { Country } from 'shared/api';
import { CountryName } from 'shared/constant';

export type AddressData = {
  address1: string;
  address2?: string | null;
  city: string;
  country: Country;
  state: string;
  zipCode: string;
};

export class Address {
  address1: string;

  address2: string | null = null;

  city: string;

  country: Country;

  state: string;

  zipCode: string;

  constructor(addressData: AddressData) {
    this.address1 = addressData.address1;
    this.address2 = addressData.address2 ?? null;
    this.city = addressData.city;
    this.country = addressData.country;
    this.state = addressData.state;
    this.zipCode = addressData.zipCode;
  }

  static isZipCode(searchString: string): boolean {
    const regex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$|(^\d{5}$)|(^\d{5}-\d{4}$)|([A-Z]\d[A-Z])/;

    return regex.test(searchString);
  }

  get shortAddress(): string {
    return [
      this.city,
      [this.state, this.zipCode].join(' '),
      this.country !== Country.Us ? CountryName[this.country] : '',
    ]
      .filter((element) => !isEmpty(element))
      .join(', ');
  }

  toString(): string {
    return [this.address1, this.address2, this.shortAddress].filter((element) => !isEmpty(element)).join(', ');
  }
}
