import { createVehicleFromQuery } from 'factories';
import { TireSize } from 'models';
import { RecentSearchType } from 'shared/api';
import { Categories, Keyword, NonApp, PartNumber, Tires, TirePartNumber } from '../models';
import type { RecentSearchesFragment } from '../api/getRecentSearches/RecentSearchesFragment.generated';
import type { RecentSearchInterface } from '../models';

export const convertQueryToRecentSearch = ({ id, type, params }: RecentSearchesFragment): RecentSearchInterface => {
  const vehicle = createVehicleFromQuery(
    params.vehicle
      ? {
          ...params.vehicle,
          state: params.state ?? undefined,
          plate: params.plate ?? undefined,
          vin: params.vin ?? undefined,
        }
      : null
  );

  switch (type) {
    case RecentSearchType.PartNumber:
      if (!('partNumber' in params)) {
        throw new Error('`partNumber` must be in params');
      }

      return new PartNumber(id, vehicle, params);
    case RecentSearchType.Keyword:
      if (!('partText' in params)) {
        throw new Error('`partText` must be in params');
      }

      if (vehicle === null) {
        throw new Error('`vehicle` must be in params');
      }

      return new Keyword(id, vehicle, params);

    case RecentSearchType.Categories:
      if (!('partCategory' in params)) {
        throw new Error('`partCategory` must be in params');
      }

      if (vehicle === null) {
        throw new Error('`vehicle` must be in params');
      }

      return new Categories(id, vehicle, params);

    case RecentSearchType.NonApp:
      if (!('attributes' in params)) {
        throw new Error('`attributes` must be in params');
      }

      return new NonApp(id, vehicle, params);

    case RecentSearchType.Tires:
      if (!('tire' in params)) {
        throw new Error('`tire` must be in params');
      }

      if (params.tiresPartNumber) {
        return new TirePartNumber(id, vehicle, {
          ...params,
          partNumber: params.tiresPartNumber,
        });
      }

      return new Tires(id, vehicle, {
        ...params,
        tire: params.tire
          ? {
              ...params.tire,
              size: new TireSize(params.tire.size),
              rearSize: params.tire?.rearSize ? new TireSize(params.tire.rearSize) : null,
            }
          : null,
      });

    default:
      throw new Error('Incorrect recent search type');
  }
};
