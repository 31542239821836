import { FormSelectBox } from '@partstech/ui/forms';
import { useGetStatesByCountry } from '../../../api/states/useGetStatesByCountry';
import type { SingleSelectBoxProps } from '@partstech/ui';
import type { RegisterOptions } from '@partstech/ui/forms';
import type { Country } from 'shared/api';

type Props = {
  country: Country;
  rules?: RegisterOptions;
} & Omit<SingleSelectBoxProps, 'options'>;

export const StatesSelectBox = ({ country, rules, ...props }: Props) => {
  const { options } = useGetStatesByCountry(country);

  return <FormSelectBox {...props} isSearchable options={options} rules={rules} />;
};
