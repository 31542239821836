import { URL } from 'constant';
import { ExternalRedirect } from './ExternalRedirect';
import type { RouteObject } from 'react-router-dom';

type ApplicationRedirects = { from: string; to: string }[];

const applicationExternalRedirects: ApplicationRedirects = [{ from: '/status', to: URL.SUPPLIER_SYSTEM_STATUS }];

export const getApplicationExternalRedirects = (): RouteObject[] =>
  applicationExternalRedirects.map((redirect) => ({
    path: redirect.from,
    element: <ExternalRedirect to={redirect.to} />,
  }));
