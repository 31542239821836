import { useMedia } from '@partstech/ui';
import { useToggle } from '@partstech/ui/hooks';
import { useCallback } from 'react';

export const useSortableCard = () => {
  const { isDesktop, isWide } = useMedia();

  const isDesktopScreen = isDesktop || isWide;

  const { isToggle: isHovered, turnOn: onFocus, turnOff: onBlur } = useToggle();

  const focus = useCallback(() => {
    if (!isDesktopScreen) {
      return;
    }

    onFocus();
  }, [isDesktopScreen, onFocus]);

  const blur = useCallback(() => {
    if (!isDesktopScreen) {
      return;
    }

    onBlur();
  }, [isDesktopScreen, onBlur]);

  return { isHovered, focus, blur };
};
