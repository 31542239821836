import type { MatrixRequest } from 'shared/api/rest/gen/shop';
import type { RetailPriceMatrix } from 'types/matrix';

export const matrixToQuoteRequest = (matrix: Omit<RetailPriceMatrix, 'id'>): MatrixRequest => ({
  ...matrix,
  brandIds: matrix.brands.map((brand) => Number(brand.id)),
  subCategories: matrix.subCategories.map((subCategory) => ({
    id: Number(subCategory.id),
    partTypeIds: subCategory.partTypes.map((partType) => Number(partType)),
  })),
});
