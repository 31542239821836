import { useMemo } from 'react';
import { Options } from '../../DefaultFilter/Options';
import type { OptionsProps } from '../../DefaultFilter/Options/types';

export const TireAvailabilityOptions = (props: OptionsProps) => {
  const { checkedOptions } = props;

  const checkedOptionsExceptLast = useMemo(() => checkedOptions.slice(0, -1), [checkedOptions]);

  return <Options {...props} disabledOptions={checkedOptionsExceptLast} />;
};
