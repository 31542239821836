import { Box, Card, Skeleton } from '@partstech/ui';
import { memo } from 'react';

export const Loader = memo(() => (
  <Card
    display="flex"
    flexDirection="column"
    flex="1"
    mx={1}
    mb={2}
    position="relative"
    p={4}
    alignItems="center"
    data-testid="cardLoader"
  >
    <Skeleton height="40" width="196" />

    <Box mt={4} display="flex" justifyContent="space-between" alignItems="center" width="100%">
      <Skeleton height="20" width="50%" />

      <Skeleton height="32" width="60" />
    </Box>
  </Card>
));
