import { useCallback, useMemo, useState } from 'react';
import { useGoogleMaps } from '../ui/GoogleMapsProvider/GoogleMapsContext';
import { createPlacesService } from './createPlacesService';
import { extractAddressFromGoogleResult } from './extractAddressFromGoogleResult';
import type { DetailsByPlate, Place } from './types';

const formatPhone = (phone: string) => {
  const cleanedPhone = phone.replace(/\D+/g, '');

  if (cleanedPhone.length === 10) {
    return cleanedPhone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  }

  if (cleanedPhone.length > 10 && cleanedPhone.length <= 15) {
    return cleanedPhone.replace(/(\d{3})(\d{3})(\d{4})(\d{1,5})/, '($1) $2-$3 x$4');
  }

  return '';
};

export const useGetDetailsByPlace = () => {
  const { isLoaded } = useGoogleMaps();

  const [data, setData] = useState<DetailsByPlate | null>(null);
  const [status, setStatus] = useState({ isLoading: false, isSuccess: false, isError: false, error: '' });

  const generateError = useCallback((errorMessage: string) => {
    setStatus({ isLoading: false, isSuccess: false, isError: true, error: errorMessage });

    return new Promise<DetailsByPlate>((_, reject) => {
      reject(new Error(errorMessage));
    });
  }, []);

  const getDetailsByPlace = useCallback(
    (place: Place, element: HTMLElement | null) => {
      if (!isLoaded) {
        return generateError('google services have not been loaded');
      }

      if (!element) {
        return generateError('element must not be null');
      }

      setStatus({ isLoading: true, isSuccess: false, isError: false, error: '' });

      const placesService = createPlacesService(element);

      if (!placesService?.getDetails) {
        return generateError('getDetails is not defined');
      }

      return new Promise<DetailsByPlate>((resolve, reject) => {
        const { PlacesServiceStatus } = google.maps.places;

        placesService?.getDetails(
          { placeId: place.id, fields: ['address_components', 'formatted_phone_number', 'website'] },
          (response, responseStatus) => {
            if (
              response &&
              (responseStatus === PlacesServiceStatus.OK || responseStatus === PlacesServiceStatus.ZERO_RESULTS)
            ) {
              const { address_components, formatted_phone_number: phone = '', website = '' } = response;

              const address = extractAddressFromGoogleResult(address_components);

              if (address) {
                const newData = { name: place.name, phone: formatPhone(phone), website, address };

                setData(newData);
                setStatus({ isLoading: false, isSuccess: true, isError: false, error: '' });

                resolve(newData);
                return;
              }
            }

            const errorMessage = 'Something went wrong';

            setStatus({ isLoading: false, isSuccess: false, isError: true, error: errorMessage });

            reject(new Error(errorMessage));
          }
        );
      });
    },
    [generateError, isLoaded]
  );

  return useMemo(() => [getDetailsByPlace, { data, ...status }] as const, [data, getDetailsByPlace, status]);
};
