import { useFormContext } from '@partstech/ui/forms';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useGooglePlacesAutocomplete } from 'integrations/google';
import { Country } from 'shared/api';
import { join, type PathWithMeta } from 'shared/lib/form';
import { useDisableEnterPress } from 'shared/lib/keyboard';
import type { FieldValues } from '@partstech/ui/forms';
import type { Address } from 'shared/api';

type Args<FormValues> = {
  path: PathWithMeta<FormValues, Address>;
};

export const useAddressForm = <FormValues extends FieldValues>({ path }: Args<FormValues>) => {
  const {
    setValue,
    getValues,
    formState: { errors },
    reset,
    watch,
  } = useFormContext<FieldValues>();

  const addressValues = watch(path);

  const [country, setCountry] = useState(Country.Us);

  const searchInput = useRef<HTMLInputElement>(null);

  const { disableEnterPress } = useDisableEnterPress();

  const error = useMemo(() => {
    if (!path) {
      return errors.root?.message ?? '';
    }

    return (errors[path]?.message as string) ?? errors.root?.message ?? '';
  }, [errors, path]);

  const selectAutocompleteAddress = useCallback(
    (address: Address | null) => {
      if (!path || !address) {
        return;
      }

      const values = getValues();

      reset({ ...values, [path]: address }, { keepDefaultValues: true });
      setCountry(address.country);
    },
    [getValues, path, reset]
  );

  useGooglePlacesAutocomplete(searchInput.current, selectAutocompleteAddress);

  const changeState = useCallback(
    (value: string | number) => {
      setValue(join(path, 'state'), value);
    },
    [path, setValue]
  );

  const changeCountry = useCallback(
    (newCountry: string | number) => {
      changeState('');

      setCountry(newCountry as Country);
    },
    [changeState]
  );

  const onFocus = useCallback(() => {
    if (!searchInput.current) {
      return;
    }

    searchInput.current.autocomplete = 'new-password';
  }, []);

  useEffect(() => {
    if (!addressValues.country) {
      return;
    }

    setCountry(getValues(join(path, 'country')));
  }, [addressValues.country, getValues, path]);

  return { error, searchInput, onFocus, disableEnterPress, country, changeCountry, changeState };
};
