import { Button, useMedia } from '@partstech/ui';

type Props = {
  onSubmit: () => void;
  onCancel: () => void;
};

export const FormActions = ({ onSubmit, onCancel }: Props) => {
  const { isMobile } = useMedia();

  return (
    <>
      <Button variant="secondary" leadingIcon="delete" color="negative" onClick={onSubmit}>
        Delete supplier
      </Button>

      <Button fullWidth={isMobile} onClick={onCancel} variant="text">
        Cancel
      </Button>
    </>
  );
};
