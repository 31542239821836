/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import { SubscriptionFragmentDoc } from '../../queries/subscription/Subscription.generated';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type DowngradePlanMutationVariables = Types.Exact<{
  input: Types.DowngradeSubscriptionPlanInput;
}>;

export type DowngradePlanMutation = {
  __typename?: 'Mutation';
  downgradeSubscriptionPlan?: {
    __typename?: 'DowngradeSubscriptionPlanPayload';
    shopSubscription?: {
      __typename?: 'ShopSubscription';
      amount: Types.Scalars['BigDecimal']['input'];
      currentPeriodEnd?: Types.Scalars['DateTime']['input'] | null;
      period: Types.SubscriptionPlanPeriod;
      startedAt?: Types.Scalars['DateTime']['input'] | null;
      plans?: Array<{
        __typename?: 'SubscriptionSubscriptionPlan';
        downgradeProductAlias?: Types.SubscriptionProductAlias | null;
        status: Types.SubscriptionSubscriptionPlanStatus;
        account?: { __typename?: 'Account'; id: string } | null;
        plan?: {
          __typename?: 'SubscriptionPlan';
          amount: Types.Scalars['BigDecimal']['input'];
          id: string;
          product?: {
            __typename?: 'SubscriptionProduct';
            alias: Types.SubscriptionProductAlias;
            isPlan: Types.Scalars['Boolean']['input'];
            unsubscribeReasons?: Array<Types.Scalars['String']['input']> | null;
          } | null;
        } | null;
      }> | null;
    } | null;
  } | null;
};

export const DowngradePlanDocument = `
    mutation DowngradePlan($input: DowngradeSubscriptionPlanInput!) {
  downgradeSubscriptionPlan(input: $input) {
    shopSubscription {
      ...Subscription
    }
  }
}
    ${SubscriptionFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    DowngradePlan: build.mutation<DowngradePlanMutation, DowngradePlanMutationVariables>({
      query: (variables) => ({ document: DowngradePlanDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useDowngradePlanMutation } = injectedRtkApi;
