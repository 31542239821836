import { Country, type Address } from 'shared/api';

const abbreviationsMexicanStates = {
  Ags: 'AG',
  BC: 'BC',
  BCS: 'BS',
  Camp: 'CM',
  Chis: 'CS',
  Chih: 'CH',
  Coah: 'CO',
  Col: 'CL',
  CDMX: 'DF',
  Dgo: 'DG',
  Gto: 'GT',
  Gro: 'GR',
  Hgo: 'HG',
  Jal: 'JA',
  Méx: 'EM',
  Mich: 'MI',
  Mor: 'MO',
  Nay: 'NA',
  NL: 'NL',
  Oax: 'OA',
  Pue: 'PU',
  Qro: 'QT',
  QR: 'QR',
  SLP: 'SL',
  Sin: 'SI',
  Son: 'SO',
  Tab: 'TB',
  Tamps: 'TM',
  Tlax: 'TL',
  Ver: 'VE',
  Yuc: 'YU',
  Zac: 'ZA',
};

export const extractAddressFromGoogleResult = (addressComponents?: google.maps.GeocoderAddressComponent[]) => {
  const address = {
    address1: '',
    city: '',
    country: Country.Us,
    state: '',
    zipCode: '',
  };

  if (!Array.isArray(addressComponents)) {
    return null;
  }

  addressComponents.forEach((addressComponent) => {
    const { long_name: longName, short_name: shortName, types } = addressComponent;

    if (types.includes('street_number')) {
      address.address1 = longName;
    }

    if (types.includes('route')) {
      address.address1 = `${address.address1} ${longName}`;
    }

    if (types.includes('locality') || types.includes('sublocality')) {
      address.city = shortName;
    }

    if (types.includes('country')) {
      address.country = shortName as Address['country'];
    }

    if (types.includes('administrative_area_level_1')) {
      const isMexico = addressComponents?.some(
        (component) => component.types.includes('country') && component.short_name === 'MX'
      );

      if (isMexico) {
        const shortNameMexico = shortName.replace(/\./g, '') as keyof typeof abbreviationsMexicanStates;
        address.state = abbreviationsMexicanStates[shortNameMexico];
      } else {
        address.state = shortName;
      }
    }

    if (types.includes('postal_code')) {
      address.zipCode = shortName;
    }
  });

  return address;
};
