import { useCallback } from 'react';

import { api as rootApi } from 'store/queries/currentUser/useGetCurrentUser';
import { api as generatedApi } from './UpdateShopCartSettings.generated';
import type { UpdateShopCartSettingsInput } from 'shared/api';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    UpdateShopCartSettings: {
      onQueryStarted: async ({ input }, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          rootApi.util.updateQueryData('GetCurrentUser', undefined, (draft) => {
            if (draft.currentUser?.activeMember?.shop) {
              Object.assign(draft.currentUser.activeMember.shop, {
                confirmShipping: input.confirmShipping,
                mandatoryPoNumber: input.mandatoryPoNumber,
              });
            }
          })
        );

        queryFulfilled.catch(patchResult.undo);
      },
    },
  },
});

const { useUpdateShopCartSettingsMutation } = api;

export const useUpdateShopCartSettings = () => {
  const [updateShopCartSettings, status] = useUpdateShopCartSettingsMutation();

  const handleUpdateShopCartSettings = useCallback(
    (input: UpdateShopCartSettingsInput) => updateShopCartSettings({ input }),
    [updateShopCartSettings]
  );

  return [handleUpdateShopCartSettings, status] as const;
};
