import { FeaturedAvailabilityLine } from './FeaturedAvailabilityLine';

export class FeaturedEnhancementsAvailabilityLine extends FeaturedAvailabilityLine {
  getAdditionalTitle() {
    return this.displayName;
  }

  getMainAvailabilityTitle() {
    if (this.group === 'Home Store') {
      return `Home Store (${this.formattedMaxQuantity})`;
    }

    return `${this.group ?? 'In Stock'} (${this.formattedMaxQuantity})`;
  }

  getNetworkAvailabilityTitle() {
    return `${this.group ?? 'In Network'} (${this.formattedMaxQuantity})`;
  }
}
