/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import { SupplierAccountFragmentDoc } from '../../../../queries/currentUser/supplierAccounts/SupplierAccount.generated';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type ChangeSupplierAccountPrioritiesMutationVariables = Types.Exact<{
  input: Types.ChangeAccountPrioritiesInput;
}>;

export type ChangeSupplierAccountPrioritiesMutation = {
  __typename?: 'Mutation';
  changeAccountPriorities?: {
    __typename?: 'ChangeAccountPrioritiesPayload';
    accounts: Array<{
      __typename?: 'Account';
      customerDescription?: Types.Scalars['String']['input'] | null;
      id: string;
      nickname?: Types.Scalars['String']['input'] | null;
      priority: number;
      status: Types.AccountStatus;
      additionalInformation: Array<{
        __typename?: 'AccountField';
        name: Types.Scalars['String']['input'];
        value?: Types.Scalars['String']['input'] | null;
      }>;
      credentials: Array<{
        __typename?: 'AccountField';
        name: Types.Scalars['String']['input'];
        value?: Types.Scalars['String']['input'] | null;
      }>;
    }>;
  } | null;
};

export const ChangeSupplierAccountPrioritiesDocument = `
    mutation ChangeSupplierAccountPriorities($input: ChangeAccountPrioritiesInput!) {
  changeAccountPriorities(input: $input) {
    accounts {
      ...SupplierAccount
    }
  }
}
    ${SupplierAccountFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    ChangeSupplierAccountPriorities: build.mutation<
      ChangeSupplierAccountPrioritiesMutation,
      ChangeSupplierAccountPrioritiesMutationVariables
    >({
      query: (variables) => ({ document: ChangeSupplierAccountPrioritiesDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useChangeSupplierAccountPrioritiesMutation } = injectedRtkApi;
