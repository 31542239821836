import { Box, Typography, useMedia } from '@partstech/ui';

type Props = {
  isPartStockOrder: boolean;
};

export const MobileWarning = ({ isPartStockOrder }: Props) => {
  const { isMobile } = useMedia();

  if (!isMobile) {
    return null;
  }

  return (
    <Box background="mono2" py={2} px={6} my={3} mx={6}>
      <Typography variant="bodyText2">
        This stock order contains {isPartStockOrder ? 'parts' : 'tires'} and cannot be changed to{' '}
        {isPartStockOrder ? 'tires' : 'parts'}. To create a {isPartStockOrder ? 'tire' : 'part'} stock order, click on
        “New supplier” and then select a {isPartStockOrder ? 'tire' : 'part'} supplier.
      </Typography>
    </Box>
  );
};
