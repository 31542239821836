type Variants = Record<
  1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9,
  {
    bighex: string;
    grey1: string;
    grey2: string;
    grey3: string;
    grey4: string;
    grey5: string;
    grey6: string;
    grey7: string;
    grey8: string;
    grey9: string;
    grey10: string;
    grey11: string;
    grey13: string;
    grey14: string;
    grey15: string;
    grey16: string;
    orange1: string;
    orange2: string;
    orange3: string;
    orange4: string;
    orange5: string;
  }
>;

export const variants: Variants = {
  1: {
    orange4:
      'M90.7327 143.5L100.252 133.981L96.7683 120.984L83.7652 117.5L74.2521 127.013L77.7358 140.016L90.7327 143.5Z',
    orange3:
      'M173.778 140.738L165.252 108.846L188.54 85.5L220.354 94.047L228.879 125.94L205.592 149.285L173.778 140.738ZM197.066 133.842C206.134 133.842 213.475 126.473 213.475 117.393C213.475 108.302 206.124 100.943 197.066 100.943C188.008 100.943 180.657 108.312 180.657 117.393C180.657 126.473 188.008 133.842 197.066 133.842Z',
    grey16:
      'M135.601 141.394L156.006 120.937L148.535 92.9901L120.658 85.5L100.252 105.957L107.723 133.904L135.601 141.394Z',
    grey15:
      'M163.77 89.1996L170.418 82.535L167.988 73.4341L158.9 70.9978L152.252 77.6624L154.692 86.7633L163.77 89.1996Z',
    grey14:
      'M177.902 83.3392L189.33 71.8825L185.142 56.2278L169.537 52.0398L158.109 63.4964L162.286 79.1411L177.902 83.3392Z',
    grey13:
      'M209.419 74.5217L214.4 69.5183L212.582 62.6926L205.763 60.8604L200.782 65.8638L202.61 72.6895L209.419 74.5217Z',
    orange5:
      'M209.419 58.5217L214.4 53.5183L212.582 46.6926L205.763 44.8604L200.782 49.8638L202.61 56.6895L209.419 58.5217Z',
    grey11:
      'M242.348 42.1445L245.873 38.6209L244.577 33.7987L239.767 32.5L236.252 36.0336L237.538 40.8559L242.348 42.1445Z',
    grey10:
      'M229.137 79.8341L226.064 68.3372L234.459 59.9209L245.927 63.0015L249 74.4984L240.605 82.9147L229.137 79.8341ZM237.532 65.9714C234.529 65.9714 232.099 68.4077 232.099 71.4178C232.099 74.4279 234.529 76.8642 237.532 76.8642C240.534 76.8642 242.965 74.4279 242.965 71.4178C242.965 68.4077 240.534 65.9714 237.532 65.9714Z',
    grey9: 'M176.044 313.285L183 306.329L180.456 296.829L170.956 294.285L164 301.236L166.544 310.742L176.044 313.285Z',
    grey8:
      'M80.9924 400.058L93.6957 387.333L89.0462 369.947L71.7034 365.285L59 378.011L63.6495 395.407L80.9924 400.058Z',
    grey7: 'M45.0469 375.285L52 368.329L49.4586 358.832L39.9531 356.285L33 363.242L35.5469 372.739L45.0469 375.285Z',
    grey6: 'M27.606 402.285L32 397.895L30.3881 391.892L24.394 390.285L20 394.676L21.606 400.679L27.606 402.285Z',
    grey5: 'M72.2592 315.285L88 299.545L82.2408 278.045L60.7408 272.285L45 288.021L50.7592 309.526L72.2592 315.285Z',
    orange2:
      'M54.1976 349.487L50 333.782L61.4682 322.285L77.134 326.494L81.3316 342.199L69.8634 353.696L54.1976 349.487ZM65.6658 345.299C69.6927 345.299 72.9665 342.027 72.9665 337.98C72.9665 333.933 69.7028 330.661 65.6658 330.661C61.6389 330.661 58.3651 333.933 58.3651 337.98C58.3651 342.027 61.6389 345.299 65.6658 345.299Z',
    orange1: 'M162.389 292.285L173 281.666L169.115 267.17L154.619 263.285L144 273.897L147.885 288.401L162.389 292.285Z',
    grey4:
      'M139.686 355.869L163.998 331.497L155.101 298.205L121.891 289.285L97.5791 313.658L106.476 346.95L139.686 355.869Z',
    grey3:
      'M112.481 366.885L127.765 351.563L122.172 330.633L101.284 325.025L86 340.348L91.5935 361.278L112.481 366.885Z',
    grey2:
      'M117.653 312.584L114.58 301.087L122.975 292.671L134.444 295.752L137.516 307.248L129.121 315.665L117.653 312.584ZM126.048 298.721C123.046 298.721 120.615 301.158 120.615 304.168C120.615 307.178 123.046 309.614 126.048 309.614C129.051 309.614 131.481 307.178 131.481 304.168C131.481 301.158 129.051 298.721 126.048 298.721Z',
    grey1:
      'M98.2174 293.068L95 281.068L103.783 272.285L115.783 275.503L119 287.503L110.217 296.285L98.2174 293.068ZM107 289.767C110.026 289.767 112.482 287.311 112.482 284.285C112.482 281.26 110.026 278.804 107 278.804C103.974 278.804 101.518 281.26 101.518 284.285C101.518 287.311 103.974 289.767 107 289.767Z',
    bighex: 'M177.415 336L240 273.415L217.096 187.911L131.592 165L69 227.592L91.9114 313.096L177.415 336Z',
  },

  2: {
    orange4:
      'M105.369 158.926L112.336 147.406L105.845 135.62L92.3862 135.346L85.4238 146.858L91.9158 158.651L105.369 158.926Z',
    orange3:
      'M185.344 136.387L169.44 107.458L186.469 79.2208L219.404 79.9126L235.309 108.841L218.279 137.078L185.344 136.387ZM206.308 124.122C215.113 121.954 220.478 113.043 218.307 104.226C216.133 95.399 207.236 90.0112 198.441 92.1771C189.645 94.3431 184.27 103.256 186.441 112.073C188.613 120.89 197.512 126.288 206.308 124.122Z',
    grey16:
      'M148.431 146.152L163.353 121.409L149.416 96.0596L120.557 95.4527L105.635 120.196L119.572 145.545L148.431 146.152Z',
    grey15:
      'M163.303 88.7361L168.165 80.6753L163.629 72.4195L154.222 72.227L149.36 80.2879L153.906 88.5413L163.303 88.7361Z',
    grey14:
      'M175.623 79.6667L183.98 65.8098L176.171 51.6105L160.016 51.2756L151.659 65.1325L159.457 79.3244L175.623 79.6667Z',
    grey13:
      'M204.117 63.5685L207.757 57.5191L204.36 51.3261L197.301 51.1775L193.661 57.2269L197.068 63.4175L204.117 63.5685Z',
    orange5:
      'M200.291 48.0327L203.932 41.9833L200.534 35.7903L193.475 35.6417L189.835 41.691L193.242 47.8816L200.291 48.0327Z',
    grey11:
      'M228.349 24.2565L230.929 19.9923L228.518 15.6197L223.537 15.5089L220.969 19.7805L223.37 24.1555L228.349 24.2565Z',
    grey10:
      'M224.534 64.0118L218.801 53.5832L224.94 43.4036L236.812 43.6526L242.545 54.0812L236.406 64.2608L224.534 64.0118ZM229.371 48.5438C226.455 49.2618 224.678 52.2085 225.398 55.1313C226.117 58.0541 229.06 59.8386 231.975 59.1206C234.891 58.4027 236.668 55.4559 235.948 52.5331C235.228 49.6103 232.286 47.8258 229.371 48.5438Z',
    grey9:
      'M167.663 293.751L172.422 285.141L167.349 276.716L157.516 276.902L152.755 285.506L157.83 293.937L167.663 293.751Z',
    grey8:
      'M100.341 403.442L109.026 387.698L99.7464 372.278L81.791 372.599L73.1059 388.343L82.3886 403.772L100.341 403.442Z',
    grey7:
      'M58.9435 389.586L63.6996 380.977L58.6289 372.554L48.79 372.738L44.0339 381.347L49.1099 389.769L58.9435 389.586Z',
    grey6:
      'M49.6571 420.358L52.6644 414.923L49.4539 409.601L43.2494 409.716L40.2421 415.151L43.4468 420.475L49.6571 420.358Z',
    grey5:
      'M68.4865 324.398L79.2557 304.916L67.7708 285.849L45.5167 286.266L34.746 305.743L46.2324 324.814L68.4865 324.398Z',
    orange2:
      'M60.5964 362.263L52.216 348.333L60.0541 334.111L76.2726 333.819L84.6531 347.749L76.8149 361.971L60.5964 362.263ZM70.4574 355.064C74.327 353.95 76.5673 349.9 75.4472 346.011C74.3271 342.122 70.2853 339.881 66.406 340.998C62.5364 342.113 60.2961 346.163 61.4163 350.052C62.5364 353.941 66.5878 356.179 70.4574 355.064Z',
    orange1:
      'M148.729 277.351L155.987 264.21L148.241 251.355L133.236 251.635L125.969 264.771L133.716 277.633L148.729 277.351Z',
    grey4:
      'M144.512 344.735L161.128 314.585L143.364 285.056L108.983 285.677L92.3668 315.826L110.131 345.355L144.512 344.735Z',
    grey3:
      'M121.419 362.85L131.865 343.896L120.697 325.332L99.0735 325.724L88.6272 344.679L99.7951 363.243L121.419 362.85Z',
    grey2:
      'M111.359 309.239L105.224 299.042L110.962 288.63L122.835 288.417L128.97 298.614L123.232 309.025L111.359 309.239ZM115.59 293.594C112.704 294.425 111.043 297.439 111.876 300.331C112.709 303.224 115.719 304.892 118.604 304.061C121.49 303.23 123.151 300.217 122.318 297.324C121.484 294.432 118.475 292.763 115.59 293.594Z',
    grey1:
      'M87.281 295.864L80.8681 285.224L86.8768 274.353L99.2985 274.124L105.711 284.764L99.7027 295.635L87.281 295.864ZM94.807 290.262C97.7146 289.424 99.3949 286.384 98.5574 283.477C97.7199 280.569 94.6801 278.889 91.7725 279.726C88.865 280.564 87.1847 283.604 88.0221 286.511C88.8596 289.419 91.8994 291.099 94.807 290.262Z',
    bighex: 'M186.415 336L249 273.415L226.096 187.911L140.592 165L78 227.592L100.911 313.096L186.415 336Z',
  },

  3: {
    orange4:
      'M138.431 148.164L133.538 135.622L120.237 133.59L111.822 144.098L116.711 156.631L130.019 158.666L138.431 148.164Z',
    orange3:
      'M218.633 102.725L186.002 97.7186L173.981 67.0132L194.589 41.3144L227.22 46.3209L239.242 77.0263L218.633 102.725ZM221.675 78.629C225.318 70.325 221.52 60.6419 213.204 56.9935C204.88 53.341 195.187 57.1158 191.548 65.4105C187.908 73.7053 191.703 83.3975 200.019 87.046C208.334 90.6944 218.036 86.9237 221.675 78.629Z',
    grey16:
      'M203.894 137.949L193.359 111.044L164.765 106.657L146.706 129.176L157.24 156.081L185.834 160.468L203.894 137.949Z',
    grey15:
      'M167.417 91.182L163.985 82.4165L154.674 80.9854L148.792 88.3289L152.224 97.0943L161.538 98.5163L167.417 91.182Z',
    grey14:
      'M167.728 75.887L161.828 60.8189L145.81 58.3638L135.705 70.9717L141.604 86.0398L157.609 88.5L167.728 75.887Z',
    grey13:
      'M172.316 43.483L169.735 36.9114L162.754 35.8335L158.337 41.3414L160.918 47.9129L167.902 48.9817L172.316 43.483Z',
    orange5:
      'M157.664 37.0543L155.083 30.4828L148.102 29.4049L143.685 34.9127L146.266 41.4843L153.25 42.553L157.664 37.0543Z',
    grey11:
      'M155.897 0.320318L154.087 -4.32319L149.15 -5.07443L146.028 -1.19131L147.852 3.44704L152.784 4.20743L155.897 0.320318Z',
    grey10:
      'M185.103 27.5612L173.34 25.7559L169.006 14.6865L176.435 5.42242L188.198 7.22771L192.532 18.2971L185.103 27.5612ZM175.781 14.3035C174.575 17.0531 175.829 20.2574 178.586 21.4668C181.342 22.6762 184.55 21.4297 185.756 18.6801C186.963 15.9305 185.708 12.7262 182.952 11.5168C180.195 10.3073 176.988 11.5539 175.781 14.3035Z',
    grey9:
      'M139.914 266.297L139.977 256.459L131.493 251.486L122.945 256.35L122.878 266.183L131.366 271.161L139.914 266.297Z',
    grey8:
      'M133.234 394.825L133.334 376.845L117.811 367.739L102.194 376.606L102.094 394.586L117.625 403.699L133.234 394.825Z',
    grey7:
      'M90.2499 402.45L90.3106 392.615L81.8297 387.642L73.2765 392.508L73.2158 402.343L81.7005 407.313L90.2499 402.45Z',
    grey6:
      'M96.807 433.917L96.8496 427.706L91.4851 424.566L86.0907 427.634L86.0481 433.845L91.4084 436.989L96.807 433.917Z',
    grey5:
      'M67.4618 340.634L67.6047 318.374L48.4014 307.12L29.0552 318.126L28.9084 340.383L48.1155 351.64L67.4618 340.634Z',
    orange2:
      'M78.6374 377.662L64.6166 369.435L64.7006 353.196L78.8053 345.185L92.8261 353.412L92.7421 369.651L78.6374 377.662ZM83.8562 366.625C86.7219 363.796 86.7529 359.167 83.9097 356.287C81.0664 353.407 76.4452 353.372 73.5723 356.208C70.7066 359.037 70.6756 363.666 73.5189 366.546C76.3621 369.426 80.9905 369.454 83.8562 366.625Z',
    orange1:
      'M175.46 287.092L175.551 272.08L162.602 264.494L149.557 271.913L149.455 286.925L162.409 294.518L175.46 287.092Z',
    grey4:
      'M143.958 322.144L144.136 287.719L114.415 270.279L84.5155 287.263L84.3372 321.687L114.058 339.128L143.958 322.144Z',
    grey3:
      'M132.337 349.096L132.449 327.454L113.764 316.489L94.9604 327.174L94.8485 348.816L113.533 359.78L132.337 349.096Z',
    grey2:
      'M97.8677 306.821L87.6038 300.798L87.6653 288.911L97.9907 283.046L108.255 289.069L108.193 300.956L97.8677 306.821ZM94.1028 291.058C91.9661 293.167 91.9483 296.608 94.063 298.75C96.1778 300.892 99.6189 300.919 101.756 298.809C103.892 296.7 103.91 293.259 101.795 291.117C99.6806 288.975 96.2396 288.948 94.1028 291.058Z',
    grey1:
      'M70.3262 306.587L59.606 300.308L59.6858 287.887L70.4857 281.746L81.2059 288.026L81.1261 300.446L70.3262 306.587ZM74.2572 298.068C76.4104 295.942 76.4327 292.469 74.307 290.315C72.1812 288.162 68.708 288.14 66.5547 290.266C64.4015 292.391 64.3792 295.865 66.5049 298.018C68.6307 300.171 72.1039 300.193 74.2572 298.068Z',
    bighex: 'M186.415 336L249 273.415L226.096 187.911L140.592 165L78 227.592L100.911 313.096L186.415 336Z',
  },

  4: {
    orange4:
      'M174.438 154.297L163.096 147.045L151.151 153.239L150.541 166.687L161.876 173.934L173.827 167.739L174.438 154.297Z',
    orange3:
      'M227.237 94.9426L197.92 110.12L170.118 92.3895L171.632 59.4826L200.949 44.3057L228.752 62.0356L227.237 94.9426ZM215.501 73.6794C213.553 64.823 204.779 59.2362 195.91 61.1866C187.032 63.1392 181.423 71.8991 183.369 80.7458C185.315 89.5924 194.091 95.1889 202.959 93.2385C211.828 91.2882 217.446 82.5261 215.501 73.6794Z',
    grey16:
      'M236.077 132.089L211.715 116.553L186.025 129.853L184.697 158.688L209.059 174.224L234.749 160.924L236.077 132.089Z',
    grey15:
      'M179.051 115.786L171.114 110.725L162.747 115.053L162.32 124.452L170.257 129.514L178.621 125.176L179.051 115.786Z',
    grey14:
      'M170.292 103.243L156.648 94.5428L142.258 101.995L141.52 118.136L155.164 126.837L169.546 119.396L170.292 103.243Z',
    grey13:
      'M154.911 74.3562L148.954 70.5662L142.679 73.8075L142.354 80.8605L148.31 84.6505L154.584 81.3995L154.911 74.3562Z',
    orange5:
      'M139.284 77.793L133.327 74.003L127.051 77.2443L126.726 84.2973L132.683 88.0873L138.957 84.8362L139.284 77.793Z',
    grey11:
      'M116.217 49.1501L112.018 46.4644L107.587 48.7653L107.351 53.7422L111.558 56.416L115.991 54.1248L116.217 49.1501Z',
    grey10:
      'M155.864 53.9578L145.295 59.4284L135.272 53.0367L135.818 41.1745L146.386 35.704L156.409 42.0956L155.864 53.9578ZM140.522 48.736C141.166 51.6685 144.068 53.5187 147.008 52.8722C149.948 52.2257 151.805 49.3289 151.16 46.3963C150.515 43.4638 147.614 41.6136 144.674 42.2601C141.734 42.9067 139.877 45.8034 140.522 48.736Z',
    grey9:
      'M100.889 276.868L96.114 268.267L86.2808 268.099L81.2224 276.533L85.9913 285.133L95.8303 285.302L100.889 276.868Z',
    grey8:
      'M158.17 392.12L149.43 376.406L131.435 376.094L122.183 391.486L130.924 407.199L148.928 407.514L158.17 392.12Z',
    grey7:
      'M124.466 419.865L119.69 411.267L109.861 411.098L104.798 419.537L109.574 428.135L119.405 428.299L124.466 419.865Z',
    grey6:
      'M145.627 444.06L142.615 438.628L136.4 438.526L133.207 443.847L136.219 449.279L142.432 449.387L145.627 444.06Z',
    grey5:
      'M74.2651 377.2L63.461 357.737L41.2062 357.36L29.7555 376.447L40.5544 395.909L62.8144 396.287L74.2651 377.2Z',
    orange2:
      'M102.18 403.972L85.9262 403.688L78.0271 389.499L86.3818 375.595L102.636 375.879L110.535 390.067L102.18 403.972ZM101.308 391.794C102.416 387.922 100.17 383.875 96.2793 382.762C92.3883 381.648 88.3449 383.886 87.2346 387.768C86.127 391.639 88.3722 395.687 92.2632 396.8C96.1542 397.913 100.2 395.665 101.308 391.794Z',
    orange1:
      'M71.9393 288.179L64.6484 275.056L49.6429 274.803L41.9208 287.672L49.2022 300.8L64.2151 301.055L71.9393 288.179Z',
    grey4:
      'M131.83 323.536L115.085 293.458L80.6294 292.855L62.9197 322.331L79.6651 352.409L114.12 353.012L131.83 323.536Z',
    grey3:
      'M134.938 352.721L124.411 333.812L102.749 333.433L91.613 351.973L102.141 370.882L123.802 371.261L134.938 352.721Z',
    grey2:
      'M84.154 332.815L72.2553 332.607L66.4727 322.22L72.5889 312.042L84.4875 312.25L90.2701 322.636L84.154 332.815ZM73.135 320.93C72.3092 323.817 73.9831 326.823 76.8771 327.651C79.7711 328.479 82.7819 326.813 83.6078 323.926C84.4337 321.039 82.7598 318.033 79.8657 317.205C76.9717 316.377 73.9609 318.043 73.135 320.93Z',
    grey1:
      'M60.0449 346.132L47.6228 345.925L41.5946 335.065L47.9885 324.413L60.4106 324.62L66.4388 335.48L60.0449 346.132ZM59.287 336.78C60.1193 333.871 58.4335 330.834 55.5245 330.002C52.6154 329.17 49.5786 330.856 48.7463 333.765C47.9141 336.674 49.5998 339.711 52.5089 340.543C55.418 341.375 58.4548 339.689 59.287 336.78Z',
    bighex: 'M190.415 356L253 293.415L230.096 207.911L144.592 185L82 247.592L104.911 333.096L190.415 356Z',
  },

  5: {
    orange4:
      'M201.634 134.588L188.21 135.593L182.371 147.716L189.954 158.839L203.37 157.835L209.212 145.707L201.634 134.588Z',
    orange3:
      'M208.252 55.4238L193.908 85.1575L161.028 87.6594L142.492 60.4276L156.836 30.694L189.715 28.192L208.252 55.4238ZM186.103 45.4583C179.23 39.5427 168.859 40.339 162.935 47.2212C157.005 54.1111 157.775 64.484 164.641 70.3931C171.506 76.3022 181.884 75.5125 187.808 68.6302C193.732 61.748 192.968 51.3674 186.103 45.4583Z',
    grey16:
      'M237.614 79.8333L208.803 82.0259L196.234 108.081L212.476 131.944L241.287 129.751L253.856 103.696L237.614 79.8333Z',
    grey15:
      'M182.214 101.014L172.828 101.729L168.733 110.212L174.032 117.987L183.418 117.272L187.505 108.783L182.214 101.014Z',
    grey14:
      'M167.681 96.2371L151.546 97.4651L144.507 112.062L153.603 125.416L169.738 124.188L176.778 109.606L167.681 96.2371Z',
    grey13:
      'M138.042 82.36L131.003 82.9028L127.927 89.2618L131.9 95.0987L138.939 94.5558L142.007 88.1903L138.042 82.36Z',
    orange5:
      'M127.604 94.4869L120.565 95.0297L117.49 101.389L121.462 107.226L128.501 106.683L131.569 100.317L127.604 94.4869Z',
    grey11:
      'M91.9632 85.4173L86.9931 85.7884L84.829 90.2883L87.6275 94.4106L92.5966 94.0253L94.7682 89.5319L91.9632 85.4173Z',
    grey10:
      'M126.563 65.4707L121.392 76.189L109.538 77.091L102.856 67.2748L108.027 56.5565L119.881 55.6545L126.563 65.4707ZM111.156 70.5006C113.432 72.4594 116.863 72.1983 118.827 69.9169C120.791 67.6355 120.538 64.2036 118.263 62.2448C115.987 60.286 112.556 60.5472 110.592 62.8286C108.628 65.11 108.881 68.5419 111.156 70.5006Z',
    grey9:
      'M51.8378 265.768L43.175 261.106L34.8075 266.274L35.1028 276.104L43.7598 280.768L52.1332 275.599L51.8378 265.768Z',
    grey8:
      'M162.275 331.857L146.434 323.348L131.119 332.801L131.641 350.751L147.482 359.259L162.807 349.804L162.275 331.857Z',
    grey7:
      'M148.884 373.407L140.222 368.748L131.857 373.913L132.151 383.749L140.813 388.408L149.177 383.238L148.884 373.407Z',
    grey6:
      'M179.759 382.349L174.29 379.402L169.004 382.672L169.189 388.875L174.657 391.821L179.945 388.557L179.759 382.349Z',
    grey5:
      'M83.5934 364.596L63.9915 354.046L45.0552 365.744L45.721 387.992L65.3177 398.543L84.2591 386.844L83.5934 364.596Z',
    orange2:
      'M121.544 372.061L107.709 380.597L93.4002 372.918L92.9264 356.704L106.761 348.168L121.07 355.846L121.544 372.061ZM114.235 362.281C113.078 358.424 109.003 356.229 105.126 357.393C101.25 358.556 99.0549 362.623 100.216 366.49C101.373 370.346 105.448 372.541 109.325 371.378C113.201 370.214 115.393 366.138 114.235 362.281Z',
    orange1:
      'M33.5757 290.918L20.3539 283.808L7.58684 291.696L8.03417 306.697L21.2508 313.817L34.0253 305.926L33.5757 290.918Z',
    grey4:
      'M103.077 288.347L72.7435 272.069L43.4154 290.163L44.4211 324.535L74.7548 340.813L104.083 322.719L103.077 288.347Z',
    grey3:
      'M121.45 311.235L102.38 301.002L83.9418 312.377L84.5768 333.995L103.647 344.229L122.085 332.853L121.45 311.235Z',
    grey2:
      'M67.9552 321.896L57.8273 328.145L47.3525 322.524L47.0057 310.654L57.1336 304.406L67.6083 310.026L67.9552 321.896ZM52.264 317.841C53.1273 320.717 56.1595 322.344 59.0425 321.479C61.9255 320.613 63.5602 317.585 62.6969 314.709C61.8336 311.833 58.8014 310.206 55.9184 311.072C53.0353 311.937 51.4007 314.965 52.264 317.841Z',
    grey1:
      'M54.8508 346.122L44.2826 352.653L33.3456 346.767L32.9768 334.348L43.545 327.817L54.482 333.703L54.8508 346.122ZM49.1641 338.659C48.2941 335.761 45.2357 334.115 42.3376 334.985C39.4396 335.855 37.7935 338.913 38.6635 341.811C39.5335 344.709 42.5919 346.355 45.4899 345.485C48.3879 344.615 50.0341 341.557 49.1641 338.659Z',
    bighex: 'M186.415 336L249 273.415L226.096 187.911L140.592 165L78 227.592L100.911 313.096L186.415 336Z',
  },

  6: {
    orange4:
      'M211.805 102.42L205.372 114.246L212.396 125.724L225.853 125.38L232.281 113.562L225.256 102.079L211.805 102.42Z',
    orange3:
      'M135.084 36.2161L152.296 64.3864L136.577 93.3735L103.645 94.1902L86.4328 66.0198L102.152 37.0328L135.084 36.2161ZM114.704 49.4274C106.007 51.9966 101.055 61.1436 103.628 69.8521C106.203 78.5703 115.338 83.5451 124.025 80.9788C132.712 78.4124 137.674 69.2626 135.101 60.5541C132.528 51.8456 123.391 46.8611 114.704 49.4274Z',
    grey16:
      'M171.982 57.912L158.208 83.3121L173.291 107.997L202.148 107.282L215.922 81.8821L200.839 57.1971L171.982 57.912Z',
    grey15:
      'M162.216 113.74L157.728 122.015L162.638 130.054L172.044 129.816L176.531 121.541L171.612 113.504L162.216 113.74Z',
    grey14:
      'M143.379 109.906L135.665 124.131L144.116 137.958L160.269 137.553L167.983 123.328L159.544 109.508L143.379 109.906Z',
    grey13:
      'M117.355 112.879L113.996 119.089L117.673 125.12L124.731 124.945L128.091 118.735L124.404 112.707L117.355 112.879Z',
    orange5:
      'M112.873 127.842L109.513 134.052L113.191 140.083L120.249 139.908L123.608 133.698L119.921 127.67L112.873 127.842Z',
    grey11:
      'M67.2494 121.564L64.8673 125.941L67.4759 130.199L72.457 130.082L74.8265 125.697L72.2275 121.437L67.2494 121.564Z',
    grey10:
      'M91.8417 94.1663L98.0461 104.321L92.3793 114.771L80.5082 115.066L74.3039 104.911L79.9706 94.4611L91.8417 94.1663ZM87.718 109.84C90.5976 108.989 92.238 105.964 91.3852 103.077C90.5324 100.19 87.5114 98.5423 84.6319 99.393C81.7523 100.244 80.1119 103.269 80.9648 106.156C81.8176 109.042 84.8385 110.69 87.718 109.84Z',
    grey9:
      'M31.5133 297.051L21.8842 299.067L18.8145 308.41L25.3741 315.737L34.9999 313.727L38.0729 304.378L31.5133 297.051Z',
    grey8:
      'M158.553 276.437L140.957 280.136L135.334 297.233L147.299 310.624L164.895 306.925L170.523 289.82L158.553 276.437Z',
    grey7:
      'M175.084 316.841L165.457 318.859L162.387 328.199L168.95 335.531L178.577 333.514L181.642 324.171L175.084 316.841Z',
    grey6:
      'M204.456 303.786L198.376 305.056L196.44 310.962L200.578 315.587L206.658 314.317L208.599 308.414L204.456 303.786Z',
    grey5:
      'M119.475 352.17L97.6861 356.732L90.7414 377.879L105.585 394.464L127.371 389.907L134.318 368.756L119.475 352.17Z',
    orange2:
      'M153.307 333.427L148.227 348.869L132.337 352.216L121.527 340.121L126.608 324.679L142.498 321.332L153.307 333.427ZM141.417 330.656C138.046 328.453 133.516 329.4 131.301 332.787C129.087 336.175 130.028 340.699 133.407 342.908C136.777 345.112 141.308 344.165 143.522 340.777C145.737 337.39 144.787 332.86 141.417 330.656Z',
    orange1:
      'M33.751 328.051L19.0583 331.132L14.3772 345.391L24.3846 356.575L39.0795 353.504L43.7648 339.239L33.751 328.051Z',
    grey4:
      'M85.2468 281.304L51.5607 288.4L40.7898 321.134L63.705 346.772L97.3911 339.676L108.162 306.942L85.2468 281.304Z',
    grey3:
      'M114.044 286.971L92.867 291.432L86.0955 312.011L100.51 328.134L121.687 323.673L128.459 303.094L114.044 286.971Z',
    grey2:
      'M80.0034 329.591L76.2841 340.895L64.6517 343.346L56.7388 334.492L60.458 323.187L72.0903 320.737L80.0034 329.591ZM65.3907 336.6C67.9038 338.243 71.2711 337.534 72.9183 335.014C74.5655 332.495 73.8646 329.126 71.3514 327.483C68.8383 325.84 65.471 326.549 63.8238 329.068C62.1766 331.588 62.8775 334.957 65.3907 336.6Z',
    grey1:
      'M85.5897 356.56L81.716 368.365L69.559 370.91L61.2757 361.65L65.1494 349.846L77.3064 347.301L85.5897 356.56ZM76.4324 354.517C73.8998 352.862 70.5002 353.573 68.8445 356.106C67.1887 358.638 67.9004 362.038 70.4329 363.694C72.9655 365.349 76.3651 364.638 78.0209 362.105C79.6766 359.573 78.9649 356.173 76.4324 354.517Z',
    bighex: 'M186.415 336L249 273.415L226.096 187.911L140.592 165L78 227.592L100.911 313.096L186.415 336Z',
  },

  7: {
    orange4:
      'M208.758 68.2912L209.469 81.7349L221.461 87.8371L232.748 80.4998L232.037 67.065L220.04 60.9586L208.758 68.2912Z',
    orange3:
      'M108.731 52.0484L138.144 67.0394L139.926 99.9662L112.295 117.902L82.882 102.911L81.1004 69.9841L108.731 52.0484ZM98.2834 73.9737C92.2188 80.7153 92.7879 91.1013 99.5388 97.1743C106.297 103.254 116.685 102.711 122.743 95.9765C128.8 89.2423 128.238 78.8489 121.487 72.7759C114.736 66.7029 104.341 67.2395 98.2834 73.9737Z',
    grey16:
      'M151.528 51.2175L153.089 80.0696L178.863 93.2056L203.076 77.4897L201.514 48.6377L175.74 35.5016L151.528 51.2175Z',
    grey15:
      'M172.432 103.896L172.941 113.296L181.332 117.576L189.222 112.449L188.713 103.049L180.315 98.7765L172.432 103.896Z',
    grey14:
      'M154.376 110.491L155.25 126.65L169.689 134.006L183.24 125.205L182.365 109.047L167.94 101.69L154.376 110.491Z',
    grey13:
      'M133.759 126.647L134.148 133.697L140.438 136.91L146.361 133.066L145.972 126.017L139.675 122.811L133.759 126.647Z',
    orange5:
      'M137.773 141.743L138.162 148.793L144.452 152.006L150.375 148.162L149.986 141.113L143.689 137.907L137.773 141.743Z',
    grey11:
      'M84.3574 149.532L84.6196 154.509L89.0711 156.771L93.2536 154.063L92.9772 149.087L88.5324 146.817L84.3574 149.532Z',
    grey10:
      'M102.226 124.061L112.828 129.465L113.471 141.335L103.511 147.801L92.908 142.397L92.2656 130.526L102.226 124.061ZM106.917 139.573C108.925 137.341 108.739 133.905 106.502 131.892C104.264 129.879 100.827 130.056 98.8189 132.288C96.8108 134.521 96.9968 137.957 99.2347 139.97C101.473 141.983 104.909 141.806 106.917 139.573Z',
    grey9:
      'M38.6805 356.956L32.0368 364.211L34.9958 373.59L44.5985 375.713L51.2424 368.464L48.2832 359.079L38.6805 356.956Z',
    grey8:
      'M129.822 266.086L117.69 279.358L123.1 296.523L140.631 300.417L152.763 287.145L147.352 269.97L129.822 266.086Z',
    grey7:
      'M166.823 289.253L160.183 296.508L163.139 305.885L172.748 308.01L179.388 300.754L176.426 291.378L166.823 289.253Z',
    grey6:
      'M183.059 261.512L178.862 266.091L180.736 272.017L186.795 273.359L190.992 268.78L189.124 262.853L183.059 261.512Z',
    grey5:
      'M142.277 350.392L127.244 366.81L133.944 388.036L155.676 392.844L170.71 376.431L164.01 355.2L142.277 350.392Z',
    orange2:
      'M158.816 315.429L163.7 330.934L152.749 342.924L136.913 339.409L132.029 323.904L142.98 311.914L158.816 315.429ZM147.543 320.117C143.52 320.294 140.393 323.707 140.571 327.75C140.749 331.794 144.154 334.919 148.187 334.741C152.21 334.564 155.337 331.151 155.159 327.108C154.981 323.065 151.566 319.94 147.543 320.117Z',
    orange1:
      'M58.5862 380.825L48.4522 391.901L52.9708 406.212L67.6238 409.455L77.7659 398.387L73.2469 384.068L58.5862 380.825Z',
    grey4:
      'M73.1295 312.813L49.9132 338.232L60.2665 371.1L93.8363 378.55L117.053 353.132L106.699 320.263L73.1295 312.813Z',
    grey3:
      'M99.8228 300.612L85.2275 316.592L91.7364 337.256L112.851 341.939L127.446 325.959L120.937 305.295L99.8228 300.612Z',
    grey2:
      'M97.0471 355.087L100.623 366.438L92.6059 375.215L81.0133 372.642L77.4376 361.292L85.4545 352.514L97.0471 355.087ZM89.2698 369.306C92.2695 369.174 94.5902 366.633 94.4577 363.626C94.3253 360.619 91.7903 358.292 88.7906 358.424C85.7909 358.556 83.4702 361.097 83.6026 364.104C83.735 367.111 86.2701 369.438 89.2698 369.306Z',
    grey1:
      'M117.321 373.73L121.063 385.577L112.675 394.737L100.545 392.051L96.8029 380.204L105.191 371.044L117.321 373.73ZM108.692 377.414C105.669 377.547 103.323 380.109 103.456 383.132C103.589 386.154 106.151 388.5 109.174 388.367C112.197 388.234 114.542 385.672 114.409 382.649C114.276 379.626 111.714 377.281 108.692 377.414Z',
    bighex: 'M186.415 336L249 273.415L226.096 187.911L140.592 165L78 227.592L100.911 313.096L186.415 336Z',
  },

  8: {
    orange4:
      'M195.98 35.7629L202.703 47.4264L216.159 47.4346L222.898 35.781L216.179 24.1251L202.717 24.1153L195.98 35.7629Z',
    orange3:
      'M99.446 66.5891L132.459 66.6314L148.961 95.1796L132.452 123.685L99.4391 123.643L82.9363 95.095L99.446 66.5891ZM100.063 90.8686C97.7095 99.6261 102.921 108.628 111.691 110.984C120.47 113.343 129.485 108.154 131.835 99.4059C134.186 90.6581 128.976 81.6468 120.207 79.2905C111.437 76.9341 102.413 82.1209 100.063 90.8686Z',
    grey16:
      'M137.224 46.4637L151.685 71.4789L180.613 71.5155L195.081 46.537L180.62 21.5219L151.692 21.4852L137.224 46.4637Z',
    grey15:
      'M179.723 83.9595L184.434 92.1091L193.854 92.1237L198.565 83.9791L193.854 75.8295L184.432 75.8245L179.723 83.9595Z',
    grey14:
      'M166.612 98.0181L174.711 112.028L190.916 112.046L199.01 98.0616L190.911 84.0521L174.719 84.0268L166.612 98.0181Z',
    grey13:
      'M155.55 121.76L159.089 127.869L166.153 127.884L169.692 121.775L166.152 115.666L159.086 115.66L155.55 121.76Z',
    orange5:
      'M145.097 133.733L146.288 140.692L152.904 143.166L158.349 138.671L157.158 131.712L150.536 129.246L145.097 133.733Z',
    grey11:
      'M105.043 144.968L105.872 149.882L110.552 151.621L114.398 148.454L113.555 143.542L108.881 141.794L105.043 144.968Z',
    grey10:
      'M126.265 133.737L138.165 133.753L144.115 144.045L138.164 154.321L126.263 154.305L120.314 144.013L126.265 133.737ZM137.474 145.442C138.253 142.542 136.531 139.563 133.624 138.782C130.717 138.001 127.733 139.716 126.954 142.616C126.175 145.515 127.897 148.494 130.804 149.276C133.711 150.057 136.695 148.342 137.474 145.442Z',
    grey9:
      'M92.4796 421.678L95.7085 430.971L105.367 432.822L111.797 425.381L108.574 416.091L98.9095 414.236L92.4796 421.678Z',
    grey8:
      'M55.8121 298.311L61.7281 315.291L79.4025 318.684L91.1567 305.107L85.2408 288.127L67.5573 284.729L55.8121 298.311Z',
    grey7:
      'M93.7748 276.756L97.0053 286.046L106.661 287.898L113.095 280.453L109.865 271.163L100.207 269.316L93.7748 276.756Z',
    grey6:
      'M53.5961 327.238L55.6322 333.106L61.7375 334.272L65.7962 329.578L63.7601 323.709L57.6573 322.538L53.5961 327.238Z',
    grey5:
      'M135.916 327.399L143.222 348.426L165.083 352.614L179.637 335.774L172.336 314.748L150.47 310.558L135.916 327.399Z',
    orange2:
      'M113.005 296.237L128.97 299.304L134.319 314.637L123.703 326.902L107.739 323.835L102.39 308.502L113.005 296.237ZM111.776 308.384C110.021 312.008 111.539 316.38 115.181 318.144C118.824 319.908 123.191 318.397 124.95 314.764C126.706 311.139 125.188 306.767 121.545 305.003C117.903 303.239 113.531 304.759 111.776 308.384Z',
    orange1:
      'M122.94 415.5L127.872 429.679L142.612 432.501L152.427 421.148L147.505 406.965L132.758 404.14L122.94 415.5Z',
    grey4:
      'M70.0002 370.395L81.3392 402.9L115.181 409.403L137.683 383.401L126.344 350.897L92.5026 344.394L70.0002 370.395Z',
    grey3:
      'M71.9432 341.11L79.0719 361.544L100.347 365.633L114.498 349.277L107.369 328.843L86.0942 324.755L71.9432 341.11Z',
    grey2:
      'M118.562 369.43L130.249 371.676L134.164 382.9L126.393 391.879L114.706 389.633L110.791 378.409L118.562 369.43ZM127.379 383.028C128.688 380.326 127.555 377.077 124.845 375.765C122.136 374.453 118.884 375.578 117.576 378.28C116.267 380.983 117.4 384.232 120.109 385.544C122.819 386.856 126.071 385.731 127.379 383.028Z',
    grey1:
      'M144.597 360.446L156.8 362.781L160.876 374.513L152.75 383.911L140.548 381.576L136.471 369.844L144.597 360.446ZM143.74 369.789C142.421 372.512 143.561 375.793 146.284 377.112C149.008 378.431 152.288 377.291 153.607 374.568C154.926 371.844 153.786 368.564 151.063 367.245C148.34 365.926 145.059 367.066 143.74 369.789Z',
    bighex: 'M186.415 336L249 273.415L226.096 187.911L140.592 165L78 227.592L100.911 313.096L186.415 336Z',
  },

  9: {
    orange4:
      'M120.587 28.3007L133.607 24.8776L137.15 11.8966L127.675 2.33336L114.664 5.75426L111.118 18.7407L120.587 28.3007Z',
    orange3:
      'M124.973 129.543L133.685 97.7007L165.566 89.2764L188.734 112.694L180.022 144.537L148.141 152.961L124.973 129.543ZM148.562 135.326C156.394 139.897 166.448 137.232 171.025 129.39C175.608 121.539 172.968 111.477 165.145 106.912C157.322 102.346 147.259 105.005 142.682 112.847C138.105 120.69 140.739 130.76 148.562 135.326Z',
    grey16:
      'M115.478 87.8044L143.413 80.4224L151.047 52.5195L130.746 31.9987L102.811 39.3807L95.1766 67.2836L115.478 87.8044Z',
    grey15:
      'M162.821 56.6473L171.922 54.2423L174.41 45.1572L167.789 38.472L158.688 40.877L156.208 49.9671L162.821 56.6473Z',
    grey14:
      'M172.941 72.9907L188.586 68.8565L192.861 53.2253L181.494 41.742L165.849 45.8762L161.571 61.4937L172.941 72.9907Z',
    grey13:
      'M192.944 89.9009L199.768 88.0903L201.638 81.279L196.673 76.2595L189.849 78.0701L187.987 84.8865L192.944 89.9009Z',
    orange5:
      'M201.75 103.132L208.777 103.811L212.903 98.0771L209.996 91.6427L202.969 90.9638L198.85 96.7051L201.75 103.132Z',
    grey11:
      'M220.689 94.4583L225.649 94.9497L228.556 90.8902L226.51 86.3472L221.549 85.87L218.636 89.9217L220.689 94.4583Z',
    grey10:
      'M196.808 121.304L199.949 109.826L211.442 106.789L219.794 115.23L216.653 126.709L205.16 129.746L196.808 121.304ZM211.046 113.563C208.453 112.05 205.126 112.929 203.609 115.529C202.091 118.128 202.962 121.458 205.556 122.971C208.149 124.485 211.476 123.605 212.993 121.006C214.51 118.406 213.64 115.077 211.046 113.563Z',
    grey9:
      'M163.368 450.124L171.424 455.772L180.34 451.623L181.201 441.826L173.152 436.177L164.23 440.327L163.368 450.124Z',
    grey8:
      'M61.4585 371.522L76.1898 381.831L92.5093 374.244L94.0993 356.356L79.368 346.046L63.0386 353.636L61.4585 371.522Z',
    grey7:
      'M79.6367 331.831L87.6915 337.476L96.6058 333.33L97.4687 323.527L89.414 317.882L80.5007 322.034L79.6367 331.831Z',
    grey6:
      'M66.8858 311.378L71.9701 314.946L77.6036 312.32L78.149 306.138L73.0647 302.57L67.4301 305.19L66.8858 311.378Z',
    grey5:
      'M143.44 348.25L161.667 361.03L181.846 351.637L183.799 329.465L165.577 316.684L145.392 326.078L143.44 348.25Z',
    orange2:
      'M106.63 336.38L121.371 329.528L134.679 338.834L133.245 354.992L118.504 361.844L105.196 352.538L106.63 336.38ZM112.739 346.951C113.436 350.917 117.225 353.575 121.211 352.875C125.197 352.175 127.854 348.394 127.156 344.418C126.459 340.452 122.67 337.794 118.684 338.494C114.698 339.194 112.042 342.985 112.739 346.951Z',
    orange1:
      'M184.458 427.294L196.754 435.908L210.359 429.574L211.677 414.624L199.388 406.001L185.775 412.337L184.458 427.294Z',
    grey4:
      'M115.136 421.683L143.347 441.412L174.598 426.888L177.636 392.636L149.425 372.908L118.174 387.431L115.136 421.683Z',
    grey3:
      'M99.5795 396.796L117.316 409.198L136.962 400.068L138.871 378.525L121.135 366.122L101.488 375.253L99.5795 396.796Z',
    grey2:
      'M153.956 392.491L164.747 387.475L174.489 394.288L173.44 406.116L162.648 411.132L152.906 404.32L153.956 392.491ZM169.062 398.361C168.542 395.404 165.722 393.432 162.758 393.953C159.793 394.474 157.814 397.289 158.333 400.246C158.853 403.203 161.673 405.175 164.638 404.655C167.602 404.134 169.581 401.319 169.062 398.361Z',
    grey1:
      'M169.814 369.973L181.077 364.728L191.246 371.859L190.154 384.234L178.892 389.479L168.722 382.349L169.814 369.973ZM174.585 378.052C175.109 381.032 177.953 383.026 180.933 382.503C183.913 381.979 185.907 379.135 185.383 376.155C184.86 373.175 182.016 371.181 179.036 371.705C176.056 372.228 174.062 375.072 174.585 378.052Z',
    bighex: 'M186.415 338L249 275.415L226.096 189.911L140.592 167L78 229.592L100.911 315.096L186.415 338Z',
  },
};
