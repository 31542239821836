import { useSupplierAccounts } from './useSupplierAccounts';

type Params = {
  skip?: boolean;
};

export const useDealerSupplierAccounts = ({ skip }: Params = {}) => {
  const { accounts, ...rest } = useSupplierAccounts({ type: 'parts', supplierType: 'dealers' }, { skip });

  return { dealerAccounts: accounts, ...rest };
};
