import { useCallback } from 'react';
import { useCreateStripeToken } from 'integrations/stripe';
import { useCreatePaymentCardMutation } from '../api/createCard';

export const useAddPaymentCard = () => {
  const [createStripeToken, stripeTokenStatus] = useCreateStripeToken();
  const [createPaymentCard, paymentCardStatus] = useCreatePaymentCardMutation();

  const addPaymentCard = useCallback(
    async (holder: string) => {
      const stripeToken = await createStripeToken(holder);

      if (!stripeToken) {
        return null;
      }

      return createPaymentCard({ input: { token: stripeToken.id } });
    },
    [createStripeToken, createPaymentCard]
  );

  const status = {
    ...paymentCardStatus,
    error: stripeTokenStatus.error ?? paymentCardStatus.error,
    isError: stripeTokenStatus.error || paymentCardStatus.isError,
  };

  return [addPaymentCard, status] as const;
};
