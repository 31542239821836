import { Button } from '@partstech/ui';

type Props = {
  onSubmit: () => void;
  onCancel: () => void;
};

export const FormActions = ({ onSubmit, onCancel }: Props) => (
  <>
    <Button color="negative" onClick={onSubmit} noHover>
      Yes, delete it
    </Button>

    <Button onClick={onCancel} variant="text">
      No, I want to keep it
    </Button>
  </>
);
