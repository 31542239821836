/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type GetStockOrderTemplateQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;

export type GetStockOrderTemplateQuery = {
  __typename?: 'Query';
  currentUser?: {
    __typename?: 'User';
    activeMember?: {
      __typename?: 'ShopMember';
      shop?: {
        __typename?: 'Shop';
        stockOrderTemplate?: {
          __typename?: 'StockOrderTemplate';
          id: string;
          name: Types.Scalars['String']['input'];
          items?: Array<{
            __typename?: 'StockOrderTemplateItem';
            displayPartNumber: Types.Scalars['String']['input'];
            id: string;
            lineCardId?: number | null;
            partName?: Types.Scalars['String']['input'] | null;
            partNumberId: Types.Scalars['String']['input'];
            quantity: number;
            account?: { __typename?: 'Account'; id: string } | null;
          }> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export const GetStockOrderTemplateDocument = `
    query GetStockOrderTemplate($id: ID!) {
  currentUser {
    activeMember {
      shop {
        stockOrderTemplate(id: $id) {
          id
          name
          items {
            account {
              id
            }
            displayPartNumber
            id
            lineCardId
            partName
            partNumberId
            quantity
          }
        }
      }
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetStockOrderTemplate: build.query<GetStockOrderTemplateQuery, GetStockOrderTemplateQueryVariables>({
      query: (variables) => ({ document: GetStockOrderTemplateDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetStockOrderTemplateQuery, useLazyGetStockOrderTemplateQuery } = injectedRtkApi;
