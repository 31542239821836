import { Button, ButtonGroup, Card, Link, Typography } from '@partstech/ui';
import { SUPPORT_EMAIL, SUPPORT_PHONE } from '../../constant';
import type { ButtonGroupProps, CardProps } from '@partstech/ui';
import type { PropsWithChildren } from 'react';

type Props = PropsWithChildren<CardProps & { buttonGroupProps?: ButtonGroupProps }>;

export const SupportContact = ({ children, buttonGroupProps, ...props }: Props) => (
  <Card textAlign="center" {...props}>
    {children}

    <ButtonGroup
      justifyContent="center"
      mt={2}
      flexDirection={{ sm: 'column', md: 'row' }}
      gap={{ sm: 2, md: 8 }}
      {...buttonGroupProps}
    >
      <Button
        variant="light"
        leadingIcon="call"
        iconColor="primary"
        as={<Link variant="subtitle2" color="primary" to={`tel:${SUPPORT_PHONE}`} />}
      >
        <Typography variant="subtitle2" color="primary">
          {SUPPORT_PHONE}
        </Typography>
      </Button>

      <Button variant="light" leadingIcon="email" iconColor="primary" as={<Link to={`mailto:${SUPPORT_EMAIL}`} />}>
        <Typography variant="subtitle2" color="primary">
          {SUPPORT_EMAIL}
        </Typography>
      </Button>
    </ButtonGroup>
  </Card>
);
