import { isEmpty } from '@partstech/ui/utils';
import { useMemo } from 'react';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import { useGetFullPartTypesQuery } from './GetFullPartTypes.generated';
import type { PartType } from 'shared/api';

type Args = Partial<{
  search: string;
  ids: string[];
  categoryID: string;
}>;

type Params = {
  skip?: boolean;
};

const emptyPartTypes: PartType[] = [];

export const useGetFullPartTypes = ({ ids, search, categoryID }: Args, { skip = false }: Params = {}) => {
  const { hasCurrentUser } = useGetCurrentUser();

  const isEmptyPayload = isEmpty(ids) && isEmpty(search) && isEmpty(categoryID);

  const { currentData, ...props } = useGetFullPartTypesQuery(
    { ids, search, categoryID },
    { skip: isEmptyPayload || !hasCurrentUser || skip }
  );

  const partTypes = useMemo(() => currentData?.partTypes ?? emptyPartTypes, [currentData?.partTypes]);

  return {
    partTypes,
    ...props,
  };
};
