import { Box, ImageFallback, ModalView, useMedia } from '@partstech/ui';
import { useKeyboardInteractions } from '@partstech/ui/hooks';
import { memo, useMemo } from 'react';
import { useElementsNavigation } from 'shared/lib/react-dom';
import { ImageAction } from '../Images/ImageAction';
import { FilesCarousel } from './FilesCarousel';
import { styles } from './FileViewerModal.styles';
import { Images360 } from './Images360';
import type { ProductFile, ProductImage } from 'types/product';

export type FileViewerModalProps = {
  brandName: string;
  files: ProductFile[];
  image360Groups: ProductImage['image360Groups'];
  partNumber: string;
  partTypeName: string;
  title?: string;
};

type Props = FileViewerModalProps & { onClose: () => void; currentIndex?: number };

export const FileViewerModal = memo(
  ({
    brandName,
    currentIndex,
    files,
    image360Groups,
    onClose,
    partNumber,
    partTypeName,
    title = 'View image',
  }: Props) => {
    const { isTablet, isMobile } = useMedia();
    const filesCount = image360Groups.length > 0 ? files.length + 1 : files.length;

    const { current, next, prev, currentClick } = useElementsNavigation(filesCount, currentIndex);

    useKeyboardInteractions({ onRightArrow: next, onLeftArrow: prev });

    const images360 = useMemo(
      () => image360Groups.flatMap((imageGroup) => imageGroup.map((image) => image.full ?? '')).reverse(),
      [image360Groups]
    );

    const currentFile = files[current];
    const previewFile = currentFile?.full;
    const isVideo = currentFile?.isVideo;
    const hasImages360Groups = image360Groups.length > 0;
    const shouldShowImageActions = files.length > 1 || hasImages360Groups;
    const alt = `${brandName} ${partNumber} ${partTypeName ?? ''} (full size)`;

    return (
      <ModalView title={title} closeIcon="close" onClose={onClose}>
        <Box
          display={{ sm: 'flex' }}
          flexDirection={{ sm: 'column', md: 'column', lg: 'row' }}
          my={{ md: 10, lg: 15 }}
          mx={{ md: 6, lg: 0 }}
          height={{ sm: `calc(100vh - 52px)` }}
          alignContent={{ sm: 'center' }}
          justifyContent={{ sm: 'space-between' }}
        >
          <Box
            position="relative"
            maxWidth={{ lg: 200 }}
            width={{ lg: '100%' }}
            height={{ sm: '100%', md: 200 }}
            mx={{ sm: 6, lg: 'auto' }}
          >
            {isVideo && <iframe css={styles.video} allowFullScreen src={previewFile} title="video" />}

            {!isVideo && previewFile && <ImageFallback src={previewFile} alt={alt} css={styles.image} />}

            {!currentFile && hasImages360Groups && <Images360 images={images360} />}

            {shouldShowImageActions && (
              <>
                <ImageAction
                  iconName="chevron_left"
                  position="absolute"
                  variant="large"
                  top="50%"
                  left={{ sm: 2, md: -6, lg: -21 }}
                  radius={30}
                  onClick={prev}
                />
                <ImageAction
                  iconName="chevron_right"
                  position="absolute"
                  variant="large"
                  top="50%"
                  right={{ sm: 2, md: -6, lg: -21 }}
                  radius={30}
                  onClick={next}
                />
              </>
            )}
          </Box>

          <Box pb={{ sm: 8 }} px={{ sm: 4 }}>
            <FilesCarousel
              files={files}
              hasImages360Groups={image360Groups.length > 0}
              currentIndex={current}
              isVertical={!isTablet && !isMobile}
              onClick={currentClick}
            />
          </Box>
        </Box>
      </ModalView>
    );
  }
);
