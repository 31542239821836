import { css } from '@emotion/react';
import { Box, Card, Icon, ListItem, Menu, MenuItem, TextField, Typography, useMedia } from '@partstech/ui';
import { useMembershipsMenu } from './useMembershipsMenu';
import type { Theme } from '@partstech/ui';
import type { ShopMembership, SupplierMembership } from 'models';

const styles = {
  wrapper: (showSearchField: boolean) => (theme: Theme) => css`
    ${showSearchField &&
    `
      .menu {
        margin-top: -5px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;

        > div {
          padding: 0;
        }
      }
      `}

    ${theme.breakpoints.down('md')} {
      > div {
        box-shadow: none;
      }
    }
  `,

  searchFieldCard: (theme: Theme) => css`
    .text-field-container {
      margin: 0;
      border: none;
      border-radius: ${theme.sizing(4, 4, 0, 0)};
      border-bottom: 1px solid ${theme.color.mono17};
      z-index: ${theme.zIndex.default};

      input {
        font-size: ${theme.sizing(4)};
      }

      &:focus-within {
        border: none;
        border-bottom: 1px solid ${theme.color.mono17};
        box-shadow: none;
      }

      ${theme.breakpoints.down('md')} {
        border-radius: 0;
      }
    }
  `,

  menuTitle: (showSearchField: boolean) => (theme: Theme) => css`
    /* padding: ${theme.sizing(4, 4, 1)}; */
    ${!showSearchField && 'padding-top: 0'}
  `,
};

type Props = {
  shopMemberships: ShopMembership[];
  supplierMemberships: SupplierMembership[];
};

export const MembershipsMenu = ({ shopMemberships, supplierMemberships }: Props) => {
  const { isMobile } = useMedia();

  const {
    value,
    changeValue,
    searchFieldRef,
    showSearchField,
    shopMembershipsBySearch,
    supplierMembershipsBySearch,
    selectShopLocation,
    selectSupplierMember,
    menuMaxHeight,
  } = useMembershipsMenu({ shopMemberships, supplierMemberships });

  return (
    <Card zIndex="popover" elevation={8} width={isMobile ? undefined : 75} css={styles.wrapper(showSearchField)}>
      {showSearchField && (
        <Box css={styles.searchFieldCard} ref={searchFieldRef}>
          <TextField
            name="searchValue"
            placeholder={supplierMemberships.length ? 'Search locations and portals' : 'Search locations'}
            value={value}
            onChange={changeValue}
            prefix={<Icon name="search" color="subtleText" />}
            data-testid="searchField"
          />
        </Box>
      )}

      <Menu maxHeight={menuMaxHeight} builtIn>
        {!shopMembershipsBySearch.length && !supplierMembershipsBySearch.length && (
          <Box p={4}>
            <Typography variant="bodyText2" color="dark">
              Your search uncovered no results
            </Typography>
          </Box>
        )}

        {shopMembershipsBySearch.length > 0 && (
          <ListItem css={styles.menuTitle(showSearchField)}>
            <Typography variant="overline" textTransform="uppercase" component="div">
              Shop Locations
            </Typography>
          </ListItem>
        )}

        {shopMembershipsBySearch.map((shopLocation) => (
          <MenuItem
            key={shopLocation.id}
            selected={shopLocation.isActive}
            prefix={<Icon name="location_on" color="subtleText" />}
            onClick={selectShopLocation(shopLocation.id)}
          >
            {shopLocation.shop?.name ?? ''}
          </MenuItem>
        ))}

        {supplierMembershipsBySearch.length > 0 && (
          <ListItem css={styles.menuTitle(showSearchField)}>
            <Typography variant="overline" textTransform="uppercase" component="div">
              Supplier Portals
            </Typography>
          </ListItem>
        )}

        {supplierMembershipsBySearch.map((supplierMember) => (
          <MenuItem
            key={supplierMember.id}
            selected={supplierMember.isActive}
            prefix={<Icon name="domain" color="subtleText" />}
            onClick={selectSupplierMember(supplierMember.id)}
          >
            {supplierMember.supplier?.name ?? ''}
          </MenuItem>
        ))}
      </Menu>
    </Card>
  );
};
