import { Icon, IconButton } from '@partstech/ui';
import { useCreateModal } from '@partstech/ui/hooks';
import { memo, useCallback } from 'react';
import { RefineModal } from './RefineModal';

type Props = {
  onStartNewSearch: () => void;
};

export const RefineSearchButton = memo(({ onStartNewSearch }: Props) => {
  const { open: openRefineModal } = useCreateModal(RefineModal, { onSearch: onStartNewSearch });

  const handleClick = useCallback(() => {
    openRefineModal();
  }, [openRefineModal]);

  return (
    <IconButton className="tune-button" onClick={handleClick}>
      <Icon name="tune" />
    </IconButton>
  );
});
