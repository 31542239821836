import { PartCategory } from '../models/PartCategory';
import { createSubCategoryFromQuery } from './createSubCategoryFromQuery';
import { createPartTypesFromQuery } from './partTypes/createPartTypesFromQuery';
import type { GetCategoriesWithPartTypesQuery } from 'features/taxonomy';
import type { GetCategoryQuery } from 'store/queries/categories/GetCategory.generated';
import type { ArrayItemType } from 'types/general';

export const createCategoryFromQuery = (
  category:
    | ArrayItemType<NonNullable<GetCategoryQuery['category']>>
    | ArrayItemType<NonNullable<GetCategoriesWithPartTypesQuery['categories']>>
) =>
  new PartCategory(
    category.id,
    category.name,
    category.subCategories?.map((subCategory) => {
      const partTypes =
        'partTypes' in subCategory
          ? createPartTypesFromQuery(subCategory.partTypes).map((partType) => partType.setSubCategoryId(subCategory.id))
          : [];

      return createSubCategoryFromQuery(subCategory, category.id, partTypes);
    }) ?? []
  );
