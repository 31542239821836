import { Box, Button, Typography } from '@partstech/ui';
import { CardBrand, CardName } from 'entities/paymentCard';
import { usePaymentUpdated } from './usePaymentUpdated';

export const PaymentUpdated = () => {
  const { card, submitForm } = usePaymentUpdated();

  if (!card) {
    return null;
  }

  return (
    <>
      <Typography>Your PartsTech subscription is now attached to this card.</Typography>

      <Box display="flex" mt={4} mb={6}>
        <CardBrand name={card.brand} size="large" mr={3} />

        <CardName card={card} />
      </Box>

      <Button fullWidth onClick={submitForm} data-testid="submitButton">
        Okay
      </Button>
    </>
  );
};
