import { PartType } from 'models';
import type { SearchEntryPointInterface } from 'features/searchForm';

export class SuggestedPartTypeOption extends PartType implements SearchEntryPointInterface {
  getColumnTitle(): string {
    return 'did you mean?';
  }

  getClassName(): string {
    return 'fs-suggested';
  }
}
