import { isNotNull } from '@partstech/ui/utils';
import { useMemo } from 'react';
import { createVehicleFromQuery } from 'factories';
import { useGetVehiclesByYearMakeQuery } from './GetVehiclesByYearMake.generated';
import type { Vehicle } from 'models';

const emptyVehicles: Vehicle[] = [];

export const useGetVehiclesByYearMake = (year: number | null, makeId: string | null) => {
  const { data, ...props } = useGetVehiclesByYearMakeQuery({ year, makeId }, { skip: !year || !makeId });

  const vehicles = useMemo(
    () => (data?.vehicles ? data?.vehicles.map(createVehicleFromQuery).filter(isNotNull) : emptyVehicles),
    [data?.vehicles]
  );

  return {
    vehicles,
    ...props,
  };
};
