import { useMemo, useState } from 'react';
import { SupplierSelectorContextProvider } from './SupplierSelectorContext';
import type { PropsWithChildren } from 'react';

export const SupplierSelectorProvider = ({ children }: PropsWithChildren) => {
  const [isSticky, setIsSticky] = useState(false);

  const value = useMemo(() => ({ isSticky, setIsSticky }), [isSticky]);

  return <SupplierSelectorContextProvider value={value}>{children}</SupplierSelectorContextProvider>;
};
