import { useEffect, useState } from 'react';
import { Userpilot } from 'userpilot';

export type IdentifyUserpilotArgs = {
  userid: string;
  parameters?: {
    email: string | null;
    fullName: string;
    id: string | null;
    ldFlags: string;
    numberOrders: number;
    lastOrderDate: string | null;
    package: string;
    partSupplierCount: number;
    /** registration date */
    created_at: string | null;
    shopName: string;
    shopState: string | null;
    shopShippingZipCode: string | null;
    shopManagementSystemName: string | null;
    subscriptionStartedAt: string | null;
    suppliers: string;
    userIdEven: boolean;
  };
};

type Params = {
  skip?: boolean;
};

/**
 * useIdentifyUserpilot
 *
 * A custom hook that triggers `Userpilot.identify()` when the `userid` or `parameters` change.
 * The hook allows for optional skipping of the identify action using the `skip` parameter.
 *
 * @param {IdentifyUserpilotArgs} args - The arguments for identifying the user.
 * @param {string} args.userid - The unique identifier for the user.
 * @param {object} [args.parameters] - Additional parameters to send with the identification.
 * @param {Params} [options] - Optional parameters.
 * @param {boolean} [options.skip=false] - If true, the identify action will be skipped.
 */
export const useIdentifyUserpilot = ({ userid, parameters }: IdentifyUserpilotArgs, { skip }: Params = {}) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (isLoaded || skip) {
      return;
    }

    Userpilot.identify(userid, parameters);

    setIsLoaded(true);
  }, [isLoaded, parameters, skip, userid]);
};
