import { memo, useMemo } from 'react';
import { ProductWarnings } from 'components/ProductWarnings';
import type { OrderItemProductWarning, ProductWarning } from 'shared/api';

type Props = {
  warnings: OrderItemProductWarning[];
};

export const Warnings = memo(({ warnings }: Props) => {
  const convertedWarnings = useMemo<ProductWarning[]>(
    () =>
      warnings.map((warning) => {
        if ('attachment' in warning) {
          return { url: warning.attachment };
        }

        if ('path' in warning) {
          return { url: warning.path };
        }

        return { text: warning.text };
      }),
    [warnings]
  );

  if (convertedWarnings.length === 0) {
    return null;
  }

  return <ProductWarnings warnings={convertedWarnings} color="secondary" variant="bodyText1" />;
});
