import { useCreateModal } from '@partstech/ui/hooks';
import { DateTime } from 'luxon';
import { useEffect, useMemo } from 'react';
import { usePermissions } from 'store/queries/currentUser/usePermissions';
import { DowngradeReminderModal } from '../components/DowngradeReminderModal';
import { useSkipSubscriptionReminder } from './useSkipSubscriptionReminder';
import { useSubscription } from './useSubscription';

export const useSubscriptionDowngradeReminder = () => {
  const { member } = usePermissions();

  const { subscription } = useSubscription();

  const currentPeriodEnd = subscription?.currentPeriodEnd;
  const isPlanExpires = subscription?.plan?.isExpires;

  const { isSkipped, skip } = useSkipSubscriptionReminder();

  const { open } = useCreateModal(
    DowngradeReminderModal,
    { size: 'dialog', onClose: skip },
    { customId: 'subscriptionDowngradeReminder' }
  );

  const showReminder = useMemo(() => {
    if (!isPlanExpires || !currentPeriodEnd || !member.subscriptionAllowed || isSkipped) {
      return false;
    }

    const subscriptionEndDate = DateTime.fromISO(currentPeriodEnd);
    const dateAfterThreeDays = DateTime.now().plus({ hours: 72 });

    return subscriptionEndDate < dateAfterThreeDays;
  }, [isSkipped, isPlanExpires, currentPeriodEnd, member.subscriptionAllowed]);

  useEffect(() => {
    if (showReminder) {
      open();
    }
  }, [showReminder, open]);
};
