import { css } from '@emotion/react';
import type { Theme } from '@partstech/ui';

export const styles = {
  image: css`
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  `,
  video: css`
    border: 0;
    width: 100%;
    height: 100%;
  `,
  isVideo: (theme: Theme) => css`
    position: absolute;
    top: ${theme.sizing(4.5)};
    left: ${theme.sizing(5.5)};
  `,
  scrollbar: (theme: Theme) => css`
    flex-shrink: 0;

    ${theme.breakpoints.up('md')} {
      margin-top: ${theme.sizing(7)};
    }

    ${theme.breakpoints.up('lg')} {
      margin-top: ${theme.sizing(-13)};
      margin-left: ${theme.sizing(2)};
      margin-right: ${theme.sizing(2)};
      max-height: ${theme.sizing(200)};
    }
  `,
  carouselButton: (isActive: boolean) => (theme: Theme) => css`
    cursor: pointer;
    border: ${isActive ? '2px solid' : '1px solid'};
    border-radius: ${theme.sizing(2)};
    background-color: ${isActive ? theme.color.lightBlue : theme.color.white};
    border-color: ${isActive ? theme.color.primary : theme.color.mono6};

    &:hover {
      cursor: ${isActive ? 'default' : 'pointer'};
      background-color: ${isActive ? theme.color.lightBlue : theme.color.mono6};
    }
  `,
  button360: (dragging: boolean) => css`
    cursor: ${dragging ? 'grabbing' : 'grab'};
    padding: 0;
    flex: auto;
    width: 100%;
    height: 100%;
  `,
};
