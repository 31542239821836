import { Box, Portal, useMedia } from '@partstech/ui';
import { useClickAway, usePreventScroll } from '@partstech/ui/hooks';
import { useRef } from 'react';
import { MembershipsMenu } from '../MembershipsMenu';
import { positionTopMenu } from '../MembershipsMenu/useMembershipsMenu';
import type { ShopMembership, SupplierMembership } from 'models';

type Props = {
  shopMemberships: ShopMembership[];
  supplierMemberships: SupplierMembership[];
  onClose: () => void;
};

export const Memberships = ({ shopMemberships, supplierMemberships, onClose }: Props) => {
  const { isMobile } = useMedia();

  const membershipsRef = useRef<HTMLDivElement | null>(null);

  useClickAway(membershipsRef, onClose);
  usePreventScroll({ selector: 'body', applyCondition: isMobile });

  return (
    <Portal>
      <Box
        ref={membershipsRef}
        position={{ md: 'fixed' }}
        left={16}
        zIndex="popover"
        top={positionTopMenu}
        data-testid="memberships"
      >
        <MembershipsMenu shopMemberships={shopMemberships} supplierMemberships={supplierMemberships} />
      </Box>
    </Portal>
  );
};
