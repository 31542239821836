import { Typography } from '@partstech/ui';

export const OldVehicleMessage = () => (
  <>
    <Typography variant="subtitle1">
      There are currently no service guides available for the vehicle you selected.
    </Typography>
    <Typography color="subtleText" variant="bodyText2">
      MOTOR supports data for vehicles manufactured in 1985 and later.
    </Typography>
  </>
);
