import { useState } from 'react';
import type { UIEvent } from 'react';

export const useCardsScroll = () => {
  const [isScrolling, setIsScrolling] = useState(false);

  const scrollCards = ({ target }: UIEvent<HTMLDivElement>) => {
    if (target instanceof HTMLDivElement) {
      setIsScrolling(target.scrollTop !== 0);
    }
  };

  return {
    isScrolling,
    scrollCards,
  };
};
