import { css } from '@emotion/react';
import { Box, Button } from '@partstech/ui';
import React, { useCallback } from 'react';
import { useContactModal } from 'components/Modal';

const styles = {
  button: css`
    border-radius: 20px;
    line-height: 15px;
    display: none;

    .icon {
      vertical-align: middle;
      margin-right: 5px;
    }
  `,
};

export const SupportBlock: React.FunctionComponent = () => {
  const { open, opened } = useContactModal({ mode: 'globalSupport' });

  const handleClick = useCallback(() => {
    open();
  }, [open]);

  return (
    <Box position="fixed" bottom={5} right={2.5} zIndex="popover">
      {!opened && (
        <Button onClick={handleClick} className="support-button" leadingIcon="help" css={styles.button}>
          Support
        </Button>
      )}
    </Box>
  );
};
