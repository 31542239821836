const messages = [
  'Failed to fetch dynamically imported module:',
  'error loading dynamically imported module',
  'Importing a module script failed',
];

export const checkModuleError = (error: Error): boolean => {
  const { message, name } = error;

  if (message) {
    const isModuleError = messages.some((errorMessage) => error.message.includes(errorMessage));

    if (isModuleError) {
      return true;
    }
  }

  return name === 'ChunkLoadError';
};
