import { createAsyncThunk } from '@reduxjs/toolkit';
import type { ResponseError } from 'shared/api';
import type { RootDispatch, RootState } from 'store/index';

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: RootDispatch;
  rejectValue: ResponseError;
}>();

export const selectRootState = (state: RootState) => state;
