import { useEffect, useState } from 'react';
import { VehicleSelectorTab } from '../../constant';

export const useActiveTab = (defaultTab: VehicleSelectorTab = VehicleSelectorTab.Ymm) => {
  const [activeTab, setActiveTab] = useState<VehicleSelectorTab>(defaultTab);

  useEffect(() => {
    if (defaultTab) {
      setActiveTab(defaultTab);
    }
  }, [defaultTab]);

  return { activeTab, setActiveTab };
};
