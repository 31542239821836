/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import type * as Types from '../graphql';

export type CardFragment = {
  __typename?: 'Card';
  brand: Types.Scalars['String']['input'];
  expMonth: number;
  expYear: number;
  name: Types.Scalars['String']['input'];
  owner?: Types.Scalars['String']['input'] | null;
  id: string;
};

export const CardFragmentDoc = `
    fragment Card on Card {
  brand
  expMonth
  expYear
  name
  owner
  id
}
    `;
