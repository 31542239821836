import { ExpectedAvailabilityType, OrderItemStatus } from 'shared/api';
import { getProductUrl } from 'utils/url';
import type { Quote, Vehicle } from 'models';
import type { AvailableBundle, CartOrderItemOrigin, OrderItemProductWarning, OrderItemSponsorType } from 'shared/api';
import type { ProductAttribute } from 'types/product';

type DeliveryNote = {
  label?: string | null;
  text?: string | null;
};

export type CartOrderItemData = {
  id: string;
  quantity: number;
  title: string;
  image: string;
  partNumber: string;
  isTire: boolean;
  tireSize?: string | null;
  quote?: Quote | null;
  price: number;
  retailPrice?: number;
  coreCharge?: number;
  fetCharge?: number;
  mapPrice?: number | null;
  listPrice?: number | null;
  discount?: number;
  error?: string | null;
  isPaid: boolean;
  vehicle?: Vehicle | null;
  bundled?: boolean;
  availableBundles?: AvailableBundle[];
  position?: string | null;
  supplierId?: string;
  supplierAccountId?: string;
  sponsorType?: OrderItemSponsorType;
  partTypeId?: string;
  partTypeName?: string;
  categoryName?: string;
  origin?: CartOrderItemOrigin;
  lineCardId?: number | null;
  partNumberId?: string;
  brandLogo?: string | null;
  brandName?: string;
  rewardPoint?: number | null;
  warnings?: OrderItemProductWarning[];
  attributes?: ProductAttribute[];
  notes?: string[];
  deliveryNotes?: DeliveryNote[];
  availabilityType?: ExpectedAvailabilityType | null;
  status?: OrderItemStatus | null;
};

export class CartOrderItem {
  id: string;

  quantity: number;

  title: string;

  image: string;

  partNumber: string;

  isTire: boolean;

  tireSize: string | null;

  quote: Quote | null;

  price: number;

  retailPrice: number;

  coreCharge: number;

  fetCharge: number;

  mapPrice: number;

  listPrice: number;

  discount: number;

  error: string | null;

  vehicle: Vehicle | null;

  isPaid: boolean;

  bundled: boolean;

  availableBundles: AvailableBundle[];

  position: string | null;

  supplierId: string | null;

  supplierAccountId: string | null;

  partTypeId: string | null;

  partTypeName: string | null;

  categoryName: string | null;

  origin: CartOrderItemOrigin | null;

  lineCardId: number | null;

  partNumberId: string | null;

  brandLogo: string | null;

  brandName: string | null;

  rewardPoint: number;

  warnings: OrderItemProductWarning[];

  attributes: ProductAttribute[];

  notes: string[];

  deliveryNotes: DeliveryNote[];

  availabilityType: ExpectedAvailabilityType | null;

  status: OrderItemStatus | null;

  sponsorType: OrderItemSponsorType | null;

  constructor(data: CartOrderItemData) {
    this.id = data.id;
    this.quantity = data.quantity;
    this.title = data.title;
    this.image = data.image || '';
    this.partNumber = data.partNumber;
    this.isTire = data.isTire;
    this.tireSize = data.tireSize || null;
    this.quote = data.quote || null;
    this.price = data.price;
    this.retailPrice = data.retailPrice || 0;
    this.coreCharge = data.coreCharge || 0;
    this.fetCharge = data.fetCharge || 0;
    this.mapPrice = data.mapPrice || 0;
    this.listPrice = data.listPrice || 0;
    this.error = data.error || null;
    this.vehicle = data.vehicle || null;
    this.isPaid = data.isPaid;
    this.bundled = data.bundled || false;
    this.availableBundles = data.availableBundles || [];
    this.position = data.position || null;
    this.discount = data.discount || 0;
    this.supplierId = data.supplierId || null;
    this.supplierAccountId = data.supplierAccountId || null;
    this.sponsorType = data.sponsorType || null;
    this.partTypeId = data.partTypeId || null;
    this.partTypeName = data.partTypeName || null;
    this.categoryName = data.categoryName || null;
    this.origin = data.origin || null;
    this.lineCardId = data.lineCardId || null;
    this.partNumberId = data.partNumberId || null;
    this.brandLogo = data.brandLogo || null;
    this.brandName = data.brandName || null;
    this.rewardPoint = data.rewardPoint || 0;
    this.warnings = data.warnings || [];
    this.attributes = data.attributes || [];
    this.notes = data.notes || [];
    this.deliveryNotes = data.deliveryNotes || [];
    this.availabilityType = data.availabilityType || null;
    this.status = data.status || null;
  }

  priceWithDiscount(showRetailPrice: boolean) {
    const price = showRetailPrice ? this.retailPrice : this.price;
    return price - this.discount / this.quantity;
  }

  isInNetwork() {
    return this.isPaid ? this.availabilityType !== ExpectedAvailabilityType.Main : this.quote?.isInNetwork() || false;
  }

  get isQuantityExceedingSelectedStore() {
    if (this.isPaid) {
      return (
        this.availabilityType === ExpectedAvailabilityType.ManyOnlyNetwork ||
        this.availabilityType === ExpectedAvailabilityType.ManyWithMain
      );
    }

    const isSingleLineAvailability = this.quote?.hasOneLine();
    const store = this.quote?.lines[0];

    if (!store || isSingleLineAvailability) {
      return false;
    }

    return store.qty.value < this.quantity;
  }

  get productUrl() {
    return getProductUrl({
      supplierId: Number(this.supplierId),
      credentialId: Number(this.supplierAccountId),
      vehicleId: this.vehicle?.id ? Number(this.vehicle.id) : undefined,
      partTypeId: this.partTypeId ? Number(this.partTypeId) : undefined,
      lineCardId: this.lineCardId,
      partNumberId: this.partNumberId || '',
    });
  }

  get isOnTrack() {
    return (
      this.status === OrderItemStatus.New ||
      this.status === OrderItemStatus.NotShipped ||
      this.status === OrderItemStatus.Shipped
    );
  }
}
