import { useCallback, useMemo } from 'react';
import { CreateOrUpdateOnboardStepType, OnboardStepType } from 'shared/api';
import { useOnboardSteps } from 'store/queries/currentUser/useOnboardSteps';
import { useCreateOnboardStep } from './useCreateOnboardStep';

const stepMappings = {
  [OnboardStepType.ShopType]: CreateOrUpdateOnboardStepType.ShopType,
  [OnboardStepType.Sms]: CreateOrUpdateOnboardStepType.Sms,
  [OnboardStepType.Parts]: CreateOrUpdateOnboardStepType.Parts,
  [OnboardStepType.Tires]: CreateOrUpdateOnboardStepType.Tires,
  [OnboardStepType.PrimaryRole]: CreateOrUpdateOnboardStepType.PrimaryRole,
  [OnboardStepType.Referral]: CreateOrUpdateOnboardStepType.Referral,
};

type Args = {
  step: keyof typeof stepMappings;
  onSuccess?: () => void;
};

export const useStepProgress = ({ step, onSuccess }: Args) => {
  const { isSuccess, isStepCompleted, getValueByStep } = useOnboardSteps();

  const [createOnboardStep] = useCreateOnboardStep({ onSuccess });

  const defaultOption = useMemo(() => getValueByStep(step) ?? undefined, [getValueByStep, step]);

  const handleSelect = useCallback(
    (option: string | null) => {
      if (defaultOption !== option) {
        createOnboardStep({ step: stepMappings[step]!, value: option });
        return;
      }

      onSuccess?.();
    },
    [defaultOption, createOnboardStep, step, onSuccess]
  );

  const handleSkip = useCallback(() => handleSelect(null), [handleSelect]);

  return {
    defaultOption,
    isLoaded: isSuccess,
    isStepCompleted: isStepCompleted(step),
    selectOption: handleSelect,
    skipStep: handleSkip,
  };
};
