import { BottomSheet, Box, Button, Checkbox, MenuItem } from '@partstech/ui';
import { useCallback, useState } from 'react';
import type { ModalComponentProps, Option } from '@partstech/ui';

type Props = {
  defaultValue: string[];
  options: Option<string>[];
  onChange: (value: string[]) => void;
};

export const MobileFilterList = ({ defaultValue, options, onChange, onClose }: ModalComponentProps<Props>) => {
  const [value, setValue] = useState<string[]>(defaultValue);

  const handleItemClick = useCallback(
    (option: Option<string>) => () => {
      setValue((currentValue) => {
        const isChecked = currentValue.some((item) => item === option.value);

        if (isChecked) {
          return currentValue.filter((item) => item !== option.value);
        }

        return [...currentValue, option.value];
      });
    },
    []
  );

  const handleApply = useCallback(() => {
    onChange(value);
    onClose();
  }, [onChange, onClose, value]);

  return (
    <BottomSheet onClose={onClose} isClosable isMaxHeight title="Filters" px={0} pt={0}>
      {options.map((option) => (
        <MenuItem
          key={option.value}
          onClick={handleItemClick(option)}
          prefix={<Checkbox checked={value.includes(option.value)} forceControlled />}
        >
          {option.text}
        </MenuItem>
      ))}

      <Box p={4}>
        <Button fullWidth onClick={handleApply}>
          Apply filters
        </Button>
      </Box>
    </BottomSheet>
  );
};
