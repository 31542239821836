import { css } from '@emotion/react';
import { Box, ImageFallback } from '@partstech/ui';
import type { BoxProps } from '@partstech/ui';

const styles = {
  container: css`
    transition: all 0.5s ease-in-out;
  `,
  image: css`
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  `,
};

type Props = BoxProps & {
  partImage?: string;
  fallbackImage?: string;
  brandWidth?: number;
  brandHeight?: number;
};

export const PartImage = ({
  partImage = '',
  fallbackImage = '',
  brandWidth = 12,
  brandHeight = 12,
  ...props
}: Props) => (
  <Box
    position="relative"
    display="flex"
    justifyContent="center"
    alignItems="center"
    flexShrink="0"
    css={styles.container}
    {...props}
  >
    {!partImage && (
      <Box position="absolute" left={0} top={0} width={brandWidth} height={brandHeight}>
        <ImageFallback src={fallbackImage} alt="" css={styles.image} />
      </Box>
    )}

    <ImageFallback src={partImage} alt="" css={styles.image} data-testid="partImage" />
  </Box>
);
