import 'react-app-polyfill/stable';
import { createRoot } from 'react-dom/client';
import { App } from 'app/App';
import { FullStory, init as initFullstory, getFullStorySessionId } from 'integrations/fullstory';
import { sentryFullstory } from 'integrations/fullstory/sentry';
import { init as initSaaSquatch } from 'integrations/saaSquatch';
import { init as initSentry } from 'integrations/sentry';
import { init as initUserpilot } from 'integrations/userpilot';
import { client } from 'shared/api';

initFullstory();

client.interceptors.request.use((request) => {
  // eslint-disable-next-line no-param-reassign
  request.headers['X-FSID'] = getFullStorySessionId();

  return request;
});

initSentry({ integrations: [sentryFullstory('partstech', { client: FullStory })] });

initSaaSquatch();

initUserpilot();

const root = createRoot(document.getElementById('app')!);
root.render(<App />);
