/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type GetVehicleMakeGroupsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetVehicleMakeGroupsQuery = {
  __typename?: 'Query';
  vehicleMakeGroups?: Array<{
    __typename?: 'VehicleMakeGroup';
    id: string;
    name: Types.Scalars['String']['input'];
    priority: number;
    types: Array<Types.VehicleType>;
    years: Array<number>;
  }> | null;
};

export const GetVehicleMakeGroupsDocument = `
    query GetVehicleMakeGroups {
  vehicleMakeGroups {
    id
    name
    priority
    types
    years
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetVehicleMakeGroups: build.query<GetVehicleMakeGroupsQuery, GetVehicleMakeGroupsQueryVariables | void>({
      query: (variables) => ({ document: GetVehicleMakeGroupsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetVehicleMakeGroupsQuery, useLazyGetVehicleMakeGroupsQuery } = injectedRtkApi;
