import { UniversalPartType } from './UniversalPartType';
import type { FullPartTypeFragment } from 'store/queries/partTypes/FullPartType.generated';

export class UniversalPartTypeAlias extends UniversalPartType {
  constructor(
    partTypeData: FullPartTypeFragment,
    private alias: string
  ) {
    super(partTypeData);

    this.name = alias;
  }

  toString(): string {
    return this.alias;
  }

  populateInput(): string {
    return this.alias;
  }
}
