import { Link, Typography } from '@partstech/ui';
import type { FeatureLink } from '../../../../../types';
import type { TypographyProps } from '@partstech/ui';

type Props = {
  link?: FeatureLink;
  name: string;
  variant?: TypographyProps['variant'];
  onLinkClick?: () => void;
};

export const FeatureName = ({ link, name, variant = 'bodyText1', onLinkClick }: Props) => {
  if (!link) {
    return <Typography variant={variant}>{name}</Typography>;
  }

  if (!link.name) {
    return (
      <Link to={link.url} isExternal={!link.isInternal} variant={variant} onClick={onLinkClick}>
        {name}
      </Link>
    );
  }

  return (
    <div>
      <Typography component="span" variant={variant}>
        {name}
      </Typography>{' '}
      <Link to={link.url} isExternal={!link.isInternal} variant={variant} onClick={onLinkClick}>
        {link.name}
      </Link>
    </div>
  );
};
