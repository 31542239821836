import { useMounted } from '@partstech/ui/hooks';
import { isTypeOf } from '@partstech/ui/utils';
import { useEffect } from 'react';
import { isRef } from './isRef';
import type { MutableRefObject } from 'react';

interface UseScrollIntoViewOptions extends ScrollIntoViewOptions {
  /**
   * Defines vertical alignment.
   * One of `'start'`, `'center'`, `'end'`, or `'nearest'`
   *
   * @default 'start'
   */
  block?: ScrollLogicalPosition;

  /**
   * Defines horizontal alignment.
   * One of `start`, `center`, `end`, or `nearest`. Defaults to nearest.
   *
   * @default 'nearest'
   */
  inline?: ScrollLogicalPosition;

  /**
   * Defines the transition animation.
   * One of 'auto' or 'smooth'.
   *
   * @default 'auto'
   */
  behavior?: ScrollBehavior;

  /**
   * The margin around the element to make sure it's visible.
   *
   * @default '0px'
   */
  scrollMargin?: string;

  /**
   * The condition to decide if the element should be scrolled into view.
   *
   * @default true
   */
  predicate?: boolean | (() => boolean);
}

type Options = {
  /**
   * Skip scrolling to element.
   */
  skip?: boolean;
};

/**
 *
 * A hook to scroll an element into view on mounting.
 *
 * @param options {UseScrollIntoViewOptions}
 * @param options {Options}
 */
export const useScrollIntoView = <T extends HTMLElement | null>(
  target: T | MutableRefObject<T>,
  options: UseScrollIntoViewOptions = {},
  { skip }: Options = { skip: false }
) => {
  const isMounted = useMounted();

  const { behavior = 'auto', block = 'start', inline = 'nearest', scrollMargin = '0px', predicate = true } = options;

  useEffect(() => {
    if (!isMounted || skip) {
      return;
    }

    const element = isRef(target) ? (target as MutableRefObject<T>).current : (target as T);

    if (!(element && (isTypeOf(predicate, 'function') ? predicate() : predicate))) {
      return;
    }

    element.style.scrollMargin = scrollMargin;

    element.scrollIntoView({
      behavior,
      block,
      inline,
    });
  }, [behavior, block, inline, isMounted, predicate, scrollMargin, skip, target]);
};
