import { css } from '@emotion/react';
import { Box, Button, Icon, Result } from '@partstech/ui';
import type { BoxProps, Theme } from '@partstech/ui';
import type { PropsWithChildren, ReactNode } from 'react';

const style = {
  infoIcon: (theme: Theme) => css`
    width: ${theme.sizing(10)};
    height: ${theme.sizing(10)};
    font-size: ${theme.sizing(10)};
  `,
};

type Props = BoxProps &
  PropsWithChildren<{
    hasOptions: boolean;
    hasVehicle: boolean;
    noOptionsText: string;
    noVehicleText: ReactNode;
    noOptionsIcon?: ReactNode;
    adornmentBottomText?: string;
    onSelectVehicleClick?: () => void;
  }>;

/**
 * Wrapper to show `Select Vehicle` button instead of content if hasVehicle === false
 */
export const VehicleRequiredView = ({
  children,
  hasOptions,
  hasVehicle,
  noOptionsText,
  noVehicleText,
  noOptionsIcon,
  adornmentBottomText = 'Select Vehicle',
  onSelectVehicleClick,
  ...props
}: Props) => {
  if (!hasVehicle) {
    return (
      <Result
        width="100%"
        m="auto"
        message={noVehicleText}
        customIcon={<Icon name="directions_car" color="subtleText" css={style.infoIcon} />}
        extraButtons={[
          <Button key={1} onClick={onSelectVehicleClick} variant="secondary">
            {adornmentBottomText}
          </Button>,
        ]}
      />
    );
  }

  if (!hasOptions) {
    return <Result width="100%" m="auto" message={noOptionsText} customIcon={noOptionsIcon} />;
  }

  return <Box {...props}>{children}</Box>;
};
