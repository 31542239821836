import { BaseStatus } from '../BaseStatus';
import type { AvailabilityStatusProps } from '../../AvailabilityDropdown';

type Props = AvailabilityStatusProps;

export const SpecialOrderStatus = ({ onClick, quantity, trailingIcon, variant }: Props) => (
  <BaseStatus
    color="special"
    leadingIcon={variant === 'default' ? 'storefront' : undefined}
    onClick={onClick}
    text={`${'Special Order'}${quantity ? ` (${quantity})` : ''}`}
    trailingIcon={trailingIcon}
  />
);
