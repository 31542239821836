import { isNumber } from '@partstech/ui/utils';
import { gUploadApi, api as rootApi, ResponseError } from 'shared/api';
import type { ErrorLine } from 'shared/api';

const api = gUploadApi.injectEndpoints({
  endpoints: (build) => ({
    UploadUserAvatar: build.mutation<{ success: boolean }, FormData>({
      query: (formData) => ({ url: `/user-avatar`, method: 'POST', body: formData }),
      transformResponse: () => ({ success: true }),
      transformErrorResponse(baseQueryReturnValue) {
        const status = isNumber(baseQueryReturnValue.status) ? baseQueryReturnValue.status : 500;

        const errors = (baseQueryReturnValue.data as { errors?: ErrorLine[] })?.errors ?? [
          { code: 1, message: 'An unexpected error occurred. Please try again.' },
        ];

        return new ResponseError(status, errors);
      },
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;

          dispatch(rootApi.util.invalidateTags([{ type: 'CurrentUser' }]));
        } catch {
          /* empty */
        }
      },
    }),
  }),
});

export const { useUploadUserAvatarMutation } = api;
