import { useMemo } from 'react';
import { useMatch } from 'react-router-dom';
import { useAppLocation } from 'app/AppRouter/useAppLocation';
import { Routes } from 'constant';
import { parseQuery } from 'shared/lib/query';
import type { SignUpQueryParams } from 'utils/url';

export const useSignupQueryParams = () => {
  const { search } = useAppLocation();

  const isSignupPage = useMatch(Routes.SIGN_UP);
  const isGoogleSignup = useMatch(Routes.SIGN_UP_GOOGLE);
  const isInvitationSignup = useMatch(Routes.SIGN_UP_INVITATION);

  const searchParams = useMemo(
    () => parseQuery<SignUpQueryParams>(search, { ignoreQueryPrefix: true, decoder: decodeURIComponent }),
    [search]
  );

  return useMemo<SignUpQueryParams>(() => {
    if (isInvitationSignup) {
      return { token: searchParams?.token };
    }

    if (isGoogleSignup) {
      const state = JSON.parse(searchParams?.state ?? '');

      return {
        code: searchParams?.code,
        ...state,
      };
    }

    if (isSignupPage) {
      return { selectedSms: searchParams?.selectedSms };
    }

    return {};
  }, [
    isGoogleSignup,
    isInvitationSignup,
    isSignupPage,
    searchParams?.code,
    searchParams?.selectedSms,
    searchParams?.state,
    searchParams?.token,
  ]);
};
