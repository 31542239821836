import { entries } from '@partstech/ui/utils';

export const replaceObjectValue = <T extends {}>(inputObject: T, searchValue: unknown, replaceValue: unknown): T =>
  entries(inputObject).reduce<T>((result, [key, value]) => {
    if (value instanceof Object && !Array.isArray(value)) {
      return {
        ...result,
        [key]: replaceObjectValue(value, searchValue, replaceValue),
      };
    }

    return {
      ...result,
      [key]: value === searchValue ? replaceValue : value,
    };
  }, {} as T);
