import { css, keyframes } from '@emotion/react';
import { Box, Icon, Loader, Portal, Typography } from '@partstech/ui';
import { elevationShadow } from '@partstech/ui/utils';
import { useSavedQuotesIcon } from './useSavedQuotesIcon';
import type { SerializedStyles } from '@emotion/react';
import type { Theme } from '@partstech/ui';

const dotsAnimation = keyframes`
  0% {
    content: ''
  }
  25% {
    content: '.'
  }
  50% {
    content: '..'
  }
  75% {
    content: '...'
  }`;

const animation = keyframes`
  0% {
    width: 40px;
    opacity: 0;
  }
  10% {
    width: 144px;
    opacity: 1;
  }
  90% {
    width: 144px;
    opacity: 1;
  }
  100% {
    width: 40px;
    opacity: 0;
  }`;

const styles = {
  root: (position: SerializedStyles) => (theme: Theme) => css`
    position: absolute;
    margin-left: ${theme.sizing(-1)};
    white-space: nowrap;
    overflow: hidden;
    padding: ${theme.sizing(1)};
    display: flex;
    align-items: center;
    gap: ${theme.sizing(2)};
    border-radius: ${theme.sizing(1)};
    background: ${theme.color.white};
    ${elevationShadow(2)}
    cursor: pointer;
    ${position}
    animation: ${animation} 2s ease-in-out;
    &:after {
      content: '';
      position: relative;
      right: 8px;
      bottom: 1px;
      animation: ${dotsAnimation} 2s ease-in-out;
    }
  `,
};

export const SavedQuotesIcon = () => {
  const { wrapRef, contentRef, position, showAnimation, isLoading } = useSavedQuotesIcon();

  if (showAnimation) {
    return (
      <div ref={wrapRef}>
        <Portal group="cartSavingAnimation">
          <div ref={contentRef} css={styles.root(position)}>
            <Icon name="request_quote" color="subtleText" />
            <Typography variant="overline">CART IS SAVING</Typography>
          </div>
        </Portal>
        <Icon name="request_quote" color="subtleText" />
      </div>
    );
  }

  if (isLoading) {
    return (
      <Box pt={1}>
        <Loader />
      </Box>
    );
  }

  return <Icon name="request_quote" color="subtleText" />;
};
