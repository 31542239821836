import { isCanadian } from '../utils/vehicle/isCanadian';
import type { Diagram } from './Diagram';
import type { Job } from './Job';
import type { TireConfiguration } from './TireConfiguration';
import type { VehicleRegionsAware } from './types';
import type { VehicleRegion, VehicleType } from 'shared/api';

type VehicleData = {
  id: string;
  year: number;
  make: {
    id: string;
    name: string;
    priority: number;
  };
  model: {
    id: string;
    name: string;
  };
  subModel: {
    id: string;
    name: string;
  };
  engine: {
    id: string;
    name: string;
    aspiration?: string | null;
    designation?: string | null;
    fuel?: string | null;
  };
  type: VehicleType;
  regions?: VehicleRegion[] | null;
  body?: {
    doors?: number | null;
    drive?: string | null;
    name?: string | null;
  } | null;
  tires?:
    | {
        id: string;
        name: string;
        size: {
          width: number;
          height: number;
          rim: number;
          speedRating?: string | null;
          loadIndex?: number | null;
        };
        rearSize?: {
          width: number;
          height: number;
          rim: number;
          speedRating?: string | null;
          loadIndex?: number | null;
        } | null;
      }[]
    | null;
  vin?: string | null;
  plate?: string | null;
  state?: string | null;
};

const emptyRegions: VehicleRegion[] = [];

export class Vehicle {
  id: string;

  year: number;

  protected $make: {
    id: string;
    name: string;
    priority: number;
  };

  model: {
    id: string;
    name: string;
  };

  protected $subModel: {
    id: string;
    name: string;
  };

  protected $engine: {
    id: string;
    name: string;
    aspiration?: string | null;
    designation?: string | null;
    fuel?: string | null;
  };

  protected $type: VehicleType;

  protected $regions?: VehicleRegion[] | null;

  body?: {
    doors?: number | null;
    drive?: string | null;
    name?: string | null;
  } | null;

  tires?: TireConfiguration[] | null;

  protected $vin?: string | null;

  plate?: string | null;

  state?: string | null;

  diagrams?: Diagram[];

  jobs?: Job[];

  constructor(vehicle: VehicleData) {
    this.id = vehicle.id;
    this.year = vehicle.year;
    this.$make = vehicle.make;
    this.model = vehicle.model;
    this.$subModel = vehicle.subModel;
    this.$engine = vehicle.engine;
    this.$type = vehicle.type;
    this.$regions = vehicle.regions;
    this.body = vehicle.body;
    this.$vin = vehicle.vin;
    this.plate = vehicle.plate ?? null;
    this.state = vehicle.state ?? null;
  }

  get make(): VehicleData['make'] & { type: VehicleType } {
    return { ...this.$make, type: this.$type };
  }

  get subModel(): VehicleData['subModel'] & VehicleRegionsAware {
    return { ...this.$subModel, regions: this.regions };
  }

  get engine(): VehicleData['engine'] & VehicleRegionsAware {
    return { ...this.$engine, regions: this.regions };
  }

  get regions(): NonNullable<VehicleData['regions']> {
    return this.$regions ?? emptyRegions;
  }

  get vin(): string | undefined {
    return this.$vin?.toUpperCase();
  }

  get isCanadian(): boolean {
    return isCanadian(this.regions);
  }

  toString(): string {
    return `${this.shortName} ${this.subModel.name} ${this.engine.name}`.trim();
  }

  get shortName(): string {
    return `${this.year} ${this.make.name} ${this.model.name}`;
  }

  findTire(tire: TireConfiguration) {
    return this.tires?.find((vehicleTire) => vehicleTire.size.isEqual(tire.size));
  }

  setPlate(plate: string) {
    this.plate = plate;
    return this;
  }

  setState(state: string) {
    this.state = state;
    return this;
  }

  setTires(tires: TireConfiguration[] | null) {
    this.tires = tires;
  }

  setDiagrams(diagrams: Diagram[]) {
    this.diagrams = diagrams;
  }

  setJobs(jobs: Job[]) {
    this.jobs = jobs;
  }
}
