import { isNumber } from '@partstech/ui/utils';

export const convertNumericPropertiesToString = <T extends {}>(obj: T): T | T[] => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => convertNumericPropertiesToString(item)) as T[];
  }

  const keys = Object.keys(obj);

  const newObj: Record<string, {}> = { ...obj };

  keys.forEach((key) => {
    const value = newObj[key];

    if (isNumber(value)) {
      newObj[key] = value.toString();
    } else if (typeof value === 'object') {
      newObj[key] = convertNumericPropertiesToString(value);
    }
  });

  return newObj as T;
};
