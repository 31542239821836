import { getTireSpeedRating } from 'utils';
import { Options } from '../../DefaultFilter/Options';
import type { OptionsProps } from '../../DefaultFilter/Options/types';

export const SpeedRatingOptions = ({ options, ...props }: OptionsProps) => {
  const speedRatingOptions = options.map((option) => {
    const speedMph = getTireSpeedRating(option.value);
    const optionName = speedMph ? `${option.value} - ${speedMph}` : option.value;

    return {
      ...option,
      name: optionName,
    };
  });

  return <Options options={speedRatingOptions} {...props} />;
};
