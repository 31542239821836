import { DialogModal, Typography } from '@partstech/ui';
import { useCreateModal } from '@partstech/ui/hooks';

export const usePermissionDeniedDialog = () => {
  const { open } = useCreateModal(
    DialogModal,
    {
      children: <Typography>You don’t have permission to take that action. Contact your admin for access.</Typography>,
      primaryText: 'Okay',
      size: 'dialog',
    },
    { customId: 'permissionDeniedDialog' }
  );

  return {
    openPermissionDeniedDialog: open,
  };
};
