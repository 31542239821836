import { css } from '@emotion/react';
import { Card, Icon, IconButton, Typography } from '@partstech/ui';

const styles = {
  icon: css`
    font-size: 40px;
    width: 40px;
    height: 40px;
  `,
};

type Props = {
  shopName: string;
  onClose: () => void;
};

export const ShopChangedSnackbar = ({ shopName, onClose }: Props) => (
  <Card display="flex" background="black" gap={4} p={4} maxWidth={100} alignItems="start">
    <Icon name="request_quote" size="default" color="white" css={styles.icon} />

    <Typography variant="subtitle1" color="white">
      You are now viewing a quote for {shopName}.
    </Typography>

    <IconButton size="dense" onClick={onClose}>
      <Icon size="medium" name="close" color="white" />
    </IconButton>
  </Card>
);
