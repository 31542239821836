import { useMemo } from 'react';
import { createPaymentCardFromData } from 'entities/paymentCard';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import { api as generatedApi } from './GetPaymentCards.generated';
import type { GetPaymentCardsQuery } from './GetPaymentCards.generated';
import type { PaymentCard } from 'entities/paymentCard';

const api = generatedApi.enhanceEndpoints({
  addTagTypes: ['PaymentCard'],
  endpoints: {
    GetPaymentCards: {
      providesTags: (response: GetPaymentCardsQuery | undefined) => {
        const shop = response?.currentUser?.activeMember?.shop;

        const cards =
          shop?.cards?.map((card) => ({
            type: 'PaymentCard' as const,
            id: card.id,
          })) ?? [];

        return ['PaymentCard', ...cards];
      },
    },
  },
});

const { useGetPaymentCardsQuery } = api;

const emptyCards: PaymentCard[] = [];

export const useGetPaymentCards = () => {
  // TODO: DEV-3255 Remove this dependency on useGetCurrentUser
  const { activeMember } = useGetCurrentUser();

  const { data, ...props } = useGetPaymentCardsQuery(undefined, { skip: !activeMember });

  const subscriptionCardEntity = data?.currentUser?.activeMember?.shop?.shopSubscription?.card;

  const subscriptionCard = useMemo(
    () =>
      subscriptionCardEntity ? createPaymentCardFromData(subscriptionCardEntity, subscriptionCardEntity?.id) : null,
    [subscriptionCardEntity]
  );

  const cards = useMemo(() => {
    const userCards =
      data?.currentUser?.activeMember?.shop?.cards?.map((card) =>
        createPaymentCardFromData(card, subscriptionCard?.id)
      ) ?? emptyCards;

    return userCards.sort((a, b) => {
      if (a.expYear !== b.expYear) {
        return b.expYear - a.expYear;
      }
      if (a.expMonth !== b.expMonth) {
        return b.expMonth - a.expMonth;
      }
      if (a.id !== b.id) {
        return Number(b.id) - Number(a.id);
      }
      return 0;
    });
  }, [data?.currentUser?.activeMember?.shop?.cards, subscriptionCard?.id]);

  return {
    cards,
    subscriptionCard,
    ...props,
  };
};

export const paymentApi = api;
