import { css } from '@emotion/react';
import { Box, Button, Icon, Scrollbar, Tag, Breadcrumbs } from '@partstech/ui';
import { useCallback } from 'react';
import { OptionsByColumns } from 'components/OptionsByColumns';
import { PartType, PartTypeList } from 'models';
import { useCategorySelection } from './useCategorySelection';
import type { Theme } from '@partstech/ui';
import type { SearchEntryPointInterface } from 'features/searchForm';
import type { PartCategory } from 'models';

type Props = {
  categories: PartCategory[];
  onChange?: (value: PartTypeList) => void;
  onSelect?: (value: SearchEntryPointInterface) => void;
  isKeyboardDisabled?: boolean;
};

const styles = {
  options: css`
    flex: 1;
  `,
  tags: (theme: Theme) => css`
    max-height: ${theme.sizing(27)};
  `,
};

export const Categories = ({ categories, onChange, onSelect, isKeyboardDisabled }: Props) => {
  const {
    breadcrumbs,
    options,
    selectedCategory,
    selectedPartTypes,
    selectOption,
    removePartType,
    isSelectedPartType,
  } = useCategorySelection(categories);

  const handlePartTypeRemoveClick = useCallback(
    (partType: PartType) => () => {
      removePartType(partType);
    },
    [removePartType]
  );

  const handleSearchClick = useCallback(() => {
    onChange && onChange(new PartTypeList(selectedPartTypes));
  }, [onChange, selectedPartTypes]);

  return (
    <Box data-testid="categoriesBlock" width="100%" display="flex" flexDirection="column">
      {selectedCategory && <Breadcrumbs breadcrumbs={breadcrumbs} pb={4} />}

      <OptionsByColumns
        data-testid="content"
        className="ps-categories"
        options={options}
        onChange={selectOption}
        onSelect={onSelect}
        maxColumns={2}
        isKeyboardDisabled={isKeyboardDisabled}
        adornmentBefore={(option) =>
          option instanceof PartType ? (
            <Icon name={isSelectedPartType(option) ? 'check_box' : 'check_box_outline_blank'} />
          ) : null
        }
        css={styles.options}
      />

      <Box>
        <Scrollbar css={styles.tags}>
          {selectedPartTypes.length > 0 && (
            <Box mt={5} data-testid="selectedPartTypes" gap={2} display="flex" flexWrap="wrap">
              {selectedPartTypes.map((partType) => (
                <Tag key={partType.id} data-testid="tag" onClose={handlePartTypeRemoveClick(partType)}>
                  {partType.name}
                </Tag>
              ))}
            </Box>
          )}
        </Scrollbar>

        {selectedPartTypes.length > 0 && (
          <Box mt={4}>
            <Button fullWidth onClick={handleSearchClick}>
              Search
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};
