import { isNotNull } from '@partstech/ui/utils';
import { useMemo } from 'react';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import { api as generatedApi } from './GetRecentOrders.generated';
import type { GetRecentOrdersQuery } from './GetRecentOrders.generated';
import type { ShopRecentOrdersArgs } from 'shared/api';

type CurrentUser = NonNullable<GetRecentOrdersQuery['currentUser']>;
type ActiveMember = NonNullable<CurrentUser['activeMember']>;
type Shop = NonNullable<ActiveMember['shop']>;
type RecentOrders = NonNullable<Shop['recentOrders']>;
type RecentOrderEdges = NonNullable<RecentOrders['edges']>;
type RecentOrderEdge = NonNullable<RecentOrderEdges[0]>;
export type RecentOrder = NonNullable<RecentOrderEdge['node']>;

const emptyRecentOrderEdges: RecentOrder[] = [];

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    GetRecentOrders: {
      forceRefetch: ({ currentArg, previousArg }) => currentArg !== previousArg,
      serializeQueryArgs: () => ({}),
      merge: (currentCache, newResponce) => {
        const newRecentOrders = newResponce.currentUser?.activeMember?.shop?.recentOrders;
        const prevRecentOrders = currentCache.currentUser?.activeMember?.shop?.recentOrders;
        const isFirstPage = !newRecentOrders?.pageInfo.hasPreviousPage;

        if (!isFirstPage && newRecentOrders?.edges && prevRecentOrders?.edges) {
          return {
            currentUser: {
              activeMember: {
                shop: {
                  recentOrders: {
                    ...newRecentOrders,
                    edges: [...prevRecentOrders.edges, ...newRecentOrders.edges],
                  },
                },
              },
            },
          };
        }

        return newResponce;
      },
    },
  },
});

const { useGetRecentOrdersQuery, useLazyGetRecentOrdersQuery } = api;

const useGetRecentOrders = (args: ShopRecentOrdersArgs) => {
  const { hasCurrentUser } = useGetCurrentUser();

  const { data, ...props } = useGetRecentOrdersQuery(args, {
    skip: !hasCurrentUser,
  });

  const recentOrderEdges = useMemo(
    () => data?.currentUser?.activeMember?.shop?.recentOrders?.edges,
    [data?.currentUser?.activeMember?.shop?.recentOrders?.edges]
  );

  const recentOrders: RecentOrder[] = useMemo(
    () => recentOrderEdges?.map((edge) => edge?.node).filter(isNotNull) || emptyRecentOrderEdges,
    [recentOrderEdges]
  );

  const pageInfo = data?.currentUser?.activeMember?.shop?.recentOrders?.pageInfo;

  return {
    recentOrders,
    hasNextPage: pageInfo?.hasNextPage || false,
    endCursor: pageInfo?.endCursor || null,
    ...props,
  };
};

export { useGetRecentOrders, useLazyGetRecentOrdersQuery };
