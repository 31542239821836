import axios from 'axios';
import qs from 'qs';
import { CancelError } from './errors/CancelError';
import { ErrorCode, ResponseError } from './errors/ResponseError';
import type { AxiosError, AxiosRequestConfig } from 'axios';

declare module 'axios' {
  export interface AxiosInstance {
    request<T>(config: AxiosRequestConfig): Promise<T>;
    get<T>(url: string, config?: AxiosRequestConfig): Promise<T>;
    delete<T>(url: string, config?: AxiosRequestConfig): Promise<T>;
    head<T>(url: string, config?: AxiosRequestConfig): Promise<T>;
    post<T, B>(url: string, data?: B, config?: AxiosRequestConfig): Promise<T>;
    put<T, B>(url: string, data?: B, config?: AxiosRequestConfig): Promise<T>;
    patch<T, B>(url: string, data?: B, config?: AxiosRequestConfig): Promise<T>;
  }
}

export type Config = Pick<AxiosRequestConfig, 'baseURL' | 'cancelToken' | 'timeout' | 'headers' | 'withCredentials'>;

export const client = axios.create({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'X-Mode': '1',
  },
  paramsSerializer: { serialize: (params) => qs.stringify(params, { arrayFormat: 'comma', encodeValuesOnly: true }) },
  withCredentials: true,
});

client.interceptors.response.use(
  (response) => response.data,
  (error: AxiosError<ResponseError>) => {
    if (error.response?.status && error.response.data?.errors) {
      throw new ResponseError(error.response.status, error.response.data.errors);
    }

    if (error.response?.status && typeof error.response.data === 'object') {
      throw error.response.data;
    }

    if (axios.isCancel(error)) {
      throw new CancelError();
    }

    throw new ResponseError(500, [
      { code: ErrorCode.GENERAL_ERROR, message: 'An unexpected error occurred. Please try again.' },
    ]);
  }
);
