/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type UpdateCartInfoMutationVariables = Types.Exact<{
  input: Types.UpdateCartInput;
}>;

export type UpdateCartInfoMutation = {
  __typename?: 'Mutation';
  updateCart?: {
    __typename?: 'UpdateCartPayload';
    cart?: {
      __typename?: 'Cart';
      id: string;
      updatedAt: Types.Scalars['DateTime']['input'];
      customerEmail?: Types.Scalars['String']['input'] | null;
      notes?: Types.Scalars['String']['input'] | null;
      repairOrderNumber?: Types.Scalars['String']['input'] | null;
    } | null;
  } | null;
};

export const UpdateCartInfoDocument = `
    mutation UpdateCartInfo($input: UpdateCartInput!) {
  updateCart(input: $input) {
    cart {
      id
      updatedAt
      customerEmail
      notes
      repairOrderNumber
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    UpdateCartInfo: build.mutation<UpdateCartInfoMutation, UpdateCartInfoMutationVariables>({
      query: (variables) => ({ document: UpdateCartInfoDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUpdateCartInfoMutation } = injectedRtkApi;
