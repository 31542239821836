import { ErrorType } from './graphql';
import type { Path } from '@partstech/ui/forms';

type BadRequestError = {
  errorType: ErrorType.BadRequest;
  constraint: string;
};

type NotFoundError = {
  errorType: ErrorType.NotFound;
  constraint: undefined;
};

type PermissionDeniedError = {
  errorType: ErrorType.PermissionDenied;
  constraint: undefined;
};

type UnauthenticatedError = {
  errorType: ErrorType.Unauthenticated;
  constraint: undefined;
};

export type ErrorLine<FormData extends Record<string, string>> = {
  message: string;
  path?: [string, string, ...Array<Path<FormData>>];
  extensions?: BadRequestError | NotFoundError | PermissionDeniedError | UnauthenticatedError;
};

export type ResponseError<FormData extends Record<string, string>> = {
  message: string;
  errors: ErrorLine<FormData>[];
  status: number;
};

export class GraphQLError<FormData extends Record<string, string>> extends Error {
  errors: ErrorLine<FormData>[];

  status: number;

  constructor({ errors, status }: { errors: ErrorLine<FormData>[]; status: number }) {
    super(GraphQLError.extractMessage(errors));

    this.status = status;
    this.errors = errors;
  }

  getAllMessages() {
    return this.errors.map((error) => error.message);
  }

  getValidationErrors() {
    return this.errors.filter((error) => error.extensions?.errorType === ErrorType.BadRequest);
  }

  getUnauthenticatedError() {
    return this.errors.find((error) => error.extensions?.errorType === ErrorType.Unauthenticated);
  }

  getPermissionDeniedError() {
    return this.errors.find((error) => error.extensions?.errorType === ErrorType.PermissionDenied);
  }

  toSerializable(): ResponseError<FormData> {
    return {
      message: this.message,
      status: this.status,
      errors: this.errors,
    };
  }

  private static extractMessage<FormData extends Record<string, string>>(errors: ErrorLine<FormData>[]): string {
    const defaultMessage = 'Internal Server Error';

    return errors[0]?.message ?? defaultMessage;
  }
}
