import { Box, Button, OldNavigationListItem, Typography, useMedia } from '@partstech/ui';
import { useKeyboardSelection, useToggle } from '@partstech/ui/hooks';
import { useCallback } from 'react';
import { useParametersList } from '../useParametersList';
import { useGetDecades } from './useGetDecades';
import { styles } from './YearsList.styles';
import type { CommonParametersListProps } from '../types';

const defaultDecadesAmount = 10;

export const YearsList = ({ searchValue, onChange, isUserFocused, year: selectedValue }: CommonParametersListProps) => {
  const { isMobile } = useMedia();
  const { isToggle, turnOn } = useToggle(false);

  const { years, decades, yearsByDecades, shownYears } = useGetDecades({
    decadesAmount: isMobile || isToggle ? undefined : defaultDecadesAmount,
    searchValue: searchValue === selectedValue ? '' : searchValue,
  });

  const { updateValue } = useParametersList({
    fieldName: 'year',
    options: shownYears,
    onChange,
    isUserFocused,
    searchValue,
    selectedValue,
  });

  const handleEnterKeyDown = useCallback(
    (index: number) => {
      const year = shownYears[index];

      if (year) {
        updateValue(String(year));
      }
    },
    [shownYears, updateValue]
  );

  const handleClick = useCallback(
    (year: number) => () => {
      updateValue(String(year));
    },
    [updateValue]
  );

  const selectedIndex = useKeyboardSelection(shownYears.length, handleEnterKeyDown);
  let yearIndex = -1;

  return (
    <>
      <div css={styles.container}>
        {decades.map((decade, index) => (
          <Box display="flex" flexDirection="column" mt={{ sm: index !== 0 ? 2 : 0, md: 0 }} key={decade}>
            <OldNavigationListItem css={styles.title} disabled>
              <Typography bold>{decade}s</Typography>
            </OldNavigationListItem>

            {yearsByDecades[decade]!.map((year) => (
              <OldNavigationListItem
                css={styles.item}
                active={selectedIndex === ++yearIndex}
                onClick={handleClick(year)}
                key={year}
              >
                {year}
              </OldNavigationListItem>
            ))}
          </Box>
        ))}
      </div>

      {!isMobile && !isToggle && years.length > 0 && (
        <Box display="flex" justifyContent="center">
          <Button onClick={turnOn} variant="light">
            Show more years
          </Button>
        </Box>
      )}
    </>
  );
};
