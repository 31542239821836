import { useSnackbar } from '@partstech/ui';
import { isEmpty } from '@partstech/ui/utils';
import { useEffect, useMemo } from 'react';
import { useMutationError } from './useMutationError';
import type { SerializedError } from '@reduxjs/toolkit';
import type { GraphQLError } from 'graphql';

export type MutationStatusArgs = {
  status: {
    error?: GraphQLError | SerializedError;
    isSuccess: boolean;
  };
  successMessage?: string;
  onSuccess?: () => void;
  onError?: (error: GraphQLError | SerializedError) => void;
  errorMessage?: string;
};

/**  @deprecated -> use useMutationCallback */
export const useMutationStatus = ({ status, successMessage, onSuccess, onError, errorMessage }: MutationStatusArgs) => {
  const { error, isSuccess } = status;

  const { addSnackbar } = useSnackbar();

  const graphQLError = useMutationError(error);

  const validationErrors = useMemo(
    () => graphQLError?.getValidationErrors().filter((validationError) => !isEmpty(validationError.path)) || [],
    [graphQLError]
  );

  useEffect(() => {
    if (!error || validationErrors.length > 0) {
      return;
    }

    // TODO: A temporary solution. You need to change the modal globally or leave the snackbar
    if (graphQLError?.getPermissionDeniedError()) {
      return;
    }

    const message = errorMessage || error.message || 'Something went wrong';

    addSnackbar({ label: message });
  }, [addSnackbar, error, errorMessage, graphQLError, validationErrors.length]);

  useEffect(() => {
    if (isSuccess && successMessage) {
      addSnackbar({ label: successMessage });
    }
  }, [isSuccess, successMessage, addSnackbar]);

  useEffect(() => {
    if (isSuccess && onSuccess) {
      onSuccess();
    }
  }, [isSuccess, onSuccess]);

  useEffect(() => {
    if (error && onError) {
      onError(error);
    }
  }, [error, onError]);

  return {
    validationErrors,
  };
};
