import { css } from '@emotion/react';
import { Icon, IconButton, Tooltip } from '@partstech/ui';
import { RECENT_SEARCHES_ANIMATION } from '../../../constants';
import type { Theme } from '@partstech/ui';

type Props = {
  isOpen: boolean;
  onClick: () => void;
};

const transitionProp = (prop: string) => `${prop} ${RECENT_SEARCHES_ANIMATION.effect}`;

const styles = {
  root: (isOpen: boolean) => (theme: Theme) => css`
    display: flex;
    align-items: center;
    height: ${theme.sizing(12)};
    color: ${isOpen ? theme.color.primary : theme.color.monoDark};
    transition: ${transitionProp('color')};
    cursor: pointer;
    user-select: none;

    & .toggle-icon {
      top: ${isOpen ? 0 : 1}px;
      display: inline-block;
      transform: rotateX(${isOpen ? 180 : 0}deg);
      transition: ${transitionProp('top')}, ${transitionProp('transform')};
    }

    @media (hover), (-ms-high-contrast: none) {
      &:hover {
        color: ${theme.color.primary};
      }
    }
  `,
};

export const OpenButton = ({ isOpen, onClick }: Props) => (
  <Tooltip isHidden={isOpen} offsetX={16} offsetY={-4} tooltip="Recent Searches">
    <IconButton
      variant="light"
      css={styles.root(isOpen)}
      onClick={onClick}
      data-testid="openButton"
      className="open-button"
    >
      <Icon name="history" />
      <Icon className="toggle-icon" name="expand_more" size="large" />
    </IconButton>
  </Tooltip>
);
