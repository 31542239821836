import { useMemo } from 'react';
import { OptionsByColumns } from 'components/OptionsByColumns';
import type { Diagram } from 'models';

type Props = {
  categoryId: string;
  diagrams: Diagram[];
  onChange: (diagram: Diagram) => void;
  onSelect?: (diagram: Diagram) => void;
  isKeyboardDisabled?: boolean;
};

export const DiagramsOfCategory = ({ categoryId, diagrams, onChange, onSelect, isKeyboardDisabled }: Props) => {
  const options = useMemo(
    () => diagrams.filter((diagram) => diagram.category?.id === categoryId),
    [categoryId, diagrams]
  );

  return (
    <OptionsByColumns
      onChange={onChange}
      onSelect={onSelect}
      options={options}
      isKeyboardDisabled={isKeyboardDisabled}
    />
  );
};
