import { useForm } from '@partstech/ui/forms';
import { useCallback, useMemo } from 'react';
import { useFormStatus } from 'shared/lib/form';
import { useChangePasswordConfirmation } from '../../../api/changePasswordConfirmation';
import type { ChangePasswordConfirmationInput } from 'shared/api';

type Args = {
  newPassword: string;
  onSubmit: () => void;
};

export const useConfirmPasswordForm = ({ newPassword, onSubmit }: Args) => {
  const [confirmChangePassword, status] = useChangePasswordConfirmation();
  const { isLoading } = status;

  const form = useForm<ChangePasswordConfirmationInput>({ defaultValues: { newPassword, confirmationCode: '' } });
  const { reset, watch } = form;

  const { confirmationCode } = watch();

  const isDisabled = useMemo(
    () => isLoading || confirmationCode.length !== 5 || confirmationCode.split('').some((value) => !value.trim()),
    [isLoading, confirmationCode]
  );

  const submitForm = useCallback(
    (values: ChangePasswordConfirmationInput) => {
      reset({ newPassword });

      confirmChangePassword(values);
    },
    [confirmChangePassword, newPassword, reset]
  );

  useFormStatus({ form, status, successMessage: 'Your password has been successfully updated.', onSuccess: onSubmit });

  return { form, submitForm, isDisabled, isLoading };
};
