import { Navigate } from 'react-router-dom';
import { Outlet } from '../Outlet';
import { useGetRedirect } from './useGetRedirect';
import type { PagePermission } from 'constant';
import type { PropsWithChildren } from 'react';

type Props = {
  permission: PagePermission;
};

export const ProtectedRoute = ({ permission, children }: PropsWithChildren<Props>) => {
  const redirectUrl = useGetRedirect(permission);

  if (redirectUrl) {
    return <Navigate to={redirectUrl} replace />;
  }

  return <Outlet>{children}</Outlet>;
};
