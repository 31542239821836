import { useMutationStatus } from 'shared/api';
import { api as savedCartApi } from '../../queries/GetSavedCart.generated';
import { api as generatedApi } from './UpdateCartInfo.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    UpdateCartInfo: {
      onQueryStarted: async (params, { dispatch, queryFulfilled }) => {
        try {
          const res = await queryFulfilled;

          dispatch(
            savedCartApi.util.updateQueryData('GetSavedCart', { id: params.input.id }, (draft) => {
              if (draft.cart && res.data.updateCart?.cart) {
                Object.assign(draft.cart, res.data.updateCart.cart);
              }
            })
          );
        } catch {
          /* empty */
        }
      },
    },
  },
});

const { useUpdateCartInfoMutation } = api;

export const useUpdateCartInfo = () => {
  const [updateCartInfo, status] = useUpdateCartInfoMutation();

  useMutationStatus({ status });

  return [updateCartInfo, status] as const;
};
