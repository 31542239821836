import { useMemo } from 'react';
import { useInfiniteScrollingFetch, CartStatusFilter } from 'shared/api';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import { api as generatedApi, useLazyGetSavedCartListQuery } from './GetSavedCartList.generated';
import type { GetSavedCartListQuery } from './GetSavedCartList.generated';
import type { SortOrder } from 'shared/api';

type Carts = NonNullable<GetSavedCartListQuery['carts']>;
type CartEdges = NonNullable<Carts['edges']>;
export type CartListItem = CartEdges[0]['node'];
export type CartLinkedVehicle = NonNullable<CartListItem['vehicles']>[0];

type CartListArgs = {
  after?: string;
  first?: number;
  statuses?: CartStatusFilter[];
  search?: string;
  sortOrder?: SortOrder;
};

const emptyCartList: CartListItem[] = [];

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    GetSavedCartList: {
      forceRefetch: ({ currentArg, previousArg }) => currentArg !== previousArg,
      serializeQueryArgs: () => ({}),
      merge: (currentCache, newResponce) => {
        const isFirstPage = !newResponce?.carts?.pageInfo.hasPreviousPage;

        if (!isFirstPage && newResponce.carts?.edges && currentCache?.carts?.edges) {
          return {
            carts: {
              ...newResponce.carts,
              edges: [...currentCache.carts.edges, ...newResponce.carts.edges],
            },
          };
        }

        return newResponce;
      },
    },
  },
});

const { useGetSavedCartListQuery } = api;

const defaultStatuses = [CartStatusFilter.Active, CartStatusFilter.Created, CartStatusFilter.Ordered];

export const useGetSavedCartList = (args: CartListArgs = {}) => {
  const { hasCurrentUser } = useGetCurrentUser();

  const fullArgs = useMemo(
    () => ({
      ...args,
      statuses: !args.statuses || args.statuses?.length > 0 ? args.statuses : defaultStatuses,
    }),
    [args]
  );

  const { data, isFetching } = useGetSavedCartListQuery(fullArgs, { skip: !hasCurrentUser });

  const [fetchNext] = useLazyGetSavedCartListQuery();

  const { hasNextPage = false, endCursor = null } = data?.carts?.pageInfo || {};

  const { loadMore, isFetchingFirstPage, isFetchingMore } = useInfiniteScrollingFetch({
    fetchNext,
    isFetching,
    endCursor,
    args: fullArgs,
  });

  const cartList: CartListItem[] = useMemo(
    () => data?.carts?.edges?.map((edge) => edge.node) || emptyCartList,
    [data?.carts?.edges]
  );

  return {
    cartList,
    hasNextPage,
    loadMore,
    isFetchingFirstPage,
    isFetchingMore,
  };
};
