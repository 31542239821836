import { api as generatedApi } from './CreatePricePackage.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    CreatePricePackage: {
      invalidatesTags: ['PricePackage', 'ActiveCart'],
    },
  },
});

export const { useCreatePricePackageMutation } = api;
