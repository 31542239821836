import { useCallback, useEffect, useState } from 'react';
import { TireSize } from 'models';
import type { TireSizeFormData } from 'features/searchForm';

export const useTireSizeValue = (defaultValue: TireSizeFormData, onChange?: (value: TireSize) => void) => {
  const [value, setValue] = useState<TireSizeFormData>(defaultValue);

  const setWidth = useCallback((width: string | number | null) => {
    setValue({ width: width ? `${width}` : null, height: null, rim: null });
  }, []);

  const setHeight = useCallback((height: string | number | null) => {
    setValue((prev) => ({ ...prev, height: height ? `${height}` : null, rim: null }));
  }, []);

  const setRim = useCallback((rim: string | number | null) => {
    setValue((prev) => ({ ...prev, rim: rim ? `${rim}` : null }));
  }, []);

  useEffect(() => {
    if (value.width && value.height && value.rim && onChange) {
      onChange(new TireSize({ width: Number(value.width), height: Number(value.height), rim: Number(value.rim) }));
    }
  }, [onChange, value.height, value.rim, value.width]);

  return { value, setValue, setHeight, setRim, setWidth };
};
