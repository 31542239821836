import { Button, Typography, Box, TextCut, Card, useMedia } from '@partstech/ui';
import React, { forwardRef, useMemo } from 'react';
import { Region } from 'components/Region';
import { VinButton } from '../../VinButton';
import { styles } from './SelectVehicleButton.styles';
import type { Vehicle } from 'models';

export type SelectVehicleButtonProps = {
  isOpen: boolean;
  onClick: (e: React.MouseEvent | React.TouchEvent) => void;
  selectedVehicle: Vehicle | null;
  disabled?: boolean;
};

export const SelectVehicleButton = forwardRef<HTMLButtonElement, SelectVehicleButtonProps>(
  ({ onClick, isOpen, selectedVehicle, disabled }, ref) => {
    const { isMobile, isDesktop } = useMedia();

    const trailingIcon = useMemo(() => {
      if (isMobile) {
        return undefined;
      }

      return isOpen ? 'expand_less' : 'expand_more';
    }, [isOpen, isMobile]);

    return (
      <Card height="100%" width="100%" background="mono2" borderWidth={0}>
        <Button
          variant="text"
          trailingIcon={trailingIcon}
          iconSize={isMobile ? 'medium' : 'default'}
          leadingIcon="directions_car"
          iconColor={isOpen ? 'primary' : 'monoDark'}
          noHover
          fullWidth
          className="selectVehicleButton"
          css={styles.toggle}
          onClick={onClick}
          disabled={disabled}
          ref={ref}
          data-testid="selectVehicleButton"
        >
          {selectedVehicle && (
            <>
              <Box textAlign="left" width="100%" maxWidth={{ md: 32, xl: 46 }} css={styles.vehicle}>
                <Box display="flex" flexDirection={isMobile ? 'row' : 'column'} gap={[0, 1]}>
                  <Typography variant="overline" bold component="div" textTransform="uppercase">
                    <TextCut shouldShowTooltip={false}>
                      {selectedVehicle.year} {selectedVehicle.make.name} {selectedVehicle.model.name}
                    </TextCut>
                  </Typography>

                  <Typography variant="caption" component="div" textTransform="uppercase">
                    <TextCut shouldShowTooltip={false}>
                      <>
                        {selectedVehicle.subModel.name}{' '}
                        {selectedVehicle.regions && (
                          <span css={styles.region}>
                            <Region regions={selectedVehicle.regions} />
                          </span>
                        )}
                        {selectedVehicle.engine.name}
                      </>
                    </TextCut>
                  </Typography>
                </Box>

                {selectedVehicle.vin && <VinButton isNewDesign vehicle={selectedVehicle} />}
              </Box>
              {isMobile && (
                <Box ml="auto" pl={1} flexShrink={0}>
                  <Typography variant="bodyText2" component="div" color="linkText">
                    Change
                  </Typography>
                </Box>
              )}
            </>
          )}

          {!selectedVehicle && (
            <Box display="flex" width="100%">
              <Typography variant={isDesktop ? 'bodyText1' : 'bodyText2'}>Select Vehicle</Typography>
            </Box>
          )}
        </Button>
      </Card>
    );
  }
);
