import { useCreateModal, usePrevious } from '@partstech/ui/hooks';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { DiagramsListModal } from 'components/VehicleDiagrams/DiagramsListModal';
import { useDiagramsCategories } from 'hooks/diagrams';
import { useSearchParams } from 'hooks/search';
import { DiagramButton } from './DiagramButton';

export const DiagramQuickLink = memo(() => {
  const { searchParams, partType } = useSearchParams();
  const previousSearchParams = usePrevious(searchParams);

  const { vehicle: vehicleId } = searchParams;

  const [shouldOpenModal, setShouldOpenModal] = useState(false);

  const { categories } = useDiagramsCategories({ vehicleId: vehicleId ?? null });

  const selectedDiagramCategory = useMemo(
    () => categories.find((category) => partType?.category?.id === category.id),
    [categories, partType?.category?.id]
  );

  const diagramCategoryTitle = useMemo(() => {
    if (vehicleId && selectedDiagramCategory?.name) {
      return selectedDiagramCategory.name;
    }

    return 'All categories';
  }, [selectedDiagramCategory?.name, vehicleId]);

  const handleSelectVehicleClick = useCallback(() => {
    setShouldOpenModal(true);
  }, []);

  const { open: openDiagramQuickLinkModal } = useCreateModal(DiagramsListModal, {
    size: 'xl',
    onSelectVehicleClick: handleSelectVehicleClick,
    defaultCategoryId: selectedDiagramCategory?.id ?? null,
    vehicleId: searchParams.vehicle ?? null,
  });

  useEffect(() => {
    if (!shouldOpenModal) {
      return;
    }

    if (previousSearchParams && !previousSearchParams?.vehicle && vehicleId) {
      setShouldOpenModal(false);
      openDiagramQuickLinkModal({ onSelectVehicleClick: handleSelectVehicleClick });
    }
  }, [handleSelectVehicleClick, openDiagramQuickLinkModal, previousSearchParams, shouldOpenModal, vehicleId]);

  return <DiagramButton categoryTitle={diagramCategoryTitle} onClick={openDiagramQuickLinkModal} />;
});
