import { Specification } from '../../../Specification';
import { AttributeTooltip } from '../AttributeTooltip';
import { TireQualityTranslation } from '../TireQualityTranslation';
import type { ProductAttribute } from 'types/product';

type Props = {
  attribute: ProductAttribute;
};

export const TireQualityAttribute = ({ attribute }: Props) => (
  <Specification
    label={attribute.label}
    value={attribute.value}
    adornmentAfter={<AttributeTooltip tooltip={<TireQualityTranslation attributes={[attribute]} />} />}
  />
);
