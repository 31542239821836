import { css } from '@emotion/react';
import { Box, Card, Icon, Tooltip, Typography, useSnackbar } from '@partstech/ui';
import { uniq, copyToClipboard } from '@partstech/ui/utils';
import { useCallback, useState } from 'react';
import type { Theme } from '@partstech/ui';

const styles = {
  card: (theme: Theme) => css`
    .ui-card-inner {
      cursor: pointer;
      &:hover {
        background-color: ${theme.color.lightBlue};
      }
    }
  `,
};

type Props = {
  items: string[];
};

export const NoResultsBlock = ({ items }: Props) => {
  const [copiedItem, setCopiedItem] = useState<string | null>(null);

  const { addSnackbar } = useSnackbar();

  const handleClick = useCallback(
    (value: string) => async () => {
      copyToClipboard(value);
      setCopiedItem(value);
      addSnackbar({
        label: `${value} has been copied!`,
        onTimeout: () => setCopiedItem(null),
      });
    },
    [addSnackbar]
  );

  return (
    <Box mt={3} data-testid="noResultsBlock">
      <Box display="flex" alignItems="center" gap={2}>
        <Icon name="error" color="negative" size="large" />
        <Typography variant="bodyText2" color="subtleText">
          <Typography variant="subtitle2" component="span">
            No results
          </Typography>{' '}
          - It looks like this supplier does not have items that match your search.
        </Typography>
      </Box>

      <Box display="flex" gap={3} mt={3} flexWrap="wrap">
        {uniq(items).map((item) => (
          <Tooltip key={item} tooltip="Copy">
            <Card onClick={handleClick(item)} radius={4} css={styles.card} data-testid="noResultsBlockItem">
              <Box p={2} display="flex" gap={1}>
                <Typography variant="bodyText2" color="subtleText">
                  {item}
                </Typography>
                <Icon
                  name={copiedItem === item ? 'check' : 'content_copy'}
                  color={copiedItem === item ? 'positive' : 'subtleText'}
                  size="large"
                />
              </Box>
            </Card>
          </Tooltip>
        ))}
      </Box>
    </Box>
  );
};
