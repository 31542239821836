import { Icon, MenuItem, ModalView } from '@partstech/ui';
import { useCallback } from 'react';
import type { ModalComponentProps } from '@partstech/ui';

type Props = {
  onMoreResultsClick?: () => void;
  onRemoveRow: () => void;
  searchQuery: string;
};

export const MobileMenu = ({ onClose, onMoreResultsClick, onRemoveRow, searchQuery }: ModalComponentProps<Props>) => {
  const handleMoreResultsClick = useCallback(() => {
    onMoreResultsClick && onMoreResultsClick();
    onClose();
  }, [onClose, onMoreResultsClick]);

  const handleRemoveRow = useCallback(() => {
    onRemoveRow();
    onClose();
  }, [onClose, onRemoveRow]);

  return (
    <ModalView onClose={onClose}>
      {onMoreResultsClick && (
        <MenuItem prefix={<Icon name="swap_horiz" color="subtleText" />} onClick={handleMoreResultsClick}>
          More results for {searchQuery}
        </MenuItem>
      )}

      <MenuItem prefix={<Icon name="delete" color="subtleText" />} onClick={handleRemoveRow}>
        Delete part
      </MenuItem>
    </ModalView>
  );
};
