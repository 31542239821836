import { api as activeCartApi } from 'features/cart/api/queries/GetActiveCart.generated';
import { useMutationStatus } from 'shared/api';
import { api as generatedApi } from './DeleteCartOrderItems.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    DeleteActiveCartItems: {
      onQueryStarted: async ({ itemIds }, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;

        if (data.deleteActiveCartItems && 'deletedItemIds' in data.deleteActiveCartItems) {
          dispatch(
            activeCartApi.util.updateQueryData('GetActiveCart', undefined, (draft) => {
              draft?.activeCart?.orders?.forEach((order) => {
                Object.assign(order, { items: order.items?.filter((item) => !itemIds.includes(item.id)) });
              });
            })
          );
        }
      },
    },
  },
});

const { useDeleteActiveCartItemsMutation } = api;

export const useDeleteOrderItems = () => {
  const [removeOrderItems, status] = useDeleteActiveCartItemsMutation();

  useMutationStatus({ status });

  return [removeOrderItems, status] as const;
};
