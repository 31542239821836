import { OldList } from '@partstech/ui';
import { chunk } from '@partstech/ui/utils';
import { useMemo } from 'react';
import { Options } from './Options';
import type { Option } from './Options';
import type { BoxProps } from '@partstech/ui';
import type { ReactNode } from 'react';

type Props = {
  adornmentAfter?: (option: Option) => ReactNode;
  activeOption?: Option['id'];
  columnCount?: number;
  options: Option[];
  onClick: (option: Option) => void;
} & Omit<BoxProps, 'alignItems' | 'color'>;

export const OptionsList = ({ activeOption, adornmentAfter, columnCount, options, onClick, ...boxProps }: Props) => {
  const columns = useMemo(() => {
    let count = 1;

    if (options.length > 10) {
      count = 2;
    }

    if (options.length > 20) {
      count = 4;
    }

    return chunk(options, Math.ceil(options.length / (columnCount || count)));
  }, [columnCount, options]);

  return (
    <OldList fullWidth size="slim">
      <Options
        activeOption={activeOption}
        adornmentAfter={adornmentAfter}
        columns={columns}
        onSelect={onClick}
        {...boxProps}
      />
    </OldList>
  );
};
