import { useCallback } from 'react';
import { api } from './BuyCart.generated';
import type { CartOrderFragment } from '../../queries';
import type { CartOrder } from 'models';
import type { BuyActiveCartMitchell1Payload, SubmittedLabor } from 'shared/api';

type BuyResponse = {
  error: string | null;
  redirectUrl?: string | null;
  orders?: CartOrderFragment[];
  mitchell1Payload?: BuyActiveCartMitchell1Payload | null;
};

const { useBuyCartMutation } = api;

export const useBuyCart = () => {
  const [mutate, status] = useBuyCartMutation();

  const buyCart = useCallback(
    async (orders: CartOrder[], laborItemIds: string[], submittedLabor: SubmittedLabor[]): Promise<BuyResponse> => {
      const res = await mutate({
        orders: orders.map((order) => ({
          customNotes: order.notes,
          orderId: order.id,
          purchaseOrderNumber: order.poNumber || undefined,
          shippingMethodCode: order.shippingMethodCode,
          items: order.items.map((item) => ({ itemId: item.id })),
        })),
        laborItemIds,
        submittedLabor,
      });

      if (!res.data?.buyActiveCart) {
        return {
          error: res.error?.message || 'Something went wrong',
        };
      }

      if ('errorMessage' in res.data.buyActiveCart) {
        return {
          error:
            res.data.buyActiveCart.errorMessage ||
            res.data.buyActiveCart.buyOrderErrors?.[0]?.errorMessage ||
            'Something went wrong',
        };
      }

      if ('orders' in res.data.buyActiveCart) {
        return {
          error: null,
          redirectUrl: res.data.buyActiveCart.redirectUrl,
          orders: res.data.buyActiveCart.orders,
          mitchell1Payload: res.data.buyActiveCart.mitchell1Payload,
        };
      }

      return {
        error: 'Something went wrong',
      };
    },
    [mutate]
  );

  return [buyCart, status] as const;
};
