import { useEffect } from 'react';
import { Userpilot } from 'userpilot';

/**
 * useUserpilotReload
 *
 * A custom hook that triggers `Userpilot.reload()` whenever any of the dependencies change.
 * This hook accepts an array of dependencies and will run the reload function
 * whenever any of those dependencies are updated.
 *
 * @param dependencies - An array of dependencies that the effect depends on.
 * The hook will trigger `Userpilot.reload()` whenever any of these dependencies change.
 *
 * @example
 * ```typescript
 * const { pathname, search } = useLocation();
 * useUserpilotReload([pathname, search]);
 * ```
 */
export const useUserpilotReload = <T extends unknown[]>(dependencies: T) =>
  useEffect(() => {
    Userpilot.reload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
