import { useMemo } from 'react';
import { GraphQLError } from '../GraphQLError';
import type { ResponseError } from '../GraphQLError';
import type { FieldValues } from '@partstech/ui/forms';
import type { SerializedError } from '@reduxjs/toolkit';

export const useMutationError = <FormData extends FieldValues = {}>(
  error: ResponseError<FormData> | SerializedError | undefined
) =>
  useMemo(() => {
    if (error === undefined) {
      return null;
    }

    if (!('errors' in error)) {
      return null;
    }

    return new GraphQLError(error);
  }, [error]);
