import { Box, Typography } from '@partstech/ui';
import { useSubscriptionEX } from '../../../../hooks/useSubscriptionEX';
import { FeatureRow } from '../FeatureRow';
import { FeatureValue } from '../FeatureRow/FeatureValue';

export const FeaturesEX = () => {
  const { featuresEX, isRestrictedEX } = useSubscriptionEX();

  if (!isRestrictedEX || !featuresEX.length) {
    return null;
  }

  return (
    <>
      <div className="feature-column">
        <Box pt={6} pb={2} pl={8}>
          <Typography variant="subtitle2">EX Features</Typography>
        </Box>
      </div>

      {featuresEX.map((feature) => (
        <FeatureRow key={feature.name} height={17} description={feature.description} name={feature.name}>
          <FeatureValue isEnabled={feature.isEnabled} />
        </FeatureRow>
      ))}
    </>
  );
};
