import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import { api as generatedApi } from './GetStockOrderTemplate.generated';
import type { GetStockOrderTemplateQuery } from './GetStockOrderTemplate.generated';

type CurrentUser = NonNullable<GetStockOrderTemplateQuery['currentUser']>;
type ActiveMember = NonNullable<CurrentUser['activeMember']>;
type Shop = NonNullable<ActiveMember['shop']>;
export type StockOrderTemplate = NonNullable<Shop['stockOrderTemplate']>;

const api = generatedApi.enhanceEndpoints({
  addTagTypes: ['StockOrderTemplate'],
  endpoints: {
    GetStockOrderTemplate: {
      providesTags: (response: GetStockOrderTemplateQuery | undefined) => {
        const shop = response?.currentUser?.activeMember?.shop;
        if (!shop) {
          return [];
        }

        return ['StockOrderTemplate', { type: 'StockOrderTemplate' as const, id: shop.stockOrderTemplate?.id }];
      },
    },
  },
});

const { useGetStockOrderTemplateQuery } = api;

export const useGetStockOrderTemplate = (id?: string) => {
  const { hasCurrentUser } = useGetCurrentUser();

  const { data, ...props } = useGetStockOrderTemplateQuery({ id: id || '' }, { skip: !hasCurrentUser || !id });

  const template = data?.currentUser?.activeMember?.shop?.stockOrderTemplate;

  return {
    template,
    ...props,
  };
};
