import { Icon } from '@partstech/ui';
import { styles } from './Asset.styles';
import type { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
  file: string;
}>;

export const Asset = ({ children, file }: Props) => {
  const fileExtension = file.match(/\.([0-9a-z]+)(?:[?#]|$)/i);

  return (
    <a css={styles.asset} href={file} target="_blank" rel="noopener noreferrer">
      <div css={styles.extension}>
        <Icon name="insert_drive_file" />
        <span css={styles.extensionIcon}>{fileExtension && fileExtension[1]}</span>
      </div>
      <span css={styles.name}>{children}</span>
    </a>
  );
};
