import { isNumber } from '@partstech/ui/utils';
import { useCallback, useEffect, useMemo } from 'react';
import { searchByName } from '../../utils/searchByName';
import type { CommonParametersListProps, VehicleField } from './types';
import type { Option } from '../OptionsList/Options';

type Props = Pick<CommonParametersListProps, 'onChange' | 'isUserFocused'> & {
  fieldName: VehicleField;
  options: (number | Option)[];
  searchValue?: string;
  selectedValue?: string;
};

const formatOption = (option: number | Option) => (isNumber(option) ? String(option) : option);

export const useParametersList = ({
  fieldName,
  options: rawOptions,
  onChange,
  isUserFocused,
  searchValue,
  selectedValue,
}: Props) => {
  const options = useMemo(
    () =>
      rawOptions.filter((option) => {
        if (!searchValue) {
          return rawOptions;
        }

        if (isNumber(option)) {
          return String(option).includes(searchValue);
        }

        return searchByName(searchValue)(option);
      }),
    [rawOptions, searchValue]
  );

  const updateValue = useCallback(
    (value: string | Option) => {
      onChange(fieldName, value);
    },
    [fieldName, onChange]
  );

  const canAutoSelectOption = useCallback(
    (option: number | Option) => {
      const isSameValue = isNumber(option) ? String(option) === selectedValue : option.id === selectedValue;
      const isFullSearch = isNumber(option) ? String(option) === searchValue : option.name === searchValue;

      return { isSameValue, isFullSearch };
    },
    [searchValue, selectedValue]
  );

  useEffect(() => {
    const [option] = options;

    if (options.length !== 1 || !option) {
      return;
    }

    const { isSameValue, isFullSearch } = canAutoSelectOption(option);

    if (!selectedValue) {
      updateValue(formatOption(option));
      return;
    }

    if (selectedValue && !isSameValue) {
      updateValue(formatOption(option));
      return;
    }

    if (selectedValue && !isUserFocused && isSameValue && isFullSearch && fieldName !== 'engineId') {
      updateValue(formatOption(option));
    }
  }, [canAutoSelectOption, fieldName, isUserFocused, options, selectedValue, updateValue]);

  return { updateValue };
};
