import { TextField, Icon, Box } from '@partstech/ui';
import { useCallback, useEffect, useRef } from 'react';
import { useVehicleSuggestionSearch } from '../../hooks/useVehicleSuggestionSearch';
import { VehiclesList } from '../VehiclesList';
import type { BoxProps } from '@partstech/ui';
import type { Vehicle } from 'models';

type Props = {
  value?: Vehicle | null;
  isActive?: boolean;
  onChange?: (vehicle: Vehicle | null) => void;
} & BoxProps;

export const VehicleTypeahead = ({ isActive, value, onChange, ...props }: Props) => {
  const ref = useRef<HTMLInputElement>(null);

  const {
    value: currentValue,
    changeValue,
    vehicleSuggestions,
  } = useVehicleSuggestionSearch({
    value: isActive ? value : null,
    onChange: isActive ? onChange : undefined,
  });

  const handleClear = useCallback(() => {
    changeValue('');

    if (onChange) {
      onChange(null);
    }
  }, [changeValue, onChange]);

  useEffect(() => {
    if (isActive) {
      ref.current?.focus();
    }
  }, [isActive]);

  return (
    <Box {...props} flexDirection="column" gap={2}>
      <TextField
        autoFocus={isActive}
        autoComplete="off"
        adornmentAfter={currentValue ? undefined : <Icon name="search" />}
        name="vehicle"
        onClear={handleClear}
        isClearable={currentValue.length > 0}
        value={currentValue}
        onChange={changeValue}
        placeholder="Enter your car (for example: 2005 Ford Mustang)"
        ref={ref}
      />

      <VehiclesList
        onItemClick={onChange}
        onSelect={changeValue}
        vehicles={vehicleSuggestions}
        disabled={!isActive}
        highlightValue={currentValue}
      />
    </Box>
  );
};
