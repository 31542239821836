import { useMemo } from 'react';
import { FeatureValue } from '../FeatureRow/FeatureValue';
import type { Plan } from '../../../../types';

type Props = {
  featureRow: string;
  plan: Plan;
};

export const PlanFeatureValue = ({ featureRow, plan }: Props) => {
  const currentFeature = useMemo(
    () => plan.features.find((planFeature) => planFeature.name === featureRow),
    [featureRow, plan.features]
  );

  return (
    <FeatureValue
      isEnabled={currentFeature?.isEnabled}
      partialEnabled={currentFeature?.partialEnabled}
      data-testid={`${plan.alias}Value`}
    />
  );
};
