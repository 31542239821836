import accounting from 'accounting';

export function currency(
  num: number | string,
  symbol: string = '$',
  precision: number = 2,
  thousand: string = ',',
  decimal: string = '.',
  format: string = '%s%v'
) {
  return +num < 0
    ? `-${accounting.formatMoney(-num, symbol, precision, thousand, decimal, format)}`
    : accounting.formatMoney(+num, symbol, precision, thousand, decimal, format);
}
