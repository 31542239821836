import { useCallback } from 'react';
import { useBroadcastChannel } from 'app/BroadcastChannelProvider/BroadcastChannelContext';
import { useAppDispatch } from 'store';
import { logout as logoutAction } from 'store/features/user/account';

export const useLogout = () => {
  const dispatch = useAppDispatch();

  const { sendEvent } = useBroadcastChannel();

  return useCallback(async () => {
    await dispatch(logoutAction());

    sendEvent('logout');
  }, [sendEvent, dispatch]);
};
