import { useSnackbar } from '@partstech/ui';
import { useForm } from '@partstech/ui/forms';
import { useCallback, useEffect } from 'react';
import { useValidateAddressesMutation } from 'entities/address/api/validateAddresses/ValidateAddresses.generated';
import { Country, GraphQLError, ShopType } from 'shared/api';
import { denormalizeFormData, useFormMutationCallback } from 'shared/lib/form';
import { useCheckUserAlreadyExistsMutation } from '../../api/checkUserAlreadyExists/CheckUserAlreadyExists.generated';
import type { FieldPath, UseFormSetError } from '@partstech/ui/forms';
import type { AddressInput, CreateShopAndUserWithSmsConnectInput } from 'shared/api';
import type { UtmParams } from 'shared/types';

const alreadyRegisteredMessage = 'User is already registered';

export type SignUpSmsConnectionFormData = {
  utmParams: UtmParams;
} & Omit<CreateShopAndUserWithSmsConnectInput, 'hash' | 'utmParams'>;

const initialValues: SignUpSmsConnectionFormData = {
  address: {
    address1: '',
    address2: null,
    city: '',
    country: Country.Us,
    state: '',
    zipCode: '',
  },
  email: '',
  lastName: '',
  password: '',
  shopName: '',
  shopPhoneNumber: '',
  shopType: ShopType.GeneralRepairShop,
  website: null,
  firstName: '',

  utmParams: {},
};

export type SignUpFormArgs = {
  defaultValues?: SignUpSmsConnectionFormData;
  onSubmit: (payload: SignUpSmsConnectionFormData) => void | Promise<void>;
  variant?: 'default' | 'detailed';
};

export const useSignUpForm = ({ defaultValues = initialValues, variant, onSubmit }: SignUpFormArgs) => {
  const { addSnackbar } = useSnackbar();

  const form = useForm<SignUpSmsConnectionFormData>({ defaultValues: denormalizeFormData(defaultValues) });

  const {
    reset,
    formState: { isValid, isSubmitting },
    setError,
  } = form;

  const handleFormError = useCallback<UseFormSetError<SignUpSmsConnectionFormData>>(
    (name, errorMessage) => {
      setError(name.replaceAll('billingAddress', 'address') as FieldPath<SignUpSmsConnectionFormData>, errorMessage);
    },
    [setError]
  );

  const [validateAddress] = useFormMutationCallback(useValidateAddressesMutation, {
    onValidationError: handleFormError,
  });

  const checkAddressIsValid = useCallback(
    async (address: AddressInput) => {
      const response = await validateAddress({ input: { billingAddress: address } });

      if (!response || response instanceof GraphQLError || !response.validateAddresses?.billingValidity) {
        addSnackbar({ label: 'Address is not valid' });
        return false;
      }

      return true;
    },
    [addSnackbar, validateAddress]
  );

  const [checkUserAlreadyExistsTrigger] = useCheckUserAlreadyExistsMutation();

  const checkUserAlreadyExists = useCallback(
    async (email: string) => {
      const response = await checkUserAlreadyExistsTrigger({ input: { email } });

      if (response.data?.userAlreadyExists?.exists) {
        if (variant === 'default') {
          addSnackbar({ label: alreadyRegisteredMessage });
          return true;
        }

        setError('email', { message: alreadyRegisteredMessage });
        return true;
      }

      return false;
    },
    [addSnackbar, checkUserAlreadyExistsTrigger, setError, variant]
  );

  const submitForm = useCallback(
    async (payload: SignUpSmsConnectionFormData) => {
      const isAlreadyExists = await checkUserAlreadyExists(payload.email);

      if (isAlreadyExists) {
        return;
      }

      const isAddressValid = await checkAddressIsValid(payload.address);

      if (!isAddressValid) {
        return;
      }

      await onSubmit(payload);
    },
    [checkAddressIsValid, checkUserAlreadyExists, onSubmit]
  );

  const isLoading = isSubmitting;

  const isDisabled = !isValid || isLoading;

  useEffect(() => {
    reset(denormalizeFormData(defaultValues));
  }, [defaultValues, reset]);

  return { form, submitForm, isLoading, isDisabled };
};
