/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type GetSmsRegistrationDataQueryVariables = Types.Exact<{
  hash: Types.Scalars['String']['input'];
}>;

export type GetSmsRegistrationDataQuery = {
  __typename?: 'Query';
  smsRegistrationData?: {
    __typename?: 'SmsRegistrationDataResponse';
    error?: Types.RegistrationDataError | null;
    data?: {
      __typename?: 'SmsRegistrationData';
      address1?: Types.Scalars['String']['input'] | null;
      address2?: Types.Scalars['String']['input'] | null;
      alreadyRegistered: Types.Scalars['Boolean']['input'];
      city?: Types.Scalars['String']['input'] | null;
      country?: Types.Country | null;
      email: Types.Scalars['String']['input'];
      firstName?: Types.Scalars['String']['input'] | null;
      lastName?: Types.Scalars['String']['input'] | null;
      managementSystemId: Types.Scalars['String']['input'];
      shopName?: Types.Scalars['String']['input'] | null;
      shopPhoneNumber?: Types.Scalars['String']['input'] | null;
      shopType?: Types.ShopType | null;
      state?: Types.Scalars['String']['input'] | null;
      video: Types.Scalars['String']['input'];
      website?: Types.Scalars['String']['input'] | null;
      zipCode?: Types.Scalars['String']['input'] | null;
    } | null;
  } | null;
};

export const GetSmsRegistrationDataDocument = `
    query GetSmsRegistrationData($hash: String!) {
  smsRegistrationData(hash: $hash) {
    data {
      address1
      address2
      alreadyRegistered
      city
      country
      email
      firstName
      lastName
      managementSystemId
      shopName
      shopPhoneNumber
      shopType
      state
      video
      website
      zipCode
    }
    error
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetSmsRegistrationData: build.query<GetSmsRegistrationDataQuery, GetSmsRegistrationDataQueryVariables>({
      query: (variables) => ({ document: GetSmsRegistrationDataDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetSmsRegistrationDataQuery, useLazyGetSmsRegistrationDataQuery } = injectedRtkApi;
