import { Box, ImageFallback, Typography } from '@partstech/ui';
import jobsGif from 'images/content/jobs/jobs.gif';

export const Jobs = () => (
  <Box textAlign="center">
    <Box mb={6} width="100%" px={{ md: 9 }}>
      <ImageFallback src={jobsGif} width="100%" />
    </Box>

    <Box mb={2}>
      <Typography variant="bodyText1">Jobs is only available with a PartsTech Plus subscription.</Typography>
    </Box>

    <Box mb={2} px={1}>
      <Typography variant="bodyText1">
        Select a job from our popular jobs list and PartsTech will create a list of part types that are associated to
        that job.
      </Typography>
    </Box>

    <Typography variant="bodyText1">
      Jobs makes it easier for you to quickly search for all parts related to particular job in one go.
    </Typography>
  </Box>
);
