import { Box, Typography } from '@partstech/ui';
import type { ReactNode } from 'react';

type Props = {
  label: string;
  value: string;
  adornmentAfter?: ReactNode;
} & { className?: string };

export const Specification = ({ label, value, adornmentAfter, className }: Props) => (
  <Box display="flex" data-testid={label} className={className}>
    <Typography variant="bodyText2" color="subtleText">
      {label}:
    </Typography>
    <Box ml={1}>
      <Typography variant="bodyText2" color="defaultText" component="span">
        {value}
      </Typography>
      {adornmentAfter && (
        <Box display="inline-block" ml={1}>
          {adornmentAfter}
        </Box>
      )}
    </Box>
  </Box>
);
