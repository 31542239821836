import { useMemo } from 'react';
import { EditProfileInfo } from 'features/profile';
import { UserPhoneType } from 'shared/api';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import type { UpdateUserInformationInput } from 'shared/api';

type Props = {
  onClose: () => void;
};

export const Details = ({ onClose }: Props) => {
  const { firstName, lastName, email, phone, phoneType, shop } = useGetCurrentUser();

  const defaultValues: UpdateUserInformationInput = useMemo(
    () => ({ firstName, lastName, email: email ?? '', phone, phoneType: phoneType ?? UserPhoneType.Mobile }),
    [email, firstName, lastName, phone, phoneType]
  );

  return (
    <EditProfileInfo
      defaultValues={defaultValues}
      phoneShop={shop?.phone ?? undefined}
      onSubmit={onClose}
      onCancel={onClose}
    />
  );
};
