import { ChangePassword } from 'features/login';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';

type Props = {
  onClose: () => void;
};

export const Password = ({ onClose }: Props) => {
  const { isGoogleAccount } = useGetCurrentUser();

  return <ChangePassword showCurrentPasswordField={!isGoogleAccount} onSubmit={onClose} onCancel={onClose} />;
};
