import { Box, Button, ModalButtons, ModalView, Typography } from '@partstech/ui';
import { HookForm, FormCheckBoxField, FormTextField, useForm } from '@partstech/ui/forms';
import { useCallback } from 'react';
import { useCreateLaborRateMutation } from 'features/laborRates/api/createLaborRate';
import { useGetLaborRates } from 'features/laborRates/api/getLaborRates/useGetLaborRates';
import { useUpdateLaborRateMutation } from 'features/laborRates/api/updateLaborRate';
import { useFormStatus, errorMessages } from 'shared/lib/form';
import type { LaborRate } from 'shared/api';

type FormData = {
  isDefault: boolean;
  name: string;
  rate: string;
};

type Props = {
  defaultLaborRate?: LaborRate;
  showHelperText?: boolean;
  onAdd?: (laborRateId: string) => void;
  onClose: () => void;
};

export const EditLaborRateModal = ({ defaultLaborRate, onClose, showHelperText, onAdd }: Props) => {
  const { laborRates } = useGetLaborRates();
  const [createLaborRate, createLaborRateStatus] = useCreateLaborRateMutation();
  const [updateLaborRate, updateLaborRateStatus] = useUpdateLaborRateMutation();

  const isLoading = createLaborRateStatus.isLoading || updateLaborRateStatus.isLoading;
  const createdId = createLaborRateStatus.data?.createLaborRate?.laborRate?.id;

  const form = useForm<FormData>({
    defaultValues: {
      name: defaultLaborRate?.name || '',
      rate: defaultLaborRate?.rate.toFixed(2) || '',
      isDefault: defaultLaborRate?.isDefault || false,
    },
  });

  const isAdding = !defaultLaborRate;
  const { clearErrors, formState } = form;

  const handleCreateSuccess = useCallback(() => {
    if (createdId && onAdd) {
      onAdd(createdId);
    }

    onClose();
  }, [createdId, onAdd, onClose]);

  const handleSubmit = useCallback(
    async (values: FormData) => {
      if (isAdding) {
        await createLaborRate({
          input: {
            name: values.name,
            rate: Number(values.rate),
            isDefault: laborRates.length === 0 || values.isDefault,
          },
        });
      } else {
        await updateLaborRate({
          input: {
            ...values,
            id: defaultLaborRate.id,
            rate: Number(values.rate),
          },
        });
      }
    },
    [createLaborRate, defaultLaborRate?.id, isAdding, laborRates.length, updateLaborRate]
  );

  useFormStatus<FormData>({
    form,
    status: defaultLaborRate ? updateLaborRateStatus : createLaborRateStatus,
    onSuccess: handleCreateSuccess,
  });

  return (
    <ModalView title={isAdding ? 'Add labor rate' : 'Edit labor rate'} onClose={onClose}>
      <HookForm form={form} onSubmit={handleSubmit} onChange={() => clearErrors()}>
        {showHelperText && (
          <Box mb={6}>
            <Typography>
              Labor rates will be stored in My shop settings under the Retail pricing & labor tab.
            </Typography>
          </Box>
        )}

        <FormTextField
          name="name"
          label="Name"
          hideRequiredMark
          rules={{
            required: errorMessages.required,
            maxLength: {
              value: 50,
              message: 'Name must be 50 characters or less',
            },
          }}
        />

        <Box mt={2} mb={4} width={60}>
          <FormTextField
            name="rate"
            label="Labor rate"
            type="number"
            prefix="$"
            precision={2}
            rules={{
              required: errorMessages.required,
              min: {
                value: 0.01,
                message: 'Labor rate must be more than $0',
              },
              max: {
                value: 9999.99,
                message: 'Labor rate must be less than $10,000',
              },
            }}
          />
        </Box>

        <FormCheckBoxField name="isDefault" data-testid="checkbox" disabled={defaultLaborRate?.isDefault}>
          <Typography variant="bodyText2">Set as default</Typography>
        </FormCheckBoxField>

        <ModalButtons>
          <Button
            type="submit"
            data-testid="submitButton"
            disabled={
              (!isAdding && !formState.isDirty) || Boolean(formState.errors.name || formState.errors.rate) || isLoading
            }
          >
            {isAdding ? 'Add labor rate' : 'Save changes'}
          </Button>
          <Button variant="text" onClick={onClose}>
            Cancel
          </Button>
        </ModalButtons>
      </HookForm>
    </ModalView>
  );
};
