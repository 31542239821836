import { css } from '@emotion/react';
import { Box, GraphicButton, Loader, Scrollbar } from '@partstech/ui';
import { useCallback } from 'react';

const styles = {
  scrollBar: () => css`
    height: 100%;
    display: flex;
  `,
};

type Props = {
  isFetching: boolean;
  onChange: (value: string) => void;
  options: { icon?: string; value: string }[];
  title: React.ReactNode;
};

export const GraphicalList = ({ isFetching, onChange, options, title }: Props) => {
  const handleClick = useCallback(
    (value: string) => () => {
      onChange(value);
    },
    [onChange]
  );

  return (
    <Scrollbar css={styles.scrollBar}>
      <Box display="flex" height="100%" width="100%" alignItems={{ sm: 'flex-start', md: 'center' }}>
        <Box width="100%" mb={{ sm: 0, md: 20 }}>
          {title && (
            <Box textAlign="center" width="100%" mb={8}>
              {title}
            </Box>
          )}

          {isFetching && <Loader isCentered />}

          {!isFetching && (
            <Box
              display="flex"
              flexDirection={{ sm: 'column', md: 'row' }}
              justifyContent={{ sm: 'flex-start', md: 'center' }}
            >
              {options?.map((option) => (
                <GraphicButton
                  allowIconEffects={false}
                  borderColor="mono6"
                  cardHeight={20}
                  cardWidth={{ sm: '100%', md: 33 }}
                  key={option.value}
                  mx={{ sm: 0, md: 2 }}
                  my={{ sm: 2, md: 0 }}
                  onClick={handleClick(option.value)}
                  imageSrc={option.icon}
                  caption={option.value}
                  data-testid="listItem"
                />
              ))}
            </Box>
          )}
        </Box>
      </Box>
    </Scrollbar>
  );
};
