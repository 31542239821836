/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import { SubscriptionFragmentDoc } from '../../queries/subscription/Subscription.generated';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type UpdateSubscriptionMutationVariables = Types.Exact<{
  input: Types.UpdateSubscriptionInput;
}>;

export type UpdateSubscriptionMutation = {
  __typename?: 'Mutation';
  updateSubscription?: {
    __typename?: 'UpdateSubscriptionPayload';
    shopSubscription?: {
      __typename?: 'ShopSubscription';
      amount: Types.Scalars['BigDecimal']['input'];
      currentPeriodEnd?: Types.Scalars['DateTime']['input'] | null;
      period: Types.SubscriptionPlanPeriod;
      startedAt?: Types.Scalars['DateTime']['input'] | null;
      plans?: Array<{
        __typename?: 'SubscriptionSubscriptionPlan';
        downgradeProductAlias?: Types.SubscriptionProductAlias | null;
        status: Types.SubscriptionSubscriptionPlanStatus;
        account?: { __typename?: 'Account'; id: string } | null;
        plan?: {
          __typename?: 'SubscriptionPlan';
          amount: Types.Scalars['BigDecimal']['input'];
          id: string;
          product?: {
            __typename?: 'SubscriptionProduct';
            alias: Types.SubscriptionProductAlias;
            isPlan: Types.Scalars['Boolean']['input'];
            unsubscribeReasons?: Array<Types.Scalars['String']['input']> | null;
          } | null;
        } | null;
      }> | null;
    } | null;
  } | null;
};

export const UpdateSubscriptionDocument = `
    mutation UpdateSubscription($input: UpdateSubscriptionInput!) {
  updateSubscription(input: $input) {
    shopSubscription {
      ...Subscription
    }
  }
}
    ${SubscriptionFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    UpdateSubscription: build.mutation<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>({
      query: (variables) => ({ document: UpdateSubscriptionDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUpdateSubscriptionMutation } = injectedRtkApi;
