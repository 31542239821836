import { EnginesList } from '../EnginesList';
import { MakesList } from '../MakesList';
import { ModelsList } from '../ModelsList';
import { SubmodelsList } from '../SubmodelsList';
import { YearsList } from '../YearsList';
import type { VehicleParameters } from '../../../types';
import type { CommonParametersListProps, VehicleField } from '../types';

type Props = {
  activeField: VehicleField | null;
  searchValues: VehicleParameters;
  onReset: () => void;
} & Omit<CommonParametersListProps, 'searchValue'>;

const componentMap: Record<VehicleField, (props: CommonParametersListProps) => JSX.Element> = {
  engineId: EnginesList,
  submodelId: SubmodelsList,
  modelId: ModelsList,
  makeId: MakesList,
  year: YearsList,
};

export const ParametersList = ({ activeField, searchValues, onReset, ...props }: Props) => {
  if (!activeField) {
    return null;
  }

  const ListComponent = componentMap[activeField];

  return <ListComponent searchValue={searchValues[activeField]} {...props} />;
};
