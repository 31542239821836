import { isNotNull } from '@partstech/ui/utils';
import type { LaborApplication } from 'models';
import type { SubmittedLabor } from 'shared/api';

export const getSubmittedLabor = (laborApplications: LaborApplication[]): SubmittedLabor[] =>
  laborApplications
    .map((application) =>
      application.orderItemId
        ? {
            applicationId: application.applicationId,
            duration: application.duration,
            orderItemId: application.orderItemId,
          }
        : null
    )
    .filter(isNotNull);
