import { Box, Typography } from '@partstech/ui';
import { useMemo } from 'react';
import { findAttributeByName, getTireQualityTemperature, getTireQualityTraction, getTireQualityTreadwear } from 'utils';
import type { ProductAttribute } from 'types/product';

type Props = {
  attributes: ProductAttribute[];
};

export const TireQualityTranslation = ({ attributes }: Props) => {
  const tireQuality = useMemo(() => findAttributeByName('TiresQuality', attributes)?.value, [attributes]);

  const [tireQualityTreadwear, tireQualityTraction, tireQualityTemperature] = tireQuality ? tireQuality.split(' ') : [];

  return (
    <>
      {tireQualityTreadwear && (
        <>
          <Typography variant="overline" color="white">
            TREADWEAR{' '}
          </Typography>
          <Typography variant="overline" color="white" component="div">
            {tireQualityTreadwear}:{' '}
            <Typography variant="caption" color="white">
              {getTireQualityTreadwear(tireQualityTreadwear)}{' '}
            </Typography>
          </Typography>
          <Box mb={2} />
        </>
      )}
      {tireQualityTraction && (
        <>
          <Typography variant="overline" color="white">
            TRACTION{' '}
          </Typography>
          <Typography variant="overline" color="white" component="div">
            {tireQualityTraction}:{' '}
            <Typography variant="caption" color="white">
              {getTireQualityTraction(tireQualityTraction)}{' '}
            </Typography>
          </Typography>
          <Box mb={2} />
        </>
      )}
      {tireQualityTemperature && (
        <>
          <Typography variant="overline" color="white">
            TEMPERATURE{' '}
          </Typography>
          <Typography variant="overline" color="white" component="div">
            {tireQualityTemperature}:{' '}
            <Typography variant="caption" color="white">
              {getTireQualityTemperature(tireQualityTemperature)}
            </Typography>
          </Typography>
        </>
      )}
    </>
  );
};
