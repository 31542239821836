import { css } from '@emotion/react';
import { Icon } from '@partstech/ui';
import type { Theme } from '@partstech/ui';

type Size = 'small' | 'default' | 'large';

const widthMap: Record<Size, number> = {
  large: 27,
  default: 14,
  small: 10,
};

const heightMap: Record<Size, number> = {
  large: 14,
  default: 10,
  small: 8,
};

const styles = {
  iconWrapper: (size: Size, disableBorder: boolean) => (theme: Theme) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin-right: ${theme.sizing(2)};
    height: auto;
    ${!disableBorder &&
    ` 
      width: ${theme.sizing(widthMap[size])};
      height: ${theme.sizing(heightMap[size])};
      background: ${theme.color.mono2};
      border-radius: ${theme.sizing(size === 'small' ? 1 : 2)};
      border: 1px solid ${theme.color.mono17};
    `}
    ${size === 'large' && 'i { font-size: 40px; }'}
  `,
};

type Props = {
  size?: Size;
  disableBorder?: boolean;
};

export const LaborIcon = ({ size = 'default', disableBorder = false }: Props) => (
  <div css={styles.iconWrapper(size, disableBorder)}>
    <Icon name="timer" variant="outlined" color="subtleText" size="large" />
  </div>
);
