import { Banner, Box, ImageFallback, Tooltip, Typography } from '@partstech/ui';
import freeShippingIcon from 'images/icons/free-shipping.svg';
import type { BannerProps, TooltipProps } from '@partstech/ui';

type Props = {
  messages?: {
    label?: string | null;
    text?: string | null;
  }[];
  color?: 'positive' | 'secondary' | 'mix';
  hasIcon?: boolean;
  variant?: 'default' | 'preview';
  tooltip?: string;
  tooltipProps?: Omit<TooltipProps, 'tooltip'>;
} & Omit<BannerProps, 'variant' | 'color'>;

export const EtaBanner = ({
  variant = 'default',
  messages = [],
  hasIcon = false,
  color = 'secondary',
  tooltip,
  tooltipProps,
  ...bannerProps
}: Props) => {
  const bannerVariant: BannerProps['variant'] = color === 'secondary' ? 'warning' : 'success';

  if (messages.length === 0) {
    return null;
  }

  if (variant === 'preview') {
    return (
      <Tooltip
        tooltip={
          <Box width={60} data-testid="etaTooltip">
            {messages.map((message, index) => (
              <Typography key={message.label} color="white" component="span">
                {message.label}: <strong>{message.text}</strong>
                {index === messages.length - 1 ? '' : ', '}
              </Typography>
            ))}
          </Box>
        }
        {...tooltipProps}
      >
        <Banner radius={4} variant={bannerVariant} borderWidth={0} px={2} py={1} {...bannerProps}>
          <Box display="flex" gap={1}>
            {hasIcon && <ImageFallback height={16} alt="shipping icon" src={freeShippingIcon} />}

            <Typography variant="overline" color={color === 'positive' ? 'positive' : 'secondaryDark10'}>
              ETA
            </Typography>
          </Box>
        </Banner>
      </Tooltip>
    );
  }

  return (
    <Tooltip tooltip={tooltip} isHidden={!tooltip} {...tooltipProps}>
      <Banner radius={4} variant={bannerVariant} borderWidth={0} px={2} py={1} {...bannerProps}>
        <Box display="flex" gap={2}>
          {hasIcon && <ImageFallback height={20} alt="shipping icon" src={freeShippingIcon} />}

          <div>
            {messages.map((message, index) => (
              <Typography
                key={index}
                variant="bodyText2"
                color={color === 'secondary' ? 'secondaryDark10' : 'positive'}
                component="span"
              >
                {message.label}:{' '}
                <Typography
                  variant="subtitle2"
                  color={color === 'secondary' || (color === 'mix' && index === 1) ? 'secondaryDark10' : 'positive'}
                  component="span"
                >
                  {message.text}
                </Typography>
                {index === messages.length - 1 ? '' : ', '}
              </Typography>
            ))}
          </div>
        </Box>
      </Banner>
    </Tooltip>
  );
};
