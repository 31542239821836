import { useToggle } from '@partstech/ui/hooks';
import { useCallback, useMemo } from 'react';
import { useStartSearch } from 'features/searchForm';
import { useSearchParams } from 'hooks/search';
import { SearchBarContextProvider } from './SearchBarContext';
import { useSearchBarState } from './useSearchBarState';
import type { SearchEntryPointInterface } from 'features/searchForm';
import type { Vehicle } from 'models';
import type { FunctionComponent, PropsWithChildren } from 'react';

export const SearchBarProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { isToggle: isSelectVehicleOpen, turnOn: openVehicleSelector, turnOff: closeVehicleSelector } = useToggle();

  const {
    searchEntry: defaultSearchEntry,
    vehicle: defaultVehicle,
    type: defaultSearchType,
    selectedSupplierId,
    isDealersSearch,
  } = useSearchParams();

  const startSearch = useStartSearch({ onVehicleRequired: openVehicleSelector });

  const handleSearchBarStateChange = useCallback(
    (entry: SearchEntryPointInterface | null, vehicle: Vehicle | null) => {
      const preservedQueryParams = {
        selected_distributor: selectedSupplierId ?? undefined,
        dealers: isDealersSearch ? '1' : undefined,
      };

      startSearch(entry, vehicle, preservedQueryParams);
    },
    [isDealersSearch, selectedSupplierId, startSearch]
  );

  const {
    searchEntry,
    vehicle,
    changeSearchEntry,
    changeVehicle,
    searchType,
    changeSearchType,
    changeEntryPointsTab,
    entryPointsTab,
    resetSearch,
  } = useSearchBarState({
    searchEntry: defaultSearchEntry,
    vehicle: defaultVehicle,
    type: defaultSearchType,
    onChange: handleSearchBarStateChange,
  });

  const value = useMemo(
    () => ({
      isSelectVehicleOpen,
      openVehicleSelector,
      closeVehicleSelector,

      changeEntryPointsTab,
      changeSearchEntry,
      changeSearchType,
      changeVehicle,
      entryPointsTab,
      searchEntry,
      searchFormType: searchType,
      startSearch,
      vehicle,
      resetSearch,
    }),
    [
      isSelectVehicleOpen,
      openVehicleSelector,
      closeVehicleSelector,
      changeEntryPointsTab,
      changeSearchEntry,
      changeSearchType,
      changeVehicle,
      entryPointsTab,
      searchEntry,
      searchType,
      startSearch,
      vehicle,
      resetSearch,
    ]
  );

  return <SearchBarContextProvider value={value}>{children}</SearchBarContextProvider>;
};
