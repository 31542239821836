import { css } from '@emotion/react';
import { Box, IconText } from '@partstech/ui';
import type { Theme } from '@partstech/ui';

const styles = {
  preferredLabel: (theme: Theme) => css`
    border-radius: ${theme.sizing(2, 2, 0, 0)};
  `,
};

export const PreferredBrandLabel = () => (
  <Box
    background="primaryLight10"
    position="relative"
    zIndex="addition"
    textAlign="center"
    mb={-2}
    px={1}
    height={6}
    css={styles.preferredLabel}
  >
    <IconText icon="loyalty" textTransform="uppercase" color="white">
      Preferred brand
    </IconText>
  </Box>
);
