import { Box, Button, useMedia } from '@partstech/ui';
import { HookForm } from '@partstech/ui/forms';
import { FormFields } from './FormFields';
import { ParametersList } from './ParametersList';
import { useGetVehiclesByParameters } from './useGetVehiclesByParameters';
import { useVehicleParameters } from './useVehicleParameters';
import { useYMMForm } from './useYMMForm';
import type { VehicleParameters } from '../../types';
import type { BoxProps } from '@partstech/ui';
import type { Vehicle } from 'models';

type Props = {
  value?: Vehicle | null;
  isActive?: boolean;
  vehicleParameters?: VehicleParameters;
  onChange?: (value: Vehicle | null) => void;
} & BoxProps;

export const YearMakeModelForm = ({
  isActive,
  value,
  vehicleParameters: initialVehicleParameters,
  onChange,
  ...props
}: Props) => {
  const { isMobile } = useMedia();

  const { vehicleParameters, setVehicleParameters, resetVehicleParameters } = useVehicleParameters({
    isActive,
    vehicle: value,
    vehicleParameters: initialVehicleParameters,
  });

  const { vehicles } = useGetVehiclesByParameters(vehicleParameters);

  const {
    form,
    onFocus,
    onReset,
    onChange: handleChange,
    activeField,
    isUserFocused,
    formValues,
    onInputChange,
  } = useYMMForm({
    vehicleParameters,
    setVehicleParameters,
    resetVehicleParameters,
    vehicles,
    value,
    isActive,
    onChange,
  });

  return (
    <Box {...props}>
      <HookForm form={form} data-testid="ymmForm">
        <Box display="flex" flexDirection="column" width="100%" minWidth={{ sm: '100%', md: 155 }}>
          <FormFields {...vehicleParameters} onFocus={onFocus} onInputChange={onInputChange} />

          {isActive && (
            <ParametersList
              {...vehicleParameters}
              isUserFocused={isUserFocused}
              searchValues={formValues}
              onReset={onReset}
              activeField={activeField}
              onChange={handleChange}
            />
          )}

          {activeField !== 'year' && (
            <Box display="flex" justifyContent="center" mt={{ md: 1 }}>
              <Button
                iconSize={isMobile ? undefined : 'large'}
                leadingIcon={isMobile ? undefined : 'close'}
                onClick={onReset}
                size="dense"
                variant={isMobile ? 'secondary' : 'text'}
              >
                Clear vehicle
              </Button>
            </Box>
          )}
        </Box>
      </HookForm>
    </Box>
  );
};
