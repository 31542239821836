import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { googleLogin as googleLoginApi, login as loginApi, logout as logoutApi } from 'shared/api/rest/gen/shop';
import { createAppAsyncThunk } from 'store/utils';
import type { ResponseError } from 'shared/api';
import type { GoogleLoginQuery, LoginRequest } from 'shared/api/rest/gen/shop';
import type { RootState } from 'store';

export const login = createAppAsyncThunk<null, LoginRequest>(
  'features/user/account/login',
  async (payload, { rejectWithValue }) => {
    try {
      await loginApi(payload);

      return null;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const googleLogin = createAppAsyncThunk<null, GoogleLoginQuery>(
  'features/user/account/googleLogin',
  async (payload, { rejectWithValue }) => {
    try {
      await googleLoginApi(payload);

      return null;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const logout = createAsyncThunk('features/user/account', logoutApi);

type UserState = {
  isForbiddenDialogOpen?: boolean;
  loginStatuses: { isSuccess: boolean; isLoading: boolean; error: ResponseError | null };
  googleLoginStatuses: { isSuccess: boolean; isLoading: boolean; error: ResponseError | null };
};

const initialState: UserState = {
  isForbiddenDialogOpen: false,
  loginStatuses: { isSuccess: false, isLoading: false, error: null },
  googleLoginStatuses: { isSuccess: false, isLoading: false, error: null },
};

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    showForbiddenDialog: (state) => {
      state.isForbiddenDialogOpen = true;
    },
    closeForbiddenDialog: (state) => {
      state.isForbiddenDialogOpen = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loginStatuses = { isSuccess: false, isLoading: true, error: null };
      })
      .addCase(login.fulfilled, (state) => {
        state.loginStatuses = { isSuccess: true, isLoading: false, error: null };
      })
      .addCase(login.rejected, (state, action) => {
        state.loginStatuses = { isSuccess: false, isLoading: false, error: action.payload ?? null };
      })

      .addCase(googleLogin.pending, (state) => {
        state.googleLoginStatuses = { isSuccess: false, isLoading: true, error: null };
      })
      .addCase(googleLogin.fulfilled, (state) => {
        state.googleLoginStatuses = { isSuccess: true, isLoading: false, error: null };
      })
      .addCase(googleLogin.rejected, (state, action) => {
        state.googleLoginStatuses = { isSuccess: false, isLoading: false, error: action.payload ?? null };
      })

      .addCase(logout.fulfilled, () => initialState);
  },
});

export const { closeForbiddenDialog, showForbiddenDialog } = accountSlice.actions;

export const account = accountSlice.reducer;

const selectAccount = (state: RootState) => state.features.user.account;

export const selectIsForbiddenDialogOpen = createSelector([selectAccount], (state) => state.isForbiddenDialogOpen);

export const selectLoginStatuses = createSelector([selectAccount], (state) => state.loginStatuses);

export const selectGoogleLoginStatuses = createSelector([selectAccount], (state) => state.googleLoginStatuses);
