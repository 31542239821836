import { css } from '@emotion/react';
import { Divider, Scrollbar } from '@partstech/ui';
import { useMounted } from '@partstech/ui/hooks';
import { elevationShadow } from '@partstech/ui/utils';
import { useCallback, useEffect, useRef, useState } from 'react';
import type { Theme } from '@partstech/ui';
import type { PropsWithChildren } from 'react';

const styles = {
  container: css`
    overflow: hidden;
  `,
  scrollbar: (maxHeight: number) => (theme: Theme) => css`
    max-height: ${theme.sizing(maxHeight)};
  `,
  divider: (hasShadow: boolean) => css`
    ${hasShadow && elevationShadow(1)}
  `,
};

type Props = {
  maxHeight: number;
};

/** @todo Use ScrollableContainer from ui */
export const ScrollableContainer = ({ maxHeight, children }: PropsWithChildren<Props>) => {
  const scrollbarRef = useRef<HTMLDivElement>(null);
  const [isTop, setIsTop] = useState(true);
  const [isBottom, setIsBottom] = useState(true);
  const isMounted = useMounted();

  const handleYReachStart = useCallback(() => setIsTop(true), []);

  const handleYReachEnd = useCallback(() => setIsBottom(true), []);

  const handleScroll = useCallback(() => {
    setIsTop(false);
    setIsBottom(false);
  }, []);

  useEffect(() => {
    if (scrollbarRef.current && scrollbarRef.current.scrollHeight > scrollbarRef.current.clientHeight) {
      setIsBottom(false);
    }
  }, [isMounted]);

  return (
    <div css={styles.container}>
      <Divider css={styles.divider(!isTop)} />
      <Scrollbar
        ref={scrollbarRef}
        css={styles.scrollbar(maxHeight)}
        onYReachStart={handleYReachStart}
        onYReachEnd={handleYReachEnd}
        onScrollY={handleScroll}
      >
        {children}
      </Scrollbar>
      <Divider css={styles.divider(!isBottom)} />
    </div>
  );
};
