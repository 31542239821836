import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { ModalResolver, PageProvider, SnackbarProvider, UIProvider, defaultThemeSettings } from '@partstech/ui';
import { memo, useMemo } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { AppNavigationHandler } from 'app/AppRouter/NavigationHandler';
import { Outlet } from 'app/AppRouter/Outlet';
import { RouterLink } from 'app/AppRouter/RouterLink';
import { BroadcastChannelProvider } from 'app/BroadcastChannelProvider';
import { environment } from 'app/config';
import { AppProvider } from 'app/contexts/AppProvider';
import { PWAProvider } from 'app/contexts/PWAProvider';
import { ErrorBoundary } from 'app/ErrorBoundary';
import { GlobalStyles } from 'app/GlobalStyles';
import { HtmlHead } from 'app/HtmlHead';
import { GTMKey } from 'constant';
import { withProfiler } from 'integrations/sentry';
import type { ISnippetsParams } from '@elgorditosalsero/react-gtm-hook/dist/models/GoogleTagManager';
import type { PropsWithChildren } from 'react';

const MainLayout = memo(({ children }: PropsWithChildren) => {
  const navigate = useNavigate();
  const history = useMemo(() => ({ push: navigate }), [navigate]);

  const gtmParams: ISnippetsParams = {
    id: environment === 'production' ? GTMKey.production : GTMKey.beta,
    injectScript: process.env.NODE_ENV !== 'test',
  };

  return (
    <HelmetProvider>
      <GTMProvider state={gtmParams}>
        <BroadcastChannelProvider>
          <UIProvider routerLink={RouterLink} theme={defaultThemeSettings}>
            <PWAProvider>
              <AppNavigationHandler>
                <ErrorBoundary showButton>
                  <PageProvider history={history}>
                    <HtmlHead />
                    <SnackbarProvider>
                      <AppProvider>
                        <Outlet>{children}</Outlet>
                        <ModalResolver />
                      </AppProvider>
                    </SnackbarProvider>
                  </PageProvider>
                </ErrorBoundary>
                <GlobalStyles />
              </AppNavigationHandler>
            </PWAProvider>
          </UIProvider>
        </BroadcastChannelProvider>
      </GTMProvider>
    </HelmetProvider>
  );
});

export const ProfiledMainLayout = withProfiler(MainLayout);
