import { useCallback, useState } from 'react';

type Args = {
  onSubmit: () => Promise<void>;
};

export const useDuplicateShop = ({ onSubmit }: Args) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleCreateShop = useCallback(async () => {
    setIsLoading(true);

    await onSubmit();

    setIsLoading(false);
  }, [onSubmit]);

  return { createShop: handleCreateShop, isLoading };
};
