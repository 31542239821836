import { css } from '@emotion/react';
import { Box } from '@partstech/ui';
import { Outlet } from 'app/AppRouter/Outlet';
import type { PropsWithChildren } from 'react';

const styles = {
  wrapper: css`
    overflow: hidden;

    @media print {
      overflow: visible;
    }
  `,
};

export const QuoteLayout = ({ children }: PropsWithChildren) => (
  <Box background="white" minHeight="100%" css={styles.wrapper} data-testid="quotePage">
    <Outlet>{children}</Outlet>
  </Box>
);
