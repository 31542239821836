/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import { RecentVehicleFragmentDoc } from './RecentVehicleFragment.generated';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type GetRecentVehiclesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetRecentVehiclesQuery = {
  __typename?: 'Query';
  currentUser?: {
    __typename?: 'User';
    activeMember?: {
      __typename?: 'ShopMember';
      shop?: {
        __typename?: 'Shop';
        recentVehicles?: Array<{
          __typename?: 'RecentVehicle';
          id: string;
          vin?: Types.Scalars['String']['input'] | null;
          state?: Types.Scalars['String']['input'] | null;
          plate?: Types.Scalars['String']['input'] | null;
          vehicle?: {
            __typename?: 'Vehicle';
            id: string;
            type: Types.VehicleType;
            regions?: Array<Types.VehicleRegion> | null;
            year: number;
            make: { __typename?: 'VehicleMake'; id: string; name: Types.Scalars['String']['input']; priority: number };
            model: { __typename?: 'VehicleModel'; id: string; name: Types.Scalars['String']['input'] };
            subModel: { __typename?: 'VehicleSubModel'; id: string; name: Types.Scalars['String']['input'] };
            engine: { __typename?: 'VehicleEngine'; id: string; name: Types.Scalars['String']['input'] };
          } | null;
        }> | null;
      } | null;
    } | null;
  } | null;
};

export const GetRecentVehiclesDocument = `
    query GetRecentVehicles {
  currentUser {
    activeMember {
      shop {
        recentVehicles {
          ...RecentVehicle
        }
      }
    }
  }
}
    ${RecentVehicleFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetRecentVehicles: build.query<GetRecentVehiclesQuery, GetRecentVehiclesQueryVariables | void>({
      query: (variables) => ({ document: GetRecentVehiclesDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetRecentVehiclesQuery, useLazyGetRecentVehiclesQuery } = injectedRtkApi;
