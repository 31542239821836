import { useDebouncedCallback } from '@partstech/ui/hooks';
import { useCallback, useEffect, useRef, useState } from 'react';
import type { Option } from '@partstech/ui';
import type { ChangeEventHandler } from 'react';

const DEFAULT_PER_PAGE = 10;
const DEFAULT_DEBOUNCE_DELAY = 300;

type DynamicOptionsRequest = {
  keyword: string;
  page: number;
  perPage: number;
};

export type DynamicOptionsFetch = (request: DynamicOptionsRequest) => Promise<Option[]> | Option[];

type DynamicOptionsConfig = { perPage?: number; debounceDelay?: number };

const emptyOptions: Option[] = [];

export const useDynamicOptions = (
  fetchFn: DynamicOptionsFetch,
  { perPage = DEFAULT_PER_PAGE, debounceDelay = DEFAULT_DEBOUNCE_DELAY }: DynamicOptionsConfig = {}
) => {
  const [options, setOptions] = useState<Option[]>();
  const [isFetching, setIsFetching] = useState(false);

  const lastRequest = useRef<DynamicOptionsRequest>();
  const lastPageReached = useRef(false);
  const responseLength = useRef<number>(0);

  const handleFetch = useCallback(
    async (keyword: string) => {
      const { keyword: lastKeyword } = lastRequest.current ?? {};
      if (lastKeyword === keyword) {
        return;
      }

      setIsFetching(true);

      lastPageReached.current = false;
      lastRequest.current = { keyword, page: 1, perPage };

      const fetchedOptions = await fetchFn(lastRequest.current);

      responseLength.current = fetchedOptions.length;

      setOptions(fetchedOptions);
      setIsFetching(false);
    },
    [fetchFn, perPage]
  );

  const debouncedHandleFetch = useDebouncedCallback(handleFetch, debounceDelay);

  const handleFetchNextPage = useCallback(async () => {
    if (lastPageReached.current) {
      return;
    }

    setIsFetching(true);

    const { keyword = '', page = 1 } = lastRequest.current ?? {};
    lastRequest.current = { keyword, page: page + 1, perPage };

    const fetchedOptions = await fetchFn(lastRequest.current);

    if (!fetchedOptions.length || fetchedOptions.length < responseLength.current) {
      lastPageReached.current = true;
    }

    setOptions((prev = []) => [...prev, ...fetchedOptions]);
    setIsFetching(false);
  }, [fetchFn, perPage]);

  const onSearchInputChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      setIsFetching(true);
      debouncedHandleFetch(e.target.value);
    },
    [debouncedHandleFetch]
  );

  const onClear = useCallback(() => {
    handleFetch('');
  }, [handleFetch]);

  useEffect(() => {
    if (!options) {
      handleFetch('');
    }
  }, [options, handleFetch]);

  return {
    onSearchInputChange,
    onClear,
    onScrollMore: handleFetchNextPage,
    options: options ?? emptyOptions,
    fetching: isFetching,
  };
};
