import { Button } from '@partstech/ui';
import { CurrentPlanButton } from './CurrentPlanButton';
import { DowngradingButton } from './DowngradingButton';
import type { ButtonProps } from '@partstech/ui';

export type ControlButtonProps = ButtonProps & {
  canDowngrade: boolean;
  canReactivate: boolean;
  isActive: boolean;
  isDowngrading: boolean;
};

export const ControlButton = ({
  canDowngrade,
  canReactivate,
  isActive,
  isDowngrading,
  ...props
}: ControlButtonProps) => {
  const commonProps: ButtonProps = { fullWidth: true, size: 'dense', variant: 'secondary', ...props };

  if (isDowngrading) {
    return <DowngradingButton {...commonProps} />;
  }

  if (canDowngrade) {
    return <Button {...commonProps}>Downgrade</Button>;
  }

  if (canReactivate) {
    return <Button {...commonProps}>Re-subscribe</Button>;
  }

  if (isActive) {
    return <CurrentPlanButton {...commonProps} />;
  }

  return <Button {...commonProps}>Select</Button>;
};
