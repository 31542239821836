import { memo, useMemo } from 'react';
import { isDevEnvironment } from 'app/config';
import { useFullStorySetUserVars } from '../../lib/useFullStorySetUserVars';
import { useIdentifyFullstory } from '../../lib/useIdentifyFullstory';
import { useTracking } from '../../lib/useTracking';
import { FullStoryContextProvider } from './FullStoryContext';
import type { IdentifyFullstoryArgs } from '../../lib/useIdentifyFullstory';
import type { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{ userContext: IdentifyFullstoryArgs & { shopId: string } }>;

export const FullStoryProvider = memo(({ userContext, children }: Props) => {
  const isIdentified = useIdentifyFullstory(userContext, { skip: !userContext.id, isDevMode: isDevEnvironment() });

  const sendEvent = useTracking(
    { shopID: userContext.shopId, userID: userContext.id },
    { isDevMode: isDevEnvironment() }
  );

  const setUserVars = useFullStorySetUserVars({ isDevMode: isDevEnvironment() });

  const contextValue = useMemo(
    () => ({ sendEvent, setUserVars, isIdentified }),
    [sendEvent, setUserVars, isIdentified]
  );

  return <FullStoryContextProvider value={contextValue}>{children}</FullStoryContextProvider>;
});
