import { ImageFallback } from '@partstech/ui';
import { VehicleRequiredView } from 'components/VehicleRequiredView';
import diagramsImg from 'images/icons/search-tabs/diagrams.svg';
import { VehicleDiagrams } from './VehicleDiagrams';
import type { SearchEntryPointInterface } from 'features/searchForm';
import type { Diagram } from 'models';

type Props = {
  hasVehicle: boolean;
  options: Diagram[];
  onChange: (option: Diagram) => void;
  onSelect: (option: SearchEntryPointInterface) => void;
  onSelectVehicleClick?: () => void;
  isKeyboardDisabled?: boolean;
};

export const Diagrams = ({
  hasVehicle,
  isKeyboardDisabled,
  onSelectVehicleClick,
  onChange,
  onSelect,
  options,
}: Props) => (
  <VehicleRequiredView
    width="100%"
    hasOptions={options.length > 0}
    hasVehicle={hasVehicle}
    onSelectVehicleClick={onSelectVehicleClick}
    noVehicleText="A vehicle must be selected in order to view diagrams."
    noOptionsIcon={<ImageFallback src={diagramsImg} height="40px" width="40px" />}
    noOptionsText="There are currently no diagrams available for the vehicle you selected."
  >
    <VehicleDiagrams
      options={options}
      onChange={onChange}
      onSelect={onSelect}
      isKeyboardDisabled={isKeyboardDisabled}
    />
  </VehicleRequiredView>
);
