import { SearchTypes } from 'types/search';
import type { SearchParams } from 'types/search';

export const getSearchTypeBySearchParams = (
  searchParams: Pick<SearchParams, 'tire_id' | 'tire_sizes' | 'type'>
): SearchTypes => {
  if (searchParams.tire_id || searchParams.tire_sizes || searchParams.type === SearchTypes.TIRES) {
    return SearchTypes.TIRES;
  }

  return SearchTypes.PARTS;
};
