export const arraySwap = <T>(array: T[], oldIndex: number, newIndex: number) => {
  const result = [...array];

  const a = result[oldIndex];
  const b = result[newIndex];

  if (!a || !b) {
    return result;
  }

  result[newIndex] = a;
  result[oldIndex] = b;

  return result;
};
