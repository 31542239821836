import { isEqual } from '@partstech/ui/utils';
import { useMemo } from 'react';
import { useAppSelector } from 'store';
import { selectLastSearch } from 'store/features/search/settings';
import { useSearchParams } from './useSearchParams';

export const useIsSameSearch = () => {
  const { valuableParams } = useSearchParams();
  const lastSearch = useAppSelector(selectLastSearch);

  return useMemo(() => isEqual(lastSearch, valuableParams), [lastSearch, valuableParams]);
};
