import { api as generatedApi } from './CreatePaymentCard.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    CreatePaymentCard: {
      invalidatesTags: ['PaymentCard'],
    },
  },
});

export const { useCreatePaymentCardMutation } = api;
