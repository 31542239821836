import { Button } from '@partstech/ui';
import { useCallback } from 'react';
import { useCreateManagementSystem } from 'hooks/shopManagementSystems';

type Props = {
  onSubmit: () => void;
};

export const ContinueWithoutSystemButton = ({ onSubmit }: Props) => {
  const [createManagementSystem, status] = useCreateManagementSystem({ onSuccess: onSubmit });

  const handleCreateShopManagementSystem = useCallback(() => {
    createManagementSystem({ noSms: true });
  }, [createManagementSystem]);

  return (
    <Button
      trailingIcon="arrow_forward"
      variant="text"
      isLoading={status.isLoading}
      onClick={handleCreateShopManagementSystem}
    >
      Continue without a management system
    </Button>
  );
};
