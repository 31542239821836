import { isEmpty } from '@partstech/ui/utils';
import { useMemo } from 'react';
import { useDependentAttribute } from 'features/dynamicAttribute/api/useDependentAttribute';
import { useLaunchDarkly } from 'integrations/launchDarkly';
import { UniversalPartType } from 'models';
import { formatPartTypesQuery, getApplicationPartTypeId } from 'utils';
import { useSearchParams } from './useSearchParams';
import type { DynamicAttributes } from 'types/search';

type UseDynamicAttributesReturn = {
  attributes: DynamicAttributes;
  hasDependentAttribute: boolean;
  isReady: boolean;
  shouldPickAttributes: boolean;
};

export const useDynamicAttributes = (): UseDynamicAttributesReturn => {
  const {
    flags: { fluidsChemicalsTab },
  } = useLaunchDarkly();

  const { searchParams, partType, isReady, vehicle } = useSearchParams();

  const universalPartType = partType instanceof UniversalPartType ? partType : null;

  const shouldCheckDependentAttribute = useMemo(
    () => Boolean(universalPartType && vehicle && universalPartType.isSingleAttribute() && fluidsChemicalsTab),
    [fluidsChemicalsTab, universalPartType, vehicle]
  );

  const { dependentAttribute, isSuccess } = useDependentAttribute({
    partType: universalPartType,
    skip: !shouldCheckDependentAttribute,
    activeAttributeName: universalPartType?.getFirstAttribute()?.name ?? '',
    previousAttributes: [],
    vehicleId: vehicle?.id ?? null,
  });

  const partTypeId = useMemo(
    () => getApplicationPartTypeId(searchParams.part_text_id, formatPartTypesQuery(searchParams.part_types ?? [])),
    [searchParams.part_text_id, searchParams.part_types]
  );

  const isDynamicAttributeReady = useMemo(() => {
    if (!isReady) {
      return false;
    }

    if (shouldCheckDependentAttribute) {
      return isSuccess;
    }

    return isReady;
  }, [isReady, isSuccess, shouldCheckDependentAttribute]);

  const hasDependentAttribute = useMemo(
    () => (dependentAttribute?.values?.length ?? 0) > 0,
    [dependentAttribute?.values?.length]
  );

  const shouldPickAttributes = useMemo(() => {
    if (partTypeId && isReady && !partType) {
      return true;
    }

    if (partType?.application) {
      if (partType.attributes === null) {
        return false;
      }

      if (fluidsChemicalsTab && universalPartType) {
        if (universalPartType.shouldPickAttributes(Boolean(searchParams.vehicle))) {
          return !searchParams.attributes;
        }

        if (shouldCheckDependentAttribute && isDynamicAttributeReady) {
          if (hasDependentAttribute) {
            return false;
          }

          return !searchParams.attributes;
        }
      }

      if (partType.isUniversalFitted && searchParams.vehicle && isEmpty(partType.attributes)) {
        return false;
      }

      if (partType.isUniversalFitted && !searchParams.vehicle) {
        return !searchParams.attributes;
      }
    }

    return false;
  }, [
    partTypeId,
    isReady,
    partType,
    fluidsChemicalsTab,
    universalPartType,
    searchParams.vehicle,
    searchParams.attributes,
    shouldCheckDependentAttribute,
    isDynamicAttributeReady,
    hasDependentAttribute,
  ]);

  const dynamicAttributes = useMemo(
    () =>
      partType?.attributes
        ?.filter((attr) => attr.required)
        .reduce<DynamicAttributes>(
          (result, attribute) => ({ ...result, [attribute.name]: attribute.values ?? [] }),
          {}
        ) ?? {},
    [partType?.attributes]
  );

  return {
    attributes: dynamicAttributes,
    hasDependentAttribute,
    isReady: isDynamicAttributeReady,
    shouldPickAttributes,
  };
};
