/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import { StockOrderFragmentDoc } from './StockOrderFragment.generated';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type GetStockOrdersQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetStockOrdersQuery = {
  __typename?: 'Query';
  stockOrder?: {
    __typename?: 'StockOrder';
    splitByLastPurchases: Types.Scalars['Boolean']['input'];
    store?: { __typename?: 'Store'; id: string } | null;
    supplier?: { __typename?: 'Supplier'; id: string } | null;
    parts: Array<{
      __typename?: 'StockOrderPart';
      lineCardId?: string | null;
      partNumber: Types.Scalars['String']['input'];
      partNumberId?: Types.Scalars['String']['input'] | null;
      quantity: number;
      selectedIndex?: number | null;
      account?: { __typename?: 'Account'; id: string } | null;
      brand?: { __typename?: 'Brand'; id: string; name: Types.Scalars['String']['input'] } | null;
      store?: { __typename?: 'Store'; id: string } | null;
      supplier?: { __typename?: 'Supplier'; id: string } | null;
    }>;
  } | null;
};

export const GetStockOrdersDocument = `
    query GetStockOrders {
  stockOrder {
    ...StockOrder
  }
}
    ${StockOrderFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetStockOrders: build.query<GetStockOrdersQuery, GetStockOrdersQueryVariables | void>({
      query: (variables) => ({ document: GetStockOrdersDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetStockOrdersQuery, useLazyGetStockOrdersQuery } = injectedRtkApi;
