import { api as generatedApi } from './CreateStockOrderTemplate.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    CreateStockOrderTemplate: {
      invalidatesTags: ['StockOrderTemplate'],
    },
  },
});

export const { useCreateStockOrderTemplateMutation } = api;
