import { useMemo } from 'react';
import { createCartFromSavedCartData } from 'factories';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import { api as generatedApi } from './GetSavedCart.generated';
import type { GetSavedCartQuery } from './GetSavedCart.generated';

export type SavedCartRawData = NonNullable<GetSavedCartQuery['cart']>;
export type PaidOrder = NonNullable<SavedCartRawData['paidOrders']>[0];
export type PaidOrderLine = NonNullable<PaidOrder['lines']>[0];
export type ActiveOrderRawData = NonNullable<SavedCartRawData['orders']>[0];
export type ActiveOrderItemRawData = NonNullable<ActiveOrderRawData['items']>[0];

export type SavedCartAdditionalInfo = {
  customerEmail: string;
  notes: string;
  repairOrderNumber: string;
};

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    GetSavedCart: {
      /** @todo remove forceRefetch after migration cart to graph */
      forceRefetch: () => true,
      providesTags: (response: GetSavedCartQuery | undefined) => {
        const cartId = response?.cart?.id;

        if (cartId) {
          return ['SavedCart', { type: 'SavedCart', id: cartId }];
        }

        return ['SavedCart'];
      },
    },
  },
});

const { useGetSavedCartQuery } = api;

export const useGetSavedCart = (id: string, filterEnhancements?: boolean) => {
  const { hasCurrentUser } = useGetCurrentUser();

  const { data, isLoading } = useGetSavedCartQuery({ id }, { skip: !hasCurrentUser });

  const savedCart = useMemo(
    () => (data?.cart ? createCartFromSavedCartData(data.cart, filterEnhancements) : null),
    [data?.cart, filterEnhancements]
  );

  return {
    savedCart,
    isLoading,
  };
};
