/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import { CardFragmentDoc } from '../../../../shared/api/graphql/fragment/Card.generated';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type CreatePaymentCardMutationVariables = Types.Exact<{
  input: Types.CreateShopCardInput;
}>;

export type CreatePaymentCardMutation = {
  __typename?: 'Mutation';
  createShopCard?: {
    __typename?: 'CreateShopCardPayload';
    card?: {
      __typename?: 'Card';
      brand: Types.Scalars['String']['input'];
      expMonth: number;
      expYear: number;
      name: Types.Scalars['String']['input'];
      owner?: Types.Scalars['String']['input'] | null;
      id: string;
    } | null;
  } | null;
};

export const CreatePaymentCardDocument = `
    mutation CreatePaymentCard($input: CreateShopCardInput!) {
  createShopCard(input: $input) {
    card {
      ...Card
    }
  }
}
    ${CardFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    CreatePaymentCard: build.mutation<CreatePaymentCardMutation, CreatePaymentCardMutationVariables>({
      query: (variables) => ({ document: CreatePaymentCardDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useCreatePaymentCardMutation } = injectedRtkApi;
