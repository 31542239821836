import { TireConfiguration } from '../models/TireConfiguration';
import { TireSize } from '../models/TireSize';
import type { VehicleTire, TireSize as TireSizeType } from 'shared/api';

const rearSize = (size?: TireSizeType | null) => (size ? new TireSize(size) : null);

const createTireConfigurationFromQuery = (tire: VehicleTire) =>
  new TireConfiguration(new TireSize(tire.size), rearSize(tire.rearSize));

export const createTireConfigurationsFromQuery = (tires: VehicleTire[]) => tires.map(createTireConfigurationFromQuery);
