import { useMemo } from 'react';
import { createBaseProductFromQuery } from 'factories';
import { useSupplierAccounts } from 'hooks/supplierAccounts';
import { urlParamsToQuery } from 'utils';
import { useGetTireQuoteQuery } from './GetTireQuote.generated';
import type { GetTireQuoteQuery } from './GetTireQuote.generated';
import type { SupplierAccount } from 'models';
import type { CalculatedItem } from 'shared/api';
import type { ReadonlyQuote } from 'store/features/quote';

const getCalculationType = (
  typename: CalculatedItem['__typename']
): 'LABOR' | 'DISPOSAL' | 'ADDON' | 'FEE' | 'DISCOUNT' | 'FET' => {
  switch (typename) {
    case 'CalculatedAddon':
      return 'ADDON';
    case 'CalculatedDiscount':
      return 'DISCOUNT';
    case 'CalculatedDisposal':
      return 'DISPOSAL';
    case 'CalculatedLabor':
      return 'LABOR';
    case 'CalculatedFee':
      return 'FEE';
    default:
      return 'FET';
  }
};

const createQuoteFromQuery = (
  response: GetTireQuoteQuery['tireQuote'],
  quoteId: string,
  accounts: SupplierAccount[]
) => {
  const quote: ReadonlyQuote = {
    id: quoteId,
    quantity: response?.quantity ?? 0,
    packages: [],
    slots:
      response?.tires.map((tire) => {
        const account = accounts.find((item) => item.id === tire.product.account?.id);
        const product = createBaseProductFromQuery({
          product: tire.product,
          account,
          showRetailPrice: true,
        });

        return {
          product,
          calculation: {
            ...tire.calculations,
            items: tire.calculations.items.map((item) => ({ ...item, type: getCalculationType(item.typename) })),
          },
          label: tire.label,
          isFetched: true,
          isInvalid: false,
          urlParams: urlParamsToQuery({
            partNumberId: product.getPartNumberId(),
            partTypeId: product.partTypeId ? Number(product.partTypeId) : null,
            credentialId: product.credentialId ? Number(product.credentialId) : null,
            supplierId: product.supplier?.id ? Number(product.supplier?.id) : null,
            lineCardId: product.lineCardId,
            vehicleId: product.vehicleId,
          }),
          note: tire.note ?? null,
          customPrice: tire.customerPrice,
        };
      }) ?? [],
    error: null,
    isSuccess: false,
    isLoading: false,
    isShareModalOpen: false,
  };

  return {
    shop: response?.shopInfo,
    isExecuted: response?.executed,
    quote,
  };
};

export const useGetQuote = (quoteId: string, skip?: boolean) => {
  const { data, isLoading, isError, isSuccess } = useGetTireQuoteQuery({ hash: quoteId }, { skip });
  const { accounts } = useSupplierAccounts();

  const tireQuote = useMemo(
    () => (data?.tireQuote ? createQuoteFromQuery(data?.tireQuote, quoteId, accounts) : null),
    [accounts, data?.tireQuote, quoteId]
  );

  return { tireQuote, isLoading, isError, isSuccess };
};
