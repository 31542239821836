import { CURRENT_YEAR } from 'constant';

export const getYearAddedCentury = (year: number, increaseCentury: boolean = false) => {
  const century = Math.floor(CURRENT_YEAR / 100);

  if (increaseCentury && year < CURRENT_YEAR % 100) {
    return (century + 1) * 100 + year;
  }

  return century * 100 + year;
};
