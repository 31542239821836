import type { PackageItems, PercentageAmount } from 'shared/api/rest/gen/shop';
import type { AddonPriceLine, DiscountPriceLine, DisposalPriceLine, FeePriceLine, PriceLine } from 'types/pricePackage';

const getPercentageAmount = (
  priceLine: AddonPriceLine | DisposalPriceLine | FeePriceLine | DiscountPriceLine
): PercentageAmount =>
  priceLine.isPercentage
    ? {
        isPerQuantity: priceLine?.percentageAmount?.isPerQuantity ?? false,
        min: Number(priceLine?.percentageAmount?.min) || null,
        max: Number(priceLine?.percentageAmount?.max) || null,
      }
    : null;

/* This function converts package price lines to schema that is required by REST api. After migration to Graphql this function should be removed */
export const priceLinesToRestApiInput = (priceLines: PriceLine[]): PackageItems[] =>
  priceLines.map((priceLine) => {
    const { type } = priceLine;

    if (type !== 'LABOR') {
      return {
        ...priceLine,
        percentageAmount: getPercentageAmount(priceLine),
      };
    }

    return priceLine;
  });
