/**
 * This function extracts tire sizes from a given string and returns an array of strings in the format "XXX-YY-ZZ".
 * It uses a regular expression to search for tire size patterns within the string.
 *
 * @param {string} value The string from which to extract tire sizes.
 * @returns {string[]} An array of strings with tire sizes in the format "XXX-YY-ZZ".
 */
export const extractTireSizes = (value: string): string[] => {
  const regex = /(?:FRONT: |REAR: )?(\d{1,3}(?:\.\d)?)[-/](\d{1,3}(?:\.\d)?)[-/rR](\d{1,4}(?:\.\d)?)/g;

  return [...value.matchAll(regex)].map((match) => `${match[1]}-${match[2]}-${match[3]}`);
};
