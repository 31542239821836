import { useMounted } from '@partstech/ui/hooks';
import { useEffect } from 'react';
import { shallowEqual } from 'react-redux';
import { useAppDispatch, useAppSelector } from 'store';
import { restoreQuote, selectQuoteState } from 'store/features/quote';
import { useComparedQuoteCreating } from './useComparedQuoteCreating';

export const useCompareQuoteInitialize = () => {
  const dispatch = useAppDispatch();

  const { calculationError, ...quote } = useAppSelector(selectQuoteState, shallowEqual);

  const { quoteCreating, setQuoteCreating } = useComparedQuoteCreating();

  const isMounted = useMounted();

  useEffect(() => {
    if (isMounted) {
      return;
    }

    dispatch(restoreQuote(quoteCreating));
  }, [dispatch, isMounted, quoteCreating]);

  useEffect(() => {
    if (!isMounted) {
      return;
    }

    setQuoteCreating(quote);
  }, [isMounted, quote, setQuoteCreating]);
};
