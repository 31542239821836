import { useForm } from '@partstech/ui/forms';
import { usePrevious } from '@partstech/ui/hooks';
import { isEqual, isString, keys } from '@partstech/ui/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { mapVehicleToVehicleParameters } from './useVehicleParameters';
import type { VehicleField } from './types';
import type { VehicleParameters } from '../../types';
import type { Option } from '../OptionsList/Options';
import type { Vehicle } from 'models';
import type { Dispatch, SetStateAction } from 'react';

type Props = {
  isActive?: boolean;
  value?: Vehicle | null;
  onChange?: (value: Vehicle | null) => void;
  vehicleParameters: VehicleParameters;
  setVehicleParameters: Dispatch<SetStateAction<VehicleParameters>>;
  resetVehicleParameters: () => void;
  vehicles: Vehicle[];
};

const getActiveField = (vehicleParameters: VehicleParameters): VehicleField | null => {
  if (vehicleParameters.engineId) {
    return null;
  }

  if (vehicleParameters.submodelId) {
    return 'engineId';
  }

  if (vehicleParameters.modelId) {
    return 'submodelId';
  }

  if (vehicleParameters.makeId) {
    return 'modelId';
  }

  if (vehicleParameters.year) {
    return 'makeId';
  }

  return 'year';
};

const formFields: VehicleField[] = ['year', 'makeId', 'modelId', 'submodelId', 'engineId'];

const mapVehicleToFormValues = (vehicle: Vehicle): VehicleParameters => ({
  year: vehicle.year.toString(),
  makeId: vehicle.make.name,
  modelId: vehicle.model.name,
  submodelId: vehicle.subModel.name,
  engineId: vehicle.engine.name,
});

export const useYMMForm = ({
  vehicleParameters,
  setVehicleParameters,
  resetVehicleParameters,
  vehicles,
  value,
  isActive,
  onChange,
}: Props) => {
  const form = useForm<VehicleParameters>({ defaultValues: vehicleParameters });
  const { watch, reset, setFocus } = form;

  const rawFormValues = watch(['year', 'makeId', 'modelId', 'submodelId', 'engineId']);
  const previousRawFormValues = usePrevious(rawFormValues);

  const formValues = ((): VehicleParameters => {
    const [year, makeId, modelId, submodelId, engineId] = rawFormValues;

    return { year, makeId, modelId, submodelId, engineId };
  })();

  const [activeField, setActiveField] = useState<VehicleField | null>(getActiveField(vehicleParameters));
  const [isUserFocused, setIsUserFocused] = useState(false);

  const wasActive = usePrevious(isActive);
  const previousValue = usePrevious(value);

  const getNextField = useCallback(
    (field?: VehicleField): VehicleField | null => {
      const fields = keys(formValues);
      const currentField = field ?? activeField;

      if (currentField) {
        const fieldIndex = fields.indexOf(currentField);

        return fields[fieldIndex + 1] ?? null;
      }

      return 'year';
    },
    [activeField, formValues]
  );

  const onFocus = useCallback((fieldName: VehicleField) => {
    setActiveField((previousFieldName) => {
      if (!previousFieldName) {
        setIsUserFocused(true);

        return fieldName;
      }

      const previousIndex = formFields.indexOf(previousFieldName);
      const newIndex = formFields.indexOf(fieldName);

      if (previousIndex > newIndex) {
        setIsUserFocused(true);
      }

      return fieldName;
    });
  }, []);

  const updateField = useCallback(
    (fieldName: VehicleField, fieldValue: string, fieldId: string) => {
      const nextField = getNextField(fieldName);

      if (!nextField) {
        reset({ ...formValues, engineId: fieldValue });
        setVehicleParameters((previousParameters) => ({ ...previousParameters, engineId: fieldId }));
        return;
      }

      const nextFields = formFields.slice(formFields.indexOf(nextField));

      const newFormValues = formFields.reduce<VehicleParameters>(
        (parameters, key) => {
          if (isString(fieldValue) && key === fieldName) {
            return { ...parameters, [key]: fieldValue };
          }

          return { ...parameters, [key]: nextFields.includes(key) ? '' : parameters[key] };
        },
        { ...formValues }
      );

      reset(newFormValues);

      setVehicleParameters((previousParameters) =>
        formFields.reduce<VehicleParameters>(
          (parameters, key) => {
            if (isString(fieldId) && key === fieldName) {
              return { ...parameters, [key]: fieldId };
            }

            return { ...parameters, [key]: nextFields.includes(key) ? '' : parameters[key] };
          },
          { ...previousParameters }
        )
      );
    },
    [formValues, getNextField, reset, setVehicleParameters]
  );

  const handleChange = useCallback(
    (name: VehicleField, option: Option | string) => {
      const inputValue = isString(option) ? option : option.name;
      const id = isString(option) ? option : option.id;

      updateField(name, inputValue, id);

      const nextField = getNextField();

      if (nextField) {
        setActiveField(nextField);
      }
    },
    [getNextField, updateField]
  );

  const handleInputChange = useCallback(
    (name: VehicleField) => (fieldValue: string) => {
      const fields = keys(formValues);
      const vehicleParameter = vehicleParameters[name];
      const fieldIndex = fields.indexOf(name);

      if (fieldValue === '' && vehicleParameter !== '') {
        const newFormValues = fields.reduce<VehicleParameters>(
          (parameters, key, index) => ({
            ...parameters,
            [key]: index >= fieldIndex ? '' : parameters[key],
          }),
          { ...formValues }
        );

        reset(newFormValues);

        setVehicleParameters((previousParameters) =>
          fields.reduce<VehicleParameters>(
            (parameters, key, index) => ({ ...parameters, [key]: index >= fieldIndex ? '' : parameters[key] }),
            previousParameters
          )
        );
      }
    },
    [formValues, reset, setVehicleParameters, vehicleParameters]
  );

  const resetForm = useCallback(() => {
    reset({ year: '', makeId: '', modelId: '', submodelId: '', engineId: '' });
    resetVehicleParameters();
    setActiveField('year');
  }, [reset, resetVehicleParameters]);

  const onReset = useCallback(() => {
    resetForm();

    if (onChange) {
      onChange(null);
    }
  }, [onChange, resetForm]);

  const handleSubmit = useCallback(
    (vehicle: Vehicle) => {
      setVehicleParameters(mapVehicleToVehicleParameters(vehicle));
      reset(mapVehicleToFormValues(vehicle));

      if (onChange) {
        onChange(vehicle);
      }
    },
    [onChange, reset, setVehicleParameters]
  );

  const areParametersFilled = useMemo(
    () => Object.values(vehicleParameters).every((parameter) => parameter),
    [vehicleParameters]
  );

  const areFormFieldsEmpty = useMemo(() => rawFormValues.every((parameter) => !parameter), [rawFormValues]);

  useEffect(() => {
    if (isActive || isEqual(value, previousValue)) {
      return;
    }

    if (!value && areFormFieldsEmpty) {
      resetForm();
      return;
    }

    if (!value) {
      return;
    }

    reset(mapVehicleToFormValues(value));
    setVehicleParameters(mapVehicleToVehicleParameters(value));
    setActiveField(null);
  }, [areFormFieldsEmpty, isActive, previousValue, reset, resetForm, setVehicleParameters, value]);

  useEffect(() => {
    if (previousValue && !value && areParametersFilled && !isActive) {
      onReset();
    }
  }, [areParametersFilled, previousValue, isActive, onReset, value]);

  useEffect(() => {
    if (!wasActive && areParametersFilled) {
      setActiveField(null);
    }
  }, [areParametersFilled, wasActive]);

  const firstVehicle = vehicles[0] ?? null;
  const singleVehicle = vehicles.length === 1 ? firstVehicle : null;

  useEffect(() => {
    if (singleVehicle && isActive && !isEqual(singleVehicle.id, value?.id)) {
      handleSubmit(singleVehicle);
    }
  }, [handleSubmit, isActive, singleVehicle, value?.id]);

  useEffect(() => {
    if (singleVehicle && value && value.id !== singleVehicle.id) {
      reset(mapVehicleToFormValues(value));
    }
  }, [reset, singleVehicle, value]);

  useEffect(() => {
    if (isActive || value) {
      return;
    }

    if (firstVehicle) {
      reset({ ...mapVehicleToFormValues(firstVehicle), engineId: '' });
      setActiveField('engineId');
    }
  }, [isActive, firstVehicle, value, reset]);

  useEffect(() => {
    if (isActive && activeField) {
      setFocus(activeField);
    }
  }, [activeField, isActive, setFocus]);

  useEffect(() => {
    if (isUserFocused && !isEqual(rawFormValues, previousRawFormValues)) {
      setIsUserFocused(false);
    }
  }, [isUserFocused, previousRawFormValues, rawFormValues]);

  useEffect(() => {
    if (areParametersFilled && areFormFieldsEmpty && value) {
      reset(mapVehicleToFormValues(value));
      setActiveField(null);
    }
  }, [areFormFieldsEmpty, areParametersFilled, reset, value]);

  return {
    form,
    formValues,
    onFocus,
    onReset,
    onChange: handleChange,
    activeField,
    isUserFocused,
    onInputChange: handleInputChange,
  };
};
