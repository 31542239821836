import { css } from '@emotion/react';
import { Box } from '@partstech/ui';
import { useDebouncedCallback, useOpen, useToggle } from '@partstech/ui/hooks';
import { duration, isTypeOf } from '@partstech/ui/utils';
import React, { useCallback, useEffect } from 'react';
import { isVehicle } from '../../utils';
import { VehicleSelector } from '../VehicleSelector';
import { SelectVehicleButton } from './SelectVehicleButton';
import type { SelectVehicleButtonProps } from './SelectVehicleButton';
import type { VehicleSelectorValue } from '../../types';
import type { BoxProps } from '@partstech/ui';
import type { Vehicle } from 'models';

type Props = {
  customButton?: React.ForwardRefExoticComponent<SelectVehicleButtonProps & React.RefAttributes<HTMLButtonElement>>;
  isOpened?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  value?: VehicleSelectorValue;
  onChange?: (vehicle: Vehicle | null) => void;
  disabled?: boolean;
} & BoxProps;

const styles = {
  container: (isEntered: boolean) => css`
    display: ${isEntered ? 'block' : 'none'};
    width: auto;
  `,
};

export const VehicleSelectorDropdown = ({
  customButton: CustomButton,
  isOpened: isInitiallyOpened,
  onOpen,
  onClose,
  value,
  onChange,
  disabled,
  ...boxProps
}: Props) => {
  const { isToggle: isOpened, setToggle: setIsOpened } = useToggle(isInitiallyOpened ?? false);
  const { isOpen: isEntered, open, close } = useOpen();

  const handleIsEntered = useDebouncedCallback(() => {
    open();
  }, duration.standard);

  const handleOpen = useCallback(() => {
    if (onOpen) {
      onOpen();
    }

    handleIsEntered();
  }, [handleIsEntered, onOpen]);

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
    }

    setIsOpened(false);
    close();
  }, [close, onClose, setIsOpened]);

  const handleToggle = useCallback(() => {
    setIsOpened((prevIsOpened) => {
      !prevIsOpened ? handleOpen() : handleClose();

      return !prevIsOpened;
    });
  }, [setIsOpened, handleOpen, handleClose]);

  useEffect(() => {
    if (!isTypeOf(isInitiallyOpened, 'undefined')) {
      if (isInitiallyOpened) {
        handleOpen();
        return;
      }

      handleClose();
    }
  }, [handleClose, handleOpen, isInitiallyOpened]);

  return (
    <Box height={{ sm: 10, md: 15, xl: 14 }} position="relative" data-testid="vehicleSelectorDropdown">
      {CustomButton && (
        <CustomButton
          selectedVehicle={isVehicle(value) ? value : null}
          isOpen={isOpened}
          onClick={handleToggle}
          disabled={disabled}
        />
      )}

      {!CustomButton && (
        <SelectVehicleButton
          selectedVehicle={isVehicle(value) ? value : null}
          isOpen={isOpened}
          onClick={handleToggle}
          disabled={disabled}
        />
      )}

      {!disabled && (
        <div css={styles.container(isOpened)}>
          <VehicleSelector onClose={handleClose} isOpened={isEntered} value={value} onChange={onChange} {...boxProps} />
        </div>
      )}
    </Box>
  );
};
