import { api as activeCartApi } from 'features/cart/api/queries/GetActiveCart.generated';
import { useMutationStatus } from 'shared/api';
import { api as generatedApi } from './ChangeCartItemQuantity.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    ChangeCartItemQuantity: {
      onQueryStarted: async ({ itemId, quantity }, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;

        if (!data.updateActiveCartItemQuantity) {
          return;
        }

        dispatch(
          activeCartApi.util.updateQueryData('GetActiveCart', undefined, (draft) => {
            draft?.activeCart?.orders?.forEach((order) => {
              const orderItem = order.items?.find((item) => item.id === itemId);

              if (!orderItem?.builtItem) {
                return;
              }

              if (data.updateActiveCartItemQuantity && 'orderItemId' in data.updateActiveCartItemQuantity) {
                Object.assign(orderItem, { quantity });
              } else {
                Object.assign(orderItem, {
                  quantity,
                  builtItem: {
                    ...orderItem.builtItem,
                    errors: [
                      {
                        message: data.updateActiveCartItemQuantity?.errorMessage,
                      },
                    ],
                  },
                });
              }
            });
          })
        );
      },
    },
  },
});

const { useChangeCartItemQuantityMutation } = api;

export const useChangeCartItemQuantity = () => {
  const [changeQuantity, status] = useChangeCartItemQuantityMutation();

  useMutationStatus({ status });

  return [changeQuantity, status] as const;
};
