/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type UpdateShopMutationVariables = Types.Exact<{
  input: Types.UpdateShopInput;
}>;

export type UpdateShopMutation = { __typename?: 'Mutation'; updateShop?: { __typename: 'UpdateShopPayload' } | null };

export const UpdateShopDocument = `
    mutation UpdateShop($input: UpdateShopInput!) {
  updateShop(input: $input) {
    __typename
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    UpdateShop: build.mutation<UpdateShopMutation, UpdateShopMutationVariables>({
      query: (variables) => ({ document: UpdateShopDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUpdateShopMutation } = injectedRtkApi;
