import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { calculatePrice } from 'shared/api/rest/gen/shop';
import { createAppAsyncThunk } from 'store/utils';
import type { EntityId } from '@reduxjs/toolkit';
import type { PackageItemsWithFetCollection, CalculatedPackageItems } from 'shared/api/rest/gen/shop';
import type { RootState } from 'store';
import type { PricePackage } from 'types/pricePackage';

export type OutTheDoorPrice = {
  productId: string;
  packageId: PricePackage['id'];
  quantity: number;
} & CalculatedPackageItems;

type CalculateOTDTirePricePayload = {
  currentPackage: PricePackage;
  price: number;
  taxRate: number;
  quantity: number;
  productId: string;
  fetPrice: number;
};

export const getOutTheDoorPrice = createAppAsyncThunk<CalculatedPackageItems, CalculateOTDTirePricePayload>(
  'features/search/getOutTheDoorPrice',
  async (arg) => {
    const { currentPackage, price, taxRate, quantity, fetPrice } = arg;

    const priceLines = currentPackage.items.map((item) => ({ ...item, description: item.description || null }));

    const items: PackageItemsWithFetCollection = fetPrice
      ? [
          ...priceLines,
          {
            isApplyTax: false,
            isPerTire: true,
            name: 'FET',
            type: 'FET',
            price: fetPrice,
          },
        ]
      : priceLines;

    const response = await calculatePrice({
      items,
      price,
      tax: taxRate ?? 0,
      quantity,
    });

    return response;
  }
);

export const selectOutTheDoorPriceId = (entity: Pick<OutTheDoorPrice, 'productId' | 'packageId' | 'quantity'>) =>
  `${entity.productId}-${entity.packageId}-${entity.quantity}`;

const entityAdapter = createEntityAdapter<OutTheDoorPrice, EntityId>({
  selectId: selectOutTheDoorPriceId,
});

type State = {
  isLoading: Record<string, boolean>;
  isError: boolean;
};

const initialState = entityAdapter.getInitialState<State>({
  isLoading: {},
  isError: false,
});

const outTheDoorPricesSlice = createSlice({
  name: 'outTheDoorPrices',
  reducers: {
    resetOutTheDoorPrices: entityAdapter.removeAll,
  },
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getOutTheDoorPrice.pending, (state, action) => {
        const { currentPackage, quantity, productId } = action.meta.arg;
        state.isLoading[selectOutTheDoorPriceId({ productId, packageId: currentPackage.id, quantity })] = true;

        state.isError = false;
      })
      .addCase(getOutTheDoorPrice.fulfilled, (state, action) => {
        const { currentPackage, quantity, productId } = action.meta.arg;

        entityAdapter.upsertOne(state, {
          items: action.payload.items,
          price: action.payload.price,
          packageId: currentPackage.id,
          tax: action.payload.tax,
          tire: action.payload.tire,
          quantity,
          productId,
        });

        state.isLoading[selectOutTheDoorPriceId({ productId, packageId: currentPackage.id, quantity })] = false;

        state.isError = false;
      })
      .addCase(getOutTheDoorPrice.rejected, (state, action) => {
        const { currentPackage, quantity, productId } = action.meta.arg;
        state.isLoading[selectOutTheDoorPriceId({ productId, packageId: currentPackage.id, quantity })] = false;

        state.isError = true;
      });
  },
});

export const outTheDoorPrices = outTheDoorPricesSlice.reducer;

export const { resetOutTheDoorPrices } = outTheDoorPricesSlice.actions;

const selectState = (state: RootState) => state.features.search.outTheDoorPrices;

const { selectAll: selectAllOutTheDoorPrices, selectById: selectOutTheDoorPriceById } =
  entityAdapter.getSelectors(selectState);

export { selectOutTheDoorPriceById };

export const selectOutTheDoorPricesByProductId = createSelector(
  [selectAllOutTheDoorPrices, (_, productId: string | null) => productId],
  (entities, productId) => entities.filter((entity) => entity.productId === productId)
);

export const selectIsOutTheDoorPricesLoading = createSelector(
  [selectState, (_, id: string) => id],
  (state, id) => state.isLoading[id] ?? false
);

export const selectIsOutTheDoorPricesError = createSelector([selectState], (state) => state.isError);
