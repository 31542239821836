import { useCallback } from 'react';
import { useMutationStatus } from 'shared/api';
import { api as generatedApi } from './CreateShop.generated';
import type { CreateShopInput } from 'shared/api';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    CreateShop: {
      invalidatesTags: ['AppConfig'],
    },
  },
});

const { useCreateShopMutation } = api;

export const useCreateShop = () => {
  const [createShop, status] = useCreateShopMutation();

  const handleCreateShop = useCallback((input: CreateShopInput) => createShop({ input }), [createShop]);

  useMutationStatus({ status });

  return [handleCreateShop, status] as const;
};
