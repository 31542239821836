import { css } from '@emotion/react';
import { Skeleton } from '@partstech/ui';
import type { Theme } from '@emotion/react';

const styles = {
  video: (theme: Theme) => css`
    border: 0;
    width: 100%;
    aspect-ratio: 16/9;
    border-radius: ${theme.sizing(2)};
  `,
};

type Props = {
  src?: string;
};

export const SequelVideoPlayer = ({ src }: Props) => {
  if (!src) {
    return <Skeleton height="100%" width="100%" />;
  }

  return (
    <iframe
      src={src}
      title="Sequel video player"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
      css={styles.video}
    />
  );
};
