import { makeContext } from '@partstech/ui/utils';
import type { AvailabilityLine, SupplierAccount } from 'models';
import type { StockOrder } from 'types/stockOrders';

export type InitialStockOrder = Omit<StockOrder, 'lines'> & {
  lines: {
    brandName?: string;
    lineCardId?: string | null;
    manufacturerId?: string;
    partNumberId?: string | null;
    productIds: string[];
    quantity: number;
    searchQuery: string;
    selectedIndex: number | null;
    selectedStore?: AvailabilityLine;
    error?: string;
  }[];
};

export type StockOrdersContextValue = {
  addStockOrder: (supplierAccount: SupplierAccount) => void;
  addStockOrderLine: (payload: { searchQuery: string; stockOrderId: string; brandName?: string }) => void;
  changeStockOrderLineProduct: (payload: { stockOrderId: string; selectedIndex: number; index: number }) => void;
  changeStockOrderLineQuantity: (payload: { stockOrderId: string; value: number; index: number }) => void;
  changeStockOrderLineStore: (payload: { stockOrderId: string; value: AvailabilityLine; index: number }) => void;
  changeSupplier: (payload: { stockOrderId: string; supplierAccount: SupplierAccount }) => void;
  initialStockOrders: InitialStockOrder[];
  initStockOrders: (payload: { stockOrders: InitialStockOrder[]; splittedByLastPurchases: boolean }) => void;
  isInitialized: boolean;
  isSplittedByLastPurchases: boolean;
  removeStockOrder: (stockOrderId: string) => void;
  removeStockOrderLine: (payload: { stockOrderId: string; index: number }) => void;
  removeStockOrderLines: (payload: { stockOrderId: string; indexesToRemove: number[] }) => void;
  setStockOrderLineError: (payload: { stockOrderId: string; index: number; value: string }) => void;
  setTemplate: (id: string | null, stockOrders?: InitialStockOrder[]) => void;
  templateId: string | null;
};

export const [useStockOrdersContext, StockOrdersContextProvider] = makeContext<StockOrdersContextValue>();
