import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppNavigate } from 'app/AppRouter/useAppNavigate';
import { createAddressFromData, useGetAddresses } from 'entities/address';
import { useWelcomeModal } from 'features/onboarding';
import { useIsHiddenSignUpStepper } from 'hooks/sessionStorage';
import { useOpenAddSupplierModal } from 'hooks/supplierAccounts';
import { useLaunchDarkly } from 'integrations/launchDarkly';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import { getDashboardUrl } from 'utils/url';
import { CreateShopContextProvider } from './CreateShopContext';
import { useIsMitchell1SignUp } from './useIsMitchell1SignUp';
import { useSignUpSteps } from './useSignUpSteps';
import type { ShopFormData } from 'features/shop';
import type { PropsWithChildren } from 'react';
import type { ShopType } from 'shared/api';

export const CreateShopContext = ({ children }: PropsWithChildren) => {
  const navigate = useAppNavigate();

  const {
    flags: { newWelcomeModal },
  } = useLaunchDarkly();

  const [isHiddenSignUpHeader, setIsHiddenSignUpHeader] = useState<boolean>(false);
  const [isDuplicatedShopStep, setIsDuplicatedShopStep] = useState<boolean>(false);
  const [selectedShop, setSelectedShop] = useState<null | ShopFormData>(null);
  const [shopType, setShopType] = useState<ShopType>();

  const { activeMember, shop } = useGetCurrentUser();
  const { billingAddress, shippingAddresses } = useGetAddresses({ skip: !activeMember });

  const { isHiddenSignUpStepper, setIsHiddenSignUpStepper } = useIsHiddenSignUpStepper();

  const isMitchell1SignUp = useIsMitchell1SignUp();

  const { activeStep, nextStep, previousStep } = useSignUpSteps({ isMitchell1SignUp });

  const { open: openAddSupplierModal } = useOpenAddSupplierModal();

  const handleOpenAddSupplierModal = useCallback(() => {
    openAddSupplierModal('all');
  }, [openAddSupplierModal]);

  const { open: openWelcomeModal } = useWelcomeModal({ onAddSuppliers: handleOpenAddSupplierModal });

  const navigateToNextStep = useCallback(() => {
    if (!nextStep?.url) {
      if (newWelcomeModal) {
        openWelcomeModal();
      }

      navigate(getDashboardUrl());

      return;
    }

    navigate(nextStep.url);
  }, [navigate, newWelcomeModal, nextStep?.url, openWelcomeModal]);

  const navigateToPreviousStep = useCallback(() => {
    if (isDuplicatedShopStep) {
      setIsDuplicatedShopStep(false);
      return;
    }
    navigate(previousStep?.url ?? -1);
  }, [isDuplicatedShopStep, navigate, previousStep?.url]);

  const value = useMemo(
    () => ({
      activeStep,
      isDuplicatedShopStep,
      isHiddenSignUpHeader,
      isHiddenSignUpStepper,
      isMitchell1SignUp,
      navigateToNextStep,
      navigateToPreviousStep,
      nextStep,
      previousStep: isDuplicatedShopStep ? activeStep : previousStep,
      selectedShop,
      setIsDuplicatedShopStep,
      setIsHiddenSignUpHeader,
      setIsHiddenSignUpStepper,
      setSelectedShop,
      setShopType,
      shopType,
    }),
    [
      activeStep,
      isDuplicatedShopStep,
      isHiddenSignUpHeader,
      isHiddenSignUpStepper,
      isMitchell1SignUp,
      navigateToNextStep,
      navigateToPreviousStep,
      nextStep,
      previousStep,
      selectedShop,
      setIsHiddenSignUpStepper,
      setShopType,
      shopType,
    ]
  );

  useEffect(() => {
    if (!shop || !billingAddress) {
      return;
    }

    setSelectedShop({
      billingAddress: createAddressFromData(billingAddress),
      name: shop.name,
      phone: shop.phone ?? '',
      shippingAddress: shippingAddresses[0] ? createAddressFromData(shippingAddresses[0]) : undefined,
      website: shop.website ?? '',
    });
  }, [billingAddress, shippingAddresses, shop]);

  return <CreateShopContextProvider value={value}>{children}</CreateShopContextProvider>;
};
