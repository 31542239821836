import { useCallback, useMemo } from 'react';
import { useMutationStatus } from 'shared/api';
import { useValidateAddressesMutation } from './ValidateAddresses.generated';
import type { ValidateAddressesInput } from 'shared/api';

export const useValidateAddresses = () => {
  const [validateAddresses, status] = useValidateAddressesMutation();

  const handleValidateAddresses = useCallback(
    async (input: ValidateAddressesInput) => {
      const response = await validateAddresses({ input });

      if ('error' in response) {
        return false;
      }

      const { billingValidity, shippingValidity } = response.data.validateAddresses ?? {};

      return billingValidity !== false && shippingValidity !== false;
    },
    [validateAddresses]
  );

  const successMessage = useMemo(() => {
    const { billingValidity, shippingValidity } = status.data?.validateAddresses ?? {};

    const errorFields: ('billing' | 'shipping')[] = [];

    if (billingValidity === false) {
      errorFields.push('billing');
    }

    if (shippingValidity === false) {
      errorFields.push('shipping');
    }

    return errorFields.length > 0
      ? `Invalid ${errorFields.join(' and ')} ${errorFields.length > 1 ? 'addresses' : 'address'}`
      : '';
  }, [status.data?.validateAddresses]);

  useMutationStatus({ status, successMessage });

  return [handleValidateAddresses, status] as const;
};
