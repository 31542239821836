import { useCallback } from 'react';
import { useAppNavigate } from 'app/AppRouter/useAppNavigate';
import { useCreateShopContext } from 'components/Layout/SignUpLayout/CreateShopContext/CreateShopContext';
import { useSignupQueryParams } from 'hooks/signup';
import { OnboardStepType, ShopType } from 'shared/api';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import { useOnboardSteps } from 'store/queries/currentUser/useOnboardSteps';
import { SignUpPages, getSignUpUrl } from 'utils/url';
import type { MouseEvent } from 'react';

export const useShopType = () => {
  const navigate = useAppNavigate();

  const { firstName } = useGetCurrentUser();
  const { getValueByStep } = useOnboardSteps();
  const searchParams = useSignupQueryParams();
  const { shopType, setShopType } = useCreateShopContext();

  const handleSelect = useCallback(
    (option: ShopType) => {
      setShopType(option);

      navigate(getSignUpUrl(SignUpPages.ShopDetails, searchParams));
    },
    [searchParams, setShopType, navigate]
  );

  const handleSelectDIY = useCallback(
    (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();

      handleSelect(ShopType.Diy);
    },
    [handleSelect]
  );

  return {
    firstName,
    defaultOption: shopType ?? (getValueByStep(OnboardStepType.ShopType) as ShopType) ?? undefined,
    selectOption: handleSelect,
    selectDIY: handleSelectDIY,
  };
};
