import { AvailabilityLine } from './AvailabilityLine';

export class FeaturedAvailabilityLine extends AvailabilityLine {
  getIcon() {
    if (this.group === 'Home Store') {
      return 'home';
    }

    return undefined;
  }

  getMainAvailabilityTitle() {
    if (this.group === 'Home Store') {
      return `In stock (${this.formattedMaxQuantity})`;
    }

    return `${this.group ?? 'In Stock'} (${this.formattedMaxQuantity})`;
  }

  getNetworkAvailabilityTitle() {
    return `${this.group ?? 'Available'} (${this.formattedMaxQuantity})`;
  }
}
