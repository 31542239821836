import { LegacyJob } from '../../models/LegacyJob';

export const getApplicationPartTypeId = (partTextId?: string | null, partTypes?: string[] | null) => {
  if (partTextId && partTextId.length > 0) {
    return Number(partTextId);
  }

  const firstPartType = (partTypes ?? [])[0];

  if (!firstPartType || LegacyJob.isLegacyJobId(firstPartType)) {
    return null;
  }

  return firstPartType;
};
