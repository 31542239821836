import { Address } from './Address';
import type { AddressData } from './Address';

export type BaseAddressData = {
  id: string;
  isDefault: boolean;
} & AddressData;

export class BaseAddress extends Address {
  id: string;

  isDefault: boolean;

  constructor(addressPayload: BaseAddressData) {
    super(addressPayload);

    this.id = addressPayload.id;
    this.isDefault = addressPayload.isDefault;
  }
}
