import type { Product } from 'models';

export const sortBySponsored = (a: Product, b: Product) => {
  if (a.isSponsored() && !b.isSponsored()) {
    return -1;
  }

  if (!a.isSponsored() && b.isSponsored()) {
    return 1;
  }

  return 0;
};
