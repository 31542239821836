import { useCallback, useState } from 'react';
import type React from 'react';

type Props = {
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
};

export const useInputFocusEvents = ({ onFocus, onBlur }: Props) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      setIsFocused(true);
      onFocus && onFocus(e);
    },
    [onFocus]
  );

  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      const isClickedInsideField = e.target?.closest('.text-field-container')?.contains(e.relatedTarget);
      const isSearchButtonClicked = e.relatedTarget?.className?.includes('search-button');

      if (isClickedInsideField && !isSearchButtonClicked) {
        return;
      }

      setIsFocused(false);
      onBlur && onBlur(e);
    },
    [onBlur]
  );

  return { isFocused, handleFocus, handleBlur };
};
