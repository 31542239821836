import { Typography, Link, TextCut, OldNavigationListItem } from '@partstech/ui';
import type { MouseEvent, ReactNode } from 'react';

type Props = {
  title: string;
  subtitle?: string;
  link?: string;
  onClick?: (e?: MouseEvent) => void;
  isActive?: boolean;
  adornmentBefore: ReactNode;
};

export const NavigationItem = ({ title, subtitle, link, onClick, isActive, adornmentBefore }: Props) => (
  <OldNavigationListItem
    as={link ? <Link to={link} /> : undefined}
    adornmentBefore={adornmentBefore}
    onClick={onClick}
    active={isActive}
    size="dense"
    fullWidth
    colorScheme="darkBlue"
  >
    <Typography component="div" variant="subtitle2">
      <TextCut>{title}</TextCut>
    </Typography>

    {subtitle && (
      <Typography component="div" variant="caption" color="subtleText">
        {subtitle}
      </Typography>
    )}
  </OldNavigationListItem>
);
