import { Box, Breadcrumbs } from '@partstech/ui';
import { useKeyboardSelection } from '@partstech/ui/hooks';
import { useCallback } from 'react';
import { ListButton } from 'components/ListButton';
import { useCategoryDiagrams } from 'hooks/diagrams';
import { splitIntoColumns } from 'utils';
import type { Diagram } from 'shared/api';

type Props = {
  categoryId: string;
  vehicleId: string | null;
  categoryName: string;
  onAllClick: () => void;
  onDiagramClick: (diagram: Diagram) => void;
};

export const DiagramsList = ({ categoryId, vehicleId, categoryName, onAllClick, onDiagramClick }: Props) => {
  const { diagrams } = useCategoryDiagrams({ vehicleId, categoryId });

  const handleDiagramEnterKeyDown = useCallback(
    (index: number) => {
      const diagram = diagrams[index];

      if (diagram) {
        onDiagramClick(diagram);
      }
    },
    [onDiagramClick, diagrams]
  );

  const handleDiagramClick = useCallback(
    (diagram: Diagram) => () => {
      onDiagramClick(diagram);
    },
    [onDiagramClick]
  );

  const selectedIndex = useKeyboardSelection(diagrams.length, handleDiagramEnterKeyDown, -1);

  let itemIndex = -1;

  return (
    <Box justifyContent="flex-start" width="100%">
      <Breadcrumbs breadcrumbs={[{ title: 'All diagrams', onClick: onAllClick }, { title: categoryName }]} pl={2} />

      <Box display={{ md: 'flex' }} width={{ md: '100%' }} mt={4}>
        {splitIntoColumns(diagrams, 11).map((column, index) => (
          <Box key={index} width={{ sm: '100%', md: 74 }}>
            {column.map((diagram) => {
              const isSelected = selectedIndex === ++itemIndex;

              return (
                <Box key={diagram.id} display="flex" flexDirection="column">
                  <ListButton
                    text={diagram.name}
                    isSelected={isSelected}
                    onSelect={handleDiagramClick(diagram)}
                    linesCount={2}
                    data-testid="searchFormListItem"
                  />
                </Box>
              );
            })}
          </Box>
        ))}
      </Box>
    </Box>
  );
};
