import { Typography } from '@partstech/ui';
import { SignUpPages } from 'utils/url';

type Props = {
  id?: SignUpPages | 'select-shop-with-google';
};

export const Description = ({ id }: Props) => {
  if (id === SignUpPages.ShopType) {
    return (
      <Typography color="subtleText" variant="subtitle2">
        Order from all your part and tire suppliers in one lookup and save an average of{' '}
        <Typography variant="h3" component="span">
          15 minutes
        </Typography>{' '}
        per RO
      </Typography>
    );
  }

  if (id === 'select-shop-with-google') {
    return (
      <Typography color="subtleText" variant="subtitle2">
        Trusted by{' '}
        <Typography variant="h3" component="span">
          25,000+
        </Typography>{' '}
        automotive repair shops, large and small
      </Typography>
    );
  }

  if (id === SignUpPages.ShopDetails) {
    return (
      <Typography color="subtleText" variant="subtitle2">
        With more than{' '}
        <Typography variant="h3" component="span">
          30,000
        </Typography>{' '}
        supplier locations, you can always find the parts and tires you need
      </Typography>
    );
  }

  if (id === SignUpPages.ManagementSystem) {
    return (
      <Typography color="subtleText" variant="subtitle2">
        Easily plug into more than <br />
        <Typography variant="h3" component="span">
          35 management systems
        </Typography>
        <br />
        or use PartsTech on its own
      </Typography>
    );
  }

  if (id === SignUpPages.PartsSuppliers) {
    return (
      <Typography color="subtleText" variant="subtitle2">
        Search and order parts from more than{' '}
        <Typography variant="h3" component="span">
          200 major
        </Typography>{' '}
        part suppliers
      </Typography>
    );
  }

  if (id === SignUpPages.TireSuppliers) {
    return (
      <Typography color="subtleText" variant="subtitle2">
        Search, quote, and order tires from more than{' '}
        <Typography variant="h3" component="span">
          50 major
        </Typography>{' '}
        tire suppliers
      </Typography>
    );
  }

  if (id === SignUpPages.Role) {
    return (
      <Typography color="subtleText" variant="subtitle2">
        “I’ve been calling my vendors for years, but I have found a better way with PartsTech.” Tony Valliapiano, Owner,
        Tuffy Tire & Auto Service
      </Typography>
    );
  }

  if (id === SignUpPages.HearAboutUs) {
    return (
      <>
        <Typography color="subtleText" variant="subtitle2">
          “We have seen roughly about a{' '}
          <Typography variant="h3" component="span">
            15%
          </Typography>{' '}
          increase in revenue along with{' '}
          <Typography variant="h3" component="span">
            50%
          </Typography>{' '}
          time savings per repair order.”
        </Typography>

        <Typography color="subtleText" variant="subtitle2">
          Adam Shifman, Owner
        </Typography>
      </>
    );
  }

  if (id === SignUpPages.PhoneNumber) {
    return (
      <Typography color="subtleText" variant="subtitle2">
        “PartsTech is the Google of automotive parts - it’s extremely easy to search for parts. I couldn’t run the
        amount of numbers that I do without PartsTech.” Felix Diaz, Founder & CEO
      </Typography>
    );
  }

  return null;
};
