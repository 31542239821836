import { AddPaymentCardForm } from './AddPaymentCardForm';
import { useAddCard } from './useAddCard';

export const AddCard = () => {
  const { stripeKey, paymentMethod, showCardOptions, changePaymentMethod, close, submitForm } = useAddCard();

  return (
    <AddPaymentCardForm
      paymentMethod={paymentMethod}
      showCardOptions={showCardOptions}
      onPaymentChanged={changePaymentMethod}
      onCancel={close}
      onSubmit={submitForm}
      stripeKey={stripeKey}
    />
  );
};
