import { Box, Button, ButtonGroup, Logo, Typography, useMedia } from '@partstech/ui';
import { useHeader } from './useHeader';

export const Header = () => {
  const { isMobile } = useMedia();

  const { previousStepName, showBackButton, showCreateShopTimeEstimate, navigateToPreviousStep } = useHeader();

  return (
    <>
      <Box
        mt={3}
        mb={{ sm: 12, md: showCreateShopTimeEstimate ? 25 : 37 }}
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="100%"
        position="relative"
      >
        {showBackButton && (
          <ButtonGroup position="absolute" left={0} top={{ sm: 10, md: 0 }}>
            <Button
              variant="text"
              leadingIcon="chevron_left"
              onClick={navigateToPreviousStep}
              size={isMobile ? 'dense' : 'regular'}
              noHover
            >
              {isMobile ? 'Back' : `Back to ${previousStepName}`}
            </Button>
          </ButtonGroup>
        )}

        <Logo />
      </Box>

      {showCreateShopTimeEstimate && (
        <Box
          position="absolute"
          top={{ sm: 13 }}
          left="50%"
          transform="translate(-50%)"
          mt={{ sm: 3, md: 10 }}
          textAlign="center"
          width={{ sm: '100%' }}
        >
          <Typography variant={isMobile ? 'caption' : 'h3'}>This onboarding will take less than 2 minutes</Typography>
        </Box>
      )}
    </>
  );
};
