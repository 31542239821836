import { css } from '@emotion/react';
import type { Theme } from '@partstech/ui';

export const styles = {
  container: (theme: Theme) => css`
    display: flex;
    flex-direction: row;
    justify-content: center;

    ${theme.breakpoints.down('md')} {
      flex-direction: column;
    }

    ${theme.breakpoints.between('md', 'lg')} {
      justify-content: unset;
      overflow-x: auto;
    }

    ${theme.breakpoints.between('lg', 'xl')} {
      margin-left: ${theme.sizing(-2)};
    }
  `,
  title: (theme: Theme) => css`
    background: transparent;
    padding: ${theme.sizing(1, 2.75)};

    ${theme.breakpoints.down('md')} {
      padding-left: 0;
    }
  `,
  item: (theme: Theme) => css`
    cursor: pointer;
    padding: ${theme.sizing(1, 2.75)};
    color: ${theme.color.dark};
    border-radius: ${theme.sizing(1)};

    &:hover {
      background: ${theme.color.mono2};
    }

    ${theme.breakpoints.down('md')} {
      display: block;
      width: 100%;
      padding: ${theme.sizing(3, 0)};
      text-align: left;
      background: none;
      &:hover {
        background: none;
      }
    }
  `,
};
