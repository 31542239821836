import { useMutationStatus } from 'shared/api';
import { api as savedCartApi } from '../../queries/GetSavedCart.generated';
import { api as generatedApi } from './DeleteCartItems.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    DeleteCartItems: {
      invalidatesTags: (data) => {
        const res = data?.deleteCartItems;
        if (res && 'cart' in res && res.cart?.active) {
          return ['SavedCart', 'ActiveCart'];
        }
        return ['SavedCart'];
      },
      onQueryStarted: async (params, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;

          dispatch(
            savedCartApi.util.updateQueryData('GetSavedCart', { id: params.input.cartId }, (draft) => {
              if (draft.cart) {
                return {
                  cart: {
                    ...draft.cart,
                    orders: draft.cart.orders?.map((order) => ({
                      ...order,
                      items: order.items?.filter((item) => !params.input.itemIds.includes(item.id)),
                    })),
                  },
                };
              }
              return draft;
            })
          );
        } catch {
          /* empty */
        }
      },
    },
  },
});

const { useDeleteCartItemsMutation } = api;

export const useDeleteCartItems = () => {
  const [deleteCartItems, status] = useDeleteCartItemsMutation();

  useMutationStatus({ status });

  return [deleteCartItems, status] as const;
};
