import { api as rootApi } from 'store/queries/currentUser/GetCurrentUser.generated';
import { api as generatedApi } from './SetShowRetailPricing.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    SetShowRetailPricing: {
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;

        if (data.setShowRetailPricing) {
          const { shop } = data.setShowRetailPricing;

          if (shop) {
            dispatch(
              rootApi.util.updateQueryData('GetCurrentUser', undefined, (draft) => {
                if (draft.currentUser?.activeMember?.shop) {
                  Object.assign(draft.currentUser.activeMember.shop, shop);
                }
              })
            );
          }
        }
      },
    },
  },
});

export const { useSetShowRetailPricingMutation } = api;
