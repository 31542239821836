import { useEffect } from 'react';
import { shallowEqual } from 'react-redux';
import { tirePartId } from 'constant';
import { FullStoryEventNames, useFullStory } from 'integrations/fullstory';
import { useAppSelector } from 'store';
import { selectSupplierById } from 'store/entities/supplier';
import type { ProductQueryParams } from 'types/product';

export const useFailedProductTracking = (isFailed: boolean, query?: ProductQueryParams) => {
  const supplier = useAppSelector(
    (state) => selectSupplierById(state, query?.supplierId ? String(query?.supplierId) : null),
    shallowEqual
  );

  const { sendEvent } = useFullStory();

  useEffect(() => {
    if (isFailed && String(query?.part_term) === tirePartId) {
      sendEvent(FullStoryEventNames.FAILED_TIRE_FETCH, { supplier: supplier?.name, partNumberId: query?.partnumberid });
    }
  }, [isFailed, query?.part_term, query?.partnumberid, sendEvent, supplier?.name]);
};
