import { css } from '@emotion/react';
import { Box, Tooltip, Typography } from '@partstech/ui';
import { useCallback } from 'react';

const styles = {
  grossProfit: css`
    text-decoration: underline;
  `,
};

type Props = {
  hasPartMatrix: boolean;
  hasTireMatrix: boolean;
  hasParts: boolean;
  hasTires: boolean;
  grossProfit: number;
};

export const GrossProfitIndicator = ({ hasPartMatrix, hasTireMatrix, hasParts, hasTires, grossProfit }: Props) => {
  const getLabel = useCallback(() => {
    if (!hasPartMatrix && !hasTireMatrix && hasParts && hasTires) {
      return 'To view gross profit percentage, set up your retail pricing in My Shop settings under the Retail Pricing tab';
    }

    if (hasParts && !hasPartMatrix) {
      return 'Retail Pricing is not configured for parts';
    }

    if (hasTires && !hasTireMatrix) {
      return 'Retail Pricing is not configured for tires';
    }

    return `Gross profit: ${grossProfit}%`;
  }, [grossProfit, hasPartMatrix, hasParts, hasTireMatrix, hasTires]);

  return (
    <Box mr={1}>
      <Tooltip
        allowAutoPositioning
        tooltip={
          <Typography data-testid="grossProfitTooltip" color="white">
            {getLabel()}
          </Typography>
        }
      >
        <Typography color="linkText" variant="bodyText2" data-testid="grossProfitIndicator" css={styles.grossProfit}>
          GP
        </Typography>
      </Tooltip>
    </Box>
  );
};
