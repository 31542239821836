import { useMemo } from 'react';
import { useComparedQuoteCreation } from './useComparedQuoteCreation';
import { useComparedQuoteView } from './useComparedQuoteView';
import type { QuoteMode } from 'types/quote';

type Props = {
  mode?: QuoteMode;
  skip?: boolean;
};

export const useComparedQuote = (
  { skip = false, mode = 'create' }: Props = {
    skip: false,
    mode: 'create',
  }
) => {
  const createQuote = useComparedQuoteCreation();
  const viewQuote = useComparedQuoteView({ skip: mode !== 'view' || skip });

  const slots = useMemo(() => {
    if (mode === 'view') {
      return viewQuote.slots;
    }
    return createQuote.slots;
  }, [createQuote.slots, mode, viewQuote.slots]);

  const quote = useMemo(() => {
    if (mode === 'view') {
      return { ...createQuote, ...viewQuote };
    }
    return { ...viewQuote, ...createQuote };
  }, [createQuote, mode, viewQuote]);

  return useMemo(
    () => ({
      ...quote,
      slots,
    }),
    [quote, slots]
  );
};
