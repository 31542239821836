import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Swipe from 'react-easy-swipe';
import { BallControl } from './BallControl';
import { styles } from './Carousel.styles';
import { Next } from './Next';
import { Previous } from './Previous';

type CarouselProps = {
  children: React.ReactNode[];
  filesCount: number;
  className?: string;
  current?: number;
  disabled?: boolean;
  duration?: number;
  isVertical?: boolean;
  onChange?: (current: number) => void;
  title?: string;
  useBallControls?: boolean;
  isControlCurrentSlide?: boolean;
  isHiddenControls?: boolean;
};

export const Carousel = (props: CarouselProps) => {
  const {
    children,
    filesCount,
    className,
    duration = 1,
    isVertical = false,
    title = null,
    useBallControls = false,
    current = 0,
    disabled = false,
    onChange,
    isControlCurrentSlide = false,
    isHiddenControls = false,
  } = props;

  const container = useRef<HTMLDivElement>(null);
  const content = useRef<HTMLDivElement>(null);

  const getSize = useCallback(
    (el: Element): number => {
      const rect = el.getBoundingClientRect();
      const sizeName = isVertical ? 'height' : 'width';

      return rect[sizeName] ?? 0;
    },
    [isVertical]
  );

  const getShiftValue = useCallback(() => {
    const node = content.current;
    return node?.firstElementChild ? getSize(node.firstElementChild) : 0;
  }, [getSize]);
  const [currentItem, setCurrentItem] = useState(0);
  const [shift, setShift] = useState(getShiftValue() * current * -1);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  const getContainerValue = useCallback(() => {
    const node = container.current;
    return node ? getSize(node) : 0;
  }, [getSize]);

  const getMaxOffset = useCallback(() => {
    if (!container.current || !content.current) {
      return 0;
    }

    return parseInt((getContainerValue() / getShiftValue()).toString(), 10);
  }, [getContainerValue, getShiftValue]);

  const isNextDisabled = useMemo(() => {
    const maxOffset = getMaxOffset();
    const countWithType = isVertical ? filesCount - 1 - maxOffset : filesCount - maxOffset;

    return (
      currentItem === countWithType ||
      filesCount <= maxOffset ||
      filesCount === 0 ||
      !isLoaded ||
      currentItem === filesCount - 1
    );
  }, [filesCount, currentItem, getMaxOffset, isLoaded, isVertical]);

  const handleCurrent = useCallback(
    (currentNum: number) => (e: React.MouseEvent) => {
      e.preventDefault();

      setCurrentItem(currentNum);
      setShift(getShiftValue() * currentNum * -1);
    },
    [getShiftValue]
  );

  const handleChange = useCallback(() => {
    onChange && onChange(currentItem);
  }, [currentItem, onChange]);

  const handleNext = useCallback(() => {
    if (disabled || isNextDisabled) {
      return;
    }

    const left = filesCount - getMaxOffset();
    const next = currentItem + 1;
    const currentNum = next > left ? left : next;

    setCurrentItem(currentNum);
    setShift(getShiftValue() * currentNum * -1);

    !isControlCurrentSlide && handleChange();
  }, [
    currentItem,
    disabled,
    filesCount,
    getMaxOffset,
    getShiftValue,
    handleChange,
    isNextDisabled,
    isControlCurrentSlide,
  ]);

  const handlePrev = useCallback(() => {
    if (disabled) {
      return;
    }

    const next = currentItem - 1;
    const currentNum = next < 0 ? 0 : next;

    setCurrentItem(currentNum);
    setShift(getShiftValue() * currentNum * -1);

    !isControlCurrentSlide && handleChange();
  }, [disabled, currentItem, getShiftValue, isControlCurrentSlide, handleChange]);

  const onNextClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();

      handleNext();
    },
    [handleNext]
  );

  useEffect(() => {
    if (isControlCurrentSlide && current !== currentItem) {
      if (current > currentItem) {
        handleNext();
        return;
      }

      handlePrev();
    }
  }, [current, currentItem, getShiftValue, handleNext, handlePrev, isControlCurrentSlide]);

  const onPrevClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();

      handlePrev();
    },
    [handlePrev]
  );

  const prevDisabled = shift === 0 || !isLoaded;

  const showControlsButton = isHiddenControls ? false : !useBallControls;

  return (
    <div className={className} css={styles.wrapper(isVertical)} data-testid="carousel">
      {showControlsButton && <Previous disabled={prevDisabled} isVertical={isVertical} onClick={onPrevClick} />}

      <div css={styles.container(isVertical, showControlsButton)} ref={container}>
        {title && <div>{title}</div>}

        {/* @ts-ignore */}
        <Swipe onSwipeLeft={handleNext} onSwipeRight={handlePrev} innerRef={() => null}>
          <div css={styles.list(isVertical, duration, shift)} ref={content}>
            {children}
          </div>
        </Swipe>
      </div>

      {showControlsButton && <Next disabled={isNextDisabled} isVertical={isVertical} onClick={onNextClick} />}

      {useBallControls && children.length > 1 && (
        <div css={styles.ballControls}>
          <Previous
            disabled={prevDisabled}
            isVertical={isVertical}
            useBallControls={useBallControls}
            onClick={onPrevClick}
          />

          {children.map((_: unknown, key) => (
            <BallControl key={key} isActive={key === currentItem} onClick={handleCurrent(key)} />
          ))}

          <Next
            disabled={isNextDisabled}
            isVertical={isVertical}
            useBallControls={useBallControls}
            onClick={onNextClick}
          />
        </div>
      )}
    </div>
  );
};
