import { Box } from '@partstech/ui';
import { FormSelectBox } from '@partstech/ui/forms';
import { uniqBy } from '@partstech/ui/utils';
import { isFulfilled } from '@reduxjs/toolkit';
import { useCallback, useMemo } from 'react';
import { createStoreFromData } from 'factories';
import { useDynamicOptions, type DynamicOptionsFetch } from 'shared/lib/form';
import { useAppDispatch } from 'store';
import { getStoresBySupplierId } from 'store/entities/store';
import type { Option } from '@partstech/ui';
import type { Supplier } from 'models';

type SupplierStoreSelectBoxProps = {
  supplier: Supplier;
  storeId?: string;
};

const emptyOptions: Option[] = [];

export const LocationSelectBox = ({ supplier, storeId }: SupplierStoreSelectBoxProps) => {
  const dispatch = useAppDispatch();

  const fetchSupplierStore: DynamicOptionsFetch = useCallback(
    async ({ keyword, page, perPage }) => {
      if (supplier) {
        const action = await dispatch(
          getStoresBySupplierId({
            supplierId: supplier.id,
            keyword,
            from: (page - 1) * perPage,
            storeId: Number(storeId),
          })
        );

        if (isFulfilled(action)) {
          const stores = action.payload.map((store) => createStoreFromData(store, null));

          return stores.map((store) => ({
            value: store.id,
            text: store.formattedAddress ?? '',
          }));
        }
      }

      return emptyOptions;
    },
    [dispatch, storeId, supplier]
  );

  const { options, onSearchInputChange, onScrollMore } = useDynamicOptions(fetchSupplierStore);

  const uniqueOptions = useMemo(() => uniqBy(options, 'value'), [options]);

  return (
    <Box mb={{ sm: 4, md: 6 }}>
      <FormSelectBox
        label="Location"
        name="storeId"
        options={uniqueOptions}
        onInputChange={onSearchInputChange}
        onScrollMore={onScrollMore}
        isSearchable
      />
    </Box>
  );
};
