import { FullStory } from '@fullstory/browser';

export const getFullStorySessionId = (): string => {
  try {
    return FullStory('getSession', { format: 'id' }) ?? '';
  } catch (e) {
    return '';
  }
};

export const getFullStorySessionIdAsync = async (): Promise<string> => {
  try {
    return (await FullStory('getSessionAsync', { format: 'id' })) ?? '';
  } catch (e) {
    return '';
  }
};
