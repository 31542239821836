import { Button, Box, ButtonGroup, Typography } from '@partstech/ui';
import { HookForm, FormPasswordField } from '@partstech/ui/forms';
import { required } from 'shared/lib/form';
import { useChangePasswordForm } from './useChangePasswordForm';

type Props = {
  showCurrentPasswordField?: boolean;
  onSubmit: (newPassword: string) => void;
  onCancel?: () => void;
};

export const ChangePasswordForm = ({ showCurrentPasswordField, onCancel, onSubmit }: Props) => {
  const { form, formValues, isDisabled, isLoading, submitForm } = useChangePasswordForm({
    showCurrentPasswordField,
    onSubmit,
  });

  return (
    <HookForm form={form} onSubmit={submitForm} data-testid="passwordForm">
      <Typography>
        Updates will be applied to your profile across all PartsTech apps. Adding a password is optional.
      </Typography>

      <Box mt={4}>
        {showCurrentPasswordField && (
          <FormPasswordField name="currentPassword" label="Current password" hideRequiredMark rules={{ required }} />
        )}

        <Box mt={4}>
          <FormPasswordField
            name="newPassword"
            label="New password"
            withComplexValidation
            oldPassword={formValues.currentPassword}
            hideRequiredMark
            rules={{ required }}
          />
        </Box>

        <Box mt={4}>
          <FormPasswordField
            name="repeatPassword"
            label="Confirm new password"
            hideRequiredMark
            rules={{ required, validate: (value) => value === formValues.newPassword || 'Passwords don’t match' }}
          />
        </Box>
      </Box>

      <ButtonGroup mt={6} flexDirection="column" gap={3}>
        <Button type="submit" fullWidth isLoading={isLoading} disabled={isDisabled} data-testid="submit">
          Save changes
        </Button>

        {onCancel && (
          <Button variant="text" fullWidth onClick={onCancel} data-testid="cancelButton">
            Cancel
          </Button>
        )}
      </ButtonGroup>
    </HookForm>
  );
};
