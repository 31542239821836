import { usePrevious } from '@partstech/ui/hooks';
import { sortUniq } from '@partstech/ui/utils';
import { useMemo } from 'react';
import { useGetVehiclesByYearMake } from '../../../api';
import { filterParametersBySearch } from '../../../utils';
import type { CommonHookProps } from '../types';

export const useGetModels = ({ makeId, year, searchValue }: Omit<CommonHookProps, 'modelId'>) => {
  const { vehicles } = useGetVehiclesByYearMake(Number(year) ?? null, makeId);

  const previousSearchValue = usePrevious(searchValue);

  const rawModels = useMemo(
    () =>
      sortUniq(
        vehicles.map((vehicle) => vehicle.model),
        'name'
      ),
    [vehicles]
  );

  const models = filterParametersBySearch(rawModels, searchValue);

  if (!previousSearchValue && searchValue) {
    return { models: rawModels };
  }

  return { models };
};
