/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type UpdateAllowOrderEmailsMutationVariables = Types.Exact<{
  input: Types.SetAllowOrderEmailsInput;
}>;

export type UpdateAllowOrderEmailsMutation = {
  __typename?: 'Mutation';
  setAllowOrderEmails?: {
    __typename?: 'SetAllowOrderEmailsPayload';
    user?: { __typename?: 'User'; allowOrdersEmail: Types.Scalars['Boolean']['input'] } | null;
  } | null;
};

export const UpdateAllowOrderEmailsDocument = `
    mutation UpdateAllowOrderEmails($input: SetAllowOrderEmailsInput!) {
  setAllowOrderEmails(input: $input) {
    user {
      allowOrdersEmail
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    UpdateAllowOrderEmails: build.mutation<UpdateAllowOrderEmailsMutation, UpdateAllowOrderEmailsMutationVariables>({
      query: (variables) => ({ document: UpdateAllowOrderEmailsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUpdateAllowOrderEmailsMutation } = injectedRtkApi;
