import { css } from '@emotion/react';
import { GraphicButton, Skeleton } from '@partstech/ui';
import type { EntityManagementSystem } from '../../api/managementSystems';
import type { GraphicButtonProps, Theme } from '@partstech/ui';

const styles = {
  card: (theme: Theme) => css`
    ${theme.breakpoints.down(376)} {
      max-width: 47.5%;
    }
  `,
};

type Props = {
  caption: GraphicButtonProps['caption'];
  isActive: boolean;
  logo: EntityManagementSystem['logo'];
  onClick: () => void;
  isLoading?: boolean;
};

export const ManagementSystemCard = ({ caption, isActive, logo, onClick, isLoading }: Props) => {
  if (isLoading) {
    return <Skeleton width="156" height="96" roundsCornersX="8" />;
  }

  return (
    <GraphicButton
      css={styles.card}
      isActive={isActive}
      onClick={onClick}
      imageSrc={logo || undefined}
      cardWidth={39}
      cardHeight={24}
      imageHeight={14}
      caption={caption}
      justifyContent="center"
      data-testid="systemCard"
    />
  );
};
