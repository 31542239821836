export const splitIntoColumns = <T>(array: T[], perColumn: number): T[][] => {
  if (array.length <= perColumn) {
    return [array];
  }

  if (array.length > perColumn && array.length < perColumn * 2) {
    return [array.slice(0, perColumn), array.slice(perColumn)];
  }

  const arrayMiddle = Math.ceil(array.length / 2);

  return [array.slice(0, arrayMiddle), array.slice(arrayMiddle)];
};
