import { Box } from '@partstech/ui';
import { Value } from './Value';
import type { ValueProps } from './Value';
import type { BoxProps } from '@partstech/ui';

type Props = BoxProps & ValueProps;

export const FeatureValue = ({ isEnabled, partialEnabled, ...props }: Props) => (
  <Box className="plan-column" display="flex" justifyContent="center" alignItems="center" {...props}>
    <Value isEnabled={isEnabled} partialEnabled={partialEnabled} />
  </Box>
);
