import { css } from '@emotion/react';
import { TextField, Card } from '@partstech/ui';
import { PlacesMenu } from './PlacesMenu';
import { useGooglePlacesSearch } from './useGooglePlacesSearch';
import type { DetailsByPlate } from '../../lib/types';
import type { Theme } from '@partstech/ui';

const styles = {
  wrapper: (hasOptions: boolean) => (theme: Theme) => css`
    ${hasOptions &&
    css`
      border-radius: ${theme.sizing(0, 0, 4, 4)};
      .text-field-container {
        margin: 0;
        border: none;
        border-radius: ${theme.sizing(4, 4, 0, 0)};
        border-bottom: 1px solid ${theme.color.mono17};
        z-index: ${theme.zIndex.default};

        &:focus-within {
          border-bottom: 1px solid ${theme.color.mono17};
          box-shadow: none;
        }
      }

      .menu {
        margin-top: -5px;
        z-index: ${theme.zIndex.initial};
        border-radius: ${theme.sizing(0, 0, 4, 4)};

        > div {
          padding: 0;
        }
      }
    `}
  `,
};

type Props = {
  placeholder?: string;
  onSelect: (values: DetailsByPlate) => void;
  onChangeInputValue?: (value: string) => void;
};

export const GooglePlacesSearch = ({ placeholder, onSelect, onChangeInputValue }: Props) => {
  const { value, searchInputRef, options, changeValue, isOpenMenu, isLoading, selectNotFound, selectOption } =
    useGooglePlacesSearch({ onSelect, onChangeInputValue });

  return (
    <Card elevation={isOpenMenu ? 8 : undefined} borderWidth={0} css={styles.wrapper(isOpenMenu)}>
      <TextField
        placeholder={placeholder}
        leadingIcon="search"
        value={value}
        onChange={changeValue}
        ref={searchInputRef}
      />

      {isOpenMenu && (
        <PlacesMenu options={options} onSelect={selectOption} onSelectNotFound={selectNotFound} isLoading={isLoading} />
      )}
    </Card>
  );
};
