import { Box } from '@partstech/ui';
import { GOOGLE_ONE_TAP_CONTAINER_ID } from 'integrations/google';
import { useGoogleOneTap } from './useGoogleOneTap';
import type { BoxProps } from '@partstech/ui';
import type { UserAccountStatus } from 'shared/api';

type Props = {
  clientId: string;
  onSuccess: (accountStatus?: UserAccountStatus) => Promise<void>;
} & BoxProps;

export const GoogleOneTap = ({ clientId, onSuccess, ...props }: Props) => {
  useGoogleOneTap({ clientId, onSuccess });

  return <Box width="391px" ml="-15px" id={GOOGLE_ONE_TAP_CONTAINER_ID} {...props} />;
};
