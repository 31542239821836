import { useCallback, useEffect, useState } from 'react';
import { useMatch } from 'react-router-dom';
import { useAppNavigate } from 'app/AppRouter/useAppNavigate';
import { CATALOG_GROUP, Routes } from 'constant';
import { useSearchParams, useGetSearchUrl } from 'hooks/search';
import { useAfterMarketSupplierAccounts, useDealerSupplierAccounts } from 'hooks/supplierAccounts';
import { useAppDispatch } from 'store';
import { pickSupplierAccount } from 'store/entities/supplierAccounts';
import { SupplierTypeContextProvider } from './SupplierTypeContext';
import type { SupplierTypes } from './SupplierTypeContext';
import type { PropsWithChildren } from 'react';

export const SupplierTypeProvider = ({ children }: PropsWithChildren) => {
  const { searchParams, isCatalogSelected, isTiresSearch } = useSearchParams();
  const navigate = useAppNavigate();
  const dispatch = useAppDispatch();
  const isSearchResultsPage = useMatch(Routes.SEARCH_RESULTS);
  const { getSearchUrl } = useGetSearchUrl();

  const [activeSupplierType, setActiveSupplierType] = useState<SupplierTypes>();

  const { afterMarketAccounts, isFetching: isFetchingAfterMarketAccounts } = useAfterMarketSupplierAccounts();
  const { dealerAccounts, isFetching: isFetchingDealerAccounts } = useDealerSupplierAccounts();

  const isFetching = isFetchingAfterMarketAccounts || isFetchingDealerAccounts;

  const handleTagChange = useCallback(
    (type: SupplierTypes) => {
      if (activeSupplierType === type) {
        return;
      }

      setActiveSupplierType(type);

      if (type === 'dealers') {
        dispatch(pickSupplierAccount(null));
        navigate(getSearchUrl({ ...searchParams, selected_distributor: undefined, dealers: 1 }));

        return;
      }

      if (type === 'afterMarket') {
        dispatch(pickSupplierAccount(null));
        navigate(getSearchUrl({ ...searchParams, selected_distributor: undefined, dealers: undefined }));

        return;
      }

      if (type === 'catalog') {
        dispatch(pickSupplierAccount(null));
        navigate(getSearchUrl({ ...searchParams, selected_distributor: `${CATALOG_GROUP}`, dealers: undefined }));
      }
    },
    [activeSupplierType, dispatch, getSearchUrl, navigate, searchParams]
  );

  useEffect(() => {
    if (!isSearchResultsPage || isTiresSearch || activeSupplierType || isFetching) {
      return;
    }

    if (isCatalogSelected) {
      navigate(getSearchUrl({ ...searchParams, selected_distributor: `${CATALOG_GROUP}`, dealers: undefined }));

      return;
    }

    if (afterMarketAccounts.length === 0 && dealerAccounts.length > 0) {
      navigate(getSearchUrl({ ...searchParams, dealers: 1 }));

      return;
    }

    if (dealerAccounts.length === 0 && searchParams.dealers) {
      navigate(getSearchUrl({ ...searchParams, dealers: undefined }));
    }
  }, [
    activeSupplierType,
    afterMarketAccounts.length,
    dealerAccounts.length,
    getSearchUrl,
    isCatalogSelected,
    isFetching,
    isSearchResultsPage,
    isTiresSearch,
    navigate,
    searchParams,
  ]);

  useEffect(() => {
    if (!isSearchResultsPage || isTiresSearch || isFetching) {
      return;
    }

    if (searchParams.dealers) {
      setActiveSupplierType('dealers');

      return;
    }

    if (isCatalogSelected) {
      setActiveSupplierType('catalog');

      return;
    }

    setActiveSupplierType('afterMarket');
  }, [isCatalogSelected, isFetching, isSearchResultsPage, isTiresSearch, searchParams.dealers]);

  const value = {
    activeSupplierType,
    setActiveSupplierType: handleTagChange,
  };

  return <SupplierTypeContextProvider value={value}>{children}</SupplierTypeContextProvider>;
};
