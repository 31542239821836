import { api as generatedApi } from './GetSubscription.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    GetSubscription: {
      providesTags: ['Subscription'],
    },
  },
});

const { useGetSubscriptionQuery } = api;

export const useGetSubscription = (skip?: boolean) => {
  const { data, ...rest } = useGetSubscriptionQuery(undefined, { skip });

  const subscription = data?.currentUser?.activeMember?.shop?.shopSubscription;

  return {
    ...rest,
    subscription,
  };
};

export const subscriptionApi = api;
