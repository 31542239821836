import { useCallback } from 'react';
import { api as activeCartApi } from 'features/cart/api/queries/GetActiveCart.generated';
import { useMutationStatus } from 'shared/api';
import { api as generatedApi } from './DeleteCartOrder.generated';
import type { CartOrder } from 'models';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    DeleteActiveCartOrder: {
      onQueryStarted: async ({ orderId }, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;

        if (data.deleteActiveCartOrder && 'deletedOrderId' in data.deleteActiveCartOrder) {
          dispatch(
            activeCartApi.util.updateQueryData('GetActiveCart', undefined, (draft) => {
              if (draft?.activeCart?.orders) {
                return {
                  activeCart: {
                    ...draft.activeCart,
                    orders: draft.activeCart.orders.filter((order) => order.id !== orderId),
                  },
                };
              }

              return draft;
            })
          );
        }
      },
    },
  },
});

const { useDeleteActiveCartOrderMutation } = api;

export const useDeleteCartOrder = () => {
  const [mutate, status] = useDeleteActiveCartOrderMutation();

  useMutationStatus({ status });

  const removeOrder = useCallback(
    (order: CartOrder) => {
      mutate({ orderId: order.id });
    },
    [mutate]
  );

  return { removeOrder, status };
};
