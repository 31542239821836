/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import type * as Types from '../../../shared/api/graphql/graphql';

export type VehicleFragment = {
  __typename?: 'Vehicle';
  id: string;
  type: Types.VehicleType;
  regions?: Array<Types.VehicleRegion> | null;
  year: number;
  make: { __typename?: 'VehicleMake'; id: string; name: Types.Scalars['String']['input']; priority: number };
  model: { __typename?: 'VehicleModel'; id: string; name: Types.Scalars['String']['input'] };
  subModel: { __typename?: 'VehicleSubModel'; id: string; name: Types.Scalars['String']['input'] };
  engine: { __typename?: 'VehicleEngine'; id: string; name: Types.Scalars['String']['input'] };
};

export const VehicleFragmentDoc = `
    fragment Vehicle on Vehicle {
  id
  type
  regions
  year
  make {
    id
    name
    priority
  }
  model {
    id
    name
  }
  subModel {
    id
    name
  }
  engine {
    id
    name
  }
}
    `;
