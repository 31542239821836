import { isMatchingString } from 'shared/lib/string';
import type { PartSubCategory } from './PartSubCategory';
import type { SearchEntryPointInterface } from 'features/searchForm';

export class PartCategory implements SearchEntryPointInterface {
  id: string;

  name: string;

  subCategories: PartSubCategory[];

  constructor(id: string, name: string, subCategories: PartSubCategory[]) {
    this.id = id;
    this.name = name;
    this.subCategories = subCategories;
  }

  toString(): string {
    return this.name;
  }

  isVehicleRequired(): boolean {
    return true;
  }

  getClassName(): string {
    return 'fs-part-category';
  }

  populateInput(): string {
    return '';
  }

  isMatchedToString(search: string): boolean {
    return isMatchingString(this.name, search);
  }
}
