/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { ConfigurationFieldFragmentDoc } from './ConfigurationField.generated';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type SupplierFragment = {
  __typename?: 'Supplier';
  allowCustomNotes: Types.Scalars['Boolean']['input'];
  allowCustomPurchaseOrderNumbers: Types.Scalars['Boolean']['input'];
  allowStockOrders: Types.Scalars['Boolean']['input'];
  allowStoreSelect: Types.Scalars['Boolean']['input'];
  autoValidation: Types.Scalars['Boolean']['input'];
  checkoutMessage?: Types.Scalars['String']['input'] | null;
  comingSoon: Types.Scalars['Boolean']['input'];
  coreChargePolicy?: Types.Scalars['String']['input'] | null;
  coreReturns: Types.Scalars['Boolean']['input'];
  displayAction: Types.DisplayAction;
  hideLocation: Types.Scalars['Boolean']['input'];
  id: string;
  isTire: Types.Scalars['Boolean']['input'];
  isTireConnect: Types.Scalars['Boolean']['input'];
  lockedStore: Types.Scalars['Boolean']['input'];
  logo: Types.Scalars['String']['input'];
  marketingDescription?: Types.Scalars['String']['input'] | null;
  name: Types.Scalars['String']['input'];
  orderingSupported: Types.Scalars['Boolean']['input'];
  paid: Types.Scalars['Boolean']['input'];
  registrationInstruction?: Types.Scalars['String']['input'] | null;
  returnPolicy?: Types.Scalars['String']['input'] | null;
  type: Types.SupplierType;
  tutorialUrl?: Types.Scalars['String']['input'] | null;
  slug: Types.Scalars['String']['input'];
  additionalInformationConfiguration?: {
    __typename?: 'AdditionalInformation';
    title: Types.Scalars['String']['input'];
    description: Types.Scalars['String']['input'];
    fields: Array<{
      __typename?: 'ConfigurationField';
      helper?: Types.Scalars['String']['input'] | null;
      internalName: Types.Scalars['String']['input'];
      label: Types.Scalars['String']['input'];
      link?: Types.Scalars['String']['input'] | null;
      options: Array<Types.Scalars['String']['input']>;
      required: Types.Scalars['Boolean']['input'];
      type: Types.ConfigurationFieldType;
      validationRules?: {
        __typename?: 'ValidationRule';
        inputType: Types.ValidationInputType;
        length?: Types.Scalars['String']['input'] | null;
      } | null;
    }>;
  } | null;
  credentialsConfiguration: Array<{
    __typename?: 'ConfigurationField';
    helper?: Types.Scalars['String']['input'] | null;
    internalName: Types.Scalars['String']['input'];
    label: Types.Scalars['String']['input'];
    link?: Types.Scalars['String']['input'] | null;
    options: Array<Types.Scalars['String']['input']>;
    required: Types.Scalars['Boolean']['input'];
    type: Types.ConfigurationFieldType;
    validationRules?: {
      __typename?: 'ValidationRule';
      inputType: Types.ValidationInputType;
      length?: Types.Scalars['String']['input'] | null;
    } | null;
  }>;
  outageType?: {
    __typename?: 'Outage';
    type: Types.OutageType;
    message?: Types.Scalars['String']['input'] | null;
  } | null;
  serveLocations: Array<{
    __typename?: 'ServeLocation';
    country: Types.Country;
    regions: Array<Types.Scalars['String']['input']>;
  }>;
};

export const SupplierFragmentDoc = `
    fragment Supplier on Supplier {
  additionalInformationConfiguration {
    title
    description
    fields {
      ...ConfigurationField
    }
  }
  allowCustomNotes
  allowCustomPurchaseOrderNumbers
  allowStockOrders
  allowStoreSelect
  autoValidation
  checkoutMessage
  comingSoon
  coreChargePolicy
  coreReturns
  credentialsConfiguration {
    ...ConfigurationField
  }
  displayAction
  hideLocation
  id
  isTire
  isTireConnect
  lockedStore
  logo
  marketingDescription
  name
  orderingSupported
  outageType {
    type
    message
  }
  paid
  registrationInstruction
  returnPolicy
  serveLocations {
    country
    regions
  }
  type
  tutorialUrl
  slug
}
    ${ConfigurationFieldFragmentDoc}`;
