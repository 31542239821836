/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import { CartLaborItemFragmentDoc } from '../../../../../shared/api/graphql/fragment/CartLaborItem.generated';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type UpdateLaborItemsInCartMutationVariables = Types.Exact<{
  items: Array<Types.UpdateActiveCartLaborItemInput> | Types.UpdateActiveCartLaborItemInput;
}>;

export type UpdateLaborItemsInCartMutation = {
  __typename?: 'Mutation';
  batchUpdateActiveCartLaborItems?: {
    __typename?: 'BatchUpdateActiveCartLaborItemsPayload';
    error?: {
      __typename?: 'UpdateActiveCartLaborItemErrorPayload';
      errorMessage: Types.Scalars['String']['input'];
    } | null;
    items?: Array<{
      __typename?: 'CartLaborItem';
      id: string;
      hours: Types.Scalars['Float']['input'];
      defaultHours: Types.Scalars['Float']['input'];
      vin?: Types.Scalars['String']['input'] | null;
      orderItemId?: string | null;
      name: Types.Scalars['String']['input'];
      notes?: Types.Scalars['String']['input'] | null;
      skill: Types.Scalars['String']['input'];
      identifiers?: { __typename?: 'Mitchell1Identifiers' } | { __typename?: 'MotorIdentifiers'; id: string } | null;
      vehicle?: { __typename?: 'Vehicle'; id: string } | null;
    }> | null;
  } | null;
};

export const UpdateLaborItemsInCartDocument = `
    mutation UpdateLaborItemsInCart($items: [UpdateActiveCartLaborItemInput!]!) {
  batchUpdateActiveCartLaborItems(input: {items: $items}) {
    error {
      errorMessage
    }
    items {
      ...CartLaborItem
    }
  }
}
    ${CartLaborItemFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    UpdateLaborItemsInCart: build.mutation<UpdateLaborItemsInCartMutation, UpdateLaborItemsInCartMutationVariables>({
      query: (variables) => ({ document: UpdateLaborItemsInCartDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUpdateLaborItemsInCartMutation } = injectedRtkApi;
