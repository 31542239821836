import { css } from '@emotion/react';
import { Box, Button, Card, Divider, ImageFallback, Status, Typography } from '@partstech/ui';
import { useCallback, useRef } from 'react';
import { Price } from 'components/Price';
import { StoreAvailability } from 'components/Product/StoreAvailability';
import { VehicleName } from 'components/VehicleString/VehicleName';
import { LaborInfo } from 'features/labor';
import { useScrollIntoView } from 'shared/lib/react-dom';
import { OrderItemEtaMessage } from 'shared/ui';
import { Attributes } from '../CartOrderItem/PartInfo/Attributes';
import { DeliveryNotes } from '../CartOrderItem/PartInfo/DeliveryNotes';
import { Notes } from '../CartOrderItem/PartInfo/Notes';
import { Warnings } from '../CartOrderItem/PartInfo/Warnings';
import { QuantityInput } from '../CartOrderItem/QuantityInput';
import type { LaborLine } from 'features/labor';
import type { CartOrderItem } from 'models';

const styles = {
  image: css`
    object-fit: contain;
  `,
};

type Props = {
  orderItem: CartOrderItem;
  showRetailPrice: boolean;
  disabled: boolean;
  onChangeQuantity: (orderItem: CartOrderItem, quantity: number) => void;
  onRemove: (orderItem: CartOrderItem) => void;
  autoScroll?: boolean;
  laborLines: LaborLine[];
  laborRate: number;
  showETA?: boolean;
};

export const CartOrderItemMobile = ({
  orderItem,
  showRetailPrice,
  disabled,
  onChangeQuantity,
  onRemove,
  autoScroll,
  laborLines,
  laborRate,
  showETA,
}: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useScrollIntoView(containerRef, { block: 'center', behavior: 'smooth' }, { skip: !autoScroll });

  const handleRemove = useCallback(() => {
    onRemove(orderItem);
  }, [onRemove, orderItem]);

  const handleChangeQuantity = useCallback(
    (quantity: number) => {
      onChangeQuantity(orderItem, quantity);
    },
    [onChangeQuantity, orderItem]
  );

  return (
    <>
      <Divider my={3} />

      <Box display="flex" flexDirection="column" gap={3} p={3} ref={containerRef}>
        <Box display="flex" gap={3} justifyContent="space-between">
          <Box display="flex" gap={3}>
            <ImageFallback src={orderItem.image} width={48} height={48} css={styles.image} />

            <div>
              <Typography variant="bodyText1">{orderItem.title}</Typography>

              <Typography color="monoDark" variant="overline">
                {orderItem.partNumber}
              </Typography>
            </div>
          </Box>

          <Button
            variant="text"
            leadingIcon="delete"
            color="negative"
            onClick={handleRemove}
            disabled={disabled}
            size="dense"
          />
        </Box>

        <Warnings warnings={orderItem.warnings} />

        <StoreAvailability
          quote={orderItem.quote}
          store={orderItem.quote?.lines[0]}
          variant={orderItem.quote?.variant}
          allowSelect={false}
          usePortal
        />

        {showETA && orderItem.quote && (
          <OrderItemEtaMessage
            deliveryNotes={orderItem.deliveryNotes}
            isInNetwork={orderItem.quote.isInNetwork()}
            availabilityGroup={orderItem.quote.lines[0]?.group}
            supplierId={Number(orderItem.supplierId)}
          />
        )}

        <Box display="flex" justifyContent="space-between">
          <QuantityInput quantity={orderItem.quantity} onChangeQuantity={handleChangeQuantity} disabled={disabled} />

          <Price
            price={orderItem.price}
            coreCharge={orderItem.coreCharge}
            retailPrice={orderItem.retailPrice}
            listPrice={orderItem.listPrice}
            mapPrice={orderItem.mapPrice}
            showOnlyRetailPrice={showRetailPrice}
            isTire={orderItem.isTire}
            priceAlign="left"
          />
        </Box>

        {orderItem.error && (
          <Box textAlign="right">
            <Status variant="light" color="negative">
              {orderItem.error}
            </Status>
          </Box>
        )}

        {orderItem.vehicle && <VehicleName vehicle={orderItem.vehicle} multiline />}

        <Attributes attributes={orderItem.attributes} />

        {!showETA && <DeliveryNotes notes={orderItem.deliveryNotes} />}

        <Notes notes={orderItem.notes} />

        {laborLines.length > 0 && (
          <Card radius={4} mt={6}>
            <LaborInfo m={{ sm: 2, md: 0 }} mt={{ md: 3 }} laborLines={laborLines} rate={laborRate} />
          </Card>
        )}
      </Box>
    </>
  );
};
