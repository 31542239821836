import type { Supplier } from './Supplier';
import type { Address as AddressType } from 'entities/address';
import type { AdditionalInformation, Coordinates } from 'shared/api';

type StoreData = {
  additionalInformation?: AdditionalInformation | null;
  address?: AddressType | null;
  coordinates?: Coordinates | null;
  distance: number | null;
  dropship: boolean;
  hotshot: boolean;
  hotshotOutOfRange: boolean;
  id: string;
  logo?: string | null;
  name: string | null;
  national?: boolean;
  orderingSupported?: boolean;
  phone?: string | null;
  pickUp: boolean;
  servesLocation?: boolean;
  warehouse: boolean;
};

export class Store {
  $additionalInformation: AdditionalInformation | null = null;

  address: AddressType | null = null;

  coordinates: Coordinates | null = null;

  distance: number | null = null;

  dropship: boolean;

  hotshot: boolean;

  hotshotOutOfRange: boolean;

  id: string;

  $logo: string | null = null;

  $name: string | null = null;

  national: boolean = false;

  phone: string | null = null;

  pickUp: boolean;

  supplier: Supplier | null = null;

  warehouse: boolean;

  $orderingSupported?: boolean = false;

  $servesLocation?: boolean;

  constructor(store: StoreData) {
    this.$additionalInformation = store.additionalInformation ?? null;
    this.address = store.address ?? null;
    this.coordinates = store.coordinates ?? null;
    this.distance = store.distance;
    this.dropship = store.dropship;
    this.hotshot = store.hotshot;
    this.hotshotOutOfRange = store.hotshotOutOfRange;
    this.id = store.id;
    this.$logo = store.logo ?? null;
    this.$name = store.name;
    this.national = store.national ?? false;
    this.phone = store.phone ?? null;
    this.pickUp = store.pickUp;
    this.warehouse = store.warehouse;
    this.$orderingSupported = store.orderingSupported;
    this.$servesLocation = store.servesLocation;
  }

  get formattedAddress(): string | null {
    if (!this.warehouse && this.address) {
      return `${this.address}`;
    }

    return this.name;
  }

  get name(): string {
    if (this.supplier?.isTire) {
      return this.supplier.name ?? this.$name ?? '';
    }

    return this.$name ?? '';
  }

  get logo(): string {
    return this.$logo ?? this.supplier?.logo ?? '';
  }

  /**
   * @deprecated To use store from GraphQL, use supplier.orderingSupported
   */
  get isOrderingSupported(): boolean {
    return Boolean(this.supplier?.orderingSupported) || Boolean(this.$orderingSupported);
  }

  /**
   * @deprecated To use store from GraphQL, use supplier.isServesLocation(address)
   */
  isServesLocation(address: { country?: AddressType['country']; state?: AddressType['state'] } = {}) {
    return this.supplier?.isServesLocation(address) || this.$servesLocation;
  }

  /**
   * @deprecated To use store from GraphQL, use supplier.additionalInformation
   */
  get additionalInformation(): AdditionalInformation | null {
    return this.supplier?.additionalInformation || this.$additionalInformation;
  }

  setAddress(address: AddressType) {
    this.address = address;
  }

  setSupplier(supplier: Supplier | null) {
    this.supplier = supplier;
  }
}
