import { routingParams as configRoutingParams } from 'app/config';
import { useGetAppConfig } from 'store/queries/appConfig/useGetAppConfig';

type Args = Partial<{
  skip: boolean;
}>;

export const useSettings = ({ skip }: Args = {}) => {
  const { _shop_host: shopDomain, _supplier_host: supplierPortalDomain } = configRoutingParams;

  const { reCaptchaKey, stripeKey, googleClientOAuthId, isError, isLoading, isSuccess, refetch } = useGetAppConfig({
    skip,
  });

  return {
    reCaptchaKey,
    shopDomain,
    supplierPortalDomain,
    stripeKey,
    googleClientOAuthId,
    isError,
    isLoading,
    isSuccess,
    refetch,
  };
};
