import { combineReducers } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { useDispatch, useSelector } from 'react-redux';
import { api, shopApi, gUploadApi, gExportApi } from 'shared/api';
import { configureStore } from './configureStore';
import { entities } from './entities';
import { features } from './features';
import type { TypedUseSelectorHook } from 'react-redux';

export type { ActionWithPayload, ThunkResult } from './types';

export const reducer = combineReducers({
  entities,
  features,
  [api.reducerPath]: api.reducer,
  [gUploadApi.reducerPath]: gUploadApi.reducer,
  [gExportApi.reducerPath]: gExportApi.reducer,
  [shopApi.reducerPath]: shopApi.reducer,
});

export const store = configureStore(reducer);

setupListeners(store.dispatch);

export type RootDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof reducer>;

export const useAppDispatch = () => useDispatch<RootDispatch>();

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
