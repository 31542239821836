import { api as generatedApi } from './UpdatePricePackage.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    UpdatePricePackage: {
      invalidatesTags: ['PricePackage', 'ActiveCart'],
    },
  },
});

export const { useUpdatePricePackageMutation } = api;
