/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type CreateUserByRegistrationMutationVariables = Types.Exact<{
  input: Types.CreateUserByRegistrationInput;
}>;

export type CreateUserByRegistrationMutation = {
  __typename?: 'Mutation';
  createUser?: { __typename?: 'CreateUserPayload'; success: Types.Scalars['Boolean']['input'] } | null;
};

export const CreateUserByRegistrationDocument = `
    mutation CreateUserByRegistration($input: CreateUserByRegistrationInput!) {
  createUser(input: {registration: $input}) {
    success
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    CreateUserByRegistration: build.mutation<
      CreateUserByRegistrationMutation,
      CreateUserByRegistrationMutationVariables
    >({
      query: (variables) => ({ document: CreateUserByRegistrationDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useCreateUserByRegistrationMutation } = injectedRtkApi;
