import { Box, Link, Typography } from '@partstech/ui';
import { shopTypeOptions } from 'entities/signUp';
import { QuestionOptions, StepTitle } from 'features/signUp';
import { useShopType } from './useShopType';

export const ShopType = () => {
  const { firstName, defaultOption, selectOption, selectDIY } = useShopType();

  return (
    <Box p={4} textAlign={{ sm: 'center', md: 'left' }} mt={{ sm: 4 }}>
      <Typography variant="subtitle2" color="subtleText">
        Welcome {firstName}!
      </Typography>

      <StepTitle mt={4} mb={2}>
        What type of shop are you?
      </StepTitle>

      <div>
        <Typography variant="bodyText2" color="subtleText" component="span">
          Not a business? Click
        </Typography>{' '}
        <Link to="#" color="linkText" underline="none" onClick={selectDIY}>
          here
        </Link>{' '}
        <Typography variant="bodyText2" color="subtleText" component="span">
          to proceed.
        </Typography>
      </div>

      <QuestionOptions
        defaultOption={defaultOption}
        options={shopTypeOptions}
        gap={{ sm: 4, md: [4, 3] }}
        optionMinWidth={{ sm: 55, md: 0 }}
        justifyContent={{ sm: 'center', md: 'flex-start' }}
        onSelect={selectOption}
      />
    </Box>
  );
};
