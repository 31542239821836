import { useDebouncedCallback, useToggle } from '@partstech/ui/hooks';
import { useEffect, useRef } from 'react';
import { useScrollIntoView } from 'shared/lib/react-dom';

export const useFeatureHighlight = (isHighlighted: boolean) => {
  const { isToggle: isHighlightActive, setToggle: setHighlightActive } = useToggle();

  const containerRef = useRef<HTMLTableRowElement>(null);

  const disableHighlight = useDebouncedCallback(() => setHighlightActive(false), 3000);

  useEffect(() => {
    setHighlightActive(isHighlighted);

    if (isHighlighted) {
      disableHighlight();
    }
  }, [isHighlighted, disableHighlight, setHighlightActive]);

  useScrollIntoView(containerRef, { behavior: 'smooth', block: 'center' }, { skip: !isHighlightActive });

  return {
    containerRef,
    isHighlightActive,
  };
};
