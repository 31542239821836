/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type UpdateLaborRateMutationVariables = Types.Exact<{
  input: Types.UpdateLaborRateInput;
}>;

export type UpdateLaborRateMutation = {
  __typename?: 'Mutation';
  updateLaborRate?: {
    __typename?: 'UpdateLaborRatePayload';
    laborRate?: {
      __typename?: 'LaborRate';
      id: string;
      isDefault: Types.Scalars['Boolean']['input'];
      name: Types.Scalars['String']['input'];
      rate: Types.Scalars['BigDecimal']['input'];
    } | null;
  } | null;
};

export const UpdateLaborRateDocument = `
    mutation UpdateLaborRate($input: UpdateLaborRateInput!) {
  updateLaborRate(input: $input) {
    laborRate {
      id
      isDefault
      name
      rate
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    UpdateLaborRate: build.mutation<UpdateLaborRateMutation, UpdateLaborRateMutationVariables>({
      query: (variables) => ({ document: UpdateLaborRateDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUpdateLaborRateMutation } = injectedRtkApi;
