import { useOpen } from '@partstech/ui/hooks';
import { isEmpty, isEqual } from '@partstech/ui/utils';
import { useCallback, useEffect, useState } from 'react';
import { Country } from 'shared/api';
import { replaceObjectValue } from 'shared/lib/object';
import type { ShopFormData } from 'features/shop';
import type { DetailsByPlate } from 'integrations/google';
import type React from 'react';

const defaultShop = {
  billingAddress: {
    address1: '',
    city: '',
    country: Country.Us,
    state: '',
    zipCode: '',
  },
  name: '',
  phone: '',
};

type Props = {
  defaultValues?: ShopFormData;
  onSelect?: (values: ShopFormData | null) => void;
};

export const useCreateShop = ({ defaultValues, onSelect }: Props) => {
  const [shopData, setShopData] = useState<ShopFormData>(defaultShop);

  const { isOpen: isOpenSearch, open: openSearch, close: closeSearch } = useOpen(isEmpty(defaultValues));

  const handleOpenSearch = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();

      setShopData(defaultShop);
      onSelect?.(null);
      openSearch();
    },
    [onSelect, openSearch]
  );

  const selectShop = useCallback(
    (values: DetailsByPlate) => {
      const { address, ...rest } = values;
      setShopData({ ...rest, billingAddress: address });

      const payload = { ...rest, billingAddress: address };
      onSelect?.(payload);

      closeSearch();
    },
    [closeSearch, onSelect]
  );

  const changeShopName = useCallback((value: string) => {
    setShopData((prev) => ({ ...prev, name: value }));
  }, []);

  const handleCloseSearch = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      closeSearch();
    },
    [closeSearch]
  );

  useEffect(() => {
    if (defaultValues) {
      const normalizedValues = replaceObjectValue(defaultValues, null, '');
      const { billingAddress, shippingAddress } = normalizedValues;

      setShopData({
        ...normalizedValues,
        shippingAddress: isEqual(billingAddress, shippingAddress) ? undefined : shippingAddress,
      });
    }
  }, [defaultValues]);

  return {
    isOpenSearch,
    openSearch: handleOpenSearch,
    closeSearch: handleCloseSearch,
    shopData,
    changeShopName,
    selectShop,
  };
};
