import { Box, Typography } from '@partstech/ui';
import { useCreateShopContext } from 'components/Layout/SignUpLayout/CreateShopContext/CreateShopContext';
import { QuestionOptions, StepTitle, rolesOptions, useStepProgress } from 'features/signUp';
import { OnboardStepType } from 'shared/api';

export const PrimaryRole = () => {
  const { navigateToNextStep } = useCreateShopContext();

  const { defaultOption, selectOption, skipStep } = useStepProgress({
    step: OnboardStepType.PrimaryRole,
    onSuccess: navigateToNextStep,
  });

  return (
    <Box p={4} textAlign={{ sm: 'center', md: 'left' }} width={{ sm: 78, md: '100%' }}>
      <Typography variant="subtitle2" color="subtleText">
        Almost there ...
      </Typography>

      <StepTitle mt={4} mb={2}>
        What is your primary role?
      </StepTitle>

      <QuestionOptions
        defaultOption={defaultOption}
        options={rolesOptions}
        gap={{ sm: 5, md: 3 }}
        optionMinWidth={{ sm: 55, md: 6 }}
        onSelect={selectOption}
        onSkip={skipStep}
        justifyContent={{ sm: 'center', md: 'flex-start' }}
        width={{ md: 100 }}
      />
    </Box>
  );
};
