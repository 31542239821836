import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import type { Stripe } from '@stripe/stripe-js';
import type { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
  stripeKey: string;
}>;

export const StripeLoader = ({ stripeKey, children }: Props) => {
  const [stripe, setStripe] = useState<Stripe | null>(null);

  useEffect(() => {
    if (!stripeKey) {
      return;
    }

    const getStripe = async () => {
      if (stripe) {
        return;
      }

      const result = await loadStripe(stripeKey);

      if (!result) {
        return;
      }

      setStripe(result);
    };

    getStripe();
  }, [stripe, stripeKey]);

  return (
    <Elements
      stripe={stripe}
      options={{
        fonts: [
          {
            cssSrc: 'https://fonts.googleapis.com/css?family=Source+Sans+Pro',
          },
        ],
      }}
    >
      {children}
    </Elements>
  );
};
