import { FormCheckBoxField } from '@partstech/ui/forms';
import { useMemo } from 'react';
import { getFormattedDateFromISO } from 'shared/lib/dates';

type Props = {
  expiresDate: string | null;
  isPlan: boolean;
  productName: string;
};

export const TermsCheckBoxField = ({ expiresDate, isPlan, productName }: Props) => {
  const text = useMemo(() => {
    if (isPlan) {
      return 'I understand that by downgrading, I will lose access to the listed features at the end of my current billing cycle on';
    }

    return `I understand that by removing ${productName}, I will lose access at the end of my current billing cycle on`;
  }, [isPlan, productName]);

  if (!expiresDate) {
    return null;
  }

  return (
    <FormCheckBoxField name="terms">
      {text} {getFormattedDateFromISO(expiresDate)}.
    </FormCheckBoxField>
  );
};
