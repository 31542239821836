/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type GetManagementSystemsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetManagementSystemsQuery = {
  __typename?: 'Query';
  managementSystems?: Array<{
    __typename?: 'ManagementSystem';
    id: string;
    isDefaultDisplay: Types.Scalars['Boolean']['input'];
    logo?: Types.Scalars['String']['input'] | null;
    manual?: Types.Scalars['String']['input'] | null;
    name: Types.Scalars['String']['input'];
    priority?: number | null;
    status?: Types.ManagementSystemStatus | null;
    synonyms?: Types.Scalars['String']['input'] | null;
  }> | null;
};

export const GetManagementSystemsDocument = `
    query GetManagementSystems {
  managementSystems {
    ... on ManagementSystem {
      id
      isDefaultDisplay
      logo
      manual
      name
      priority
      status
      synonyms
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetManagementSystems: build.query<GetManagementSystemsQuery, GetManagementSystemsQueryVariables | void>({
      query: (variables) => ({ document: GetManagementSystemsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetManagementSystemsQuery, useLazyGetManagementSystemsQuery } = injectedRtkApi;
