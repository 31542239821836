import { Supplier } from '../../models/Supplier';
import type { SupplierFragment } from 'store/queries/currentUser/supplierAccounts/Supplier.generated';

export const createSupplierFromQueryData = (supplierData: SupplierFragment) => {
  const supplier = new Supplier(supplierData);

  if (supplierData.outageType) {
    supplier.setOutage(supplierData.outageType);
  }

  return supplier;
};
