import { Tooltip, Typography } from '@partstech/ui';
import snowedMountains from 'images/icons/snowed-mountains.svg';
import { Chip } from '../Chip';
import type { ProductAttribute } from 'types/product';

type Props = {
  attribute: ProductAttribute;
};

const MOUNTAIN_SNOWFLAKE = '3 Peak Mountain Snowflake';

export const Certification = ({ attribute }: Props) => {
  const shouldShowSnowflakeIcon = attribute.value === MOUNTAIN_SNOWFLAKE;

  if (!attribute.value) {
    return null;
  }

  return (
    <Tooltip tooltip={attribute.value} disabled={!shouldShowSnowflakeIcon} allowAutoPositioning>
      <Chip background="primaryLight10">
        {!shouldShowSnowflakeIcon && (
          <Typography variant="overline" color="white" textTransform="uppercase">
            {attribute.value}
          </Typography>
        )}

        {shouldShowSnowflakeIcon && <img src={snowedMountains} alt={attribute.value} />}
      </Chip>
    </Tooltip>
  );
};
