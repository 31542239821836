export type ShopPermissionsData = {
  additionalReports: boolean;
  jobs: boolean;
  labor: boolean;
  preferredBrands: boolean;
  retailPricing: boolean;
  schedules: boolean;
  specs: boolean;
  spendReport: boolean;
  tires: boolean;
  userPermissions: boolean;
};

export class ShopPermissions {
  advancedReportsAllowed: boolean = false;

  jobsAllowed: boolean = false;

  laborAllowed: boolean = false;

  preferredBrandsAllowed: boolean = false;

  retailPricingAllowed: boolean = false;

  schedulesAllowed: boolean = false;

  specsAllowed: boolean = false;

  spendReportAllowed: boolean = false;

  tiresAllowed: boolean = false;

  userPermissionsAllowed: boolean = false;

  constructor(permissions?: ShopPermissionsData) {
    this.advancedReportsAllowed = permissions?.additionalReports ?? false;
    this.jobsAllowed = permissions?.jobs ?? false;
    this.laborAllowed = permissions?.labor ?? false;
    this.preferredBrandsAllowed = permissions?.preferredBrands ?? false;
    this.retailPricingAllowed = permissions?.retailPricing ?? false;
    this.schedulesAllowed = permissions?.schedules ?? false;
    this.specsAllowed = permissions?.specs ?? false;
    this.spendReportAllowed = permissions?.spendReport ?? false;
    this.tiresAllowed = permissions?.tires ?? false;
    this.userPermissionsAllowed = permissions?.userPermissions ?? false;
  }
}
