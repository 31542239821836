type WithName = {
  name: string;
};

export const filterParametersBySearch = <T>(parameterList: (T & WithName)[], searchValue?: string): T[] => {
  if (!searchValue) {
    return parameterList;
  }

  const search = searchValue.toLowerCase();

  return parameterList.filter((parameter) => parameter.name.toLowerCase().includes(search));
};
