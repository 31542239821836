import { useCallback, useMemo } from 'react';
import { OnboardStepType } from 'shared/api';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';

export const useOnboardSteps = () => {
  const { shop, isPrimary, isConferenceAccount, isSuccess, isFetching } = useGetCurrentUser();

  const onboardSteps = useMemo(() => shop?.onboardSteps ?? [], [shop?.onboardSteps]);

  const isStepCompleted = useCallback(
    (step: OnboardStepType) => onboardSteps?.some((onboardStep) => onboardStep.step === step),
    [onboardSteps]
  );

  const getValueByStep = useCallback(
    (step: OnboardStepType) => onboardSteps?.find((onboardStep) => onboardStep.step === step)?.value,
    [onboardSteps]
  );

  const isOnboardCompleted = useMemo(
    () =>
      shop &&
      (!isPrimary ||
        isStepCompleted(isConferenceAccount ? OnboardStepType.Sms : OnboardStepType.Phone) ||
        shop.skipOnboard ||
        !shop.newOnboard),
    [shop, isPrimary, isStepCompleted, isConferenceAccount]
  );

  return {
    onboardSteps,
    isOnboardCompleted,
    isSuccess,
    isFetching,
    isStepCompleted,
    getValueByStep,
  };
};
