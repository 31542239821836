import { Box, Icon, MenuItem, Tooltip, useMedia } from '@partstech/ui';
import { useCallback, useMemo } from 'react';
import { AccountStatus } from 'features/supplierAccount';
import type { SupplierAccount } from 'models';

type Props = {
  onClick: (supplierAccount: SupplierAccount) => void;
  supplierAccount: SupplierAccount;
  value?: string | null;
  disabled: boolean;
  tooltipMessage: string;
};

export const SupplierAccountItem = ({ disabled, supplierAccount, value, tooltipMessage, onClick }: Props) => {
  const { isMobile } = useMedia();

  const handleClick = useCallback(() => onClick(supplierAccount), [onClick, supplierAccount]);

  const icon = useMemo(() => {
    if (value === supplierAccount.id) {
      return <Icon color="subtleText" name="check" />;
    }

    if (supplierAccount?.isApproved) {
      return <Box width={6} />;
    }

    return <AccountStatus account={supplierAccount} disabled />;
  }, [supplierAccount, value]);

  return (
    <Tooltip display="block" tooltip={tooltipMessage} isHidden={!disabled || isMobile} verticalAlign="center">
      <MenuItem
        size="regular"
        disabled={disabled && !isMobile}
        prefix={icon}
        onClick={disabled ? undefined : handleClick}
      >
        {supplierAccount.supplier?.name ?? ''}
      </MenuItem>
    </Tooltip>
  );
};
