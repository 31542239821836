import type { Product } from 'models';
import type { BrandOption } from 'types/preferredBrand';

export const getProductBrandOptions = (products: Product[]) =>
  products.reduce<BrandOption[]>((brandOption, { brand, partTypeId }) => {
    if (brand?.id && brand.name && !brandOption.find((item) => item.name === brand.name)) {
      brandOption.push({
        value: brand.id,
        name: brand.name,
        partTypeId,
      });
    }

    return brandOption;
  }, []);
