import { configureStore as configureReduxStore } from '@reduxjs/toolkit';
import { api, shopApi, gUploadApi, gExportApi } from 'shared/api';
import { permissionsMiddleware, resetMiddleware } from './middleware';
import type { Reducer } from '@reduxjs/toolkit';

export const configureStore = (reducer: Reducer) =>
  configureReduxStore({
    reducer,
    devTools: true,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false })
        .concat(permissionsMiddleware)
        .concat(api.middleware)
        .concat(shopApi.middleware)
        .concat(gUploadApi.middleware)
        .concat(gExportApi.middleware)
        .concat(resetMiddleware),
  });
