import { useMemo } from 'react';
import { api as generatedApi } from './GetSearchDiagrams.generated';

const api = generatedApi.enhanceEndpoints({
  addTagTypes: ['Diagram'],
  endpoints: {
    GetSearchDiagrams: {
      providesTags: (_response: unknown, _error: unknown, arg) => [{ type: 'Diagram', vehicleId: arg.vehicleID }],
    },
  },
});

const { useGetSearchDiagramsQuery } = api;

type Props = {
  vehicleId: string | null;
};

export const useSearchDiagrams = ({ vehicleId }: Props) => {
  const { data, ...props } = useGetSearchDiagramsQuery({ vehicleID: `${vehicleId}` }, { skip: !vehicleId });

  const diagrams = useMemo(() => {
    if (!vehicleId) {
      return [];
    }

    return data?.vehicle?.diagrams ?? [];
  }, [data?.vehicle?.diagrams, vehicleId]);

  return {
    ...props,
    diagrams,
  };
};
