/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type DeleteSupplierAccountMutationVariables = Types.Exact<{
  input: Types.DeleteShopAccountInput;
}>;

export type DeleteSupplierAccountMutation = {
  __typename?: 'Mutation';
  deleteShopAccount?: { __typename?: 'DeleteShopAccountPayload'; id?: string | null } | null;
};

export const DeleteSupplierAccountDocument = `
    mutation DeleteSupplierAccount($input: DeleteShopAccountInput!) {
  deleteShopAccount(input: $input) {
    id
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    DeleteSupplierAccount: build.mutation<DeleteSupplierAccountMutation, DeleteSupplierAccountMutationVariables>({
      query: (variables) => ({ document: DeleteSupplierAccountDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useDeleteSupplierAccountMutation } = injectedRtkApi;
