import { ModalView } from '@partstech/ui';
import { useCallback } from 'react';
import { Filters } from 'pages/SearchResults/Filters';
import { useSearchResults } from 'pages/SearchResults/SearchResultsContext';

type Props = {
  onSearch: () => void;
  onClose: () => void;
};

export const RefineModal = ({ onClose, onSearch }: Props) => {
  const handleOnSearch = useCallback(() => {
    onSearch();
    onClose();
  }, [onClose, onSearch]);

  const { filters } = useSearchResults();

  return (
    <ModalView title="Refine" onClose={onClose} closeIcon="arrow_back">
      <Filters filters={filters} onSearch={handleOnSearch} onRedirect={onClose} />
    </ModalView>
  );
};
