import { ImageFallback, Scrollbar } from '@partstech/ui';
import { useCallback, useMemo } from 'react';
import { OptionsByColumns } from 'components/OptionsByColumns';
import { VehicleRequiredView } from 'components/VehicleRequiredView';
import { PaidFeatureAlias, useLearnAboutFeatureModal } from 'features/subscription';
import jobsImg from 'images/icons/search-tabs/jobs.svg';
import { useLaunchDarkly } from 'integrations/launchDarkly';
import { usePermissions } from 'store/queries/currentUser/usePermissions';
import { FreeJobs } from './FreeJobs';
import { VehicleSpecificJobsLabel } from './VehicleSpecificJobsLabel';
import type { Job } from 'models';

type Props = {
  hasVehicle?: boolean;
  options: Job[];
  onChange: (option: Job) => void;
  onSelect?: (option: Job) => void;
  onSelectVehicleClick?: () => void;
  isKeyboardDisabled?: boolean;
};

export const Jobs = ({
  hasVehicle = false,
  isKeyboardDisabled,
  onChange,
  onSelect,
  onSelectVehicleClick,
  options,
}: Props) => {
  const {
    shop: { jobsAllowed },
  } = usePermissions();

  const {
    flags: { vehicleSpecificJobs },
  } = useLaunchDarkly();

  const { open: openLearnAboutJobsModal } = useLearnAboutFeatureModal({ feature: PaidFeatureAlias.Jobs });

  const selectJob = useCallback(
    (job: Job) => {
      if (job.isPaid && !jobsAllowed) {
        openLearnAboutJobsModal();
        return;
      }

      onChange(job);
    },
    [jobsAllowed, onChange, openLearnAboutJobsModal]
  );

  const freeJobs = useMemo(() => options.filter((job) => (jobsAllowed ? false : job.isFree)), [jobsAllowed, options]);
  const paidJobs = useMemo(() => options.filter((job) => (jobsAllowed ? true : job.isPaid)), [jobsAllowed, options]);

  return (
    <VehicleRequiredView
      data-testid="jobs"
      width="100%"
      hasOptions={options.length > 0}
      hasVehicle={hasVehicle}
      onSelectVehicleClick={onSelectVehicleClick}
      noOptionsIcon={<ImageFallback src={jobsImg} height="40px" width="40px" />}
      noVehicleText="A vehicle must be selected in order to view jobs."
      noOptionsText="There are currently no jobs available for the vehicle you selected."
    >
      <Scrollbar>
        {jobsAllowed && vehicleSpecificJobs && <VehicleSpecificJobsLabel />}
        {freeJobs.length > 0 && <FreeJobs jobs={freeJobs} onSelect={selectJob} />}
        <OptionsByColumns
          onChange={selectJob}
          onSelect={onSelect}
          options={paidJobs}
          maxColumns={2}
          isKeyboardDisabled={isKeyboardDisabled}
        />
      </Scrollbar>
    </VehicleRequiredView>
  );
};
