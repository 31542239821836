import { useBrowserTabActivity } from '@partstech/ui/hooks';
import { useCallback, useMemo } from 'react';
import { BroadcastChannelContextProvider } from './BroadcastChannelContext';
import { useBroadcastChannelListener } from './useBroadcastChannelListener';
import type { PropsWithChildren } from 'react';

type Props = PropsWithChildren;

export const BroadcastChannelProvider = ({ children }: Props) => {
  const isActive = useBrowserTabActivity({ refreshTime: 500 });

  const handleLogout = useCallback(() => {
    window.location.reload();
  }, []);

  const sendEvent = useBroadcastChannelListener({ onLogoutEvent: handleLogout, skipHandlerRegistration: isActive });

  const value = useMemo(() => ({ sendEvent }), [sendEvent]);

  return <BroadcastChannelContextProvider value={value}>{children}</BroadcastChannelContextProvider>;
};
