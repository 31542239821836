import { useEffect } from 'react';
import { useSettings } from 'hooks/app';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';

export const useRedirectToSupplierPortal = () => {
  const { isSupplier } = useGetCurrentUser();

  const { supplierPortalDomain } = useSettings({ skip: true });

  useEffect(() => {
    if (!isSupplier) {
      return;
    }

    window.location.replace(`//${supplierPortalDomain}${'/'}`);
  }, [isSupplier, supplierPortalDomain]);
};
