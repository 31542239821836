import { css, Global } from '@emotion/react';

const globalStyle = css`
  body {
    /*
      Used by SortableContainer
     */

    &.state-sorting {
      * {
        cursor: move;
      }
    }

    .pac-container {
      z-index: 9999 !important;

      .pac-item {
        cursor: pointer !important;
      }
    }
  }

  #app {
    height: 100%;
    @media print {
      height: auto;
      @page {
        size: portrait;
      }
    }
  }

  #userpilot-resource-centre-frame {
    top: 0 !important;
    transform: none !important;
  }

  #userpilotContent,
  #userpilotContentNative {
    max-height: 100%;
  }
`;

export const GlobalStyles = () => <Global styles={globalStyle} />;
