import { makeContext } from '@partstech/ui/utils';
import type { LaborApplication, LaborPartType } from 'models';

export type AddingLaborContextValue = {
  selectedLaborApplications: LaborApplication[];
  selectedPartTypes: LaborPartType[];
  customDurations: Record<string, number>;
  checkedLaborMap: Record<string, boolean>;
  checkedPartTypesMap: Record<string, boolean>;
  totalHours: number;
  totalParts: number;
  hasSelected: boolean;
  toggleLaborApplication: (application: LaborApplication) => void;
  togglePartType: (partType: LaborPartType) => void;
  changeCustomDuration: (laborApplicationId: string, customDuration: number) => void;
  clear: () => void;
};

export const [useAddingLabor, AddingLaborContextProvider] = makeContext<AddingLaborContextValue>();
