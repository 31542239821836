/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import { MemberPermissionsFragmentDoc } from './MemberPermissions.generated';
import type * as Types from '../../../shared/api/graphql/graphql';

export type GetCurrentUserQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetCurrentUserQuery = {
  __typename?: 'Query';
  currentUser?: {
    __typename?: 'User';
    allowOrdersEmail: Types.Scalars['Boolean']['input'];
    avatar?: Types.Scalars['String']['input'] | null;
    email: Types.Scalars['String']['input'];
    firstName: Types.Scalars['String']['input'];
    id: string;
    isGoogleAccount: Types.Scalars['Boolean']['input'];
    isConferenceAccount: Types.Scalars['Boolean']['input'];
    lastName: Types.Scalars['String']['input'];
    phone?: Types.Scalars['String']['input'] | null;
    phoneType: Types.UserPhoneType;
    registrationDate?: Types.Scalars['DateTime']['input'] | null;
    username: Types.Scalars['String']['input'];
    shopMemberships?: Array<{
      __typename?: 'ShopMember';
      id: string;
      isPrimary: Types.Scalars['Boolean']['input'];
      active: Types.Scalars['Boolean']['input'];
      shop?: { __typename?: 'Shop'; id: string; name: Types.Scalars['String']['input'] } | null;
    }> | null;
    supplierMemberships?: Array<{
      __typename?: 'SupplierMember';
      id: string;
      active: Types.Scalars['Boolean']['input'];
      supplier?: { __typename?: 'Supplier'; id: string; name: Types.Scalars['String']['input'] } | null;
    }> | null;
    activeMember?: {
      __typename?: 'ShopMember';
      apiKey?: Types.Scalars['String']['input'] | null;
      id: string;
      isPrimary: Types.Scalars['Boolean']['input'];
      permissions?: {
        __typename?: 'MemberPermissions';
        allOrders: Types.Scalars['Boolean']['input'];
        boschRewards: Types.Scalars['Boolean']['input'];
        brandPreferences: Types.Scalars['Boolean']['input'];
        partsReport: Types.Scalars['Boolean']['input'];
        payments: Types.Scalars['Boolean']['input'];
        reportsDashboardAccess: Types.Scalars['Boolean']['input'];
        retailPricing: Types.Scalars['Boolean']['input'];
        shipping: Types.Scalars['Boolean']['input'];
        shopProfile: Types.Scalars['Boolean']['input'];
        spendReport: Types.Scalars['Boolean']['input'];
        subscription: Types.Scalars['Boolean']['input'];
        suppliers: Types.Scalars['Boolean']['input'];
        tireReport: Types.Scalars['Boolean']['input'];
        viewWholesaleCosts: Types.Scalars['Boolean']['input'];
      } | null;
      shop?: {
        __typename?: 'Shop';
        allowToModifySuppliers: Types.Scalars['Boolean']['input'];
        catalog: Types.Scalars['Boolean']['input'];
        cellphone?: Types.Scalars['String']['input'] | null;
        confirmShipping: Types.Scalars['Boolean']['input'];
        exProfileType?: Types.ExProfileType | null;
        id: string;
        logo?: Types.Scalars['String']['input'] | null;
        name: Types.Scalars['String']['input'];
        mandatoryPoNumber: Types.Scalars['Boolean']['input'];
        newOnboard: Types.Scalars['Boolean']['input'];
        otherBusinessName?: Types.Scalars['String']['input'] | null;
        phone?: Types.Scalars['String']['input'] | null;
        pickupRadius: number;
        showRetailPrice: Types.Scalars['Boolean']['input'];
        skipOnboard: Types.Scalars['Boolean']['input'];
        smsPartTypeAsPartName: Types.Scalars['Boolean']['input'];
        website?: Types.Scalars['String']['input'] | null;
        partsPreferredBrands: Types.PreferredBrandsMode;
        tiresPreferredBrands: Types.PreferredBrandsMode;
        attributes?: {
          __typename?: 'ShopAttributes';
          defaultShippingZipCode: Types.Scalars['String']['input'];
          demo: Types.Scalars['Boolean']['input'];
          suppliers: Array<string>;
          totalPaidOrders: number;
          usersCount: number;
        } | null;
        featureFlags: { __typename?: 'ShopFeatureFlags'; newLabor: Types.Scalars['Boolean']['input'] };
        managementSystem?:
          | { __typename?: 'CustomManagementSystem'; name: Types.Scalars['String']['input'] }
          | {
              __typename?: 'ManagementSystem';
              id: string;
              logo?: Types.Scalars['String']['input'] | null;
              manual?: Types.Scalars['String']['input'] | null;
              name: Types.Scalars['String']['input'];
              status?: Types.ManagementSystemStatus | null;
            }
          | null;
        onboard?: Array<{ __typename?: 'Onboard'; status: Types.OnboardStatus; type: Types.OnboardType }> | null;
        onboardSteps?: Array<{
          __typename?: 'OnboardStep';
          step: Types.OnboardStepType;
          value?: Types.Scalars['String']['input'] | null;
        }> | null;
        orderingStatistic?: {
          __typename?: 'OrderingStatistic';
          totalPaidOrders: number;
          lastPaidDate?: Types.Scalars['DateTime']['input'] | null;
        } | null;
        permissions?: {
          __typename?: 'ShopPermissions';
          additionalReports: Types.Scalars['Boolean']['input'];
          jobs: Types.Scalars['Boolean']['input'];
          labor: Types.Scalars['Boolean']['input'];
          preferredBrands: Types.Scalars['Boolean']['input'];
          retailPricing: Types.Scalars['Boolean']['input'];
          schedules: Types.Scalars['Boolean']['input'];
          specs: Types.Scalars['Boolean']['input'];
          spendReport: Types.Scalars['Boolean']['input'];
          tires: Types.Scalars['Boolean']['input'];
          userPermissions: Types.Scalars['Boolean']['input'];
        } | null;
      } | null;
    } | null;
  } | null;
};

export const GetCurrentUserDocument = `
    query GetCurrentUser {
  currentUser {
    allowOrdersEmail
    avatar
    email
    firstName
    id
    isGoogleAccount
    isConferenceAccount
    lastName
    phone
    phoneType
    registrationDate
    username
    shopMemberships {
      id
      isPrimary
      active
      shop {
        id
        name
      }
    }
    supplierMemberships {
      id
      active
      supplier {
        id
        name
      }
    }
    activeMember {
      apiKey
      id
      isPrimary
      permissions {
        ...MemberPermissions
      }
      shop {
        allowToModifySuppliers
        attributes {
          defaultShippingZipCode
          demo
          suppliers
          totalPaidOrders
          usersCount
        }
        catalog
        cellphone
        confirmShipping
        exProfileType
        featureFlags {
          newLabor
        }
        id
        logo
        managementSystem {
          ... on ManagementSystem {
            id
            logo
            manual
            name
            status
          }
          ... on CustomManagementSystem {
            name
          }
        }
        name
        mandatoryPoNumber
        newOnboard
        onboard {
          status
          type
        }
        onboardSteps {
          step
          value
        }
        orderingStatistic {
          totalPaidOrders
          lastPaidDate
        }
        otherBusinessName
        permissions {
          additionalReports
          jobs
          labor
          preferredBrands
          retailPricing
          schedules
          specs
          spendReport
          tires
          userPermissions
        }
        phone
        pickupRadius
        showRetailPrice
        skipOnboard
        smsPartTypeAsPartName
        website
        partsPreferredBrands
        tiresPreferredBrands
      }
    }
  }
}
    ${MemberPermissionsFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetCurrentUser: build.query<GetCurrentUserQuery, GetCurrentUserQueryVariables | void>({
      query: (variables) => ({ document: GetCurrentUserDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetCurrentUserQuery, useLazyGetCurrentUserQuery } = injectedRtkApi;
