type ShopMembershipData = {
  id: string;
  isPrimary: boolean;
  active: boolean;
  shop: {
    id: string;
    name: string;
  };
};

export class ShopMembership {
  id: string;

  isPrimary: boolean;

  isActive: boolean;

  shop: {
    id: string;
    name: string;
  };

  constructor(user: ShopMembershipData) {
    this.id = user.id ?? null;
    this.isPrimary = user.isPrimary;
    this.isActive = user.active;
    this.shop = user.shop;
  }
}
