import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { getFsStats } from 'shared/api/rest/gen/shop';
import type { RootState } from 'store/index';

type TrackingStatsState = {
  smsValidatedDate: string | null;
  isLoaded: boolean;
};

const initialState: TrackingStatsState = {
  smsValidatedDate: null,
  isLoaded: false,
};

export const getTrackingStats = createAsyncThunk('features/trackingStats/getTrackingStats', getFsStats);

const trackingStatsSlice = createSlice({
  name: 'trackingStats',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTrackingStats.fulfilled, (state, action) => {
        state.smsValidatedDate = action.payload.smsValidatedDate;
        state.isLoaded = true;
      })
      .addCase(getTrackingStats.rejected, (state) => {
        state.isLoaded = true;
      });
  },
});

export const trackingStats = trackingStatsSlice.reducer;

const selectState = (state: RootState) => state.features.trackingStats;

export const selectSmsValidatedDate = createSelector([selectState], (state) => {
  if (!state.smsValidatedDate) {
    return null;
  }
  return DateTime.fromISO(state.smsValidatedDate).toJSDate();
});

export const selectIsLoadedSmsValidatedDate = createSelector([selectState], (state) => state.isLoaded);
