import { PartTypeApplication } from 'shared/api';
import type { PartCategory } from './PartCategory';
import type { Vehicle } from './Vehicle';
import type {
  SearchCatalogRequestConvertable,
  SearchEntryPointInterface,
  SearchQuery,
  SearchQueryConvertable,
  SearchRequestConvertable,
  ToCatalogRequestParams,
  ToRequestParams,
} from 'features/searchForm';
import type { CatalogSearchInput, SearchInput } from 'shared/api';
import type { FullPartTypeFragment } from 'store/queries/partTypes/FullPartType.generated';

export type Attribute = {
  name: string;
  priority: number;
  required: boolean;
  values?: string[] | null;
};

type LinkedApplication = {
  application: PartTypeApplication;
};

export class PartType
  implements
    SearchEntryPointInterface,
    SearchQueryConvertable,
    SearchRequestConvertable,
    SearchCatalogRequestConvertable
{
  static readonly TIRE_ID = '7636';

  id: string;

  name: string;

  aliases: string[];

  application: PartTypeApplication;

  attributes: Attribute[] | null;

  category: PartCategory | undefined;

  subCategoryId: string | undefined;

  isDisabled: boolean | undefined;

  linkedApplications: LinkedApplication[];

  constructor(partTypeData: FullPartTypeFragment) {
    this.id = partTypeData.id;
    this.name = partTypeData.name;
    this.aliases = partTypeData.aliases ?? [];
    this.application = partTypeData.application;
    this.attributes = partTypeData.attributes ?? null;
    this.linkedApplications = partTypeData.linkedPartTypes ?? [];
  }

  getClassName() {
    return `fs-${this.getColumnTitle().toLowerCase()}-part-type`;
  }

  getColumnTitle(): string {
    return this.isFitted ? 'FITTED' : 'UNIVERSAL';
  }

  isVehicleRequired(): boolean {
    return this.isFitted;
  }

  setCategory(category: PartCategory) {
    this.category = category;
  }

  setSubCategoryId(subCategoryId: string) {
    this.subCategoryId = subCategoryId;
    return this;
  }

  isEqual(partType: PartType) {
    return this.id === partType.id;
  }

  /**
   * Part type _requires_ vehicle for search
   */
  get isFitted(): boolean {
    return this.application === PartTypeApplication.Fitted || (!this.isUniversal && !this.isUniversalFitted);
  }

  /**
   * Part type doesn't require vehicle for search
   */
  get isUniversal(): boolean {
    return (
      this.application === PartTypeApplication.Universal &&
      !this.linkedApplications.some(({ application }) => application === PartTypeApplication.Fitted)
    );
  }

  /**
   * Part type may require vehicle for search and may not
   */
  get isUniversalFitted(): boolean {
    return (
      this.application === PartTypeApplication.UniversalFitted &&
      !this.linkedApplications.some(({ application }) => application === PartTypeApplication.Fitted)
    );
  }

  get isTire(): boolean {
    return this.id === PartType.TIRE_ID;
  }

  populateInput(): string {
    return this.name;
  }

  toString() {
    return this.name;
  }

  toQuery(vehicle?: Vehicle | null, isFittedAvailable: boolean = true): SearchQuery {
    return {
      part_text_id: this.id,
      part_text: this.name,
      fit: this.application === PartTypeApplication.UniversalFitted && vehicle && isFittedAvailable ? ['1'] : undefined,
    };
  }

  setIsDisabled(isDisabled: boolean) {
    this.isDisabled = isDisabled;
    return this;
  }

  toRequest({ accountId, attributes, vehicleId, vin, universal, jobId }: ToRequestParams): SearchInput {
    return {
      partTypeAttribute: {
        accountId,
        attributes,
        partTypeIds: [this.id],
        universal: universal ? true : undefined,
        vehicleId,
        vin,
        jobId,
      },
    };
  }

  toCatalogRequest({ attributes, vehicleId, vin, fit }: ToCatalogRequestParams): CatalogSearchInput {
    return {
      partTypeAttribute: {
        attributes,
        partTypeIds: [this.id],
        universal: fit ? undefined : true,
        vehicleId,
        vin,
      },
    };
  }
}
