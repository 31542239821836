import { useForm } from '@partstech/ui/forms';
import { useCallback } from 'react';
// TODO: Feature cannot use feature
import { useStepProgress } from 'features/signUp';
import { useCreateManagementSystem } from 'hooks/shopManagementSystems';
import { OnboardStepType } from 'shared/api';
import { useFormStatus } from 'shared/lib/form';
import { useIntegrationStatusModal } from './useIntegrationStatusModal';

type FromData = { name: string };

type Args = {
  onSubmit: () => void;
};

export const useCreateSystemForm = ({ onSubmit }: Args) => {
  const [createManagementSystem, status] = useCreateManagementSystem();

  const { selectOption } = useStepProgress({ step: OnboardStepType.Sms, onSuccess: onSubmit });

  const form = useForm<FromData>({ defaultValues: { name: '' } });
  const { formState } = form;
  const { dirtyFields } = formState;

  const handleCreateManagementSystemSuccess = useCallback(async () => {
    const managementSystemName = status.data?.createShopManagementSystemRelation?.shop?.managementSystem?.name;

    if (!managementSystemName) {
      return;
    }

    await selectOption(managementSystemName);
  }, [selectOption, status.data?.createShopManagementSystemRelation?.shop?.managementSystem?.name]);

  const { open } = useIntegrationStatusModal({ onSubmit: handleCreateManagementSystemSuccess });

  const submitForm = useCallback(
    async (values: FromData) => {
      await createManagementSystem(values);
    },
    [createManagementSystem]
  );

  const handleOpenModal = useCallback(() => {
    status.reset();

    open();
  }, [open, status]);

  useFormStatus({ form, status, onSuccess: handleOpenModal });

  return { form, submitForm, isDisabled: !dirtyFields.name, isLoading: status.isLoading };
};
