import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { checkMitchell1Token, generateMitchell1Token, refreshMitchell1Token } from 'shared/api/rest/gen/shop';
import { logout } from 'store/features/user/account';
import type { CheckMitchell1TokenResponse, GenerateMitchell1TokenRequest } from 'shared/api/rest/gen/shop';
import type { RootState } from 'store';

export type Mitchell1SetupFormData = GenerateMitchell1TokenRequest;

type Mitchell1CheckResponse = {
  token: string;
};

export const generateToken = createAsyncThunk<Mitchell1CheckResponse, Mitchell1SetupFormData>(
  'features/punchout/generateToken',
  async (payload) => {
    const { token } = await generateMitchell1Token(payload);

    return { token };
  }
);

export const checkToken = createAsyncThunk<CheckMitchell1TokenResponse, string>(
  'features/punchout/checkToken',
  async (token) => {
    const { username } = await checkMitchell1Token({ token });

    return { username };
  }
);

export const refreshToken = createAsyncThunk('features/punchout/refreshToken', (token: string) =>
  refreshMitchell1Token({ token })
);

type State = {
  isLoading: boolean;
  mitchell1Token: string | null;
  mitchell1Username: string | null;
  error: string | null;
};

const initialState: State = {
  isLoading: false,
  mitchell1Token: null,
  mitchell1Username: null,
  error: null,
};

const punchoutSlice = createSlice({
  initialState,
  name: 'punchout',
  reducers: {
    resetUsername: (state) => {
      state.mitchell1Username = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(generateToken.fulfilled, (state, action) => {
        state.mitchell1Token = action.payload.token;
        state.error = null;
      })
      .addCase(generateToken.rejected, (state) => {
        state.error = 'Enter the correct credentials to connect';
      })
      .addCase(checkToken.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(checkToken.fulfilled, (state, action) => {
        state.mitchell1Token = action.meta.arg;
        state.mitchell1Username = action.payload.username;
        state.error = null;
        state.isLoading = false;
      })
      .addCase(checkToken.rejected, (state) => {
        state.error = 'Token has expired';
        state.isLoading = false;
      })
      .addCase(refreshToken.fulfilled, (state, action) => {
        state.mitchell1Token = action.payload.token;
        state.error = null;
      })
      .addCase(logout.fulfilled, () => initialState);
  },
});

export const punchout = punchoutSlice.reducer;

const selectState = (state: RootState) => state.features.punchout;

export const selectMitchell1Token = createSelector([selectState], (state) => state?.mitchell1Token ?? null);

export const selectMitchell1Username = createSelector([selectState], (state) => state?.mitchell1Username ?? null);

export const selectMitchell1Error = createSelector([selectState], (state) => state?.error ?? null);

export const selectMitchell1IsLoading = createSelector([selectState], (state) => state?.isLoading ?? null);
