import { keys } from '@partstech/ui/utils';
import { useMemo } from 'react';
import { useVehicleMakeGroups } from '../../../api';
import { searchByName } from '../../../utils/searchByName';

type Props = {
  decadesAmount?: number;
  searchValue?: string;
};

type Decade = Record<number, number[]>;

const getDecades = (years: number[]): Decade =>
  years.reduce((memo: Decade, year) => {
    const decade = Math.floor(year / 10) * 10;

    return {
      ...memo,
      [decade]: [...(memo[decade] || []), year],
    };
  }, {});

export const useGetDecades = ({ decadesAmount, searchValue }: Props = {}) => {
  const { years: rawYears } = useVehicleMakeGroups();

  const years = useMemo(
    () =>
      rawYears
        .map((year) => ({ id: year, name: String(year) }))
        .sort((a, b) => b.id - a.id)
        .filter(searchByName(searchValue)),
    [rawYears, searchValue]
  );

  const yearsByDecades = useMemo(() => getDecades(years.map(({ id }) => id)), [years]);

  const decadeNames = useMemo(() => keys(yearsByDecades).map(Number), [yearsByDecades]);

  const decades = useMemo(
    () => decadeNames.sort((a, b) => b - a).slice(0, decadesAmount ?? decadeNames.length),
    [decadeNames, decadesAmount]
  );

  const shownYears = useMemo(
    () =>
      decades.reduce<number[]>((yearsList, decade) => {
        const decadeYears = yearsByDecades[decade];

        if (Array.isArray(decadeYears)) {
          return yearsList.concat(decadeYears);
        }

        return yearsList;
      }, []),
    [decades, yearsByDecades]
  );

  return { years, decades, yearsByDecades, shownYears };
};
