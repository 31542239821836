import { findAttributeByName } from '../product/attributes/findAttributeByName';
import { getAttributeOptionsByName } from '../search/createFilters/getAttributeOptionsByName';
import type { RawAttribute } from 'shared/api/rest/gen/shop';
import type { ProductAttribute } from 'types/product';

export const getCustomAttributesByNames = (
  names: RawAttribute['name'][],
  attributes: ProductAttribute[]
): ProductAttribute[] =>
  names.map((name) => {
    const options = getAttributeOptionsByName(name);
    const defaultOptions = {
      name,
      label: options?.label ?? name,
      value: '',
      isCollapsed: false,
      isHidden: false,
    };

    return findAttributeByName(name, attributes) || defaultOptions;
  });
