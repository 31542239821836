import { Box, Typography } from '@partstech/ui';
import { ConfigurationFieldType, ValidationInputType } from 'shared/api';
import { CredentialFormField } from '../CredentialFormField';
import type { AdditionalInformation as AdditionalInformationType } from 'shared/api';

type Props = {
  additionalInformation: AdditionalInformationType | null;
};

export const AdditionalInformation = ({ additionalInformation }: Props) => {
  if (additionalInformation === null) {
    return null;
  }

  return (
    <>
      <Box mb={4}>
        <Box mb={1}>
          <Typography variant="subtitle2">{additionalInformation.title}</Typography>
        </Box>

        <Typography variant="bodyText2">{additionalInformation.description}</Typography>
      </Box>

      {additionalInformation.fields.map((credential) => (
        <Box key={credential.internalName} mb={{ sm: 4, md: 6 }}>
          <CredentialFormField
            type={credential.type}
            label={credential.label}
            name={`additionalInformation.${credential.internalName}`}
            validationRules={
              credential.type === ConfigurationFieldType.Email
                ? { inputType: ValidationInputType.Email, length: null }
                : undefined
            }
            isRequired={credential.required}
          />
        </Box>
      ))}
    </>
  );
};
