/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../shared/api/graphql/graphql';

export type GetAppConfigQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetAppConfigQuery = {
  __typename?: 'Query';
  appConfig?: {
    __typename?: 'AppConfig';
    googleOauthClientId: Types.Scalars['String']['input'];
    reCaptchaKey: Types.Scalars['String']['input'];
    stripeKey: Types.Scalars['String']['input'];
  } | null;
};

export const GetAppConfigDocument = `
    query GetAppConfig {
  appConfig {
    googleOauthClientId
    reCaptchaKey
    stripeKey
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetAppConfig: build.query<GetAppConfigQuery, GetAppConfigQueryVariables | void>({
      query: (variables) => ({ document: GetAppConfigDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetAppConfigQuery, useLazyGetAppConfigQuery } = injectedRtkApi;
