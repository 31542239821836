import { PageModal } from '@partstech/ui';
import { usePreviousMemo } from '@partstech/ui/hooks';
import { isEqual } from '@partstech/ui/utils';
import { useCallback, useId, useMemo } from 'react';
import { isModalPathname } from 'app/AppRouter/isModalPathname';
import { Outlet } from 'app/AppRouter/Outlet';
import { useAppLocation } from 'app/AppRouter/useAppLocation';
import { useAppNavigate } from 'app/AppRouter/useAppNavigate';
import { getDashboardUrl } from 'utils/url';
import type { ModalCommonProps, ModalComponentProps } from '@partstech/ui';
import type { PropsWithChildren } from 'react';

const Component = ({ children }: ModalComponentProps<PropsWithChildren>) => <Outlet>{children}</Outlet>;

export const ModalLayout = ({
  customId,
  ...modalProps
}: ModalCommonProps & { children?: JSX.Element; customId?: string }) => {
  const navigate = useAppNavigate();
  const location = useAppLocation();
  const prevLocation = usePreviousMemo(location, isEqual);

  const uniqueId = useId();

  const id = useMemo(() => customId ?? uniqueId.replaceAll(':', ''), [customId, uniqueId]);

  const handleClose = useCallback(() => {
    if (prevLocation?.state?.background) {
      const { background } = prevLocation.state;
      navigate(background, { replace: true });
      return;
    }

    if (prevLocation?.pathname && !isModalPathname(prevLocation.pathname)) {
      navigate(prevLocation.pathname, { replace: true });
      return;
    }

    navigate(getDashboardUrl());
  }, [navigate, prevLocation?.pathname, prevLocation?.state]);

  return <PageModal id={id} onClose={handleClose} component={Component} {...modalProps} />;
};
