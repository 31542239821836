import { groupBy, isEqual, entries } from '@partstech/ui/utils';
import { findAttributeFirstValueByName } from '../../product/attributes/findAttributeFirstValueByName';
import { getAttributeValuesFromProduct } from './getAttributeValuesFromProduct';
import type { Product } from 'models';
import type { MatchingSetId, MatchingSets } from 'types/search';

export const MATCHING_SETS_VALUE = 'MatchingSets';

export const getMatchingSetId = (product: Product): MatchingSetId =>
  `${product.brand?.id}:${product.title}:${findAttributeFirstValueByName('TiresQuality', product.attributes)}`;

export const filterMatchedTires = (tires: Product[]) =>
  tires.filter((tire, _, allTires) =>
    allTires.find(
      (otherTire) =>
        getMatchingSetId(otherTire) === getMatchingSetId(tire) &&
        !isEqual(getAttributeValuesFromProduct(otherTire, 'Position'), getAttributeValuesFromProduct(tire, 'Position'))
    )
  );

const filterMoreThanOneLengthSets = (matchingSets: MatchingSets): MatchingSets =>
  entries(matchingSets).reduce(
    (memo: MatchingSets, [matchingSetId, tires]) => (tires.length > 1 ? { ...memo, [matchingSetId]: tires } : memo),
    {}
  );

export const getMatchingSets = (products: Product[]): MatchingSets => {
  const matchedTires = filterMatchedTires(products);
  const groupedByMatchingId = groupBy(matchedTires, getMatchingSetId);

  return filterMoreThanOneLengthSets(groupedByMatchingId);
};
