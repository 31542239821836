import { keys } from '@partstech/ui/utils';
import { useMemo } from 'react';
import { pickDefinedValues } from 'shared/lib/object';
import { useGetVehiclesByYearMake } from '../../api';
import type { VehicleParameters } from '../../types';

export const useGetVehiclesByParameters = ({ year, makeId, ...props }: VehicleParameters) => {
  const { vehicles } = useGetVehiclesByYearMake(Number(year) ?? null, makeId);

  const filledValues = useMemo(() => pickDefinedValues({ year, makeId, ...props }), [makeId, props, year]);

  const filteredVehicles = useMemo(() => {
    if (!year) {
      return [];
    }

    return vehicles.filter((vehicleObj) =>
      keys(filledValues).every((key) => {
        switch (key) {
          case 'year':
            return vehicleObj.year === Number(filledValues.year);
          case 'makeId':
            return vehicleObj.make.id === filledValues.makeId;
          case 'modelId':
            return vehicleObj.model.id === filledValues.modelId;
          case 'submodelId':
            return vehicleObj.subModel.id === filledValues.submodelId;
          case 'engineId':
            return vehicleObj.engine.id === filledValues.engineId;
          default:
            return false;
        }
      })
    );
  }, [filledValues, vehicles, year]);

  return { vehicles: filteredVehicles };
};
