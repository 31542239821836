import type { ConfigurationFieldType, ValidationInputType } from 'shared/api';

export enum SupplierId {
  ADVANCEAUTOPARTS = 1458,
  ARROW_TIRE = 16524,
  ATD = 16638,
  ATD_TIRECONNECT = 16509,
  AUTOPARTINTERNATIONAL = 15029,
  AUTOZONE = 2501,
  KnM = 16514,
  OREILLY = 16620,
  THE_PARTS_HOUSE = 15144,
  TIRE_HUB = 16645,
  TIRE_HUB_TIRECONNECT = 16519,
  XL_PARTS = 15219,
}

export type ConfigurationField = {
  helper?: string | null;
  internalName: string;
  label: string;
  link?: string | null;
  options: string[];
  required: boolean;
  type: ConfigurationFieldType;
  validationRules?: {
    inputType: ValidationInputType;
    length?: string | null;
  } | null;
};
