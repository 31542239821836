import { css } from '@emotion/react';
import { Box, Card, Icon, Typography } from '@partstech/ui';
import { useToggle } from '@partstech/ui/hooks';
import { useCallback } from 'react';
import diagramSVG from 'images/content/diagrams/diagram.svg';

const styles = {
  card: css`
    cursor: pointer;
  `,
};

type Props = {
  categoryTitle: string;
  onClick: () => void;
};

export const DiagramButton = ({ categoryTitle, onClick }: Props) => {
  const { isToggle: isHovered, setToggle: setHovered } = useToggle();

  const handleMouseOver = useCallback(() => {
    setHovered(true);
  }, [setHovered]);

  const handleMouseLeave = useCallback(() => {
    setHovered(false);
  }, [setHovered]);

  return (
    <Card
      width="100%"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      background={isHovered ? 'lightBlue' : 'white'}
      borderColor={isHovered ? 'primary' : 'mono17'}
      mt={{ sm: 4, md: 0 }}
      mb={4}
      py={2}
      pr={2}
      pl={4}
      data-testid="diagramQuickLink"
      css={styles.card}
      onClick={onClick}
      onMouseLeave={handleMouseLeave}
      onMouseOver={handleMouseOver}
    >
      <Box textAlign="left">
        <Box display="flex" alignItems="center">
          <Typography variant="overline" color="linkText">
            VIEW DIAGRAMS
          </Typography>
          <Icon name="chevron_right" color="linkText" size="medium" />
        </Box>

        <Typography variant="caption" color="subtleText">
          {categoryTitle}
        </Typography>
      </Box>
      <img src={diagramSVG} alt="diagram" />
    </Card>
  );
};
