import type { PreferredBrandsEdge } from 'shared/api';

export const convertAutomaticPreferredBrands = (filteredBrands: Omit<PreferredBrandsEdge, 'cursor'>[]) =>
  filteredBrands.map((filteredBrand) => ({
    id: filteredBrand.node?.brand?.id,
    name: filteredBrand?.node?.brand?.name,
    partTypeId: filteredBrand?.node?.partType?.id,
    purchaseCount: filteredBrand?.node?.purchaseCount,
    linkedPartTypes: filteredBrand.node?.partType?.linkedPartTypes,
    matchingBrandsIds: filteredBrand.node?.brands?.map(({ id }) => id) ?? [],
  }));
