import { css } from '@emotion/react';
import { useMemo } from 'react';
import { UpgradeTireSubscriptionButton, useSubscriptionEX } from 'features/subscription';
import { currency } from 'shared/lib/string';
import { useGetSupplierAccounts } from 'store/queries/currentUser/supplierAccounts/useGetSupplierAccounts';
import { usePermissions } from 'store/queries/currentUser/usePermissions';
import { AddButton } from './AddButton';
import { PurchaseButton } from './PurchaseButton';
import { RemoveButton } from './RemoveButton';
import type { Store } from 'models';

const styles = {
  button: (isRound: boolean) => css`
    ${isRound && 'border-radius: 50%;'}
  `,
};

type Props = {
  store: Store;
  onAddClick: () => void;
  onDeleteClick: () => void;
  fullWidth?: boolean;
  isRound?: boolean;
};

export const ActionButton = ({ store, onAddClick, onDeleteClick, fullWidth = false, isRound = false }: Props) => {
  const { getAccountByStoreId } = useGetSupplierAccounts();
  const supplierAccount = useMemo(() => getAccountByStoreId(store.id), [getAccountByStoreId, store.id]);

  const { isRestrictedEX } = useSubscriptionEX();

  const isSelected = Boolean(supplierAccount);

  const {
    shop: { tiresAllowed },
  } = usePermissions();

  const isPaidSupplier = store.supplier?.isPaid;

  if (isSelected) {
    return (
      <RemoveButton store={store} onClick={onDeleteClick} fullWidth={fullWidth} css={styles.button(isRound)}>
        {isRound ? '' : 'Remove'}
      </RemoveButton>
    );
  }

  if (isPaidSupplier) {
    if (isRestrictedEX) {
      return (
        <PurchaseButton onClick={onAddClick} css={styles.button(isRound)}>
          {isRound ? '' : `Purchase for ${currency(15, '$', 0)}/mo`}
        </PurchaseButton>
      );
    }

    if (!tiresAllowed) {
      return (
        <UpgradeTireSubscriptionButton fullWidth={fullWidth} componentType={isRound ? 'roundedButton' : 'button'}>
          {isRound ? '' : 'Available with upgrade'}
        </UpgradeTireSubscriptionButton>
      );
    }
  }

  return (
    <AddButton store={store} onClick={onAddClick} fullWidth={fullWidth} css={styles.button(isRound)}>
      {isRound ? '' : 'Add'}
    </AddButton>
  );
};
