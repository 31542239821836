import { css } from '@emotion/react';
import { Box, OldNavigationListItem } from '@partstech/ui';
import { type ReactNode, useCallback, useMemo } from 'react';
import { useColumnKeyboardSelection } from 'shared/lib/keyboard';
import type { BoxProps, OldListItemProps, Theme } from '@partstech/ui';

export type Option = {
  id: string;
  name: string;
} & Record<string, unknown>;

type Props = {
  activeOption?: Option['id'];
  adornmentAfter?: (option: Option) => ReactNode | null;
  columns: Option[][];
  onSelect: (option: Option) => void;
} & Partial<Pick<OldListItemProps, 'size' | 'fullWidth' | 'color' | 'alignItems' | 'colorScheme'>> &
  Omit<BoxProps, 'alignItems' | 'color'>;

const styles = {
  item: (theme: Theme) => css`
    padding: ${theme.sizing(0, '14px')};
    min-height: ${theme.sizing(8)};
    height: auto;
  `,
};

export const Options = ({
  activeOption,
  adornmentAfter,
  columns,
  onSelect,
  size,
  fullWidth,
  color,
  alignItems,
  colorScheme,
  ...boxProps
}: Props) => {
  const { isActive } = useColumnKeyboardSelection({ columns, onEnterKeyDown: onSelect });

  const handleOptionClick = useCallback(
    (option: Option) => () => {
      onSelect && onSelect(option);
    },
    [onSelect]
  );

  const itemProps = useMemo(
    () => ({ size, fullWidth, color, alignItems, colorScheme }),
    [alignItems, color, colorScheme, fullWidth, size]
  );

  return (
    <Box display="flex" gap={2} alignItems="baseline" {...boxProps}>
      {columns.map((column, columnIndex) => (
        <Box key={columnIndex} display="flex" flexDirection="column">
          {column.map((option, optionIndex) => (
            <OldNavigationListItem
              css={styles.item}
              active={isActive(columnIndex, optionIndex) || activeOption === option.id}
              key={option.id}
              {...itemProps}
              onClick={handleOptionClick(option)}
            >
              {option.name}

              {adornmentAfter && adornmentAfter(option)}
            </OldNavigationListItem>
          ))}
        </Box>
      ))}
    </Box>
  );
};
