import { DateTime } from 'luxon';

export type PaymentCardData = {
  brand: string;
  expMonth: number;
  expYear: number;
  id?: string | null;
  isDefault?: boolean;
  name: string;
  owner?: string | null;
};

export class PaymentCard {
  brand: string;

  expMonth: number;

  expYear: number;

  id: string | null = null;

  isDefault: boolean = false;

  name: string;

  owner: string | null = null;

  constructor(data: PaymentCardData) {
    this.brand = data.brand;
    this.expMonth = data.expMonth;
    this.expYear = data.expYear;
    this.id = data.id ?? null;
    this.name = data.name;
    this.owner = data.owner ?? null;
    this.isDefault = data.isDefault ?? false;
  }

  get isExpired(): boolean {
    return this.calculateDaysUntilExpiry() < 0;
  }

  calculateDaysUntilExpiry(): number {
    return Math.floor(
      DateTime.fromObject({ month: this.expMonth, year: this.expYear, day: 1 })
        .plus({ months: 1 })
        .diffNow('days')
        .toObject().days || 0
    );
  }

  isRemovable(): boolean {
    return !this.isDefault;
  }
}
