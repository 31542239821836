import { Icon, Tooltip } from '@partstech/ui';
import type { ReactNode } from 'react';

type Props = {
  tooltip: ReactNode;
};

export const AttributeTooltip = ({ tooltip }: Props) => (
  <Tooltip positioning="horizontal" allowAutoPositioning display="inline" tooltip={tooltip}>
    <Icon name="info" color="monoDark" size="medium" />
  </Tooltip>
);
