import { usePrevious } from '@partstech/ui/hooks';
import { useEffect, useMemo } from 'react';
import { useAppNavigate } from 'app/AppRouter/useAppNavigate';
import { useSearchResults } from 'pages/SearchResults/SearchResultsContext';
import { Availability } from 'types/search';
import { hasBackorder } from 'utils';
import { useGetSearchUrl } from './useGetSearchUrl';
import { useSearchParams } from './useSearchParams';

export const useRemoveBackorderFilter = () => {
  const navigate = useAppNavigate();
  const { searchParams } = useSearchParams();
  const { products, loadingIds } = useSearchResults();
  const { getSearchUrl } = useGetSearchUrl();

  const previousLoadingIds = usePrevious(loadingIds);
  const hasBackorderProduct = useMemo(() => hasBackorder(products), [products]);

  useEffect(() => {
    if (
      loadingIds.length === 0 &&
      previousLoadingIds &&
      previousLoadingIds.length > 0 &&
      !hasBackorderProduct &&
      searchParams.availability?.includes(Availability.BACK_ORDER)
    ) {
      navigate(
        getSearchUrl({
          ...searchParams,
          availability: searchParams.availability.filter((param) => param !== Availability.BACK_ORDER),
        })
      );
    }
  }, [getSearchUrl, hasBackorderProduct, loadingIds.length, navigate, previousLoadingIds, searchParams]);
};
