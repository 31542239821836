import { useDebouncedCallback } from '@partstech/ui/hooks';
import { useCallback, useMemo } from 'react';
import { shallowEqual } from 'react-redux';
import { initialShop } from 'constant';
import { useLaunchDarkly } from 'integrations/launchDarkly';
import { useAppDispatch, useAppSelector } from 'store';
import {
  removeIndex,
  reorderQuoteSlots,
  resetQuote,
  selectQuoteQuantity,
  selectQuoteSlots,
  selectRawSlots,
  setQuoteQuantity,
} from 'store/features/quote';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import { removeNonDigits, convertQuoteSlots } from 'utils';
import { useComparedQuoteProducts } from '../useComparedQuoteProducts';
import { useClearComparedQuote } from './useClearComparedQuote';
import type { UseCreateQuoteResult } from 'types/quote';

export const useComparedQuoteCreation = (): UseCreateQuoteResult => {
  const dispatch = useAppDispatch();
  const {
    flags: { tireQuoteGql },
  } = useLaunchDarkly();

  const { clear } = useClearComparedQuote();

  const { shop, email, showRetailPrice } = useGetCurrentUser();

  const quantity = useAppSelector(selectQuoteQuantity);
  const rawSlots = useAppSelector(selectRawSlots, shallowEqual);

  const { isLoading, isSuccess, failedProductIds, fetchedProductIds, isError, products, isProductLoading } =
    useComparedQuoteProducts();

  const availableQuantity = useMemo(() => {
    const availableItems = products.filter(Boolean).map((product) => product?.quote?.getAvailableQuantity() ?? 0);

    return Math.min(...availableItems);
  }, [products]);

  const hasAllQuantityAvailable = useMemo(() => availableQuantity >= quantity, [availableQuantity, quantity]);

  const slotsGraphQL = convertQuoteSlots(rawSlots, products, fetchedProductIds, failedProductIds);
  const slotsRest = useAppSelector((state) => selectQuoteSlots(state, showRetailPrice), shallowEqual);

  const slots = tireQuoteGql ? slotsGraphQL : slotsRest;

  const isSingleSlot = useMemo(() => slots.filter((slot) => slot.urlParams).length === 1, [slots]);

  const setQuantity = useDebouncedCallback((value: number) => {
    dispatch(setQuoteQuantity(value));
  }, 300);

  const removeProductByIndex = useCallback(
    (index: number) => {
      dispatch(removeIndex(index));
    },
    [dispatch]
  );

  const swapSlots = useCallback(
    ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
      dispatch(reorderQuoteSlots({ oldIndex, newIndex }));
    },
    [dispatch]
  );

  const reset = useCallback(() => {
    dispatch(resetQuote());
  }, [dispatch]);

  const productErrorIds = useMemo(
    () => slots.filter((slot) => slot.isInvalid).map((errorSlot) => removeNonDigits(errorSlot.urlParams!.partnumberid)),
    [slots]
  );

  return {
    availableQuantity,
    clear,
    hasAllQuantityAvailable,
    isError,
    isLoading,
    isSingleSlot,
    isSuccess,
    productErrorIds,
    quantity,
    removeProductByIndex,
    reset,
    setQuantity,
    shop: shop
      ? {
          email: email || null,
          logo: shop.logo || null,
          name: shop.name || '',
          phone: shop.phone || null,
        }
      : initialShop,
    slots,
    swapSlots,
    isSlotLoading: isProductLoading,
  };
};
