/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type CreateShopManagementSystemMutationVariables = Types.Exact<{
  input: Types.CreateShopManagementSystemRelationInput;
}>;

export type CreateShopManagementSystemMutation = {
  __typename?: 'Mutation';
  createShopManagementSystemRelation?: {
    __typename?: 'CreateShopManagementSystemRelationPayload';
    shop?: {
      __typename?: 'Shop';
      managementSystem?:
        | { __typename?: 'CustomManagementSystem'; name: Types.Scalars['String']['input'] }
        | {
            __typename?: 'ManagementSystem';
            id: string;
            logo?: Types.Scalars['String']['input'] | null;
            manual?: Types.Scalars['String']['input'] | null;
            name: Types.Scalars['String']['input'];
            status?: Types.ManagementSystemStatus | null;
          }
        | null;
    } | null;
  } | null;
};

export const CreateShopManagementSystemDocument = `
    mutation CreateShopManagementSystem($input: CreateShopManagementSystemRelationInput!) {
  createShopManagementSystemRelation(input: $input) {
    shop {
      managementSystem {
        ... on ManagementSystem {
          id
          logo
          manual
          name
          status
        }
        ... on CustomManagementSystem {
          name
        }
      }
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    CreateShopManagementSystem: build.mutation<
      CreateShopManagementSystemMutation,
      CreateShopManagementSystemMutationVariables
    >({
      query: (variables) => ({ document: CreateShopManagementSystemDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useCreateShopManagementSystemMutation } = injectedRtkApi;
