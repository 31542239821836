import { useMemo } from 'react';
import { PartNumber, TireConfiguration, type TireSize } from 'models';
import { SearchTypes } from 'types/search';
import { filterTiresSizesByString } from 'utils';
import { useDefaultOptions } from '../useDefaultOptions';

const emptyTireSizes: TireSize[] = [];

type Props = {
  search?: string;
  skip?: boolean;
};

export const useTireSizeSearch = ({ search = '', skip }: Props = {}) => {
  const { tireSizes } = useDefaultOptions();

  const matchedTireSizes = useMemo(() => {
    if (skip) {
      return emptyTireSizes;
    }

    return filterTiresSizesByString(tireSizes, search).splice(0, 200);
  }, [search, skip, tireSizes]);

  const partNumbers = useMemo(() => {
    if (TireConfiguration.createFromString(search)?.isStaggered()) {
      return [];
    }

    return [
      new PartNumber({ id: search, partNumber: search, searchType: SearchTypes.TIRES, columnTitle: 'part number' }),
    ];
  }, [search]);

  const options = useMemo(() => [...matchedTireSizes, ...partNumbers], [matchedTireSizes, partNumbers]);

  return { options, matchedTireSizes };
};
