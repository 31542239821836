import { sortUniq } from '@partstech/ui/utils';
import { useMemo } from 'react';
import type { Option } from '@partstech/ui';
import type { TireSizeFieldName, TireSizeFormData } from 'features/searchForm';
import type { TireSize } from 'models';

const createOption =
  (field: TireSizeFieldName) =>
  (tireSize: TireSize): Option => ({
    value: `${tireSize[field]}`,
    text: `${tireSize[field]}`,
  });

const emptyOptions = [{ value: '', text: '' }];

export const useTireSizeOptions = (tireSizes: TireSize[], value: TireSizeFormData) => {
  const widths = useMemo(() => sortUniq(tireSizes, 'width').map(createOption('width')), [tireSizes]);

  const heights = useMemo(
    () =>
      value.width
        ? sortUniq(
            tireSizes.filter((tireSize) => `${tireSize.width}` === value.width),
            'height'
          ).map(createOption('height'))
        : emptyOptions,
    [tireSizes, value.width]
  );

  const rims = useMemo(
    () =>
      value.width && value.height
        ? tireSizes
            .filter((tireSize) => `${tireSize.width}` === value.width && `${tireSize.height}` === value.height)
            .map(createOption('rim'))
        : emptyOptions,
    [tireSizes, value.width, value.height]
  );

  return {
    widths,
    heights,
    rims,
  };
};
