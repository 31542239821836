import { api as generatedApi } from './GetSmsRegistrationData.generated';

const api = generatedApi.enhanceEndpoints({
  addTagTypes: ['SmsRegistrationData'],
  endpoints: {
    GetSmsRegistrationData: {
      providesTags: () => ['SmsRegistrationData'],
    },
  },
});

const { useGetSmsRegistrationDataQuery } = api;

type Args = {
  hash: string;
};

type Params = Partial<{
  skip?: boolean;
}>;

export const useGetSmsRegistrationData = ({ hash }: Args, { skip = false }: Params = {}) => {
  const {
    currentData,
    isError: isQueryError,
    ...rest
  } = useGetSmsRegistrationDataQuery({ hash }, { skip: skip || !hash });

  const smsRegistrationData = currentData?.smsRegistrationData?.data ?? null;

  const isError = isQueryError || Boolean(currentData?.smsRegistrationData?.error);

  return { smsRegistrationData, isError, ...rest };
};
