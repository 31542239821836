import { Box, Button, Typography } from '@partstech/ui';

type Props = {
  message?: string;
  subMessage?: string;
  hasVehicle: boolean;
  buttonText?: string;
  onSelectVehicleClick: () => void;
};

export const EmptyState = ({
  message,
  subMessage,
  hasVehicle,
  buttonText = 'Select vehicle',
  onSelectVehicleClick,
}: Props) => (
  <Box pb={10} pt={10} data-testid="message" alignItems="center" flexDirection="column" textAlign="center">
    {message && <Typography variant="subtitle1">{message}</Typography>}

    {subMessage && (
      <Box mt={1}>
        <Typography variant="bodyText1" color="subtleText">
          {subMessage}
        </Typography>
      </Box>
    )}

    {!hasVehicle && (
      <Box mt={6}>
        <Button variant="secondary" onClick={onSelectVehicleClick} data-testid="selectVehicle">
          {buttonText}
        </Button>
      </Box>
    )}
  </Box>
);
