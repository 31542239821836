import { usePrevious } from '@partstech/ui/hooks';
import { isEmpty, isEqual } from '@partstech/ui/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { VehicleParameters } from '../../types';
import type { Vehicle } from 'models';

type Props = {
  isActive?: boolean;
  vehicle?: Vehicle | null;
  vehicleParameters?: VehicleParameters;
};

const emptyParameters: VehicleParameters = {
  year: '',
  makeId: '',
  modelId: '',
  submodelId: '',
  engineId: '',
};

export const mapVehicleToVehicleParameters = (vehicle: Vehicle): VehicleParameters => ({
  year: String(vehicle.year),
  makeId: vehicle.make.id,
  modelId: vehicle.model.id,
  submodelId: vehicle.subModel.id,
  engineId: vehicle.engine.id,
});

export const useVehicleParameters = ({ isActive, vehicle, vehicleParameters: initialParameters }: Props) => {
  const [vehicleParameters, setVehicleParameters] = useState<VehicleParameters>(initialParameters ?? emptyParameters);
  const wasActive = usePrevious(isActive);

  const resetVehicleParameters = useCallback(() => {
    setVehicleParameters(emptyParameters);
  }, []);

  const areVehicleParametersEmpty = useMemo(() => Object.values(vehicleParameters).every(isEmpty), [vehicleParameters]);
  const areVehicleParametersFilled = useMemo(
    () => Object.values(vehicleParameters).every((parameter) => parameter),
    [vehicleParameters]
  );

  useEffect(() => {
    if (vehicle && areVehicleParametersEmpty && !wasActive) {
      setVehicleParameters(mapVehicleToVehicleParameters(vehicle));
    }
  }, [areVehicleParametersEmpty, vehicle, vehicleParameters, wasActive]);

  useEffect(() => {
    if (!areVehicleParametersFilled || !vehicle) {
      return;
    }

    const newVehicleParameters = mapVehicleToVehicleParameters(vehicle);

    if (!isEqual(newVehicleParameters, vehicleParameters)) {
      setVehicleParameters(newVehicleParameters);
    }
  }, [areVehicleParametersFilled, vehicle, vehicleParameters]);

  return { vehicleParameters, setVehicleParameters, resetVehicleParameters };
};
