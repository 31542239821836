/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import type * as Types from '../../../../../shared/api/graphql/graphql';

export type UpcomingInvoiceFragment = {
  __typename?: 'SubscriptionUpcomingInvoice';
  grandTotal: Types.Scalars['BigDecimal']['input'];
  nextPaymentAmount: Types.Scalars['BigDecimal']['input'];
  tax: Types.Scalars['BigDecimal']['input'];
  total: Types.Scalars['BigDecimal']['input'];
};

export const UpcomingInvoiceFragmentDoc = `
    fragment UpcomingInvoice on SubscriptionUpcomingInvoice {
  grandTotal
  nextPaymentAmount
  tax
  total
}
    `;
