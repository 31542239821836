import { css } from '@emotion/react';
import { Icon } from '@partstech/ui';
import { useMemo } from 'react';
import { useBrandOptions } from 'hooks/preferredBrands';
import { useSearchParams } from 'hooks/search';
import { useSearchResults } from 'pages/SearchResults/SearchResultsContext';
import { Options } from '../../DefaultFilter/Options';
import type { OptionsProps } from '../../DefaultFilter/Options/types';
import type { Theme } from '@partstech/ui';

const styles = {
  icon: (theme: Theme) => css`
    vertical-align: bottom;
    margin-left: ${theme.sizing(1)};
  `,
};

export const BrandOptions = ({ options, ...props }: OptionsProps) => {
  const { isTiresSearch, isPartNumberSearch } = useSearchParams();

  const { products } = useSearchResults();

  const { preferredBrandOptions: preferredBrands } = useBrandOptions({
    products,
    type: isTiresSearch ? 'Tire' : 'Part',
  });

  const preferredBrandOptions = useMemo(
    () =>
      isPartNumberSearch
        ? []
        : options.filter((option) => preferredBrands.find((brand) => `${option.value}` === brand.name)),
    [isPartNumberSearch, options, preferredBrands]
  );

  const regularOptions = useMemo(
    () => (isPartNumberSearch ? options : options.filter((option) => !preferredBrandOptions.includes(option))),
    [isPartNumberSearch, options, preferredBrandOptions]
  );

  return (
    <>
      <Options
        options={preferredBrandOptions}
        nameAsValue
        adornmentAfter={<Icon name="loyalty" color="primaryLight10" size="large" css={styles.icon} />}
        {...props}
      />
      <Options options={regularOptions} nameAsValue {...props} />
    </>
  );
};
