import { Vehicle } from '../models/Vehicle';
import { createDiagramsFromQuery } from './createDiagramsFromQuery';
import { createTireConfigurationsFromQuery } from './createTireConfigurationsFromQuery';
import { createJobsFromQuery } from './jobs/createJobsFromQuery';
import type { JobFragment } from 'shared/api';
import type { DiagramFragment } from 'store/queries/diagrams/DiagramFragment.generated';
import type { TiresFragment } from 'store/queries/vehicle/TiresFragment.generated';
import type { VehicleFragment } from 'store/queries/vehicle/VehicleFragment.generated';

type Fragment = VehicleFragment & TiresFragment & { diagrams?: DiagramFragment[] | null; jobs?: JobFragment[] | null };

export const createVehicleFromQuery = (
  vehicleFragment: (Fragment & Partial<{ state: string; plate: string; vin: string | null }>) | null | undefined
): Vehicle | null => {
  if (!vehicleFragment) {
    return null;
  }

  const vehicle = new Vehicle({ ...vehicleFragment, regions: vehicleFragment.regions ?? [], vin: vehicleFragment.vin });

  if (vehicleFragment?.tires) {
    vehicle.setTires(createTireConfigurationsFromQuery(vehicleFragment.tires));
  }

  if (vehicleFragment?.diagrams) {
    vehicle.setDiagrams(createDiagramsFromQuery(vehicleFragment.diagrams));
  }

  if (vehicleFragment?.jobs) {
    vehicle.setJobs(createJobsFromQuery(vehicleFragment.jobs));
  }

  return vehicle;
};
