import { useMedia } from '@partstech/ui';
import { useCallback, useEffect, useRef } from 'react';
import { useLayoutContext } from 'app/contexts/LayoutProvider/LayoutContext';
import { NavigationItemKey } from 'components/AppNavigationList/useAppNavigationList';
import { useProfileModal } from 'components/Modal';
import { defaultUIOffset } from 'constant';
import { useElementEventListener } from 'shared/lib/react-dom';

type UseProfileBarArg = {
  disableExpaning: boolean;
};

export const endOffset = 261;

export const useProfileBar = ({ disableExpaning }: UseProfileBarArg) => {
  const { isMobile, isTablet } = useMedia();

  const isMobileDevice = isMobile || isTablet;

  const deferredOpenProfileBar = useRef<NodeJS.Timeout | null>(null);

  const { isOpenProfileBar, toggleProfileBar, openProfileBar, closeProfileBar, setUIOffsetProfileBar } =
    useLayoutContext();

  const elementRef = useRef<HTMLDivElement | null>(null);

  const startOffset = defaultUIOffset.left;

  const clearDeferredOpenProfileBar = useCallback(() => {
    if (!deferredOpenProfileBar.current) {
      return;
    }

    clearTimeout(deferredOpenProfileBar.current);
  }, []);

  const handleOpenProfileBar = useCallback(() => {
    if (isMobileDevice || disableExpaning) {
      return;
    }

    deferredOpenProfileBar.current = setTimeout(openProfileBar, 500);
  }, [disableExpaning, isMobileDevice, openProfileBar]);

  const selectItem = useCallback(
    (key: NavigationItemKey) => {
      const isSupplierItem = key === NavigationItemKey.Suppliers;

      if (isMobileDevice || isSupplierItem) {
        closeProfileBar();
      }
    },
    [closeProfileBar, isMobileDevice]
  );

  const handleCloseProfileBar = useCallback(() => {
    if (isMobileDevice) {
      return;
    }

    clearDeferredOpenProfileBar();

    closeProfileBar();
  }, [clearDeferredOpenProfileBar, closeProfileBar, isMobileDevice]);

  useEffect(() => {
    setUIOffsetProfileBar({ left: isOpenProfileBar ? endOffset : startOffset });
  }, [setUIOffsetProfileBar, startOffset, isOpenProfileBar]);

  useEffect(
    () => () => {
      clearDeferredOpenProfileBar();
    },
    [clearDeferredOpenProfileBar]
  );

  useElementEventListener('mouseenter', handleOpenProfileBar, elementRef);
  useElementEventListener('mouseleave', handleCloseProfileBar, elementRef);

  useProfileModal();

  return { elementRef, startOffset, isOpenProfileBar, toggleProfileBar, selectItem };
};
