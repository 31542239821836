import { useEffect } from 'react';
import { useAppNavigate } from 'app/AppRouter/useAppNavigate';
import { DEFAULT_AVAILABILITY } from 'constant';
import { useGetSearchUrl } from './useGetSearchUrl';
import { useSearchParams } from './useSearchParams';

export const useAddDefaultAvailabilityFilter = () => {
  const navigate = useAppNavigate();
  const { searchParams, selectedSupplierId } = useSearchParams();

  const { getSearchUrl } = useGetSearchUrl();

  useEffect(() => {
    if (!searchParams.availability) {
      navigate(
        getSearchUrl({
          ...searchParams,
          selected_distributor: selectedSupplierId ?? undefined,
          availability: DEFAULT_AVAILABILITY,
        }),
        {
          replace: true,
        }
      );
    }
  }, [getSearchUrl, navigate, searchParams, selectedSupplierId]);
};
