/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type RemovePaymentCardMutationVariables = Types.Exact<{
  input: Types.DeleteShopCardInput;
}>;

export type RemovePaymentCardMutation = {
  __typename?: 'Mutation';
  deleteShopCard?: { __typename?: 'DeleteShopCardPayload'; id?: string | null } | null;
};

export const RemovePaymentCardDocument = `
    mutation RemovePaymentCard($input: DeleteShopCardInput!) {
  deleteShopCard(input: $input) {
    id
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    RemovePaymentCard: build.mutation<RemovePaymentCardMutation, RemovePaymentCardMutationVariables>({
      query: (variables) => ({ document: RemovePaymentCardDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useRemovePaymentCardMutation } = injectedRtkApi;
