import { useMutationStatus } from 'shared/api';
import { api as generatedApi } from './RemoveUserAvatar.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    RemoveUserAvatar: {
      invalidatesTags: ['Member'],
    },
  },
});

const { useRemoveUserAvatarMutation } = api;

export const useRemoveUserAvatar = () => {
  const [removeShopLogo, status] = useRemoveUserAvatarMutation();

  useMutationStatus({ status });

  return [removeShopLogo, status] as const;
};
