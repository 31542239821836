import { LegacyJob } from './LegacyJob';
import type { PartTypeQuery } from 'features/searchForm';

export class LegacyJobAlias extends LegacyJob {
  constructor(
    legacyJob: Pick<LegacyJob, 'id' | 'name' | 'aliases' | 'partTypes'>,
    private alias: string
  ) {
    super(legacyJob);

    this.name = alias;
  }

  toString(): string {
    return this.alias;
  }

  populateInput(): string {
    return this.alias;
  }

  toQuery(): PartTypeQuery {
    return {
      part_text_id: this.id,
      part_text: this.alias,
    };
  }
}
