import { api as generatedApi } from './CreateTaxRate.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    CreateTaxRate: {
      invalidatesTags: ['TaxRate', 'ActiveCart'],
    },
  },
});

export const { useCreateTaxRateMutation } = api;
