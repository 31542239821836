import type { SupplierAccount } from 'models';
import type { AccountStatus } from 'shared/api';

export const filterSupplierAccounts = (accounts: SupplierAccount[], type: 'parts' | 'tires', status?: AccountStatus) =>
  accounts.filter((account) => {
    const isTires = Boolean(account.supplier?.isTire);
    const isMatchingType = type === 'tires' ? isTires : !isTires;
    const isMatchingStatus = status ? status === account.status : true;

    return isMatchingType && isMatchingStatus;
  });
