/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type ToggleCartRetailPricingMutationVariables = Types.Exact<{
  input: Types.ToggleShowRetailPricingInput;
}>;

export type ToggleCartRetailPricingMutation = {
  __typename?: 'Mutation';
  toggleShowRetailPricing?: {
    __typename?: 'ToggleShowRetailPricingPayload';
    cart?: { __typename?: 'Cart'; id: string; showRetailPricing: Types.Scalars['Boolean']['input'] } | null;
  } | null;
};

export const ToggleCartRetailPricingDocument = `
    mutation ToggleCartRetailPricing($input: ToggleShowRetailPricingInput!) {
  toggleShowRetailPricing(input: $input) {
    cart {
      id
      showRetailPricing
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    ToggleCartRetailPricing: build.mutation<ToggleCartRetailPricingMutation, ToggleCartRetailPricingMutationVariables>({
      query: (variables) => ({ document: ToggleCartRetailPricingDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useToggleCartRetailPricingMutation } = injectedRtkApi;
