import { useMemo } from 'react';
import { createPartTypeFromQuery, createSubCategoryFromQuery } from 'factories';
import { MatrixType } from 'shared/api';
import { useGetRetailPricing } from 'store/queries/currentUser/retailPricing/getRetailPricing/useGetRetailPricing';
import { isEmptyMatrix, isPartMatrix, isTireMatrix } from 'utils';
import type { RetailPriceMatrix } from 'types/matrix';

export const usePriceMatrices = () => {
  const { priceMatrices: queryPriceMatrices, ...props } = useGetRetailPricing();

  const retailPriceMatrices: RetailPriceMatrix[] = useMemo(
    () =>
      queryPriceMatrices.map((matrix) => ({
        id: Number(matrix.id) ?? undefined,
        name: matrix.name,
        isPart: matrix.type === MatrixType.Part,
        items: matrix.items,
        brands: matrix.brands?.map(({ id, name }) => ({ id, name })) || [],
        subCategories:
          matrix.subCategories && matrix.subCategories.length > 0
            ? matrix.subCategories.map(({ subCategory, partTypes }) =>
                createSubCategoryFromQuery(
                  subCategory,
                  undefined,
                  partTypes.map((partType) => createPartTypeFromQuery({ ...partType, subCategory }))
                )
              )
            : [],
        createdAt: matrix.createdAt,
      })),
    [queryPriceMatrices]
  );

  const partMatrices = useMemo(() => retailPriceMatrices.filter(isPartMatrix), [retailPriceMatrices]);
  const hasPartMatrix = useMemo(() => !partMatrices.every(isEmptyMatrix), [partMatrices]);
  const tireMatrices = useMemo(() => retailPriceMatrices.filter(isTireMatrix), [retailPriceMatrices]);
  const hasTireMatrix = useMemo(() => !tireMatrices.every(isEmptyMatrix), [tireMatrices]);

  const configuredPartMatrices = useMemo(() => partMatrices.filter((matrix) => !isEmptyMatrix(matrix)), [partMatrices]);
  const configuredTireMatrices = useMemo(() => tireMatrices.filter((matrix) => !isEmptyMatrix(matrix)), [tireMatrices]);

  return {
    ...props,
    priceMatrices: retailPriceMatrices,
    configuredPartMatrices,
    configuredTireMatrices,
    partMatrices,
    hasPartMatrix,
    tireMatrices,
    hasTireMatrix,
    hasSomeMatrix: retailPriceMatrices.length > 0,
  };
};
