import { useMatch, useParams } from 'react-router-dom';
import { Routes } from 'constant';
import { getMyShopUrl } from 'utils/url';
import { useMyShopAvailablePages } from './useMyShopAvailablePages';
import type { MyShopPageParams } from 'utils/url';

export const useGetMyShopRedirect = () => {
  const { '*': pageAlias } = useParams<MyShopPageParams>();
  const isMyShopPages = Boolean(useMatch(Routes.MY_SHOP));

  const { isAvailable: checkIsAvailable } = useMyShopAvailablePages();

  if (!isMyShopPages || !pageAlias) {
    return null;
  }

  const isAvailable = checkIsAvailable(pageAlias);

  if (!isAvailable) {
    return getMyShopUrl('details');
  }

  return null;
};
