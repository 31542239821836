import { useSnackbar } from '@partstech/ui';
import { useCallback } from 'react';
import { useMutationStatus } from 'shared/api';
import { api as savedCartListApi } from '../../queries/GetSavedCartList.generated';
import { api as generatedApi } from './ArchiveCart.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    ArchiveCart: {
      invalidatesTags: ['SavedCart'],
      onQueryStarted: async (params, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            savedCartListApi.util.updateQueryData('GetSavedCartList', {}, (draft) => {
              const { carts } = draft;

              if (carts) {
                carts.edges = carts?.edges?.filter((item) => item?.node?.id !== params.input.id) || [];
              }
            })
          );
        } catch {
          /* empty */
        }
      },
    },
  },
});

const { useArchiveCartMutation } = api;

export const useArchiveCart = () => {
  const [archiveCart, status] = useArchiveCartMutation();
  const { addSnackbar } = useSnackbar();

  const onSuccess = useCallback(() => {
    addSnackbar({ label: 'Your quote has been archived.' });
  }, [addSnackbar]);

  useMutationStatus({ status, onSuccess });

  return [archiveCart, status] as const;
};
