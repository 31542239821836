import { useMutationStatus } from 'shared/api';
import { api } from './UpdatePaymentNotes.generated';

const { useUpdatePaymentNotesMutation } = api;

export const useUpdatePaymentNotes = () => {
  const [updateShipping, status] = useUpdatePaymentNotesMutation();

  useMutationStatus({ status });

  return [updateShipping, status] as const;
};
