import { css } from '@emotion/react';
import type { Theme } from '@partstech/ui';

export const styles = {
  supplierTypeItem: (theme: Theme) => css`
    margin: ${theme.sizing(4, 3, 0, 0)};

    ${theme.breakpoints.down('md')} {
      margin: ${theme.sizing(0, 2, 1, 0)};
    }
  `,
  catalog: (isCatalogSelected: boolean) => (theme: Theme) => css`
    display: flex;
    align-items: center;
    cursor: pointer;
    border: 1px solid ${isCatalogSelected ? theme.color.primary : theme.color.mono17};
    border-radius: ${theme.sizing(1)};
    background: ${isCatalogSelected ? theme.color.lightBlue : 'transparent'};

    ${theme.breakpoints.down('md')} {
      justify-content: center;
      align-items: self-end;
      width: ${theme.sizing(6)};
      height: ${theme.sizing(6)};
    }
  `,
};
