/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type ConnectShopToSmsMutationVariables = Types.Exact<{
  input: Types.ConnectShopToSmsInput;
}>;

export type ConnectShopToSmsMutation = {
  __typename?: 'Mutation';
  connectShopToSms?: {
    __typename?: 'ConnectShopToSmsPayload';
    success?: Types.Scalars['Boolean']['input'] | null;
    error?: Types.ConnectShopToSmsError | null;
  } | null;
};

export const ConnectShopToSmsDocument = `
    mutation ConnectShopToSms($input: ConnectShopToSmsInput!) {
  connectShopToSms(input: $input) {
    success
    error
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    ConnectShopToSms: build.mutation<ConnectShopToSmsMutation, ConnectShopToSmsMutationVariables>({
      query: (variables) => ({ document: ConnectShopToSmsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useConnectShopToSmsMutation } = injectedRtkApi;
