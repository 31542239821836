import { useCallback, useMemo } from 'react';
import { SubscriptionPlanPeriod } from 'shared/api';
import { PLANS } from '../constants/plans';
import { ProductAlias } from '../constants/products';
import { useSubscriptionProducts } from './useSubscriptionProducts';
import type { Plan } from '../types';

export const usePlans = (isYearly: boolean = false) => {
  const { subscription, getProductByAlias, isLoading } = useSubscriptionProducts(isYearly);

  const subscriptionPlan = subscription?.plan;
  const isYearlySubscription = subscription?.period === SubscriptionPlanPeriod.Year;

  const plans: Plan[] = useMemo(
    () =>
      PLANS.map((PLAN) => {
        const { alias, priceMonthly, priceYearly } = PLAN;

        const product = getProductByAlias(alias);
        const price = isYearlySubscription ? priceYearly : priceMonthly;

        const isDowngrading = subscriptionPlan?.downgradeProductAlias === alias;

        return {
          ...PLAN,
          ...product,
          canDowngrade: !isDowngrading && Number(subscriptionPlan?.amount) > price,
          isActive:
            (alias === ProductAlias.Free && !subscriptionPlan) ||
            subscriptionPlan?.alias === alias ||
            Boolean(product?.isActive),
          isDowngrading,
        };
      }),
    [getProductByAlias, isYearlySubscription, subscriptionPlan]
  );

  const getPlanByAlias = useCallback((alias?: ProductAlias) => plans.find((plan) => plan.alias === alias), [plans]);

  const getLosingFeaturesOnDowngrade = useCallback(
    (planAliasAfterDowngrading?: ProductAlias) => {
      const currentFeatures = getPlanByAlias(subscriptionPlan?.alias)?.features;
      const featuresAfterDowngrading = getPlanByAlias(planAliasAfterDowngrading)?.features;

      return currentFeatures?.filter((planFeature) => {
        const isFeatureLost = !featuresAfterDowngrading?.some(
          (feature) =>
            feature.alias === planFeature.alias &&
            feature.isEnabled === planFeature.isEnabled &&
            feature.partialEnabled === planFeature.partialEnabled
        );

        return isFeatureLost;
      });
    },
    [subscriptionPlan?.alias, getPlanByAlias]
  );

  return {
    currentPeriodEnd: subscription?.currentPeriodEnd,
    isLoading,
    plans,
    subscriptionPlan,
    getLosingFeaturesOnDowngrade,
    getPlanByAlias,
  };
};
