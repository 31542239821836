import { Routes } from 'constant';
import { generateUrl } from './generateUrl';

export type SignUpFormVariant = 'detailed' | 'default';

export type SmsConnectionsQueryParams = Partial<{
  signUpFormVariant: SignUpFormVariant;
}>;

export enum SmsConnectionsPages {
  Connect = '',
  ForgotPassword = 'forgot-password',
  LogIn = 'log-in',
  SignUp = 'sign-up',
  Error = 'error',
  Success = 'success',
}

export const getSmsConnectionsUrl = ({
  page = SmsConnectionsPages.Connect,
  hash = '',
  search,
}: {
  page?: SmsConnectionsPages;
  hash: string;
  search?: SmsConnectionsQueryParams;
}) => generateUrl(Routes.SMS_CONNECTIONS, { hash, page }, search);
