import type { Coordinates } from 'shared/api';

export const calcDistanceFromCoordinates = (coordinates1: Coordinates | null, coordinates2: Coordinates | null) => {
  if (!coordinates1 || !coordinates2) {
    return null;
  }

  if (coordinates1.latitude === coordinates2.latitude && coordinates1.longitude === coordinates2.longitude) {
    return 0;
  }

  const radlat1 = (Math.PI * coordinates1.latitude) / 180;
  const radlat2 = (Math.PI * coordinates2.latitude) / 180;
  const theta = coordinates1.longitude - coordinates2.longitude;
  const radtheta = (Math.PI * theta) / 180;

  let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

  if (dist > 1) {
    dist = 1;
  }

  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;

  return Number(dist.toFixed(2));
};
