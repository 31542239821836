import { Box, Card, Icon } from '@partstech/ui';
import { useToggle } from '@partstech/ui/hooks';
import { isNotNull } from '@partstech/ui/utils';
import { useCallback } from 'react';
import { ActionButton } from 'components/SearchSupplierContainer/Stores/StoreList/StoreCard/StoreActionButton';
import { StoreAddress } from 'components/StoreAddress';
import type { Store, SupplierAccount } from 'models';

type Props = {
  store: Store;
  account: SupplierAccount | null;
  onAddClick: () => void;
  onDeleteClick: () => void;
  onMouseEnter?: (storeId: string | null) => void;
  onMouseLeave?: (storeId: string | null) => void;
};

export const ChooseLocationsSupplierCard = ({
  store,
  account,
  onAddClick,
  onDeleteClick,
  onMouseEnter,
  onMouseLeave,
}: Props) => {
  const { isToggle: isHovered, setToggle: setHovered } = useToggle();

  const handleMouseEnter = useCallback(() => {
    if (onMouseEnter) {
      onMouseEnter(store.id);
    }

    setHovered(true);
  }, [onMouseEnter, setHovered, store.id]);

  const handleMouseLeave = useCallback(() => {
    if (onMouseLeave) {
      onMouseLeave(null);
    }

    setHovered(false);
  }, [onMouseLeave, setHovered]);

  return (
    <Card
      p={4}
      display="flex"
      justifyContent="space-between"
      gap={2}
      width="100%"
      minHeight={26}
      elevation={isHovered ? 3 : 0}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      borderColor="mono17"
      data-testid="storeCard"
    >
      <Box display="flex" gap={2}>
        <Icon name="location_on" />

        <Box>
          <StoreAddress
            store={store}
            account={account}
            size="large"
            display="flex"
            flexDirection="column-reverse"
            linesCount={{ name: 2, address: 2 }}
          />

          {isNotNull(store.distance) && <Box mt={isNotNull(store.name) ? 1 : 6}>{store.distance} miles away</Box>}
        </Box>
      </Box>

      <ActionButton store={store} onAddClick={onAddClick} onDeleteClick={onDeleteClick} />
    </Card>
  );
};
