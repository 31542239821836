import type { MemberPermissions as MemberPermissionsType } from 'shared/api';

type MemberPermissionsData = {
  allOrders: boolean;
  boschRewards: boolean;
  brandPreferences: boolean;
  partsReport: boolean;
  payments: boolean;
  reportsDashboardAccess: boolean;
  retailPricing: boolean;
  shipping: boolean;
  shopProfile: boolean;
  spendReport: boolean;
  subscription: boolean;
  suppliers: boolean;
  tireReport: boolean;
  viewWholesaleCosts: boolean;
};

export class MemberPermissions {
  allOrdersAllowed: boolean = false;

  boschRewardsAllowed: boolean = false;

  brandPreferencesAllowed: boolean = false;

  partsReportAllowed: boolean = false;

  paymentsAllowed: boolean = false;

  reportsDashboardAccessAllowed: boolean = false;

  retailPricingAllowed: boolean = false;

  shippingAllowed: boolean = false;

  shopProfileAllowed: boolean = false;

  spendReportAllowed: boolean = false;

  subscriptionAllowed: boolean = false;

  suppliersAllowed: boolean = false;

  tireReportAllowed: boolean = false;

  viewWholesaleCostsAllowed: boolean = false;

  constructor(permissions?: MemberPermissionsData) {
    this.allOrdersAllowed = permissions?.allOrders ?? false;
    this.boschRewardsAllowed = permissions?.boschRewards ?? false;
    this.brandPreferencesAllowed = permissions?.brandPreferences ?? false;
    this.partsReportAllowed = permissions?.partsReport ?? false;
    this.paymentsAllowed = permissions?.payments ?? false;
    this.reportsDashboardAccessAllowed = permissions?.reportsDashboardAccess ?? false;
    this.retailPricingAllowed = permissions?.retailPricing ?? false;
    this.shippingAllowed = permissions?.shipping ?? false;
    this.shopProfileAllowed = permissions?.shopProfile ?? false;
    this.spendReportAllowed = permissions?.spendReport ?? false;
    this.subscriptionAllowed = permissions?.subscription ?? false;
    this.suppliersAllowed = permissions?.suppliers ?? false;
    this.tireReportAllowed = permissions?.tireReport ?? false;
    this.viewWholesaleCostsAllowed = permissions?.viewWholesaleCosts ?? false;
  }

  toJSON(): MemberPermissionsType {
    return {
      allOrders: this.allOrdersAllowed,
      boschRewards: this.boschRewardsAllowed,
      brandPreferences: this.brandPreferencesAllowed,
      partsReport: this.partsReportAllowed,
      payments: this.paymentsAllowed,
      reportsDashboardAccess: this.reportsDashboardAccessAllowed,
      retailPricing: this.retailPricingAllowed,
      shipping: this.shippingAllowed,
      shopProfile: this.shopProfileAllowed,
      spendReport: this.spendReportAllowed,
      subscription: this.subscriptionAllowed,
      suppliers: this.suppliersAllowed,
      tireReport: this.tireReportAllowed,
      viewWholesaleCosts: this.viewWholesaleCostsAllowed,
    };
  }
}
