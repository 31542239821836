/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type RemoveUserAvatarMutationVariables = Types.Exact<{ [key: string]: never }>;

export type RemoveUserAvatarMutation = {
  __typename?: 'Mutation';
  removeUserAvatar?: {
    __typename?: 'RemoveUserAvatarPayload';
    user?: { __typename?: 'User'; avatar?: Types.Scalars['String']['input'] | null } | null;
  } | null;
};

export const RemoveUserAvatarDocument = `
    mutation RemoveUserAvatar {
  removeUserAvatar {
    user {
      avatar
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    RemoveUserAvatar: build.mutation<RemoveUserAvatarMutation, RemoveUserAvatarMutationVariables | void>({
      query: (variables) => ({ document: RemoveUserAvatarDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useRemoveUserAvatarMutation } = injectedRtkApi;
