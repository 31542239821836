type CartTotalLine = {
  name: string;
  price: number;
};

export type CartTotalsData = {
  partCount: number;
  partsSum: number;
  partsSumRetail: number;
  discountSum: number;
  shippingLines: CartTotalLine[];
  taxesLines: CartTotalLine[];
  fetSum: number;
  coreChargeSum: number;
  laborHours?: number;
  laborRate?: number | null;
  hasDiscount: boolean;
  hasParts: boolean;
  hasTires: boolean;
};

export class CartTotals {
  partCount: number;

  partsSum: number;

  partsSumRetail: number;

  discountSum: number;

  fetSum: number;

  coreChargeSum: number;

  shippingLines: CartTotalLine[];

  taxesLines: CartTotalLine[];

  laborHours: number;

  laborRate: number;

  hasDiscount: boolean;

  hasParts: boolean;

  hasTires: boolean;

  constructor(data: CartTotalsData) {
    this.partCount = data.partCount;
    this.partsSum = data.partsSum;
    this.partsSumRetail = data.partsSumRetail;
    this.discountSum = data.discountSum;
    this.fetSum = data.fetSum;
    this.coreChargeSum = data.coreChargeSum;
    this.shippingLines = data.shippingLines;
    this.taxesLines = data.taxesLines;
    this.laborHours = data.laborHours || 0;
    this.laborRate = data.laborRate || 0;
    this.hasDiscount = data.hasDiscount;
    this.hasParts = data.hasParts;
    this.hasTires = data.hasTires;
  }

  get shippingSum(): number {
    return this.shippingLines.reduce((acc, line) => acc + line.price, 0);
  }

  get taxesSum(): number {
    return this.taxesLines.reduce((acc, line) => acc + line.price, 0);
  }

  get subtotalSum(): number {
    return this.partsSum + this.shippingSum + this.taxesSum + this.fetSum + this.coreChargeSum;
  }

  get grossProfit(): number {
    return this.partsSumRetail && this.partsSum
      ? Math.round(((this.partsSumRetail - this.partsSum + this.discountSum) / this.partsSumRetail) * 100)
      : 0;
  }

  get subtotalSumRetail(): number {
    return this.partsSumRetail + this.shippingSum + this.taxesSum + this.fetSum;
  }

  get laborSum(): number {
    return this.laborHours * this.laborRate;
  }

  get totalSum(): number {
    return this.subtotalSum + this.laborSum - this.discountSum;
  }

  get totalSumRetail(): number {
    return this.subtotalSumRetail + this.laborSum - this.discountSum;
  }

  get hasExtraCharge(): boolean {
    return Boolean(this.fetSum) || Boolean(this.taxesSum) || Boolean(this.coreChargeSum) || Boolean(this.shippingSum);
  }

  get hasLabor(): boolean {
    return this.laborHours > 0;
  }
}
