import { useOpen } from '@partstech/ui/hooks';
import { useCallback, useState } from 'react';
import { defaultUIOffset } from 'constant';
import type { UIOffset } from 'types/uiOffset';

export const useProfileBar = () => {
  const { isOpen, toggle, open, close } = useOpen();

  const [uiOffset, setUiOffset] = useState<UIOffset>(defaultUIOffset);

  const setUIOffset = useCallback(
    (offset: UIOffset) => {
      setUiOffset({ ...defaultUIOffset, ...offset });
    },
    [setUiOffset]
  );

  return {
    isOpen,
    toggle,
    open,
    close,
    setUIOffset,
    uiOffset,
  };
};
