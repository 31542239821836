/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type GetSubscriptionPaymentsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetSubscriptionPaymentsQuery = {
  __typename?: 'Query';
  currentUser?: {
    __typename?: 'User';
    activeMember?: {
      __typename?: 'ShopMember';
      shop?: {
        __typename?: 'Shop';
        subscriptionPayments?: Array<{
          __typename?: 'SubscriptionCustomerInvoice';
          amountDue: Types.Scalars['BigDecimal']['input'];
          amountPaid: Types.Scalars['BigDecimal']['input'];
          hostedInvoiceUrl?: Types.Scalars['String']['input'] | null;
          id: string;
          paid: Types.Scalars['Boolean']['input'];
          periodStart: Types.Scalars['DateTime']['input'];
          subTotal: Types.Scalars['BigDecimal']['input'];
          tax: Types.Scalars['BigDecimal']['input'];
          total: Types.Scalars['BigDecimal']['input'];
          card?: {
            __typename?: 'SubscriptionCustomerInvoiceCard';
            brand: Types.Scalars['String']['input'];
            expMonth: number;
            expYear: number;
            name: Types.Scalars['String']['input'];
            owner?: Types.Scalars['String']['input'] | null;
          } | null;
          coupon?: {
            __typename?: 'SubscriptionCustomerInvoiceCoupon';
            amountOff?: Types.Scalars['BigDecimal']['input'] | null;
            discountAmount: Types.Scalars['BigDecimal']['input'];
            name: Types.Scalars['String']['input'];
            percentOff?: Types.Scalars['BigDecimal']['input'] | null;
          } | null;
          lines?: Array<{
            __typename?: 'SubscriptionCustomerInvoiceLine';
            amount: Types.Scalars['BigDecimal']['input'];
            description: Types.Scalars['String']['input'];
            periodEnd: Types.Scalars['DateTime']['input'];
            periodStart: Types.Scalars['DateTime']['input'];
            qty: number;
            unitPrice?: Types.Scalars['BigDecimal']['input'] | null;
          }> | null;
        }> | null;
      } | null;
    } | null;
  } | null;
};

export const GetSubscriptionPaymentsDocument = `
    query GetSubscriptionPayments {
  currentUser {
    activeMember {
      shop {
        subscriptionPayments {
          amountDue
          amountPaid
          card {
            brand
            expMonth
            expYear
            name
            owner
          }
          coupon {
            amountOff
            discountAmount
            name
            percentOff
          }
          hostedInvoiceUrl
          id
          lines {
            amount
            description
            periodEnd
            periodStart
            qty
            unitPrice
          }
          paid
          periodStart
          subTotal
          tax
          total
        }
      }
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetSubscriptionPayments: build.query<GetSubscriptionPaymentsQuery, GetSubscriptionPaymentsQueryVariables | void>({
      query: (variables) => ({ document: GetSubscriptionPaymentsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetSubscriptionPaymentsQuery, useLazyGetSubscriptionPaymentsQuery } = injectedRtkApi;
