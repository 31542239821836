import { useCallback } from 'react';
import { useMutationStatus } from 'shared/api';
import { useChangeActiveSupplierMembershipMutation } from 'store/mutations/currentUser/memberships/changeActiveSupplierMembership/ChangeActiveSupplierMembership.generated';
import { useSettings } from '../app/useSettings';

export const useChangeActiveSupplierMembership = () => {
  const { supplierPortalDomain } = useSettings();

  const [changeActiveMembership, status] = useChangeActiveSupplierMembershipMutation();

  const handleChangeSuccess = useCallback(
    () => window.location.replace(`//${supplierPortalDomain}/`),
    [supplierPortalDomain]
  );

  const changeActiveSupplierMembership = useCallback(
    async (membershipID: string) => {
      await changeActiveMembership({ input: { membershipID } });
    },
    [changeActiveMembership]
  );

  useMutationStatus({
    status,
    onSuccess: handleChangeSuccess,
  });

  return [changeActiveSupplierMembership] as const;
};
