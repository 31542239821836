import { useMemo } from 'react';
import { getTireLoadRangePSI } from 'utils';
import { Options } from '../../DefaultFilter/Options';
import type { OptionsProps } from '../../DefaultFilter/Options/types';

export const LoadRangeOptions = ({ options, ...props }: OptionsProps) => {
  const loadRangeOptions = useMemo(
    () =>
      options.map((option) => {
        const loadRange = getTireLoadRangePSI(option.value);
        const optionName = loadRange ? `${option.name} - ${loadRange} psi` : option.name;

        return { ...option, name: optionName };
      }),
    [options]
  );

  return <Options options={loadRangeOptions} {...props} />;
};
