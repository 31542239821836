import { highlight } from '@partstech/ui/utils';
import type { PropsWithChildren } from 'react';

type Props = {
  children: string;
  value: string;
};

export const Highlight = ({ children, value }: PropsWithChildren<Props>) => (
  <span
    dangerouslySetInnerHTML={{
      __html: highlight(children, value),
    }}
  />
);
