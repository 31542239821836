import { Box, Card, Icon, Tab, Tabs, Typography, useBox, WithClickAway, useMedia } from '@partstech/ui';
import { isEqual } from '@partstech/ui/utils';
import { useCallback } from 'react';
import { useGetAddresses } from 'entities/address';
import { VehicleSelectorTab } from '../../constant';
import { isLicensePlate, isVehicle, isVehicleParameters, isVin } from '../../utils';
import { PlateForm } from '../PlateForm';
import { RecentVehicles } from '../RecentVehicles';
import { VehicleTypeahead } from '../VehicleTypeahead';
import { VinForm } from '../VinForm';
import { YearMakeModelForm } from '../YearMakeModelForm';
import { useActiveTab } from './useActiveTab';
import { mobileHeaderHeight, styles } from './VehicleSelector.styles';
import type { VehicleSelectorProps, VehicleSelectorTabs } from '../../types';
import type { Vehicle } from 'models';

const getTabs: (isMobile?: boolean) => VehicleSelectorTabs = (isMobile) => [
  { name: isMobile ? 'y/m/m' : 'year/make/model', id: VehicleSelectorTab.Ymm },
  { name: 'enter vehicle', id: VehicleSelectorTab.Vehicle },
  { name: 'plate', id: VehicleSelectorTab.Plate },
  { name: 'vin', id: VehicleSelectorTab.Vin },
  { name: 'recent vehicles', id: VehicleSelectorTab.RecentVehicles },
];

const getInitialTab = (value: VehicleSelectorProps['value']): VehicleSelectorTab => {
  if (isLicensePlate(value)) {
    return VehicleSelectorTab.Plate;
  }

  if (isVin(value)) {
    return VehicleSelectorTab.Vin;
  }

  return VehicleSelectorTab.Ymm;
};

export const VehicleSelector = ({ isOpened, value, onChange, onClose, ...boxProps }: VehicleSelectorProps) => {
  const { isMobile } = useMedia();

  const { isBillingAddressInUS, billingAddress } = useGetAddresses();

  const { activeTab, setActiveTab } = useActiveTab(getInitialTab(value));

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const handleChange = useCallback(
    (vehicle: Vehicle | null) => {
      if (isVehicle(value) && isEqual(vehicle?.id, value?.id)) {
        return;
      }

      if (onChange) {
        onChange(vehicle);
      }

      if (vehicle !== null) {
        handleClose();
      }
    },
    [handleClose, onChange, value]
  );

  const { boxStyle } = useBox(boxProps);
  const defaultState = isBillingAddressInUS ? billingAddress?.state : '';

  return (
    <Box
      css={styles.container({
        isOpened,
        isYMMForm: activeTab === VehicleSelectorTab.Ymm,
        boxStyle,
      })}
      data-testid="vehicleSelector"
    >
      {isMobile && (
        <Box
          pl={5}
          pt={4}
          pb={5}
          height={mobileHeaderHeight}
          maxHeight={mobileHeaderHeight}
          background="white"
          onClick={onClose}
        >
          <button css={styles.modalCloseButton}>
            <Icon name="chevron_left" color="dark" />
            <Typography variant="subtitle1" color="dark">
              Select Vehicle
            </Typography>
          </button>
        </Box>
      )}
      <WithClickAway
        onClickAway={handleClose}
        config={{ ignoreClassNames: ['selectVehicleButton'], isDisabled: !isOpened }}
      >
        <Card
          display="flex"
          flexDirection="column"
          minWidth={{ sm: undefined, md: 133 }}
          width={{ sm: '100vw', md: 'auto' }}
          p={5}
          elevation={isMobile ? null : 24}
          css={styles.vehicleSelector}
        >
          <Tabs activeTab={activeTab} onChange={setActiveTab}>
            {getTabs(isMobile).map((tab) => (
              <Tab id={tab.id} data-testid="vehicleSelectorTab" key={tab.id}>
                {tab.name.toUpperCase()}
              </Tab>
            ))}
          </Tabs>

          <div css={styles.formWrapper}>
            <YearMakeModelForm
              value={isVehicle(value) ? value : null}
              vehicleParameters={isVehicleParameters(value) ? value : undefined}
              onChange={handleChange}
              isActive={isOpened && activeTab === 'year/make/model'}
              css={styles.visibility(Boolean(isOpened) && activeTab === 'year/make/model')}
            />

            <VehicleTypeahead
              value={isVehicle(value) ? value : null}
              onChange={handleChange}
              isActive={isOpened && activeTab === 'vehicle'}
              css={styles.visibility(Boolean(isOpened) && activeTab === 'vehicle')}
            />

            <PlateForm
              defaultValues={{
                state: isLicensePlate(value) || isVehicle(value) ? (value.state ?? defaultState) : defaultState,
                plate: isLicensePlate(value) || isVehicle(value) ? (value.plate ?? '') : '',
              }}
              onChange={handleChange}
              isActive={isOpened && activeTab === 'plate'}
              isUSCountry={isBillingAddressInUS}
              vehicle={isVehicle(value) ? value : null}
              css={styles.visibility(Boolean(isOpened) && activeTab === 'plate')}
            />

            <VinForm
              defaultValues={isVehicle(value) || isVin(value) ? { vin: value.vin } : undefined}
              onChange={handleChange}
              isActive={isOpened && activeTab === 'vin'}
              css={styles.visibility(Boolean(isOpened) && activeTab === 'vin')}
            />

            <RecentVehicles
              isActive={isOpened && activeTab === 'recent vehicles'}
              onChange={handleChange}
              css={styles.visibility(Boolean(isOpened) && activeTab === 'recent vehicles')}
            />
          </div>
        </Card>
      </WithClickAway>
    </Box>
  );
};
