import { useCallback, useEffect, useState } from 'react';
import { SearchTypes } from 'types/search';

export const useSearchType = (value: SearchTypes | null, onChange?: (type: SearchTypes) => void) => {
  const [searchType, setSearchType] = useState<SearchTypes>(value ?? SearchTypes.PARTS);

  const changeSearchType = useCallback(
    (type: SearchTypes) => {
      setSearchType(type);
      onChange && onChange(type);
    },
    [onChange]
  );

  useEffect(() => {
    if (value) {
      setSearchType(value);
    }
  }, [value]);

  return [searchType, changeSearchType] as const;
};
