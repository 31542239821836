import { entries } from '@partstech/ui/utils';

export const convertFormFieldsToAccountField = (
  fields: Record<string, string> | undefined
): { name: string; value: string }[] => {
  if (!fields) {
    return [];
  }

  return entries(fields).map(([name, value]) => ({ name, value }));
};
