import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useCallback, useEffect, useRef } from 'react';
import { emptyLDClient } from '../constant';

type EventDataMap = {
  'user-created': undefined;
  'test-click': { username: string; shopId: string };
};

export type SendLaunchDarklyEvent = <K extends keyof EventDataMap>(
  eventName: K,
  ...args: EventDataMap[K] extends undefined ? [] : [data: EventDataMap[K]]
) => void;

export const useSendLaunchDarklyEvent = () => {
  const { track } = useLDClient() ?? emptyLDClient;

  const trackFnRef = useRef(track);

  const sendEvent = useCallback<SendLaunchDarklyEvent>((eventName, ...args) => {
    const [data] = args;

    trackFnRef.current?.(eventName, data);
  }, []);

  useEffect(() => {
    trackFnRef.current = track;
  }, [track]);

  return sendEvent;
};
