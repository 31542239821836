import type { RawAttribute } from 'shared/api/rest/gen/shop';

type TireSpeedRatings = {
  [key: string]: {
    'Speed (mph)': string;
  };
};

type TireLoadRanges = {
  [key: string]: {
    psi: number;
    kpa: number;
  };
};

type TireQualityTreadwear = {
  [key: string]: {
    mi: string;
  };
};

type TireQualityTraction = {
  [key: string]: {
    g: string;
  };
};

type TireQualityTemperature = {
  [key: string]: {
    g: string;
  };
};

export const TIRE_SPEED_RATINGS: TireSpeedRatings = {
  A1: {
    'Speed (mph)': '3 mph',
  },
  A2: {
    'Speed (mph)': '6 mph',
  },
  A3: {
    'Speed (mph)': '9 mph',
  },
  A4: {
    'Speed (mph)': '12 mph',
  },
  A5: {
    'Speed (mph)': '16 mph',
  },
  A6: {
    'Speed (mph)': '19 mph',
  },
  A8: {
    'Speed (mph)': '25 mph',
  },
  B: {
    'Speed (mph)': '31 mph',
  },
  C: {
    'Speed (mph)': '37 mph',
  },
  D: {
    'Speed (mph)': '40 mph',
  },
  E: {
    'Speed (mph)': '43 mph',
  },
  F: {
    'Speed (mph)': '50 mph',
  },
  G: {
    'Speed (mph)': '56 mph',
  },
  J: {
    'Speed (mph)': '62 mph',
  },
  K: {
    'Speed (mph)': '68 mph',
  },
  L: {
    'Speed (mph)': '75 mph',
  },
  M: {
    'Speed (mph)': '81 mph',
  },
  N: {
    'Speed (mph)': '87 mph',
  },
  P: {
    'Speed (mph)': '94 mph',
  },
  Q: {
    'Speed (mph)': '100 mph',
  },
  R: {
    'Speed (mph)': '106 mph',
  },
  S: {
    'Speed (mph)': '112 mph',
  },
  T: {
    'Speed (mph)': '118 mph',
  },
  U: {
    'Speed (mph)': '124 mph',
  },
  H: {
    'Speed (mph)': '130 mph',
  },
  V: {
    'Speed (mph)': '149 mph',
  },
  W: {
    'Speed (mph)': '168 mph',
  },
  Y: {
    'Speed (mph)': '186 mph',
  },
  '(Y)': {
    'Speed (mph)': 'Above 186',
  },
  Z: {
    'Speed (mph)': '149+',
  },
};

export const TIRE_LOAD_INDEXES = [
  { 'Load Index': 1, 'Load (lbs)': '102 lbs' },
  { 'Load Index': 2, 'Load (lbs)': '105 lbs' },
  { 'Load Index': 3, 'Load (lbs)': '108 lbs' },
  { 'Load Index': 4, 'Load (lbs)': '111 lbs' },
  { 'Load Index': 5, 'Load (lbs)': '114 lbs' },
  { 'Load Index': 6, 'Load (lbs)': '117 lbs' },
  { 'Load Index': 7, 'Load (lbs)': '121 lbs' },
  { 'Load Index': 8, 'Load (lbs)': '124 lbs' },
  { 'Load Index': 9, 'Load (lbs)': '128 lbs' },
  { 'Load Index': 10, 'Load (lbs)': '133 lbs' },
  { 'Load Index': 11, 'Load (lbs)': '114 lbs' },
  { 'Load Index': 12, 'Load (lbs)': '139 lbs' },
  { 'Load Index': 13, 'Load (lbs)': '144 lbs' },
  { 'Load Index': 14, 'Load (lbs)': '148 lbs' },
  { 'Load Index': 15, 'Load (lbs)': '153 lbs' },
  { 'Load Index': 16, 'Load (lbs)': '157 lbs' },
  { 'Load Index': 17, 'Load (lbs)': '161 lbs' },
  { 'Load Index': 18, 'Load (lbs)': '166 lbs' },
  { 'Load Index': 19, 'Load (lbs)': '171 lbs' },
  { 'Load Index': 20, 'Load (lbs)': '177 lbs' },
  { 'Load Index': 21, 'Load (lbs)': '182 lbs' },
  { 'Load Index': 22, 'Load (lbs)': '188 lbs' },
  { 'Load Index': 23, 'Load (lbs)': '193 lbs' },
  { 'Load Index': 24, 'Load (lbs)': '199 lbs' },
  { 'Load Index': 25, 'Load (lbs)': '204 lbs' },
  { 'Load Index': 26, 'Load (lbs)': '210 lbs' },
  { 'Load Index': 27, 'Load (lbs)': '215 lbs' },
  { 'Load Index': 28, 'Load (lbs)': '221 lbs' },
  { 'Load Index': 29, 'Load (lbs)': '228 lbs' },
  { 'Load Index': 30, 'Load (lbs)': '234 lbs' },
  { 'Load Index': 31, 'Load (lbs)': '241 lbs' },
  { 'Load Index': 32, 'Load (lbs)': '247 lbs' },
  { 'Load Index': 33, 'Load (lbs)': '254 lbs' },
  { 'Load Index': 34, 'Load (lbs)': '261 lbs' },
  { 'Load Index': 35, 'Load (lbs)': '267 lbs' },
  { 'Load Index': 36, 'Load (lbs)': '276 lbs' },
  { 'Load Index': 37, 'Load (lbs)': '283 lbs' },
  { 'Load Index': 38, 'Load (lbs)': '292 lbs' },
  { 'Load Index': 39, 'Load (lbs)': '300 lbs' },
  { 'Load Index': 40, 'Load (lbs)': '309 lbs' },
  { 'Load Index': 41, 'Load (lbs)': '320 lbs' },
  { 'Load Index': 42, 'Load (lbs)': '331 lbs' },
  { 'Load Index': 43, 'Load (lbs)': '342 lbs' },
  { 'Load Index': 44, 'Load (lbs)': '353 lbs' },
  { 'Load Index': 45, 'Load (lbs)': '364 lbs' },
  { 'Load Index': 46, 'Load (lbs)': '375 lbs' },
  { 'Load Index': 47, 'Load (lbs)': '386 lbs' },
  { 'Load Index': 48, 'Load (lbs)': '397 lbs' },
  { 'Load Index': 49, 'Load (lbs)': '408 lbs' },
  { 'Load Index': 50, 'Load (lbs)': '419 lbs' },
  { 'Load Index': 51, 'Load (lbs)': '430 lbs' },
  { 'Load Index': 52, 'Load (lbs)': '441 lbs' },
  { 'Load Index': 53, 'Load (lbs)': '455 lbs' },
  { 'Load Index': 54, 'Load (lbs)': '468 lbs' },
  { 'Load Index': 55, 'Load (lbs)': '481 lbs' },
  { 'Load Index': 56, 'Load (lbs)': '494 lbs' },
  { 'Load Index': 57, 'Load (lbs)': '508 lbs' },
  { 'Load Index': 58, 'Load (lbs)': '521 lbs' },
  { 'Load Index': 59, 'Load (lbs)': '536 lbs' },
  { 'Load Index': 60, 'Load (lbs)': '552 lbs' },
  { 'Load Index': 61, 'Load (lbs)': '567 lbs' },
  { 'Load Index': 62, 'Load (lbs)': '585 lbs' },
  { 'Load Index': 63, 'Load (lbs)': '600 lbs' },
  { 'Load Index': 64, 'Load (lbs)': '618 lbs' },
  { 'Load Index': 65, 'Load (lbs)': '640 lbs' },
  { 'Load Index': 66, 'Load (lbs)': '662 lbs' },
  { 'Load Index': 67, 'Load (lbs)': '677 lbs' },
  { 'Load Index': 68, 'Load (lbs)': '695 lbs' },
  { 'Load Index': 69, 'Load (lbs)': '717 lbs' },
  { 'Load Index': 70, 'Load (lbs)': '739 lbs' },
  { 'Load Index': 71, 'Load (lbs)': '761 lbs' },
  { 'Load Index': 72, 'Load (lbs)': '783 lbs' },
  { 'Load Index': 73, 'Load (lbs)': '805 lbs' },
  { 'Load Index': 74, 'Load (lbs)': '827 lbs' },
  { 'Load Index': 75, 'Load (lbs)': '854 lbs' },
  { 'Load Index': 76, 'Load (lbs)': '882 lbs' },
  { 'Load Index': 77, 'Load (lbs)': '909 lbs' },
  { 'Load Index': 78, 'Load (lbs)': '937 lbs' },
  { 'Load Index': 79, 'Load (lbs)': '964 lbs' },
  { 'Load Index': 80, 'Load (lbs)': '993 lbs' },
  { 'Load Index': 81, 'Load (lbs)': '1019 lbs' },
  { 'Load Index': 82, 'Load (lbs)': '1048 lbs' },
  { 'Load Index': 83, 'Load (lbs)': '1074 lbs' },
  { 'Load Index': 84, 'Load (lbs)': '1103 lbs' },
  { 'Load Index': 85, 'Load (lbs)': '1136 lbs' },
  { 'Load Index': 86, 'Load (lbs)': '1169 lbs' },
  { 'Load Index': 87, 'Load (lbs)': '1202 lbs' },
  { 'Load Index': 88, 'Load (lbs)': '1235 lbs' },
  { 'Load Index': 89, 'Load (lbs)': '1279 lbs' },
  { 'Load Index': 90, 'Load (lbs)': '1323 lbs' },
  { 'Load Index': 91, 'Load (lbs)': '1356 lbs' },
  { 'Load Index': 92, 'Load (lbs)': '1389 lbs' },
  { 'Load Index': 93, 'Load (lbs)': '1433 lbs' },
  { 'Load Index': 94, 'Load (lbs)': '1478 lbs' },
  { 'Load Index': 95, 'Load (lbs)': '1522 lbs' },
  { 'Load Index': 96, 'Load (lbs)': '1566 lbs' },
  { 'Load Index': 97, 'Load (lbs)': '1610 lbs' },
  { 'Load Index': 98, 'Load (lbs)': '1654 lbs' },
  { 'Load Index': 99, 'Load (lbs)': '1709 lbs' },
  { 'Load Index': 100, 'Load (lbs)': '1764 lbs' },
  { 'Load Index': 101, 'Load (lbs)': '1819 lbs' },
  { 'Load Index': 102, 'Load (lbs)': '1874 lbs' },
  { 'Load Index': 103, 'Load (lbs)': '1930 lbs' },
  { 'Load Index': 104, 'Load (lbs)': '1985 lbs' },
  { 'Load Index': 105, 'Load (lbs)': '2040 lbs' },
  { 'Load Index': 106, 'Load (lbs)': '2095 lbs' },
  { 'Load Index': 107, 'Load (lbs)': '2150 lbs' },
  { 'Load Index': 108, 'Load (lbs)': '2205 lbs' },
  { 'Load Index': 109, 'Load (lbs)': '2271 lbs' },
  { 'Load Index': 110, 'Load (lbs)': '2337 lbs' },
  { 'Load Index': 111, 'Load (lbs)': '2403 lbs' },
  { 'Load Index': 112, 'Load (lbs)': '2470 lbs' },
  { 'Load Index': 113, 'Load (lbs)': '2536 lbs' },
  { 'Load Index': 114, 'Load (lbs)': '2602 lbs' },
  { 'Load Index': 115, 'Load (lbs)': '2679 lbs' },
  { 'Load Index': 116, 'Load (lbs)': '2756 lbs' },
  { 'Load Index': 117, 'Load (lbs)': '2833 lbs' },
  { 'Load Index': 118, 'Load (lbs)': '2911 lbs' },
  { 'Load Index': 119, 'Load (lbs)': '2999 lbs' },
  { 'Load Index': 120, 'Load (lbs)': '3087 lbs' },
  { 'Load Index': 121, 'Load (lbs)': '3197 lbs' },
  { 'Load Index': 122, 'Load (lbs)': '3307 lbs' },
  { 'Load Index': 123, 'Load (lbs)': '3418 lbs' },
  { 'Load Index': 124, 'Load (lbs)': '3528 lbs' },
  { 'Load Index': 125, 'Load (lbs)': '3638 lbs' },
  { 'Load Index': 126, 'Load (lbs)': '3748 lbs' },
  { 'Load Index': 127, 'Load (lbs)': '3859 lbs' },
  { 'Load Index': 128, 'Load (lbs)': '3969 lbs' },
  { 'Load Index': 129, 'Load (lbs)': '4079 lbs' },
  { 'Load Index': 130, 'Load (lbs)': '4189 lbs' },
  { 'Load Index': 131, 'Load (lbs)': '4299 lbs' },
  { 'Load Index': 132, 'Load (lbs)': '4410 lbs' },
  { 'Load Index': 133, 'Load (lbs)': '4542 lbs' },
  { 'Load Index': 134, 'Load (lbs)': '4674 lbs' },
  { 'Load Index': 135, 'Load (lbs)': '4806 lbs' },
  { 'Load Index': 136, 'Load (lbs)': '4939 lbs' },
  { 'Load Index': 137, 'Load (lbs)': '5071 lbs' },
  { 'Load Index': 138, 'Load (lbs)': '5203 lbs' },
  { 'Load Index': 139, 'Load (lbs)': '5358 lbs' },
  { 'Load Index': 140, 'Load (lbs)': '5512 lbs' },
  { 'Load Index': 141, 'Load (lbs)': '5677 lbs' },
  { 'Load Index': 142, 'Load (lbs)': '5843 lbs' },
  { 'Load Index': 143, 'Load (lbs)': '6008 lbs' },
  { 'Load Index': 144, 'Load (lbs)': '6173 lbs' },
  { 'Load Index': 145, 'Load (lbs)': '6394 lbs' },
  { 'Load Index': 146, 'Load (lbs)': '6614 lbs' },
  { 'Load Index': 147, 'Load (lbs)': '6780 lbs' },
  { 'Load Index': 148, 'Load (lbs)': '6945 lbs' },
  { 'Load Index': 149, 'Load (lbs)': '7165 lbs' },
  { 'Load Index': 150, 'Load (lbs)': '7386 lbs' },
  { 'Load Index': 151, 'Load (lbs)': '7606 lbs' },
  { 'Load Index': 152, 'Load (lbs)': '7827 lbs' },
  { 'Load Index': 153, 'Load (lbs)': '8047 lbs' },
  { 'Load Index': 154, 'Load (lbs)': '8268 lbs' },
  { 'Load Index': 155, 'Load (lbs)': '8543 lbs' },
  { 'Load Index': 156, 'Load (lbs)': '8819 lbs' },
  { 'Load Index': 157, 'Load (lbs)': '9094 lbs' },
  { 'Load Index': 158, 'Load (lbs)': '9370 lbs' },
  { 'Load Index': 159, 'Load (lbs)': '9646 lbs' },
  { 'Load Index': 160, 'Load (lbs)': '9921 lbs' },
  { 'Load Index': 161, 'Load (lbs)': '10197 lbs' },
  { 'Load Index': 162, 'Load (lbs)': '10472 lbs' },
  { 'Load Index': 163, 'Load (lbs)': '10748 lbs' },
  { 'Load Index': 164, 'Load (lbs)': '11023 lbs' },
  { 'Load Index': 165, 'Load (lbs)': '11354 lbs' },
  { 'Load Index': 166, 'Load (lbs)': '11685 lbs' },
  { 'Load Index': 167, 'Load (lbs)': '12015 lbs' },
  { 'Load Index': 168, 'Load (lbs)': '12346 lbs' },
  { 'Load Index': 169, 'Load (lbs)': '12787 lbs' },
  { 'Load Index': 170, 'Load (lbs)': '13228 lbs' },
  { 'Load Index': 171, 'Load (lbs)': '13559 lbs' },
  { 'Load Index': 172, 'Load (lbs)': '13889 lbs' },
  { 'Load Index': 173, 'Load (lbs)': '14330 lbs' },
  { 'Load Index': 174, 'Load (lbs)': '14771 lbs' },
  { 'Load Index': 175, 'Load (lbs)': '15212 lbs' },
  { 'Load Index': 176, 'Load (lbs)': '15653 lbs' },
  { 'Load Index': 177, 'Load (lbs)': '16094 lbs' },
  { 'Load Index': 178, 'Load (lbs)': '16535 lbs' },
  { 'Load Index': 179, 'Load (lbs)': '17086 lbs' },
  { 'Load Index': 180, 'Load (lbs)': '17637 lbs' },
  { 'Load Index': 181, 'Load (lbs)': '18188 lbs' },
  { 'Load Index': 182, 'Load (lbs)': '18739 lbs' },
  { 'Load Index': 183, 'Load (lbs)': '19291 lbs' },
  { 'Load Index': 184, 'Load (lbs)': '19842 lbs' },
  { 'Load Index': 185, 'Load (lbs)': '20393 lbs' },
  { 'Load Index': 186, 'Load (lbs)': '20944 lbs' },
  { 'Load Index': 187, 'Load (lbs)': '21495 lbs' },
  { 'Load Index': 188, 'Load (lbs)': '22046 lbs' },
  { 'Load Index': 189, 'Load (lbs)': '22708 lbs' },
  { 'Load Index': 190, 'Load (lbs)': '23369 lbs' },
  { 'Load Index': 191, 'Load (lbs)': '24030 lbs' },
  { 'Load Index': 192, 'Load (lbs)': '24692 lbs' },
  { 'Load Index': 193, 'Load (lbs)': '25353 lbs' },
  { 'Load Index': 194, 'Load (lbs)': '26015 lbs' },
  { 'Load Index': 195, 'Load (lbs)': '26786 lbs' },
  { 'Load Index': 196, 'Load (lbs)': '27558 lbs' },
  { 'Load Index': 197, 'Load (lbs)': '28329 lbs' },
  { 'Load Index': 198, 'Load (lbs)': '29101 lbs' },
  { 'Load Index': 199, 'Load (lbs)': '29983 lbs' },
  { 'Load Index': 200, 'Load (lbs)': '30865 lbs' },
  { 'Load Index': 201, 'Load (lbs)': '31967 lbs' },
  { 'Load Index': 202, 'Load (lbs)': '33069 lbs' },
  { 'Load Index': 203, 'Load (lbs)': '34172 lbs' },
  { 'Load Index': 204, 'Load (lbs)': '35274 lbs' },
  { 'Load Index': 205, 'Load (lbs)': '36376 lbs' },
  { 'Load Index': 206, 'Load (lbs)': '37478 lbs' },
  { 'Load Index': 207, 'Load (lbs)': '38581 lbs' },
  { 'Load Index': 208, 'Load (lbs)': '39683 lbs' },
  { 'Load Index': 209, 'Load (lbs)': '40785 lbs' },
  { 'Load Index': 210, 'Load (lbs)': '41888 lbs' },
  { 'Load Index': 211, 'Load (lbs)': '42990 lbs' },
  { 'Load Index': 212, 'Load (lbs)': '44092 lbs' },
  { 'Load Index': 213, 'Load (lbs)': '45415 lbs' },
  { 'Load Index': 214, 'Load (lbs)': '46738 lbs' },
  { 'Load Index': 215, 'Load (lbs)': '48060 lbs' },
  { 'Load Index': 216, 'Load (lbs)': '49383 lbs' },
  { 'Load Index': 217, 'Load (lbs)': '50706 lbs' },
  { 'Load Index': 218, 'Load (lbs)': '52029 lbs' },
  { 'Load Index': 219, 'Load (lbs)': '53572 lbs' },
  { 'Load Index': 220, 'Load (lbs)': '55115 lbs' },
  { 'Load Index': 221, 'Load (lbs)': '56769 lbs' },
  { 'Load Index': 222, 'Load (lbs)': '58422 lbs' },
  { 'Load Index': 223, 'Load (lbs)': '60075 lbs' },
  { 'Load Index': 224, 'Load (lbs)': '61729 lbs' },
  { 'Load Index': 225, 'Load (lbs)': '63933 lbs' },
  { 'Load Index': 226, 'Load (lbs)': '66138 lbs' },
  { 'Load Index': 227, 'Load (lbs)': '67792 lbs' },
  { 'Load Index': 228, 'Load (lbs)': '69445 lbs' },
  { 'Load Index': 229, 'Load (lbs)': '71650 lbs' },
  { 'Load Index': 230, 'Load (lbs)': '73854 lbs' },
  { 'Load Index': 231, 'Load (lbs)': '76059 lbs' },
  { 'Load Index': 232, 'Load (lbs)': '78263 lbs' },
  { 'Load Index': 233, 'Load (lbs)': '80468 lbs' },
  { 'Load Index': 234, 'Load (lbs)': '82672 lbs' },
  { 'Load Index': 235, 'Load (lbs)': '85428 lbs' },
  { 'Load Index': 236, 'Load (lbs)': '88184 lbs' },
  { 'Load Index': 237, 'Load (lbs)': '90940 lbs' },
  { 'Load Index': 238, 'Load (lbs)': '93695 lbs' },
  { 'Load Index': 239, 'Load (lbs)': '96451 lbs' },
  { 'Load Index': 240, 'Load (lbs)': '99207 lbs' },
  { 'Load Index': 241, 'Load (lbs)': '101963 lbs' },
  { 'Load Index': 242, 'Load (lbs)': '104718 lbs' },
  { 'Load Index': 243, 'Load (lbs)': '107474 lbs' },
  { 'Load Index': 244, 'Load (lbs)': '110230 lbs' },
  { 'Load Index': 245, 'Load (lbs)': '113537 lbs' },
  { 'Load Index': 246, 'Load (lbs)': '116844 lbs' },
  { 'Load Index': 247, 'Load (lbs)': '120150 lbs' },
  { 'Load Index': 248, 'Load (lbs)': '123457 lbs' },
  { 'Load Index': 249, 'Load (lbs)': '127866 lbs' },
  { 'Load Index': 250, 'Load (lbs)': '132276 lbs' },
  { 'Load Index': 251, 'Load (lbs)': '135583 lbs' },
  { 'Load Index': 252, 'Load (lbs)': '138889 lbs' },
  { 'Load Index': 253, 'Load (lbs)': '143299 lbs' },
  { 'Load Index': 254, 'Load (lbs)': '147708 lbs' },
  { 'Load Index': 255, 'Load (lbs)': '152117 lbs' },
  { 'Load Index': 256, 'Load (lbs)': '156526 lbs' },
  { 'Load Index': 257, 'Load (lbs)': '160935 lbs' },
  { 'Load Index': 258, 'Load (lbs)': '165344 lbs' },
  { 'Load Index': 259, 'Load (lbs)': '170856 lbs' },
  { 'Load Index': 260, 'Load (lbs)': '176367 lbs' },
  { 'Load Index': 261, 'Load (lbs)': '181879 lbs' },
  { 'Load Index': 262, 'Load (lbs)': '187390 lbs' },
  { 'Load Index': 263, 'Load (lbs)': '192902 lbs' },
  { 'Load Index': 264, 'Load (lbs)': '198413 lbs' },
  { 'Load Index': 265, 'Load (lbs)': '203925 lbs' },
  { 'Load Index': 266, 'Load (lbs)': '209436 lbs' },
  { 'Load Index': 267, 'Load (lbs)': '214948 lbs' },
  { 'Load Index': 268, 'Load (lbs)': '22046 lbs' },
  { 'Load Index': 269, 'Load (lbs)': '227073 lbs' },
  { 'Load Index': 270, 'Load (lbs)': '233687 lbs' },
  { 'Load Index': 271, 'Load (lbs)': '240300 lbs' },
  { 'Load Index': 272, 'Load (lbs)': '246914 lbs' },
  { 'Load Index': 273, 'Load (lbs)': '253528 lbs' },
  { 'Load Index': 274, 'Load (lbs)': '260142 lbs' },
  { 'Load Index': 275, 'Load (lbs)': '266755 lbs' },
  { 'Load Index': 276, 'Load (lbs)': '275574 lbs' },
  { 'Load Index': 277, 'Load (lbs)': '283290 lbs' },
  { 'Load Index': 278, 'Load (lbs)': '291006 lbs' },
  { 'Load Index': 279, 'Load (lbs)': '299824 lbs' },
];

export const TIRE_LOAD_RANGES: TireLoadRanges = {
  LL: {
    psi: 36,
    kpa: 250,
  },
  SL: {
    psi: 36,
    kpa: 250,
  },
  RF: {
    psi: 42,
    kpa: 290,
  },
  XL: {
    psi: 42,
    kpa: 290,
  },
  HL: {
    psi: 42,
    kpa: 282.74,
  },
  B: {
    psi: 35,
    kpa: 240,
  },
  C: {
    psi: 50,
    kpa: 350,
  },
  C1: {
    psi: 50,
    kpa: 282.74,
  },
  C2: {
    psi: 35,
    kpa: 240,
  },
  D: {
    psi: 65,
    kpa: 450,
  },
  D1: {
    psi: 65,
    kpa: 450,
  },
  E: {
    psi: 80,
    kpa: 550,
  },
  E1: {
    psi: 80,
    kpa: 550,
  },
  E2: {
    psi: 65,
    kpa: 450,
  },
  D2: {
    psi: 50,
    kpa: 350,
  },
  F: {
    psi: 95,
    kpa: 650,
  },
  F1: {
    psi: 95,
    kpa: 650,
  },
  G: {
    psi: 110,
    kpa: 750,
  },
};

export const TIRE_QUALITY_TREADWEAR: TireQualityTreadwear = {
  800: {
    mi: '> 70,000 mi',
  },
  700: {
    mi: '60,000 - 70,000 mi',
  },
  600: {
    mi: '50,000 - 60,000 mi',
  },
  500: {
    mi: '40,000 - 50,000 mi',
  },
  400: {
    mi: '30,000 - 40,000 mi',
  },
  300: {
    mi: '20,000 - 30,000 mi',
  },
  200: {
    mi: '< 20,000 mi',
  },
  100: {
    mi: '< 20,000 mi',
  },
  0: {
    mi: '< 20,000 mi',
  },
};

export const TIRE_QUALITY_TRACTION: TireQualityTraction = {
  AA: {
    g: '>0.54 G',
  },
  A: {
    g: '>0.47 G',
  },
  B: {
    g: '>0.38 G',
  },
  C: {
    g: 'Less than 0.38 G',
  },
};

export const TIRE_QUALITY_TEMPERATURE: TireQualityTemperature = {
  A: {
    g: '>115 mph',
  },
  B: {
    g: '100 - 115 mph',
  },
  C: {
    g: '85 - 100 mph',
  },
};

export const TIRES_SPECIFICATION_ATTRIBUTES: RawAttribute['name'][] = [
  'TiresSize',
  'TiresRunFlat',
  'TiresCommercialPosition',
  'TiresApplication',
  'TiresCategoryLabel',
  'TiresSideWallStyle',
  'TiresMileageRating',
  'TireTreadDepth',
  'TireWinterStud',
  'TireSpecialNote',
];

export const CONDENSED_TIRES_SPECIFICATION_ATTRIBUTES: RawAttribute['name'][] = [
  'TiresSize',
  'TiresMileageRating',
  'TiresCategoryLabel',
];

export const TIRES_SERVICE_DESCRIPTION_ATTRIBUTES: RawAttribute['name'][] = [
  'TiresLoadIndex',
  'TiresSpeedRating',
  'TiresLoadRange',
];
