import { useMedia } from '@partstech/ui';
import { useResize } from '@partstech/ui/hooks';
import { useCallback, useMemo, useRef } from 'react';
import { useChangeActiveShopMembership, useChangeActiveSupplierMembership } from 'hooks/memberships';
import { useInputValue } from 'shared/lib/form';
import type { ShopMembership, SupplierMembership } from 'models';

export const positionTopMenu = 36;

const LOCATIONS_VISIBLE_ITEMS_LIMIT = 10;

type Args = {
  shopMemberships: ShopMembership[];
  supplierMemberships: SupplierMembership[];
};

export const useMembershipsMenu = ({ shopMemberships, supplierMemberships }: Args) => {
  const { isMobile } = useMedia();

  const [_, windowHeight] = useResize(true);

  const searchFieldRef = useRef<HTMLDivElement | null>(null);

  const [changeActiveShopMembership] = useChangeActiveShopMembership();
  const [changeActiveSupplierMembership] = useChangeActiveSupplierMembership();

  const { value, changeValue } = useInputValue('');
  const lowerSearchValue = value.toLowerCase();

  const showSearchField =
    shopMemberships.length > LOCATIONS_VISIBLE_ITEMS_LIMIT ||
    supplierMemberships.length > LOCATIONS_VISIBLE_ITEMS_LIMIT;

  const shopMembershipsBySearch = useMemo(() => {
    if (lowerSearchValue) {
      return shopMemberships.filter((shopLocation) =>
        shopLocation.shop?.name?.toLowerCase().includes(lowerSearchValue)
      );
    }

    return shopMemberships;
  }, [lowerSearchValue, shopMemberships]);

  const supplierMembershipsBySearch = useMemo(() => {
    if (lowerSearchValue) {
      return supplierMemberships.filter((supplierMember) =>
        supplierMember.supplier?.name?.toLowerCase().includes(lowerSearchValue)
      );
    }

    return supplierMemberships;
  }, [lowerSearchValue, supplierMemberships]);

  const selectShopLocation = useCallback(
    (id: string) => () => {
      changeActiveShopMembership(id);
    },
    [changeActiveShopMembership]
  );

  const selectSupplierMember = useCallback(
    (id: string) => () => {
      changeActiveSupplierMembership(id);
    },
    [changeActiveSupplierMembership]
  );

  const searchFieldHeight = searchFieldRef.current?.clientHeight || 0;
  const marginTop = isMobile ? 0 : positionTopMenu * 4;
  const menuMaxHeight = (windowHeight ?? 0) - (searchFieldHeight + marginTop);

  return {
    value,
    changeValue,
    searchFieldRef,
    showSearchField,
    shopMembershipsBySearch,
    supplierMembershipsBySearch,
    selectShopLocation,
    selectSupplierMember,
    menuMaxHeight,
  };
};
