import { css } from '@emotion/react';
import { Typography, Card, Box } from '@partstech/ui';
import googleIcon from 'images/icons/google-icon.svg';
import type { Theme } from '@partstech/ui';
import type { PropsWithChildren } from 'react';

const styles = {
  icon: css`
    background-image: url(${googleIcon});
    background-repeat: no-repeat;
  `,

  button: (theme: Theme) => css`
    height: ${theme.sizing(10)};
    padding: ${theme.sizing(0, 7)};
    width: 100%;
    cursor: pointer;
  `,
};

type Props = PropsWithChildren<{
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isLoading?: boolean;
}>;

export const GoogleButton = ({ children, onClick, isLoading }: Props) => (
  <Box position="relative" data-testid="googleButton">
    <Card elevation={1} radius={0}>
      <Box m="auto" width={6} height={6} position="absolute" left={3} top={0} bottom={0} css={styles.icon} />

      <button css={styles.button} onClick={onClick} type="button" disabled={isLoading}>
        <Typography color="subtleText" component="span" variant="subtitle2">
          {children}
        </Typography>
      </button>
    </Card>
  </Box>
);
