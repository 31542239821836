import { useCallback } from 'react';
import { useAppDispatch } from 'store';
import { showForbiddenDialog } from 'store/features/user/account';
import { usePermissions } from 'store/queries/currentUser/usePermissions';
import { PaidFeatureAlias } from '../../constants/features';
import { useLearnAboutFeatureModal } from '../../hooks/useLearnAboutFeatureModal';
import { UpgradeSubscriptionButton } from '../UpgradeSubscriptionButton';
import type { UpgradeSubscriptionButtonProps } from '../UpgradeSubscriptionButton';
import type { ButtonProps } from '@partstech/ui';

type Props = {
  onClick?: () => void;
  showIconWithText?: boolean;
  componentType?: UpgradeSubscriptionButtonProps['componentType'];
} & ButtonProps;

export const UpgradeTireSubscriptionButton = ({ showIconWithText = true, ...props }: Props) => {
  const dispatch = useAppDispatch();

  const {
    member: { suppliersAllowed },
  } = usePermissions();

  const { open: openTireUpgradeSubscriptionModal } = useLearnAboutFeatureModal({
    feature: PaidFeatureAlias.Tires,
    size: 'dialog',
  });

  const handleIncompleteTireSubscriptionClick = useCallback(() => {
    if (!suppliersAllowed) {
      dispatch(showForbiddenDialog());
      return;
    }

    openTireUpgradeSubscriptionModal();
  }, [dispatch, openTireUpgradeSubscriptionModal, suppliersAllowed]);

  return (
    <UpgradeSubscriptionButton
      onClick={handleIncompleteTireSubscriptionClick}
      isNavigated={false}
      data-testid="upgradeButton"
      {...props}
    />
  );
};
