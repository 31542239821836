import { SkeletonLoader } from '@partstech/ui';
import { memo } from 'react';
import { CartBarSkeleton } from '../CartBarSkeleton';
import type { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{ isVisible: boolean }>;

export const CartBarLoader = memo(({ isVisible, children }: Props) => (
  <SkeletonLoader isVisible={isVisible} count={3} loader={<CartBarSkeleton />}>
    {children}
  </SkeletonLoader>
));
