/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import { CardFragmentDoc } from '../../../../shared/api/graphql/fragment/Card.generated';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type UpdatePaymentCardMutationVariables = Types.Exact<{
  input: Types.UpdateShopCardInput;
}>;

export type UpdatePaymentCardMutation = {
  __typename?: 'Mutation';
  updateShopCard?: {
    __typename?: 'UpdateShopCardPayload';
    card?: {
      __typename?: 'Card';
      brand: Types.Scalars['String']['input'];
      expMonth: number;
      expYear: number;
      name: Types.Scalars['String']['input'];
      owner?: Types.Scalars['String']['input'] | null;
      id: string;
    } | null;
  } | null;
};

export const UpdatePaymentCardDocument = `
    mutation UpdatePaymentCard($input: UpdateShopCardInput!) {
  updateShopCard(input: $input) {
    card {
      ...Card
    }
  }
}
    ${CardFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    UpdatePaymentCard: build.mutation<UpdatePaymentCardMutation, UpdatePaymentCardMutationVariables>({
      query: (variables) => ({ document: UpdatePaymentCardDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUpdatePaymentCardMutation } = injectedRtkApi;
