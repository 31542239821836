import { DialogModal } from '@partstech/ui';
import { useCreateModal } from '@partstech/ui/hooks';
import { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { closeForbiddenDialog, selectIsForbiddenDialogOpen } from 'store/features/user/account';

export const useForbiddenDialog = () => {
  const dispatch = useAppDispatch();

  const isOpened = useAppSelector(selectIsForbiddenDialogOpen);

  const handleClose = useCallback(() => {
    dispatch(closeForbiddenDialog());
  }, [dispatch]);

  const { open, opened } = useCreateModal(
    DialogModal,
    {
      children: <>You don’t currently have permission to take that action.</>,
      primaryText: 'Okay',
      onClose: handleClose,
    },
    { customId: 'forbiddenDialog' }
  );

  useEffect(() => {
    if (isOpened && !opened) {
      open();
    }
  }, [isOpened, open, opened]);
};
