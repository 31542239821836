import { useMemo } from 'react';
import { VehicleName } from './VehicleName';
import type { Vehicle } from 'models';

type Props = {
  grouped?: boolean;
  vehicles: Vehicle[];
  multiline?: boolean;
  hasFlag?: boolean;
  hasVin?: boolean;
  size?: 'dense' | 'default';
};

export const VehicleString = ({
  vehicles,
  grouped = true,
  multiline = false,
  hasFlag = true,
  hasVin = false,
  size,
}: Props) => {
  const commonProps = useMemo(() => ({ multiline, hasFlag, hasVin, size }), [hasFlag, hasVin, multiline, size]);

  if (vehicles.length === 0) {
    return null;
  }

  return grouped ? (
    <VehicleName
      vehicle={vehicles.length > 1 ? `for ${vehicles.length} vehicles` : (vehicles[0] ?? '')}
      {...commonProps}
    />
  ) : (
    <>
      {vehicles.map((vehicle) => (
        <VehicleName key={vehicle.id} vehicle={vehicle} {...commonProps} />
      ))}
    </>
  );
};
