import { useModalHistory } from '@partstech/ui';
import { useCallback, useEffect } from 'react';
import { useMutationStatus } from 'shared/api';
import { useDeleteSupplierAccountMutation } from 'store/mutations/currentUser/supplierAccounts/deleteAccount';
import { DeleteCredentials } from './DeleteCredentials';
import { DeletePaidCredentials } from './DeletePaidCredentials';
import { StopProcessing } from './StopProcessing';
import type { CommonAccountFlowPaths } from '../../../types';

export const Delete = () => {
  const {
    state: modalState,
    onClose,
    goBack,
    hasGoBack,
    push,
    setTitle,
  } = useModalHistory<CommonAccountFlowPaths, 'delete'>();

  const { account = null, currentPeriodEnd, isRestrictedEX } = modalState ?? {};

  const [deleteAccount, status] = useDeleteSupplierAccountMutation();

  useMutationStatus({ status, onSuccess: onClose });

  const showDeletePaidAccountForm = account?.isApproved && account?.supplier?.isPaid && isRestrictedEX;

  const handleRemoveAccount = useCallback(() => {
    if (!account?.id) {
      return;
    }

    deleteAccount({ input: { accountId: account.id } });
  }, [account?.id, deleteAccount]);

  const handleEditClick = useCallback(() => {
    if (hasGoBack) {
      goBack();
      return;
    }

    if (!account || !account.store) {
      return;
    }

    push('edit', { account, selectedStore: account.store });
  }, [goBack, hasGoBack, account, push]);

  useEffect(() => {
    if (showDeletePaidAccountForm) {
      setTitle(`Remove ${account.supplier?.name}`);
      return;
    }

    setTitle('Delete supplier');
  }, [account?.supplier?.name, setTitle, showDeletePaidAccountForm]);

  if (!account) {
    return null;
  }

  if (showDeletePaidAccountForm) {
    return (
      <DeletePaidCredentials
        account={account}
        currentPeriodEnd={currentPeriodEnd}
        isSubmitting={status.isLoading}
        onSubmit={handleRemoveAccount}
        onCancel={hasGoBack ? goBack : onClose}
      />
    );
  }

  if (account.isNotApproved) {
    return <StopProcessing account={account} onSubmit={handleRemoveAccount} onCancel={handleEditClick} />;
  }

  return <DeleteCredentials account={account} onSubmit={handleRemoveAccount} onCancel={hasGoBack ? goBack : onClose} />;
};
