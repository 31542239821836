import { Country } from 'shared/api';

export enum CountryName {
  CA = 'Canada',
  US = 'United States',
  MX = 'Mexico',
}

export const COUNTRIES: Array<{ value: Country; text: CountryName }> = [
  { value: Country.Us, text: CountryName.US },
  { value: Country.Ca, text: CountryName.CA },
  { value: Country.Mx, text: CountryName.MX },
];
