import { Box, ImageFallback, Typography } from '@partstech/ui';
import preferredBrandsGif from 'images/content/my-shop/preview-preferred-brands.gif';

export const PreferredBrands = () => (
  <>
    <Box width="100%" px={{ sm: 0, md: 11 }} mb={6}>
      <ImageFallback src={preferredBrandsGif} width="100%" alt="Learn about preferred brands" />
    </Box>

    <Box textAlign="center">
      <Typography>Preferred Brands can be unlocked with a PartsTech Complete subscription.</Typography>

      <Box my={2}>
        <Typography>
          Upgrading and enabling preferred Brands will pin the brands you purchase most often at the top of search
          results.
        </Typography>
      </Box>

      <Typography>
        In addition to Preferred brands, PartsTech Complete includes various features to save you time and super-charge
        your shop’s performance.
      </Typography>
    </Box>
  </>
);
