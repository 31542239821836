import laborLogo from 'images/content/subscriptions/labor-logo.svg';
import maintenanceScheduleLogo from 'images/content/subscriptions/maintenance-schedule-logo.svg';
import tiresLogo from 'images/content/subscriptions/tires-logo.svg';
import vehicleSpecsLogo from 'images/content/subscriptions/vehicle-specs-logo.svg';
import { ProductAlias } from './products';
import type { AddOn } from '../types';

const LABOR: AddOn = {
  alias: ProductAlias.Labor,
  description: 'Super charge your quoting workflow with Labor.',
  id: '',
  isExpires: false,
  isActive: false,
  isYearly: false,
  logo: laborLogo,
  name: 'Labor',
  price: 60,
  priceMonthly: 60,
  priceYearly: 648,
  url: '/',
};

const MAINTENANCE_SCHEDULES: AddOn = {
  alias: ProductAlias.Maintenance,
  description: 'Keep your customers vehicle running smooth.',
  id: '',
  isExpires: false,
  isActive: false,
  isYearly: false,
  logo: maintenanceScheduleLogo,
  name: 'Maintenance Schedules',
  price: 45,
  priceMonthly: 45,
  priceYearly: 486,
  url: '/',
};

const VEHICLE_SPECS: AddOn = {
  alias: ProductAlias.VehicleSpecs,
  description: 'Make the right decisions with vehicle & fluid specs.',
  id: '',
  isExpires: false,
  isActive: false,
  isYearly: false,
  logo: vehicleSpecsLogo,
  name: 'Vehicle Specs',
  price: 45,
  priceMonthly: 45,
  priceYearly: 486,
  url: '/',
};

const TIRES: AddOn = {
  alias: ProductAlias.Tires,
  description: 'Unlock additional tire suppliers that service your shop.',
  id: '',
  isExpires: false,
  isActive: false,
  isYearly: false,
  logo: tiresLogo,
  name: 'Tires',
  price: 15,
  priceMonthly: 15,
  priceYearly: 180,
  url: '/',
};

export const ADDONS = [LABOR, MAINTENANCE_SCHEDULES, VEHICLE_SPECS, TIRES];
