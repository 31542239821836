import { Box, Skeleton } from '@partstech/ui';

export const ContentLoader = () => (
  <Box display="flex" flexDirection="column" alignItems="center" gap={2} data-testid="contentLoader">
    <Skeleton height="20" width="60%" />
    <Skeleton height="14" width="40%" />
    <Skeleton height="16" width="50%" />
    <Skeleton height="14" width="40%" />
    <Skeleton height="18" width="70%" />
  </Box>
);
