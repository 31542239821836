import { createHistoryModalRouter, HistoryModalProvider } from '@partstech/ui';
import { Edit } from './Edit';
import { Info } from './Info';
import type { ProfileModalPaths } from 'types/profileModal';

const router = createHistoryModalRouter<ProfileModalPaths, undefined>([
  {
    path: 'info',
    title: 'My profile',
    createActions: ({ push }) => [
      {
        grouped: false,
        icon: 'edit',
        title: 'Edit my profile',
        onClick: () => {
          push('edit');
        },
        variant: 'icon',
      },
    ],
    element: <Info />,
  },
  {
    path: 'edit',
    title: 'Edit my profile',
    element: <Edit />,
  },
]);

export const ProfileModal = () => <HistoryModalProvider router={router} initialRoute="info" initialData={undefined} />;
