import { useCreateModal } from '@partstech/ui/hooks';
import { useCallback } from 'react';
import { ClearConfirmDialog } from 'pages/SearchResults/TiresCompare/ClearConfirmDialog';
import { useAppDispatch } from 'store';
import { resetQuote } from 'store/features/quote';
import { useComparedQuoteAlertDismissed } from '../useComparedQuoteAlertDismissed';
import { useComparedQuoteCreating } from '../useComparedQuoteCreating';

export const useClearComparedQuote = () => {
  const dispatch = useAppDispatch();

  const { setQuoteCreating } = useComparedQuoteCreating();

  const { setQuoteAlertDismissed } = useComparedQuoteAlertDismissed();

  const { open: openConfirmDialog, close: closeConfirmDialog } = useCreateModal(
    ClearConfirmDialog,
    {
      onYes: () => {},
    },
    { customId: 'clearConfirmDialog' }
  );

  const handleConfirm = useCallback(() => {
    closeConfirmDialog();

    dispatch(resetQuote());
    setQuoteCreating(undefined);
    setQuoteAlertDismissed(false);
  }, [closeConfirmDialog, dispatch, setQuoteAlertDismissed, setQuoteCreating]);

  const clear = useCallback(() => {
    openConfirmDialog({ onYes: handleConfirm });
  }, [handleConfirm, openConfirmDialog]);

  return { clear };
};
