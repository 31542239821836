/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type CheckUserAlreadyExistsMutationVariables = Types.Exact<{
  input: Types.UserAlreadyExistsInput;
}>;

export type CheckUserAlreadyExistsMutation = {
  __typename?: 'Mutation';
  userAlreadyExists?: { __typename?: 'UserAlreadyExistsPayload'; exists: Types.Scalars['Boolean']['input'] } | null;
};

export const CheckUserAlreadyExistsDocument = `
    mutation CheckUserAlreadyExists($input: UserAlreadyExistsInput!) {
  userAlreadyExists(input: $input) {
    exists
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    CheckUserAlreadyExists: build.mutation<CheckUserAlreadyExistsMutation, CheckUserAlreadyExistsMutationVariables>({
      query: (variables) => ({ document: CheckUserAlreadyExistsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useCheckUserAlreadyExistsMutation } = injectedRtkApi;
