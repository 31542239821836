import { isNotNull } from '@partstech/ui/utils';
import { useMemo } from 'react';
import { useBrandOptions } from 'hooks/preferredBrands';
import { useSearchParams } from 'hooks/search';
import { useSearchResults } from 'pages/SearchResults/SearchResultsContext';
import { DefaultFilter } from '../DefaultFilter';
import { BrandOptions } from './BrandOptions';
import type { FilterProps } from '../DefaultFilter/types';
import type { FilterOption } from 'types/search';

export const BrandFilter = (props: FilterProps) => {
  const { isTiresSearch } = useSearchParams();

  const { products } = useSearchResults();

  const { preferredBrandOptions, regularBrandOptions } = useBrandOptions({
    products,
    type: isTiresSearch ? 'Tire' : 'Part',
  });

  const optionsMap = useMemo(
    () => props.options.reduce<Record<string, FilterOption>>((acc, option) => ({ ...acc, [option.name]: option }), {}),
    [props.options]
  );

  const options = useMemo(
    () => [...preferredBrandOptions, ...regularBrandOptions].map((brand) => optionsMap[brand.name]).filter(isNotNull),
    [optionsMap, preferredBrandOptions, regularBrandOptions]
  );

  return <DefaultFilter {...props} options={options} OptionsComponent={BrandOptions} />;
};
