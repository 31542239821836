import { Typography, Box } from '@partstech/ui';

export const BadCredentials = () => (
  <>
    <Box mb={2}>
      <Typography variant="subtitle1" color="negative">
        We couldn’t log you in
      </Typography>
    </Box>

    <Typography variant="bodyText2" color="negative">
      Enter the correct email address and password to log in.
    </Typography>
  </>
);
