import { Box, Typography } from '@partstech/ui';
import { useCreateShopContext } from 'components/Layout/SignUpLayout/CreateShopContext/CreateShopContext';
import { hearAboutUsOptions, QuestionOptions, StepTitle, useStepProgress } from 'features/signUp';
import { OnboardStepType } from 'shared/api';

export const HearAboutUs = () => {
  const { navigateToNextStep } = useCreateShopContext();

  const { defaultOption, selectOption, skipStep } = useStepProgress({
    step: OnboardStepType.Referral,
    onSuccess: navigateToNextStep,
  });

  return (
    <Box p={2} textAlign={{ sm: 'center', md: 'left' }} width={{ sm: 78, md: '100%' }}>
      <Typography variant="subtitle2" color="subtleText">
        Wrapping up ...
      </Typography>

      <StepTitle mt={4} mb={2}>
        How did you hear about us?
      </StepTitle>

      <QuestionOptions
        defaultOption={defaultOption}
        options={hearAboutUsOptions}
        gap={{ sm: 4, md: 3 }}
        optionMinWidth={{ sm: undefined, md: 6 }}
        onSelect={selectOption}
        onSkip={skipStep}
        justifyContent={{ sm: 'center', md: 'flex-start' }}
        width={{ md: 122 }}
      />
    </Box>
  );
};
