import { PartSubCategory } from '../models/PartSubCategory';
import type { PartType } from 'models';
import type { GetCategoryQuery } from 'store/queries/categories/GetCategory.generated';
import type { ArrayItemType } from 'types/general';

export const createSubCategoryFromQuery = (
  subCategory: NonNullable<ArrayItemType<ArrayItemType<NonNullable<GetCategoryQuery['category']>>['subCategories']>>,
  categoryId?: string,
  partTypes?: PartType[]
) => new PartSubCategory(subCategory.id, subCategory.name, categoryId, partTypes);
