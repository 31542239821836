import { useMemo } from 'react';
import { createVehicleFromQuery } from 'factories';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import { api as generatedApi } from './GetVehicleById.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    GetVehicleById: {
      providesTags: ['VehicleById'],
    },
  },
});

const { useGetVehicleByIdQuery } = api;

export const useGetVehicleById = (id: string | null, skip: boolean = false) => {
  const { hasCurrentUser } = useGetCurrentUser();

  const { currentData, ...props } = useGetVehicleByIdQuery(
    { id: String(id) },
    { skip: skip || !id || !hasCurrentUser }
  );

  const vehicle = useMemo(() => createVehicleFromQuery(currentData?.vehicle), [currentData?.vehicle]);

  return {
    vehicle,
    ...props,
  };
};
