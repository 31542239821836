/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import { MatrixFragmentDoc } from '../../../../../queries/currentUser/retailPricing/matrix/Matrix.generated';
import type * as Types from '../../../../../../shared/api/graphql/graphql';

export type UpdateMatrixMutationVariables = Types.Exact<{
  input: Types.UpdateMatrixInput;
}>;

export type UpdateMatrixMutation = {
  __typename?: 'Mutation';
  updateMatrix?: {
    __typename?: 'UpdateMatrixPayload';
    matrix?: {
      __typename?: 'Matrix';
      createdAt: Types.Scalars['DateTime']['input'];
      id: string;
      name: Types.Scalars['String']['input'];
      type: Types.MatrixType;
      brands?: Array<{ __typename?: 'Brand'; id: string; name: Types.Scalars['String']['input'] }> | null;
      items: Array<{
        __typename?: 'MatrixItem';
        endPrice: Types.Scalars['BigDecimal']['input'];
        price: Types.Scalars['BigDecimal']['input'];
        startPrice: Types.Scalars['BigDecimal']['input'];
        fixed: Types.Scalars['Boolean']['input'];
      }>;
      subCategories?: Array<{
        __typename?: 'MatrixSubCategory';
        subCategory: { __typename?: 'PartSubCategory'; id: string; name: Types.Scalars['String']['input'] };
        partTypes: Array<{
          __typename?: 'PartType';
          id: string;
          application: Types.PartTypeApplication;
          name: Types.Scalars['String']['input'];
        }>;
      }> | null;
    } | null;
  } | null;
};

export const UpdateMatrixDocument = `
    mutation UpdateMatrix($input: UpdateMatrixInput!) {
  updateMatrix(input: $input) {
    matrix {
      ...Matrix
    }
  }
}
    ${MatrixFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    UpdateMatrix: build.mutation<UpdateMatrixMutation, UpdateMatrixMutationVariables>({
      query: (variables) => ({ document: UpdateMatrixDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUpdateMatrixMutation } = injectedRtkApi;
