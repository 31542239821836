import { css } from '@emotion/react';
import { Box, Divider } from '@partstech/ui';
import { useCallback } from 'react';
import { useReactivatePlan } from '../../../api/mutations/reactivatePlan';
import { ProductAlias } from '../../../constants/products';
import { useDowngradeModal } from '../../../hooks/useDowngradeModal';
import { useSubscriptionEX } from '../../../hooks/useSubscriptionEX';
import { PlanContent } from '../../Plans/PlanContent';
import { ControlButton } from './ControlButton';
import { PlanForCompareStatus } from './PlanForCompareStatus';
import type { Plan } from '../../../types';
import type { Theme } from '@partstech/ui';

const styles = {
  wrapper: (theme: Theme) => css`
    position: relative;

    .ui-status {
      top: ${theme.sizing(2)};
      left: 0;
      border-radius: ${theme.sizing(0, 0, 2, 0)};
    }
  `,
};

type Props = {
  isLoading: boolean;
  plan: Plan;
  onControlButtonClick: (alias: ProductAlias) => void;
};

export const PlanForCompare = ({ isLoading, plan, onControlButtonClick }: Props) => {
  const { alias, canDowngrade, color, isActive, isExpires, isDowngrading } = plan;

  const [reactivatePlan, status] = useReactivatePlan();

  const { openDowngradeModal } = useDowngradeModal({ isPlan: true, product: plan });

  const { isRestrictedEX } = useSubscriptionEX();

  const handleControlButtonClick = useCallback(async () => {
    if (canDowngrade) {
      openDowngradeModal();
      return;
    }

    if (isExpires) {
      await reactivatePlan();
      return;
    }

    onControlButtonClick(alias);
  }, [alias, canDowngrade, isExpires, openDowngradeModal, reactivatePlan, onControlButtonClick]);

  return (
    <Box className="plan-column" css={styles.wrapper} textAlign="center" data-testid="planForCompare">
      {!isRestrictedEX && <PlanForCompareStatus alias={alias} />}

      <Box className="background" background={color} height={2} mb={10} />

      <PlanContent
        isFree={plan.alias === ProductAlias.Free}
        isLoading={isLoading}
        height={isRestrictedEX ? 'initial' : 77}
        plan={plan}
        showPriceInfo={!isRestrictedEX}
      >
        {!isRestrictedEX && (
          <Box mt="auto" px={3}>
            <ControlButton
              canDowngrade={canDowngrade}
              canReactivate={isExpires}
              disabled={isDowngrading}
              isActive={isActive}
              isDowngrading={isDowngrading}
              isLoading={status.isLoading}
              onClick={handleControlButtonClick}
            />
          </Box>
        )}
      </PlanContent>

      <Divider type="dashed" mt={4} />
    </Box>
  );
};
