import { useCallback } from 'react';
import { api as rootApi } from 'store/queries/currentUser/GetCurrentUser.generated';
import { api as generatedApi } from './UpdateUserInformation.generated';
import type { UpdateUserInformationInput } from 'shared/api';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    UpdateUserInformation: {
      onQueryStarted: async ({ input }, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          rootApi.util.updateQueryData('GetCurrentUser', undefined, (draft) => {
            if (draft.currentUser) {
              Object.assign(draft.currentUser, {
                firstName: input.firstName,
                lastName: input.lastName,
                phone: input.phone ?? null,
                phoneType: input.phoneType,
              });
            }
          })
        );

        queryFulfilled.catch(patchResult.undo);
      },
    },
  },
});

const { useUpdateUserInformationMutation } = api;

export const useUpdateUserInformation = () => {
  const [updateUserInformation, status] = useUpdateUserInformationMutation();

  const handleUpdateUserInformation = useCallback(
    (input: UpdateUserInformationInput) => updateUserInformation({ input }),
    [updateUserInformation]
  );

  return [handleUpdateUserInformation, status] as const;
};
