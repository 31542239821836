import { Typography, Link } from '@partstech/ui';
import { FormCheckBoxField } from '@partstech/ui/forms';
import { useCallback } from 'react';
import type { CredentialValues } from '../../../../../../types';
import type { FieldPath, ControllerRenderProps } from '@partstech/ui/forms';

type Props = {
  field: ControllerRenderProps<CredentialValues>;
  name: FieldPath<CredentialValues>;
  hint?: string;
  link?: string;
};

export const CheckBoxField = ({ field, hint, link, name }: Props) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      field.onChange(e.target.checked ? String(e.target.checked) : '');
    },
    [field]
  );

  return (
    <FormCheckBoxField name={name} onChange={handleChange} checked={field.value === 'true'}>
      <Typography>{hint}</Typography>
      {link && (
        <Link to={link} variant="bodyText2" color="primary" isExternal>
          {link}
        </Link>
      )}
    </FormCheckBoxField>
  );
};
