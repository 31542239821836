import { HistoryModalProvider, createHistoryModalRouter } from '@partstech/ui';
import { ContactSupport } from './ContactSupport';
import { Success } from './Success';
import type { ModalComponentProps } from '@partstech/ui';
import type { ContactSupportPaths, SupportMode } from 'types/contactForm';

const router = createHistoryModalRouter<ContactSupportPaths, undefined>([
  {
    path: 'contactSupport',
    title: 'Contact our Support team',
    element: <ContactSupport />,
  },
  {
    path: 'success',
    title: 'Message sent',
    element: <Success />,
  },
]);

export type ContactModalProps = {
  mode: SupportMode;
};

export const ContactModal = ({ mode }: ModalComponentProps<ContactModalProps>) => (
  <HistoryModalProvider
    router={router}
    initialRoute={{ path: 'contactSupport', state: { mode } }}
    initialData={undefined}
  />
);
