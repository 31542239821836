import { useSnackbar } from '@partstech/ui';
import { useCallback, useEffect, useRef } from 'react';
import { useRegisterSW } from 'virtual:pwa-register/react';
import { captureException } from 'integrations/sentry';

const intervalMS = 30 * 60 * 1000; // 30 min

const handleRegisteredWorker = (swScriptUrl: string, registration: ServiceWorkerRegistration | undefined) => {
  if (!registration) {
    return;
  }

  setInterval(async () => {
    if (!(!registration.installing && navigator)) {
      return;
    }

    if ('connection' in navigator && !navigator.onLine) {
      return;
    }

    try {
      await fetch(swScriptUrl, {
        cache: 'no-store',
        headers: {
          cache: 'no-store',
          'cache-control': 'no-cache',
        },
      });

      await registration.update();
    } catch (e) {
      if (e instanceof DOMException) {
        return;
      }

      captureException(e);
    }
  }, intervalMS);
};

const handleRegisterError = (_e: Error) => null;

export const useServiceWorker = () => {
  const {
    needRefresh: [isNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({ onRegisteredSW: handleRegisteredWorker, onRegisterError: handleRegisterError });

  const { addSnackbar } = useSnackbar();

  const updateServiceWorkerRef = useRef(updateServiceWorker);

  const refresh = useCallback(() => {
    updateServiceWorkerRef.current();

    window.location.reload();
  }, []);

  useEffect(() => {
    updateServiceWorkerRef.current = updateServiceWorker;
  }, [updateServiceWorker]);

  useEffect(() => {
    if (!isNeedRefresh) {
      return;
    }

    addSnackbar({
      variant: 'update',
      header: 'We’ve made some updates.',
      label: 'Refresh the page to see the latest content.',
      button: 'Refresh',
      onClick: refresh,
      position: { vertical: 'bottom', horizontal: 'left' },
      isPermanent: true,
    });
  }, [addSnackbar, isNeedRefresh, refresh]);
};
