import { useMutationStatus } from 'shared/api';
import { api as savedCartApi } from '../../queries/GetSavedCart.generated';
import { api as generatedApi } from './ToggleCartRetailPricing.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    ToggleCartRetailPricing: {
      onQueryStarted: async (params, { dispatch }) => {
        try {
          dispatch(
            savedCartApi.util.updateQueryData('GetSavedCart', { id: params.input.id }, (draft) => {
              const { cart } = draft;

              if (cart) {
                cart.showRetailPricing = !cart.showRetailPricing;
              }
            })
          );
        } catch {
          /* empty */
        }
      },
    },
  },
});

const { useToggleCartRetailPricingMutation } = api;

export const useToggleCartRetailPricing = () => {
  const [toggleCartRetailPricing, status] = useToggleCartRetailPricingMutation();

  useMutationStatus({ status });

  return [toggleCartRetailPricing, status] as const;
};
