import { TireSize } from '../../models/TireSize';
import { getOnlyNumberAndDot } from './getOnlyNumbersAndDot';
import { getOnlyNumberWithoutZero } from './getOnlyNumberWithoutZero';

const searchTireSizes = (parser: (value: string) => string) => (tireSizes: TireSize[], search: string) => {
  const parsedValue = parser(search);
  return tireSizes.filter((tireSize) => (parsedValue ? parser(tireSize.toPlainText()).includes(parsedValue) : false));
};

const searchRegularTireSizes = searchTireSizes(getOnlyNumberAndDot);
const searchCommercialTireSizes = searchTireSizes(getOnlyNumberWithoutZero);

export const filterTiresSizesByString = (tireSizes: TireSize[], search: string) => {
  if (!search) {
    return [];
  }

  if (TireSize.isCommercial(search)) {
    return searchCommercialTireSizes(tireSizes, search);
  }

  const regularTireSizes = searchRegularTireSizes(tireSizes, search);

  return regularTireSizes.length > 0 ? regularTireSizes : searchCommercialTireSizes(tireSizes, search);
};
