import { List } from '@partstech/ui';
import { PermissionGroupItem } from './PermissionGroupItem';
import { usePermissionList } from './usePermissionList';
import type { PermissionListArgs } from './usePermissionList';
import type { MemberPermissions } from 'shared/api';

type Props = {
  onChange?: (permissions: MemberPermissions) => Promise<void>;
} & PermissionListArgs;

export const PermissionList = ({ previewMode = false, onChange }: Props) => {
  const { groups, changePermission, changeGroupPermission } = usePermissionList({ onChange, previewMode });

  return (
    <List size="dense">
      {groups.map((group) => (
        <PermissionGroupItem
          key={group.name}
          group={group}
          onChange={changePermission}
          onGroupChange={changeGroupPermission}
        />
      ))}
    </List>
  );
};
