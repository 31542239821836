/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import type * as Types from '../../../shared/api/graphql/graphql';

export type FullPartTypeFragment = {
  __typename?: 'PartType';
  id: string;
  name: Types.Scalars['String']['input'];
  application: Types.PartTypeApplication;
  aliases?: Array<Types.Scalars['String']['input']> | null;
  attributes?: Array<{
    __typename?: 'PartTypeAttribute';
    name: Types.Scalars['String']['input'];
    priority: number;
    required: Types.Scalars['Boolean']['input'];
    values?: Array<Types.Scalars['String']['input']> | null;
  }> | null;
  category?: { __typename?: 'PartCategory'; id: string; name: Types.Scalars['String']['input'] } | null;
  linkedPartTypes?: Array<{ __typename?: 'PartType'; application: Types.PartTypeApplication }> | null;
  subCategory?: { __typename?: 'PartSubCategory'; id: string; name: Types.Scalars['String']['input'] } | null;
};

export const FullPartTypeFragmentDoc = `
    fragment FullPartType on PartType {
  id
  name
  application
  aliases
  attributes(requiredOnly: true) {
    name
    priority
    required
    values
  }
  category {
    id
    name
  }
  linkedPartTypes {
    application
  }
  subCategory {
    id
    name
  }
}
    `;
