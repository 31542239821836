import { useMemo } from 'react';
import { createCartTotalsFromCartOrders } from 'factories';
import { useToggleRetailPrice } from 'hooks/retailPricing';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import { usePunchoutInfo } from 'store/queries/punchoutInfo/usePunchoutInfo';
import { useDeleteCartOrder } from '../../api/mutations/deleteCartOrder';
import { useGetActiveCart } from '../../api/queries';
import {
  useCartSync,
  useCartQueue,
  useCartHighlights,
  useBuyOrSubmitCart,
  usePoNumbers,
  useUndoableRemoving,
} from '../../hooks';
import { CartContextProvider } from './CartContext';
import type { CartContextValue } from './CartContext';
import type { PropsWithChildren } from 'react';

export const CartProvider = ({ children }: PropsWithChildren) => {
  const { shop } = useGetCurrentUser();

  const { removingOrderItemIds, startRemoving, onRemoved } = useUndoableRemoving();

  const { isFetching, cart, refetch: refetchCart } = useGetActiveCart(removingOrderItemIds);

  const { isShown: showRetailPrice } = useToggleRetailPrice();

  const { highlights, addHighlights } = useCartHighlights();

  const { updateLastUpdatedTime } = useCartSync({ refetchCart });

  const {
    addToCart,
    addToCartMultiple,
    removeOrderItem,
    changeQuantity,
    isLoading: isOrdersLoading,
  } = useCartQueue({
    startRemoving,
    onRemoved,
    addHighlights,
    onUpdateCart: updateLastUpdatedTime,
    isCartLoaded: !isFetching,
    hasCart: Boolean(cart),
  });

  const totalLaborHours = useMemo(() => cart?.getTotalLaborHours(), [cart]);

  const { removeOrder, status: removeOrderStatus } = useDeleteCartOrder();

  const { buy, submit, isSubmitting, paidOrders, clearPaidOrders, mitchell1Payload } = useBuyOrSubmitCart({
    orders: cart?.orders,
    laborApplications: cart?.laborApplications,
    refetchCart,
  });

  const { hasReservedPoNumbers, poNumbersEnabled } = usePunchoutInfo();

  const requiredPoNumbers = Boolean(poNumbersEnabled && shop?.mandatoryPoNumber && !hasReservedPoNumbers);

  const { changePoNumber, changeGlobalPoNumber, errors: poNumberErrors } = usePoNumbers({ orders: cart?.orders });

  const totals = useMemo(
    () => createCartTotalsFromCartOrders(cart?.orders || [], cart?.laborRate || 0, totalLaborHours),
    [cart?.laborRate, cart?.orders, totalLaborHours]
  );

  const shouldConfirmShipping = Boolean(shop?.confirmShipping && totals.shippingLines.length > 0);

  const orderErrors = useMemo(() => cart?.getOrderErrors() || [], [cart]);

  const partErrors = useMemo(() => cart?.getPartErrors() || [], [cart]);

  const contextValue: CartContextValue = useMemo(
    () => ({
      isLoading: isFetching,
      isFetching: isFetching || isOrdersLoading || removeOrderStatus.isLoading || isSubmitting,
      cart,
      showRetailPrice,
      highlights,
      isRemoving: removingOrderItemIds.length > 0,
      orderErrors,
      partErrors,
      paidOrders,
      mitchell1Payload,
      poNumberErrors,
      totals,
      shouldConfirmShipping,
      requiredPoNumbers,
      changeGlobalPoNumber,
      changePoNumber,
      addHighlights,
      changeQuantity,
      removeOrderItem,
      removeOrder,
      buy,
      submit,
      addToCart,
      addToCartMultiple,
      clearPaidOrders,
    }),
    [
      isFetching,
      isOrdersLoading,
      removeOrderStatus.isLoading,
      isSubmitting,
      cart,
      showRetailPrice,
      highlights,
      removingOrderItemIds.length,
      orderErrors,
      partErrors,
      paidOrders,
      mitchell1Payload,
      poNumberErrors,
      totals,
      shouldConfirmShipping,
      requiredPoNumbers,
      changeGlobalPoNumber,
      changePoNumber,
      addHighlights,
      changeQuantity,
      removeOrderItem,
      removeOrder,
      buy,
      submit,
      addToCart,
      addToCartMultiple,
      clearPaidOrders,
    ]
  );

  return <CartContextProvider value={contextValue}>{children}</CartContextProvider>;
};
