import { css } from '@emotion/react';
import { Card } from '@partstech/ui';
import { useCallback } from 'react';
import { CardName, CardBrand } from 'entities/paymentCard';
import { usePermissions } from 'store/queries/currentUser/usePermissions';
import type { IconName } from '@partstech/ui';
import type { PaymentCard } from 'entities/paymentCard';

const styles = {
  wrapper: (isHovered: boolean) => css`
    ${isHovered &&
    `
      @media (hover), (-ms-high-contrast: none) {
        &:hover {
          cursor: pointer;
        }
      }
      `}
  `,
};

export type SelectedCreditCardProps = {
  actionIcon?: IconName;
  card: PaymentCard;
  isHovered?: boolean;
  onActionClick?: () => void;
  onCardClick?: () => void;
};

export const SelectedCreditCard = ({
  actionIcon = 'more_vert',
  card,
  isHovered = false,
  onActionClick,
  onCardClick,
}: SelectedCreditCardProps) => {
  const {
    member: { paymentsAllowed },
  } = usePermissions();

  const handleCardClick = useCallback(() => {
    if (paymentsAllowed && onCardClick) {
      onCardClick();
    }
  }, [paymentsAllowed, onCardClick]);

  return (
    <Card
      actions={
        paymentsAllowed ? [{ title: 'Manage payment method', icon: actionIcon, onClick: onActionClick }] : undefined
      }
      css={styles.wrapper(paymentsAllowed && isHovered)}
      borderColor={card.isExpired ? 'negative' : 'mono40'}
      radius={1.5}
      height={12}
      px={2}
      display="flex"
      alignItems="center"
      gap={2}
      onClick={handleCardClick}
      data-testid="selectedCreditCard"
    >
      <CardBrand name={card.brand} size="large" />

      <CardName card={card} color={card.isExpired ? 'negative' : 'defaultText'} showExpired />
    </Card>
  );
};
