import { useSnackbar } from '@partstech/ui';
import { useForm } from '@partstech/ui/forms';
import { isEmpty } from '@partstech/ui/utils';
import { useCallback, useEffect } from 'react';
import { createPaymentCardFromData } from 'entities/paymentCard';
import { useFormStatus } from 'shared/lib/form';
import { useAddPaymentCard } from '../../../../lib/useAddPaymentCard';
import { useChangePaymentCard } from '../../../../lib/useChangePaymentCard';
import type { PaymentCard } from 'entities/paymentCard';
import type { CardFormData } from 'integrations/stripe';

type FormData = Omit<CardFormData, 'id'>;

type Args = {
  onPaymentChanged?: (card: PaymentCard) => void;
  onSubmit?: (card: PaymentCard) => void;
};

export const useAddPaymentCardForm = ({ onPaymentChanged, onSubmit }: Args) => {
  const { addSnackbar } = useSnackbar();

  const form = useForm<FormData>({
    defaultValues: { holder: '', cardNumber: '', expire: '', cvc: '', isMovingSubscriptions: false },
    mode: 'onChange',
  });

  const { formState, getValues, watch } = form;
  const { isSubmitting, isValid, dirtyFields } = formState;
  const isMovingSubscriptions = watch('isMovingSubscriptions');

  const [addPaymentCard, status] = useAddPaymentCard();

  const handlePaymentChangedSuccess = useCallback(() => {
    const card = status.data?.createShopCard?.card;

    if (!card) {
      return;
    }

    onPaymentChanged?.(createPaymentCardFromData(card, isMovingSubscriptions ? card.id : undefined));
  }, [isMovingSubscriptions, onPaymentChanged, status.data?.createShopCard?.card]);

  const [changePaymentCard] = useChangePaymentCard({ onSuccess: handlePaymentChangedSuccess });

  const { isLoading: isLoadingAddPaymentCard, isSuccess: isSuccessAddPaymentCard } = status;

  const isLoading = isSubmitting || isLoadingAddPaymentCard;
  const isDisabled = isLoading || !isValid || isEmpty(dirtyFields);

  const handleAddedCard = useCallback(async () => {
    const card = status?.data?.createShopCard?.card;

    if (!card) {
      return;
    }

    if (isMovingSubscriptions) {
      await changePaymentCard(card.id);
      return;
    }

    onSubmit?.(createPaymentCardFromData(card));
  }, [status?.data?.createShopCard?.card, isMovingSubscriptions, changePaymentCard, onSubmit]);

  const submitForm = useCallback(async () => {
    await addPaymentCard(getValues('holder'));
  }, [getValues, addPaymentCard]);

  useEffect(() => {
    if (!isSuccessAddPaymentCard) {
      return;
    }

    if (!isMovingSubscriptions) {
      addSnackbar({ label: 'Credit card added' });
    }
  }, [isSuccessAddPaymentCard, isMovingSubscriptions, addSnackbar]);

  useFormStatus<FormData>({ form, status, onSuccess: handleAddedCard });

  return {
    form,
    isDisabled,
    isLoading,
    submitForm,
  };
};
