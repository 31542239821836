/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import { DiagramFragmentDoc } from './DiagramFragment.generated';
import type * as Types from '../../../shared/api/graphql/graphql';

export type GetSearchDiagramsQueryVariables = Types.Exact<{
  vehicleID: Types.Scalars['ID']['input'];
}>;

export type GetSearchDiagramsQuery = {
  __typename?: 'Query';
  vehicle?: {
    __typename?: 'Vehicle';
    diagrams?: Array<{
      __typename?: 'Diagram';
      id: string;
      url?: Types.Scalars['String']['input'] | null;
      name: Types.Scalars['String']['input'];
      partTypes?: Array<{
        __typename?: 'PartType';
        id: string;
        name: Types.Scalars['String']['input'];
        application: Types.PartTypeApplication;
      }> | null;
      references?: Array<{
        __typename?: 'DiagramReference';
        layer?: Types.Scalars['String']['input'] | null;
        partNumber?: Types.Scalars['String']['input'] | null;
        partType?: {
          __typename?: 'PartType';
          id: string;
          name: Types.Scalars['String']['input'];
          application: Types.PartTypeApplication;
          linkedPartTypes?: Array<{
            __typename?: 'PartType';
            id: string;
            name: Types.Scalars['String']['input'];
            application: Types.PartTypeApplication;
          }> | null;
        } | null;
      }> | null;
      brand?: { __typename?: 'Brand'; id: string; name: Types.Scalars['String']['input'] } | null;
      category?: { __typename?: 'PartCategory'; id: string; name: Types.Scalars['String']['input'] } | null;
    }> | null;
  } | null;
};

export const GetSearchDiagramsDocument = `
    query GetSearchDiagrams($vehicleID: ID!) {
  vehicle(vehicleID: $vehicleID) {
    diagrams {
      ...Diagram
      partTypes {
        id
        name
        application
      }
      references {
        layer
        partNumber
        partType {
          id
          name
          application
          linkedPartTypes {
            id
            name
            application
          }
        }
      }
    }
  }
}
    ${DiagramFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetSearchDiagrams: build.query<GetSearchDiagramsQuery, GetSearchDiagramsQueryVariables>({
      query: (variables) => ({ document: GetSearchDiagramsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetSearchDiagramsQuery, useLazyGetSearchDiagramsQuery } = injectedRtkApi;
