import { api as activeCartApi } from './GetActiveCart.generated';
import { api as generatedApi } from './GetActiveCartOrders.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    GetActiveCartOrders: {
      onQueryStarted: async ({ ids }, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;

        dispatch(
          activeCartApi.util.updateQueryData('GetActiveCart', undefined, (draft) => {
            if (!data.activeCart || !draft.activeCart) {
              return;
            }

            Object.assign(draft.activeCart, { vehicles: data.activeCart.vehicles });

            if (data.activeCart.orderByIds) {
              data.activeCart.orderByIds?.forEach((order) => {
                const prevOrder = draft.activeCart?.orders?.find((item) => item.id === order.id);

                if (prevOrder) {
                  Object.assign(prevOrder, order);
                } else {
                  draft.activeCart?.orders?.push(order);
                }
              });
            } else {
              Object.assign(draft.activeCart, {
                orders: draft.activeCart.orders?.filter((item) => !ids.includes(item.id)),
              });
            }
          })
        );
      },
    },
  },
});

export const { useLazyGetActiveCartOrdersQuery } = api;
