/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../shared/api/graphql/graphql';

export type GetAutomaticPreferredBrandsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetAutomaticPreferredBrandsQuery = {
  __typename?: 'Query';
  currentUser?: {
    __typename?: 'User';
    activeMember?: {
      __typename?: 'ShopMember';
      shop?: {
        __typename?: 'Shop';
        preferredBrands?: {
          __typename?: 'PreferredBrandsConnection';
          edges?: Array<{
            __typename?: 'PreferredBrandsEdge';
            node?: {
              __typename?: 'PreferredBrands';
              purchaseCount: number;
              brand?: { __typename?: 'Brand'; id: string; name: Types.Scalars['String']['input'] } | null;
              brands?: Array<{ __typename?: 'Brand'; id: string; name: Types.Scalars['String']['input'] }> | null;
              partType?: {
                __typename?: 'PartType';
                id: string;
                name: Types.Scalars['String']['input'];
                application: Types.PartTypeApplication;
                linkedPartTypes?: Array<{
                  __typename?: 'PartType';
                  id: string;
                  name: Types.Scalars['String']['input'];
                  application: Types.PartTypeApplication;
                }> | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export const GetAutomaticPreferredBrandsDocument = `
    query GetAutomaticPreferredBrands {
  currentUser {
    activeMember {
      shop {
        preferredBrands {
          edges {
            node {
              purchaseCount
              brand {
                id
                name
              }
              brands {
                id
                name
              }
              partType {
                id
                name
                application
                linkedPartTypes {
                  id
                  name
                  application
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetAutomaticPreferredBrands: build.query<
      GetAutomaticPreferredBrandsQuery,
      GetAutomaticPreferredBrandsQueryVariables | void
    >({
      query: (variables) => ({ document: GetAutomaticPreferredBrandsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetAutomaticPreferredBrandsQuery, useLazyGetAutomaticPreferredBrandsQuery } = injectedRtkApi;
