/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type UnarchiveCartMutationVariables = Types.Exact<{
  input: Types.UnarchiveCartInput;
}>;

export type UnarchiveCartMutation = {
  __typename?: 'Mutation';
  unarchiveCart?: {
    __typename?: 'UnarchiveCartPayload';
    cart?: {
      __typename?: 'Cart';
      id: string;
      improvedStatus: Types.CartImprovedStatus;
      updatedAt: Types.Scalars['DateTime']['input'];
    } | null;
  } | null;
};

export const UnarchiveCartDocument = `
    mutation UnarchiveCart($input: UnarchiveCartInput!) {
  unarchiveCart(input: $input) {
    cart {
      id
      improvedStatus
      updatedAt
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    UnarchiveCart: build.mutation<UnarchiveCartMutation, UnarchiveCartMutationVariables>({
      query: (variables) => ({ document: UnarchiveCartDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUnarchiveCartMutation } = injectedRtkApi;
