import { Box, List, ListItem, Switcher, Typography } from '@partstech/ui';
import { useFormContext } from '@partstech/ui/forms';
import { PermissionItem } from './PermissionItem';
import type { ChangeEvent } from 'react';
import type { PermissionGroup } from 'types/permissions';

type Props = {
  group: PermissionGroup;
  onChange: () => void;
  onGroupChange: (e: ChangeEvent<HTMLInputElement>) => Promise<void>;
};

export const PermissionGroupItem = ({ group, onChange, onGroupChange }: Props) => {
  const { label, name, permissions, description } = group;

  const { watch } = useFormContext();
  const formData = watch();

  const checked = permissions.every((permission) => formData[permission.name]);
  const indeterminate =
    permissions.some((permission) => !formData[permission.name]) &&
    permissions.some((permission) => formData[permission.name]);

  return (
    <ListItem data-testid={name}>
      <Switcher checked={checked} id={name} indeterminate={indeterminate} name={name} onChange={onGroupChange}>
        <Typography variant="bodyText2">{label}</Typography>
        <Typography variant="bodyText2" color="subtleText">
          {description}
        </Typography>
      </Switcher>

      <Box mt={2}>
        <List size="dense">
          {permissions.map((permission) => (
            <PermissionItem key={permission.name} permission={permission} onChange={onChange} />
          ))}
        </List>
      </Box>
    </ListItem>
  );
};
