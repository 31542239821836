import { Routes } from 'constant';
import { generateUrl } from './generateUrl';
import type { ProductAlias } from 'features/subscription';
import type { SubscriptionPlanPeriod } from 'shared/api';

export type SubscriptionSearchQuery = {
  addOns?: ProductAlias[];
  isUpdatePeriod?: boolean;
  period?: SubscriptionPlanPeriod;
  plan?: ProductAlias;
};

type Args = {
  page?: 'payment-history';
  search?: SubscriptionSearchQuery;
};

export const getSubscriptionUrl = ({ page, search }: Args = {}) => {
  if (page) {
    return generateUrl(Routes.SUBSCRIPTION, { page });
  }

  return generateUrl('/my-shop/subscription' as Routes, undefined, search);
};
