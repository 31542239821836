import type { PricingFlags } from 'types/product';

export const getAdditionalRetailPrice = (
  retailPrice: number | null | undefined,
  price: number,
  flags: PricingFlags
) => {
  if (flags.showOnlyRetailPrice || retailPrice === undefined) {
    return undefined;
  }
  return retailPrice || price;
};
