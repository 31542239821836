import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { createReport } from 'shared/api/rest/gen/custom';
import { logout } from 'store/features/user/account';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { CreateReportRequest } from 'shared/api/rest/gen/custom';
import type { RootState } from 'store';
import type { SearchParams } from 'types/search';

export type SearchErrorData = { errorMessages: string[]; supplierName: string; storeAddress: string };

export const createReportIssue = createAsyncThunk(
  'features/search/settings/createReportIssue',
  async (payload: CreateReportRequest, { rejectWithValue }) => {
    try {
      return await createReport(payload);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

type State = {
  lastSearch: SearchParams | null;
};

const initialState: State = {
  lastSearch: null,
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setLastSearch: (state, action: PayloadAction<SearchParams | null>) => {
      state.lastSearch = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, () => initialState);
  },
});

export const { setLastSearch } = settingsSlice.actions;

export const settings = settingsSlice.reducer;

const selectState = (state: RootState) => state.features.search.settings;

export const selectLastSearch = createSelector([selectState], (state) => state.lastSearch);
