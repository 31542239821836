/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type AddItemToCartMutationVariables = Types.Exact<{
  item: Types.ItemParametersInput;
}>;

export type AddItemToCartMutation = {
  __typename?: 'Mutation';
  addItemToActiveCart?:
    | {
        __typename?: 'AddItemToActiveCartErrorPayload';
        error: Types.AddItemError;
        errorMessage: Types.Scalars['String']['input'];
      }
    | { __typename?: 'AddItemToActiveCartSuccessPayload'; orderId: string; orderItemId: string }
    | null;
};

export const AddItemToCartDocument = `
    mutation AddItemToCart($item: ItemParametersInput!) {
  addItemToActiveCart(input: {item: $item}) {
    ... on AddItemToActiveCartErrorPayload {
      error
      errorMessage
    }
    ... on AddItemToActiveCartSuccessPayload {
      orderId
      orderItemId
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    AddItemToCart: build.mutation<AddItemToCartMutation, AddItemToCartMutationVariables>({
      query: (variables) => ({ document: AddItemToCartDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useAddItemToCartMutation } = injectedRtkApi;
