import { useCreateModal } from '@partstech/ui/hooks';
import { LearnAboutFeatureModal } from '../components/LearnAboutFeatureModal';
import type { PaidFeatureAlias } from '../constants/features';
import type { GenericModalSize } from '@partstech/ui';

export const useLearnAboutFeatureModal = ({
  feature,
  onClose,
  size = 'xl',
}: {
  feature: PaidFeatureAlias;
  onClose?: () => void;
  size?: GenericModalSize;
}) => useCreateModal(LearnAboutFeatureModal, { feature, size, onClose }, { customId: 'learnAboutFeature' });
