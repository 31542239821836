import { Box, Typography } from '@partstech/ui';

export const RetailPricing = () => (
  <>
    <Typography>Retail Pricing is unlocked with any paid PartsTech subscription.</Typography>

    <Box my={2}>
      <Typography>
        Setting up Retail Pricing will allow you to hide wholesale costs from customers, send tire quotes, and review a
        cart's Gross Profit.
      </Typography>
    </Box>

    <Typography>
      In addition to Retail Pricing, PartsTech paid subscriptions include various features to save you time and
      super-charge your shop's performance.
    </Typography>
  </>
);
