import { DEFAULT_AVAILABILITY, Routes } from 'constant';
import { pickDefinedValues } from 'shared/lib/object';
import { generateUrl } from 'utils/url/generateUrl';
import type { SearchQuery, SearchQueryConvertable, SearchSequenceQuery, SupplierQuery } from 'features/searchForm';
import type { Vehicle } from 'models';

export class SearchUrl {
  private readonly vehicle: Vehicle | null;

  private readonly searchEntry: SearchQueryConvertable;

  private readonly preservedQuery: (Partial<SupplierQuery> & Partial<SearchSequenceQuery>) | undefined;

  constructor(
    vehicle: Vehicle | null,
    searchEntry: SearchQueryConvertable,
    preservedQuery?: Partial<SupplierQuery> & Partial<SearchSequenceQuery>
  ) {
    this.vehicle = vehicle;
    this.searchEntry = searchEntry;
    this.preservedQuery = preservedQuery;
  }

  getUrl(isFittedAvailable: boolean): string {
    if (this.searchEntry.isVehicleRequired() && this.vehicle === null) {
      throw new Error('Vehicle required');
    }

    const query: SearchQuery = {
      ...this.searchEntry.toQuery(this.vehicle, isFittedAvailable),
      vehicle: this.vehicle?.id,
      vin: this.vehicle?.vin,
      plate: this.vehicle?.plate ?? undefined,
      state: this.vehicle?.state ?? undefined,
      availability: DEFAULT_AVAILABILITY,
      ...this.preservedQuery,
    };

    return generateUrl(Routes.SEARCH_RESULTS, {}, pickDefinedValues(query));
  }
}
