import { css } from '@emotion/react';
import { Box, Icon, Link, Typography } from '@partstech/ui';
import { Routes } from 'constant';
import { currency } from 'shared/lib/string';
import { LaborIcon } from '../LaborIcon';
import type { Theme } from '@partstech/ui';

const styles = {
  card: (theme: Theme) => css`
    .editIcon {
      display: none;
    }
    &:hover {
      background-color: ${theme.color.mono2};
      .editIcon {
        display: flex;
      }
    }
  `,
  editIcon: (theme: Theme) => css`
    display: 'none';
    &:hover {
      background-color: ${theme.color.mono2};
      display: 'block';
    }
  `,
  description: css`
    display: block;
    line-height: 16px;
  `,
};

type Props = {
  laborRate: number;
  totalHours: number;
  quantity: number;
};

export const LaborTotals = ({ totalHours, laborRate, quantity }: Props) => (
  <Link to={Routes.CART} underline="none">
    <Box
      px={3}
      py={4}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      position="relative"
      gap={4}
      css={styles.card}
    >
      <Box display="flex">
        <LaborIcon size="small" />

        <div>
          <Typography variant="caption" color="subtleText" css={styles.description}>
            Services: {quantity}
          </Typography>
          <Typography variant="caption" color="subtleText" css={styles.description}>
            {totalHours.toFixed(2)} HRS x {currency(laborRate)}
          </Typography>
        </div>
      </Box>

      <Typography variant="subtitle1">{currency(laborRate * totalHours)}</Typography>

      <Box
        position="absolute"
        right={3}
        display="flex"
        justifyContent="center"
        alignItems="center"
        width={8}
        height={8}
        background="mono2"
        className="editIcon"
      >
        <Icon name="edit" color="subtleText" />
      </Box>
    </Box>
  </Link>
);
