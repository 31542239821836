import { css } from '@emotion/react';
import { Box, Tab, Tabs } from '@partstech/ui';
import { elevationShadow } from '@partstech/ui/utils';
import { getShortPlanName } from '../../../../utils/getShortPlanName';
import type { ProductAlias } from '../../../../constants/products';
import type { Plan } from '../../../../types';

const styles = {
  tabs: css`
    ${elevationShadow(6)}
  `,
};

type Props = {
  activeTab: ProductAlias;
  plans: Plan[];
  onChange: (id: ProductAlias) => void;
};

export const PlanTabs = ({ activeTab, plans, onChange }: Props) => (
  <Box background="white" position="sticky" top={0} zIndex="initial">
    <Tabs activeTab={activeTab} onChange={onChange} tabWidth={1 / plans.length} css={styles.tabs}>
      {plans.map((plan) => (
        <Tab id={plan.alias} key={plan.alias} icon="star" iconColor={plan.color}>
          {getShortPlanName(plan.name)}
        </Tab>
      ))}
    </Tabs>
  </Box>
);
