import { useMemo } from 'react';
import { useMatch } from 'react-router-dom';
import { useSignupQueryParams } from 'hooks/signup';
import { OnboardStepType } from 'shared/api';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import { useOnboardSteps } from 'store/queries/currentUser/useOnboardSteps';
import { SignUpPages, getSignUpUrl } from 'utils/url';
import { useAppLocation } from '../useAppLocation';

export const useSignUpQuestionsRedirect = () => {
  const { pathname } = useAppLocation();
  const searchParams = useSignupQueryParams();
  const { hasCurrentUser, shop, managementSystem } = useGetCurrentUser();
  const { isOnboardCompleted, isSuccess, isStepCompleted } = useOnboardSteps();

  const stepsConfig = useMemo(
    () => [
      { step: SignUpPages.ShopDetails, isAvailable: Boolean(shop) },
      { step: SignUpPages.ManagementSystem, isAvailable: Boolean(managementSystem) },
      { step: SignUpPages.PartsSuppliers, isAvailable: Boolean(isStepCompleted(OnboardStepType.Parts)) },
      { step: SignUpPages.TireSuppliers, isAvailable: Boolean(isStepCompleted(OnboardStepType.Tires)) },
      { step: SignUpPages.Role, isAvailable: Boolean(isStepCompleted(OnboardStepType.PrimaryRole)) },
      { step: SignUpPages.HearAboutUs, isAvailable: Boolean(isStepCompleted(OnboardStepType.Referral)) },
      { step: SignUpPages.PhoneNumber, isAvailable: Boolean(isStepCompleted(OnboardStepType.Phone)) },
    ],
    [isStepCompleted, shop, managementSystem]
  );

  const stepCompletionStatus = useMemo(
    () =>
      stepsConfig.reduce<Record<string, boolean>>(
        (acc, object) => ({ ...acc, [getSignUpUrl(object.step)]: object.isAvailable }),
        {
          [getSignUpUrl(SignUpPages.ShopType)]: true,
          [getSignUpUrl(SignUpPages.ShopDetails)]: true,
        }
      ),
    [stepsConfig]
  );

  const redirectUrl = useMemo(() => {
    const neededStep = stepsConfig.find((step) => !step.isAvailable)?.step;

    if (!neededStep) {
      return null;
    }

    return getSignUpUrl(neededStep, searchParams);
  }, [searchParams, stepsConfig]);

  const pathRedirect = redirectUrl?.split('?')[0] ?? '';

  const shouldSkipRedirect = Boolean(useMatch(pathRedirect) || !isSuccess || !hasCurrentUser || isOnboardCompleted);

  if (shouldSkipRedirect) {
    return null;
  }

  return stepCompletionStatus[pathname] ? null : redirectUrl;
};
