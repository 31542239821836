import { css } from '@emotion/react';
import { Box, Typography, useMedia } from '@partstech/ui';
import { memo } from 'react';
import type { BoxProps } from '@partstech/ui';

const styles = {
  partsListTitle: css`
    text-transform: uppercase;
  `,
};

type Props = BoxProps & {
  title?: string;
};

export const Title = memo(({ title, ...props }: Props) => {
  const { isMobile } = useMedia();

  if (!title) {
    return null;
  }

  return (
    <Box position="relative" css={styles.partsListTitle} {...props}>
      <Typography variant={isMobile ? 'overline' : 'bodyText2'} color="black" bold>
        {title}
      </Typography>
    </Box>
  );
});
