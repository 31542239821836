import { useFormContext, useController } from '@partstech/ui/forms';
import { useCallback } from 'react';
import type { RegisterOptions } from '@partstech/ui/forms';
import type { ChangeEvent } from 'react';

const expireValidator = (value: string) => {
  const expire = value.split(' / ');

  if (!expire) {
    return false;
  }

  const [month, year] = expire;

  const dateNow = new Date();

  const dateForm = new Date(
    Number(`20${year}`),
    Number(`${month?.slice(0, 1) === '0' ? month.slice(-1) : month}`),
    dateNow.getDate()
  );

  if (!Number(month) || !Number(year) || month?.includes('-') || year?.includes('-')) {
    return false;
  }

  if ((month?.slice(0, 1) !== '0' && Number(month) > 12) || month === '0-') {
    return false;
  }

  if (dateForm <= dateNow) {
    return 'Card validity period has expired.';
  }

  return true;
};

type Props = {
  name?: string;
  rules?: RegisterOptions;
};

export const useExpireCardField = ({ name = '', rules }: Props) => {
  const expireRules = { ...rules, validate: { ...rules?.validate, expireValidator } };

  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { field } = useController({ control, name, rules: expireRules });

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.value === '-- / --') {
        return field.onChange('');
      }

      const expire = e.target.value.split(' / ');
      const [month, year] = expire;

      if (Number(`${month?.slice(0, 1) === '0' ? month.slice(-1) : month}`) > 12) {
        return field.onChange(`12 / ${year}`);
      }

      if (month?.slice(0, 1) === '0' && (month.slice(-1) === '0' || month.slice(-1) === '-')) {
        return field.onChange('0- / --');
      }

      return field.onChange(e);
    },
    [field]
  );

  const error = `${errors?.[name]?.message ?? ''}`;

  return { field, error, onChange: handleChange };
};
