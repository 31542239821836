import { Box, Button, useModalHistory, useMedia } from '@partstech/ui';
import { useCallback, useMemo } from 'react';
import { useLastAddedSupplierAccount } from 'hooks/supplierAccounts';
import { useGetSupplierAccounts } from 'store/queries/currentUser/supplierAccounts/useGetSupplierAccounts';
import { CredentialsWrapper } from '../CredentialsWrapper';
import { TireConnectBanner } from '../TireConnectBanner';
import { SupplierConnectionStatus } from './SupplierConnectionStatus';
import type { CommonAccountFlowPaths } from '../../../types';

export const SubmittedForm = () => {
  const { isMobile } = useMedia();

  const { state, onClose } = useModalHistory<CommonAccountFlowPaths, 'submitted'>();
  const { store } = state ?? {};

  const supplier = store?.supplier ?? null;

  const { openAddAccountSnackbar } = useLastAddedSupplierAccount();
  const { getAccountByStoreId } = useGetSupplierAccounts();

  const account = useMemo(
    () => (store?.id ? getAccountByStoreId(store.id) : undefined),
    [getAccountByStoreId, store?.id]
  );

  const handleClose = useCallback(() => {
    onClose();

    openAddAccountSnackbar();
  }, [openAddAccountSnackbar, onClose]);

  if (!store || !supplier) {
    return null;
  }

  return (
    <CredentialsWrapper
      store={store}
      account={account}
      actions={
        <Button fullWidth={isMobile} onClick={handleClose}>
          Okay
        </Button>
      }
    >
      <Box mt={{ sm: 6, md: 0 }} mb={5}>
        <SupplierConnectionStatus supplier={supplier} />
      </Box>

      {supplier.isTire && <TireConnectBanner supplier={supplier} />}
    </CredentialsWrapper>
  );
};
