import { useCallback } from 'react';
import { useMutationStatus } from 'shared/api';
import { subscriptionApi } from '../../queries/subscription';
import { api as generatedApi } from './RemoveAddOn.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    RemoveAddOn: {
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        const shopSubscription = data.removeSubscriptionAddon?.shopSubscription;

        if (shopSubscription) {
          dispatch(
            subscriptionApi.util.updateQueryData('GetSubscription', undefined, ({ currentUser }) => {
              const shop = currentUser?.activeMember?.shop;

              if (shop) {
                Object.assign(shop, { shopSubscription });
              }
            })
          );
        }
      },
    },
  },
});

const { useRemoveAddOnMutation } = api;

export const useRemoveAddOn = (id: string | null) => {
  const [removeAddOn, status] = useRemoveAddOnMutation();

  const handleRemoveAddOn = useCallback(async () => {
    if (id) {
      await removeAddOn({ input: { planId: id } });
    }
  }, [id, removeAddOn]);

  useMutationStatus({ status });

  return [handleRemoveAddOn, status] as const;
};
