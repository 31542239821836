/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type UpdatePaymentNotesMutationVariables = Types.Exact<{
  paymentNotes: Types.PaymentNotesInput;
  orderId: Types.Scalars['ID']['input'];
}>;

export type UpdatePaymentNotesMutation = {
  __typename?: 'Mutation';
  updateActiveCartOrderPaymentNotes?:
    | { __typename?: 'UpdateOrderPaymentNotesErrorPayload'; errorMessage: Types.Scalars['String']['input'] }
    | { __typename?: 'UpdateOrderPaymentNotesSuccessPayload'; updatedOrderId: string }
    | null;
};

export const UpdatePaymentNotesDocument = `
    mutation UpdatePaymentNotes($paymentNotes: PaymentNotesInput!, $orderId: ID!) {
  updateActiveCartOrderPaymentNotes(
    input: {paymentNotes: $paymentNotes, orderId: $orderId}
  ) {
    ... on UpdateOrderPaymentNotesErrorPayload {
      errorMessage
    }
    ... on UpdateOrderPaymentNotesSuccessPayload {
      updatedOrderId
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    UpdatePaymentNotes: build.mutation<UpdatePaymentNotesMutation, UpdatePaymentNotesMutationVariables>({
      query: (variables) => ({ document: UpdatePaymentNotesDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUpdatePaymentNotesMutation } = injectedRtkApi;
