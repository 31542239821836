import { extractTireSizes } from '../utils/tire/extractTireSizes';
import { TireSize } from './TireSize';
import type {
  SearchEntryPointInterface,
  SearchQueryConvertable,
  SearchQuery,
  SearchRequestConvertable,
  ToRequestParams,
} from 'features/searchForm';
import type { SearchInput } from 'shared/api';

export class TireConfiguration implements SearchEntryPointInterface, SearchQueryConvertable, SearchRequestConvertable {
  size: TireSize;

  rearSize: TireSize | null = null;

  constructor(size: TireSize, rearSize: TireSize | null = null) {
    this.size = size;

    if (rearSize) {
      this.rearSize = rearSize;
    }
  }

  public static createFromString(value: string) {
    const [frontSize, rearSize] = extractTireSizes(value);

    if (!frontSize) {
      return null;
    }

    return new TireConfiguration(
      TireSize.createFromValue(frontSize),
      rearSize ? TireSize.createFromValue(rearSize) : null
    );
  }

  isVehicleRequired(): boolean {
    return false;
  }

  getClassName(): string {
    return 'fs-tire';
  }

  getSizeByPosition(isRear: boolean) {
    return isRear ? this.rearSize : this.size;
  }

  isStaggered() {
    return this.rearSize ? this.rearSize.toString() !== this.size.toString() : false;
  }

  populateInput(): string {
    return this.toString();
  }

  toString() {
    return this.rearSize ? `FRONT: ${this.size} x REAR: ${this.rearSize}` : `${this.size}`;
  }

  toQuery(): SearchQuery {
    return {
      tire_sizes: this.rearSize ? `${this.size.toValue()},${this.rearSize.toValue()}` : this.size.toValue(),
    };
  }

  toValue() {
    return {
      size: this.size.toObject(),
      rearSize: this.rearSize?.toObject(),
    };
  }

  toRequest({ accountId, vehicleId }: ToRequestParams): SearchInput {
    if (!this.rearSize) {
      return {
        tireSize: {
          accountId,
          height: this.size.height,
          width: this.size.width,
          rim: this.size.rim,
        },
      };
    }

    return {
      tireApplicationStaggered: {
        accountId,
        vehicleId,
        frontSize: {
          height: this.size.height,
          width: this.size.width,
          rim: this.size.rim,
        },
        rearSize: {
          height: this.rearSize.height,
          width: this.rearSize.width,
          rim: this.rearSize.rim,
        },
      },
    };
  }
}
