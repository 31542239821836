import { useController, useFormContext } from '@partstech/ui/forms';
import { useMemo } from 'react';
import { isObject } from 'shared/lib/object';
import type { StripeElementChangeEvent } from '@stripe/stripe-js';

const validator = (value: string | number | boolean | StripeElementChangeEvent) =>
  Boolean(value && isObject(value) && 'complete' in value && value.complete);

export type CardFormData<Value = string> = {
  id: number;
  holder: string;
  cardNumber: Value;
  expire: Value;
  cvc: Value;
  isMovingSubscriptions: boolean;
};

type Props = {
  name: keyof CardFormData;
  type: 'cardNumber' | 'expire' | 'cvc';
  onChange?: (e: StripeElementChangeEvent) => void;
};

export const useFormCardField = ({ name, type }: Props) => {
  const {
    control,
    formState: { errors, isSubmitting },
  } = useFormContext<CardFormData<StripeElementChangeEvent>>();

  const { field } = useController({
    control,
    name: name ?? '',
    rules: { validate: { validator } },
  });

  const error = useMemo(
    () => (isObject(field.value) && field.value?.error?.message) || (errors[type]?.ref?.name && errors[type]?.message),
    [errors, field.value, type]
  );

  return { field, error, isSubmitting };
};
