import { Box, Link, Typography } from '@partstech/ui';
import { CreateShopForm } from 'features/shop';
import { StepTitle } from 'features/signUp';
import { ShopSearch } from './ShopSearch';
import { useCreateShop } from './useCreateShop';
import type { ShopFormData } from 'features/shop';

type Props = {
  defaultValues?: ShopFormData;
  onSubmit: (
    values: ShopFormData,
    params: Partial<{ hasDuplicates: boolean; hasChangedShopInfo: boolean }>
  ) => Promise<void>;
  onSelect?: (values: ShopFormData | null) => void;
  submitButtonText: string;
  hasShop?: boolean;
  isLoading?: boolean;
};

export const CreateShop = ({ defaultValues, onSubmit, onSelect, submitButtonText, hasShop, isLoading }: Props) => {
  const { changeShopName, closeSearch, isOpenSearch, openSearch, selectShop, shopData } = useCreateShop({
    defaultValues,
    onSelect,
  });

  if (isOpenSearch) {
    return <ShopSearch onChangeShopName={changeShopName} onClose={closeSearch} onShopSelect={selectShop} />;
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      textAlign={{ sm: 'center', md: 'left' }}
      px={{ sm: 4 }}
      pb={{ sm: 2 }}
      width={{ md: 90 }}
    >
      <StepTitle>Tell us about your shop</StepTitle>

      <Box mt={2}>
        <Typography variant="bodyText2" color="subtleText">
          Confirm the information about your shop below, or enter the correct information.{' '}
        </Typography>
      </Box>

      <Box my={2}>
        <Typography variant="bodyText2" component="span">
          Not your shop?
        </Typography>{' '}
        <Link to="#" underline="hover" color="linkText" onClick={openSearch} variant="bodyText2">
          Search again
        </Link>
      </Box>

      <CreateShopForm
        defaultValues={shopData ?? undefined}
        onSubmit={onSubmit}
        isLoading={isLoading}
        submitButtonText={submitButtonText}
        hasShop={hasShop}
      />
    </Box>
  );
};
