import { isMatchingString } from 'shared/lib/string';
import type { PartType } from './PartType';
import type { SearchEntryPointInterface } from 'features/searchForm';

export class PartSubCategory implements SearchEntryPointInterface {
  id: string;

  name: string;

  categoryId?: string;

  partTypes: PartType[];

  isDisabled: boolean | undefined;

  constructor(id: string, name: string, categoryId?: string, partTypes: PartType[] = []) {
    this.id = id;
    this.name = name;
    this.categoryId = categoryId;
    this.partTypes = partTypes;
  }

  get count(): string {
    const partTypesCount = this.getPartTypesCount();
    return partTypesCount > 99 ? '99+' : String(partTypesCount);
  }

  isVehicleRequired(): boolean {
    return true;
  }

  getClassName(): string {
    return 'fs-part-subcategory';
  }

  populateInput(): string {
    return `${this.name} - (All)`;
  }

  toString() {
    return this.name;
  }

  isMatchedToString(search: string) {
    return isMatchingString(this.name, search);
  }

  getPartTypesCount(): number {
    return this.partTypes.length;
  }

  getMatchingPartTypes(partTypes: PartType[]): PartType[] {
    return this.partTypes.filter((partType) =>
      partTypes.some((selectedPartType) => selectedPartType.id === partType.id)
    );
  }

  setIsDisabled() {
    this.isDisabled = this.partTypes.every((partType) => partType.isDisabled);
    return this;
  }
}
