import { api as generatedApi } from './GetSubscriptionUpcomingInvoice.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    GetSubscriptionUpcomingInvoice: {
      forceRefetch: ({ currentArg, previousArg }) => currentArg !== previousArg,
    },
  },
});

export const { useGetSubscriptionUpcomingInvoiceQuery } = api;
