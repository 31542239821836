/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type CreateCartMutationVariables = Types.Exact<{
  input: Types.CreateCartInput;
}>;

export type CreateCartMutation = {
  __typename?: 'Mutation';
  createCart?: { __typename?: 'CreateCartPayload'; cart?: { __typename?: 'Cart'; id: string } | null } | null;
};

export const CreateCartDocument = `
    mutation CreateCart($input: CreateCartInput!) {
  createCart(input: $input) {
    cart {
      id
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    CreateCart: build.mutation<CreateCartMutation, CreateCartMutationVariables>({
      query: (variables) => ({ document: CreateCartDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useCreateCartMutation } = injectedRtkApi;
