import { useCallback } from 'react';
import { api as activeCartApi } from 'features/cart/api/queries/GetActiveCart.generated';
import { useMutationStatus } from 'shared/api';
import { useAppDispatch } from 'store';
import { getAddToCartInput, createOrderItemData } from '../../../utils';
import { api } from './AddItemsToCart.generated';
import type { AddToCartPayload } from 'features/cart';

const { useAddItemsToCartMutation } = api;

export const useAddItemsToCart = () => {
  const dispatch = useAppDispatch();

  const [mutate, status] = useAddItemsToCartMutation();

  useMutationStatus({ status });

  const addToCart = useCallback(
    async (payloads: AddToCartPayload[]) => {
      const res = await mutate({ items: payloads.map(getAddToCartInput) });

      const { data } = res;

      data?.batchAddItemsToActiveCart?.results.forEach(({ result }, index) => {
        if ('error' in result) {
          return;
        }

        const { orderId, orderItemId } = result;
        const payload = payloads[index];

        if (!payload) {
          return;
        }

        const orderItemData = createOrderItemData(orderItemId, payload.quantity, payload.product);

        dispatch(
          activeCartApi.util.updateQueryData('GetActiveCart', undefined, (draft) => {
            const order = draft?.activeCart?.orders?.find((item) => item.id === orderId);

            if (order) {
              const orderItem = order.items?.find((item) => item.id === orderItemId);

              if (orderItem) {
                Object.assign(orderItem, { quantity: orderItem.quantity + payload.quantity });
              } else {
                order.items?.push(orderItemData);
              }
            } else {
              draft?.activeCart?.orders?.push({
                id: orderId,
                items: [orderItemData],
                account: {
                  id: String(payload.product.credentialId),
                },
              });
            }
          })
        );
      });

      return res;
    },
    [mutate, dispatch]
  );

  return [addToCart, status] as const;
};
