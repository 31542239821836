import { css } from '@emotion/react';
import { Box } from '@partstech/ui';
import { elevationShadow } from '@partstech/ui/utils';

const styles = {
  wrapper: css`
    ${elevationShadow(6)};
  `,
};

export const ScrollDivider = () => (
  <Box
    css={styles.wrapper}
    position="absolute"
    top="86px"
    left="4px"
    width="calc(100% - 8px)"
    height="6px"
    zIndex="initial"
  />
);
