import { isNotNull } from '@partstech/ui/utils';
import { useMemo } from 'react';
import { createAddressFromData } from 'entities/address';
import { ShopAddressType } from 'shared/api';
import { api as generatedApi } from './GetUserShopsWithAddresses.generated';
import type { GetUserShopsWithAddressesQuery } from './GetUserShopsWithAddresses.generated';
import type { Address } from 'entities/address';

export type UserShopsWithAddresses = {
  id: string;
  name: string;
  address: Address;
};

const api = generatedApi.enhanceEndpoints({
  addTagTypes: ['UserShopWithAddresses'],
  endpoints: {
    GetUserShopsWithAddresses: {
      providesTags: (response: GetUserShopsWithAddressesQuery | undefined) => {
        const userShopsWithAddresses =
          response?.currentUser?.shopMemberships?.map((userShopWithAddresses) => ({
            type: 'UserShopWithAddresses' as const,
            id: userShopWithAddresses.shop?.id,
          })) ?? [];

        return ['UserShopWithAddresses', ...userShopsWithAddresses];
      },
    },
  },
});

const { useGetUserShopsWithAddressesQuery } = api;

export const useGetUserShopsWithAddresses = () => {
  const { data, currentData, ...props } = useGetUserShopsWithAddressesQuery();

  const allShops = useMemo(
    () =>
      currentData?.currentUser?.shopMemberships?.map((shopMembership) => shopMembership.shop).filter(isNotNull) ?? [],
    [currentData?.currentUser?.shopMemberships]
  );

  const shops = useMemo<UserShopsWithAddresses[]>(
    () =>
      allShops
        .map((shop) => {
          const { id, name, addresses } = shop;

          const foundBillingAddress = addresses?.find((address) => address.type === ShopAddressType.Billing) ?? null;

          return foundBillingAddress ? { id, name, address: createAddressFromData(foundBillingAddress.address) } : null;
        })
        .filter(isNotNull),
    [allShops]
  );

  return { shops, ...props };
};
