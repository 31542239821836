import { api as generatedApi } from './RemovePaymentCard.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    RemovePaymentCard: {
      invalidatesTags: ['PaymentCard'],
    },
  },
});

export const { useRemovePaymentCardMutation } = api;
