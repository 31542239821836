/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import type * as Types from '../graphql';

export type CoordinatesFragment = {
  __typename?: 'Coordinates';
  longitude: Types.Scalars['Float']['input'];
  latitude: Types.Scalars['Float']['input'];
};

export const CoordinatesFragmentDoc = `
    fragment Coordinates on Coordinates {
  longitude
  latitude
}
    `;
