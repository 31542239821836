import { css } from '@emotion/react';
import { Icon, Tooltip, Typography, Box, useMedia } from '@partstech/ui';
import type { Theme } from '@partstech/ui';

type Props = {
  isPromotion?: boolean;
  points?: number | null;
};

const styles = {
  rewardPoints: (isPromotion: boolean) => (theme: Theme) => css`
    cursor: ${isPromotion ? 'pointer' : 'default'};
    vertical-align: middle;
    border-radius: 2px;
    background: ${theme.color.customColor.lightBlue};
  `,
  rewardPointsStar: (theme: Theme) => css`
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    background: ${theme.color.negative};
    border-radius: 2px 0 0 2px;
  `,
  rewardPointsPoint: (theme: Theme) => css`
    font-size: ${theme.sizing(2)};
    height: ${theme.sizing(4)};
  `,
  points: css`
    text-transform: uppercase;
  `,
};

export const RewardPoints = ({ points, isPromotion = false }: Props) => {
  const { isMobile } = useMedia();

  return points ? (
    <Tooltip
      offsetX={isMobile ? 0 : -176}
      offsetY={4}
      isHidden={!isPromotion}
      tooltip="Buy this part today to get more points than normally"
    >
      <Box
        position="relative"
        display="inline-flex"
        pr={1}
        pl={isPromotion ? 5 : 1}
        css={styles.rewardPoints(isPromotion)}
        data-testid="rewardPointsBlock"
      >
        {isPromotion && <Icon css={styles.rewardPointsStar} name="star" size="medium" color="white" />}
        <Icon css={styles.rewardPointsPoint} name="circle" color="negative" size="large" />
        <Typography css={styles.points} color="dark" variant="overline">{`${points} ${
          points > 1 ? 'points' : 'point'
        }`}</Typography>
      </Box>
    </Tooltip>
  ) : null;
};
