import { SupplierMembership } from 'models';

type SupplierMembershipPayload = {
  id: string;
  active: boolean;
  supplier?: {
    id: string;
    name: string;
  } | null;
};

export const createSupplierMembershipFromData = (membership: SupplierMembershipPayload) => {
  if (!membership.supplier) {
    return null;
  }

  // @ts-ignore Error of ts itself, Type '{ id: string; name: string; } | null | undefined' cannot be assigned to type '{ id: string; name: string; }'
  return new SupplierMembership(membership);
};
