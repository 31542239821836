import { Box, Button } from '@partstech/ui';
import { useCallback } from 'react';
import { useAppNavigate } from 'app/AppRouter/useAppNavigate';
import { getDiagramUrl } from 'utils/url/getDiagramUrl';

type Props = {
  diagramId: string | null;
  diagramCategoryId: string | null;
  vehicleId: number | null;
};

export const DiagramsButton = ({ diagramId, diagramCategoryId, vehicleId }: Props) => {
  const navigate = useAppNavigate();

  const handleClick = useCallback(() => {
    if (!vehicleId || !diagramCategoryId) {
      return;
    }

    navigate(getDiagramUrl(`${vehicleId}`, diagramCategoryId, diagramId ?? ''));
  }, [diagramCategoryId, diagramId, navigate, vehicleId]);

  if (!diagramCategoryId) {
    return null;
  }

  return (
    <Box mb={6}>
      <Button variant="secondary" onClick={handleClick} data-testid="diagramButton">
        Diagrams
      </Button>
    </Box>
  );
};
