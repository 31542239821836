import { Box, Link, Typography, useMedia } from '@partstech/ui';
import { StoreLogo } from 'components/StoreLogo';
import { StoreAddress } from '../../../../../components/StoreAddress';
import type { Store, SupplierAccount } from 'models';

type Props = {
  store: Store;
  account?: SupplierAccount | null;
};

export const StoreInfo = ({ store, account }: Props) => {
  const { isMobile } = useMedia();

  const supplier = store?.supplier ?? null;

  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      flexDirection={{ sm: 'row', md: 'column' }}
      flexWrap="nowrap"
      width={{ sm: '100%', md: 40 }}
      mb={{ sm: 6, md: 0 }}
    >
      <StoreLogo store={store} width={20} height={12} />

      <Box display="flex" flexDirection="column" flex={{ sm: '1', md: 'initial' }} ml={{ sm: 4, md: 0 }} width="100%">
        <StoreAddress store={store} account={account} size="medium" />

        <Box mt={3} data-testid="phone">
          {store?.phone && !isMobile ? (
            <Typography variant="bodyText2">{store.phone}</Typography>
          ) : (
            <Link color="linkText" isExternal to={`tel:${store?.phone ?? ''}`} type="mobile">
              {store?.phone}
            </Link>
          )}
        </Box>

        {Boolean(supplier?.isTire) && !store?.isOrderingSupported && (
          <Box mt={2}>
            <Typography variant="bodyText2" color="subtleText">
              Inventory lookup only
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};
