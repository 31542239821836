import { css } from '@emotion/react';
import { Box, Link, Status } from '@partstech/ui';
import { useCreateModal } from '@partstech/ui/hooks';
import icon from 'images/content/jobs/vehicle-specific-icon.svg';
import { IntroducingModal } from './IntroducingModal';
import type { Theme } from '@partstech/ui';

const styles = {
  label: (theme: Theme) => css`
    > div {
      background: linear-gradient(90deg, ${theme.color.primary}, ${theme.color.primaryLight10});
    }
  `,
};

export const VehicleSpecificJobsLabel = () => {
  const { open } = useCreateModal(IntroducingModal, { size: 'large' }, { customId: 'vehicleSpecificJobs' });

  return (
    <Box
      position="relative"
      height={6}
      width={71}
      mb={2}
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
      css={styles.label}
    >
      <Status variant="filled" builtIn="top-left" color="primary">
        <Box display="flex" gap={1} py={0.5} mr={1}>
          <img src={icon} alt="Vehicle specific job icon" />
          New - Vehicle specific Jobs
        </Box>
      </Status>

      <Link to="" variant="bodyText2" onClick={open}>
        Learn more
      </Link>
    </Box>
  );
};
