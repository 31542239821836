import { Typography } from '@partstech/ui';
import { HookForm } from '@partstech/ui/forms';
import { PermissionList } from './PermissionList';
import { useSetPermissionsForm } from './useSetPermissionsForm';
import type { SetPermissionsFormProps } from './useSetPermissionsForm';
import type { MemberPermissions } from 'shared/api';

type Props = {
  previewMode?: boolean;
  onSubmit?: (permissions: MemberPermissions) => Promise<void>;
} & SetPermissionsFormProps;

export const PermissionsForm = ({ defaultPermissions, previewMode, onSubmit }: Props) => {
  const { form } = useSetPermissionsForm({ defaultPermissions });

  return (
    <HookForm form={form} data-testid="permissionsForm">
      {!previewMode && (
        <Typography variant="overline" color="subtleText" textTransform="uppercase">
          User Permissions
        </Typography>
      )}

      <PermissionList onChange={onSubmit} previewMode={previewMode} />
    </HookForm>
  );
};
