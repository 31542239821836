/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';

import { MatrixFragmentDoc } from '../matrix/Matrix.generated';
import { PricePackageFragmentDoc } from '../pricePackage/PricePackage.generated';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type GetRetailPricingQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetRetailPricingQuery = {
  __typename?: 'Query';
  currentUser?: {
    __typename?: 'User';
    activeMember?: {
      __typename?: 'ShopMember';
      shop?: {
        __typename?: 'Shop';
        retailPricing?: {
          __typename?: 'RetailPricing';
          taxRate: Types.Scalars['BigDecimal']['input'];
          packages?: Array<{
            __typename?: 'Package';
            id: string;
            name: Types.Scalars['String']['input'];
            isDefault: Types.Scalars['Boolean']['input'];
            items: Array<
              | {
                  __typename: 'Addon';
                  name: Types.Scalars['String']['input'];
                  createdAt: Types.Scalars['DateTime']['input'];
                  price: Types.Scalars['BigDecimal']['input'];
                  description?: Types.Scalars['String']['input'] | null;
                  isPerTire: Types.Scalars['Boolean']['input'];
                  isApplyTax: Types.Scalars['Boolean']['input'];
                  isPercentage: Types.Scalars['Boolean']['input'];
                  addonCalculatedBy: Array<Types.AddonCalculatedBy>;
                  percentageAmount?: {
                    __typename?: 'PercentageAmount';
                    max?: Types.Scalars['BigDecimal']['input'] | null;
                    min?: Types.Scalars['BigDecimal']['input'] | null;
                    isPerQuantity: Types.Scalars['Boolean']['input'];
                  } | null;
                }
              | {
                  __typename: 'Discount';
                  name: Types.Scalars['String']['input'];
                  createdAt: Types.Scalars['DateTime']['input'];
                  price: Types.Scalars['BigDecimal']['input'];
                  description?: Types.Scalars['String']['input'] | null;
                  isPerTire: Types.Scalars['Boolean']['input'];
                  isPercentage: Types.Scalars['Boolean']['input'];
                  discountCalculatedBy: Array<Types.DiscountCalculatedBy>;
                  percentageAmount?: {
                    __typename?: 'PercentageAmount';
                    max?: Types.Scalars['BigDecimal']['input'] | null;
                    min?: Types.Scalars['BigDecimal']['input'] | null;
                    isPerQuantity: Types.Scalars['Boolean']['input'];
                  } | null;
                }
              | {
                  __typename: 'Disposal';
                  name: Types.Scalars['String']['input'];
                  createdAt: Types.Scalars['DateTime']['input'];
                  price: Types.Scalars['BigDecimal']['input'];
                  description?: Types.Scalars['String']['input'] | null;
                  isApplyTax: Types.Scalars['Boolean']['input'];
                  isPercentage: Types.Scalars['Boolean']['input'];
                  percentageAmount?: {
                    __typename?: 'PercentageAmount';
                    max?: Types.Scalars['BigDecimal']['input'] | null;
                    min?: Types.Scalars['BigDecimal']['input'] | null;
                    isPerQuantity: Types.Scalars['Boolean']['input'];
                  } | null;
                }
              | {
                  __typename: 'Fee';
                  name: Types.Scalars['String']['input'];
                  createdAt: Types.Scalars['DateTime']['input'];
                  price: Types.Scalars['BigDecimal']['input'];
                  description?: Types.Scalars['String']['input'] | null;
                  isPerTire: Types.Scalars['Boolean']['input'];
                  isApplyTax: Types.Scalars['Boolean']['input'];
                  feeCalculatedBy: Array<Types.FeeCalculatedBy>;
                  isPercentage: Types.Scalars['Boolean']['input'];
                  percentageAmount?: {
                    __typename?: 'PercentageAmount';
                    max?: Types.Scalars['BigDecimal']['input'] | null;
                    min?: Types.Scalars['BigDecimal']['input'] | null;
                    isPerQuantity: Types.Scalars['Boolean']['input'];
                  } | null;
                }
              | {
                  __typename: 'Labor';
                  name: Types.Scalars['String']['input'];
                  createdAt: Types.Scalars['DateTime']['input'];
                  price: Types.Scalars['BigDecimal']['input'];
                  description?: Types.Scalars['String']['input'] | null;
                  isPerTire: Types.Scalars['Boolean']['input'];
                  isApplyTax: Types.Scalars['Boolean']['input'];
                }
            >;
          }> | null;
          matrices?: Array<{
            __typename?: 'Matrix';
            createdAt: Types.Scalars['DateTime']['input'];
            id: string;
            name: Types.Scalars['String']['input'];
            type: Types.MatrixType;
            brands?: Array<{ __typename?: 'Brand'; id: string; name: Types.Scalars['String']['input'] }> | null;
            items: Array<{
              __typename?: 'MatrixItem';
              endPrice: Types.Scalars['BigDecimal']['input'];
              price: Types.Scalars['BigDecimal']['input'];
              startPrice: Types.Scalars['BigDecimal']['input'];
              fixed: Types.Scalars['Boolean']['input'];
            }>;
            subCategories?: Array<{
              __typename?: 'MatrixSubCategory';
              subCategory: { __typename?: 'PartSubCategory'; id: string; name: Types.Scalars['String']['input'] };
              partTypes: Array<{
                __typename?: 'PartType';
                id: string;
                application: Types.PartTypeApplication;
                name: Types.Scalars['String']['input'];
              }>;
            }> | null;
          }> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export const GetRetailPricingDocument = `
    query GetRetailPricing {
  currentUser {
    activeMember {
      shop {
        retailPricing {
          taxRate
          packages {
            ...PricePackage
          }
          matrices {
            ...Matrix
          }
        }
      }
    }
  }
}
    ${PricePackageFragmentDoc}
${MatrixFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetRetailPricing: build.query<GetRetailPricingQuery, GetRetailPricingQueryVariables | void>({
      query: (variables) => ({ document: GetRetailPricingDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetRetailPricingQuery, useLazyGetRetailPricingQuery } = injectedRtkApi;
