/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type UpdateCartItemQuantityMutationVariables = Types.Exact<{
  input: Types.UpdateCartItemQuantityInput;
}>;

export type UpdateCartItemQuantityMutation = {
  __typename?: 'Mutation';
  updateCartItemQuantity?:
    | {
        __typename?: 'UpdateCartItemQuantityErrorPayload';
        error: Types.UpdateCartItemQuantityError;
        errorMessage: Types.Scalars['String']['input'];
      }
    | {
        __typename?: 'UpdateCartItemQuantitySuccessPayload';
        cart?: { __typename?: 'Cart'; id: string; active: Types.Scalars['Boolean']['input'] } | null;
      }
    | null;
};

export const UpdateCartItemQuantityDocument = `
    mutation UpdateCartItemQuantity($input: UpdateCartItemQuantityInput!) {
  updateCartItemQuantity(input: $input) {
    ... on UpdateCartItemQuantityErrorPayload {
      error
      errorMessage
    }
    ... on UpdateCartItemQuantitySuccessPayload {
      cart {
        id
        active
      }
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    UpdateCartItemQuantity: build.mutation<UpdateCartItemQuantityMutation, UpdateCartItemQuantityMutationVariables>({
      query: (variables) => ({ document: UpdateCartItemQuantityDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUpdateCartItemQuantityMutation } = injectedRtkApi;
