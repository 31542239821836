import { css } from '@emotion/react';
import { Box, Button, ButtonGroup, Tag } from '@partstech/ui';
import { useQuestionOptions, type Option } from './useQuestionOptions';
import type { BoxProps } from '@partstech/ui';

const styles = {
  tag: css`
    width: 100%;
    span {
      flex: 1;
      text-align: center;
    }
  `,
};

type Props<T extends string | number> = BoxProps & {
  defaultOption?: Option<T>['value'];
  options: Option<T>[];
  optionMinWidth?: BoxProps['width'];
  onSelect?: (option: Option<T>['value']) => void;
  onSkip?: () => void;
};

export const QuestionOptions = <T extends string | number>({
  defaultOption,
  options,
  optionMinWidth,
  onSelect,
  onSkip,
  ...props
}: Props<T>) => {
  const { selectedOption, selectOption } = useQuestionOptions({ defaultOption, onSelect });

  return (
    <>
      <Box mt={6} display="flex" flexWrap="wrap" {...props}>
        {options.map((option) => (
          <Box key={option.value} minWidth={optionMinWidth}>
            <Tag
              css={styles.tag}
              checked={option.value === selectedOption}
              onChange={selectOption(option.value)}
              controlled
            >
              {option.text}
            </Tag>
          </Box>
        ))}
      </Box>

      {onSkip && (
        <ButtonGroup mt={7} justifyContent={{ sm: 'center', md: 'flex-start' }}>
          <Button variant="text" onClick={onSkip}>
            Skip
          </Button>
        </ButtonGroup>
      )}
    </>
  );
};
