import type { VehicleSelectorValue } from '../types';
import type { LicensePlate } from 'shared/api';

export const isLicensePlate = (value?: VehicleSelectorValue): value is Partial<LicensePlate> =>
  Boolean(
    value &&
      typeof value === 'object' &&
      'plate' in value &&
      'state' in value &&
      value.plate &&
      value.state &&
      Object.keys(value).length === 2
  );
