import { entries, isNotNull } from '@partstech/ui/utils';
import { useCallback, useMemo } from 'react';
import { CATALOG_GROUP } from 'constant';
import { isSearchRequestConvertable, isSearchCatalogRequestConvertable } from 'features/searchForm';
import { useHasCatalog } from '../search/useHasCatalog';
import { useSearchParams } from '../search/useSearchParams';
import { useSupplierAccounts } from '../supplierAccounts/useSupplierAccounts';

export const useSearchEntryRequests = () => {
  const { isTiresSearch, searchEntry, valuableParams } = useSearchParams();

  const hasCatalog = useHasCatalog();
  const { accountIds } = useSupplierAccounts({ type: isTiresSearch ? 'tires' : 'parts' });

  const attributes = useMemo(() => {
    if (!valuableParams.attributes) {
      return undefined;
    }

    return entries(valuableParams.attributes).map(([name, values]) => ({
      name,
      values,
    }));
  }, [valuableParams.attributes]);

  const getSearchEntryRequestByAccountId = useCallback(
    (accountId: string) => {
      if (!searchEntry || (searchEntry && !isSearchRequestConvertable(searchEntry))) {
        return null;
      }

      return {
        accountId: String(accountId),
        searchInput: searchEntry?.toRequest({
          accountId: String(accountId),
          attributes,
          universal: !valuableParams.fit?.[0],
          vehicleId: valuableParams.vehicle,
          vin: valuableParams?.vin,
          jobId: valuableParams?.job_id,
        }),
      };
    },
    [attributes, searchEntry, valuableParams.fit, valuableParams?.job_id, valuableParams.vehicle, valuableParams?.vin]
  );

  const getSearchEntryRequests = useCallback(() => {
    try {
      return accountIds.map(getSearchEntryRequestByAccountId).filter(isNotNull);
    } catch (e) {
      return [];
    }
  }, [accountIds, getSearchEntryRequestByAccountId]);

  const getCatalogSearchEntryRequest = useCallback(() => {
    if (!hasCatalog) {
      return null;
    }

    if (!searchEntry || (searchEntry && !isSearchCatalogRequestConvertable(searchEntry))) {
      return null;
    }

    try {
      return {
        accountId: CATALOG_GROUP,
        searchInput: searchEntry?.toCatalogRequest({
          attributes,
          fit: Boolean(valuableParams.fit),
          vehicleId: valuableParams.vehicle,
          vin: valuableParams?.vin,
        }),
      };
    } catch (e) {
      return null;
    }
  }, [attributes, hasCatalog, searchEntry, valuableParams.fit, valuableParams.vehicle, valuableParams?.vin]);

  return { getSearchEntryRequests, getCatalogSearchEntryRequest, getSearchEntryRequestByAccountId };
};
