import { entries } from '@partstech/ui/utils';
import { parseQuery } from 'shared/lib/query/parseQuery';
import type { SearchParams } from 'types/search';

export const decodeSearchParams = (search: string): SearchParams => {
  const query = parseQuery(search, { plainObjects: true });

  return entries(query).reduce((memo, [name, value]) => {
    const clearName = name.replace(/\[\d+]/, '');
    // @ts-ignore
    const existingValue = memo[clearName];

    let newValue;
    if ((name.search(/\[\d*]/) !== -1 && !existingValue) || existingValue) {
      if (!existingValue) {
        newValue = [value];
      } else {
        newValue = [...existingValue, value];
      }
    } else {
      newValue = value;
    }

    return {
      ...memo,
      [clearName]: newValue,
    };
  }, {});
};
