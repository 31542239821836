/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type SetShowRetailPricingMutationVariables = Types.Exact<{
  input: Types.SetShowRetailPricingInput;
}>;

export type SetShowRetailPricingMutation = {
  __typename?: 'Mutation';
  setShowRetailPricing?: {
    __typename: 'SetShowRetailPricingPayload';
    shop?: { __typename?: 'Shop'; showRetailPrice: Types.Scalars['Boolean']['input'] } | null;
  } | null;
};

export const SetShowRetailPricingDocument = `
    mutation SetShowRetailPricing($input: SetShowRetailPricingInput!) {
  setShowRetailPricing(input: $input) {
    __typename
    shop {
      showRetailPrice
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    SetShowRetailPricing: build.mutation<SetShowRetailPricingMutation, SetShowRetailPricingMutationVariables>({
      query: (variables) => ({ document: SetShowRetailPricingDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useSetShowRetailPricingMutation } = injectedRtkApi;
