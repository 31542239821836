import axios from 'axios';
import { client } from '../client';

const config = { headers: { 'Content-Type': 'multipart/form-data' } };

export type CreateReportRequest = {
  image: File | null;
  case: {
    shopId: number;
    userId: number;
    typeSubmissions: string;
    vehicle: string;
    vehicleId: string;
    supplierSubmittedFrom: string;
    supplierId: number;
    storeId: number;
    resultsPage: string;
    partDetails: string;
    partNumber: string;
    brand: string;
    notes?: string;
    searchUrl?: string;
    fullStory?: string;
  };
};

export function createReport(params: CreateReportRequest): Promise<void> {
  const formData = new FormData();

  formData.append('image', params.image !== null ? params.image : 'null');
  formData.append('case', JSON.stringify(params.case));

  return client.post('/api/support/search-result', formData, config);
}

export function getDiagramImage(url: string): Promise<{ data: string }> {
  return axios.get(url);
}
