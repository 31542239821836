import { isNotNull } from '@partstech/ui/utils';
import { useCallback, useRef } from 'react';
import { useMutationStatus } from 'shared/api';
import { useChangeSupplierAccountPrioritiesMutation } from 'store/mutations/currentUser/supplierAccounts/changePriorities';
import type { AccountPriority } from 'shared/api';

type ChangeSupplierAccountPrioritiesReturnType = ReturnType<
  ReturnType<typeof useChangeSupplierAccountPrioritiesMutation>[0]
>;

export const useChangeSupplierAccountPriorities = () => {
  const changePriorityRef = useRef<ChangeSupplierAccountPrioritiesReturnType | null>(null);

  const [changeSupplierAccountPriorities, status] = useChangeSupplierAccountPrioritiesMutation();

  const changePriorities = useCallback(
    (ids: string[]) => {
      if (isNotNull(changePriorityRef.current)) {
        changePriorityRef.current.abort();
      }

      const accountPriorities = ids.reduce<AccountPriority[]>(
        (acc, accountId, index) => [...acc, { accountId, priority: index + 1 }],
        []
      );

      changePriorityRef.current = changeSupplierAccountPriorities({ input: { accountPriorities } });
    },
    [changeSupplierAccountPriorities]
  );

  useMutationStatus({ status });

  return [changePriorities, status] as const;
};
