/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../../../shared/api/graphql/graphql';

export type CreateTaxRateMutationVariables = Types.Exact<{
  input: Types.CreateRetailPricingInput;
}>;

export type CreateTaxRateMutation = {
  __typename?: 'Mutation';
  createRetailPricing?: {
    __typename?: 'CreateRetailPricingPayload';
    retailPricing?: { __typename?: 'RetailPricing'; taxRate: Types.Scalars['BigDecimal']['input'] } | null;
  } | null;
};

export const CreateTaxRateDocument = `
    mutation CreateTaxRate($input: CreateRetailPricingInput!) {
  createRetailPricing(input: $input) {
    retailPricing {
      taxRate
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    CreateTaxRate: build.mutation<CreateTaxRateMutation, CreateTaxRateMutationVariables>({
      query: (variables) => ({ document: CreateTaxRateDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useCreateTaxRateMutation } = injectedRtkApi;
