import { useCallback } from 'react';
import { api as activeCartApi } from 'features/cart/api/queries/GetActiveCart.generated';
import { useMutationStatus } from 'shared/api';
import { useAppDispatch } from 'store';
import { api as generatedApi } from './UpdatePreferredShippingMethod.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    UpdatePreferredShippingMethod: {
      invalidatesTags: ['Shipment'],
    },
  },
});

const { useUpdatePreferredShippingMethodMutation } = api;

export const useUpdatePreferredShippingMethod = () => {
  const dispatch = useAppDispatch();
  const [mutate, status] = useUpdatePreferredShippingMethodMutation();

  const updatePreferredShippingMethod = useCallback(
    async (shippingMethodCode: string, supplierId: string) => {
      const updatedCart = dispatch(
        activeCartApi.util.updateQueryData('GetActiveCart', undefined, (draft) => {
          draft?.activeCart?.orders?.forEach((order) => {
            if (order.account?.supplier?.id === supplierId) {
              Object.assign(order, { optionsPreferences: { shippingMethodCode } });
            }
          });
        })
      );

      const { data } = await mutate({ shippingMethodCode, supplierId });

      if (!data?.updateCartSupplierPreferredShippingMethod?.optionsPreferences) {
        updatedCart.undo();
      }
    },
    [dispatch, mutate]
  );

  useMutationStatus({ status });

  return [updatePreferredShippingMethod, status] as const;
};
