/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type ChangePaymentCardMutationVariables = Types.Exact<{
  input: Types.ChangePaymentMethodInput;
}>;

export type ChangePaymentCardMutation = {
  __typename?: 'Mutation';
  changePaymentMethod?: { __typename: 'ChangePaymentMethodPayload' } | null;
};

export const ChangePaymentCardDocument = `
    mutation ChangePaymentCard($input: ChangePaymentMethodInput!) {
  changePaymentMethod(input: $input) {
    __typename
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    ChangePaymentCard: build.mutation<ChangePaymentCardMutation, ChangePaymentCardMutationVariables>({
      query: (variables) => ({ document: ChangePaymentCardDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useChangePaymentCardMutation } = injectedRtkApi;
