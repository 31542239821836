/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import { PricePackageFragmentDoc } from '../../../../../queries/currentUser/retailPricing/pricePackage/PricePackage.generated';
import type * as Types from '../../../../../../shared/api/graphql/graphql';

export type UpdatePricePackageMutationVariables = Types.Exact<{
  input: Types.UpdatePackageInput;
}>;

export type UpdatePricePackageMutation = {
  __typename?: 'Mutation';
  updatePackage?: {
    __typename?: 'UpdatePackagePayload';
    package?: {
      __typename?: 'Package';
      id: string;
      name: Types.Scalars['String']['input'];
      isDefault: Types.Scalars['Boolean']['input'];
      items: Array<
        | {
            __typename: 'Addon';
            name: Types.Scalars['String']['input'];
            createdAt: Types.Scalars['DateTime']['input'];
            price: Types.Scalars['BigDecimal']['input'];
            description?: Types.Scalars['String']['input'] | null;
            isPerTire: Types.Scalars['Boolean']['input'];
            isApplyTax: Types.Scalars['Boolean']['input'];
            isPercentage: Types.Scalars['Boolean']['input'];
            addonCalculatedBy: Array<Types.AddonCalculatedBy>;
            percentageAmount?: {
              __typename?: 'PercentageAmount';
              max?: Types.Scalars['BigDecimal']['input'] | null;
              min?: Types.Scalars['BigDecimal']['input'] | null;
              isPerQuantity: Types.Scalars['Boolean']['input'];
            } | null;
          }
        | {
            __typename: 'Discount';
            name: Types.Scalars['String']['input'];
            createdAt: Types.Scalars['DateTime']['input'];
            price: Types.Scalars['BigDecimal']['input'];
            description?: Types.Scalars['String']['input'] | null;
            isPerTire: Types.Scalars['Boolean']['input'];
            isPercentage: Types.Scalars['Boolean']['input'];
            discountCalculatedBy: Array<Types.DiscountCalculatedBy>;
            percentageAmount?: {
              __typename?: 'PercentageAmount';
              max?: Types.Scalars['BigDecimal']['input'] | null;
              min?: Types.Scalars['BigDecimal']['input'] | null;
              isPerQuantity: Types.Scalars['Boolean']['input'];
            } | null;
          }
        | {
            __typename: 'Disposal';
            name: Types.Scalars['String']['input'];
            createdAt: Types.Scalars['DateTime']['input'];
            price: Types.Scalars['BigDecimal']['input'];
            description?: Types.Scalars['String']['input'] | null;
            isApplyTax: Types.Scalars['Boolean']['input'];
            isPercentage: Types.Scalars['Boolean']['input'];
            percentageAmount?: {
              __typename?: 'PercentageAmount';
              max?: Types.Scalars['BigDecimal']['input'] | null;
              min?: Types.Scalars['BigDecimal']['input'] | null;
              isPerQuantity: Types.Scalars['Boolean']['input'];
            } | null;
          }
        | {
            __typename: 'Fee';
            name: Types.Scalars['String']['input'];
            createdAt: Types.Scalars['DateTime']['input'];
            price: Types.Scalars['BigDecimal']['input'];
            description?: Types.Scalars['String']['input'] | null;
            isPerTire: Types.Scalars['Boolean']['input'];
            isApplyTax: Types.Scalars['Boolean']['input'];
            feeCalculatedBy: Array<Types.FeeCalculatedBy>;
            isPercentage: Types.Scalars['Boolean']['input'];
            percentageAmount?: {
              __typename?: 'PercentageAmount';
              max?: Types.Scalars['BigDecimal']['input'] | null;
              min?: Types.Scalars['BigDecimal']['input'] | null;
              isPerQuantity: Types.Scalars['Boolean']['input'];
            } | null;
          }
        | {
            __typename: 'Labor';
            name: Types.Scalars['String']['input'];
            createdAt: Types.Scalars['DateTime']['input'];
            price: Types.Scalars['BigDecimal']['input'];
            description?: Types.Scalars['String']['input'] | null;
            isPerTire: Types.Scalars['Boolean']['input'];
            isApplyTax: Types.Scalars['Boolean']['input'];
          }
      >;
    } | null;
  } | null;
};

export const UpdatePricePackageDocument = `
    mutation UpdatePricePackage($input: UpdatePackageInput!) {
  updatePackage(input: $input) {
    package {
      ...PricePackage
    }
  }
}
    ${PricePackageFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    UpdatePricePackage: build.mutation<UpdatePricePackageMutation, UpdatePricePackageMutationVariables>({
      query: (variables) => ({ document: UpdatePricePackageDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUpdatePricePackageMutation } = injectedRtkApi;
