/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type UpdateUserPhoneNumberMutationVariables = Types.Exact<{
  input: Types.UpdateUserPhoneNumberInput;
}>;

export type UpdateUserPhoneNumberMutation = {
  __typename?: 'Mutation';
  updateUserPhoneNumber?: {
    __typename?: 'UpdateUserPhoneNumberPayload';
    user?: {
      __typename?: 'User';
      phoneType: Types.UserPhoneType;
      phone?: Types.Scalars['String']['input'] | null;
    } | null;
  } | null;
};

export const UpdateUserPhoneNumberDocument = `
    mutation UpdateUserPhoneNumber($input: UpdateUserPhoneNumberInput!) {
  updateUserPhoneNumber(input: $input) {
    user {
      phoneType
      phone
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    UpdateUserPhoneNumber: build.mutation<UpdateUserPhoneNumberMutation, UpdateUserPhoneNumberMutationVariables>({
      query: (variables) => ({ document: UpdateUserPhoneNumberDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUpdateUserPhoneNumberMutation } = injectedRtkApi;
