import { useElements, useStripe } from '@stripe/react-stripe-js';
import { useCallback, useState } from 'react';
import { CARD_NUMBER_FIELD_NAME } from '../constant';
import type { StripeError } from '@stripe/stripe-js';

type Error = StripeError | null;

export const useCreateStripeToken = () => {
  const stripe = useStripe();
  const elements = useElements();

  const [error, setError] = useState<Error>(null);

  const createStripeToken = useCallback(
    async (name: string) => {
      if (!stripe || !elements) {
        return null;
      }

      const cardNumberElement = elements?.getElement(CARD_NUMBER_FIELD_NAME);

      if (!cardNumberElement) {
        return null;
      }

      const result = await stripe?.createToken(cardNumberElement, { name });

      if (result?.error?.message) {
        setError(result.error);
        return null;
      }

      setError(null);
      return result.token ?? null;
    },
    [elements, stripe]
  );

  return [createStripeToken, { error }] as const;
};
