import { useMedia } from '@partstech/ui';
import { OptionsList } from '../../OptionsList';
import { useParametersList } from '../useParametersList';
import { useGetModels } from './useGetModels';
import type { CommonParametersListProps } from '../types';

export const ModelsList = ({
  modelId: selectedValue,
  onChange,
  searchValue,
  isUserFocused,
  ...props
}: CommonParametersListProps) => {
  const { isMobile } = useMedia();

  const { models } = useGetModels({ searchValue, ...props });
  const { updateValue } = useParametersList({
    fieldName: 'modelId',
    options: models,
    onChange,
    isUserFocused,
    searchValue,
    selectedValue,
  });

  return <OptionsList options={models} onClick={updateValue} columnCount={isMobile ? 1 : undefined} />;
};
