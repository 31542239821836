import { AccountStatus } from 'shared/api';

export const WARNING_STATUS: Record<AccountStatus, string> = {
  [AccountStatus.Processing]: 'Processing',
  [AccountStatus.Approved]: 'Approved',
  [AccountStatus.NotApproved]: 'Not Approved',
  [AccountStatus.HelpRequested]: 'Help Requested',
  [AccountStatus.ReadyForReview]: 'Ready For Review',
  [AccountStatus.PartstechHelpRequested]: 'Processing',
  [AccountStatus.SupplierApprovalNeeded]: 'Supplier Approval Needed',
  [AccountStatus.Pending]: 'Pending',
  [AccountStatus.PaymentPending]: 'Awaiting Payment',
};

export const CHECK_STATUS_REQUEST_LIMIT = 15;

export const DEFAULT_MAP_ZOOM = 9;
