import { useSnackbar, Button, ImageUploader, ButtonGroup } from '@partstech/ui';
import { useCallback } from 'react';
import { useRemoveUserAvatar } from 'store/mutations/currentUser/removeUserAvatar';
import { useUploadUserAvatarMutation } from 'store/mutations/currentUser/uploadUserAvatar';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';

type Props = {
  onClose: () => void;
};

export const Picture = ({ onClose }: Props) => {
  const [uploadUserAvatar, { isLoading: isLoadingUploadUserAvatar }] = useUploadUserAvatarMutation();
  const [removeUserAvatar, { isLoading: isLoadingRemoveUserAvatar }] = useRemoveUserAvatar();

  const { addSnackbar } = useSnackbar();

  const { avatar } = useGetCurrentUser();

  const addErrorSnackbar = useCallback(
    (errorMessage: string) => {
      addSnackbar({ label: errorMessage });
    },
    [addSnackbar]
  );

  const handleUploadUserAvatar = useCallback(
    async (file: File, editFile?: (item: File | null) => void) => {
      try {
        const formData = new FormData();
        formData.append('avatar', file);

        await uploadUserAvatar(formData).unwrap();

        editFile && editFile(file);
      } catch (e) {
        addErrorSnackbar(e.error().message ?? 'Something went wrong');
      }
    },
    [addErrorSnackbar, uploadUserAvatar]
  );

  const handleDeleteLogo = useCallback(async () => {
    await removeUserAvatar();
  }, [removeUserAvatar]);

  return (
    <div data-testid="pictureForm">
      <ImageUploader
        fileType="image"
        isLoading={isLoadingRemoveUserAvatar || isLoadingUploadUserAvatar}
        onAdd={handleUploadUserAvatar}
        onDelete={handleDeleteLogo}
        onError={addErrorSnackbar}
        previewFile={avatar}
        hint="Updates will be applied to your profile across all PartsTech apps."
      />

      <ButtonGroup mt={6}>
        <Button fullWidth variant="text" leadingIcon="arrow_back" onClick={onClose} data-testid="cancelButton">
          Back to my profile
        </Button>
      </ButtonGroup>
    </div>
  );
};
