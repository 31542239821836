/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type UpdatePreferredShippingMethodMutationVariables = Types.Exact<{
  shippingMethodCode: Types.Scalars['String']['input'];
  supplierId: Types.Scalars['ID']['input'];
}>;

export type UpdatePreferredShippingMethodMutation = {
  __typename?: 'Mutation';
  updateCartSupplierPreferredShippingMethod?: {
    __typename?: 'UpdateCartSupplierPreferredShippingMethodPayload';
    optionsPreferences: {
      __typename?: 'SupplierOptionsPreferences';
      shippingMethodCode?: Types.Scalars['String']['input'] | null;
    };
  } | null;
};

export const UpdatePreferredShippingMethodDocument = `
    mutation UpdatePreferredShippingMethod($shippingMethodCode: String!, $supplierId: ID!) {
  updateCartSupplierPreferredShippingMethod(
    input: {shippingMethodCode: $shippingMethodCode, supplierID: $supplierId}
  ) {
    optionsPreferences {
      shippingMethodCode
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    UpdatePreferredShippingMethod: build.mutation<
      UpdatePreferredShippingMethodMutation,
      UpdatePreferredShippingMethodMutationVariables
    >({
      query: (variables) => ({ document: UpdatePreferredShippingMethodDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUpdatePreferredShippingMethodMutation } = injectedRtkApi;
