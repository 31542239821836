import { PartTypeApplication } from 'shared/api';
import { PartCategory } from '../../models/PartCategory';
import { PartType } from '../../models/PartType';
import { PartTypeAlias } from '../../models/PartTypeAlias';
import { UniversalPartType } from '../../models/UniversalPartType';
import { UniversalPartTypeAlias } from '../../models/UniversalPartTypeAlias';
import type { Attribute } from '../../models/PartType';
import type { FullPartTypeFragment } from 'store/queries/partTypes/FullPartType.generated';

const sortAttributesByPriority = (attributes: Attribute[] | null): Attribute[] | null => {
  if (!attributes) {
    return null;
  }

  return [...attributes].sort((a, b) => a.priority - b.priority);
};

const createPartType = (data: FullPartTypeFragment, alias?: string, search?: string) => {
  const partType = {
    ...data,
    attributes: data.attributes ? sortAttributesByPriority([...data.attributes]) : undefined,
  };

  if (partType.application !== PartTypeApplication.Fitted) {
    const universalPartType = alias ? new UniversalPartTypeAlias(partType, alias) : new UniversalPartType(partType);

    const attribute = universalPartType.findAttributeByValue(search ?? '');
    const value = universalPartType.findValueInAttribute(search ?? '');

    if (attribute && value) {
      universalPartType.setSelectedAttributes({
        [attribute.name]: [value],
      });
    }

    return universalPartType;
  }

  return alias ? new PartTypeAlias(partType, alias) : new PartType(partType);
};

export const createPartTypeFromQuery = (data: FullPartTypeFragment, alias?: string, search?: string) => {
  const partType = createPartType(data, alias, search);

  if (data.category) {
    const { id, name } = data.category;

    partType.setCategory(new PartCategory(id, name, []));
  }

  if (data.subCategory) {
    partType.setSubCategoryId(data.subCategory.id);
  }

  return partType;
};
