import { api as generatedApi } from 'store/queries/appConfig/GetAppConfig.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    GetAppConfig: {
      providesTags: () => ['AppConfig'],
    },
  },
});

const { useGetAppConfigQuery } = api;

type Params = Partial<{
  skip: boolean;
}>;

export const useGetAppConfig = ({ skip = false }: Params = {}) => {
  const { data, ...props } = useGetAppConfigQuery({}, { skip });

  const reCaptchaKey = data?.appConfig?.reCaptchaKey ?? '';
  const stripeKey = data?.appConfig?.stripeKey ?? '';
  const googleClientOAuthId = data?.appConfig?.googleOauthClientId ?? '';

  return { reCaptchaKey, stripeKey, googleClientOAuthId, ...props };
};
