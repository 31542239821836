import { GraphicTab, Typography, useMedia } from '@partstech/ui';
import { useOpen } from '@partstech/ui/hooks';
import { isEmpty } from '@partstech/ui/utils';
import classNames from 'classnames';
import { memo, useCallback, useMemo, useRef, useState } from 'react';
import { useSearchError } from 'hooks/searchResults';
import { SupplierInformationFlyout } from '../SupplierInformationFlyout';
import { SupplierInformationFlyoutMobile } from '../SupplierInformationFlyoutMobile';
import { SupplierStatus } from './SupplierStatus';
import type { GraphicTabProps } from '@partstech/ui';
import type { SupplierAccount } from 'models';
import type { FunctionComponent } from 'react';

type Props = {
  id: string;
  isLoading: boolean;
  isSelected: boolean;
  supplierAccount: SupplierAccount | null;
  total: number;
  isDisabled?: boolean;
  isStickyTab?: boolean;
  hideTotal?: boolean;
} & GraphicTabProps<string>;

export const SupplierTab: FunctionComponent<Props> = memo(
  ({
    isLoading,
    isSelected,
    supplierAccount,
    total,
    isDisabled,
    isStickyTab = false,
    isActive = false,
    hideTotal = false,
    ...props
  }) => {
    const { isMobile } = useMedia();

    const { supplier, store } = supplierAccount ?? {};

    const { isOpen: isShowSupplierInfo, open: handleOpenSupplierInfo, close: handleCloseSupplierInfo } = useOpen();
    const {
      isOpen: isShowSupplierInfoMobile,
      open: handleOpenSupplierInfoMobile,
      close: handleCloseSupplierInfoMobile,
    } = useOpen();

    const [rect, setRect] = useState<{ left: number; top: number } | null>(null);

    const { errors } = useSearchError({ supplierAccountId: supplierAccount?.id });

    const ref = useRef<HTMLDivElement | null>(null);

    const supplierLogo = useMemo(
      () =>
        supplierAccount
          ? {
              src: store?.logo ?? '',
              alt: `${supplier?.name} logo`,
            }
          : undefined,
      [store?.logo, supplier?.name, supplierAccount]
    );

    const handleSupplierInfoClick = useCallback(() => {
      if (ref.current) {
        const { left, top } = ref.current.getBoundingClientRect();

        setRect({ left, top });
      }

      if (isMobile) {
        handleOpenSupplierInfoMobile();
        return;
      }

      handleOpenSupplierInfo();
    }, [handleOpenSupplierInfo, handleOpenSupplierInfoMobile, isMobile]);

    return (
      <div ref={ref}>
        <GraphicTab
          data-testid="supplier"
          variant={isStickyTab ? 'sticky' : 'default'}
          size={isStickyTab ? 'sticky' : 'small'}
          isLoading={isLoading}
          className={classNames(supplier?.getNameAlias(), {
            aftermarket_supplier: supplier?.isAfterMarket,
            dealer_supplier: supplier?.isDealer,
            tires_supplier: supplier?.isTire,
          })}
          forceStatus
          status={
            supplierAccount && (
              <SupplierStatus
                supplierAccount={supplierAccount}
                total={total}
                hideTotal={hideTotal}
                isStickyTab={isStickyTab}
                isActive={isActive}
                hasSupplierErrors={!isEmpty(errors)}
              />
            )
          }
          featured={!isStickyTab && supplierAccount?.isFirstCall ? { tooltipText: 'First call' } : undefined}
          isDisabled={isDisabled}
          isActive={isActive}
          image={supplierLogo}
          onInfoClick={supplierAccount ? handleSupplierInfoClick : undefined}
          {...props}
        >
          <Typography
            variant="caption"
            color={isActive ? 'defaultText' : 'subtleText'}
            data-testid="caption"
            className={isActive ? 'active' : ''}
          >
            {supplierAccount?.name ?? ''}
          </Typography>
        </GraphicTab>

        {supplierAccount && isShowSupplierInfo && rect && !isMobile && (
          <SupplierInformationFlyout supplierAccount={supplierAccount} onClose={handleCloseSupplierInfo} rect={rect} />
        )}

        {supplierAccount && isMobile && isShowSupplierInfoMobile && (
          <SupplierInformationFlyoutMobile supplierAccount={supplierAccount} onClose={handleCloseSupplierInfoMobile} />
        )}
      </div>
    );
  }
);
