/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../shared/api/graphql/graphql';

export type CreateUserByGoogleOneTapMutationVariables = Types.Exact<{
  input: Types.CreateUserByGoogleOneTapInput;
}>;

export type CreateUserByGoogleOneTapMutation = {
  __typename?: 'Mutation';
  createUser?: { __typename?: 'CreateUserPayload'; accountStatus?: Types.UserAccountStatus | null } | null;
};

export const CreateUserByGoogleOneTapDocument = `
    mutation CreateUserByGoogleOneTap($input: CreateUserByGoogleOneTapInput!) {
  createUser(input: {googleOneTap: $input}) {
    accountStatus
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    CreateUserByGoogleOneTap: build.mutation<
      CreateUserByGoogleOneTapMutation,
      CreateUserByGoogleOneTapMutationVariables
    >({
      query: (variables) => ({ document: CreateUserByGoogleOneTapDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useCreateUserByGoogleOneTapMutation } = injectedRtkApi;
