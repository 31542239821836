import { css } from '@emotion/react';
import { WithClickAway, Card, Box, Button } from '@partstech/ui';
import { useOpen } from '@partstech/ui/hooks';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import type { Theme } from '@partstech/ui';
import type { MouseEvent, ChangeEvent } from 'react';

const styles = {
  quantitySelectorField: (isInputMode?: boolean) => (theme: Theme) => css`
    margin: ${theme.sizing(0, 1, 0, 0)};
    padding: ${theme.sizing(0, 0, 0, 1)};
    outline: none;
    border-radius: 2px;
    border: 1px solid ${theme.color.mono17};
    background: ${theme.color.white};
    cursor: pointer;
    display: flex;
    align-items: center;
    height: ${theme.sizing(6)};
    justify-content: flex-end;

    span {
      margin: 0;
      min-width: ${theme.sizing(2)};
      line-height: 18px;
    }

    ${isInputMode &&
    `
        width: ${theme.sizing(9)};
        padding: ${theme.sizing(1)};
      `}
  `,
};

const QUANTITIES = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

type Props = {
  quantity: number;
  onChange: (quantity: number) => void;
  disabled?: boolean;
};

export const QuantitySelector = memo(({ quantity, onChange, disabled = false }: Props) => {
  const quantitySelectorFieldRef = useRef<HTMLInputElement | null>(null);

  const [currentQuantity, setCurrentQuantity] = useState<number>(quantity);
  const [isInputMode, setInputMode] = useState(false);
  const [quantityPopupOffsetY, setQuantityPopupOffsetY] = useState(0);

  const { isOpen: isQuantityPopupOpen, open: handleQuantityPopupOpen, close: handleQuantityPopupClose } = useOpen();

  const handleQuantityPopupClick = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      handleQuantityPopupOpen();
    },
    [handleQuantityPopupOpen]
  );

  const handleSelectQuantity = useCallback(
    (qty: number) => (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      handleQuantityPopupClose();

      if (qty === 10) {
        qty === 10 && setInputMode(true);
      } else {
        setCurrentQuantity(qty);
        onChange(qty);
      }
    },
    [handleQuantityPopupClose, onChange]
  );

  const handleBlurQuantity = useCallback(() => {
    setInputMode(false);

    onChange(currentQuantity);
  }, [currentQuantity, onChange]);

  const handleKeyPressQuantity = useCallback(
    (e: React.KeyboardEvent) => {
      e.key === 'Enter' && handleBlurQuantity();
    },
    [handleBlurQuantity]
  );

  const handleChangeQuantity = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const qty = parseInt(e.target.value, 10);

    setCurrentQuantity(!qty ? 1 : qty);
  }, []);

  useEffect(() => {
    setCurrentQuantity(quantity);
  }, [quantity]);

  useEffect(() => {
    isInputMode && quantitySelectorFieldRef.current && quantitySelectorFieldRef.current.focus();
  }, [isInputMode]);

  const onClickAway = useCallback(() => {
    if (isQuantityPopupOpen) {
      handleQuantityPopupClose();
    }

    if (quantityPopupOffsetY) {
      setQuantityPopupOffsetY(0);
    }
  }, [handleQuantityPopupClose, isQuantityPopupOpen, quantityPopupOffsetY]);

  return (
    <Box position="relative" data-testid="quantitySelector">
      {isInputMode ? (
        <input
          css={styles.quantitySelectorField(isInputMode)}
          maxLength={3}
          ref={quantitySelectorFieldRef}
          type="text"
          onBlur={handleBlurQuantity}
          onChange={handleChangeQuantity}
          onKeyPress={handleKeyPressQuantity}
          data-testid="quantitySelectorInput"
        />
      ) : (
        <Button
          css={styles.quantitySelectorField()}
          onClick={handleQuantityPopupClick}
          iconSize="medium"
          trailingIcon="arrow_drop_down"
          variant="light"
          data-testid="quantitySelectorButton"
          disabled={disabled}
        >
          {String(currentQuantity)}
        </Button>
      )}
      {isQuantityPopupOpen && (
        <WithClickAway onClickAway={onClickAway}>
          <Card zIndex="popover" position="absolute" top={-20} right={2} radius={4} elevation={8}>
            <Box display="flex" flexDirection="column" width={14}>
              {QUANTITIES.map((qty) => (
                <Button key={qty} onClick={handleSelectQuantity(qty)} variant="text" fill>
                  {qty}
                  {qty === 10 && '+'}
                </Button>
              ))}
            </Box>
          </Card>
        </WithClickAway>
      )}
    </Box>
  );
});
