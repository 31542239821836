import { Box, Card, Icon, Typography } from '@partstech/ui';
import { useMemo } from 'react';
import { usePlans } from '../../hooks/usePlans';
import type { ProductAlias } from '../../constants/products';
import type { CardProps } from '@partstech/ui';

type Props = CardProps & {
  planAliasAfterDowngrading?: ProductAlias;
  title: string;
};

export const LoseFeaturesCard = ({ planAliasAfterDowngrading, title, ...props }: Props) => {
  const { getLosingFeaturesOnDowngrade } = usePlans();

  const losingFeaturesOnDowngrade = useMemo(
    () => getLosingFeaturesOnDowngrade(planAliasAfterDowngrading),
    [planAliasAfterDowngrading, getLosingFeaturesOnDowngrade]
  );

  if (losingFeaturesOnDowngrade?.length === 0) {
    return null;
  }

  return (
    <Card p={5} data-testid="loseFeaturesCard" {...props}>
      <Box mb={4}>
        <Typography variant="bodyText2">{title}</Typography>
      </Box>

      {losingFeaturesOnDowngrade?.map((loseFeature, index) => (
        <Box key={index} mt={2} display="flex" alignItems="center" gap={2} data-testid="loseFeature">
          <Icon name="cancel" color="negative" size="large" />

          <Typography variant="subtitle2">{loseFeature.name}</Typography>
        </Box>
      ))}
    </Card>
  );
};
