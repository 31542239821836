import { css } from '@emotion/react';
import { Banner, Box, Button, Icon, useMedia } from '@partstech/ui';
import { FormTextField, HookForm, useForm } from '@partstech/ui/forms';
import { usePrevious, useToggle } from '@partstech/ui/hooks';
import { useCallback, useEffect, useRef } from 'react';
import { PLATE_ERROR } from 'constant';
import { useGetStatesByCountry } from 'entities/address';
import { Country } from 'shared/api';
import { useVehicleSearch } from '../../hooks/useVehicleSearch';
import { OptionsList } from '../OptionsList';
import { VehiclesList } from '../VehiclesList';
import type { Option } from '../OptionsList/Options';
import type { Theme, BoxProps } from '@partstech/ui';
import type { Vehicle } from 'models';
import type { LicensePlate } from 'shared/api';

type Props = {
  isActive?: boolean;
  defaultValues?: Partial<LicensePlate>;
  vehicle?: Vehicle | null;
  onChange?: (vehicle: Vehicle | null) => void;
  isUSCountry?: boolean;
} & BoxProps;

const mobilePadding = 4;

const styles = {
  container: (theme: Theme) => css`
    display: flex;
    align-items: flex-start;
    gap: ${theme.sizing(2)};
    margin-bottom: ${theme.sizing(2)};

    & > div[data-testid='stateField'] {
      min-width: ${theme.sizing(21)};
    }
  `,
  button: (theme: Theme) => css`
    margin-top: ${theme.sizing(2)};
    width: 100%;

    ${theme.breakpoints.down('md')} {
      position: fixed;
      left: ${theme.sizing(mobilePadding)};
      bottom: ${theme.sizing(mobilePadding)};
      width: calc(100vw - ${theme.sizing(mobilePadding * 2)});
    }
  `,
};

export const PlateForm = ({
  isActive,
  isUSCountry,
  defaultValues,
  onChange,
  vehicle: selectedVehicle,
  ...props
}: Props) => {
  const { isMobile } = useMedia();

  const ref = useRef<HTMLInputElement>(null);
  const { isToggle, turnOn, turnOff } = useToggle(isActive);
  const hadVehicle = usePrevious(Boolean(selectedVehicle));

  const { getTwoLetterCodePairs } = useGetStatesByCountry(Country.Us);
  const stateList = getTwoLetterCodePairs();

  const form = useForm<LicensePlate>({
    defaultValues: {
      state: defaultValues?.state ?? '',
      plate: defaultValues?.plate ?? '',
    },
  });
  const { setValue, setError, getValues, watch } = form;
  const [plate, state] = watch(['plate', 'state']);

  const handleChange = useCallback(
    (vehicle: Vehicle) => {
      const formValues = getValues();

      vehicle.setPlate(formValues.plate);
      vehicle.setState(formValues.state);

      if (onChange) {
        onChange(vehicle);
      }
    },
    [getValues, onChange]
  );

  const { search, vehicles, vehiclesNotFound, isFetching, clearVehicles } = useVehicleSearch({
    onChange: handleChange,
  });

  const handleStateClick = useCallback(
    (option: Option) => {
      setValue('state', option.name);
      turnOff();
    },
    [setValue, turnOff]
  );

  const handleClear = useCallback(() => {
    if (onChange) {
      onChange(null);
    }
  }, [onChange]);

  const handleInputChange = useCallback(
    (value: string) => {
      if (value === '') {
        handleClear();
      }
    },
    [handleClear]
  );

  useEffect(() => {
    if (vehiclesNotFound) {
      setError('plate', { type: 'server', message: PLATE_ERROR });
    }
  }, [setError, vehiclesNotFound]);

  useEffect(() => {
    const formValues = getValues();

    if (!formValues.state) {
      setValue('state', defaultValues?.state ?? '');
    }

    if (!formValues.plate) {
      setValue('plate', defaultValues?.plate ?? '');
    }
  }, [defaultValues?.plate, defaultValues?.state, getValues, setValue]);

  useEffect(() => {
    if (isActive) {
      ref.current?.focus();
    }
  }, [isActive]);

  useEffect(() => {
    if ((!isActive || !plate) && vehicles.length > 0) {
      clearVehicles();
    }
  }, [clearVehicles, isActive, plate, vehicles.length]);

  useEffect(() => {
    const formValues = getValues();
    const selectedPlate = selectedVehicle?.plate ?? '';

    if (formValues.plate && formValues.state && selectedPlate !== defaultValues?.plate && isActive) {
      search({ plate: formValues });
    }
  }, [defaultValues?.plate, getValues, isActive, search, selectedVehicle?.plate, selectedVehicle?.state]);

  useEffect(() => {
    if (isActive || !hadVehicle) {
      return;
    }

    const formValues = getValues();

    if (formValues.plate && !defaultValues?.plate && !selectedVehicle?.plate) {
      setValue('plate', '');
    }
  }, [defaultValues?.plate, getValues, hadVehicle, isActive, selectedVehicle?.plate, setValue]);

  useEffect(() => {
    if (!isActive && defaultValues?.plate && !plate) {
      setValue('plate', defaultValues?.plate);
    }
  }, [defaultValues?.plate, isActive, plate, setValue]);

  return (
    <Box {...props}>
      <HookForm form={form} onSubmit={(values) => search({ plate: values })} data-testid="plateForm">
        <div css={styles.container}>
          <FormTextField
            autoComplete="off"
            placeholder="State"
            maxLength={2}
            width="80px"
            readOnly
            name="state"
            onFocus={turnOn}
            adornmentAfter={
              <button type="button" onClick={turnOn}>
                <Icon name={isToggle ? 'expand_less' : 'expand_more'} size="large" />
              </button>
            }
          />

          <FormTextField
            autoComplete="off"
            isClearable={Boolean(plate)}
            onClear={handleClear}
            onChange={handleInputChange}
            adornmentAfter={plate ? undefined : <Icon name="search" />}
            placeholder="Plate"
            name="plate"
            onFocus={turnOff}
            ref={ref}
          />
        </div>

        {isToggle && (
          <OptionsList
            activeOption={state}
            options={stateList}
            columnCount={6}
            onClick={handleStateClick}
            justifyContent="space-between"
          />
        )}

        {!isToggle && (
          <Box width="100%">
            <VehiclesList
              vehicles={vehicles}
              onItemClick={handleChange}
              disabled={!isActive}
              highlightValue={`${state} ${plate}`}
            />
          </Box>
        )}

        {!isUSCountry && !isToggle && (
          <Banner fullWidth variant="info" icon={isMobile ? undefined : 'info'} p={2} gap={1} mt={4} mb={6}>
            The plate lookup only works for US plates.
          </Banner>
        )}

        <div css={styles.button}>
          <Button
            fullWidth
            type="submit"
            isLoading={isFetching}
            disabled={!plate || isFetching}
            data-testid="plateSearchButton"
          >
            Search for vehicle
          </Button>
        </div>
      </HookForm>
    </Box>
  );
};
