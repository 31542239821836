import { useCallback, useEffect, useMemo, useState } from 'react';
import { SubscriptionPlanPeriod } from 'shared/api';
import { useGetSupplierAccounts } from 'store/queries/currentUser/supplierAccounts/useGetSupplierAccounts';
import { getPeriodByCondition, isYearlyPeriod } from 'utils';
import { ProductAlias } from '../constants/products';
import { useAddOns } from './useAddOns';
import { usePlans } from './usePlans';
import { useSubscription } from './useSubscription';
import { useSubscriptionSearchQuery } from './useSubscriptionSearchQuery';
import type { AddOn } from '../types';

export const useSubscriptionSelection = () => {
  const { subscription, isRestrictedEX } = useSubscription();

  const {
    addOnsAlias,
    isUpdatePeriod = false,
    period = subscription.period || SubscriptionPlanPeriod.Year,
    planAlias,
    reset: resetSearchQuery,
    update: updateSearchQuery,
  } = useSubscriptionSearchQuery();

  const isYearly = isUpdatePeriod || isYearlyPeriod(period);

  const { isLoading: isAddOnsLoading, tireAddOn, getAddOnByAlias } = useAddOns(isYearly);
  const { isLoading: isPlansLoading, getPlanByAlias } = usePlans(isYearly);
  const {
    filterAccountsByType,
    isFetching: isFetchingAccounts,
    refetch: refetchSupplierAccounts,
  } = useGetSupplierAccounts();

  const [isOrderSuccess, setOrderSuccess] = useState(false);

  const selectedAddOns = useMemo(
    () => addOnsAlias.map(getAddOnByAlias).filter((addOn): addOn is AddOn => addOn !== undefined),
    [addOnsAlias, getAddOnByAlias]
  );

  const tiresAccounts = useMemo(() => filterAccountsByType('tires'), [filterAccountsByType]);

  const selectedSuppliers = useMemo(() => {
    if (!isRestrictedEX) {
      return [];
    }

    return tiresAccounts
      .filter((account) => isUpdatePeriod || account.isPaymentPending)
      .map((account) => ({
        accountId: String(account.id),
        id: tireAddOn?.id ?? '',
        price: tireAddOn?.price ?? 0,
      }));
  }, [isRestrictedEX, isUpdatePeriod, tireAddOn?.id, tireAddOn?.price, tiresAccounts]);

  const selectAddOn = useCallback(
    (alias: ProductAlias) => {
      if (addOnsAlias.includes(alias)) {
        updateSearchQuery({ addOns: addOnsAlias.filter((addOnAlias) => addOnAlias !== alias) });
        return;
      }

      updateSearchQuery({ addOns: [...addOnsAlias, alias] });
    },
    [addOnsAlias, updateSearchQuery]
  );

  const selectPlan = useCallback(
    (alias: ProductAlias, isForceUpdate?: boolean) => {
      if (planAlias === alias) {
        return;
      }

      if (!isForceUpdate && [subscription?.plan?.alias, ProductAlias.Free].includes(alias)) {
        updateSearchQuery({ plan: undefined });
        return;
      }

      updateSearchQuery({ plan: alias });
    },
    [planAlias, subscription?.plan?.alias, updateSearchQuery]
  );

  const periodToggle = useCallback(() => {
    if (isUpdatePeriod || subscription?.period) {
      return;
    }

    updateSearchQuery({ period: getPeriodByCondition(!isYearlyPeriod(period)) });
  }, [isUpdatePeriod, period, subscription?.period, updateSearchQuery]);

  const updatePeriod = useCallback(() => {
    const addOns = subscription?.addOns?.map((addOn) => addOn.alias);

    if (isRestrictedEX) {
      updateSearchQuery({
        addOns: addOns.filter((addOnAlias) => addOnAlias === ProductAlias.Tires),
        isUpdatePeriod: true,
      });
      return;
    }

    updateSearchQuery({ addOns, plan: subscription?.plan?.alias, isUpdatePeriod: true });
  }, [isRestrictedEX, subscription?.addOns, subscription?.plan?.alias, updateSearchQuery]);

  const finishOrder = useCallback(() => {
    resetSearchQuery();
    setOrderSuccess(true);

    if (selectedSuppliers.length > 0) {
      refetchSupplierAccounts();
    }
  }, [resetSearchQuery, selectedSuppliers.length, refetchSupplierAccounts]);

  useEffect(() => {
    if (!tiresAccounts.length && addOnsAlias.includes(ProductAlias.Tires)) {
      resetSearchQuery();
    }
  }, [addOnsAlias, filterAccountsByType, resetSearchQuery, selectedSuppliers, tiresAccounts.length, updateSearchQuery]);

  return {
    isLoading: isAddOnsLoading || isPlansLoading || isFetchingAccounts,
    isOrderSuccess,
    isUpdatePeriod,
    isYearly,
    selectedAddOns,
    selectedPlan: getPlanByAlias(planAlias),
    selectedSuppliers,
    finishOrder,
    periodToggle,
    resetSearchQuery,
    selectAddOn,
    selectPlan,
    updatePeriod,
  };
};
