import { isEmpty } from '@partstech/ui/utils';
import { WARNING_STATUS } from 'constant';
import { AccountStatus } from 'shared/api';
import type { Store } from './Store';
import type { Supplier } from './Supplier';
import type { Account } from 'shared/api';

type SupplierAccountData = Omit<Account, 'active' | 'store' | 'supplier' | 'additionalInformation' | 'credentials'> & {
  additionalInformation: Record<string, string>;
  credentials: Record<string, string>;
};

export class SupplierAccount {
  additionalInformation: Record<string, string>;

  credentials: Record<string, string>;

  customerDescription: string | null = null;

  id: string;

  nickname: string | null;

  priority: number;

  status: AccountStatus;

  store: Store | null = null;

  supplier: Supplier | null = null;

  constructor(account: SupplierAccountData) {
    this.additionalInformation = account.additionalInformation;
    this.credentials = account.credentials;
    this.customerDescription = account.customerDescription ?? null;
    this.id = account.id;
    this.nickname = account.nickname ?? null;
    this.priority = account.priority;
    this.status = account.status;
  }

  get isApprovalNeeded(): boolean {
    return this.status === AccountStatus.SupplierApprovalNeeded;
  }

  get isNotApproved(): boolean {
    return this.status === AccountStatus.NotApproved;
  }

  get isHelpRequested(): boolean {
    return this.status === AccountStatus.HelpRequested;
  }

  get isPartsTechHelpRequested(): boolean {
    return this.status === AccountStatus.PartstechHelpRequested;
  }

  get isPaymentPending(): boolean {
    return this.status === AccountStatus.PaymentPending;
  }

  get isPending(): boolean {
    return this.status === AccountStatus.Pending;
  }

  get isProcessing(): boolean {
    return this.status === AccountStatus.Processing;
  }

  get isReadyForReview(): boolean {
    return this.status === AccountStatus.ReadyForReview;
  }

  get isApproved(): boolean {
    return this.status === AccountStatus.Approved;
  }

  get isFirstCall(): boolean {
    return this.priority === 1;
  }

  get statusMessage(): string {
    if (this.supplier?.comingSoon) {
      return 'Not yet available';
    }

    return WARNING_STATUS[this.status];
  }

  get shouldRefetch(): boolean {
    return Boolean(
      this.hasFilledCredentials() && (this.isProcessing || this.isPending) && this.supplier?.autoValidation
    );
  }

  hasFilledCredentials(): boolean {
    if (this.supplier?.isTire) {
      const requiredFields =
        this.supplier?.credentialFields?.reduce<string[]>(
          (fields, field) => (field.required ? [...fields, field.internalName] : fields),
          []
        ) ?? [];

      return requiredFields.every((field) => !isEmpty(this.credentials[field]));
    }

    return Object.values(this.credentials).every((value) => !isEmpty(value));
  }

  get name(): string {
    return this.nickname ?? this.store?.name ?? '';
  }

  setSupplier(supplier: Supplier) {
    this.supplier = supplier;
  }

  setStore(store: Store) {
    this.store = store;
  }
}
