import { entries } from '@partstech/ui/utils';
import { useCallback, useMemo } from 'react';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import type { FeatureFlags } from 'store/queries/currentUser/useGetCurrentUser';

type FeatureKeys = keyof FeatureFlags;

type Args = {
  skip?: boolean;
};

export const useFeatureFlag = ({ skip }: Args = {}) => {
  const { featureFlags } = useGetCurrentUser({ skip });

  const isFeatureEnabled = useCallback(
    (feature: FeatureKeys) => Boolean(featureFlags?.[feature] ?? false),
    [featureFlags]
  );

  const availableFeatureFlags = useMemo(
    () =>
      entries({ newLabor: isFeatureEnabled('newLabor') })
        .filter(([_, value]) => Boolean(value))
        .map(([key]) => key),
    [isFeatureEnabled]
  );

  return { isFeatureEnabled, availableFeatureFlags };
};
