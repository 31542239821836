import { uniq } from '@partstech/ui/utils';
import { useMemo } from 'react';
import { useGetVehicleMakeGroupsQuery } from './GetVehicleMakeGroups.generated';
import type { VehicleType } from 'shared/api';

export type Make = {
  id: string;
  name: string;
  priority: number;
  types: VehicleType[];
  years: number[];
};

const emptyYears: number[] = [];
const emptyMakes: Make[] = [];

const emptyResult = {
  years: emptyYears,
  makes: emptyMakes,
};

export const useVehicleMakeGroups = () => {
  const { data, ...props } = useGetVehicleMakeGroupsQuery();

  const result = useMemo(
    () =>
      data?.vehicleMakeGroups?.reduce(
        (memo: { years: number[]; makes: Make[] }, make) => ({
          years: uniq([...memo.years, ...make.years]),
          makes: [...memo.makes, make],
        }),
        emptyResult
      ) ?? emptyResult,
    [data?.vehicleMakeGroups]
  );

  return {
    years: result.years,
    makes: result.makes,
    ...props,
  };
};
