/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type GetRecentOrdersSuppliersQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetRecentOrdersSuppliersQuery = {
  __typename?: 'Query';
  currentUser?: {
    __typename?: 'User';
    activeMember?: {
      __typename?: 'ShopMember';
      shop?: {
        __typename?: 'Shop';
        recentOrdersSuppliers?: Array<{
          __typename?: 'Supplier';
          id: string;
          name: Types.Scalars['String']['input'];
        }> | null;
      } | null;
    } | null;
  } | null;
};

export const GetRecentOrdersSuppliersDocument = `
    query GetRecentOrdersSuppliers {
  currentUser {
    activeMember {
      shop {
        recentOrdersSuppliers {
          id
          name
        }
      }
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetRecentOrdersSuppliers: build.query<GetRecentOrdersSuppliersQuery, GetRecentOrdersSuppliersQueryVariables | void>(
      {
        query: (variables) => ({ document: GetRecentOrdersSuppliersDocument, variables }),
      }
    ),
  }),
});

export { injectedRtkApi as api };
export const { useGetRecentOrdersSuppliersQuery, useLazyGetRecentOrdersSuppliersQuery } = injectedRtkApi;
