type SupplierMembershipData = {
  id: string;
  active: boolean;
  supplier: {
    id: string;
    name: string;
  };
};

export class SupplierMembership {
  id: string;

  isActive: boolean;

  supplier: {
    id: string;
    name: string;
  };

  constructor(user: SupplierMembershipData) {
    this.id = user.id;
    this.isActive = user.active;
    this.supplier = user.supplier;
  }
}
