import { useEffect, useState } from 'react';

type Args = {
  defaultUrl?: string;
};

export const useVideoUrl = ({ defaultUrl }: Args) => {
  const [videoUrl, setVideoUrl] = useState<string>('');

  useEffect(() => {
    if (!defaultUrl) {
      return;
    }

    setVideoUrl(defaultUrl);
  }, [defaultUrl]);

  return videoUrl;
};
