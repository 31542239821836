import { css } from '@emotion/react';
import { Box, Button, ImageFallback, Link, Typography } from '@partstech/ui';
import cartWithBoxesImg from 'images/icons/cart-with-boxes.svg';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import { SearchTypes } from 'types/search';
import { getAddSupplierUrl } from 'utils/url';
import type { PropsWithChildren } from 'react';

const styles = {
  container: css`
    overflow: hidden;
  `,
};

type Props = PropsWithChildren<{
  isAllowed?: boolean;
  type?: SearchTypes;
}>;

export const SupplierAccountsRequiredView = ({ children, isAllowed = false, type = SearchTypes.PARTS }: Props) => {
  const { isAllowedToModifySuppliers } = useGetCurrentUser();

  if (!isAllowed) {
    return (
      <Box
        data-testid="noSuppliersMessage"
        py={32}
        px={{ sm: 0, md: 0 }}
        textAlign="center"
        width="100%"
        css={styles.container}
      >
        <Box mb={4}>
          <ImageFallback src={cartWithBoxesImg} />
        </Box>

        <Box mt={1} mb={4}>
          <Typography variant="bodyText2">
            We’ve noticed that you haven’t selected any suppliers to buy {type} from. Connect to your suppliers by using
            your account credentials.
          </Typography>
        </Box>

        <Button
          variant="secondary"
          as={<Link data-testid="addSupplierLink" to={getAddSupplierUrl('all')} />}
          disabled={!isAllowedToModifySuppliers}
        >
          Add suppliers
        </Button>
      </Box>
    );
  }

  return (
    <Box css={styles.container} display="flex" height="100%">
      {children}
    </Box>
  );
};
