/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type AddItemsToCartMutationVariables = Types.Exact<{
  items: Array<Types.ItemParametersInput> | Types.ItemParametersInput;
}>;

export type AddItemsToCartMutation = {
  __typename?: 'Mutation';
  batchAddItemsToActiveCart?: {
    __typename?: 'BatchAddItemsToActiveCartPayload';
    results: Array<{
      __typename?: 'AddItemToActiveCartResult';
      partNumberId: Types.Scalars['String']['input'];
      result:
        | {
            __typename?: 'AddItemToActiveCartErrorPayload';
            error: Types.AddItemError;
            errorMessage: Types.Scalars['String']['input'];
          }
        | { __typename?: 'AddItemToActiveCartSuccessPayload'; orderId: string; orderItemId: string };
    }>;
  } | null;
};

export const AddItemsToCartDocument = `
    mutation AddItemsToCart($items: [ItemParametersInput!]!) {
  batchAddItemsToActiveCart(input: {items: $items}) {
    results {
      partNumberId
      result {
        ... on AddItemToActiveCartErrorPayload {
          error
          errorMessage
        }
        ... on AddItemToActiveCartSuccessPayload {
          orderId
          orderItemId
        }
      }
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    AddItemsToCart: build.mutation<AddItemsToCartMutation, AddItemsToCartMutationVariables>({
      query: (variables) => ({ document: AddItemsToCartDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useAddItemsToCartMutation } = injectedRtkApi;
