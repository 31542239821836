import { Routes } from 'constant';
import { generateUrl } from './generateUrl';
import type { IntervalType } from 'constant';
import type { Severity } from 'shared/api';

export type ServiceGuidesTabs = 'labor' | 'maintenance' | 'fluids' | 'specs';

export type ServiceGuidesParams = {
  vehicleId: string;
  tab: ServiceGuidesTabs;
};

export type ServiceGuidesQuery = {
  intervalType?: IntervalType;
  severity?: Severity;
  interval?: string;
  search?: string;
  application?: string;
  vin?: string;
};

export type LaborQuery = {
  search?: string;
  system?: string;
  group?: string;
  application?: string;
};

export const getServiceGuidesUrl = ({ vehicleId, tab, ...query }: ServiceGuidesParams & ServiceGuidesQuery) =>
  generateUrl(Routes.SERVICE_GUIDES, { vehicleId, tab }, query);
