import { useCallback, useMemo, useState } from 'react';
import { filterSupplierAccounts } from 'utils';
import type { TABS } from '../constants';
import type { SupplierAccount } from 'models';

type Props = {
  supplierAccounts: SupplierAccount[];
  value: SupplierAccount | null;
  canChangeSupplierType: boolean;
};

type SupplierTabs = (typeof TABS)[number];

export const useSupplierAccountList = ({ supplierAccounts, value, canChangeSupplierType }: Props) => {
  const isTireSupplierAccountSelected = Boolean(value?.supplier?.isTire);

  const getInitialTab = useCallback(
    (): SupplierTabs => (isTireSupplierAccountSelected ? 'tires' : 'parts'),
    [isTireSupplierAccountSelected]
  );

  const [activeTab, setActiveTab] = useState<SupplierTabs>(getInitialTab());

  const canSelectPartSupplier = canChangeSupplierType || !isTireSupplierAccountSelected;
  const canSelectTireSupplier = canChangeSupplierType || isTireSupplierAccountSelected;

  const isDisabled = useCallback(
    (supplierAccount: SupplierAccount) =>
      !supplierAccount?.isApproved ||
      (supplierAccount.supplier?.isTire ? !canSelectTireSupplier : !canSelectPartSupplier),

    [canSelectPartSupplier, canSelectTireSupplier]
  );

  const isPartWarningShown = useMemo(
    () => activeTab === 'parts' && !canSelectPartSupplier && supplierAccounts.length > 0,
    [activeTab, canSelectPartSupplier, supplierAccounts.length]
  );
  const isTireWarningShown = useMemo(
    () => activeTab === 'tires' && !canSelectTireSupplier && supplierAccounts.length > 0,
    [supplierAccounts.length, activeTab, canSelectTireSupplier]
  );

  const hasWarning = isPartWarningShown || isTireWarningShown;

  const renderTooltip = useCallback((supplierAccount: SupplierAccount) => {
    if (supplierAccount?.statusMessage && !supplierAccount.isApproved) {
      return supplierAccount.statusMessage;
    }

    if (supplierAccount?.supplier?.isTire) {
      return 'To access tire suppliers, click on “New supplier” and then select a tire supplier.';
    }

    return 'To access part suppliers, click on “New supplier” and then select a part supplier.';
  }, []);

  const supplierAccountsByType = useMemo(
    () => filterSupplierAccounts(supplierAccounts, activeTab),
    [activeTab, supplierAccounts]
  );

  return { activeTab, changeTab: setActiveTab, supplierAccountsByType, hasWarning, renderTooltip, isDisabled };
};
