/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type UpdateUserInformationMutationVariables = Types.Exact<{
  input: Types.UpdateUserInformationInput;
}>;

export type UpdateUserInformationMutation = {
  __typename?: 'Mutation';
  updateUserInformation?: { __typename: 'UpdateUserInformationPayload' } | null;
};

export const UpdateUserInformationDocument = `
    mutation UpdateUserInformation($input: UpdateUserInformationInput!) {
  updateUserInformation(input: $input) {
    __typename
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    UpdateUserInformation: build.mutation<UpdateUserInformationMutation, UpdateUserInformationMutationVariables>({
      query: (variables) => ({ document: UpdateUserInformationDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUpdateUserInformationMutation } = injectedRtkApi;
