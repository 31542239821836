import type {
  SearchEntryPointInterface,
  SearchQueryConvertable,
  SearchQuery,
  SearchRequestConvertable,
  SearchCatalogRequestConvertable,
  ToRequestParams,
  ToCatalogRequestParams,
} from 'features/searchForm';
import type { CatalogSearchInput, SearchInput } from 'shared/api';

const TIRE_KEYWORDS = ['tire', 'tires'];

export class KeywordSearch
  implements
    SearchEntryPointInterface,
    SearchQueryConvertable,
    SearchRequestConvertable,
    SearchCatalogRequestConvertable
{
  readonly keyword: string;

  constructor(keyword: string) {
    this.keyword = keyword;
  }

  isVehicleRequired(): boolean {
    return true;
  }

  populateInput(): string {
    return this.keyword;
  }

  get isTire(): boolean {
    return TIRE_KEYWORDS.some((tireKeyword) => tireKeyword === this.keyword.toLowerCase());
  }

  toQuery(): SearchQuery {
    return { part_text: this.keyword };
  }

  toRequest({ accountId, vehicleId, vin }: ToRequestParams): SearchInput {
    if (!vehicleId) {
      throw new Error('vehicleId is missing');
    }

    return {
      keyword: {
        accountId,
        keyword: this.keyword,
        vehicleId,
        vin,
      },
    };
  }

  toCatalogRequest({ vehicleId, vin }: ToCatalogRequestParams): CatalogSearchInput {
    if (!vehicleId) {
      throw new Error('vehicleId is missing');
    }

    return {
      keyword: {
        keyword: this.keyword,
        vehicleId,
        vin,
      },
    };
  }
}
