import { useEffect, useState } from 'react';
import type { SearchEntryPointInterface } from 'features/searchForm';

export const useSelectedSearchEntry = (defaultSearchEntry: SearchEntryPointInterface | null) => {
  const [searchEntry, setSearchEntry] = useState<SearchEntryPointInterface | null>(defaultSearchEntry);

  useEffect(() => {
    if (defaultSearchEntry) {
      setSearchEntry(defaultSearchEntry);
    }
  }, [defaultSearchEntry]);

  return [searchEntry, setSearchEntry] as const;
};
