/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import { FullPartTypeFragmentDoc } from './FullPartType.generated';
import type * as Types from '../../../shared/api/graphql/graphql';

export type GetFullPartTypesQueryVariables = Types.Exact<{
  ids?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
  categoryID?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;

export type GetFullPartTypesQuery = {
  __typename?: 'Query';
  partTypes?: Array<{
    __typename?: 'PartType';
    id: string;
    name: Types.Scalars['String']['input'];
    application: Types.PartTypeApplication;
    aliases?: Array<Types.Scalars['String']['input']> | null;
    attributes?: Array<{
      __typename?: 'PartTypeAttribute';
      name: Types.Scalars['String']['input'];
      priority: number;
      required: Types.Scalars['Boolean']['input'];
      values?: Array<Types.Scalars['String']['input']> | null;
    }> | null;
    category?: { __typename?: 'PartCategory'; id: string; name: Types.Scalars['String']['input'] } | null;
    linkedPartTypes?: Array<{ __typename?: 'PartType'; application: Types.PartTypeApplication }> | null;
    subCategory?: { __typename?: 'PartSubCategory'; id: string; name: Types.Scalars['String']['input'] } | null;
  }> | null;
};

export const GetFullPartTypesDocument = `
    query GetFullPartTypes($ids: [ID!], $search: String, $categoryID: ID) {
  partTypes(IDs: $ids, search: $search, categoryID: $categoryID) {
    ...FullPartType
  }
}
    ${FullPartTypeFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetFullPartTypes: build.query<GetFullPartTypesQuery, GetFullPartTypesQueryVariables | void>({
      query: (variables) => ({ document: GetFullPartTypesDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetFullPartTypesQuery, useLazyGetFullPartTypesQuery } = injectedRtkApi;
