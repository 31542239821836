import { isNotNull } from '@partstech/ui/utils';
import { useMemo } from 'react';
import { useTireSizeSearch } from 'features/searchForm';
import { TireSize } from 'models';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import { adaptTireSize, isTireSize } from 'utils';
import { api as generatedApi } from './GetStockOrderTemplates.generated';
import type { GetStockOrderTemplatesQuery } from './GetStockOrderTemplates.generated';

export type StockOrderTemplateItem = {
  id: string;
  displayPartNumber: string;
  image?: string | null;
  partName?: string | null;
  supplier?: { name: string } | null;
  tireSize?: TireSize | null;
  quantity?: number;
};

export type StockOrderTemplate = {
  id: string;
  lastUseDate: string;
  name: string;
  items: StockOrderTemplateItem[];
};

const emptyTemplates: StockOrderTemplate[] = [];

const api = generatedApi.enhanceEndpoints({
  addTagTypes: ['StockOrderTemplate'],
  endpoints: {
    GetStockOrderTemplates: {
      providesTags: (response: GetStockOrderTemplatesQuery | undefined) => {
        const shop = response?.currentUser?.activeMember?.shop;
        if (!shop) {
          return [];
        }

        const stockOrderTemplatesTags =
          shop?.stockOrderTemplates?.items?.map((item) => ({ type: 'StockOrderTemplate' as const, id: item?.id })) ??
          [];

        return ['StockOrderTemplate', ...stockOrderTemplatesTags];
      },
    },
  },
});

const { useGetStockOrderTemplatesQuery } = api;

export const useGetStockOrderTemplates = ({
  page,
  perPage,
  search,
}: {
  page: number;
  perPage: number;
  search: string;
}) => {
  const { hasCurrentUser } = useGetCurrentUser();

  const { matchedTireSizes } = useTireSizeSearch({ search });

  const adaptedSearch = useMemo(() => {
    if (matchedTireSizes.length === 1) {
      return adaptTireSize(matchedTireSizes[0]?.name || '');
    }

    return isTireSize(search) ? adaptTireSize(search) : search;
  }, [search, matchedTireSizes]);

  const { data, ...props } = useGetStockOrderTemplatesQuery(
    { page, perPage, search: adaptedSearch },
    { skip: !hasCurrentUser }
  );

  const stockOrderTemplatesData = data?.currentUser?.activeMember?.shop?.stockOrderTemplates;
  const totalCount = stockOrderTemplatesData?.totalCount || 0;

  const templates = useMemo(
    () =>
      stockOrderTemplatesData?.items?.filter(isNotNull).map((template) => ({
        id: template.id,
        lastUseDate: template.lastUseDate,
        name: template.name,
        items:
          template.items?.map((item) => ({ ...item, tireSize: item.tireSize && new TireSize(item.tireSize) })) || [],
      })) || emptyTemplates,
    [stockOrderTemplatesData?.items]
  );

  return {
    templates,
    totalCount,
    ...props,
  };
};
