import { css } from '@emotion/react';
import type { Theme } from '@partstech/ui';

type ControlDirectionType = 'next' | 'previous';

type Control = {
  type: ControlDirectionType;
  isVertical: boolean;
  disabled: boolean;
  useBallControls?: boolean;
};

const carouselBlock = (theme: Theme) => css`
  position: relative;

  .carousel-item {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 25px;
      bottom: 25px;
      right: 0;
      width: 1px;
      background: ${theme.color.mono17};
    }

    &:last-of-type:after {
      display: none;
    }
  }
`;

const carouselBlockHorizontal = css`
  .carousel-item {
    vertical-align: top;
  }
`;

const carouselBlockVertical = css`
  .carousel-item {
    &:after {
      display: none;
    }
  }
`;

const firstVisible = css`
  .cover-right {
    right: 0;
  }
`;

const carouselContainer = (theme: Theme) => css`
  overflow: hidden;
  position: relative;

  .cover {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: ${theme.zIndex.default};
    background: ${theme.color.white};
    opacity: 0.7;
    transition: all 0.3s linear;
  }

  &.first-visible {
    .cover-right {
      right: 0;
    }
  }

  &.last-visible {
    .cover-left {
      left: 0;
    }
  }
`;

const carouselContainerWithVertical = css`
  height: 316px;
  margin: 30px 0;
`;

const carouselList = {
  horizontal: css`
    width: 10000px;
  `,
  vertical: css`
    height: 10000px;
    flex-direction: column;
    padding: 0;
  `,
};

const ballControls = (theme: Theme) => css`
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  line-height: 24px;
  margin: 12px auto 0 auto;
  text-align: center;

  a {
    margin: 0 5px;
    padding: 0;
    background: transparent;
    color: ${theme.color.primary};
    text-decoration: none;
  }
`;

const control = {
  vertical: (type: ControlDirectionType) => css`
    left: 50%;
    margin-left: -8px;
    ${type === 'next' ? 'bottom: 0' : 'top: 0'};
  `,

  horizontal: (type: ControlDirectionType, useBallControls?: Boolean) => css`
    top: ${useBallControls ? 'auto' : '50%'};
    margin: ${useBallControls ? '0' : '-8px 0 0'};
    ${useBallControls && 'bottom: 0;'}
    ${type === 'next' ? 'right: 0' : 'left: 0'};
  `,
};

export const styles = {
  wrapper: (isVertical: boolean) => (theme: Theme) => css`
    ${carouselBlock(theme)}
    ${isVertical ? carouselBlockVertical : carouselBlockHorizontal}
  `,

  container: (isVertical: boolean, isShowControlsButton: boolean) => (theme: Theme) => css`
    ${isShowControlsButton && 'margin: 0 30px;'}
    ${firstVisible}
      ${carouselContainer(theme)};
    ${isVertical && carouselContainerWithVertical}
  `,

  list: (isVertical: boolean, duration: number, shift: number) => () => css`
    display: flex;
    align-content: center;
    ${isVertical ? carouselList.vertical : carouselList.horizontal};
    transition-property: ${isVertical ? 'margin-top' : 'margin-left'};
    transition-duration: ${duration}s;
    ${isVertical ? `margin-top:${shift || 0}px` : `margin-left:${shift || 0}px`};
  `,

  ballControls: (theme: Theme) => css`
    ${ballControls(theme)};
  `,

  control:
    ({ type, isVertical, disabled, useBallControls }: Control) =>
    (theme: Theme) => css`
      display: ${disabled ? 'none' : 'inline-block'};
      position: absolute;
      z-index: ${theme.zIndex.scrollbar};
      ${isVertical ? control.vertical(type) : control.horizontal(type, useBallControls)}
      text-decoration: none;
      color: ${theme.color.defaultText};

      @media (max-width: 767px) {
        ${useBallControls && 'display: none;'}
      }
    `,
};
