/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type GetLaborRatesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetLaborRatesQuery = {
  __typename?: 'Query';
  currentUser?: {
    __typename?: 'User';
    activeMember?: {
      __typename?: 'ShopMember';
      shop?: {
        __typename?: 'Shop';
        laborRates?: Array<{
          __typename?: 'LaborRate';
          id: string;
          isDefault: Types.Scalars['Boolean']['input'];
          name: Types.Scalars['String']['input'];
          rate: Types.Scalars['BigDecimal']['input'];
        }> | null;
      } | null;
    } | null;
  } | null;
};

export const GetLaborRatesDocument = `
    query GetLaborRates {
  currentUser {
    activeMember {
      shop {
        laborRates {
          id
          isDefault
          name
          rate
        }
      }
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetLaborRates: build.query<GetLaborRatesQuery, GetLaborRatesQueryVariables | void>({
      query: (variables) => ({ document: GetLaborRatesDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetLaborRatesQuery, useLazyGetLaborRatesQuery } = injectedRtkApi;
