import { css } from '@emotion/react';
import type { Theme } from '@partstech/ui';

export const styles = {
  assets: (theme: Theme) => css`
    position: relative;
    z-index: ${theme.zIndex.initial};
    display: flex;
    flex-direction: column;
  `,
  asset: css`
    margin: 0 0 10px 0;
  `,
  button: (isActive: boolean) => (theme: Theme) => css`
    width: 100%;
    height: 40px;
    background-color: ${isActive ? theme.color.white : theme.color.mono2};
    border: 1px solid ${theme.color.mono17};
    border-bottom: ${isActive ? 'none' : `1px solid ${theme.color.mono17}`};
    color: ${theme.color.monoDark};
    padding: 0 14px;
    cursor: pointer;
    position: relative;
    z-index: ${theme.zIndex.initial};
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .icon {
      margin-left: 10px;
    }
  `,
  list: (theme: Theme) => css`
    width: auto;
    display: block;
    border: 1px solid ${theme.color.mono17};
    background-color: ${theme.color.white};
    padding: 14px 13px;
  `,
};
