import { useKeyboardSelection } from '@partstech/ui/hooks';
import { useCallback, useMemo } from 'react';

type Props<T> = {
  columns: T[][];
  onEnterKeyDown: (option: T) => void;
  defaultIndex?: number;
  onArrowKeyDown?: (option: T) => void;
  isKeyboardDisabled?: boolean;
};

export const useColumnKeyboardSelection = <T>({
  columns,
  onEnterKeyDown,
  defaultIndex = -1,
  onArrowKeyDown,
  isKeyboardDisabled = false,
}: Props<T>) => {
  const options = useMemo(() => columns.flat(), [columns]);

  let cumulativeIndex = -1;
  const cumulativeIndexes = useMemo(
    () => columns.map((column) => column.map(() => ++cumulativeIndex)),
    [columns, cumulativeIndex]
  );

  const getCumulativeIndex = useCallback(
    (columnIndex: number, optionIndex: number): number => {
      const columnIndexes = cumulativeIndexes[columnIndex];

      return columnIndexes?.[optionIndex] ?? -1;
    },
    [cumulativeIndexes]
  );

  const handleEnterKeyDown = useCallback(
    (index: number) => {
      const option = options[index];

      if (option) {
        onEnterKeyDown(option);
      }
    },
    [options, onEnterKeyDown]
  );

  const handleArrowKeyDown = useCallback(
    (index: number) => {
      const option = options[index];

      if (option && onArrowKeyDown) {
        onArrowKeyDown(option);
      }
    },
    [options, onArrowKeyDown]
  );

  const selectedIndex = useKeyboardSelection(
    options.length,
    handleEnterKeyDown,
    defaultIndex,
    handleArrowKeyDown,
    isKeyboardDisabled
  );

  const isActiveByColumnIndex = useCallback(
    (columnIndex: number) => (optionIndex: number) => selectedIndex === getCumulativeIndex(columnIndex, optionIndex),
    [getCumulativeIndex, selectedIndex]
  );

  const isActive = useCallback(
    (columnIndex: number, optionIndex: number) => isActiveByColumnIndex(columnIndex)(optionIndex),
    [isActiveByColumnIndex]
  );

  return { isActive, isActiveByColumnIndex };
};
