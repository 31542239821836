import { Box, Button, useMedia } from '@partstech/ui';
import { useCallback, type PropsWithChildren } from 'react';
import { Outlet } from 'app/AppRouter/Outlet';
import { useAppNavigate } from 'app/AppRouter/useAppNavigate';
import { SmsConnectionsContext } from './SmsConnectionsContext';
import { useSmsConnectionsContext } from './SmsConnectionsContext/SmsConnectionsContext';

const SmsConnectionsWrapper = ({ children }: PropsWithChildren) => {
  const navigate = useAppNavigate();

  const { isMobile } = useMedia();

  const { hasBackButton } = useSmsConnectionsContext();

  const handleBackButtonClick = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      px={{ sm: 4, md: 6 }}
      py={{ sm: hasBackButton ? 11 : 5, md: 6 }}
      width="100%"
      height="100%"
      maxWidth={192}
      mx="auto"
    >
      {hasBackButton && (
        <Box position="absolute" top={{ sm: 1, md: 2 }} left={{ sm: 1, md: 6 }}>
          <Button
            leadingIcon="chevron_left"
            variant="text"
            size={isMobile ? 'dense' : 'regular'}
            onClick={handleBackButtonClick}
            noHover
          >
            Back
          </Button>
        </Box>
      )}

      <Outlet>{children}</Outlet>
    </Box>
  );
};

export const SmsConnectionsLayout = ({ children }: PropsWithChildren) => (
  <Box minHeight="100dvh" minWidth="100vw" background="white">
    <SmsConnectionsContext>
      <SmsConnectionsWrapper>{children}</SmsConnectionsWrapper>
    </SmsConnectionsContext>
  </Box>
);
