import { forwardRef, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { isPathNeedBackground } from '../isPathNeedBackground';
import { useAppLocation } from '../useAppLocation';
import type { LocationState } from '../useAppLocation';
import type { RouterLinkPropsType } from '@partstech/ui';
import type { LinkProps } from 'react-router-dom';

type Props = LinkProps & Pick<RouterLinkPropsType, 'to' | 'css'>;

export const RouterLink = forwardRef<HTMLAnchorElement, Props>(({ onClick, to, state, ...props }, ref) => {
  const location = useAppLocation();

  const enhancedState = useMemo<LocationState>(
    () => (isPathNeedBackground(location.pathname, to) ? { ...state, background: location } : state),
    [state, location, to]
  );

  return <Link ref={ref} to={to} state={enhancedState} {...props} onClick={onClick} />;
});
