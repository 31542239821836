import { SavedQuotesLayout } from './SavedQuotesLayout';
import type { RouteObject } from 'react-router-dom';

const carts = {
  path: 'carts',
  lazy: () => import('pages/SavedQuotes/SavedCartList').then((module) => ({ Component: module.SavedCartList })),
};

export const savedQuotesPage: RouteObject = {
  path: '/saved-quotes/*',
  element: <SavedQuotesLayout />,
  children: [carts],
};
