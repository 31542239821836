import type { SearchQuery } from 'features/searchForm';
import type { Vehicle } from 'models';

export interface SearchQueryConvertable {
  isVehicleRequired: () => boolean;
  toQuery(vehicle?: Vehicle | null, isFittedAvailable?: boolean): SearchQuery;
}

export const isSearchQueryConvertable = (model: object): model is SearchQueryConvertable =>
  'toQuery' in model && 'isVehicleRequired' in model;
