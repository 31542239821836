import { api as generatedApi } from './UpdateLaborRate.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    UpdateLaborRate: {
      invalidatesTags: ['LaborRate'],
    },
  },
});

export const { useUpdateLaborRateMutation } = api;
