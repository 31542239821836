import { css } from '@emotion/react';
import { Box, Card, useMedia } from '@partstech/ui';
import { forwardRef, useMemo } from 'react';
import { DragButton } from './DragButton';
import { useSortableCard } from './useSortableCard';
import type { CardProps, DraggableControllers } from '@partstech/ui';

const styles = {
  wrapper: (disabled: boolean, isDragging: boolean) => {
    const cursor = isDragging ? 'grabbing' : 'grab';

    return css`
      cursor: ${disabled ? 'auto' : `${cursor}`};
    `;
  },
};

type Props = {
  draggableControllers?: DraggableControllers;
  isDragging?: boolean;
  showDragButton?: boolean;
  hoverEffect?: boolean;
} & CardProps;

export const SortableCard = forwardRef<HTMLDivElement, Props>(
  (
    { children, draggableControllers, showDragButton = true, hoverEffect = true, isDragging = false, ...props },
    ref
  ) => {
    const { isMobile } = useMedia();
    const { isHovered, blur, focus } = useSortableCard();

    const draggableProps = useMemo(
      () => ({ ...draggableControllers?.listeners, ...draggableControllers?.attributes }),
      [draggableControllers]
    );

    return (
      <Card
        ref={ref}
        p={1}
        display="flex"
        elevation={hoverEffect && isHovered ? 4 : 0}
        onMouseEnter={focus}
        onMouseLeave={blur}
        css={styles.wrapper(!showDragButton, isDragging)}
        data-testid="sortableCard"
        {...props}
        {...(!isMobile && draggableProps)}
      >
        <Box display="flex" alignItems="center" justifyContent="center" width={4} {...(isMobile && draggableProps)}>
          {showDragButton && (isMobile || isHovered) && <DragButton />}
        </Box>

        {children}
      </Card>
    );
  }
);
