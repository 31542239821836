import { createSelector, createSlice } from '@reduxjs/toolkit';
import { createPaymentCardFromData } from 'entities/paymentCard';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { Card as CardType } from 'shared/api';
import type { RootState } from 'store';

type Card = Omit<CardType, 'id'> & { id: string | null };

type InitialState = {
  paymentMethod: Card | null;
};

const initialState: InitialState = {
  paymentMethod: null,
};

// TODO: Delete and move to UserContext (DEV-2992)
const paymentMethodSlice = createSlice({
  name: 'paymentMethod',
  initialState,
  reducers: {
    setPaymentMethod: (state, action: PayloadAction<InitialState['paymentMethod']>) => {
      state.paymentMethod = action.payload;
    },
  },
});

export const paymentMethod = paymentMethodSlice.reducer;

export const { setPaymentMethod } = paymentMethodSlice.actions;

const selectState = (state: RootState) => state.features.paymentMethod;

export const selectPaymentMethod = createSelector([selectState, (_, id?: string | null) => id], (state, id) =>
  state.paymentMethod ? createPaymentCardFromData(state.paymentMethod, id) : null
);
