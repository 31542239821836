/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type ValidateAddressesMutationVariables = Types.Exact<{
  input: Types.ValidateAddressesInput;
}>;

export type ValidateAddressesMutation = {
  __typename?: 'Mutation';
  validateAddresses?: {
    __typename?: 'ValidateAddressesPayload';
    billingValidity: Types.Scalars['Boolean']['input'];
    shippingValidity?: Types.Scalars['Boolean']['input'] | null;
  } | null;
};

export const ValidateAddressesDocument = `
    mutation ValidateAddresses($input: ValidateAddressesInput!) {
  validateAddresses(input: $input) {
    billingValidity
    shippingValidity
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    ValidateAddresses: build.mutation<ValidateAddressesMutation, ValidateAddressesMutationVariables>({
      query: (variables) => ({ document: ValidateAddressesDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useValidateAddressesMutation } = injectedRtkApi;
