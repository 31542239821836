import { usePermissionDeniedDialog } from 'components/Modal/usePermissionDeniedDialog';
import { usePermissions } from 'store/queries/currentUser/usePermissions';

export const useSubscriptionDenied = () => {
  const { openPermissionDeniedDialog } = usePermissionDeniedDialog();

  const {
    member: { subscriptionAllowed },
  } = usePermissions();

  return {
    isDenied: !subscriptionAllowed,
    openDeniedDialog: openPermissionDeniedDialog,
  };
};
