import { useCallback, useEffect, useState } from 'react';

export type Option<T extends string | number = string> = {
  value: T;
  text: string;
};

type Args<T extends string | number> = {
  defaultOption?: Option<T>['value'];
  onSelect?: (value: Option<T>['value']) => void;
};

export const useQuestionOptions = <T extends string | number>({ defaultOption, onSelect }: Args<T>) => {
  const [selectedOption, setSelectedOption] = useState<Option<T>['value'] | null>(defaultOption ?? null);

  const selectOption = useCallback(
    (option: Option<T>['value']) => () => {
      setSelectedOption(option);

      onSelect?.(option);
    },
    [onSelect]
  );

  useEffect(() => {
    if (!defaultOption) {
      return;
    }

    setSelectedOption(defaultOption);
  }, [defaultOption]);

  return { selectedOption, selectOption };
};
