import { css } from '@emotion/react';
import { Card, Icon } from '@partstech/ui';
import type { CardProps, IconProps, Theme } from '@partstech/ui';

const actionVariant = {
  small: (theme: Theme) => css`
    height: ${theme.sizing(6)};
    width: ${theme.sizing(6)};
  `,
  large: (theme: Theme) => css`
    height: ${theme.sizing(15)};
    width: ${theme.sizing(15)};

    > .ui-card-inner > .icon {
      font-size: ${theme.sizing(10)};
      width: ${theme.sizing(10)};
      height: ${theme.sizing(10)};
    }
  `,
};

const styles = {
  card: (variant: NonNullable<Props['variant']>) => (theme: Theme) => css`
    cursor: pointer;
    ${actionVariant[variant](theme)}
  `,
};

type Props = {
  iconName: 'chevron_left' | 'chevron_right' | 'search';
  iconSize?: IconProps['size'];
  variant?: 'small' | 'large';
} & CardProps;

export const ImageAction = ({ iconName, iconSize = 'medium', variant = 'small', ...props }: Props) => (
  <Card
    minWidth={6}
    elevation={6}
    display="flex"
    alignItems="center"
    justifyContent="center"
    css={styles.card(variant)}
    {...props}
  >
    <Icon name={iconName} color="subtleText" size={iconSize} />
  </Card>
);
