import { Box, Button, ButtonGroup, Typography, useMedia } from '@partstech/ui';
import { HookForm } from '@partstech/ui/forms';
import { CredentialsWrapper } from '../../CredentialsWrapper';
import { TireConnectBanner } from '../../TireConnectBanner';
import { AdditionalInformation } from './AdditionalInformation';
import { CatalogSupplier } from './CatalogSupplier';
import { ConnectionTimeDescription } from './ConnectionTimeDescription';
import { Credentials } from './Credentials';
import { LocationSelectBox } from './LocationSelectBox';
import { RegistrationInstruction } from './RegistrationInstruction';
import { TutorialUrl } from './TutorialUrl';
import { useEditForm } from './useEditForm';
import type { Store, SupplierAccount } from 'models';

type Props = {
  account?: SupplierAccount;
  store?: Store;
  onSubmit: (store: Store) => void;
  onCancel: () => void;
};

export const EditForm = ({ account, store: currentStore, onSubmit, onCancel }: Props) => {
  const { isMobile } = useMedia();

  const store = account?.store ?? currentStore ?? undefined;
  const supplier = store?.supplier ?? undefined;

  const { form, submitForm, isLoading, disabledSubmitButton, credentialFields, additionalInformation } = useEditForm({
    account,
    store,
    onSubmit,
  });

  if (!supplier || !store) {
    return null;
  }

  return (
    <HookForm form={form} onSubmit={submitForm}>
      <CredentialsWrapper
        store={store}
        account={account}
        actions={
          <>
            <ButtonGroup flexDirection={{ sm: 'row-reverse', md: 'row' }} width={{ sm: '100%', md: 'auto' }}>
              <Button disabled={disabledSubmitButton} type="submit" isLoading={isLoading}>
                {account ? 'Save changes' : 'Submit'}
              </Button>

              <Button onClick={onCancel} variant="text">
                Cancel
              </Button>
            </ButtonGroup>

            {!isMobile && supplier.tutorialUrl && <TutorialUrl url={supplier.tutorialUrl} mr="0" ml="auto" />}
          </>
        }
        footerElement={
          <>
            {isMobile && supplier.tutorialUrl && <TutorialUrl url={supplier.tutorialUrl} />}

            {supplier.hasCredentials && <CatalogSupplier />}
          </>
        }
      >
        {supplier.isTireWithoutCredentials ? (
          <Box mb={4}>
            <Typography>
              Please only add this supplier if you have a wholesale account setup. We'll use your shop information on
              file to work with the supplier and get you connected.
            </Typography>
          </Box>
        ) : (
          <>
            {supplier.registrationInstruction && (
              <RegistrationInstruction htmlContent={supplier.registrationInstruction} />
            )}

            <ConnectionTimeDescription mb={3} connectionTime={supplier.connectionTime} supplierName={supplier.name} />

            <Credentials store={store} fields={credentialFields} />

            {!supplier.isTire && !supplier.isLockedStore && (
              <LocationSelectBox supplier={supplier} storeId={store.id ?? undefined} />
            )}

            {additionalInformation && <AdditionalInformation additionalInformation={additionalInformation} />}
          </>
        )}

        {supplier.isTire && <TireConnectBanner supplier={supplier} />}
      </CredentialsWrapper>
    </HookForm>
  );
};
