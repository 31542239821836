import { useCallback, useMemo } from 'react';
import { useAppNavigate } from 'app/AppRouter/useAppNavigate';
// eslint-disable-next-line no-restricted-imports
import { useGetSearchUrl } from 'hooks/search/useGetSearchUrl';
// eslint-disable-next-line no-restricted-imports
import { useSearchParams } from 'hooks/search/useSearchParams';
import { useOpenAddSupplierModal, useSupplierAccounts } from 'hooks/supplierAccounts';
import { Tires } from '../models';
import { useGetRecentSearches } from './useGetRecentSearches';
import type { RecentSearchInterface } from '../models';

type Props = {
  onSelect?: (recentSearch: RecentSearchInterface) => void;
};

export const useRecentSearches = ({ onSelect }: Props = {}) => {
  const navigate = useAppNavigate();

  const { isDealersSearch } = useSearchParams();

  const { recentSearches, ...props } = useGetRecentSearches();
  const { accounts } = useSupplierAccounts({ type: 'tires' });
  const { getSearchUrlBySearchValues } = useGetSearchUrl();

  const { open: openAddSupplierModal } = useOpenAddSupplierModal();

  const lastTireRecentSearch = useMemo(
    () => recentSearches.find((recentSearch) => recentSearch instanceof Tires),
    [recentSearches]
  );

  const selectRecentSearch = useCallback(
    (recentSearch: RecentSearchInterface) => {
      if (recentSearch instanceof Tires && accounts.length === 0) {
        openAddSupplierModal('all');
      } else {
        const values = recentSearch.toSearchValues();

        navigate(getSearchUrlBySearchValues({ searchValues: values, dealers: isDealersSearch, fitOnly: null }));
      }

      onSelect && onSelect(recentSearch);
    },
    [accounts.length, getSearchUrlBySearchValues, isDealersSearch, navigate, onSelect, openAddSupplierModal]
  );

  return { recentSearches, selectRecentSearch, lastTireRecentSearch, ...props };
};
