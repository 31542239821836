import { FullStory } from '@fullstory/browser';
import { useEffect, useState } from 'react';
import type { FSUserVars } from './useFullStorySetUserVars';

const getAccountType = (isSupplier: boolean): FSUserVars['accountType'] => (isSupplier ? 'supplier' : 'DIFM');

export type IdentifyFullstoryArgs = {
  fullName: string;
  email: string;
  username: string;
  id: string;
  isSupplier: boolean;
  isDemo: boolean;
  shopManagementSystemName: string;
  featureFlag: string[];
  smsValidatedDate: Date | null;
};

type Params = {
  skip?: boolean;
  isDevMode?: boolean;
};

export const useIdentifyFullstory = (
  {
    id,
    email,
    featureFlag,
    fullName,
    isDemo,
    isSupplier,
    shopManagementSystemName,
    smsValidatedDate,
    username,
  }: IdentifyFullstoryArgs,
  { skip = false, isDevMode = false }: Params = {}
) => {
  const [isIdentified, setIsIdentified] = useState(false);

  const shouldBeInitialized = id && !skip;

  useEffect(() => {
    if (!shouldBeInitialized) {
      return;
    }

    const userVars = {
      id,
      accountType: getAccountType(isSupplier),
      displayName: fullName,
      email,
      userName: username,
      shopManagementSystemName,
      smsValidatedDate,
      featureFlag,
      isDemo,
    };

    if (!isDevMode) {
      FullStory.identify(id.toString(), userVars);
    }

    setIsIdentified(true);
  }, [
    email,
    featureFlag,
    fullName,
    id,
    isDemo,
    isDevMode,
    isSupplier,
    shopManagementSystemName,
    shouldBeInitialized,
    smsValidatedDate,
    username,
  ]);

  useEffect(() => {
    if (shouldBeInitialized) {
      return;
    }

    setIsIdentified(false);
  }, [shouldBeInitialized]);

  return isIdentified;
};
