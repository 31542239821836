/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type ActivateCartMutationVariables = Types.Exact<{
  input: Types.ActivateCartInput;
}>;

export type ActivateCartMutation = {
  __typename?: 'Mutation';
  activateCart?: {
    __typename?: 'ActivateCartPayload';
    cart?: { __typename?: 'Cart'; id: string; active: Types.Scalars['Boolean']['input'] } | null;
  } | null;
};

export const ActivateCartDocument = `
    mutation ActivateCart($input: ActivateCartInput!) {
  activateCart(input: $input) {
    cart {
      id
      active
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    ActivateCart: build.mutation<ActivateCartMutation, ActivateCartMutationVariables>({
      query: (variables) => ({ document: ActivateCartDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useActivateCartMutation } = injectedRtkApi;
