import { Box } from '@partstech/ui';
import { usePrevious } from '@partstech/ui/hooks';
import { SignUpPages } from 'utils/url';
import { useCreateShopContext } from '../../CreateShopContext/CreateShopContext';
import { Description } from './Description';
import { HexNutsAndHexagons } from './HexNutsAndHexagons';
import type { variants } from './HexNutsAndHexagons/variants';

const urlPathNumberMap: Partial<Record<SignUpPages | 'select-shop-with-google', keyof typeof variants>> = {
  [SignUpPages.ShopType]: 1,
  'select-shop-with-google': 2,
  [SignUpPages.ShopDetails]: 3,
  [SignUpPages.ManagementSystem]: 4,
  [SignUpPages.PartsSuppliers]: 5,
  [SignUpPages.TireSuppliers]: 6,
  [SignUpPages.Role]: 7,
  [SignUpPages.HearAboutUs]: 8,
  [SignUpPages.PhoneNumber]: 9,
};

export const StepDescription = () => {
  const { activeStep, selectedShop } = useCreateShopContext();

  const id = !selectedShop && activeStep.id === SignUpPages.ShopDetails ? 'select-shop-with-google' : activeStep.id;

  const previousId = usePrevious(id);

  const previousStepIndex = urlPathNumberMap[previousId ?? id];
  const currentStepIndex = urlPathNumberMap[id];

  if (!previousStepIndex) {
    return null;
  }

  return (
    <Box maxWidth={67}>
      <Box position="relative">
        <HexNutsAndHexagons currentIndex={previousStepIndex} nextIndex={currentStepIndex} />

        <Box
          position="absolute"
          top="46%"
          left="50%"
          transform="translate(-50%, -50%)"
          textAlign="center"
          maxWidth={57}
          width="100%"
        >
          <Description id={id} />
        </Box>
      </Box>
    </Box>
  );
};
