import { type PropsWithChildren } from 'react';
import { FullStoryProvider } from 'integrations/fullstory';
import { LaunchDarklyProvider } from 'integrations/launchDarkly';
import { useGetFullStoryContext } from './useGetFullStoryContext';
import { useGetLaunchDarklyContext } from './useGetLaunchDarklyContext';

export const IntegrationWrapper = ({ children }: PropsWithChildren) => {
  const launchDarklyContext = useGetLaunchDarklyContext();

  const fullstoryContext = useGetFullStoryContext();

  return (
    <LaunchDarklyProvider userContext={launchDarklyContext}>
      <FullStoryProvider userContext={fullstoryContext}>{children}</FullStoryProvider>
    </LaunchDarklyProvider>
  );
};
