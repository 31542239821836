/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import type * as Types from '../../../../../shared/api/graphql/graphql';

export type MatrixFragment = {
  __typename?: 'Matrix';
  createdAt: Types.Scalars['DateTime']['input'];
  id: string;
  name: Types.Scalars['String']['input'];
  type: Types.MatrixType;
  brands?: Array<{ __typename?: 'Brand'; id: string; name: Types.Scalars['String']['input'] }> | null;
  items: Array<{
    __typename?: 'MatrixItem';
    endPrice: Types.Scalars['BigDecimal']['input'];
    price: Types.Scalars['BigDecimal']['input'];
    startPrice: Types.Scalars['BigDecimal']['input'];
    fixed: Types.Scalars['Boolean']['input'];
  }>;
  subCategories?: Array<{
    __typename?: 'MatrixSubCategory';
    subCategory: { __typename?: 'PartSubCategory'; id: string; name: Types.Scalars['String']['input'] };
    partTypes: Array<{
      __typename?: 'PartType';
      id: string;
      application: Types.PartTypeApplication;
      name: Types.Scalars['String']['input'];
    }>;
  }> | null;
};

export const MatrixFragmentDoc = `
    fragment Matrix on Matrix {
  brands {
    id
    name
  }
  createdAt
  id
  items {
    endPrice
    price
    startPrice
    fixed
  }
  name
  subCategories {
    subCategory {
      id
      name
    }
    partTypes {
      id
      application
      name
    }
  }
  type
}
    `;
