import { css, keyframes } from '@emotion/react';
import type { SerializedStyles } from '@emotion/react';
import type { Theme } from '@partstech/ui';

type ContainerProps = {
  isOpened?: boolean;
  isYMMForm?: boolean;
  boxStyle?: (theme: Theme) => SerializedStyles;
};

const animation = {
  effect: '0.3s ease-in-out',
  frames: (top: number) => keyframes` 0% { top: 20px; opacity: 0; } 100% { top: ${top}px; opacity: 1; }`,
};

export const mobileHeaderHeight = 15;

export const styles = {
  container:
    ({ isOpened, isYMMForm, boxStyle }: ContainerProps) =>
    (theme: Theme) => css`
      position: absolute;
      top: ${isOpened ? theme.sizing(18) : theme.sizing(5)};
      right: 0;
      min-width: ${theme.sizing(153)};
      animation: ${animation.frames(72)} ${animation.effect};
      z-index: ${theme.zIndex.popover};
      ${boxStyle && boxStyle(theme)};

      .ui-card-inner {
        overflow: hidden;
      }

      ${theme.breakpoints.down('md')} {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        min-width: 100%;
        min-height: 100vh;
        animation: none;
        > .ui-card-inner:first-of-type {
          border-radius: 0;
          background: ${theme.color.white};
        }
      }

      ${theme.breakpoints.between('md', 'lg')} {
        position: fixed;
        top: ${theme.sizing(5)};
        left: ${theme.sizing(5)};
        right: ${theme.sizing(5)};
        width: auto;
        margin: 0;
      }

      ${theme.breakpoints.between('lg', 'xl')} {
        position: fixed;
        top: ${isOpened ? theme.sizing(23) : theme.sizing(5)};
        left: 50%;
        width: ${isYMMForm ? theme.sizing(170) : theme.sizing(133)};
        max-height: calc(100vh - ${theme.sizing(10)});
        margin: 0 0 0 -${isYMMForm ? theme.sizing(85) : theme.sizing(66)};
        animation: ${animation.frames(90)} ${animation.effect};
      }
    `,
  formWrapper: (theme: Theme) => css`
    margin-top: ${theme.sizing(2)};
    width: 100%;

    ${theme.breakpoints.down('md')} {
      margin-top: ${theme.sizing(4)};
      overflow-y: auto;
    }
  `,
  visibility: (isVisible: boolean) => () => css`
    display: ${isVisible ? 'block' : 'none'};
  `,
  modalCloseButton: (theme: Theme) => css`
    display: flex;
    align-items: center;
    height ${theme.sizing(6)};
    max-height: ${theme.sizing(6)};
    padding: 0;
    cursor: pointer;
    .icon {
      margin-right: ${theme.sizing(7.5)};
    }
  `,
  vehicleSelector: (theme: Theme) => css`
    ${theme.breakpoints.down('md')} {
      > .ui-card-inner:first-of-type {
        height: calc(100vh - ${theme.sizing(mobileHeaderHeight)});
        max-height: calc(100vh - ${theme.sizing(mobileHeaderHeight)});
        overflow-y: auto;
      }
    }
  `,
};
