import { captureException } from '@sentry/browser';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';

export const useLaunchDarklyStatus = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const client = useLDClient();

  useEffect(() => {
    if (client) {
      const checkLoaded = async () => {
        try {
          await client.waitForInitialization(5);
        } catch (e) {
          captureException(e);
        } finally {
          setIsLoaded(true);
        }
      };

      checkLoaded();
    }
  }, [client]);

  return {
    isLoaded,
  };
};
