import { makeContext } from '@partstech/ui/utils';
import type { SearchEntryPointInterface, SearchTabs } from 'features/searchForm';
import type { Vehicle } from 'models';
import type { SearchTypes } from 'types/search';

type SearchBarContextValue = {
  openVehicleSelector: () => void;
  closeVehicleSelector: () => void;
  isSelectVehicleOpen: boolean;

  changeEntryPointsTab: (tab: SearchTabs) => void;
  changeSearchEntry: (searchEntry: SearchEntryPointInterface | null) => void;
  changeSearchType: (searchType: SearchTypes) => void;
  changeVehicle: (vehicle: Vehicle | null, silent?: boolean) => void;
  entryPointsTab: SearchTabs;
  searchEntry: SearchEntryPointInterface | null;
  searchFormType: SearchTypes;
  startSearch: (searchEntry: SearchEntryPointInterface, vehicle: Vehicle | null) => void;
  vehicle: Vehicle | null;
  resetSearch: () => void;
};

export const [useSearchBar, SearchBarContextProvider] = makeContext<SearchBarContextValue>();
