import { useCallback } from 'react';
import { useMutationCallback } from 'shared/api';
import { useCreateOrUpdateOnboardStepMutation } from '../api/mutations/onboardStep';
import type { CreateOrUpdateOnboardStepInput } from 'shared/api';

type Args = {
  onSuccess?: () => Promise<void> | void;
};

export const useCreateOnboardStep = ({ onSuccess }: Args) => {
  const [createStep, status] = useMutationCallback(useCreateOrUpdateOnboardStepMutation, { onSuccess });

  const createOnboardStep = useCallback((input: CreateOrUpdateOnboardStepInput) => createStep({ input }), [createStep]);

  return [createOnboardStep, status] as const;
};
