import { useMedia } from '@partstech/ui';
import { useEffect, useMemo } from 'react';
import { useHeaderHeight, useProfileBar } from 'hooks/app';
import { LayoutContextProvider } from './LayoutContext';
import type { PropsWithChildren } from 'react';

export const LayoutProvider = ({ children }: PropsWithChildren) => {
  const { isMobile } = useMedia();

  const {
    isOpen: isOpenProfileBar,
    toggle: toggleProfileBar,
    open: openProfileBar,
    close: closeProfileBar,
    uiOffset: uiOffsetProfileBar,
    setUIOffset,
  } = useProfileBar();

  const { headerRef, headerHeight } = useHeaderHeight();

  const value = useMemo(
    () => ({
      isOpenProfileBar,
      toggleProfileBar,
      openProfileBar,
      closeProfileBar,
      setUIOffsetProfileBar: setUIOffset,
      uiOffsetProfileBar,
      headerRef,
      headerHeight,
    }),
    [
      closeProfileBar,
      headerHeight,
      headerRef,
      isOpenProfileBar,
      openProfileBar,
      setUIOffset,
      toggleProfileBar,
      uiOffsetProfileBar,
    ]
  );

  useEffect(() => {
    if (!isMobile) {
      return;
    }

    const htmlElement = document.querySelector('html');

    if (htmlElement) {
      htmlElement.style.setProperty('--vw', `${window.innerWidth / 100}px`);
      htmlElement.style.setProperty('--vh', `${window.innerHeight / 100}px`);
    }
  }, [isMobile]);

  return <LayoutContextProvider value={value}>{children}</LayoutContextProvider>;
};
