/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../shared/api/graphql/graphql';

export type GetPartTypesWithSubCategoryQueryVariables = Types.Exact<{
  ids?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
  categoryID?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;

export type GetPartTypesWithSubCategoryQuery = {
  __typename?: 'Query';
  partTypes?: Array<{
    __typename?: 'PartType';
    id: string;
    name: Types.Scalars['String']['input'];
    application: Types.PartTypeApplication;
    subCategory?: { __typename?: 'PartSubCategory'; id: string; name: Types.Scalars['String']['input'] } | null;
  }> | null;
};

export const GetPartTypesWithSubCategoryDocument = `
    query GetPartTypesWithSubCategory($ids: [ID!], $search: String, $categoryID: ID) {
  partTypes(IDs: $ids, search: $search, categoryID: $categoryID) {
    id
    name
    application
    subCategory {
      id
      name
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetPartTypesWithSubCategory: build.query<
      GetPartTypesWithSubCategoryQuery,
      GetPartTypesWithSubCategoryQueryVariables | void
    >({
      query: (variables) => ({ document: GetPartTypesWithSubCategoryDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetPartTypesWithSubCategoryQuery, useLazyGetPartTypesWithSubCategoryQuery } = injectedRtkApi;
