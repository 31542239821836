import { useMemo } from 'react';
import { createPartTypeFromQuery, createSubCategoryFromQuery } from 'factories';
import { useGetSubCategoryQuery } from './GetSubCateogry.generated';
import type { PartType } from 'models';

const emptyPartTypes: PartType[] = [];

type Props = {
  categoryId: string | number | undefined;
  subCategoryId?: string | null;
  searchValue?: string;
};

export const useSubCategory = ({ categoryId, subCategoryId, searchValue }: Props) => {
  const { currentData, ...props } = useGetSubCategoryQuery(
    { categoryId: String(categoryId), subCategoryId: String(subCategoryId) },
    { skip: Boolean(!categoryId || !subCategoryId) }
  );

  const subCategory = useMemo(
    () =>
      currentData?.category?.subCategory
        ? createSubCategoryFromQuery(
            currentData?.category?.subCategory,
            String(categoryId),
            currentData.category.subCategory.partTypes?.map((partType) =>
              createPartTypeFromQuery(partType).setSubCategoryId(currentData?.category?.subCategory?.id ?? '')
            )
          )
        : null,
    [categoryId, currentData?.category?.subCategory]
  );

  const partTypes = useMemo(() => {
    if (!subCategory) {
      return [];
    }

    const subCategoryPartTypes =
      subCategory.partTypes?.map((partType) => createPartTypeFromQuery(partType)) ?? emptyPartTypes;

    if (searchValue) {
      return subCategoryPartTypes.filter((partType) => partType.name.toLowerCase().includes(searchValue.toLowerCase()));
    }

    return subCategoryPartTypes;
  }, [searchValue, subCategory]);

  return {
    subCategory,
    partTypes,
    ...props,
  };
};
