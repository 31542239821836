import { Box, Button } from '@partstech/ui';
import { useCallback } from 'react';
import { useAppNavigate } from 'app/AppRouter/useAppNavigate';
import { useLastUrl } from 'hooks/localStorage';
import type { BoxProps, IconProps } from '@partstech/ui';

type Props = {
  title: string;
  icon?: IconProps['name'];
  url?: string | null;
} & BoxProps;

export const GoBackButton = ({ title, url, icon = 'arrow_back', ...boxProps }: Props) => {
  const navigate = useAppNavigate();
  const [lastUrl] = useLastUrl();

  const handleArrowBackClick = useCallback(() => {
    if (url) {
      navigate(url);
    } else {
      navigate(lastUrl ?? -1);
    }
  }, [lastUrl, navigate, url]);

  return (
    <Box {...boxProps}>
      <Button variant="text" leadingIcon={icon} onClick={handleArrowBackClick} data-testid="goBackButton">
        {title}
      </Button>
    </Box>
  );
};
