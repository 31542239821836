import { uniq } from '@partstech/ui/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAddItemsToCart } from 'features/cart/api/mutations/addItemsToCart';
import { useAddItemToCart } from 'features/cart/api/mutations/addItemToCart';
import { useDeleteOrderItems } from 'features/cart/api/mutations/deleteCartOrderItems';
import { usePermissions } from 'store/queries/currentUser/usePermissions';
import { useChangeCartItemQuantity } from '../api/mutations/changeCartItemQuantity';
import { useLazyGetActiveCartQuery } from '../api/queries/GetActiveCart.generated';
import { useLazyGetActiveCartOrdersQuery } from '../api/queries/useGetActiveCartOrders';
import { useRemoveFromCartTracking } from './useRemoveFromCartTracking';
import type { Highlights } from './useCartHighlights';
import type { AddToCartPayload, QueueItem } from '../types';
import type { CartOrderItem } from 'models';
import type { AddItemToActiveCartPayload, AddItemToActiveCartResult } from 'shared/api';

type Props = {
  startRemoving: (orderItem: CartOrderItem, onTimeout: () => void) => void;
  onRemoved: (orderItemId: string) => void;
  addHighlights: (value: Highlights) => void;
  onUpdateCart: () => void;
  isCartLoaded: boolean;
  hasCart: boolean;
};

export const useCartQueue = ({
  startRemoving,
  onRemoved,
  addHighlights,
  onUpdateCart,
  isCartLoaded,
  hasCart,
}: Props) => {
  const {
    shop: { laborAllowed },
  } = usePermissions();

  const { trackRemoveFromCart } = useRemoveFromCartTracking();

  const [queue, setQueue] = useState<QueueItem[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [orderIdsToUpdate, setOrderIdsToUpdate] = useState<string[]>([]);

  const isRemovingBlocked = useMemo(() => queue.some((item) => item.type === 'add'), [queue]);

  const [fetchCart] = useLazyGetActiveCartQuery();
  const [fetchOrders] = useLazyGetActiveCartOrdersQuery();
  const [addItemToCart] = useAddItemToCart();
  const [addItemsToCart] = useAddItemsToCart();
  const [removeOrderItems] = useDeleteOrderItems();
  const [changeCartItemQuantity] = useChangeCartItemQuantity();

  const updateOrders = useCallback(async () => {
    if (orderIdsToUpdate.length > 0 && isCartLoaded) {
      setIsProcessing(true);
      hasCart
        ? await fetchOrders({ ids: orderIdsToUpdate, withLabor: laborAllowed })
        : await fetchCart({ withLabor: laborAllowed });

      setOrderIdsToUpdate([]);
      onUpdateCart();
      setIsProcessing(false);
    }
  }, [fetchCart, fetchOrders, hasCart, isCartLoaded, laborAllowed, onUpdateCart, orderIdsToUpdate]);

  const proceedQueue = useCallback(
    async (item: QueueItem) => {
      setIsProcessing(true);

      if (item.type === 'add') {
        const { data } = await addItemToCart(item.payload);

        if (item.onFinally && data?.addItemToActiveCart) {
          item.onFinally(data.addItemToActiveCart);
        }

        if (data?.addItemToActiveCart && 'orderId' in data.addItemToActiveCart) {
          const { orderId, orderItemId } = data.addItemToActiveCart;
          setOrderIdsToUpdate((prev) => uniq([...prev, orderId]));
          addHighlights({ [orderItemId]: 'info' });
        }
      }

      if (item.type === 'addMultiple') {
        const { data } = await addItemsToCart(item.payload);

        if (item.onFinally && data?.batchAddItemsToActiveCart?.results) {
          item.onFinally(data.batchAddItemsToActiveCart?.results);
        }

        data?.batchAddItemsToActiveCart?.results.forEach((resultData) => {
          if ('orderId' in resultData.result) {
            const { orderId, orderItemId } = resultData.result;
            setOrderIdsToUpdate((prev) => uniq([...prev, orderId]));
            addHighlights({ [orderItemId]: 'info' });
          }
        });
      }

      if (item.type === 'changeQuantity') {
        const { orderItem, quantity } = item.payload;
        const res = await changeCartItemQuantity({ itemId: orderItem.id, quantity });

        if (res.data?.updateActiveCartItemQuantity && 'orderId' in res.data.updateActiveCartItemQuantity) {
          const { orderId } = res.data.updateActiveCartItemQuantity;
          setOrderIdsToUpdate((prev) => uniq([...prev, orderId]));
        }
      }

      if (item.type === 'remove') {
        await removeOrderItems({ itemIds: [item.payload.id] });
        onRemoved(item.payload.id);
        trackRemoveFromCart(item.payload);
      }

      setQueue((prev) => prev.slice(1));
      setIsProcessing(false);
    },
    [
      addHighlights,
      addItemToCart,
      addItemsToCart,
      changeCartItemQuantity,
      onRemoved,
      removeOrderItems,
      trackRemoveFromCart,
    ]
  );

  const addToCart = useCallback(
    (item: AddToCartPayload, onFinally?: (response: AddItemToActiveCartPayload) => void) => {
      setQueue((prev) => [...prev, { type: 'add', payload: item, onFinally }]);
    },
    []
  );

  const addToCartMultiple = useCallback(
    (items: AddToCartPayload[], onFinally?: (response: AddItemToActiveCartResult[]) => void) => {
      setQueue((prev) => [...prev, { type: 'addMultiple', payload: items, onFinally }]);
    },
    []
  );

  const changeQuantity = useCallback((orderItem: CartOrderItem, quantity: number) => {
    setQueue((prev) => [...prev, { type: 'changeQuantity', payload: { orderItem, quantity } }]);
  }, []);

  const removeOrderItem = useCallback(
    (orderItem: CartOrderItem, orderId: string) => {
      if (!isRemovingBlocked) {
        startRemoving(orderItem, () => {
          setQueue((prev) => [...prev, { type: 'remove', payload: orderItem }]);
          setOrderIdsToUpdate((prev) => uniq([...prev, orderId]));
        });
      }
    },
    [isRemovingBlocked, startRemoving]
  );

  useEffect(() => {
    if (!isProcessing && queue[0] && isCartLoaded) {
      proceedQueue(queue[0]);
    }
  }, [isCartLoaded, isProcessing, proceedQueue, queue]);

  useEffect(() => {
    if (!isProcessing && !queue[0]) {
      updateOrders();
    }
  }, [isProcessing, queue, updateOrders]);

  return {
    addToCart,
    addToCartMultiple,
    removeOrderItem,
    changeQuantity,
    isLoading: queue.length > 0 || orderIdsToUpdate.length > 0,
  };
};
