import { BottomSheet, Box, Divider, Scrollbar, Typography } from '@partstech/ui';
import { useToggle } from '@partstech/ui/hooks';
import { useCallback } from 'react';
import { currency } from 'shared/lib/string';
import type { LaborLine } from '../../../utils';

type Props = {
  laborLines: LaborLine[];
  totalHours: number;
  totalPrice: string;
  rate: number;
  onClose: () => void;
};

export const LaborDetailsMobile = ({ laborLines, totalHours, totalPrice, rate, onClose }: Props) => {
  const { isToggle: isMaxHeight, turnOn: expand, turnOff: collapse } = useToggle();

  const handleSwipe = useCallback(
    (isSwipeToUp: boolean) => {
      if (isSwipeToUp) {
        expand();
      } else if (isMaxHeight) {
        collapse();
      } else {
        onClose();
      }
    },
    [collapse, expand, isMaxHeight, onClose]
  );

  return (
    <BottomSheet
      pt={0}
      pb={0}
      px={0}
      mode="expandable"
      hasOverlay
      isExpanded
      isMaxHeight={isMaxHeight}
      height={376}
      onClickAway={onClose}
      onExpandToggle={handleSwipe}
    >
      <Box height={isMaxHeight ? (window.innerHeight - 72) / 4 : 88} display="flex" flexDirection="column" pb={6}>
        <Scrollbar>
          <Box mb={6} mt={6} px={4}>
            <Typography variant="subtitle1">Labor and rate breakdown</Typography>
          </Box>

          {laborLines.map((line) => (
            <Box key={line.id} display="flex" justifyContent="space-between" mb={4} color="defaultText" gap={4} px={4}>
              <Typography variant="caption" color="inherit">
                {line.name}
              </Typography>

              <Typography variant="caption" component="p" color="inherit" noWrap>
                {line.duration} hrs x {currency(rate || 0)}
              </Typography>
            </Box>
          ))}
        </Scrollbar>

        <Box px={4}>
          <Divider color="mono6" />
        </Box>

        <Box mt={4} px={4} display="flex" justifyContent="space-between">
          <Typography variant="subtitle1">Total ({totalHours} hrs)</Typography>
          <Typography variant="subtitle1">{totalPrice}</Typography>
        </Box>

        {rate === 0 && (
          <Box px={4} mt={1}>
            <Typography variant="caption" color="subtleText">
              To see the price breakdown, make sure to add a labor rate in the labor page.
            </Typography>
          </Box>
        )}
      </Box>
    </BottomSheet>
  );
};
