import { useSearchResults } from 'pages/SearchResults/SearchResultsContext';

type Props = {
  supplierAccountId: string | null | undefined;
};

export const useSearchError = ({ supplierAccountId }: Props) => {
  const { getErrorsBySupplierAccountId } = useSearchResults();

  return {
    errors: getErrorsBySupplierAccountId(supplierAccountId ?? null),
  };
};
