import { useMutationStatus } from 'shared/api';
import { api as generatedApi } from './ActivateCart.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    ActivateCart: {
      invalidatesTags: ['ActiveCart'],
    },
  },
});

const { useActivateCartMutation } = api;

export const useActivateCart = () => {
  const [activateCart, status] = useActivateCartMutation();

  useMutationStatus({ status });

  return [activateCart, status] as const;
};
