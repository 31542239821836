import { useCallback } from 'react';
import { useInputValue } from 'shared/lib/form';
import { ConfirmEditProfileForm } from './ConfirmEditProfileForm';
import { EditProfileForm } from './EditProfileForm';
import type { UpdateUserInformationInput } from 'shared/api';

type Props = {
  defaultValues?: UpdateUserInformationInput;
  phoneShop?: string;
  onSubmit: () => void;
  onCancel: () => void;
};

export const EditProfileInfo = ({ defaultValues, phoneShop, onCancel, onSubmit }: Props) => {
  const { value, changeValue, clearValue } = useInputValue('');

  const handleSubmit = useCallback(
    (newEmail: string | null) => {
      if (!newEmail) {
        onSubmit();
        return;
      }

      changeValue(newEmail);
    },
    [changeValue, onSubmit]
  );

  if (value) {
    return <ConfirmEditProfileForm newEmail={value} onSubmit={onSubmit} onCancel={clearValue} />;
  }

  return (
    <EditProfileForm defaultValues={defaultValues} phoneShop={phoneShop} onSubmit={handleSubmit} onCancel={onCancel} />
  );
};
