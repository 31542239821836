import type { Location } from 'react-router-dom';

export const generateLocation = (
  pathname: string,
  state?: Location['state'],
  search?: string,
  hash?: string,
  key?: string
): Location => ({
  pathname,
  search: search || '',
  hash: hash || '',
  state: state || '',
  key: key || '',
});
