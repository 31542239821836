import { Status } from '@partstech/ui';
import type { StatusProps } from '@partstech/ui';

type Props = StatusProps & { text: string };

export const BaseStatus = ({ color, leadingIcon, onClick, secondaryText, text, trailingIcon }: Props) => (
  <Status
    color={color}
    leadingIcon={leadingIcon}
    onClick={onClick}
    secondaryText={secondaryText}
    trailingIcon={trailingIcon}
    variant="fancy"
  >
    {text}
  </Status>
);
