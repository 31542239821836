import { uniqBy } from '@partstech/ui/utils';
import { useMemo } from 'react';
import type { Diagram } from 'models';

type Props = {
  diagrams: Diagram[];
  search?: string;
  skip?: boolean;
};

const emptyDiagrams: Diagram[] = [];

export const useDiagramSearch = ({ diagrams, search = '', skip = false }: Props) => {
  const filteredDiagrams = useMemo(() => {
    if (skip) {
      return emptyDiagrams;
    }

    return search
      ? uniqBy(
          diagrams.filter((diagram) => diagram.isMatchedToString(search)),
          'name'
        )
      : diagrams;
  }, [diagrams, search, skip]);

  return { diagrams: filteredDiagrams };
};
