import { Box, Icon, Typography } from '@partstech/ui';
import type { BoxProps, ThemeColorName } from '@partstech/ui';
import type { Supplier } from 'shared/api/rest/gen/shop';

const iconColors: Record<Exclude<Supplier['connectionTime'], 'NA'>, ThemeColorName> = {
  LESS_ONE_MIN: 'positive',
  TODAY: 'primaryLight',
  NEXT_DAY: 'neutral',
  '1_PLUS_DAYS': 'secondaryDark10',
};

const values: Record<Exclude<Supplier['connectionTime'], 'NA'>, string> = {
  LESS_ONE_MIN: '< 1 MIN',
  TODAY: 'TODAY',
  NEXT_DAY: 'TOMORROW',
  '1_PLUS_DAYS': '1+ DAYS',
};

type Props = BoxProps & {
  connectionTime: Supplier['connectionTime'];
};

export const SupplierConnectionTime = ({ connectionTime, ...props }: Props) => {
  if (connectionTime === 'NA') {
    return null;
  }

  return (
    <Box display="flex" alignItems="center" gap={1} {...props}>
      <Icon name="radio_button_unchecked" color={iconColors[connectionTime]} size="medium" />

      <Typography variant="overline">CONNECTION TIME: {values[connectionTime]}</Typography>
    </Box>
  );
};
