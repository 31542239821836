import { ModalLayout } from 'components/Layout/ModalLayout';
import { modalRoutes } from './modalRoutes';
import { ProtectedRoute } from './ProtectedRoute';
import type { RouteObject } from 'react-router-dom';

export const getModalPages = (): RouteObject[] =>
  modalRoutes.map(({ permission, ...routeParams }) => ({
    element: (
      <ProtectedRoute permission={permission}>
        <ModalLayout size="fullScreen" />
      </ProtectedRoute>
    ),
    children: [routeParams],
  }));
