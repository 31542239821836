/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import { VehicleFragmentDoc } from '../../../../store/queries/vehicle/VehicleFragment.generated';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type GetSavedCartListQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sortOrder?: Types.InputMaybe<Types.SortOrder>;
  statuses?: Types.InputMaybe<Array<Types.CartStatusFilter> | Types.CartStatusFilter>;
}>;

export type GetSavedCartListQuery = {
  __typename?: 'Query';
  carts?: {
    __typename?: 'CartConnection';
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: Types.Scalars['String']['input'] | null;
      hasNextPage: Types.Scalars['Boolean']['input'];
      hasPreviousPage: Types.Scalars['Boolean']['input'];
    };
    edges?: Array<{
      __typename?: 'CartEdge';
      node: {
        __typename?: 'Cart';
        active: Types.Scalars['Boolean']['input'];
        id: string;
        improvedStatus: Types.CartImprovedStatus;
        quoteType: Types.CartQuoteType;
        repairOrderNumber?: Types.Scalars['String']['input'] | null;
        status: Types.CartStatus;
        updatedAt: Types.Scalars['DateTime']['input'];
        user?: {
          __typename?: 'User';
          firstName: Types.Scalars['String']['input'];
          lastName: Types.Scalars['String']['input'];
        } | null;
        orders?: Array<{
          __typename?: 'CartOrder';
          id: string;
          purchaseOrderNumber?: Types.Scalars['String']['input'] | null;
          items?: Array<{ __typename?: 'CartOrderItem'; id: string; quantity: number }> | null;
        }> | null;
        paidOrders?: Array<{
          __typename?: 'Order';
          id: string;
          purchaseOrderNumber: Types.Scalars['String']['input'];
          lines?: Array<{ __typename?: 'OrderItem'; id: string; quantity: number }> | null;
        }> | null;
        vehicles?: Array<{
          __typename?: 'CartLinkedVehicle';
          id: string;
          source: Types.CartLinkedVehicleSource;
          vin?: Types.Scalars['String']['input'] | null;
          vehicle?: {
            __typename?: 'Vehicle';
            id: string;
            type: Types.VehicleType;
            regions?: Array<Types.VehicleRegion> | null;
            year: number;
            make: { __typename?: 'VehicleMake'; id: string; name: Types.Scalars['String']['input']; priority: number };
            model: { __typename?: 'VehicleModel'; id: string; name: Types.Scalars['String']['input'] };
            subModel: { __typename?: 'VehicleSubModel'; id: string; name: Types.Scalars['String']['input'] };
            engine: { __typename?: 'VehicleEngine'; id: string; name: Types.Scalars['String']['input'] };
          } | null;
        }> | null;
      };
    }> | null;
  } | null;
};

export const GetSavedCartListDocument = `
    query GetSavedCartList($after: String, $first: Int, $search: String, $sortOrder: SortOrder = DESC, $statuses: [CartStatusFilter!]) {
  carts(
    after: $after
    first: $first
    search: $search
    sortOrder: $sortOrder
    statuses: $statuses
  ) {
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      node {
        active
        id
        improvedStatus
        user {
          firstName
          lastName
        }
        orders {
          id
          purchaseOrderNumber
          items {
            id
            quantity
          }
        }
        paidOrders {
          id
          purchaseOrderNumber
          lines {
            id
            quantity
          }
        }
        quoteType
        repairOrderNumber
        status
        updatedAt
        vehicles {
          id
          source
          vin
          vehicle {
            ...Vehicle
          }
        }
      }
    }
  }
}
    ${VehicleFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetSavedCartList: build.query<GetSavedCartListQuery, GetSavedCartListQueryVariables | void>({
      query: (variables) => ({ document: GetSavedCartListDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetSavedCartListQuery, useLazyGetSavedCartListQuery } = injectedRtkApi;
