import { Button } from '@partstech/ui';
import { BackgroundCard } from '../../BackgroundCard';
import { Title } from '../../Title';
import { MatchingList } from './MatchingList';
import type { CardProps } from '@partstech/ui';
import type { TireConfiguration } from 'models';

type Props = Omit<CardProps, 'onSelect'> & {
  hasVehicle: boolean;
  onSelectVehicleClick?: () => void;
  onSelect: (option: TireConfiguration) => void;
  options: TireConfiguration[];
};

export const MatchingTireConfigurations = ({
  hasVehicle,
  onSelect,
  onSelectVehicleClick,
  options,
  ...props
}: Props) => {
  if (!hasVehicle) {
    return (
      <BackgroundCard data-testid="matchingTiresConfigurations" {...props}>
        <Title>To view matching tires, select a vehicle</Title>
        <Button variant="secondary" onClick={onSelectVehicleClick} size="dense">
          Select vehicle
        </Button>
      </BackgroundCard>
    );
  }

  if (options.length === 0) {
    return (
      <BackgroundCard data-testid="matchingTiresConfigurations">
        <Title>No matching tire sizes found</Title>
      </BackgroundCard>
    );
  }

  return (
    <BackgroundCard data-testid="matchingTiresConfigurations" {...props}>
      <Title>Tires that match your vehicle</Title>
      <MatchingList options={options} onSelect={onSelect} />
    </BackgroundCard>
  );
};
