import { Icon } from '@partstech/ui';
import React from 'react';
import { styles } from '../Carousel.styles';

type PreviousProps = {
  disabled: boolean;
  isVertical: boolean;
  onClick: (e: React.MouseEvent) => void;
  useBallControls?: boolean;
};

export const Previous: React.FunctionComponent<PreviousProps> = ({
  disabled,
  isVertical,
  onClick,
  useBallControls,
}) => (
  <a
    href="components/Carousel/Previous/index#"
    css={styles.control({ type: 'previous', isVertical, disabled, useBallControls })}
    onClick={(e) => !disabled && onClick(e)}
  >
    <Icon name={isVertical ? 'expand_less' : 'chevron_left'} />
  </a>
);
