/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import { SupplierAccountFragmentDoc } from '../../../../queries/currentUser/supplierAccounts/SupplierAccount.generated';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type UpdateSupplierAccountWithoutStoreMutationVariables = Types.Exact<{
  input: Types.UpdateShopAccountInput;
}>;

export type UpdateSupplierAccountWithoutStoreMutation = {
  __typename?: 'Mutation';
  updateShopAccount?: {
    __typename?: 'UpdateShopAccountPayload';
    account?: {
      __typename?: 'Account';
      customerDescription?: Types.Scalars['String']['input'] | null;
      id: string;
      nickname?: Types.Scalars['String']['input'] | null;
      priority: number;
      status: Types.AccountStatus;
      additionalInformation: Array<{
        __typename?: 'AccountField';
        name: Types.Scalars['String']['input'];
        value?: Types.Scalars['String']['input'] | null;
      }>;
      credentials: Array<{
        __typename?: 'AccountField';
        name: Types.Scalars['String']['input'];
        value?: Types.Scalars['String']['input'] | null;
      }>;
    } | null;
  } | null;
};

export const UpdateSupplierAccountWithoutStoreDocument = `
    mutation UpdateSupplierAccountWithoutStore($input: UpdateShopAccountInput!) {
  updateShopAccount(input: $input) {
    account {
      ...SupplierAccount
    }
  }
}
    ${SupplierAccountFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    UpdateSupplierAccountWithoutStore: build.mutation<
      UpdateSupplierAccountWithoutStoreMutation,
      UpdateSupplierAccountWithoutStoreMutationVariables
    >({
      query: (variables) => ({ document: UpdateSupplierAccountWithoutStoreDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUpdateSupplierAccountWithoutStoreMutation } = injectedRtkApi;
