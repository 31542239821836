import { useMemo } from 'react';
import { createPartTypesFromQuery } from 'factories';
import { useGetFullPartTypes } from 'store/queries/partTypes/useGetFullPartTypes';

type Args = {
  search?: string;
  ids?: string[];
  categoryID?: string;
  alias?: string;
};

type Params = {
  skip?: boolean;
};

export const useFullPartTypes = ({ ids, search, categoryID, alias }: Args, { skip = false }: Params = {}) => {
  const { partTypes: partTypesData, ...props } = useGetFullPartTypes({ ids, search, categoryID }, { skip });

  const partTypes = useMemo(() => createPartTypesFromQuery(partTypesData, alias), [alias, partTypesData]);

  return {
    partTypes,
    ...props,
  };
};
