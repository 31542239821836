import type { ProductUrlParams, ProductUrlQueryParams } from 'types/product';

export const urlParamsToQuery = (urlParams: ProductUrlParams): ProductUrlQueryParams => ({
  partnumberid: encodeURIComponent(urlParams.partNumberId).replaceAll('%20', '+'),
  part_term: urlParams.partTypeId,
  credentialId: urlParams.credentialId,
  supplierId: urlParams.supplierId,
  linecard: urlParams.lineCardId,
  vehicle: urlParams.vehicleId || undefined,
});
