import { useClickAway } from '@partstech/ui/hooks';
import { useCallback, useRef } from 'react';
import type { ModalProps } from '@partstech/ui';

type Args = {
  onAddSuppliers: () => void;
} & ModalProps;

export const useWelcomeModalView = ({ onAddSuppliers, onClose }: Args) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);

  const close = useCallback(() => onClose?.(), [onClose]);

  const addSuppliers = useCallback(() => {
    onAddSuppliers();

    close();
  }, [close, onAddSuppliers]);

  useClickAway(cardRef, close);

  return { addSuppliers, close, cardRef, headerRef };
};
