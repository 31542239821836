/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type GetSearchVehiclesByNameQueryVariables = Types.Exact<{
  search: Types.Scalars['String']['input'];
}>;

export type GetSearchVehiclesByNameQuery = {
  __typename?: 'Query';
  vehicleSuggest?: Array<{
    __typename?: 'VehicleSuggest';
    id?: string | null;
    type?: Types.VehicleType | null;
    year: number;
    regions?: Array<Types.VehicleRegion> | null;
    make: { __typename?: 'VehicleMake'; id: string; name: Types.Scalars['String']['input']; priority: number };
    model: { __typename?: 'VehicleModel'; id: string; name: Types.Scalars['String']['input'] };
    subModel?: { __typename?: 'VehicleSubModel'; id: string; name: Types.Scalars['String']['input'] } | null;
    engine?: { __typename?: 'VehicleEngine'; id: string; name: Types.Scalars['String']['input'] } | null;
  }> | null;
};

export const GetSearchVehiclesByNameDocument = `
    query GetSearchVehiclesByName($search: String!) {
  vehicleSuggest(search: $search) {
    id
    type
    year
    make {
      id
      name
      priority
    }
    model {
      id
      name
    }
    subModel {
      id
      name
    }
    engine {
      id
      name
    }
    regions
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetSearchVehiclesByName: build.query<GetSearchVehiclesByNameQuery, GetSearchVehiclesByNameQueryVariables>({
      query: (variables) => ({ document: GetSearchVehiclesByNameDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetSearchVehiclesByNameQuery, useLazyGetSearchVehiclesByNameQuery } = injectedRtkApi;
