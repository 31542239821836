import { useMedia } from '@partstech/ui';
import { useCreateModal } from '@partstech/ui/hooks';
import { useCallback } from 'react';
import { BundlesModal } from './BundlesModal';
import type { Bundle } from 'types/product';

export const useBundlesModal = (bundles: Bundle[]) => {
  const { isMobile } = useMedia();

  const { open } = useCreateModal(
    BundlesModal,
    { size: isMobile ? 'bottomSheet' : 'large', bundles },
    { customId: 'bundleDeals' }
  );

  const openBundlesModal = useCallback(() => {
    open();
  }, [open]);

  return { openBundlesModal };
};
