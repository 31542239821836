import { useCreateModal } from '@partstech/ui/hooks';
import { useCallback } from 'react';
import { MembershipsModal } from './MembershipsModal';
import type { ShopMembership, SupplierMembership } from 'models';

type Args = {
  shopMemberships: ShopMembership[];
  supplierMemberships: SupplierMembership[];
};

export const useMembershipsModal = ({ shopMemberships, supplierMemberships }: Args) => {
  const { open } = useCreateModal(MembershipsModal, {
    shopMemberships,
    supplierMemberships,
    size: 'fullScreen',
  });

  const openModal = useCallback(() => open(), [open]);

  return { open: openModal };
};
