import { Button } from '@partstech/ui';
import type { ButtonProps } from '@partstech/ui';
import type { Store } from 'models';

type Props = {
  store: Store;
  onClick?: () => void;
} & ButtonProps;

export const RemoveButton = ({ store, onClick, ...props }: Props) => (
  <Button
    color="negative"
    variant="secondary"
    onClick={onClick}
    leadingIcon="delete"
    iconColor="negative"
    data-testid="removeButton"
    {...props}
  />
);
