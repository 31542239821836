import { useCallback } from 'react';
import { api as rootApi } from 'store/queries/currentUser/GetCurrentUser.generated';
import { api as generatedApi } from './ChangeEmailConfirmation.generated';
import type { ChangeEmailConfirmationInput } from 'shared/api';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    ChangeEmailConfirmation: {
      onQueryStarted: async ({ input }, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          rootApi.util.updateQueryData('GetCurrentUser', undefined, (draft) => {
            if (draft.currentUser) {
              Object.assign(draft.currentUser, { email: input.newEmail });
            }
          })
        );

        queryFulfilled.catch(patchResult.undo);
      },
    },
  },
});

const { useChangeEmailConfirmationMutation } = api;

export const useChangeEmailConfirmation = () => {
  const [changeEmailConfirmation, status] = useChangeEmailConfirmationMutation();

  const handleChangeEmailConfirmation = useCallback(
    (input: ChangeEmailConfirmationInput) => changeEmailConfirmation({ input }),
    [changeEmailConfirmation]
  );

  return [handleChangeEmailConfirmation, status] as const;
};
