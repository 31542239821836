import { useParams } from 'react-router-dom';
import type { SmsConnectionsPages } from 'utils/url';

type SmsConnectionsPathParams = {
  hash: string;
  '*': SmsConnectionsPages | '';
};

export const useSmsConnectionsPathParams = () => {
  const { '*': page, hash = '' } = useParams<SmsConnectionsPathParams>();

  return { page, hash };
};
