import { usePosition, usePrevious } from '@partstech/ui/hooks';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useCart } from 'features/cart';

export const useSavedQuotesIcon = () => {
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const wrapRef = useRef<HTMLDivElement>(null);

  const { isLoading: isFirstLoading, isFetching: isFetchingNew, isRemoving, cart } = useCart();
  const isLoading = (isFetchingNew || isRemoving) && !isFirstLoading;

  const [showAnimation, setShowAnimation] = useState(false);

  const partsCount = useMemo(() => cart?.partsCount() || 0, [cart]);

  const isCartEmpty = partsCount === 0;
  const isCartEmptyPrev = usePrevious(isCartEmpty);
  const isFirstAdding = isCartEmptyPrev && !isCartEmpty;

  const { position } = usePosition({ contentRef, wrapRef, preferPlacement: 'top-start', offset: -7 });

  useEffect(() => {
    if (isFirstAdding) {
      setShowAnimation(true);

      timerRef.current = setTimeout(() => {
        setShowAnimation(false);
      }, 2000);
    }
  }, [isCartEmptyPrev, isFirstAdding]);

  useEffect(() => {
    if (!showAnimation && timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  }, [showAnimation]);

  return { wrapRef, contentRef, position, showAnimation, isLoading: isLoading && !isCartEmptyPrev };
};
