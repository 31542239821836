import { useDebouncedCallback } from '@partstech/ui/hooks';
import { useCallback, useState } from 'react';

export type HighlightType = 'info' | 'error';

export type Highlights = Record<string, HighlightType>;

export const useCartHighlights = () => {
  const [highlights, setHighlights] = useState<Highlights>({});

  const clearHighlights = useDebouncedCallback(() => setHighlights({}), 600);

  const addHighlights = useCallback(
    (value: Highlights) => {
      setHighlights(value);

      clearHighlights();
    },
    [clearHighlights]
  );

  return {
    highlights,
    addHighlights,
  };
};
