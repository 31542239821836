import { useEffect } from 'react';
import { shallowEqual } from 'react-redux';
import { useToggleRetailPrice } from 'hooks/retailPricing';
import { useAppSelector } from 'store';
import { selectProductModelsByIds, useLazyGetProductQuery } from 'store/entities/product';
import {
  selectFetchingIds,
  selectIsProductsFetched,
  selectIsProductsFetchFailed,
  selectIsProductsFetching,
} from 'store/features/productPage';
import type { ProductQueryParams } from 'types/product';

export const useProducts = (
  urlParamsList: ProductQueryParams[],
  productIds: string[],
  filterEnhancements?: boolean,
  skip: boolean = false
) => {
  const { isShown: showRetailPrice } = useToggleRetailPrice();

  const [fetchProduct] = useLazyGetProductQuery();

  const isLoading = useAppSelector(selectIsProductsFetching);

  const isProductsFetched = useAppSelector((state) => selectIsProductsFetched(state, productIds));

  const fetchingIds = useAppSelector(selectFetchingIds, shallowEqual);

  const isProductsFetchFailed = useAppSelector((state) => selectIsProductsFetchFailed(state, productIds));

  const products = useAppSelector(
    (state) => selectProductModelsByIds(state, productIds, showRetailPrice, filterEnhancements),
    shallowEqual
  );

  useEffect(() => {
    if (!isProductsFetched && !isLoading && !skip) {
      urlParamsList.forEach((urlParams) => fetchProduct(urlParams, true));
    }
  }, [isLoading, isProductsFetched, urlParamsList, skip, fetchProduct]);

  return {
    isLoading,
    isSuccess: isProductsFetched && !isProductsFetchFailed,
    isFailed: isProductsFetchFailed,
    products,
    productIds,
    fetchingIds,
  };
};
