import { useCallback, useRef } from 'react';

type Props<T> = {
  args: T;
  endCursor: string | null;
  fetchNext: (args: T & { after: string }) => Promise<unknown>;
  isFetching: boolean;
};

export const useInfiniteScrollingFetch = <T extends Record<string, unknown>>({
  args,
  fetchNext,
  isFetching,
  endCursor,
}: Props<T>) => {
  const isFetchingMoreRef = useRef(false);

  const loadMore = useCallback(async () => {
    if (!isFetching && !isFetchingMoreRef.current && endCursor) {
      isFetchingMoreRef.current = true;
      await fetchNext({ ...args, after: endCursor });
      isFetchingMoreRef.current = false;
    }
  }, [args, endCursor, fetchNext, isFetching]);

  return {
    loadMore,
    isFetchingFirstPage: isFetching && !isFetchingMoreRef.current,
    isFetchingMore: isFetching && isFetchingMoreRef.current,
  };
};
