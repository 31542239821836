import { useCreateModal, useLocalStorage } from '@partstech/ui/hooks';
import { useCallback, useEffect, useMemo } from 'react';
import { LocalStorageKeys } from 'constant';
import { useGetPaymentCards } from 'features/paymentsMethod';
import { CardExpireModal } from './CardExpireModal';

const days = [1, 5, 15, 30];

export const useCardExpiryNotification = () => {
  const { subscriptionCard } = useGetPaymentCards();

  const daysToCardExpire = useMemo(() => subscriptionCard?.calculateDaysUntilExpiry() ?? 0, [subscriptionCard]);

  const [skippedDay, setSkippedDay] = useLocalStorage<number>(LocalStorageKeys.SKIPPED_EXPIRE_CARD_DAY);

  const handleClose = useCallback(() => {
    setSkippedDay(daysToCardExpire);
  }, [daysToCardExpire, setSkippedDay]);

  const { open, opened } = useCreateModal(CardExpireModal, { daysToCardExpire, onClose: handleClose });

  useEffect(() => {
    if (skippedDay !== daysToCardExpire && days.includes(daysToCardExpire) && !opened) {
      open();
    }
  }, [daysToCardExpire, open, opened, skippedDay]);
};
