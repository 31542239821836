import { useModalHistory } from '@partstech/ui';
import { useCallback } from 'react';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import { ContactForm } from './ContactForm';
import type { ContactSupportPaths } from 'types/contactForm';

export const ContactSupport = () => {
  const { state, reset, onClose } = useModalHistory<ContactSupportPaths, 'contactSupport'>();

  const { mode = 'globalSupport' } = state ?? {};

  const { email, username, firstName, lastName } = useGetCurrentUser();

  const initialValues = {
    firstName,
    lastName,
    email: email ?? '',
    username: username ?? '',
  };

  const handleSubmit = useCallback(() => reset('success'), [reset]);

  return <ContactForm mode={mode} defaultValues={initialValues} onSubmit={handleSubmit} onCancel={onClose} />;
};
