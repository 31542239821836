import { useMemo } from 'react';
import { useGetDependentAttributeQuery } from './DependentAttribute.generated';
import type { UniversalPartType } from 'models';

type Props = {
  activeAttributeName: string;
  partType: UniversalPartType | null;
  previousAttributes: { name: string; value: string }[];
  skip: boolean;
  vehicleId: string | null;
};

export const useDependentAttribute = ({
  activeAttributeName,
  partType,
  previousAttributes,
  skip,
  vehicleId,
}: Props) => {
  const effectiveVehicleId = previousAttributes.length === 0 ? vehicleId : null;

  const { currentData, ...props } = useGetDependentAttributeQuery(
    {
      ids: partType ? [partType.id] : [],
      attributeName: activeAttributeName,
      previousAttributes,
      vehicleId: effectiveVehicleId,
    },
    { skip: !activeAttributeName || skip }
  );

  const dependentAttribute = useMemo(() => currentData?.partTypes?.[0]?.dependentAttribute, [currentData?.partTypes]);

  return { dependentAttribute, ...props };
};
