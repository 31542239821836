import { SubscriptionProductAlias } from 'shared/api';

export enum ProductAlias {
  Free = 'FREE',
  Complete = SubscriptionProductAlias.PartstechComplete,
  Labor = SubscriptionProductAlias.Labor,
  Maintenance = SubscriptionProductAlias.MaintenanceSchedules,
  Plus = SubscriptionProductAlias.PartstechPlus,
  Tires = SubscriptionProductAlias.Tires,
  VehicleSpecs = SubscriptionProductAlias.VehicleSpecs,
}
