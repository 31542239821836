import { Typography } from '@partstech/ui';
import type { TypographyProps } from '@partstech/ui';

type Props = TypographyProps & {
  isYearly: boolean;
};

export const DiscountNotice = ({ isYearly, ...props }: Props) => (
  <Typography color={isYearly ? 'secondary' : 'mono40'} variant="overline" {...props}>
    SAVE 10% OR MORE
  </Typography>
);
