import type { QueryStatus } from '@reduxjs/toolkit/query';
import type { BaseProductFragment } from 'store/queries/searchResults/BaseProductFragment.generated';

type Props = {
  status: QueryStatus;
  products: BaseProductFragment[];
  errors?: string[];
};

export const createProductResult = ({
  status,
  products,
  errors,
}: Props): { status: QueryStatus; products: BaseProductFragment[]; errors?: string[] } => ({
  status,
  products,
  errors,
});
