import { entries, isEmpty, keys, uniq } from '@partstech/ui/utils';
import { PartTypeApplication } from 'shared/api';
import { PartType } from './PartType';
import type { Vehicle } from './Vehicle';

type SelectedAttribute = Record<string, string[]>;

export class UniversalPartType extends PartType {
  selectedAttributes: SelectedAttribute = {};

  hasAttributes() {
    return (this.attributes ?? []).length > 0;
  }

  isAllAttributesFilled() {
    return (this.attributes ?? []).length === keys(this.selectedAttributes).length;
  }

  isMultiAttribute() {
    return (this.attributes ?? []).length > 1;
  }

  isSingleAttribute() {
    return this.attributes?.length === 1;
  }

  hasSomeFilledAttributes(): boolean {
    return Boolean(Object.values(this.selectedAttributes).find(([_, values]) => Boolean(values)));
  }

  setSelectedAttributes(attributes: SelectedAttribute) {
    this.selectedAttributes = attributes;

    return this;
  }

  shouldPickAttributes(hasVehicle: boolean): boolean {
    if (this.isMultiAttribute() && !this.isAllAttributesFilled()) {
      return true;
    }

    if (hasVehicle) {
      return false;
    }

    return !this.isAllAttributesFilled();
  }

  getSelectedAttributes() {
    return entries(this.selectedAttributes).reduce<SelectedAttribute>(
      (acc, [name, values]) => ({
        ...acc,
        [name]: uniq(values),
      }),
      {}
    );
  }

  resetSelectedAttributes() {
    this.selectedAttributes = {};

    return this;
  }

  getFirstAttribute() {
    return this.attributes?.[0] ?? null;
  }

  normalizeValue(value: string) {
    return value.toLowerCase().replace(/\s+/g, '').replace('-', '');
  }

  findAttributeByValue(value: string) {
    return this.attributes?.find((attribute) =>
      attribute.values?.find((attributeValue) => this.normalizeValue(attributeValue) === this.normalizeValue(value))
    );
  }

  findValueInAttribute(value: string) {
    const attribute = this.findAttributeByValue(value);

    return attribute?.values?.find(
      (attributeValue) => this.normalizeValue(attributeValue) === this.normalizeValue(value)
    );
  }

  toQuery(vehicle: Vehicle | null, isFittedAvailable: boolean = false) {
    return {
      part_text_id: this.id,
      part_text: this.name,
      attributes: !isFittedAvailable ? this.getSelectedAttributes() : undefined,
      fit: this.application === PartTypeApplication.UniversalFitted && vehicle && isFittedAvailable ? ['1'] : undefined,
      prefill: isEmpty(this.selectedAttributes) && vehicle ? 'filled' : 'none',
    };
  }
}
