/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type SendReasonsMutationVariables = Types.Exact<{
  input: Types.SendUnsubscribeReasonsInput;
}>;

export type SendReasonsMutation = {
  __typename?: 'Mutation';
  sendUnsubscribeReasons?: {
    __typename?: 'SendUnsubscribeReasonsPayload';
    success: Types.Scalars['Boolean']['input'];
  } | null;
};

export const SendReasonsDocument = `
    mutation SendReasons($input: SendUnsubscribeReasonsInput!) {
  sendUnsubscribeReasons(input: $input) {
    success
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    SendReasons: build.mutation<SendReasonsMutation, SendReasonsMutationVariables>({
      query: (variables) => ({ document: SendReasonsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useSendReasonsMutation } = injectedRtkApi;
