import { css } from '@emotion/react';
import { Box, Button, Result } from '@partstech/ui';
import { useCallback } from 'react';
import type { Theme } from '@partstech/ui';

type Props = {
  askRefresh?: boolean;
  showButton?: boolean;
};

const styles = {
  result: (theme: Theme) => css`
    border-radius: ${theme.sizing(2)};
  `,
};

export const ErrorFallback = ({ askRefresh = true, showButton = false }: Props) => {
  const handleRefreshClick = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <Box
      minHeight="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      alignContent="center"
      flexDirection="column"
    >
      {askRefresh ? (
        <Result
          title="We've made some updates"
          message="Refresh the page to see the latest content"
          variant="refresh"
          extraButtons={
            showButton
              ? [
                  <Button key={1} onClick={handleRefreshClick}>
                    Refresh
                  </Button>,
                ]
              : undefined
          }
          background="white"
          css={styles.result}
        />
      ) : (
        <Result
          title="An unexpected error occured."
          message="Refresh the page or try again later."
          variant="error"
          extraButtons={
            showButton
              ? [
                  <Button key={1} onClick={handleRefreshClick}>
                    Refresh
                  </Button>,
                ]
              : undefined
          }
          background="white"
          css={styles.result}
        />
      )}
    </Box>
  );
};
