import { css } from '@emotion/react';
import { Box } from '@partstech/ui';
import AmericanExpress from 'images/icons/card-brand/american-express.svg';
import Discover from 'images/icons/card-brand/discover.svg';
import Jcb from 'images/icons/card-brand/jcb.svg';
import MasterCard from 'images/icons/card-brand/mastercard.svg';
import Visa from 'images/icons/card-brand/visa.svg';
import type { BoxProps } from '@partstech/ui';
import type { Card } from 'shared/api';

const sizes = {
  small: { w: 32, h: 18 },
  large: { w: 40, h: 24 },
} as const;

type CardBrandSize = keyof typeof sizes | undefined;

const styles = {
  cardBrand: (size: CardBrandSize) => () => css`
    width: ${size ? `${sizes[size].w}px` : '100%'};
    height: ${size ? `${sizes[size].h}px` : '100%'};
  `,
};

const BrandMap: Record<string, string> = {
  visa: Visa,
  mastercard: MasterCard,
  discover: Discover,
  amex: AmericanExpress,
  jcb: Jcb,
  'american express': AmericanExpress,
};

type Props = {
  name: Card['brand'];
  size?: CardBrandSize;
} & BoxProps;

export const CardBrand = ({ name, size, ...props }: Props) => (
  <Box display="flex" alignItems="center" justifyContent="center" {...props}>
    <img
      className="brand-image"
      src={BrandMap[name.toLowerCase()]}
      alt={name}
      css={styles.cardBrand(size)}
      data-testid={`${name.toLowerCase()}Logo`}
    />
  </Box>
);
