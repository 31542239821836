import { css } from '@emotion/react';
import type { Theme } from '@partstech/ui';

const defaultContainer = (theme: Theme) => css`
  display: grid;
  column-gap: ${theme.sizing(2)};
  row-gap: ${theme.sizing(1)};
  grid-template-rows: auto;

  ${theme.breakpoints.up('md')} {
    row-gap: 0;
  }

  &:hover {
    background-color: ${theme.color.lightBlue};

    .deleteIcon {
      display: block;
    }
  }

  .deleteIcon {
    display: none;
  }
`;

export const styles = {
  emptyRowContainer: (theme: Theme) => css`
    ${defaultContainer(theme)}
    grid-template-columns: ${theme.sizing(14)} 1fr 1fr 1fr ${theme.sizing(18)} 1fr;
    grid-template-areas: 'emptyMessage emptyMessage emptyMessage emptyMessage emptyMessage. action';

    ${theme.breakpoints.up('md')} {
      grid-template-areas: 'emptyMessage emptyMessage emptyMessage moreResults . action';
    }
  `,

  container: (shouldShowActionMessage: boolean) => (theme: Theme) => {
    const input = shouldShowActionMessage ? 'actionMessage' : 'input';
    const price = shouldShowActionMessage ? 'actionMessage' : 'price';
    const actionMessage = shouldShowActionMessage ? 'actionMessage' : '.';

    return css`
      ${defaultContainer(theme)}
      grid-template-columns: ${theme.sizing(14)} repeat(5, 1fr);
      grid-template-areas:
        'image    details  details  details          details  action'
        'image    details  details  details          details  action'
        'selector selector selector ${actionMessage} ${input} ${price}';

      ${theme.breakpoints.up('md')} {
        grid-template-columns: ${theme.sizing(14)} 1fr 1fr 1fr ${theme.sizing(18)} 1fr;

        grid-template-areas:
          'image details  details  moreResults ${input} price'
          'image selector selector .           ${input}    .'
          'image selector selector .           ${input}      action';
      }
    `;
  },

  emptyMessage: css`
    grid-area: emptyMessage;
  `,
  image: (theme: Theme) => css`
    grid-area: image;
    align-self: baseline;

    ${theme.breakpoints.up('md')} {
      align-self: center;
    }
  `,
  title: css`
    grid-area: title;
  `,
  details: (theme: Theme) => css`
    grid-area: details;
    & > *:not(:last-child) {
      margin-bottom: ${theme.sizing(1)};
    }
  `,
  selector: css`
    grid-area: selector;
  `,
  input: css`
    grid-area: input;
    * > fieldset {
      margin: 0;
    }
  `,
  price: (hasError: boolean) => (theme: Theme) => css`
    grid-area: price;
    text-align: right;
    ${!hasError && 'align-self: center'};
    padding-top: ${hasError ? theme.sizing(4) : 0};

    ${theme.breakpoints.up('md')} {
      padding: 0;
      align-self: baseline;
    }
  `,
  action: (theme: Theme) => css`
    grid-area: action;
    justify-self: end;

    ${theme.breakpoints.up('md')} {
      align-self: end;
    }
  `,
  moreResults: css`
    grid-area: moreResults;
    padding: 0;
  `,
  actionMessage: css`
    text-align: center;
    grid-area: actionMessage;
  `,
};
