type BrandType = {
  id: string | null;
  isPreferred: boolean;
  lineCode: string | null;
  logo: string | null;
  name: string | null;
};

export class Brand {
  id: string | null;

  isPreferred: boolean;

  lineCode: string | null;

  logo: string | null;

  name: string | null;

  constructor(brand: BrandType) {
    this.id = brand.id;
    this.isPreferred = brand.isPreferred;
    this.lineCode = brand.lineCode;
    this.logo = brand.logo;
    this.name = brand.name;
  }
}
