import { getTireLoadIndex } from 'utils';
import { Options } from '../../DefaultFilter/Options';
import type { OptionsProps } from '../../DefaultFilter/Options/types';

export const LoadIndexOptions = ({ options, ...props }: OptionsProps) => {
  const loadIndexOptions = options.map((option) => {
    const loadMph = getTireLoadIndex(option.value);
    const optionName = loadMph ? `${option.name} - ${loadMph}` : option.name;

    return { ...option, name: optionName };
  });

  return <Options options={loadIndexOptions} {...props} />;
};
