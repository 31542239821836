import { css } from '@emotion/react';
import { Box, Icon, Status } from '@partstech/ui';
import { memo, useMemo } from 'react';
import type { Theme } from '@partstech/ui';
import type { SupplierAccount } from 'models';

const styles = {
  status: (theme: Theme) => css`
    z-index: ${theme.zIndex.addition + theme.zIndex.default};
  `,
};

type Props = {
  hideTotal: boolean;
  isActive: boolean;
  isStickyTab: boolean;
  supplierAccount: SupplierAccount;
  total: number;
  hasSupplierErrors: boolean;
};

export const SupplierStatus = memo(
  ({ hideTotal, supplierAccount, total, isStickyTab, isActive, hasSupplierErrors }: Props) => {
    const { supplier, statusMessage, isNotApproved, isApproved } = supplierAccount;

    const hasError = isNotApproved || supplier?.comingSoon || hasSupplierErrors;

    const showNegativeIcon = hasError || Boolean(supplier?.isFullOutage);

    const tabStatusVariant = useMemo(() => {
      if (showNegativeIcon) {
        return 'negative';
      }

      if (!isApproved || Boolean(supplier?.isPartialOutage)) {
        return 'neutral';
      }

      return 'primary';
    }, [showNegativeIcon, isApproved, supplier?.isPartialOutage]);

    const tabStatus = useMemo(() => {
      if (supplier?.comingSoon) {
        return 'Not yet available';
      }

      if (supplier?.isFullOutage || (supplier?.isPartialOutage && total === 0)) {
        return 'Outage';
      }

      if (statusMessage && !isApproved) {
        return statusMessage;
      }

      if (hasSupplierErrors) {
        return 'Error';
      }

      if (hideTotal) {
        return <Icon name="tune" size="medium" />;
      }

      return total;
    }, [
      hideTotal,
      hasSupplierErrors,
      isApproved,
      statusMessage,
      supplier?.comingSoon,
      supplier?.isFullOutage,
      supplier?.isPartialOutage,
      total,
    ]);

    if (!hasError && isApproved && supplier?.isNoOutage && !hideTotal && total === 0) {
      return null;
    }

    if (isStickyTab) {
      if (showNegativeIcon) {
        return <Icon name="error" color="negative" />;
      }

      if (!isApproved) {
        return <Icon name="error" color="neutral" />;
      }

      if (hideTotal) {
        return (
          <Status color={tabStatusVariant} variant="light">
            <Icon name="tune" size="medium" />
          </Status>
        );
      }

      if (total !== 0) {
        return (
          <Status color={tabStatusVariant} variant="light">
            {total}
          </Status>
        );
      }

      return null;
    }

    return (
      <Box css={styles.status} position="absolute" top={isActive ? -3.5 : -3.25} right={isActive ? -3 : -2.75}>
        <Status color={tabStatusVariant} variant="light">
          {tabStatus}
        </Status>
      </Box>
    );
  }
);
