import type { UserName } from 'types/userName';

export const getUserFullName = (user: UserName) => {
  const { firstName, lastName } = user;

  if (!lastName) {
    return firstName;
  }

  return `${firstName} ${lastName}`;
};
