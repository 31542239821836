/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import { AddressFragmentDoc } from '../../../../shared/api/graphql/fragment/Address.generated';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type GetUserShopsWithAddressesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetUserShopsWithAddressesQuery = {
  __typename?: 'Query';
  currentUser?: {
    __typename?: 'User';
    shopMemberships?: Array<{
      __typename?: 'ShopMember';
      shop?: {
        __typename?: 'Shop';
        id: string;
        name: Types.Scalars['String']['input'];
        addresses?: Array<{
          __typename?: 'ShopAddress';
          type: Types.ShopAddressType;
          address: {
            __typename?: 'Address';
            address1: Types.Scalars['String']['input'];
            address2?: Types.Scalars['String']['input'] | null;
            city: Types.Scalars['String']['input'];
            country: Types.Country;
            state: Types.Scalars['String']['input'];
            zipCode: Types.Scalars['String']['input'];
          };
        }> | null;
      } | null;
    }> | null;
  } | null;
};

export const GetUserShopsWithAddressesDocument = `
    query GetUserShopsWithAddresses {
  currentUser {
    shopMemberships {
      shop {
        id
        name
        addresses {
          address {
            ...Address
          }
          type
        }
      }
    }
  }
}
    ${AddressFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetUserShopsWithAddresses: build.query<
      GetUserShopsWithAddressesQuery,
      GetUserShopsWithAddressesQueryVariables | void
    >({
      query: (variables) => ({ document: GetUserShopsWithAddressesDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetUserShopsWithAddressesQuery, useLazyGetUserShopsWithAddressesQuery } = injectedRtkApi;
