import { EditCreditCardForm } from './EditCreditCardForm';
import { useEditCard } from './useEditCard';

export const EditCard = () => {
  const { card, paymentMethod, showCardOptions, submitForm, onClose } = useEditCard();

  if (!card) {
    return null;
  }

  return (
    <EditCreditCardForm
      card={card}
      paymentMethod={paymentMethod}
      showCardOptions={showCardOptions}
      onSubmit={submitForm}
      onCancel={onClose}
    />
  );
};
