import { URL } from 'constant/url';
import { getAddSupplierUrl } from 'utils/url';
import type { PlanFeature } from '../types';

export enum PaidFeatureAlias {
  Analytics = 'analytics',
  Jobs = 'jobs',
  Labor = 'labor',
  PreferredBrands = 'preferred-brands',
  RetailPricing = 'retail-pricing',
  Support = 'support',
  TireComparison = 'tire-comparison',
  Tires = 'tires',
  UserPermissions = 'user-permissions',
}

const tireSuppliers: PlanFeature = {
  alias: PaidFeatureAlias.Tires,
  isEnabled: true,
  name: 'Access to 50+ tire suppliers',
  link: {
    isInternal: true,
    name: 'view tire suppliers',
    url: getAddSupplierUrl('tires'),
  },
};

const jobs: PlanFeature = {
  alias: PaidFeatureAlias.Jobs,
  isEnabled: true,
  name: 'Popular Jobs',
  link: {
    name: null,
    url: URL.POPULAR_JOBS,
  },
};

const preferredBrands: PlanFeature = {
  alias: PaidFeatureAlias.PreferredBrands,
  isEnabled: true,
  name: 'Preferred Brands',
  link: {
    name: null,
    url: URL.PREFERRED_BRANDS,
  },
};

const analytics: PlanFeature = {
  alias: PaidFeatureAlias.Analytics,
  isEnabled: true,
  name: 'Analytics & Insights',
  link: {
    name: null,
    url: URL.ANALYTICS,
  },
};

const tireComparison: PlanFeature = {
  alias: PaidFeatureAlias.TireComparison,
  isEnabled: true,
  name: 'Tire comparison and mobile quoting',
  link: {
    name: null,
    url: URL.TIRE_COMPARISON,
  },
};

const retailPricing: PlanFeature = {
  alias: PaidFeatureAlias.RetailPricing,
  isEnabled: true,
  name: 'Retail pricing configuration',
  link: {
    name: null,
    url: URL.QUOTING,
  },
};

const userPermissions: PlanFeature = {
  alias: PaidFeatureAlias.UserPermissions,
  isEnabled: true,
  name: 'User permissions',
  link: {
    name: null,
    url: URL.USER_PERMISSIONS,
  },
};

const support: PlanFeature = {
  alias: PaidFeatureAlias.Support,
  isEnabled: true,
  name: 'VIP Support',
};

export const freeFeaturesList: PlanFeature[] = [
  {
    isEnabled: true,
    name: 'Search & order from unlimited part suppliers',
    link: {
      name: null,
      url: URL.SUPPLIERS_USERS,
    },
  },
  {
    isEnabled: true,
    name: 'Unlimited users',
    link: {
      name: null,
      url: URL.SUPPLIERS_USERS,
    },
  },
  {
    isEnabled: true,
    name: 'Unlimited parts lookup and ordering',
    link: {
      name: null,
      url: URL.PARTS_TIRE_SEARCH,
    },
  },
  { isEnabled: true, name: 'Live wholesale pricing & inventory' },
  { isEnabled: true, name: 'Direct integration with 35+ Shop Management Systems' },
  { isEnabled: true, name: 'Access to 15M+ products from 5K+ brands' },
  { isEnabled: true, name: 'License plate/VIN look-ups' },
  { isEnabled: true, name: 'Vehicle diagrams', link: { name: null, url: URL.DIAGRAMS } },
];

export const paidFeatures = {
  analytics,
  jobs,
  preferredBrands,
  retailPricing,
  support,
  tireComparison,
  tireSuppliers,
  userPermissions,
} as const;
