/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type CreateOrUpdateOnboardStepMutationVariables = Types.Exact<{
  input: Types.CreateOrUpdateOnboardStepInput;
}>;

export type CreateOrUpdateOnboardStepMutation = {
  __typename?: 'Mutation';
  createOrUpdateOnboardStep?: {
    __typename?: 'CreateOrUpdateOnboardStepPayload';
    onboardSteps?: Array<{
      __typename?: 'OnboardStep';
      step: Types.OnboardStepType;
      value?: Types.Scalars['String']['input'] | null;
    }> | null;
  } | null;
};

export const CreateOrUpdateOnboardStepDocument = `
    mutation CreateOrUpdateOnboardStep($input: CreateOrUpdateOnboardStepInput!) {
  createOrUpdateOnboardStep(input: $input) {
    onboardSteps {
      step
      value
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    CreateOrUpdateOnboardStep: build.mutation<
      CreateOrUpdateOnboardStepMutation,
      CreateOrUpdateOnboardStepMutationVariables
    >({
      query: (variables) => ({ document: CreateOrUpdateOnboardStepDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useCreateOrUpdateOnboardStepMutation } = injectedRtkApi;
