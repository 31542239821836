import { Box, Tab, Tabs, Typography, useMedia } from '@partstech/ui';
import { useCallback } from 'react';
import { useMatch } from 'react-router-dom';
import { Outlet } from 'app/AppRouter/Outlet';
import { useAppNavigate } from 'app/AppRouter/useAppNavigate';
import { useLayoutContext } from 'app/contexts/LayoutProvider/LayoutContext';
import { Routes } from 'constant';
import { StockOrdersProvider } from 'features/stockOrders';
import type { PropsWithChildren } from 'react';

export const StockOrderLayout = ({ children }: PropsWithChildren) => {
  const { isMobile } = useMedia();
  const navigate = useAppNavigate();
  const isNewTab = Boolean(useMatch(Routes.STOCK_ORDER_NEW));

  const { headerHeight } = useLayoutContext();

  const handleTabChange = useCallback(
    (tab: string) => {
      const isSelectedTabNew = tab === 'new';

      if (isNewTab !== isSelectedTabNew) {
        navigate(isSelectedTabNew ? Routes.STOCK_ORDER_NEW : Routes.STOCK_ORDER_TEMPLATES);
      }
    },
    [isNewTab, navigate]
  );

  return (
    <Box background="white" minHeight={`calc(100vh - ${headerHeight}px)`}>
      <Box mx="auto" maxWidth={180} py={{ sm: 1, md: 7 }}>
        {!isMobile && (
          <Box mb={6}>
            <Typography variant="h2">Stock Orders</Typography>
          </Box>
        )}

        <Box mb={6} px={{ sm: 2, md: 0 }}>
          <Tabs activeTab={isNewTab ? 'new' : 'templates'} onChange={handleTabChange}>
            <Tab id="new" isActive={isNewTab}>
              New stock order
            </Tab>
            <Tab id="templates" isActive={!isNewTab}>
              Templates
            </Tab>
          </Tabs>
        </Box>

        <StockOrdersProvider>
          <Outlet>{children}</Outlet>
        </StockOrdersProvider>
      </Box>
    </Box>
  );
};
