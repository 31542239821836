import { Banner, Box, Link, Typography } from '@partstech/ui';
import React, { useCallback, useMemo } from 'react';
import { usePermissions } from 'store/queries/currentUser/usePermissions';
import { usePreferredBrandsMode } from 'store/queries/currentUser/usePreferredBrandsMode';
import { useLearnAboutFeatureModal } from '../../hooks/useLearnAboutFeatureModal';
import type { PaidFeatureAlias } from '../../constants/features';
import type { BannerProps, GenericModalSize } from '@partstech/ui';
import type { PropsWithChildren } from 'react';

export type UpgradeBannerProps = BannerProps & {
  feature: PaidFeatureAlias;
  modalSize?: GenericModalSize;
  onModalClose?: () => void;
  multiline?: boolean;
};

export const UpgradeBanner = ({
  children,
  onModalClose,
  feature,
  modalSize,
  multiline = false,
  ...props
}: PropsWithChildren<UpgradeBannerProps>) => {
  const { isFreePreferredBrands } = usePreferredBrandsMode();

  const {
    shop: {
      jobsAllowed,
      laborAllowed,
      tiresAllowed,
      preferredBrandsAllowed,
      retailPricingAllowed,
      userPermissionsAllowed,
    },
  } = usePermissions();

  const { open: openModal } = useLearnAboutFeatureModal({ feature, onClose: onModalClose, size: modalSize });

  const handleLearnMoreClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();

      openModal();
    },
    [openModal]
  );

  const isFeatureAllowed = useMemo(() => {
    switch (feature) {
      case 'jobs':
        return jobsAllowed;
      case 'labor':
        return laborAllowed;
      case 'tires':
        return tiresAllowed;
      case 'preferred-brands':
        return !isFreePreferredBrands && preferredBrandsAllowed;
      case 'retail-pricing':
        return retailPricingAllowed;
      case 'user-permissions':
        return userPermissionsAllowed;
      default:
        return true;
    }
  }, [
    feature,
    isFreePreferredBrands,
    jobsAllowed,
    laborAllowed,
    preferredBrandsAllowed,
    retailPricingAllowed,
    tiresAllowed,
    userPermissionsAllowed,
  ]);

  if (isFeatureAllowed) {
    return null;
  }

  return (
    <Banner
      icon="auto_awesome"
      iconColor="secondary"
      borderWidth={0}
      p={props.variant !== 'upgrade' ? 0 : undefined}
      {...props}
    >
      <Box display="flex" flexDirection={multiline ? 'column' : 'row'} ml={-2}>
        <Box mr={2}>
          <Typography component="span" bold variant="bodyText2">
            {children}
          </Typography>
        </Box>

        <Link to="#" onClick={handleLearnMoreClick}>
          Learn more
        </Link>
      </Box>
    </Banner>
  );
};
