/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type CreateShopAndUserWithSmsConnectMutationVariables = Types.Exact<{
  input: Types.CreateShopAndUserWithSmsConnectInput;
}>;

export type CreateShopAndUserWithSmsConnectMutation = {
  __typename?: 'Mutation';
  createShopAndUserWithSmsConnect?: {
    __typename?: 'CreateShopAndUserWithSmsConnectPayload';
    success?: Types.Scalars['Boolean']['input'] | null;
    error?: Types.CreateShopAndUserWithSmsConnectError | null;
  } | null;
};

export const CreateShopAndUserWithSmsConnectDocument = `
    mutation CreateShopAndUserWithSmsConnect($input: CreateShopAndUserWithSmsConnectInput!) {
  createShopAndUserWithSmsConnect(input: $input) {
    success
    error
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    CreateShopAndUserWithSmsConnect: build.mutation<
      CreateShopAndUserWithSmsConnectMutation,
      CreateShopAndUserWithSmsConnectMutationVariables
    >({
      query: (variables) => ({ document: CreateShopAndUserWithSmsConnectDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useCreateShopAndUserWithSmsConnectMutation } = injectedRtkApi;
