import { Box, Button, ButtonGroup, Typography } from '@partstech/ui';
import { CardBrand, CardName } from 'entities/paymentCard';
import { useDeleteCard } from './useDeleteCard';

export const DeleteCard = () => {
  const { card, children, isLoading, close, removePaymentCard } = useDeleteCard();

  if (!card) {
    return null;
  }

  return (
    <>
      <Typography>{children} It can’t be undone.</Typography>

      <Box display="flex" mt={4} mb={6}>
        <CardBrand name={card.brand} size="large" mr={3} />

        <CardName card={card} />
      </Box>

      <ButtonGroup flexDirection="column">
        <Button onClick={close} data-testid="keepCardButton">
          No, keep it
        </Button>

        <Button
          variant="text"
          color="negative"
          disabled={isLoading}
          isLoading={isLoading}
          onClick={removePaymentCard}
          data-testid="deleteCardButton"
        >
          Yes, delete it
        </Button>
      </ButtonGroup>
    </>
  );
};
