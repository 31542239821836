import { Icon } from '@partstech/ui';
import React from 'react';

type BallControlProps = {
  isActive: boolean;
  onClick: (e: React.MouseEvent) => void;
};

export const BallControl: React.FunctionComponent<BallControlProps> = ({ isActive, onClick }) => (
  <a href="components/Carousel/BallControl/index#" onClick={onClick}>
    <Icon name={isActive ? 'radio_button_checked' : 'radio_button_unchecked'} size="large" />
  </a>
);
