import { css } from '@emotion/react';
import { Link } from '@partstech/ui';
import type { Theme, TypographyProps } from '@partstech/ui';

const styles = {
  callForAvailability: (theme: Theme) => css`
    display: block;
    max-width: ${theme.sizing(50)};

    &:hover {
      color: ${theme.color.monoDark};
      text-decoration: underline;
    }
  `,
};

type Props = {
  canBuy?: boolean;
  className?: string;
  color?: TypographyProps['color'];
  name?: string;
  phone?: string | null;
  shouldCallAvailability?: boolean;
  shouldCallSupplier?: boolean;
  variant?: TypographyProps['variant'];
};

export const CallForAvailability = ({
  canBuy,
  className,
  color = 'primary',
  name,
  phone,
  shouldCallAvailability,
  shouldCallSupplier,
  variant = 'subtitle2',
}: Props) => {
  if (canBuy || !phone) {
    return null;
  }

  if (!shouldCallAvailability && !shouldCallSupplier) {
    return null;
  }

  return (
    <Link
      to={`tel:${phone}`}
      isExternal
      variant={variant}
      color={color}
      css={styles.callForAvailability}
      className={className}
    >
      Please call {name} at {phone} to check availability
    </Link>
  );
};
