/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type GetSubscriptionProductsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetSubscriptionProductsQuery = {
  __typename?: 'Query';
  currentUser?: {
    __typename?: 'User';
    activeMember?: {
      __typename?: 'ShopMember';
      shop?: {
        __typename?: 'Shop';
        subscriptionProducts?: Array<{
          __typename?: 'SubscriptionProduct';
          alias: Types.SubscriptionProductAlias;
          description?: Types.Scalars['String']['input'] | null;
          isPlan: Types.Scalars['Boolean']['input'];
          name: Types.Scalars['String']['input'];
          plans: Array<{
            __typename?: 'SubscriptionPlan';
            amount: Types.Scalars['BigDecimal']['input'];
            id: string;
            isTrial: Types.Scalars['Boolean']['input'];
            monthlyPrice?: Types.Scalars['BigDecimal']['input'] | null;
            period: Types.SubscriptionPlanPeriod;
          }>;
        }> | null;
      } | null;
    } | null;
  } | null;
};

export const GetSubscriptionProductsDocument = `
    query GetSubscriptionProducts {
  currentUser {
    activeMember {
      shop {
        subscriptionProducts {
          alias
          description
          isPlan
          name
          plans {
            amount
            id
            isTrial
            monthlyPrice
            period
          }
        }
      }
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetSubscriptionProducts: build.query<GetSubscriptionProductsQuery, GetSubscriptionProductsQueryVariables | void>({
      query: (variables) => ({ document: GetSubscriptionProductsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetSubscriptionProductsQuery, useLazyGetSubscriptionProductsQuery } = injectedRtkApi;
