import { Tooltip } from '@partstech/ui';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import type { TooltipProps } from '@partstech/ui';
import type { PropsWithChildren } from 'react';

type Props = PropsWithChildren<Omit<TooltipProps, 'tooltip'>>;

export const ContactSmsTooltip = ({ children, ...props }: Props) => {
  const { managementSystem } = useGetCurrentUser();

  const managementSystemName = managementSystem?.name ?? 'Your partner';

  return (
    <Tooltip {...props} tooltip={`Contact ${managementSystemName} to purchase.`} overflow verticalAlign="center">
      {children}
    </Tooltip>
  );
};
