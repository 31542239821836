import { SUPPORT_EMAIL } from 'shared/constant';

export const credentialMatchingCheck = (credentials?: string[]) => (fieldValue: string) => {
  const isCredentialMatch = fieldValue && credentials?.some((credential) => credential === fieldValue);

  if (isCredentialMatch) {
    return `You've already connected to your primary store, if you like additional assistance please reach out to ${SUPPORT_EMAIL}`;
  }

  return true;
};
