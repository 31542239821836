import { uniqBy } from '@partstech/ui/utils';
import { getAttributeOptionsByName } from '../../search/createFilters/getAttributeOptionsByName';
import { isAvailableOnSection } from '../../search/createFilters/isAvailableOnSection';
import { getCustomAttributesByNames } from '../../tire/getCustomAttributesByNames';
import { formatAttributeName } from './formatAttributeName';
import type { AttributeUsageSection } from 'constant';
import type { RawAttribute } from 'shared/api/rest/gen/shop';
import type { Product, ProductAttribute } from 'types/product';

const formatAttributeValue = (attributeValue?: RawAttribute['value']): ProductAttribute['value'] | null =>
  attributeValue?.join(', ') ?? null;

const uniqueCustomAttributes = <A extends Pick<RawAttribute, 'name'>>(attributes: A[]): A[] =>
  uniqBy(attributes, 'name');

export const makeProductAttribute = <A extends Pick<RawAttribute, 'name' | 'value'>>(
  attribute: A,
  _: number,
  attributes: RawAttribute[]
): ProductAttribute => {
  const attributeOptions = getAttributeOptionsByName(attribute.name);

  const getChildren = () => {
    if (!attributeOptions?.childrenNames) {
      return [];
    }

    return getCustomAttributesByNames(
      attributeOptions.childrenNames,
      attributes.filter((attr) => attr.name !== attribute.name).map(makeProductAttribute)
    );
  };

  return {
    name: attribute.name,
    label: attributeOptions?.label ?? formatAttributeName(attribute.name),
    value: formatAttributeValue(attribute.value) ?? '-',
    isCollapsed: attributeOptions?.isCollapsed ?? false,
    isHidden: attributeOptions?.isHidden ?? false,
    children: getChildren(),
  };
};

const compareAttributes =
  (section: AttributeUsageSection) => (a: Pick<RawAttribute, 'name'>, b: Pick<RawAttribute, 'name'>) => {
    const sortA = getAttributeOptionsByName(a.name)?.usage[section] ?? 0;
    const sortB = getAttributeOptionsByName(b.name)?.usage[section] ?? 0;

    if (sortA < sortB) {
      return -1;
    }
    if (sortA > sortB) {
      return 1;
    }
    return 0;
  };

export const getCustomAttributesForSection = (
  attributes: Product['attributes'],
  section: AttributeUsageSection
): ProductAttribute[] => {
  const uniqAttributes = uniqueCustomAttributes(attributes);
  const nonConfigAttributes = uniqAttributes.filter((attribute) => !getAttributeOptionsByName(attribute.name));

  const productAttributes = uniqAttributes
    .filter((attribute) => isAvailableOnSection(section, getAttributeOptionsByName(attribute.name)))
    .sort(compareAttributes(section));

  return [...productAttributes, ...nonConfigAttributes].map(makeProductAttribute);
};
