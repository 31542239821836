import type { PreferredAutomaticBrand, PreferredBrandUnified } from 'types/preferredBrand';

export const unifyAutomaticBrands = (automaticBrands: PreferredAutomaticBrand[]): PreferredBrandUnified[] =>
  automaticBrands.map((automaticBrand) => ({
    id: automaticBrand.id || null,
    name: automaticBrand.name || null,
    partTypeId: automaticBrand.partTypeId ? automaticBrand.partTypeId : null,
    priority: 0,
    type: 'automatic',
    purchaseCount: automaticBrand.purchaseCount ? Number(automaticBrand.purchaseCount) : null,
    linkedPartTypes: automaticBrand.linkedPartTypes?.map((linkedPart) => linkedPart.id),
    matchingBrandsIds: automaticBrand.matchingBrandsIds,
  }));
