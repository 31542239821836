/* eslint-disable no-param-reassign */
import type { ApiV2 } from '@fullstory/snippet';
import type { Event, EventHint, Client } from '@sentry/types';

// eslint-disable-next-line no-underscore-dangle
const doesFullStoryExist = () => Boolean(window[window._fs_namespace ?? '']);

const getProjectIdFromSentryDsn = (dsn: string) => new URL(dsn).pathname.replace('/', '');

const isError = (exception: unknown): exception is Error => (exception as Error).message !== undefined;

const getOriginalExceptionProperties = (hint?: EventHint) => {
  if (hint && hint.originalException && isError(hint.originalException)) {
    const { originalException } = hint;
    const { name, message } = originalException;
    return { name, message };
  }

  return {};
};

function getSentryUrl({
  hint,
  sentryOrg,
  baseUrl,
  client,
}: {
  hint?: EventHint;
  sentryOrg: string;
  baseUrl: string;
  client: Client;
}) {
  try {
    const { dsn } = client.getOptions();
    if (!dsn || !hint) {
      return 'Could not retrieve url';
    }

    const projectId = getProjectIdFromSentryDsn(dsn);

    return `${baseUrl}/organizations/${sentryOrg}/issues/?project=${projectId}&query=${hint.event_id}`;
  } catch (err) {
    return 'Could not retrieve url';
  }
}

const INTEGRATION_NAME = 'pt-sentry-fullstory';

type Options = { client: ApiV2; baseSentryUrl?: string };

export const sentryFullstory = (sentryOrg: string, { client: FS, baseSentryUrl }: Options) => {
  const baseUrl = baseSentryUrl ?? 'https://sentry.io/';

  return {
    name: INTEGRATION_NAME,
    async processEvent(event: Event, hint: EventHint, client: Client) {
      const self = client.getIntegrationByName?.(INTEGRATION_NAME);

      if (!self || event.type !== undefined || !doesFullStoryExist()) {
        return null;
      }

      try {
        const fsSessionId = await FS('getSessionAsync', { format: 'id' });
        const sessionUrlAtTime = await FS('getSessionAsync', { format: 'url.now' });

        event.contexts = {
          ...event.contexts,
          fullstory: {
            sessionId: fsSessionId ?? 'unknown',
            fullStoryUrl: sessionUrlAtTime ?? 'unknown',
          },
        };

        FS('trackEvent', {
          name: 'Sentry Error',
          properties: {
            sentryUrl: getSentryUrl({
              baseUrl,
              sentryOrg,
              hint,
              client,
            }),
            ...getOriginalExceptionProperties(hint),
          },
        });
      } catch (e) {
        return event;
      }

      return event;
    },
  };
};
