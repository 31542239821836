import { CHECK_STATUS_REQUEST_LIMIT } from 'constant';
import { setRequestCount } from 'store/entities/supplierAccounts';
import { updateSupplierAccountAction } from 'utils';
import { api as generatedApi } from './UpdateSupplierAccount.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    UpdateSupplierAccount: {
      onQueryStarted: async ({ input }, { queryFulfilled, dispatch }) => {
        const patchResult = dispatch(
          updateSupplierAccountAction(input.accountId, {
            additionalInformation: input.additionalInformation ?? undefined,
            credentials: input.credentials ?? undefined,
            nickname: input.nickname,
            store: null,
          })
        );

        try {
          const { data } = await queryFulfilled;
          const account = data.updateShopAccount?.account;

          if (!account) {
            return;
          }

          dispatch(updateSupplierAccountAction(input.accountId, account));

          dispatch(setRequestCount(CHECK_STATUS_REQUEST_LIMIT));
        } catch (e) {
          patchResult.undo();
        }
      },
    },
  },
});

export const { useUpdateSupplierAccountMutation } = api;
