import { useInputValue } from 'shared/lib/form';
import { ChangePasswordForm } from './ChangePasswordForm';
import { ConfirmPasswordForm } from './ConfirmPasswordForm';

type Props = {
  showCurrentPasswordField?: boolean;
  onSubmit: () => void;
  onCancel: () => void;
};

export const ChangePassword = ({ showCurrentPasswordField, onCancel, onSubmit }: Props) => {
  const { value, changeValue, clearValue } = useInputValue('');

  if (value) {
    return <ConfirmPasswordForm newPassword={value} onSubmit={onSubmit} onCancel={clearValue} />;
  }

  return (
    <ChangePasswordForm
      showCurrentPasswordField={showCurrentPasswordField}
      onSubmit={changeValue}
      onCancel={onCancel}
    />
  );
};
