import { Banner, Box } from '@partstech/ui';
import { FormSelectBox, FormTextField } from '@partstech/ui/forms';
import { trimStart } from '@partstech/ui/utils';
import { COUNTRIES } from 'shared/constant';
import { join, required, type PathWithMeta } from 'shared/lib/form';
import { StatesSelectBox } from './StatesSelectBox';
import { useAddressForm } from './useAddressForm';
import type { BoxProps } from '@partstech/ui';
import type { FieldValues } from '@partstech/ui/forms';
import type { Address } from 'shared/api';

type Props<FormValues> = {
  path: PathWithMeta<FormValues, Address>;
  singleAddressLine?: boolean;
  hideRequiredMark?: boolean;
} & BoxProps;

export const AddressForm = <FormValues extends FieldValues>({
  path,
  singleAddressLine,
  hideRequiredMark = true,
  ...props
}: Props<FormValues>) => {
  const { error, searchInput, onFocus, disableEnterPress, country, changeCountry, changeState } =
    useAddressForm<FormValues>({ path });

  return (
    <Box {...props} data-testid={`${path}Form`}>
      {error && (
        <Banner icon="error" variant="error" fullWidth mb={4} data-testid={`${path}ErrorBanner`}>
          {error}
        </Banner>
      )}

      <Box display="flex" flexWrap="wrap" gap={2}>
        <Box flex={`1 1 ${singleAddressLine ? 'calc(50% - 4px)' : '100%'}`}>
          <FormTextField
            label="Street address 1"
            name={join(path, 'address1')}
            normalize={trimStart}
            hideRequiredMark={hideRequiredMark}
            rules={{ required }}
            ref={searchInput}
            onFocus={onFocus}
            onKeyDown={disableEnterPress}
          />
        </Box>

        <Box flex={`1 1 ${singleAddressLine ? 'calc(50% - 4px)' : '100%'}`}>
          <FormTextField label="Street address 2" name={join(path, 'address2')} normalize={trimStart} />
        </Box>

        <Box flex="1 1 calc(50% - 4px)">
          <FormSelectBox
            onChange={changeCountry}
            name={join(path, 'country')}
            label="Country"
            options={COUNTRIES}
            rules={{ required }}
          />
        </Box>

        <Box flex="1 1 calc(50% - 4px)">
          <StatesSelectBox
            country={country}
            label="State or Province"
            name={join(path, 'state')}
            onChange={changeState}
            rules={{ required }}
          />
        </Box>

        <Box flex="1 1 calc(50% - 4px)">
          <FormTextField
            label="City"
            name={join(path, 'city')}
            normalize={trimStart}
            hideRequiredMark={hideRequiredMark}
            rules={{ required }}
          />
        </Box>

        <Box flex="1 1 calc(50% - 4px)">
          <FormTextField
            label="Zip code"
            name={join(path, 'zipCode')}
            normalize={trimStart}
            hideRequiredMark={hideRequiredMark}
            rules={{ required }}
          />
        </Box>
      </Box>
    </Box>
  );
};
