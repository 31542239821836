import { css } from '@emotion/react';
import { Box, Skeleton, type Theme } from '@partstech/ui';

const styles = {
  cartBarLoader: (theme: Theme) => css`
    border-bottom: 1px solid ${theme.color.mono6};
  `,
};

export const CartBarSkeleton = () => (
  <Box display="flex" justifyContent="space-between" p={3} css={styles.cartBarLoader} data-testid="cartBarLoader">
    <Box mr={3}>
      <Skeleton width="32" height="32" />
    </Box>

    <Box display="flex" flexGrow={1} flexDirection="column">
      <Box mb={3}>
        <Skeleton width="176" height="12" />
      </Box>

      <Skeleton width="116" height="12" />
    </Box>
  </Box>
);
