import { HistoryModalProvider, historyModalRouterBuilder } from '@partstech/ui';
import { useMemo } from 'react';
import { ChooseLocation } from './ChooseLocation';
import { Delete } from './Delete';
import { Edit } from './Edit';
import { SubmittedForm } from './SubmittedForm';
import type { CommonAccountFlowPaths } from '../../types';
import type { HistoryModalInitialRoute } from '@partstech/ui';
import type { Store, SupplierAccount } from 'models';

const router = historyModalRouterBuilder<CommonAccountFlowPaths>()
  .addRoute({
    path: 'chooseStores',
    title: 'Choose location',
    element: <ChooseLocation />,
  })
  .addRoute({
    path: 'add',
    title: 'Add supplier',
    element: <Edit />,
  })
  .addRoute({
    path: 'edit',
    title: 'Edit supplier',
    element: <Edit />,
    createActions: ({ push, state }) => {
      const { account, isRestrictedEX } = state ?? {};

      const isWithoutCredentials = Boolean(account?.supplier?.isTireWithoutCredentials);

      if (isWithoutCredentials || !account) {
        return [];
      }

      return [
        {
          grouped: false,
          title: 'Delete',
          icon: 'delete',
          variant: 'icon',
          buttonColor: 'negative',
          onClick: () => push('delete', { account, isRestrictedEX }),
        },
      ];
    },
  })
  .addRoute({
    path: 'submitted',
    title: 'Submitted',
    element: <SubmittedForm />,
  })
  .addRoute({
    path: 'delete',
    title: 'Delete supplier',
    element: <Delete />,
  })
  .build();

type OpenBefore = keyof Omit<CommonAccountFlowPaths, 'submitted'>;

type Props = {
  account: SupplierAccount;
  currentPeriodEnd: string | null;
  isRestrictedEX: boolean;
  openBefore: OpenBefore;
  selectedStore: Store;
  selectedStores: Store[];
  onClose?: () => void;
};

export const EditAccountModal = ({
  account,
  currentPeriodEnd,
  isRestrictedEX,
  openBefore,
  selectedStore,
  selectedStores,
  onClose,
}: Props) => {
  const initialRoute = useMemo<HistoryModalInitialRoute<CommonAccountFlowPaths>>(() => {
    if (openBefore === 'edit') {
      return { path: 'edit', state: { selectedStore, account } };
    }

    if (openBefore === 'chooseStores') {
      return { path: 'chooseStores', state: { selectedStores } };
    }

    if (openBefore === 'delete') {
      return { path: 'delete', state: { account, currentPeriodEnd, isRestrictedEX, onClose } };
    }

    return { path: 'add', state: { selectedStore } };
  }, [account, currentPeriodEnd, isRestrictedEX, onClose, openBefore, selectedStore, selectedStores]);

  return <HistoryModalProvider router={router} initialData={undefined} initialRoute={initialRoute} />;
};
