import { css } from '@emotion/react';
import { Box, Logo, Skeleton, type Theme, useMedia } from '@partstech/ui';

const styles = {
  header: (theme: Theme) => css`
    display: flex;
    align-items: center;
    position: sticky;
    background: ${theme.color.white};
    width: 100%;
    height: ${theme.sizing(18)};
    padding: ${theme.sizing(0, 4, 0, 4)};
  `,
  logo: css`
    flex-shrink: 0;
  `,
  vehicleSelector: css`
    flex-shrink: 0;
  `,
};

export const HeaderSkeleton = () => {
  const { isMobile, isTablet, isDesktop } = useMedia();

  if (isMobile) {
    return (
      <Box background="white" width="100%" height="100vh">
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection="row"
          gap={2}
          height={12}
          width="100%"
          px={4}
          pt={2}
        >
          <Skeleton width="24px" height="24px" />
          <Logo size="default" />
          <Skeleton width="24px" height="24px" />
        </Box>
        <Box display="flex" flexDirection="column" gap={5} px={4}>
          <Skeleton width="100%" height="40px" />
          <Skeleton width="100%" height="40px" />
        </Box>
      </Box>
    );
  }

  return (
    <Box css={styles.header} gap={4}>
      <Box flexShrink={0} width={{ md: 14, xl: 60, xxl: 70 }}>
        <Logo size={isTablet || isDesktop ? 'emblem' : 'default'} css={styles.logo} />
      </Box>
      <Skeleton width="100%" height="56px" />
      <Skeleton width="265px" height="56px" css={styles.vehicleSelector} />
    </Box>
  );
};
