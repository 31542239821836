/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';

import { StoreFragmentDoc } from '../../../../queries/currentUser/supplierAccounts/Store.generated';
import { SupplierAccountFragmentDoc } from '../../../../queries/currentUser/supplierAccounts/SupplierAccount.generated';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type UpdateSupplierAccountMutationVariables = Types.Exact<{
  input: Types.UpdateShopAccountInput;
}>;

export type UpdateSupplierAccountMutation = {
  __typename?: 'Mutation';
  updateShopAccount?: {
    __typename?: 'UpdateShopAccountPayload';
    account?: {
      __typename?: 'Account';
      customerDescription?: Types.Scalars['String']['input'] | null;
      id: string;
      nickname?: Types.Scalars['String']['input'] | null;
      priority: number;
      status: Types.AccountStatus;
      store?: {
        __typename?: 'Store';
        name: Types.Scalars['String']['input'];
        id: string;
        logo?: Types.Scalars['String']['input'] | null;
        dropship: Types.Scalars['Boolean']['input'];
        hotshot: number;
        phone?: Types.Scalars['String']['input'] | null;
        pickUp: Types.Scalars['Boolean']['input'];
        warehouse: Types.Scalars['Boolean']['input'];
        address: {
          __typename?: 'Address';
          address1: Types.Scalars['String']['input'];
          address2?: Types.Scalars['String']['input'] | null;
          city: Types.Scalars['String']['input'];
          country: Types.Country;
          state: Types.Scalars['String']['input'];
          zipCode: Types.Scalars['String']['input'];
        };
        coordinates?: {
          __typename?: 'Coordinates';
          longitude: Types.Scalars['Float']['input'];
          latitude: Types.Scalars['Float']['input'];
        } | null;
      } | null;
      additionalInformation: Array<{
        __typename?: 'AccountField';
        name: Types.Scalars['String']['input'];
        value?: Types.Scalars['String']['input'] | null;
      }>;
      credentials: Array<{
        __typename?: 'AccountField';
        name: Types.Scalars['String']['input'];
        value?: Types.Scalars['String']['input'] | null;
      }>;
    } | null;
  } | null;
};

export const UpdateSupplierAccountDocument = `
    mutation UpdateSupplierAccount($input: UpdateShopAccountInput!) {
  updateShopAccount(input: $input) {
    account {
      ...SupplierAccount
      store {
        ...Store
      }
    }
  }
}
    ${SupplierAccountFragmentDoc}
${StoreFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    UpdateSupplierAccount: build.mutation<UpdateSupplierAccountMutation, UpdateSupplierAccountMutationVariables>({
      query: (variables) => ({ document: UpdateSupplierAccountDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUpdateSupplierAccountMutation } = injectedRtkApi;
