import { useCreateShopContext } from 'components/Layout/SignUpLayout/CreateShopContext/CreateShopContext';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';

export const usePhoneNumber = () => {
  const { navigateToNextStep } = useCreateShopContext();

  const { shop } = useGetCurrentUser();

  return { shopPhoneNumber: shop?.phone ?? '', navigateToNextStep };
};
