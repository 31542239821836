import { isNotNull } from '@partstech/ui/utils';
import { useCallback, useMemo, useState } from 'react';
import { PartCategory, PartSubCategory, PartType } from 'models';
import { useSubCategory } from 'store/queries/categories/useSubCategory';
import { useSelectedPartTypes } from './useSelectedPartTypes';
import type { BreadcrumbsProps } from '@partstech/ui';
import type { SearchEntryPointInterface } from 'features/searchForm';
import type React from 'react';

type UseCategoriesResult = {
  breadcrumbs: BreadcrumbsProps['breadcrumbs'];
  options: SearchEntryPointInterface[];
  selectOption: (option: SearchEntryPointInterface) => void;
  selectedCategory: PartCategory | null;
  selectedSubCategory: PartSubCategory | null;
  resetCategory: () => void;
  selectedPartTypes: PartType[];
  isSelectedPartType: (partType: PartType) => boolean;
  removePartType: (partType: PartType) => void;
};

export const useCategorySelection = (categories: PartCategory[]): UseCategoriesResult => {
  const [selectedCategory, setSelectedCategory] = useState<PartCategory | null>(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState<PartSubCategory | null>(null);

  const { selectedPartTypes, removePartType, togglePartType, isSelected: isSelectedPartType } = useSelectedPartTypes();

  const { partTypes } = useSubCategory({ categoryId: selectedCategory?.id, subCategoryId: selectedSubCategory?.id });

  const options = useMemo(() => {
    if (selectedSubCategory) {
      return partTypes;
    }

    if (selectedCategory) {
      return selectedCategory.subCategories;
    }

    return categories;
  }, [categories, partTypes, selectedCategory, selectedSubCategory]);

  const selectOption = useCallback(
    (option: SearchEntryPointInterface) => {
      if (option instanceof PartCategory) {
        setSelectedCategory(option);
        setSelectedSubCategory(null);
        return;
      }

      if (option instanceof PartSubCategory) {
        setSelectedSubCategory(option);
      }

      if (option instanceof PartType) {
        togglePartType(option);
      }
    },
    [togglePartType]
  );

  const resetCategory = useCallback(() => {
    setSelectedCategory(null);
    setSelectedSubCategory(null);
  }, []);

  const breadcrumbs = useMemo(
    () =>
      [
        {
          title: 'All Categories',
          onClick: (e: React.MouseEvent) => {
            e.preventDefault();
            resetCategory();
          },
        },
        selectedCategory && {
          title: selectedCategory.name,
          onClick: (e: React.MouseEvent) => {
            e.preventDefault();
            selectOption(selectedCategory);
          },
        },
        selectedSubCategory && {
          title: selectedSubCategory?.name,
        },
      ].filter(isNotNull),
    [resetCategory, selectOption, selectedCategory, selectedSubCategory]
  );

  return {
    breadcrumbs,
    options,
    resetCategory,
    selectOption,
    selectedCategory,
    selectedSubCategory,
    selectedPartTypes,
    isSelectedPartType,
    removePartType,
  };
};
