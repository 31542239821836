import { useCallback } from 'react';
import { api as rootApi } from 'store/queries/currentUser/GetCurrentUser.generated';
import { api as generatedApi } from './UpdateUserPhoneNumber.generated';
import type { UpdateUserPhoneNumberInput } from 'shared/api';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    UpdateUserPhoneNumber: {
      onQueryStarted: async ({ input }, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          rootApi.util.updateQueryData('GetCurrentUser', undefined, (draft) => {
            if (draft.currentUser) {
              Object.assign(draft.currentUser, {
                phone: input.phone ?? null,
                phoneType: input.phoneType,
              });
            }
          })
        );

        queryFulfilled.catch(patchResult.undo);
      },
    },
  },
});

const { useUpdateUserPhoneNumberMutation } = api;

export const useUpdateUserPhoneNumber = () => {
  const [updateUserPhoneNumber, status] = useUpdateUserPhoneNumberMutation();

  const handleUpdateUserPhoneNumber = useCallback(
    (input: UpdateUserPhoneNumberInput) => updateUserPhoneNumber({ input }),
    [updateUserPhoneNumber]
  );

  return [handleUpdateUserPhoneNumber, status] as const;
};
