import { Routes } from 'constant';
import { generateUrl } from './generateUrl';

export type AddSuppliersTabs = 'all' | 'tires' | 'parts' | 'dealer';

export type AddSuppliersSearchParams = { activeScreen: 'all-suppliers' | 'my-suppliers' };

export type PathParams = { tab: AddSuppliersTabs };

export const getAddSupplierUrl = (tab: AddSuppliersTabs, search?: AddSuppliersSearchParams) =>
  generateUrl(Routes.ADD_SUPPLIER, { tab }, search);
