import { Box, Typography } from '@partstech/ui';
import { integerCurrency } from 'shared/lib/string';
import { DiscountNotice } from '../../../DiscountNotice';
import { ContentLoader } from './ContentLoader';
import type { BoxProps } from '@partstech/ui';

type Props = BoxProps & {
  isFree: boolean;
  isLoading: boolean;
  isYearly: boolean;
  priceMonthly: number;
  priceYearly: number;
  showDiscount?: boolean;
};

export const PriceInfo = ({
  isFree,
  isLoading,
  isYearly,
  priceMonthly,
  priceYearly,
  showDiscount,
  ...props
}: Props) => {
  if (isLoading) {
    return <ContentLoader />;
  }

  return (
    <Box {...props} data-testid="priceInfo">
      <Typography variant="h3">{integerCurrency(isYearly ? priceYearly / 12 : priceMonthly)} /month</Typography>

      <Box mt={1}>
        <Typography variant="caption" lineThrough={!isFree && isYearly}>
          {isFree ? 'Free forever' : `${integerCurrency(priceMonthly)} /month`}
        </Typography>
      </Box>

      <Typography variant="overline" component="p">
        {isFree ? 'NEVER BILLED' : `BILLED ${isYearly ? 'YEARLY' : 'MONTHLY'}`}
      </Typography>

      {showDiscount && (
        <Box mt={1} px={2}>
          <DiscountNotice isYearly={isYearly} />
        </Box>
      )}
    </Box>
  );
};
