import { adaptTireSize } from './adaptTireSize';

export const isTireSize = (value: string) => {
  const adaptedTireSize = adaptTireSize(value);

  return (
    adaptedTireSize.split('-').length === 3 &&
    adaptedTireSize
      .split('-')
      .filter((splitValue) => !Number.isNaN(Number(splitValue)))
      .filter((splitValue) => Boolean(splitValue.length > 0)).length === 3
  );
};
