import { useCallback } from 'react';
import { paymentApi } from '../paymentCards';
import { api as generatedApi } from './UpdatePaymentCard.generated';
import type { UpdateShopCardInput } from 'shared/api';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    UpdatePaymentCard: {
      onQueryStarted: async ({ input }, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          paymentApi.util.updateQueryData('GetPaymentCards', undefined, ({ currentUser }) => {
            const card = currentUser?.activeMember?.shop?.cards?.find(({ id }) => id === input.cardId);

            if (card) {
              Object.assign(card, { expMonth: input.expMonth, expYear: input.expYear });
            }
          })
        );

        queryFulfilled.catch(patchResult.undo);
      },
      invalidatesTags: ['PaymentCard'],
    },
  },
});

const { useUpdatePaymentCardMutation } = api;

export const useUpdatePaymentCard = () => {
  const [updateCard, status] = useUpdatePaymentCardMutation();

  const updatePaymentCard = useCallback((input: UpdateShopCardInput) => updateCard({ input }), [updateCard]);

  return [updatePaymentCard, status] as const;
};
