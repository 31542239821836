import { css } from '@emotion/react';
import { Box, Skeleton, type Theme } from '@partstech/ui';

const styles = {
  sidebar: (theme: Theme) => css`
    position: fixed;
    display: flex;
    flex-direction: column;
    gap: ${theme.sizing(6)};
    justify-content: flex-start;
    align-items: center;
    align-content: flex-start;
    left: 0;
    top: 0;
    bottom: 0;
    background: ${theme.color.white};
    width: 57px;
    border-right: 1px solid ${theme.color.mono17};
    padding-top: ${theme.sizing(4)};

    & :first-of-type {
      margin-bottom: ${theme.sizing(4)};
    }
  `,
};

export const SideBarSkeleton = () => (
  <Box css={styles.sidebar}>
    <Skeleton width="32px" height="32px" />

    <Skeleton width="32px" height="32px" />
    <Skeleton width="32px" height="32px" />
    <Skeleton width="32px" height="32px" />
    <Skeleton width="32px" height="32px" />
    <Skeleton width="32px" height="32px" />
    <Skeleton width="32px" height="32px" />
  </Box>
);
