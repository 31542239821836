import { useCallback, useState } from 'react';
import type { PartType } from 'models';

const PART_TYPES_LIMIT = 15;

export const useSelectedPartTypes = () => {
  const [value, setValue] = useState<PartType[]>([]);

  const isSelected = useCallback((partType: PartType) => value.some((option) => option.isEqual(partType)), [value]);

  const removePartType = useCallback(
    (partType: PartType) => {
      setValue(value.filter((item) => item.id !== partType.id));
    },
    [value]
  );

  const addPartType = useCallback(
    (partType: PartType) => {
      if (value.length >= PART_TYPES_LIMIT) {
        return;
      }

      setValue([...value, partType]);
    },
    [value]
  );

  const togglePartType = useCallback(
    (partType: PartType) => {
      if (isSelected(partType)) {
        removePartType(partType);
        return;
      }

      addPartType(partType);
    },
    [addPartType, isSelected, removePartType]
  );

  return { isSelected, selectedPartTypes: value, removePartType, addPartType, togglePartType };
};
