import { Box, OldList, OldNavigationListItem, TextCut } from '@partstech/ui';
import { isString } from '@partstech/ui/utils';
import React, { useCallback } from 'react';
import { Highlight } from 'components/Highlight';
import { Title } from './Title';
import type { BoxProps } from '@partstech/ui';
import type { SearchEntryPointInterface } from 'features/searchForm';

export type OptionsListProps<T extends SearchEntryPointInterface | string> = BoxProps & {
  isActive?: (optionIndex: number) => boolean;
  onChange: (option: T) => void;
  onSelect?: (option: T) => void;
  options: T[];
  title?: string;
  adornmentBefore?: (option: T) => React.ReactNode;
  adornmentAfter?: (option: T) => React.ReactNode;
  highlightText?: string;
};

// TODO Extract to reusable component DEV-3506
export const OptionList = <T extends SearchEntryPointInterface | string>({
  adornmentAfter,
  adornmentBefore,
  highlightText,
  isActive,
  onChange,
  onSelect,
  options,
  title,
  ...props
}: OptionsListProps<T>) => {
  const handleSelect = useCallback(
    (option: T) => () => {
      onChange(option);
    },
    [onChange]
  );

  return (
    <Box data-testid="column" {...props}>
      {title && <Title title={title} pb={3} pl={4} />}

      <OldList size="slim" fullWidth>
        {options.map((option, index) => (
          <OldNavigationListItem
            key={`${index}:${option.toString()}`}
            active={isActive ? isActive(index) : false}
            className={!isString(option) && option.getClassName ? option.getClassName() : undefined}
            onClick={handleSelect(option)}
            data-testid="listItem"
            adornmentBefore={adornmentBefore && adornmentBefore(option)}
            alignItems="flex-start"
          >
            <TextCut>
              <Highlight value={highlightText ?? ''}>{option.toString()}</Highlight>
              {adornmentAfter && <> {adornmentAfter(option)}</>}
            </TextCut>
          </OldNavigationListItem>
        ))}
      </OldList>
    </Box>
  );
};
