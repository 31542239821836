import { Box, Button, Card, Link, Typography } from '@partstech/ui';
import { SUPPORT_EMAIL, SUPPORT_PHONE } from 'shared/constant';
import type { CardProps } from '@partstech/ui';

export const SupportCard = (props: CardProps) => (
  <Card p={5} width="100%" {...props}>
    <Box mb={4} width="100%">
      <Typography variant="bodyText2">Let us help! Give us a call or send us an email.</Typography>
    </Box>

    <Box width="70%">
      <Button
        variant="light"
        leadingIcon="call"
        iconColor="primary"
        as={<Link variant="subtitle2" color="primary" to={`tel:${SUPPORT_PHONE}`} />}
      >
        <Typography variant="subtitle2" color="primary">
          {SUPPORT_PHONE}
        </Typography>
      </Button>

      <Button variant="light" leadingIcon="email" iconColor="primary" as={<Link to={`mailto:${SUPPORT_EMAIL}`} />}>
        <Typography variant="subtitle2" color="primary">
          {SUPPORT_EMAIL}
        </Typography>
      </Button>
    </Box>
  </Card>
);
