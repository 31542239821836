/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import { VehicleFragmentDoc } from '../../../../../store/queries/vehicle/VehicleFragment.generated';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type LinkVehicleToCartMutationVariables = Types.Exact<{
  input: Types.LinkVehicleToCartInput;
}>;

export type LinkVehicleToCartMutation = {
  __typename?: 'Mutation';
  linkVehicleToCart?: {
    __typename?: 'LinkVehicleToCartPayload';
    linkedVehicle?: {
      __typename?: 'CartLinkedVehicle';
      id: string;
      vin?: Types.Scalars['String']['input'] | null;
      vehicle?: {
        __typename?: 'Vehicle';
        id: string;
        type: Types.VehicleType;
        regions?: Array<Types.VehicleRegion> | null;
        year: number;
        make: { __typename?: 'VehicleMake'; id: string; name: Types.Scalars['String']['input']; priority: number };
        model: { __typename?: 'VehicleModel'; id: string; name: Types.Scalars['String']['input'] };
        subModel: { __typename?: 'VehicleSubModel'; id: string; name: Types.Scalars['String']['input'] };
        engine: { __typename?: 'VehicleEngine'; id: string; name: Types.Scalars['String']['input'] };
      } | null;
    } | null;
  } | null;
};

export const LinkVehicleToCartDocument = `
    mutation LinkVehicleToCart($input: LinkVehicleToCartInput!) {
  linkVehicleToCart(input: $input) {
    linkedVehicle {
      id
      vin
      vehicle {
        ...Vehicle
      }
    }
  }
}
    ${VehicleFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    LinkVehicleToCart: build.mutation<LinkVehicleToCartMutation, LinkVehicleToCartMutationVariables>({
      query: (variables) => ({ document: LinkVehicleToCartDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useLinkVehicleToCartMutation } = injectedRtkApi;
