/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type UpdateShopCartSettingsMutationVariables = Types.Exact<{
  input: Types.UpdateShopCartSettingsInput;
}>;

export type UpdateShopCartSettingsMutation = {
  __typename?: 'Mutation';
  updateShopCartSettings?: { __typename: 'UpdateShopCartSettingsPayload' } | null;
};

export const UpdateShopCartSettingsDocument = `
    mutation UpdateShopCartSettings($input: UpdateShopCartSettingsInput!) {
  updateShopCartSettings(input: $input) {
    __typename
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    UpdateShopCartSettings: build.mutation<UpdateShopCartSettingsMutation, UpdateShopCartSettingsMutationVariables>({
      query: (variables) => ({ document: UpdateShopCartSettingsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUpdateShopCartSettingsMutation } = injectedRtkApi;
