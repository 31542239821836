import { Box, Card, Skeleton } from '@partstech/ui';

export const DashboardSkeleton = () => (
  <Box pl={18} pr={{ md: 4, lg: 74 }} pt={4}>
    <Card width="100%" height={129} elevation={null} p={4} mb={5}>
      <Box display="flex" gap={4} mb={4}>
        <Skeleton width="150px" height="50px" />
        <Skeleton width="150px" height="50px" />
        <Skeleton width="150px" height="50px" />
        <Skeleton width="150px" height="50px" />
        <Skeleton width="150px" height="50px" />
      </Box>
      <Box display="flex" flexDirection="column" gap={2} pl={4}>
        <Skeleton width="120px" height="24px" />
        <Skeleton width="200px" height="24px" />
        <Skeleton width="270px" height="24px" />
        <Skeleton width="120px" height="24px" />
        <Skeleton width="200px" height="24px" />
        <Skeleton width="270px" height="24px" />
        <Skeleton width="120px" height="24px" />
        <Skeleton width="200px" height="24px" />
        <Skeleton width="270px" height="24px" />
        <Skeleton width="120px" height="24px" />
        <Skeleton width="200px" height="24px" />
        <Skeleton width="270px" height="24px" />
      </Box>
    </Card>
  </Box>
);
