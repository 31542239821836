import { Box, Status } from '@partstech/ui';
import type { BoxProps } from '@partstech/ui';

type Props = BoxProps & {
  error?: string | null;
};

export const ErrorMessage = ({ error, ...boxProps }: Props) => {
  if (!error) {
    return null;
  }

  return (
    <Box {...boxProps}>
      <Status variant="light" color={error === 'Part is not available' ? 'monoDark' : 'negative'}>
        {error.toUpperCase()}
      </Status>
    </Box>
  );
};
