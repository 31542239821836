import type { UserName } from 'types/userName';

export const getUserInitials = (user: UserName) => {
  const { firstName, lastName } = user;

  if (!user.lastName) {
    return firstName?.charAt(0);
  }

  return `${firstName?.charAt(0)}${lastName?.charAt(0)}`;
};
