import { isEmpty } from '@partstech/ui/utils';
import type { RegisterOptions } from '@partstech/ui/forms';

export const errorMessages = {
  lettersOnly: 'Please enter letters only (ie a, b, c, etc)',
  numbersOnly: 'Please enter numbers only (ie 1, 2, 3, etc)',
  required: 'Field is required',
  invalidEmail: 'Invalid email address',
  maxLength: (length: number) => `Must be ${length} characters or less`,
  minLength: (length: number) => `Must be ${length} characters or more`,
  rangeLength: (range: string) => `Value must be ${range} characters`,
  noSpaces: 'Field can not contain spaces',
  notOnlySpaces: 'Field can not contain only spaces',
  passwordsShouldMatch: 'The password fields must match',
  emailsShouldMatch: 'Emails don’t match',
  notEmptyArray: 'Must contain at least one item',
};

export const required: RegisterOptions['required'] = { value: true, message: errorMessages.required };

export const email: RegisterOptions['pattern'] = {
  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
  message: errorMessages.invalidEmail,
};

export const alphabet: RegisterOptions['pattern'] = {
  value: /^[a-zA-Z]+$/,
  message: errorMessages.lettersOnly,
};

export const number: RegisterOptions['pattern'] = {
  value: /^[0-9]+$/,
  message: errorMessages.numbersOnly,
};

export const maxLength = (length: number): RegisterOptions['maxLength'] => ({
  value: length,
  message: errorMessages.maxLength(length),
});

export const minLength = (length: number): RegisterOptions['minLength'] => ({
  value: length,
  message: errorMessages.minLength(length),
});

type MinMaxLengthValidation = {
  max: number | null;
  message: string | null;
  min: number | null;
  regex: string | null;
};

export const minMaxLength = (value: string | null, validation?: MinMaxLengthValidation) => {
  if (value === null) {
    return null;
  }

  const { max, min } = validation || {};

  if (max && value.length > max) {
    return errorMessages.maxLength(max);
  }

  if (min && value.length < min) {
    return errorMessages.minLength(min);
  }

  return null;
};

type RangeLength = (range: string) => RegisterOptions['validate'];

export const rangeLength: RangeLength = (range: string) => (value: string) => {
  const numbers = range.split('-').map(Number);

  const minLengthNumber = numbers[0] as number;
  const maxLengthNumber = numbers[1] ?? minLengthNumber;

  return range && (value.length < minLengthNumber || value.length > maxLengthNumber)
    ? errorMessages.rangeLength(range)
    : undefined;
};

const max = (value: string | number): RegisterOptions['max'] => ({
  value,
  message: `Must be ${max} or less`,
});

export const noOnlySpaces: RegisterOptions['pattern'] = {
  value: /\S/,
  message: errorMessages.notOnlySpaces,
};

export const passwordsShouldMatch = (mainPassValue: string) => (value: string) =>
  !isEmpty(mainPassValue) && value !== mainPassValue ? errorMessages.passwordsShouldMatch : undefined;

export const notEmptyArray: RegisterOptions['validate'] = (value) =>
  isEmpty(value) ? 'Must contain at least one item' : undefined;

export const isSame = (value: string, message: string) => (fieldValue: string) =>
  value === fieldValue ? message : undefined;
