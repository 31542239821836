import { css } from '@emotion/react';
import { Box, ImageFallback } from '@partstech/ui';
import { memo } from 'react';
import type { Theme, BoxProps } from '@partstech/ui';
import type { Store } from 'models';

const styles = {
  logo: (width: number | string, height: number | string) => (theme: Theme) => css`
    height: ${theme.sizing(height)};
    width: ${theme.sizing(width)};
    object-fit: contain;
    flex-basis: content;
  `,
};

type Props = {
  store: Store | null;
  width: number | string;
  height: number | string;
} & BoxProps;

export const StoreLogo = memo(({ store, width, height, ...props }: Props) => {
  const logo = store?.logo ?? store?.supplier?.logo ?? '';
  const supplierName = store?.supplier?.name ?? '';

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
      maxWidth={width}
      height={height}
      {...props}
    >
      <ImageFallback src={logo} alt={supplierName} css={styles.logo(width, height)} data-testid="logo" />
    </Box>
  );
});
