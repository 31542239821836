import { createSelector, createSlice } from '@reduxjs/toolkit';
import { productApi } from 'store/entities/product';
import { queryToUrlParams, selectId } from 'utils';
import { logout } from './user/account';
import type { RootState } from 'store';

type ProductPageState = {
  fetchedIds: string[];
  fetchedWithErrorIds: string[];
  isFailed: boolean;
  fetchingIds: string[];
  productId: string | null;
};

const initialState: ProductPageState = {
  fetchedIds: [],
  fetchedWithErrorIds: [],
  isFailed: false,
  fetchingIds: [],
  productId: null,
};

const productPageSlice = createSlice({
  name: 'productPage',
  initialState,
  reducers: {
    resetProductId: (state) => {
      state.productId = initialState.productId;
    },
    resetProductPage: (state) => {
      state.isFailed = initialState.isFailed;
      state.fetchingIds = initialState.fetchingIds;
    },
    resetFetchedIds: (state) => {
      state.fetchedIds = initialState.fetchedIds;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logout.fulfilled, () => initialState)
      .addMatcher(productApi.endpoints.GetProduct.matchPending, (state, action) => {
        const productId = selectId(queryToUrlParams(action.meta.arg.originalArgs));

        state.fetchingIds.push(productId);
      })
      .addMatcher(productApi.endpoints.GetProduct.matchFulfilled, (state, action) => {
        state.isFailed = false;

        const productId = selectId(queryToUrlParams(action.meta.arg.originalArgs));

        state.fetchingIds = state.fetchingIds.filter((id) => id !== productId);

        state.fetchedIds.push(productId);
      })
      .addMatcher(productApi.endpoints.GetProduct.matchRejected, (state, action) => {
        state.isFailed = true;

        const productId = selectId(queryToUrlParams(action.meta.arg.originalArgs));

        state.fetchingIds = state.fetchingIds.filter((id) => id !== productId);

        if (!state.fetchedIds.includes(productId)) {
          state.fetchedIds.push(productId);
        }

        if (!state.fetchedWithErrorIds.includes(productId)) {
          state.fetchedWithErrorIds.push(productId);
        }
      });
  },
});

export const productPage = productPageSlice.reducer;

export const { resetProductPage, resetProductId, resetFetchedIds } = productPageSlice.actions;

const selectState = (state: RootState) => state.features.productPage;

export const selectFetchedIds = createSelector([selectState], (page) => page.fetchedIds);

export const selectFetchingIds = createSelector([selectState], (page) => page.fetchingIds);

export const selectFetchedWithErrorIds = createSelector([selectState], (page) => page.fetchedWithErrorIds);

export const selectIsProductsFetching = createSelector([selectState], (state) => state.fetchingIds.length > 0);

export const selectIsProductsFetched = createSelector(
  [selectFetchedIds, (_: unknown, ids: string[]) => ids],
  (fetchedIds, ids) => ids.every((id: string) => fetchedIds.includes(id))
);

export const selectIsProductsFetchFailed = createSelector(
  [selectFetchedWithErrorIds, (_: unknown, ids: string[]) => ids],
  (failedIds, ids) => ids.some((id: string) => failedIds.includes(id))
);
