import { Availability } from 'types/search';
import type { Product } from 'models';
import type { CheckedFilters } from 'types/search';

export const filterByAvailability = (product: Product, values: CheckedFilters['availability'] | undefined) =>
  values?.some((value) => {
    if (value === Availability.OUT_OF_STOCK) {
      return product.quote?.isOutOfStock();
    }

    if (value === Availability.SPECIAL_ORDER) {
      return product.quote?.isSpecialOrder();
    }

    if (value === Availability.BACK_ORDER) {
      return product.quote?.isBackOrder();
    }

    if (value === Availability.IN_STOCK) {
      return product.quote?.isInStock();
    }

    return false;
  }) ?? false;
