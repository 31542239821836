import { useSnackbar } from '@partstech/ui';
import { useCallback } from 'react';
import type { GraphQLError } from '../GraphQLError';
import type { FieldValues } from '@partstech/ui/forms';

export type UseMutationSnackbarArgs = {
  successMessage?: string;
  errorMessage?: string;
};

/**
 * useMutationSnackbar is a custom hook that helps display success and error snackbar notifications
 * based on the result of a mutation, using the `useSnackbar` function. It accepts optional success
 * and error messages, and provides two functions to show these messages.
 *
 * @param {Args} params - An optional object containing:
 *  - `successMessage` (optional): A string message to display when the mutation succeeds.
 *  - `errorMessage` (optional): A string message to display when the mutation fails. If not provided,
 *     the hook will show a default error message based on the GraphQL error or a generic message.
 *
 * @returns {Object} - Returns an object with two functions:
 *  - `showSuccessMessage`: A function that triggers a success snackbar message if `successMessage` is provided.
 *  - `showErrorMessage`: A generic function that takes a GraphQL error and triggers an error snackbar message.
 *    It uses the `generateMutationError` function to format the error and displays the provided `errorMessage`,
 *    or falls back to the error’s message or a generic error message if necessary.
 *
 * Example usage:
 * ```tsx
 * const { showSuccessMessage, showErrorMessage } = useMutationSnackbar({
 *   successMessage: 'Data saved successfully!',
 *   errorMessage: 'Failed to save data',
 * });
 *
 * try {
 *   await mutateData();
 *   showSuccessMessage();
 * } catch (error) {
 *   showErrorMessage(error);
 * }
 * ```
 */
export const useMutationSnackbar = ({ successMessage, errorMessage }: UseMutationSnackbarArgs) => {
  const { addSnackbar } = useSnackbar();

  const showSuccessMessage = useCallback(() => {
    if (!successMessage) {
      return;
    }

    addSnackbar({ label: successMessage });
  }, [addSnackbar, successMessage]);

  const showErrorMessage = useCallback(
    <FormData extends FieldValues = {}>(graphQLError: GraphQLError<FormData>) => {
      addSnackbar({ label: errorMessage || graphQLError.message || 'Something went wrong' });
    },
    [addSnackbar, errorMessage]
  );

  return { showSuccessMessage, showErrorMessage };
};
