/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type GetStockOrderTemplatesQueryVariables = Types.Exact<{
  page: Types.Scalars['Int']['input'];
  perPage: Types.Scalars['Int']['input'];
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type GetStockOrderTemplatesQuery = {
  __typename?: 'Query';
  currentUser?: {
    __typename?: 'User';
    activeMember?: {
      __typename?: 'ShopMember';
      shop?: {
        __typename?: 'Shop';
        stockOrderTemplates?: {
          __typename?: 'StockOrderTemplateConnection';
          totalCount: number;
          items?: Array<{
            __typename?: 'StockOrderTemplate';
            id: string;
            lastUseDate: Types.Scalars['DateTime']['input'];
            name: Types.Scalars['String']['input'];
            items?: Array<{
              __typename?: 'StockOrderTemplateItem';
              id: string;
              displayPartNumber: Types.Scalars['String']['input'];
              image?: Types.Scalars['String']['input'] | null;
              partName?: Types.Scalars['String']['input'] | null;
              quantity: number;
              supplier?: { __typename?: 'Supplier'; name: Types.Scalars['String']['input'] } | null;
              tireSize?: {
                __typename?: 'TireSize';
                height: Types.Scalars['Float']['input'];
                rim: Types.Scalars['Float']['input'];
                width: Types.Scalars['Float']['input'];
              } | null;
            }> | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export const GetStockOrderTemplatesDocument = `
    query GetStockOrderTemplates($page: Int!, $perPage: Int!, $search: String) {
  currentUser {
    activeMember {
      shop {
        stockOrderTemplates(page: $page, perPage: $perPage, search: $search) {
          totalCount
          items {
            id
            lastUseDate
            name
            items {
              id
              displayPartNumber
              image
              partName
              supplier {
                name
              }
              tireSize {
                height
                rim
                width
              }
              quantity
            }
          }
        }
      }
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetStockOrderTemplates: build.query<GetStockOrderTemplatesQuery, GetStockOrderTemplatesQueryVariables>({
      query: (variables) => ({ document: GetStockOrderTemplatesDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetStockOrderTemplatesQuery, useLazyGetStockOrderTemplatesQuery } = injectedRtkApi;
