import { css } from '@emotion/react';
import { Link, Box, Card, Typography, useMedia } from '@partstech/ui';
import { Helmet } from 'react-helmet-async';
import notFoundImg from 'images/spinoff/main/not-found.svg';
import { getDashboardUrl } from 'utils/url';
import type { Theme } from '@partstech/ui';

const styles = {
  logo: (theme: Theme) => css`
    max-width: 100%;

    ${theme.breakpoints.down('md')} {
      max-width: 70%;
    }
  `,
};

export const NotFound = () => {
  const { isMobile } = useMedia();

  return (
    <Box mx="auto" data-testid="notFound">
      <Helmet>
        <title>Page Not Found</title>
      </Helmet>

      <Card
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection={isMobile ? 'column-reverse' : 'column'}
        maxWidth={200}
        my={6}
        mx="auto"
        p={12}
        textAlign="center"
      >
        <Box mt={12} mx="auto" textAlign="center">
          <img src={notFoundImg} alt="" css={styles.logo} />
        </Box>

        <Box width="100%" pt={isMobile ? 0 : 7} px={isMobile ? 0 : 17}>
          <Box mt={2} mb={7}>
            <Typography variant="tagline1" color="dark">
              Sorry, we couldn't find what you were looking for.
            </Typography>
          </Box>

          <Typography component="div">
            Please try searching using forms above or go to{' '}
            <Link color="primary" to={getDashboardUrl()}>
              PartsTech's home page
            </Link>
            .
          </Typography>
        </Box>
      </Card>
    </Box>
  );
};
