import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'hooks/search';
import { useSearchBar } from '../context/SearchBarContext';

type Props = {
  onSearchEntryRequired: () => void;
  onVehicleRequired: () => void;
};

export const useSearchBarStateValidation = ({ onSearchEntryRequired, onVehicleRequired }: Props) => {
  const { searchEntry, vehicle: selectedVehicle } = useSearchBar();
  const { hasValidationInSearchParams, isReady, vehicleId } = useSearchParams();

  const shouldValidate = hasValidationInSearchParams && isReady;

  const hasVehicleError = useMemo(() => {
    if (!hasValidationInSearchParams) {
      return false;
    }

    return !vehicleId;
  }, [hasValidationInSearchParams, vehicleId]);

  const isSearchEntryRequired = shouldValidate && !searchEntry;

  useEffect(() => {
    if (!shouldValidate) {
      return;
    }

    // TODO: remove !vehicle when the old search form will be removed
    if (!vehicleId && !selectedVehicle) {
      onVehicleRequired();
      return;
    }

    if (isSearchEntryRequired && !hasVehicleError) {
      onSearchEntryRequired();
    }
  }, [
    hasVehicleError,
    isSearchEntryRequired,
    onSearchEntryRequired,
    onVehicleRequired,
    selectedVehicle,
    shouldValidate,
    vehicleId,
  ]);
};
