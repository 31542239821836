import { Box, Checkbox } from '@partstech/ui';
import React, { useCallback, useRef, useState } from 'react';
import { TireConfiguration } from 'models';
import { Title } from '../../Title';
import { TireSizeForm } from './TireSizeForm';
import type { TireSizeFormRef } from './TireSizeForm';
import type { TireSize } from 'models';

type Props = {
  options: TireSize[];
  onSelect?: (value: TireConfiguration) => void;
};

export const TireSizeSelector = ({ options: tireSizes, onSelect }: Props) => {
  const frontSizeForm = useRef<TireSizeFormRef>(null);
  const rearSizeForm = useRef<TireSizeFormRef>(null);

  const [frontSize, setFrontSize] = useState<TireSize | null>(null);
  const [isStaggered, setIsStaggered] = useState(false);

  const handleStaggeredCheckboxChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setIsStaggered(e.target.checked);
  }, []);

  const handleFrontFormChange = useCallback(
    (tireSize: TireSize) => {
      if (isStaggered && tireSize) {
        setFrontSize(tireSize);
        rearSizeForm.current?.focus();
        return;
      }

      if (!onSelect) {
        return;
      }

      onSelect(new TireConfiguration(tireSize));
    },
    [isStaggered, onSelect]
  );

  const handleRearFormChange = useCallback(
    (rearSize: TireSize) => {
      if (!onSelect || frontSize === null) {
        return;
      }

      onSelect(new TireConfiguration(frontSize, rearSize));
    },
    [frontSize, onSelect]
  );

  return (
    <Box display="flex" flexDirection="column" gap={4} width="100%" data-testid="tireSizeSelector" p={{ md: 4 }}>
      <Title>Search by size</Title>

      <Checkbox name="staggered" data-testid="checkbox" checked={isStaggered} onChange={handleStaggeredCheckboxChange}>
        Different sizes for Front/Rear
      </Checkbox>

      <TireSizeForm
        ref={frontSizeForm}
        label={isStaggered ? 'Front' : undefined}
        tireSizes={tireSizes}
        defaultValue={{ width: null, height: null, rim: null }}
        data-testid="frontSize"
        onChange={handleFrontFormChange}
      />

      {isStaggered && (
        <TireSizeForm
          ref={rearSizeForm}
          label="Rear"
          tireSizes={tireSizes}
          defaultValue={{ width: null, height: null, rim: null }}
          data-testid="rearSize"
          onChange={handleRearFormChange}
        />
      )}
    </Box>
  );
};
