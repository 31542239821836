import { Availability } from 'types/search';
import type { Filter } from 'types/search';

export const CATEGORY_EXHAUST = '11';

export const CATALOG_GROUP = 1;

export const PREDEFINED_FILTERS: Filter['name'][] = [
  'availability',
  'storeAvailability',
  'parts',
  'manufacturers',
  'HasRebate',
  'fit',
];

export const DEFAULT_FILTERS_SORT_INDEX = 10000;

export const DEFAULT_AVAILABILITY = [Availability.IN_STOCK, Availability.BACK_ORDER];

export const VIN_ERROR = 'Your VIN was not recognized. Please check to be sure you entered the 17-digit VIN correctly.';

export const PLATE_ERROR =
  'Your license plate was not recognized. Please be sure that you have entered the correct license plate number and selected the state where the vehicle is registered.';
