import { css } from '@emotion/react';
import { Button, Icon } from '@partstech/ui';
import { useCallback } from 'react';
import type { IconProps, Theme } from '@partstech/ui';
import type { MouseEvent } from 'react';

const styles = {
  button: (theme: Theme) => css`
    height: auto;
    padding: ${theme.sizing(1)};
    z-index: 1;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `,
  buttonNavigationPrev: (theme: Theme) => css`
    background: ${theme.color.mono2};
    position: absolute;
    left: 0;
  `,
  buttonNavigationNext: (theme: Theme) => css`
    background: ${theme.color.mono2};
    position: absolute;
    right: 0;
  `,
};

type Props = {
  currentIndex: number;
  setCurrentIndex: (index: number) => void;
  filesCount: number;
  iconSize?: IconProps['size'];
};

export const FileViewerNavigation = ({ currentIndex, setCurrentIndex, filesCount, iconSize = 'medium' }: Props) => {
  const handlePrevClick = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();

      setCurrentIndex(currentIndex === 0 ? filesCount - 1 : currentIndex - 1);
    },
    [filesCount, currentIndex, setCurrentIndex]
  );

  const handleNextClick = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();

      setCurrentIndex(currentIndex === filesCount - 1 ? 0 : currentIndex + 1);
    },
    [filesCount, currentIndex, setCurrentIndex]
  );

  return (
    <>
      <Button variant="text" css={[styles.button, styles.buttonNavigationPrev]} onClick={handlePrevClick} noHover>
        <Icon name="arrow_back" size={iconSize} color="subtleText" />
      </Button>

      <Button variant="text" css={[styles.button, styles.buttonNavigationNext]} onClick={handleNextClick} noHover>
        <Icon name="arrow_forward" size={iconSize} color="subtleText" />
      </Button>
    </>
  );
};
