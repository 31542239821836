import { useOpen } from '@partstech/ui/hooks';
import { CreateSystem } from 'features/shop';
import { SearchManagementSystem } from './SearchManagementSystem';

type Props = {
  selectedManagementSystemId?: string;
  onSubmit: () => void;
};

export const SelectManagementSystem = ({ selectedManagementSystemId, onSubmit }: Props) => {
  const { isOpen: isSearchSystem, open: showSearchSystem, close: showCreateSystem } = useOpen(true);

  if (isSearchSystem) {
    return (
      <SearchManagementSystem
        selectedManagementSystemId={selectedManagementSystemId}
        onSubmit={onSubmit}
        onNotListedSoftwareClick={showCreateSystem}
      />
    );
  }

  return <CreateSystem onSubmit={onSubmit} onSearchClick={showSearchSystem} />;
};
