import { css } from '@emotion/react';
import { Box, Icon } from '@partstech/ui';
import type { IconName } from '@partstech/ui';

const styles = {
  tooltipContainer: css`
    & .ui-tooltip-trigger {
      display: flex;
    }
  `,
};

const iconVariant: Record<'cardNumber' | 'expire', IconName> = {
  cardNumber: 'credit_card',
  expire: 'calendar_today',
};

type Props = {
  type: 'cardNumber' | 'expire' | 'cvc';
};

export const FieldAdornment = ({ type }: Props) => {
  if (type === 'cvc') {
    return null;
  }

  return (
    <Box display="flex" css={styles.tooltipContainer}>
      <Icon color="subtleText" name={iconVariant[type]} />
    </Box>
  );
};
