import { Icon, MenuItem } from '@partstech/ui';
import { useOpen } from '@partstech/ui/hooks';
import { useCallback } from 'react';
import { CardBrand, CardName } from 'entities/paymentCard';
import type { PaymentCard } from 'entities/paymentCard';

type Props = {
  card: PaymentCard;
  isSelectedCard: boolean;
  onCloseDropdown: () => void;
  onEditCard: (card: PaymentCard) => void;
  onSelectCard: (card: PaymentCard) => void;
};

export const CreditCardItem = ({ card, isSelectedCard, onCloseDropdown, onEditCard, onSelectCard }: Props) => {
  const { isOpen: isMouseOnCardItem, toggle: handleCardItemHoverToggle } = useOpen();
  const { isOpen: isMouseOnEditIcon, toggle: handleEditHoverToggle } = useOpen();

  const handleEditCard = useCallback(() => {
    onEditCard(card);
  }, [card, onEditCard]);

  const handleSelectCard = useCallback(() => {
    onSelectCard(card);

    onCloseDropdown();
  }, [card, onSelectCard, onCloseDropdown]);

  return (
    <MenuItem
      prefix={<CardBrand name={card.brand} size="small" />}
      suffix={
        isMouseOnCardItem && (
          <Icon
            name="edit"
            color={isMouseOnEditIcon ? 'primary' : 'subtleText'}
            onClick={handleEditCard}
            onMouseEnter={handleEditHoverToggle}
            onMouseLeave={handleEditHoverToggle}
          />
        )
      }
      secondaryText={isSelectedCard ? '(this card)' : ''}
      onClick={isMouseOnEditIcon ? undefined : handleSelectCard}
      onMouseEnter={handleCardItemHoverToggle}
      onMouseLeave={handleCardItemHoverToggle}
      size="dense"
    >
      <CardName card={card} color={card.isExpired ? 'negative' : 'defaultText'} showExpired />
    </MenuItem>
  );
};
