import { isNotNull } from '@partstech/ui/utils';
import { useMemo } from 'react';
import { useGetAddresses } from 'entities/address';
import { getShortPlanName, useSubscription } from 'features/subscription';
import { useAfterMarketSupplierAccounts, useSupplierAccounts } from 'hooks/supplierAccounts';
import { useLaunchDarkly } from 'integrations/launchDarkly';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import type { IdentifyUserpilotArgs } from 'integrations/userpilot';

export const useGetUserpilotParams = (): IdentifyUserpilotArgs & { isReady: boolean } => {
  const { flags } = useLaunchDarkly();
  const availableFlags = useMemo(
    () =>
      Object.entries(flags)
        .filter(([, value]) => value)
        .map(([key]) => key)
        .join('-'),
    [flags]
  );

  const {
    activeMember,
    accountId,
    email,
    fullName,
    registrationDate,
    managementSystem,
    shopName,
    hasCurrentUser,
    isSuccess,
    shop,
  } = useGetCurrentUser();

  const { defaultAddress } = useGetAddresses({ skip: !activeMember });

  const { accounts, isFetching: isFetchingSupplierAccounts } = useSupplierAccounts();
  const { afterMarketAccounts } = useAfterMarketSupplierAccounts();

  const { subscription, isLoading: isSubscriptionLoading } = useSubscription();

  const suppliersNames = useMemo(
    () =>
      accounts
        .map((account) => account.supplier?.name)
        .filter(isNotNull)
        .join('-'),
    [accounts]
  );

  const canIdentifyUserpilot = hasCurrentUser && isSuccess && !isFetchingSupplierAccounts && !isSubscriptionLoading;

  return {
    userid: accountId ?? '',
    parameters: {
      email,
      ldFlags: availableFlags,
      fullName,
      package: getShortPlanName(subscription?.plan?.name ?? '').toLowerCase(),
      partSupplierCount: afterMarketAccounts.length,
      created_at: registrationDate,
      shopName,
      shopShippingZipCode: shop?.attributes?.defaultShippingZipCode ?? null,
      shopState: defaultAddress?.state ?? null,
      shopManagementSystemName: managementSystem?.name ?? null,
      subscriptionStartedAt: subscription?.startedAt ?? null,
      suppliers: suppliersNames,
      numberOrders: shop?.orderingStatistic?.totalPaidOrders ?? 0,
      lastOrderDate: shop?.orderingStatistic?.lastPaidDate ?? null,
      id: accountId,
      userIdEven: Number(accountId) % 2 === 0,
    },
    isReady: canIdentifyUserpilot,
  };
};
