/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type ChangeEmailConfirmationMutationVariables = Types.Exact<{
  input: Types.ChangeEmailConfirmationInput;
}>;

export type ChangeEmailConfirmationMutation = {
  __typename?: 'Mutation';
  changeEmailConfirmation?: { __typename: 'ChangeEmailConfirmationPayload' } | null;
};

export const ChangeEmailConfirmationDocument = `
    mutation ChangeEmailConfirmation($input: ChangeEmailConfirmationInput!) {
  changeEmailConfirmation(input: $input) {
    __typename
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    ChangeEmailConfirmation: build.mutation<ChangeEmailConfirmationMutation, ChangeEmailConfirmationMutationVariables>({
      query: (variables) => ({ document: ChangeEmailConfirmationDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useChangeEmailConfirmationMutation } = injectedRtkApi;
