import { api, gExportApi, gUploadApi, shopApi } from 'shared/api';
import type { Middleware, MiddlewareAPI, UnknownAction } from '@reduxjs/toolkit';
import type { RootDispatch, RootState } from 'store';

export const resetMiddleware: Middleware<{}, RootState> =
  ({ dispatch }: MiddlewareAPI<RootDispatch>) =>
  (next) =>
  (action) => {
    if ((action as UnknownAction).type === 'features/user/account/fulfilled') {
      dispatch(api.util.resetApiState());
      dispatch(shopApi.util.resetApiState());
      dispatch(gUploadApi.util.resetApiState());
      dispatch(gExportApi.util.resetApiState());

      sessionStorage.clear();
      localStorage.clear();
    }

    return next(action);
  };
