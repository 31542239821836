import { currency } from './currency';

type Options = Partial<{
  decimal: string;
  format: string;
  symbol: string;
  thousand: string;
}>;

export const integerCurrency = (value: number | string, options?: Options) =>
  currency(value, options?.symbol, 0, options?.thousand, options?.decimal, options?.format);
