/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type UpdateShippingMethodMutationVariables = Types.Exact<{
  orderId: Types.Scalars['ID']['input'];
  shippingMethodCode: Types.Scalars['String']['input'];
}>;

export type UpdateShippingMethodMutation = {
  __typename?: 'Mutation';
  updateActiveCartOrderShippingMethod?:
    | { __typename?: 'UpdateOrderShippingMethodErrorPayload'; errorMessage: Types.Scalars['String']['input'] }
    | { __typename?: 'UpdateOrderShippingMethodSuccessPayload'; updatedOrderId: string }
    | null;
};

export const UpdateShippingMethodDocument = `
    mutation UpdateShippingMethod($orderId: ID!, $shippingMethodCode: String!) {
  updateActiveCartOrderShippingMethod(
    input: {orderId: $orderId, shippingMethodCode: $shippingMethodCode}
  ) {
    ... on UpdateOrderShippingMethodErrorPayload {
      errorMessage
    }
    ... on UpdateOrderShippingMethodSuccessPayload {
      updatedOrderId
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    UpdateShippingMethod: build.mutation<UpdateShippingMethodMutation, UpdateShippingMethodMutationVariables>({
      query: (variables) => ({ document: UpdateShippingMethodDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUpdateShippingMethodMutation } = injectedRtkApi;
