import { Routes } from 'constant';
import { generateUrl } from './generateUrl';
import type { FilterRecentOrderStatus } from 'shared/api';

export type RecentOrdersQueryParams = Partial<{
  search: string;
  id: string;
  fromDate: string;
  manufacturers: string;
  status: FilterRecentOrderStatus;
  suppliers: string;
  toDate: string;
}>;

export const getRecentOrdersUrl = (params: RecentOrdersQueryParams = {}) =>
  generateUrl(Routes.RECENT_ORDERS, {}, params);
