import { Typography, Button, Box, ButtonGroup } from '@partstech/ui';
import { HookForm, FormPhoneField, FormSelectBox, FormTextField } from '@partstech/ui/forms';
import { UserPhoneType } from 'shared/api';
import { email as emailValidator, isSame, required } from 'shared/lib/form';
import { useEditProfileForm } from './useEditProfileForm';
import type { UpdateUserInformationInput } from 'shared/api';

const isSamePhone = (value: string) =>
  isSame(value, 'This is the same as the shop phone number, please enter your personal phone number instead.');

type Props = {
  defaultValues?: UpdateUserInformationInput;
  phoneShop?: string;
  onSubmit: (newEmail: string | null) => void;
  onCancel?: () => void;
};

export const EditProfileForm = ({ defaultValues, phoneShop, onSubmit, onCancel }: Props) => {
  const { form, isDisabled, isLoading, submitForm } = useEditProfileForm({ defaultValues, onSubmit });

  return (
    <HookForm form={form} onSubmit={submitForm} data-testid="detailsForm">
      <Typography>Updates will be applied to your profile across all PartsTech apps.</Typography>

      <Box mt={3}>
        <FormTextField name="firstName" label="First name" rules={{ required }} />
      </Box>

      <Box mt={3}>
        <FormTextField name="lastName" label="Last name" rules={{ required }} />
      </Box>

      <Box mt={3}>
        <FormTextField
          name="email"
          label="Email address"
          rules={{ required, pattern: emailValidator }}
          normalize={(value) => value.trim()}
        />
      </Box>

      <Box mt={3} display="flex" justifyContent="space-between" gap={2}>
        <Box width={50}>
          <FormPhoneField name="phone" label="Alternate phone" rules={{ validate: isSamePhone(phoneShop ?? '') }} />
        </Box>

        <Box width={35}>
          <FormSelectBox
            name="phoneType"
            label="Phone type"
            options={[
              { text: 'Mobile', value: UserPhoneType.Mobile },
              { text: 'Direct', value: UserPhoneType.Direct },
            ]}
          />
        </Box>
      </Box>

      <ButtonGroup mt={6} gap={3} flexDirection="column">
        <Button type="submit" fullWidth isLoading={isLoading} disabled={isDisabled} data-testid="submit">
          Save changes
        </Button>

        {onCancel && (
          <Button variant="text" fullWidth onClick={onCancel} data-testid="cancelButton">
            Cancel
          </Button>
        )}
      </ButtonGroup>
    </HookForm>
  );
};
