/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import type * as Types from '../../../shared/api/graphql/graphql';

export type TiresFragment = {
  __typename?: 'Vehicle';
  tires?: Array<{
    __typename?: 'VehicleTire';
    id: string;
    name: Types.Scalars['String']['input'];
    size: {
      __typename?: 'TireSize';
      width: Types.Scalars['Float']['input'];
      height: Types.Scalars['Float']['input'];
      rim: Types.Scalars['Float']['input'];
      speedRating?: Types.Scalars['String']['input'] | null;
      loadIndex?: number | null;
    };
    rearSize?: {
      __typename?: 'TireSize';
      width: Types.Scalars['Float']['input'];
      height: Types.Scalars['Float']['input'];
      rim: Types.Scalars['Float']['input'];
      speedRating?: Types.Scalars['String']['input'] | null;
      loadIndex?: number | null;
    } | null;
  }> | null;
};

export const TiresFragmentDoc = `
    fragment Tires on Vehicle {
  tires {
    id
    name
    size {
      width
      height
      rim
      speedRating
      loadIndex
    }
    rearSize {
      width
      height
      rim
      speedRating
      loadIndex
    }
  }
}
    `;
