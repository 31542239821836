import type { RecentSearchInterface } from './RecentSearchInterface';
import type { Vehicle } from 'models';
import type { SearchValues } from 'types/search';

export type AbstractParams = {
  plate?: string | null;
  state?: string | null;
  vin?: string | null;
};

export abstract class RecentSearchAbstract implements RecentSearchInterface {
  abstract type: string;

  id: string;

  plate: string | null;

  state: string | null;

  vehicle: Vehicle | null;

  vin: string | null;

  protected constructor(id: string, vehicle: Vehicle | null, params: AbstractParams) {
    this.id = id;
    this.vehicle = vehicle;
    this.plate = params.plate ?? null;
    this.state = params.state ?? null;
    this.vin = params.vin ?? null;
  }

  abstract toSearchValues(): SearchValues;
}
