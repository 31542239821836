import { useLocalStorage } from '@partstech/ui/hooks';
import { useCallback } from 'react';
import { LocalStorageKeys } from 'constant';

export const useSkipSubscriptionReminder = () => {
  const [isSkipped = false, setHiddenSubscriptionReminder] = useLocalStorage<boolean>(
    LocalStorageKeys.IS_SUBSCRIPTION_REMINDER_SKIPPED
  );

  const skip = useCallback(() => {
    setHiddenSubscriptionReminder(true);
  }, [setHiddenSubscriptionReminder]);

  return {
    isSkipped,
    skip,
  };
};
