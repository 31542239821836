import completeLogo from 'images/content/subscriptions/complete-logo.svg';
import freeLogo from 'images/content/subscriptions/free-logo.svg';
import plusLogo from 'images/content/subscriptions/plus-logo.svg';
import { freeFeaturesList, paidFeatures } from './features';
import { ProductAlias } from './products';
import type { Plan } from '../types';

const { analytics, jobs, preferredBrands, retailPricing, support, tireComparison, tireSuppliers, userPermissions } =
  paidFeatures;

export const PLAN_FREE: Plan = {
  alias: ProductAlias.Free,
  canDowngrade: false,
  color: 'bronze',
  description: 'Quick and Easy Parts Ordering',
  features: [
    { ...tireSuppliers, isEnabled: false, partialEnabled: '25+ included for free' },
    ...freeFeaturesList,
    { ...jobs, isEnabled: false, partialEnabled: 'Only Brake-Front jobs' },
    { ...preferredBrands, isEnabled: false, partialEnabled: 'Only Brake-Front parts' },
    { ...tireComparison, isEnabled: false },
    { ...retailPricing, isEnabled: false },
    { ...analytics, isEnabled: false },
    { ...userPermissions, isEnabled: false },
    { ...support, isEnabled: false },
  ],
  id: '',
  isActive: false,
  isDowngrading: false,
  isExpires: false,
  isYearly: false,
  logo: freeLogo,
  name: 'PartsTech Free',
  price: 0,
  priceMonthly: 0,
  priceYearly: 0,
};

const PLAN_PLUS: Plan = {
  alias: ProductAlias.Plus,
  canDowngrade: false,
  color: 'mono40',
  description: 'Tires, Jobs & Retail pricing',
  features: [
    tireSuppliers,
    ...freeFeaturesList,
    jobs,
    { ...preferredBrands, isEnabled: false, partialEnabled: 'Only Brake-Front parts' },
    tireComparison,
    retailPricing,
    { ...analytics, isEnabled: false, partialEnabled: 'Only Basic reporting' },
    { ...userPermissions, isEnabled: false },
    { ...support, isEnabled: false },
  ],
  id: '',
  isActive: false,
  isDowngrading: false,
  isExpires: false,
  isYearly: false,
  logo: plusLogo,
  name: 'PartsTech Plus',
  price: 50,
  priceMonthly: 50,
  priceYearly: 540,
};

export const PLAN_COMPLETE: Plan = {
  alias: ProductAlias.Complete,
  canDowngrade: false,
  color: 'secondary',
  description: 'Advanced Analytics, User Permissions, VIP Support',
  features: [
    tireSuppliers,
    ...freeFeaturesList,
    jobs,
    preferredBrands,
    tireComparison,
    retailPricing,
    analytics,
    userPermissions,
    support,
  ],
  id: '',
  isActive: false,
  isDowngrading: false,
  isExpires: false,
  isYearly: false,
  logo: completeLogo,
  name: 'PartsTech Complete',
  price: 95,
  priceMonthly: 95,
  priceYearly: 1020,
};

export const PLANS: Plan[] = [PLAN_FREE, PLAN_PLUS, PLAN_COMPLETE];
