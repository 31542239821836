import { useMutationStatus } from 'shared/api';
import { api as generatedApi } from './CreateCart.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    CreateCart: {
      invalidatesTags: ['ActiveCart'],
    },
  },
});

const { useCreateCartMutation } = api;

export const useCreateCart = () => {
  const [createCart, status] = useCreateCartMutation();

  useMutationStatus({ status });

  return [createCart, status] as const;
};
