import { uniqueId } from '@partstech/ui/utils';
import { useCallback, useMemo, useState } from 'react';
import { StockOrdersContextProvider } from './StockOrdersContext';
import type { InitialStockOrder, StockOrdersContextValue } from './StockOrdersContext';
import type { AvailabilityLine, SupplierAccount } from 'models';
import type { PropsWithChildren } from 'react';

const useStockOrderState = () => {
  const [initialStockOrders, setInitialStockOrders] = useState<InitialStockOrder[]>([]);
  const [isSplittedByLastPurchases, setIsSplittedByLastPurchases] = useState<boolean>(false);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [templateId, setTemplateId] = useState<string | null>(null);

  const initStockOrders = useCallback(
    (payload: { stockOrders: InitialStockOrder[]; splittedByLastPurchases: boolean }) => {
      setInitialStockOrders(payload.stockOrders);
      setIsSplittedByLastPurchases(payload.splittedByLastPurchases);
      setIsInitialized(true);
    },
    []
  );

  const addStockOrder = useCallback((supplierAccount: SupplierAccount) => {
    setInitialStockOrders((state) => [...state, { id: uniqueId(), supplierAccount, lines: [], typeahead: [] }]);
  }, []);

  const removeStockOrder = useCallback((stockOrderId: string) => {
    setInitialStockOrders((state) => state.filter((order) => order.id !== stockOrderId));
  }, []);

  const updateStockOrder = useCallback(
    (stockOrderId: string, updater: (order: InitialStockOrder) => InitialStockOrder) => {
      setInitialStockOrders((state) => state.map((order) => (order.id === stockOrderId ? updater(order) : order)));
    },
    []
  );

  const addStockOrderLine = useCallback(
    (payload: { searchQuery: string; stockOrderId: string; brandName?: string }) => {
      updateStockOrder(payload.stockOrderId, (order) => ({
        ...order,
        lines: [
          ...order.lines,
          {
            quantity: 0,
            searchQuery: payload.searchQuery,
            productIds: [],
            selectedIndex: null,
            lineCardId: null,
            brandName: payload.brandName,
          },
        ],
      }));
    },
    [updateStockOrder]
  );

  const removeStockOrderLine = useCallback(
    (payload: { index: number; stockOrderId: string }) => {
      updateStockOrder(payload.stockOrderId, (order) => ({
        ...order,
        lines: order.lines.filter((_, index) => index !== payload.index),
      }));
    },
    [updateStockOrder]
  );

  const removeStockOrderLines = useCallback(
    (payload: { indexesToRemove: number[]; stockOrderId: string }) => {
      updateStockOrder(payload.stockOrderId, (order) => ({
        ...order,
        lines: order.lines.filter((_, index) => !payload.indexesToRemove.includes(index)),
      }));
    },
    [updateStockOrder]
  );

  const updateLine = useCallback(
    (payload: { stockOrderId: string; index: number; changes: Partial<InitialStockOrder['lines'][number]> }) => {
      updateStockOrder(payload.stockOrderId, (order) => ({
        ...order,
        lines: order.lines.map((line, idx) => (idx === payload.index ? { ...line, ...payload.changes } : line)),
      }));
    },
    [updateStockOrder]
  );

  const changeSupplier = useCallback(
    (payload: { stockOrderId: string; supplierAccount: SupplierAccount }) => {
      updateStockOrder(payload.stockOrderId, (order) => ({
        ...order,
        supplierAccount: payload.supplierAccount,
      }));
    },
    [updateStockOrder]
  );

  const setTemplate = useCallback((id: string | null, stockOrders?: InitialStockOrder[]) => {
    if (id === null || !stockOrders) {
      setTemplateId(null);

      return;
    }

    setTemplateId(id);

    setInitialStockOrders(stockOrders);

    setIsSplittedByLastPurchases(false);

    setIsInitialized(false);
  }, []);

  return {
    addStockOrder,
    addStockOrderLine,
    changeSupplier,
    initialStockOrders,
    initStockOrders,
    isInitialized,
    isSplittedByLastPurchases,
    removeStockOrder,
    removeStockOrderLine,
    removeStockOrderLines,
    setTemplate,
    templateId,
    updateLine,
  };
};

export const StockOrdersProvider = ({ children }: PropsWithChildren) => {
  const {
    addStockOrder,
    addStockOrderLine,
    changeSupplier,
    initialStockOrders,
    initStockOrders,
    isInitialized,
    isSplittedByLastPurchases,
    removeStockOrder,
    removeStockOrderLine,
    removeStockOrderLines,
    setTemplate,
    templateId,
    updateLine,
  } = useStockOrderState();

  const changeStockOrderLineQuantity = useCallback(
    (payload: { stockOrderId: string; value: number; index: number }) => {
      updateLine({ stockOrderId: payload.stockOrderId, index: payload.index, changes: { quantity: payload.value } });
    },
    [updateLine]
  );

  const changeStockOrderLineStore = useCallback(
    (payload: { stockOrderId: string; value: AvailabilityLine; index: number }) => {
      updateLine({
        stockOrderId: payload.stockOrderId,
        index: payload.index,
        changes: { selectedStore: payload.value },
      });
    },
    [updateLine]
  );

  const setStockOrderLineError = useCallback(
    (payload: { stockOrderId: string; index: number; value: string }) => {
      updateLine({ stockOrderId: payload.stockOrderId, index: payload.index, changes: { error: payload.value } });
    },
    [updateLine]
  );

  const changeStockOrderLineProduct = useCallback(
    (payload: { stockOrderId: string; selectedIndex: number; index: number }) => {
      updateLine({
        stockOrderId: payload.stockOrderId,
        index: payload.index,
        changes: { selectedIndex: payload.selectedIndex },
      });
    },
    [updateLine]
  );

  const value: StockOrdersContextValue = useMemo(
    () => ({
      addStockOrder,
      addStockOrderLine,
      changeStockOrderLineProduct,
      changeStockOrderLineQuantity,
      changeStockOrderLineStore,
      changeSupplier,
      initialStockOrders,
      initStockOrders,
      isInitialized,
      isSplittedByLastPurchases,
      removeStockOrder,
      removeStockOrderLine,
      removeStockOrderLines,
      setStockOrderLineError,
      setTemplate,
      templateId,
    }),
    [
      addStockOrder,
      addStockOrderLine,
      changeStockOrderLineProduct,
      changeStockOrderLineQuantity,
      changeStockOrderLineStore,
      changeSupplier,
      initialStockOrders,
      initStockOrders,
      isInitialized,
      isSplittedByLastPurchases,
      removeStockOrder,
      removeStockOrderLine,
      removeStockOrderLines,
      setStockOrderLineError,
      setTemplate,
      templateId,
    ]
  );

  return <StockOrdersContextProvider value={value}>{children}</StockOrdersContextProvider>;
};
