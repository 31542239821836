import { Box, Typography } from '@partstech/ui';
import { CredentialsWrapper } from '../../CredentialsWrapper';
import { TireConnectBanner } from '../../TireConnectBanner';
import { FormActions } from './FormActions';
import type { SupplierAccount } from 'models';

type Props = {
  account: SupplierAccount;
  onSubmit: () => void;
  onCancel: () => void;
};

export const WithoutCredentials = ({ account, onSubmit, onCancel }: Props) => {
  const { store, supplier } = account;

  if (!store || !supplier) {
    return null;
  }

  return (
    <CredentialsWrapper
      store={store}
      actions={<FormActions onSubmit={onSubmit} onCancel={onCancel} />}
      height={{ sm: '100%', md: 'auto' }}
      flex={{ sm: '1', md: 'initial' }}
      display="flex"
      flexDirection="column"
      justifyContent={{ sm: 'space-between', md: 'flex-start' }}
    >
      <Box mb={4}>
        <Typography>There are no user credentials or passwords for this supplier.</Typography>
      </Box>

      {supplier.isTire && <TireConnectBanner supplier={supplier} />}
    </CredentialsWrapper>
  );
};
