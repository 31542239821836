import { useState, useEffect, useCallback, useRef } from 'react';

const pixelsPerDegree = 2;
const rotationInterval = 100;

export const useImages360Navigation = (images: string[]) => {
  const [isDragging, setIsDragging] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);

  const dragStartIndexRef = useRef(0);
  const lastUpdateTimeRef = useRef(Date.now());

  const imagesCount = images.length;
  const pixelsPerImage = pixelsPerDegree * (360 / imagesCount);

  const handleMouseDown = useCallback(() => {
    setIsDragging(true);
    dragStartIndexRef.current = imageIndex;
  }, [imageIndex]);

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
  }, []);

  const updateImageIndex = useCallback(
    (currentPosition: number) => {
      const pixelsMoved = (currentPosition - dragStartIndexRef.current) / pixelsPerImage;

      let currentIndex = Math.floor(pixelsMoved) % imagesCount;

      if (currentIndex < 0) {
        currentIndex = imagesCount + currentIndex - 1;
      }

      currentIndex = (currentIndex + dragStartIndexRef.current) % imagesCount;

      if (currentIndex !== imageIndex) {
        setImageIndex(currentIndex);
      }
    },
    [imageIndex, imagesCount, pixelsPerImage]
  );

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (isDragging) {
        updateImageIndex(e.screenX);
      }
    },
    [isDragging, updateImageIndex]
  );

  const handleTouchMove = useCallback(
    (e: TouchEvent) => {
      if (isDragging) {
        updateImageIndex(e.touches[0]?.screenX ?? 0);
      }
    },
    [isDragging, updateImageIndex]
  );

  const animate = useCallback(() => {
    if (dragStartIndexRef.current > 0) {
      return;
    }

    const now = Date.now();

    if (now - lastUpdateTimeRef.current >= rotationInterval) {
      setImageIndex((prevIndex) => (prevIndex + 1) % imagesCount);
      lastUpdateTimeRef.current = now;
    }

    requestAnimationFrame(animate);
  }, [imagesCount]);

  useEffect(() => {
    const animationFrame = requestAnimationFrame(animate);

    return () => cancelAnimationFrame(animationFrame);
  }, [imagesCount, animate]);

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('touchmove', handleTouchMove);
    document.addEventListener('touchend', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleMouseUp);
    };
  }, [handleMouseMove, handleMouseUp, handleTouchMove]);

  return { currentIndex: imageIndex, isDragging, onMouseDown: handleMouseDown };
};
