import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { emptyLDClient } from '../constant';

export const useLaunchDarklySessionClose = () => {
  const { close } = useLDClient() ?? emptyLDClient;

  useEffect(() => {
    if (!close) {
      return () => {};
    }

    return () => {
      close();
    };
  }, [close]);
};
