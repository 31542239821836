import { groupBy, isNotNull, keys } from '@partstech/ui/utils';
import type { Diagram as DiagramType } from 'shared/api';
import type { Diagram } from 'types/diagrams';

const formatName = (diagram: DiagramType, index: number, groupLength: number): string => {
  const name = diagram.name ? diagram.name : `${diagram.brand?.name} Diagram`;
  const count = groupLength > 1 ? `${index + 1}` : '';

  return `${name} ${count}`.trim();
};

const addIndexToName = (diagram: DiagramType, index: number, array: DiagramType[]): DiagramType => ({
  ...diagram,
  name: formatName(diagram, index, array.length),
});

export const formatDiagrams = (diagrams: DiagramType[]): Diagram[] => {
  const categories = groupBy(diagrams, (category) => category.category?.id);

  return Object.values(categories)
    .map((diagramsOfCategory) => {
      const byName = groupBy(diagramsOfCategory, (diagram) => diagram.name || diagram.brand?.name);

      return keys(byName)
        .map((key) => byName[key]?.map(addIndexToName))
        .filter(isNotNull)
        .flat();
    })
    .flat();
};
