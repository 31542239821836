import { useLaunchDarkly } from 'integrations/launchDarkly';
import { useSearchParams } from './useSearchParams';

export const useShouldShowVehicleFitment = (): boolean => {
  const {
    flags: { fluidsChemicalsTab },
  } = useLaunchDarkly();

  const { searchParams, partType } = useSearchParams();

  if (fluidsChemicalsTab) {
    return false;
  }

  return Boolean(partType?.isUniversalFitted && searchParams.vehicle);
};
