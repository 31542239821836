/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type UpdateLaborRateInCartMutationVariables = Types.Exact<{
  laborRate: Types.Scalars['BigDecimal']['input'];
}>;

export type UpdateLaborRateInCartMutation = {
  __typename?: 'Mutation';
  updateActiveCartLaborRate?:
    | { __typename?: 'UpdateActiveCartLaborRateErrorPayload'; errorMessage: Types.Scalars['String']['input'] }
    | {
        __typename?: 'UpdateActiveCartLaborRateSuccessPayload';
        cart?: { __typename?: 'Cart'; laborRate?: Types.Scalars['Float']['input'] | null } | null;
      }
    | null;
};

export const UpdateLaborRateInCartDocument = `
    mutation UpdateLaborRateInCart($laborRate: BigDecimal!) {
  updateActiveCartLaborRate(input: {laborRate: $laborRate}) {
    ... on UpdateActiveCartLaborRateErrorPayload {
      errorMessage
    }
    ... on UpdateActiveCartLaborRateSuccessPayload {
      cart {
        laborRate
      }
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    UpdateLaborRateInCart: build.mutation<UpdateLaborRateInCartMutation, UpdateLaborRateInCartMutationVariables>({
      query: (variables) => ({ document: UpdateLaborRateInCartDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUpdateLaborRateInCartMutation } = injectedRtkApi;
