/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type CreateStockOrderTemplateMutationVariables = Types.Exact<{
  input: Types.CreateStockOrderTemplateInput;
}>;

export type CreateStockOrderTemplateMutation = {
  __typename?: 'Mutation';
  createStockOrderTemplate?: {
    __typename?: 'CreateStockOrderTemplatePayload';
    template?: { __typename?: 'StockOrderTemplate'; id: string } | null;
  } | null;
};

export const CreateStockOrderTemplateDocument = `
    mutation CreateStockOrderTemplate($input: CreateStockOrderTemplateInput!) {
  createStockOrderTemplate(input: $input) {
    template {
      id
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    CreateStockOrderTemplate: build.mutation<
      CreateStockOrderTemplateMutation,
      CreateStockOrderTemplateMutationVariables
    >({
      query: (variables) => ({ document: CreateStockOrderTemplateDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useCreateStockOrderTemplateMutation } = injectedRtkApi;
