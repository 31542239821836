import { createAvailability } from './createAvailability';
import type { OrderItemProductAvailabilityLine, ProductAvailabilityLine } from 'shared/api';
import type { AvailabilityLineVariant, AvailabilityLine as AvailabilityLineType } from 'types/product';

export const createAvailabilityLineFromQuery = (
  line: ProductAvailabilityLine | OrderItemProductAvailabilityLine,
  storeId?: string,
  accountNickname?: string | null,
  variant?: AvailabilityLineVariant
) => {
  const availabilityLineData: AvailabilityLineType = {
    ...line,
    address: line.address ?? null,
    group: line.group ?? null,
    id: line.id ?? null,
    qty: { type: 'EQ', value: line.quantity },
  };

  const availabilityLine = createAvailability(availabilityLineData, variant);

  if (availabilityLine.id === Number(storeId)) {
    availabilityLine.setNickname(accountNickname);
  }

  return availabilityLine;
};
