import { isNotNull } from '@partstech/ui/utils';
import { useMemo } from 'react';
import { VehicleSuggestion } from 'models';
import { useGetSearchVehiclesByNameQuery } from './GetSearchVehiclesByName.generated';
import type { VehicleSuggest } from 'shared/api';

const convertQueryToVehicleSuggestion = (suggestion: VehicleSuggest | null | undefined): VehicleSuggestion | null =>
  suggestion ? new VehicleSuggestion(suggestion) : null;

const emptySuggestions: VehicleSuggestion[] = [];

export const useSearchVehiclesByName = (name: string) => {
  const { currentData, isFetching, isSuccess } = useGetSearchVehiclesByNameQuery(
    { search: name },
    { skip: name.length === 0 }
  );

  const vehicleSuggestions = useMemo(() => {
    if (name.length === 0) {
      return emptySuggestions;
    }

    return currentData?.vehicleSuggest
      ? currentData.vehicleSuggest.map(convertQueryToVehicleSuggestion).filter(isNotNull)
      : emptySuggestions;
  }, [currentData?.vehicleSuggest, name.length]);

  return { vehicleSuggestions, isSearchLoaded: isSuccess && !isFetching };
};
