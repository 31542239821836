import { api as generatedApi } from './CreateLaborRate.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    CreateLaborRate: {
      invalidatesTags: ['LaborRate'],
    },
  },
});

export const { useCreateLaborRateMutation } = api;
