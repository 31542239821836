import { uniq, isNotNull } from '@partstech/ui/utils';
import { findAttributeByName } from '../../product/attributes/findAttributeByName';
import { getEmptyAttributeValueByName } from './getEmptyAttributeValueByName';
import type { Product } from 'models';
import type { RawAttribute } from 'shared/api/rest/gen/shop';

export const getAttributeValuesFromProduct = (product: Product, attributeName: RawAttribute['name']): string[] => {
  const emptyAttribute: RawAttribute = {
    name: attributeName,
    value: [getEmptyAttributeValueByName(attributeName)],
  };

  const variationAttributeValues = product.variations
    .map((variation) => findAttributeByName(attributeName, variation.attributes))
    .filter(isNotNull)
    .map((attr) => attr.value)
    .flat();

  const productAttribute = findAttributeByName(attributeName, product.attributes);
  const attribute = !productAttribute && variationAttributeValues.length === 0 ? emptyAttribute : productAttribute;

  return uniq([...(attribute?.value ?? []), ...variationAttributeValues]);
};
