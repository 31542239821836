import { css } from '@emotion/react';
import { Box, ImageFallback, Typography } from '@partstech/ui';

const styles = {
  image: css`
    object-fit: contain;
  `,
};

type Props = {
  image: string;
  title: string;
  partNumber: string;
};

export const ProductTitle = ({ image, title, partNumber }: Props) => (
  <Box display="flex">
    <ImageFallback css={styles.image} width={40} height={40} src={image} alt={title} />

    <Box px={4}>
      <Typography color="dark">{title}</Typography>

      <Typography color="dark" variant="subtitle1" bold>
        {partNumber}
      </Typography>
    </Box>
  </Box>
);
