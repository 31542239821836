import { Availability } from 'types/search';
import { createOption } from './createOption';
import type { Product } from 'models';
import type { FilterOption } from 'types/search';

const AvailabilityNames: Record<Availability, string> = {
  [Availability.IN_STOCK]: 'In Stock',
  [Availability.OUT_OF_STOCK]: 'Out of Stock',
  [Availability.SPECIAL_ORDER]: 'Special Order',
  [Availability.BACK_ORDER]: 'Backorder',
};

export const createAvailabilityOption =
  (count: number = 1) =>
  (product: Product): FilterOption => {
    if (product.quote?.isOutOfStock()) {
      return createOption(Availability.OUT_OF_STOCK, AvailabilityNames[Availability.OUT_OF_STOCK], count);
    }

    if (product.quote?.isSpecialOrder()) {
      return createOption(Availability.SPECIAL_ORDER, AvailabilityNames[Availability.SPECIAL_ORDER], count);
    }

    if (product.quote?.isBackOrder()) {
      return createOption(Availability.BACK_ORDER, AvailabilityNames[Availability.BACK_ORDER], count);
    }

    if (product.quote?.isInStock()) {
      return createOption(Availability.IN_STOCK, AvailabilityNames[Availability.IN_STOCK], count);
    }

    return createOption('', '');
  };
