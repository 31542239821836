import { Box, Scrollbar, useMedia } from '@partstech/ui';
import { useCallback, useState } from 'react';
import { OptionsByColumns } from 'components/OptionsByColumns';
import { DynamicAttributeConfigurator } from 'features/dynamicAttribute';
import type { UniversalPartType, Vehicle } from 'models';

type Props = {
  attributeBasedSearch?: boolean;
  defaultPartType?: UniversalPartType;
  onSelectVehicleClick?: () => void;
  options: UniversalPartType[];
  onChange: (searchEntry: UniversalPartType) => void;
  vehicle: Vehicle | null;
};

export const FluidsAndChemicals = ({
  attributeBasedSearch = false,
  defaultPartType,
  onSelectVehicleClick,
  options,
  onChange,
  vehicle,
}: Props) => {
  const { isMobile } = useMedia();

  const [selectedPartType, setSelectedPartType] = useState<UniversalPartType | null>(defaultPartType ?? null);

  const handleSelectPartType = useCallback(
    (partType: UniversalPartType) => {
      if (partType.shouldPickAttributes(Boolean(vehicle))) {
        setSelectedPartType(partType);

        return;
      }

      onChange(partType);
    },
    [vehicle, onChange]
  );

  const handleReset = useCallback(() => {
    setSelectedPartType(null);
  }, []);

  const handleSelectVehicleClick = useCallback(() => {
    onSelectVehicleClick?.();
  }, [onSelectVehicleClick]);

  return (
    <Box width="100%">
      {selectedPartType?.hasAttributes() ? (
        <DynamicAttributeConfigurator
          attributeBasedSearch={attributeBasedSearch}
          partType={selectedPartType}
          onSelectVehicleClick={handleSelectVehicleClick}
          onChange={onChange}
          onReset={handleReset}
          vehicle={vehicle}
        />
      ) : (
        <Scrollbar>
          <OptionsByColumns maxColumns={isMobile ? 1 : 2} options={options} onChange={handleSelectPartType} />
        </Scrollbar>
      )}
    </Box>
  );
};
