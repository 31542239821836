import { useMutationStatus } from 'shared/api';
import { api as savedCartApi } from '../../queries/GetSavedCart.generated';
import { api as generatedApi } from './LinkVehicleToCart.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    LinkVehicleToCart: {
      onQueryStarted: async (params, { dispatch, queryFulfilled }) => {
        try {
          const res = await queryFulfilled;

          dispatch(
            savedCartApi.util.updateQueryData('GetSavedCart', { id: params.input.cartId }, (draft) => {
              const { cart } = draft;

              if (cart && res.data.linkVehicleToCart?.linkedVehicle) {
                cart.vehicles = [res.data.linkVehicleToCart.linkedVehicle];
              }
            })
          );
        } catch {
          /* empty */
        }
      },
    },
  },
});

const { useLinkVehicleToCartMutation } = api;

export const useLinkVehicleToCart = () => {
  const [linkVehicleToCart, status] = useLinkVehicleToCartMutation();

  useMutationStatus({ status });

  return [linkVehicleToCart, status] as const;
};
