import { PackageItemType } from 'shared/api';
import type { QuotePricePackage } from 'store/features/quote';
import type { AddonPriceLine, DiscountPriceLine, FeePriceLine, PricePackage } from 'types/pricePackage';

export const MAX_END_PRICE_MATRIX = 2147483647;
export const MAX_PERCENTAGE = 999;
export const MAX_TITLE_LENGTH = 255;
export const MAX_DESCRIPTION_LENGTH = 1000;

export const DEFAULT_QUOTE_QUANTITY = 4;

export const CALCULATED_BY_VALUES: {
  [PackageItemType.Addon]: AddonPriceLine['calculatedBy'];
  [PackageItemType.Discount]: DiscountPriceLine['calculatedBy'];
  [PackageItemType.Fee]: FeePriceLine['calculatedBy'];
} = {
  [PackageItemType.Addon]: ['TIRES', 'LABOR'],
  [PackageItemType.Discount]: ['TIRES', 'LABOR', 'DISPOSAL', 'ADDON', 'FEE'],
  [PackageItemType.Fee]: ['TIRES', 'LABOR', 'ADDON'],
};

export const defaultPricePackage: PricePackage = {
  name: 'Default',
  isDefault: true,
  items: [],
};

export const defaultQuotePricePackage: QuotePricePackage = {
  id: null,
  name: 'Default',
  items: [],
  isDefault: true,
};
