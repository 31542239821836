import { css } from '@emotion/react';
import { Icon, Field, Tooltip, useMedia } from '@partstech/ui';
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import { useCallback } from 'react';
import { FieldAdornment } from './FieldAdornment';
import { useFormCardField } from './useFormCardField';
import type { CardFormData } from './useFormCardField';
import type { TextFieldProps } from '@partstech/ui';
import type { RegisterOptions } from '@partstech/ui/forms';
import type { StripeElementChangeEvent, StripeElementStyle } from '@stripe/stripe-js';

type Props = Pick<TextFieldProps, 'label' | 'name' | 'required' | 'fsExclude'> & {
  name: keyof CardFormData;
  type: 'cardNumber' | 'expire' | 'cvc';
  onChange?: (e: StripeElementChangeEvent) => void;
  rules?: RegisterOptions;
};

const stripeStyles: StripeElementStyle = {
  base: {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '14px',
    lineHeight: '40px',
    fontWeight: '400',
    color: '#313131',
    '::placeholder': {
      color: '#969696',
    },
  },
};

const styles = {
  fieldBody: css`
    .Field-content {
      display: block;
    }
  `,
};

const components = {
  cardNumber: CardNumberElement,
  expire: CardExpiryElement,
  cvc: CardCvcElement,
};

export const FormCardField = ({ onChange, label, name, type, rules, ...props }: Props) => {
  const { isMobile } = useMedia();

  const { field, error, isSubmitting } = useFormCardField({ name, type });

  const handleChange = useCallback(
    (e: StripeElementChangeEvent) => {
      if (onChange) {
        onChange(e);
      }

      field.onChange(e);
    },
    [field, onChange]
  );

  const Component = components[type];

  return (
    <Field name={name}>
      <Field.Body
        {...props}
        label={label}
        adornmentAfter={
          type === 'cvc' && (
            <Tooltip
              verticalAlign={isMobile ? 'end' : 'center'}
              tooltip="Three digit number on the back of your credit card"
              fullWidth
            >
              <Icon color="subtleText" name="help" />
            </Tooltip>
          )
        }
        adornmentBefore={<FieldAdornment type={type} />}
        css={styles.fieldBody}
        hideRequiredMark
        required
        fsExclude
      >
        <Component options={{ disabled: isSubmitting, placeholder: '', style: stripeStyles }} onChange={handleChange} />
      </Field.Body>
      <Field.Status error={error} />
    </Field>
  );
};
