import { TextCut, Typography } from '@partstech/ui';
import { useMemo } from 'react';
import { AddressStringFormat } from './AddressStringFormat';
import type { Address as AddressType } from '../../model/Address';
import type { TypographyProps } from '@partstech/ui';

type Props = {
  address: AddressType;
  format?: AddressStringFormat;
  linesCount?: number;
} & TypographyProps;

export const AddressString = ({ address, format = AddressStringFormat.MULTILINE, linesCount, ...props }: Props) => {
  const formattedAddress = useMemo(() => {
    if (format === AddressStringFormat.SHORT) {
      return address.shortAddress;
    }

    if (format === AddressStringFormat.ONELINE) {
      return `${address}`;
    }

    return (
      <>
        {address.address1}
        {address.address2 && `, ${address.address2}`} <br />
        {address.shortAddress}
      </>
    );
  }, [address, format]);

  return (
    <Typography {...props} component="span">
      <TextCut linesCount={linesCount}>{formattedAddress}</TextCut>
    </Typography>
  );
};
