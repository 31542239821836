import { useModalHistory, useSnackbar } from '@partstech/ui';
import { useCallback } from 'react';
import { useMutationStatus } from 'shared/api';
import { useRemovePaymentCardMutation } from '../../../api/removeCard';
import type { PaymentCardModalData, PaymentCardModalPaths } from '../router';

export const useDeleteCard = () => {
  const { data, state, onSubmit, onClose } = useModalHistory<
    PaymentCardModalPaths,
    'deleteCard',
    PaymentCardModalData
  >();

  const { card, children } = state ?? {};

  const { addSnackbar } = useSnackbar();

  const successRemoveCard = useCallback(() => {
    addSnackbar({ label: `Credit card ending in ${card?.name} was deleted` });

    onSubmit({ card: null, paymentMethod: data.paymentMethod });

    onClose();
  }, [addSnackbar, card?.name, data.paymentMethod, onClose, onSubmit]);

  const close = useCallback(() => {
    onSubmit(data);

    onClose();
  }, [data, onClose, onSubmit]);

  const [removePaymentCard, status] = useRemovePaymentCardMutation();

  const handleRemovePaymentCard = useCallback(async () => {
    if (!card?.id) {
      return;
    }

    await removePaymentCard({ input: { cardId: card.id } });
  }, [card?.id, removePaymentCard]);

  useMutationStatus({ status, onSuccess: successRemoveCard });

  return { card, isLoading: status.isLoading, children, removePaymentCard: handleRemovePaymentCard, close };
};
