import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useAppNavigate } from 'app/AppRouter/useAppNavigate';
import { useMyShopAvailablePages } from 'hooks/myShop';
import { usePermissions } from 'store/queries/currentUser/usePermissions';
import { getMyShopUrl } from 'utils/url';
import type { MyShopPageAlias, MyShopPageParams } from 'utils/url';

type PermissionMap = {
  hasLabor: boolean;
};

const getTabName = (alias: MyShopPageAlias, map: PermissionMap) => {
  const pages: Record<MyShopPageAlias, string> = {
    details: 'Details',
    users: 'Users',
    'cart-shipping': 'Cart & Shipping',
    suppliers: 'Suppliers',
    payments: 'Payments',
    'retail-pricing': map.hasLabor ? 'Retail pricing & Labor' : 'Retail pricing',
    'brand-preferences': 'Brand Preferences',
    rewards: 'Referrals & Rewards',
    subscription: 'My subscription',
  };

  return pages[alias];
};

export const useMyShopTabs = () => {
  const navigate = useAppNavigate();
  const { '*': pageAlias } = useParams<MyShopPageParams>();

  const { availablePages } = useMyShopAvailablePages();

  const {
    shop: { laborAllowed },
  } = usePermissions();

  const changeTab = useCallback(
    (tab: MyShopPageAlias) => {
      if (tab === pageAlias) {
        return;
      }

      navigate(getMyShopUrl(tab));
    },
    [pageAlias, navigate]
  );

  const tabs = useMemo(
    () =>
      availablePages.map((alias) => ({
        alias,
        title: getTabName(alias, { hasLabor: laborAllowed }),
      })),
    [availablePages, laborAllowed]
  );

  return {
    tabs,
    activeTab: (pageAlias?.split('/')[0] ?? 'details') as MyShopPageAlias,
    changeTab,
  };
};
