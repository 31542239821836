import { Box, Divider, Typography } from '@partstech/ui';

export const CatalogSupplier = () => (
  <Box mb={4}>
    <Divider mt={10} />

    <Box mt={4} mb={1}>
      <Typography variant="subtitle2">Don't have an account with this supplier?</Typography>
    </Box>

    <Typography variant="bodyText2">
      In order to connect this supplier to your PartsTech account please reach out to the supplier and set up a
      wholesale account with them.
    </Typography>
  </Box>
);
