import { Box, Icon, ImageFallback } from '@partstech/ui';
import { memo, useRef } from 'react';
import { useScrollIntoView } from 'shared/lib/react-dom';
import { styles } from '../FileViewerModal.styles';
import type { ProductFile } from 'types/product';

type Props = {
  file: ProductFile;
  index: number;
  isSelected: boolean;
  onClick: () => void;
};

export const ImageCell = memo(({ file, index, isSelected, onClick }: Props) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useScrollIntoView(ref, { block: 'center' }, { skip: !isSelected });

  return (
    <Box
      ref={ref}
      position="relative"
      width={17}
      height={16}
      p={isSelected ? 0.75 : 1}
      css={styles.carouselButton(isSelected)}
      data-testid="imageCell"
      className={isSelected ? 'active' : undefined}
      onClick={onClick}
    >
      {file.isVideo && <Icon name="videocam" color="secondary" css={styles.isVideo} />}

      <ImageFallback src={file.preview} alt={`preview #${index + 1}`} css={styles.image} />
    </Box>
  );
});
