import { Button, Link, ModalButtons, ModalView, Typography, useMedia } from '@partstech/ui';
import { getSubscriptionUrl } from 'utils/url';
import type { ModalProps } from '@partstech/ui';

type Props = {
  daysToCardExpire: number;
} & ModalProps;

export const CardExpireModal = ({ daysToCardExpire, onClose }: Props) => {
  const { isMobile } = useMedia();

  return (
    <ModalView onClose={onClose} title="Credit card about to expire">
      <Typography>
        Your credit card expires in{' '}
        <Typography bold component="span">
          {daysToCardExpire} {daysToCardExpire === 1 ? 'day' : 'days'}.
        </Typography>
      </Typography>

      <Typography>Update your payment information in order to keep getting the most out of PartsTech.</Typography>

      <ModalButtons>
        <Button as={<Link to={getSubscriptionUrl()} />} fullWidth={isMobile} onClick={onClose}>
          Update
        </Button>
      </ModalButtons>
    </ModalView>
  );
};
