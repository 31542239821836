/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type ChangeCartItemQuantityMutationVariables = Types.Exact<{
  itemId: Types.Scalars['ID']['input'];
  quantity: Types.Scalars['Int']['input'];
}>;

export type ChangeCartItemQuantityMutation = {
  __typename?: 'Mutation';
  updateActiveCartItemQuantity?:
    | { __typename?: 'UpdateActiveCartItemQuantityErrorPayload'; errorMessage: Types.Scalars['String']['input'] }
    | { __typename?: 'UpdateActiveCartItemQuantitySuccessPayload'; orderId: string; orderItemId: string }
    | null;
};

export const ChangeCartItemQuantityDocument = `
    mutation ChangeCartItemQuantity($itemId: ID!, $quantity: Int!) {
  updateActiveCartItemQuantity(input: {itemId: $itemId, quantity: $quantity}) {
    ... on UpdateActiveCartItemQuantityErrorPayload {
      errorMessage
    }
    ... on UpdateActiveCartItemQuantitySuccessPayload {
      orderId
      orderItemId
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    ChangeCartItemQuantity: build.mutation<ChangeCartItemQuantityMutation, ChangeCartItemQuantityMutationVariables>({
      query: (variables) => ({ document: ChangeCartItemQuantityDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useChangeCartItemQuantityMutation } = injectedRtkApi;
