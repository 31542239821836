/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import type * as Types from '../../../shared/api/graphql/graphql';

export type DiagramFragment = {
  __typename?: 'Diagram';
  id: string;
  url?: Types.Scalars['String']['input'] | null;
  name: Types.Scalars['String']['input'];
  brand?: { __typename?: 'Brand'; id: string; name: Types.Scalars['String']['input'] } | null;
  category?: { __typename?: 'PartCategory'; id: string; name: Types.Scalars['String']['input'] } | null;
};

export const DiagramFragmentDoc = `
    fragment Diagram on Diagram {
  id
  url
  name
  brand {
    id
    name
  }
  category {
    id
    name
  }
}
    `;
