import { useMemo } from 'react';
import { useGetManagementSystems } from 'features/shop/api/managementSystems';
import { useSignupQueryParams } from 'hooks/signup';
import type { EntityManagementSystem } from 'features/shop/api/managementSystems';

type UseSelectedSmsFromQuery = {
  selectedSms?: string;
  shopManagementSystem?: EntityManagementSystem;
  isFetching?: boolean;
};

export const useSelectedSmsFromQuery = (): UseSelectedSmsFromQuery => {
  const { selectedSms } = useSignupQueryParams();

  const { managementSystems, isFetching } = useGetManagementSystems();

  const shopManagementSystem = useMemo(
    () => managementSystems.find((sms) => sms.name === selectedSms),
    [managementSystems, selectedSms]
  );

  return useMemo(
    () => ({ selectedSms, shopManagementSystem, isFetching }),
    [isFetching, selectedSms, shopManagementSystem]
  );
};
