import { Box } from '@partstech/ui';
import { isNotNull } from '@partstech/ui/utils';
import { useCallback } from 'react';
import { useGetSupplierAccounts } from 'store/queries/currentUser/supplierAccounts/useGetSupplierAccounts';
import { credentialMatchingCheck } from 'utils';
import { CredentialFormField } from '../CredentialFormField';
import type { Store } from 'models';
import type { ConfigurationField } from 'types/supplier';

type Props = {
  store: Store;
  fields: ConfigurationField[];
};

export const Credentials = ({ store, fields }: Props) => {
  const { accounts } = useGetSupplierAccounts();

  const getCredentialValuesByInternalName = useCallback(
    (internalName: string) => {
      const supplierLocations = accounts.filter(
        (account) => account.supplier?.id === store?.supplier?.id && account.store?.id !== store.id
      );

      if (supplierLocations.length === 0) {
        return undefined;
      }

      return supplierLocations.map((location) => location.credentials[internalName]).filter(isNotNull);
    },
    [accounts, store.id, store?.supplier?.id]
  );

  if (fields.length === 0) {
    return null;
  }

  return fields?.map((credential) => (
    <Box key={credential.internalName} mb={{ sm: 4, md: 6 }}>
      <CredentialFormField
        type={credential.type}
        hint={credential.helper ?? undefined}
        label={credential.label}
        name={`credentials.${credential.internalName}`}
        validator={credentialMatchingCheck(getCredentialValuesByInternalName(credential.internalName))}
        validationRules={credential.validationRules}
        options={credential.options}
        link={credential.link ?? ''}
      />
    </Box>
  ));
};
