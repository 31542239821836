import type { Filter, FilterOptionType } from 'types/search';

type Props = {
  checked: boolean;
  type?: FilterOptionType;
  checkedValues: string[];
  changedValues: string[];
  changedFilter?: Filter;
};

export const getUpdatedCheckedValues = ({
  checked,
  type,
  checkedValues,
  changedValues,
  changedFilter,
}: Props): string[] => {
  if (type === 'radio') {
    return checked
      ? [
          ...checkedValues.filter(
            (checkedValue) =>
              !changedFilter?.options.some((option) => option.value === checkedValue && option.type === 'radio')
          ),
          ...changedValues,
        ]
      : [];
  }
  return checked ? [...checkedValues, ...changedValues] : checkedValues.filter((val) => !changedValues.includes(val));
};
