import { useCallback } from 'react';
import { Userpilot } from 'userpilot';
import type { ConnectSmsKey, SupportKey } from '../constant';

/**
 * useUserpilotTrigger
 *
 * A custom hook that returns a function to trigger a specific Userpilot event.
 * The function, when called, will invoke `Userpilot.trigger(TRIGGER_KEY)`.
 *
 * @returns {Function} A function that triggers the Userpilot event with the given key.
 *
 * @example
 * ```typescript
 * const triggerEvent = useUserpilotTrigger();
 *
 * // Trigger the event when a button is clicked
 * <button onClick={() => triggerEvent(SupportKey.Default)}>Trigger Event</button>;
 * ```
 */
export const useUserpilotTrigger = () =>
  useCallback((key: SupportKey | ConnectSmsKey) => {
    Userpilot.trigger(key);
  }, []);
