import { CHECK_STATUS_REQUEST_LIMIT } from 'constant';
import { setRequestCount } from 'store/entities/supplierAccounts';
import { endpoints } from 'store/queries/currentUser/supplierAccounts/useGetSupplierAccounts';
import { updateSupplierAccountAction } from 'utils';
import { api as generatedApi } from './UpdateAccountWithoutStore.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    UpdateSupplierAccountWithoutStore: {
      onQueryStarted: async ({ input }, { queryFulfilled, dispatch, getState }) => {
        const accountsQuery = endpoints.GetSupplierAccounts.select({})(getState()).data;
        const store =
          accountsQuery?.currentUser?.activeMember?.shop?.accounts?.find((entity) => entity.id === input.accountId)
            ?.store ?? null;

        const patchResult = dispatch(
          updateSupplierAccountAction(input.accountId, {
            additionalInformation: input.additionalInformation ?? undefined,
            credentials: input.credentials ?? undefined,
            nickname: input.nickname,
            store: null,
          })
        );

        try {
          const { data } = await queryFulfilled;

          const account = { ...data.updateShopAccount?.account, store };

          if (!account) {
            return;
          }

          dispatch(updateSupplierAccountAction(input.accountId, account));

          dispatch(setRequestCount(CHECK_STATUS_REQUEST_LIMIT));
        } catch (e) {
          patchResult.undo();
        }
      },
    },
  },
});

export const { useUpdateSupplierAccountWithoutStoreMutation } = api;
