import { useLocalStorage } from '@partstech/ui/hooks';
import { useCallback, useEffect, useRef } from 'react';
import { LocalStorageKeys } from 'constant';

type Props = {
  refetchCart: () => void;
};

/** @info Makes sure that the current cart has last changes made in another browser tab */

export const useCartSync = ({ refetchCart }: Props) => {
  const [lastUpdatedTime, setLastUpdatedTime] = useLocalStorage<number>(LocalStorageKeys.CART_LAST_UPDATED_TIME);
  const localLastUpdatedTimeRef = useRef<number>(lastUpdatedTime || 0);

  const updateLastUpdatedTime = useCallback(() => {
    const time = Date.now();
    setLastUpdatedTime(time);
    localLastUpdatedTimeRef.current = time;
  }, [setLastUpdatedTime]);

  useEffect(() => {
    if (lastUpdatedTime && lastUpdatedTime > localLastUpdatedTimeRef.current) {
      refetchCart();
      localLastUpdatedTimeRef.current = lastUpdatedTime;
    }
  }, [lastUpdatedTime, refetchCart]);

  return {
    updateLastUpdatedTime,
  };
};
