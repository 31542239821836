import { DateTime } from 'luxon';
import { DeliverySource, TrackingStatus } from 'shared/api';
import { api as recentOrdersApi } from 'store/queries/currentUser/recentOrders/GetRecentOrders.generated';
import { api as generatedApi } from './SetOrderAsDelivered.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    SetOrderAsDelivered: {
      invalidatesTags: ['RecentOrderLine'],
      onQueryStarted: async (params, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            recentOrdersApi.util.updateQueryData('GetRecentOrders', {}, (draft) => {
              const order = draft.currentUser?.activeMember?.shop?.recentOrders?.edges?.find(
                (item) => item?.node?.id === params.input.id
              )?.node;

              if (order) {
                Object.assign(order, {
                  trackingStatus: TrackingStatus.Completed,
                  lastTrackingDate: DateTime.local(),
                  lines: order.lines?.map((line) => ({
                    ...line,
                    delivery: {
                      deliverySource: DeliverySource.MarkAsDelivered,
                    },
                  })),
                });
              }
            })
          );
        } catch {
          /* empty */
        }
      },
    },
  },
});

export const { useSetOrderAsDeliveredMutation } = api;
