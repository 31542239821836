/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import type * as Types from '../../../shared/api/graphql/graphql';

export type MemberPermissionsFragment = {
  __typename?: 'MemberPermissions';
  allOrders: Types.Scalars['Boolean']['input'];
  boschRewards: Types.Scalars['Boolean']['input'];
  brandPreferences: Types.Scalars['Boolean']['input'];
  partsReport: Types.Scalars['Boolean']['input'];
  payments: Types.Scalars['Boolean']['input'];
  reportsDashboardAccess: Types.Scalars['Boolean']['input'];
  retailPricing: Types.Scalars['Boolean']['input'];
  shipping: Types.Scalars['Boolean']['input'];
  shopProfile: Types.Scalars['Boolean']['input'];
  spendReport: Types.Scalars['Boolean']['input'];
  subscription: Types.Scalars['Boolean']['input'];
  suppliers: Types.Scalars['Boolean']['input'];
  tireReport: Types.Scalars['Boolean']['input'];
  viewWholesaleCosts: Types.Scalars['Boolean']['input'];
};

export const MemberPermissionsFragmentDoc = `
    fragment MemberPermissions on MemberPermissions {
  allOrders
  boschRewards
  brandPreferences
  partsReport
  payments
  reportsDashboardAccess
  retailPricing
  shipping
  shopProfile
  spendReport
  subscription
  suppliers
  tireReport
  viewWholesaleCosts
}
    `;
