import { Card, Typography } from '@partstech/ui';
import { useOpen } from '@partstech/ui/hooks';
import { memo } from 'react';

export const BestPriceMessage = memo(() => {
  const { isOpen: isMessageOpen, close: closeMessage } = useOpen(true);

  if (!isMessageOpen) {
    return null;
  }

  return (
    <Card
      background="mono6"
      p={4}
      pr={9}
      display="flex"
      actions={[{ title: 'close', icon: 'close', onClick: closeMessage }]}
    >
      <Typography variant="bodyText2">
        You are already receiving the best price for the bundle in your cart so you are not eligible to receive an
        additional discount.
      </Typography>
    </Card>
  );
});
