import type { AttributeInput, SearchInput } from 'shared/api';

export type ToRequestParams = {
  accountId: string;
  attributes?: AttributeInput[] | undefined;
  universal: boolean;
  vehicleId?: string | undefined;
  vin?: string | undefined;
  jobId?: string | undefined;
};

export interface SearchRequestConvertable {
  toRequest(params: ToRequestParams): SearchInput;
}

export const isSearchRequestConvertable = (model: object): model is SearchRequestConvertable => 'toRequest' in model;
