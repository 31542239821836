import { Box, Typography } from '@partstech/ui';
import { StepTitle } from 'features/signUp';
import { PhoneNumberForm } from './PhoneNumberForm';
import { usePhoneNumber } from './usePhoneNumber';

export const PhoneNumber = () => {
  const { shopPhoneNumber, navigateToNextStep } = usePhoneNumber();

  return (
    <Box p={3} textAlign={{ sm: 'center', md: 'left' }} mt={{ sm: 4 }} width={{ md: 147 }}>
      <Typography variant="subtitle2" color="subtleText">
        One last question ...
      </Typography>

      <StepTitle my={4} px={{ sm: 1 }}>
        Do you have an alternate phone number you'd prefer PartsTech contact you at?
      </StepTitle>

      <Typography variant="bodyText2" color="subtleText">
        We recognize that your front desk can get busy. Provide a secondary number and we'll call that one when you need
        our assistance instead of the shop phone.
      </Typography>

      <Box mt={5}>
        <PhoneNumberForm shopPhoneNumber={shopPhoneNumber} onSubmit={navigateToNextStep} />
      </Box>
    </Box>
  );
};
