import { Box, Card, Icon, Tooltip, Typography, useMedia } from '@partstech/ui';
import { forwardRef } from 'react';
import type { SelectVehicleButtonProps } from 'features/vehicleSelector';

export const SelectVehicleButton = forwardRef<HTMLButtonElement, SelectVehicleButtonProps & { disabled?: boolean }>(
  ({ onClick, isOpen, disabled }, _) => {
    const { isMobile } = useMedia();

    return (
      <Tooltip
        fullWidth
        tooltip="You cannot select a vehicle because this quote has been ordered or archived."
        isHidden={!disabled}
      >
        <Card
          px={{ sm: 2, md: 3 }}
          py={{ sm: 2, md: 4 }}
          width={{ md: 62, lg: 84 }}
          onClick={disabled ? undefined : onClick}
          className="selectVehicleButton"
          data-testid="selectVehicleButton"
        >
          <Box display="flex" alignItems="center" width="100%">
            <Icon
              name="directions_car"
              color={disabled ? 'mono40' : 'subtleText'}
              size={isMobile ? 'medium' : 'default'}
            />

            <Box ml={2} width="100%" display="flex" justifyContent="space-between">
              <Typography variant={isMobile ? 'bodyText2' : 'bodyText1'} color={disabled ? 'mono40' : 'defaultText'}>
                Select Vehicle
              </Typography>

              {!isMobile && !disabled && <Icon name={isOpen ? 'expand_less' : 'expand_more'} />}

              {isMobile && !disabled && (
                <Typography variant="bodyText2" color="linkText">
                  Select
                </Typography>
              )}
            </Box>
          </Box>
        </Card>
      </Tooltip>
    );
  }
);
