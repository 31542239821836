import { useCallback, useEffect } from 'react';

const channelName = 'reload_channel';

export type BroadcastChannelListenerEvents = 'logout';

type Args = {
  onLogoutEvent: () => void;
  skipHandlerRegistration?: boolean;
};

/**
 * Hook to listen for events on a BroadcastChannel.
 * It listens for 'logout' events and triggers the provided callback when received.
 * @param {object} args - Arguments object.
 * @param {() => void} args.onLogoutEvent - Callback function to be executed when a 'logout' event is received.
 * @param {boolean | undefined} args.skipHandlerRegistration - Skip initialization Broadcast Channel Listener.
 * @returns {(event: BroadcastChannelListenerEvents) => void} Function to send events through the BroadcastChannel.
 */
export const useBroadcastChannelListener = ({ onLogoutEvent, skipHandlerRegistration }: Args) => {
  const sendEvent = useCallback((event: BroadcastChannelListenerEvents) => {
    const channel = new BroadcastChannel(channelName);

    channel.postMessage(event);
  }, []);

  useEffect(() => {
    if (skipHandlerRegistration) {
      return () => {};
    }

    const handleReloadMessage = (event: MessageEvent<BroadcastChannelListenerEvents>) => {
      if (event.data === 'logout') {
        onLogoutEvent();
      }
    };

    const channel = new BroadcastChannel(channelName);

    channel.addEventListener('message', handleReloadMessage);

    return () => {
      channel.removeEventListener('message', handleReloadMessage);
    };
  }, [skipHandlerRegistration, onLogoutEvent]);

  return sendEvent;
};
