import { isEmpty } from '@partstech/ui/utils';
import type { AdditionalInformation, Address, ConfigurationField, DisplayAction, ServeLocation } from 'shared/api';

type Outage = {
  type: 'NO_OUTAGE' | 'PARTIAL_OUTAGE' | 'FULL_OUTAGE';
  message?: string | null;
};

type SupplierData = {
  allowCustomNotes: boolean;
  allowCustomPurchaseOrderNumbers: boolean;
  allowStockOrders: boolean;
  allowStoreSelect: boolean;
  autoValidation?: boolean;
  checkoutMessage?: string | null;
  comingSoon: boolean;
  connectionTime?: 'NA' | 'LESS_ONE_MIN' | 'TODAY' | 'NEXT_DAY' | '1_PLUS_DAYS';
  coreChargePolicy?: string | null;
  coreReturns: boolean;
  displayAction?: DisplayAction;
  hideLocation: boolean;
  id: string;
  isTire: boolean;
  isTireConnect: boolean;
  lockedStore: boolean;
  logo: string;
  marketingDescription?: string | null;
  name: string;
  newSupplierBadge?: boolean;
  orderingSupported?: boolean;
  paid: boolean;
  registrationInstruction?: string | null;
  returnPolicy?: string | null;
  type: 'TIRES' | 'DEALER' | 'AFTERMARKET';
  tutorialUrl?: string | null;
  slug: string;
  additionalInformationConfiguration?: {
    title: string;
    description: string;
    fields: ConfigurationField[];
  } | null;
  credentialsConfiguration: ConfigurationField[];
  serveLocations?: ServeLocation[];
};

export class Supplier {
  additionalInformation: AdditionalInformation | null = null;

  allowCustomNotes: boolean;

  allowCustomPurchaseOrderNumbers: boolean;

  allowStockOrders: boolean;

  allowStoreSelect: boolean;

  autoValidation: boolean = false;

  checkoutMessage: string | null;

  comingSoon: boolean;

  connectionTime: 'NA' | 'LESS_ONE_MIN' | 'TODAY' | 'NEXT_DAY' | '1_PLUS_DAYS';

  coreChargePolicy: string | null;

  coreReturns: boolean;

  credentialFields: ConfigurationField[] | null;

  displayAction: DisplayAction | null = null;

  isLocationHidden: boolean;

  id: string;

  isTire: boolean;

  isTireConnect: boolean;

  isLockedStore: boolean;

  logo: string | null;

  marketingDescription: string | null;

  name: string;

  newSupplierBadge: boolean = false;

  orderingSupported: boolean | null;

  outage: Outage | null = null;

  paid: boolean;

  registrationInstruction: string | null;

  returnPolicy: string | null;

  serveLocations: ServeLocation[] | null;

  slug: string;

  tutorialUrl: string | null;

  type: 'TIRES' | 'DEALER' | 'AFTERMARKET';

  constructor(supplier: SupplierData) {
    this.allowCustomNotes = supplier.allowCustomNotes;
    this.allowCustomPurchaseOrderNumbers = supplier.allowCustomPurchaseOrderNumbers;
    this.allowStockOrders = supplier.allowStockOrders;
    this.allowStoreSelect = supplier.allowStoreSelect;
    this.autoValidation = supplier.autoValidation ?? false;
    this.checkoutMessage = supplier.checkoutMessage ?? null;
    this.comingSoon = supplier.comingSoon;
    this.connectionTime = supplier.connectionTime ?? 'NA';
    this.coreChargePolicy = supplier.coreChargePolicy ?? null;
    this.coreReturns = supplier.coreReturns;
    this.displayAction = supplier.displayAction ?? null;
    this.isLocationHidden = supplier.hideLocation;
    this.id = supplier.id;
    this.isTire = supplier.isTire;
    this.isTireConnect = supplier.isTireConnect;
    this.isLockedStore = supplier.lockedStore;
    this.logo = supplier.logo;
    this.marketingDescription = supplier.marketingDescription ?? null;
    this.name = supplier.name;
    this.newSupplierBadge = supplier.newSupplierBadge ?? false;
    this.orderingSupported = supplier.orderingSupported ?? null;
    this.paid = supplier.paid;
    this.registrationInstruction = supplier.registrationInstruction ?? null;
    this.returnPolicy = supplier.returnPolicy ?? null;
    this.type = supplier.type;
    this.tutorialUrl = supplier.tutorialUrl ?? null;
    this.slug = supplier.slug;
    this.additionalInformation = supplier.additionalInformationConfiguration ?? null;
    this.credentialFields = supplier.credentialsConfiguration;
    this.serveLocations = supplier.serveLocations ?? null;
  }

  get canSelectPartStore(): boolean {
    return !this.isTire && this.allowStoreSelect;
  }

  get hasCredentials(): boolean {
    return !isEmpty(this.credentialFields);
  }

  get isAfterMarket(): boolean {
    return !this.isTire && this.type === 'AFTERMARKET';
  }

  get isStockOrdersAllowed(): boolean {
    return this.allowStockOrders;
  }

  get isDealer(): boolean {
    return this.type === 'DEALER';
  }

  get isFullOutage(): boolean {
    return this.outage?.type === 'FULL_OUTAGE';
  }

  get isNoOutage(): boolean {
    // TODO: remove this.outage?.type === 'NO_OUTAGE' after removing use restAPI
    return this.outage?.type === 'NO_OUTAGE' || !this.outage;
  }

  get isPaid(): boolean {
    return this.paid;
  }

  get isPartialOutage(): boolean {
    return this.outage?.type === 'PARTIAL_OUTAGE';
  }

  get isRequiredCredentials(): boolean {
    const requiredCredentials = this.credentialFields?.filter((field) => field.required) ?? [];

    return this.hasCredentials && requiredCredentials.length > 0;
  }

  get isTireDisconnected(): boolean {
    return !this.isTireConnect;
  }

  get isTireWithoutCredentials(): boolean {
    return this.isTire && !this.isRequiredCredentials;
  }

  get shouldDistributeQuantities(): boolean {
    return this.isTire && !this.isTireDisconnected && this?.allowStoreSelect;
  }

  isServesLocation({ country, state }: { country?: Address['country']; state?: Address['state'] } = {}) {
    if (!country || !state) {
      return false;
    }

    return Boolean(
      this.serveLocations?.find((location) => location.country === country && location.regions.includes(state))
    );
  }

  setOutage(outagePayload: Outage) {
    this.outage = outagePayload;
  }

  sliceName(maxLength?: number): string {
    const { name } = this;

    if (maxLength && maxLength < name.length) {
      return `${name.slice(0, maxLength - 2).trim()}...`;
    }

    return name;
  }

  getNameAlias() {
    return `${this.name
      .trim()
      .toLowerCase()
      .replace(/[^0-9a-z_]+/gi, '_')}_supplier`;
  }
}
