import type { FieldValues } from '@partstech/ui/forms';

/**
 * @info https://github.com/react-hook-form/react-hook-form/discussions/7354
 * Solution for named nested forms in RFC of RHF repo
 */
export type PathWithMeta<FormValues, Type> = string & { __formValues: FormValues; __type: Type };

export function of<FormValues, Key extends keyof FormValues>(key: Key) {
  return key as PathWithMeta<FormValues, FormValues[Key]>;
}

export function join<FormValues extends FieldValues, Type, Key extends keyof Type>(
  path: PathWithMeta<FormValues, Type>,
  key: Key
) {
  return `${path}.${String(key)}` as PathWithMeta<FormValues, Type[Key]>;
}
