import { useMemo } from 'react';
import { createMemberPermissionsFromData } from 'factories/createMemberPermissionsFromData';
import { createShopPermissionsFromData } from 'factories/createShopPermissionsFromData';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import type { ShopPermissions, MemberPermissions } from 'models';

type UsePermissionsReturn = Omit<ReturnType<typeof useGetCurrentUser>, 'activeMember' | 'shop'> & {
  shop: ShopPermissions;
  member: MemberPermissions;
};

export const usePermissions = (): UsePermissionsReturn => {
  const { activeMember, shop, isSuccess, ...props } = useGetCurrentUser();

  return useMemo(
    () => ({
      member: createMemberPermissionsFromData(activeMember?.permissions),
      shop: createShopPermissionsFromData(shop?.permissions),
      isSuccess,
      ...props,
    }),
    [activeMember?.permissions, shop?.permissions, isSuccess, props]
  );
};
