import { Typography, Button, Box, ButtonGroup } from '@partstech/ui';
import { HookForm, FormCodeField, FormHiddenField } from '@partstech/ui/forms';
import { useConfirmPasswordForm } from './useConfirmPasswordForm';

type Props = {
  newPassword: string;
  onSubmit: () => void;
  onCancel?: () => void;
};

export const ConfirmPasswordForm = ({ newPassword, onSubmit, onCancel }: Props) => {
  const { form, isDisabled, isLoading, submitForm } = useConfirmPasswordForm({ newPassword, onSubmit });

  return (
    <HookForm form={form} onSubmit={submitForm} data-testid="confirmCodeForm">
      <Typography>
        An email was just sent to your email address to confirm your account. Please enter the five-digit code from that
        email below.
      </Typography>

      <Box mt={2}>
        <Typography variant="bodyText2" color="subtleText">
          This code will expire in 20 minutes.
        </Typography>
      </Box>

      <FormHiddenField name="newPassword" defaultValue={newPassword} />

      <Box mt={{ sm: 2, md: 10 }} mb={{ sm: 6, md: 15 }} display="flex" justifyContent="center">
        <FormCodeField name="confirmationCode" />
      </Box>

      <ButtonGroup flexDirection="column" gap={4}>
        <Button type="submit" fullWidth isLoading={isLoading} disabled={isDisabled} data-testid="submit">
          Submit
        </Button>

        {onCancel && (
          <Button variant="text" fullWidth onClick={onCancel}>
            Cancel
          </Button>
        )}
      </ButtonGroup>
    </HookForm>
  );
};
