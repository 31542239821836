import { Box } from '@partstech/ui';
import { useKeyboardSelection } from '@partstech/ui/hooks';
import { useCallback } from 'react';
import { ListButton } from 'components/ListButton';
import { useDiagramsCategories } from 'hooks/diagrams';
import { splitIntoColumns } from 'utils';

type Props = {
  vehicleId: string | null;
  onCategoryClick: (categoryId: string) => void;
};

export const DiagramsCategoriesList = ({ vehicleId, onCategoryClick }: Props) => {
  const { categories } = useDiagramsCategories({ vehicleId });

  const handleDiagramEnterKeyDown = useCallback(
    (index: number) => {
      const diagram = categories[index];

      if (diagram) {
        onCategoryClick(diagram.id);
      }
    },
    [onCategoryClick, categories]
  );

  const handleDiagramClick = useCallback(
    (categoryId: string) => () => {
      onCategoryClick(categoryId);
    },
    [onCategoryClick]
  );

  const selectedIndex = useKeyboardSelection(categories.length, handleDiagramEnterKeyDown, -1);

  let itemIndex = -1;

  return (
    <Box display={{ md: 'flex' }} width={{ md: '100%' }}>
      {splitIntoColumns(categories, 11).map((column, index) => (
        <Box key={index} width={{ sm: '100%', md: 74 }}>
          {column.map((category) => {
            const isSelected = selectedIndex === ++itemIndex;

            return (
              <Box display="flex" flexDirection="column" key={category.id}>
                <ListButton
                  text={category.name}
                  isSelected={isSelected}
                  onSelect={handleDiagramClick(category.id)}
                  data-testid="searchFormListItem"
                />
              </Box>
            );
          })}
        </Box>
      ))}
    </Box>
  );
};
