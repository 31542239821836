import { useMemo } from 'react';
import { useSearchDiagrams } from 'store/queries/diagrams/useSearchDiagrams';
import { formatDiagrams } from 'utils';

type Props = {
  vehicleId: string | null;
  categoryId: string | null;
  diagramId?: string | null;
};

export const useCategoryDiagrams = ({ vehicleId, categoryId, diagramId }: Props) => {
  const { diagrams } = useSearchDiagrams({ vehicleId });

  const diagramsByCategory = useMemo(() => {
    if (!categoryId) {
      return [];
    }

    return formatDiagrams(diagrams).filter((diagram) => diagram.category?.id === categoryId);
  }, [categoryId, diagrams]);

  const activeDiagram = useMemo(() => diagrams.find((diagram) => diagram.id === diagramId), [diagramId, diagrams]);

  return {
    diagrams: diagramsByCategory,
    activeDiagram,
  };
};
