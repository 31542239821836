import { Box, Icon, TableCol, TableRow, Typography, useModal } from '@partstech/ui';
import { useFeatureHighlight } from '../../../../../../hooks/useFeatureHighlight';
import { FeatureName } from '../../../../DesktopView/FeatureRow/FeatureName';
import { styles } from './FeatureTableRow.styles';
import type { FeatureEX, PlanFeature } from '../../../../../../types';

type Props = {
  feature: PlanFeature | FeatureEX;
  isHighlighted?: boolean;
  title?: string;
};

export const FeatureTableRow = ({ feature, isHighlighted = false, title }: Props) => {
  const { onClose } = useModal();

  const { containerRef, isHighlightActive } = useFeatureHighlight(isHighlighted);

  return (
    <TableRow css={styles.tableRow(isHighlightActive)}>
      <TableCol>
        <div ref={containerRef} />

        {title && (
          <Box mt={2} mb={4}>
            <Typography variant="subtitle2">{title}</Typography>
          </Box>
        )}

        <FeatureName
          name={feature.name}
          link={feature?.link}
          variant="bodyText2"
          onLinkClick={feature.link?.isInternal ? onClose : undefined}
        />

        {'description' in feature && (
          <Box mt={2}>
            <Typography color="subtleText" variant="caption">
              {feature.description}
            </Typography>
          </Box>
        )}
      </TableCol>

      <TableCol>
        {feature.partialEnabled ? (
          <Typography variant="caption">{feature.partialEnabled}</Typography>
        ) : (
          <Icon
            name={feature.isEnabled ? 'check_circle' : 'cancel'}
            color={feature.isEnabled ? 'positive' : 'negative'}
          />
        )}
      </TableCol>
    </TableRow>
  );
};
