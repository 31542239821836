import { memo, useEffect, useRef } from 'react';
import type { SVGProps } from 'react';

const duration = 0.3;

type Props = SVGProps<SVGPathElement> & {
  nextD?: string;
};

export const AnimatedPath = memo(({ d, nextD, ...props }: Props) => {
  const pathRef = useRef<SVGPathElement | null>(null);
  const animateRef = useRef<SVGAnimateElement | null>(null);

  useEffect(() => {
    animateRef.current?.beginElement?.();
  }, [d, nextD]);

  return (
    <path ref={pathRef} d={d} {...props}>
      {nextD && (
        <animate
          ref={animateRef}
          attributeName="d"
          attributeType="XML"
          from={d}
          to={nextD}
          dur={duration}
          fill="freeze"
          begin="0s"
        />
      )}
    </path>
  );
});
