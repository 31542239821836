import { useModalHistory, useSnackbar } from '@partstech/ui';
import { useCallback } from 'react';
import type { PaymentCardModalData, PaymentCardModalPaths } from '../router';

export const usePaymentUpdated = () => {
  const { addSnackbar } = useSnackbar();

  const { data, reset, onSubmit, onClose, state } = useModalHistory<
    PaymentCardModalPaths,
    'paymentCardUpdated',
    PaymentCardModalData
  >();

  const submitForm = useCallback(() => {
    if (!state?.cardForRemoving) {
      onSubmit(data);

      onClose();
      return;
    }

    addSnackbar({ label: 'Subscription payment method updated' });

    reset('deleteCard', {
      ...state,
      stripeKey: state?.stripeKey ?? '',
      card: state.cardForRemoving,
      children: 'Do you want to delete the previous credit card?',
    });
  }, [state, addSnackbar, reset, onSubmit, data, onClose]);

  return { card: data.card, submitForm };
};
