import { Box, ModalView } from '@partstech/ui';
import { VehicleDiagrams } from 'components/VehicleDiagrams';

type Props = {
  onClose: () => void;
  onSelectVehicleClick?: () => void;
  defaultCategoryId: string | null;
  vehicleId: string | null;
};

export const DiagramsListModal = ({ onClose, onSelectVehicleClick, defaultCategoryId, vehicleId }: Props) => (
  <ModalView title="Vehicle diagrams" onClose={onClose} data-testid="diagramsListModal">
    <Box px={4}>
      <VehicleDiagrams
        defaultCategoryId={defaultCategoryId}
        vehicleId={vehicleId}
        onClose={onClose}
        onSelectVehicleClick={onSelectVehicleClick}
      />
    </Box>
  </ModalView>
);
