import { useEffect } from 'react';
import type { RefObject } from 'react';

export const useElementEventListener = <K extends keyof HTMLElementEventMap>(
  type: K,
  listener: (this: HTMLDivElement, ev: HTMLElementEventMap[K]) => unknown,
  elementRef: RefObject<HTMLDivElement>
) => {
  useEffect(() => {
    const element = elementRef.current;

    element?.addEventListener(type, listener);

    return () => {
      element?.removeEventListener(type, listener);
    };
  }, [elementRef, listener, type]);
};
