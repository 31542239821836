import { Box, Icon, GraphicTab, Typography, useMedia } from '@partstech/ui';
import classNames from 'classnames';
import { useCallback, useMemo } from 'react';
import { useSupplierType } from 'components/SupplierSelector/SupplierTypeContext';
import { useHasCatalog, useSearchParams } from 'hooks/search';
import { useDealerSupplierAccounts, useSupplierAccountsProductsCount } from 'hooks/supplierAccounts';
import afterMarketImg from 'images/icons/search-tabs/afterMarket.svg';
import dealersImg from 'images/icons/search-tabs/dealers.svg';
import { styles } from './SupplierTypes.styles';
import type { SupplierTypes } from 'components/SupplierSelector/SupplierTypeContext';

type SupplierTypesTab = Record<
  string,
  { value: SupplierTypes; label: string; total: string; id: string; image: { src: string; alt: string } }
>;

export const SupplierType = () => {
  const { isMobile } = useMedia();
  const { isCatalogSelected } = useSearchParams();

  const { activeSupplierType, setActiveSupplierType } = useSupplierType();

  const hasCatalog = useHasCatalog();

  const { dealerAccounts } = useDealerSupplierAccounts();

  const { dealersTotal, afterMarketTotal } = useSupplierAccountsProductsCount();

  const supplierTypes = useMemo(() => {
    const types: SupplierTypesTab = {
      AFTER_MARKET: {
        value: 'afterMarket',
        label: 'Aftermarket',
        total: afterMarketTotal,
        id: 'aftermarket_tab',
        image: { src: afterMarketImg, alt: 'afterMarket' },
      },
    };

    if (dealerAccounts.length > 0) {
      types.DEALERS = {
        value: 'dealers',
        label: 'Dealers',
        total: dealersTotal,
        id: 'dealer_tab',
        image: { src: dealersImg, alt: 'dealers' },
      };
    }

    return types;
  }, [afterMarketTotal, dealerAccounts.length, dealersTotal]);

  const handleChangeSupplierType = useCallback(
    (type: SupplierTypes) => () => {
      setActiveSupplierType(type);
    },
    [setActiveSupplierType]
  );

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" px={{ md: 4 }} data-testid="supplierType">
      <Box display="flex">
        {Object.values(supplierTypes).map((supplierType) => (
          <GraphicTab
            variant="feature"
            size={isMobile ? 'small' : 'medium'}
            key={supplierType.id}
            id={supplierType.value}
            image={supplierType.image}
            isActive={supplierType.value === activeSupplierType}
            onClick={handleChangeSupplierType(supplierType.value)}
            className={classNames(supplierType.id, { active: supplierType.value === activeSupplierType })}
            data-testid="supplierTypeItem"
            css={styles.supplierTypeItem}
          >
            <Typography variant={isMobile ? 'caption' : 'bodyText2'} component="span">
              {supplierType.label} {supplierType.total}
            </Typography>
          </GraphicTab>
        ))}
      </Box>

      {hasCatalog && (
        <button
          css={styles.catalog(isCatalogSelected)}
          onClick={handleChangeSupplierType('catalog')}
          type="button"
          data-testid="supplierTypeItem"
        >
          <Box mr={{ md: 1 }}>
            <Icon name="menu_book" size="medium" color={isCatalogSelected ? 'primary' : 'subtleText'} />
          </Box>
          {!isMobile && (
            <Typography variant="caption" color={isCatalogSelected ? 'primary' : 'subtleText'} data-testid="catalogTab">
              PartsTech Catalog
            </Typography>
          )}
        </button>
      )}
    </Box>
  );
};
