import { css } from '@emotion/react';
import { Box, Icon } from '@partstech/ui';
import { memo } from 'react';
import type { Theme } from '@partstech/ui';

const styles = {
  cart: (theme: Theme) => css`
    border: ${theme.sizing(1)} solid ${theme.color.negative};
    border-radius: 50%;
  `,
  warningSign: () => css`
    border-radius: 50%;
  `,
  icon: (theme: Theme) => css`
    font-size: ${theme.sizing(9)};
  `,
};

export const CartWarningIcon = memo(() => (
  <Box position="relative" width={25} m="auto">
    <Box
      position="relative"
      display="flex"
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      textAlign="center"
      width={26}
      height={26}
      css={styles.cart}
    >
      <Icon name="shopping_cart" css={styles.icon} />
    </Box>

    <Box
      display="flex"
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      width={12}
      height={12}
      top={-3}
      right={-3}
      background="negative"
      position="absolute"
      css={styles.warningSign}
    >
      <Icon name="error" color="white" />
    </Box>
  </Box>
));
