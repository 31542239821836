import { makeContext } from '@partstech/ui/utils';
import type { SendLaunchDarklyEvent } from '../../lib/useSendLaunchDarklyEvent';
import type { LDFlagSet } from 'types/launchdarkly';

type LaunchDarklyContext = {
  flags: LDFlagSet;
  sendEvent: SendLaunchDarklyEvent;
  isLoaded: boolean;
};

export const [useLaunchDarkly, LaunchDarklyContextProvider] = makeContext<LaunchDarklyContext>();
