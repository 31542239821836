import { api as generatedApi } from './CreateRecentSearch.generated';
import type { CreateRecentSearchMutation } from './CreateRecentSearch.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    CreateRecentSearch: {
      invalidatesTags: (response: CreateRecentSearchMutation | undefined) => {
        const hasVehicleId = Boolean(response?.createRecentSearch?.recentSearch?.params.vehicle?.id);

        if (hasVehicleId) {
          return ['RecentSearch', 'RecentVehicle'];
        }

        return ['RecentSearch'];
      },
    },
  },
});

export const { useCreateRecentSearchMutation } = api;
