import { api as rootApi } from 'store/queries/currentUser/GetCurrentUser.generated';
import { api as generatedApi } from './UpdateAllowOrderEmails.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    UpdateAllowOrderEmails: {
      onQueryStarted: async ({ input }, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          rootApi.util.updateQueryData('GetCurrentUser', undefined, (draft) => {
            if (draft.currentUser) {
              Object.assign(draft.currentUser, { allowOrdersEmail: input.allowOrderEmails });
            }
          })
        );

        queryFulfilled.catch(patchResult.undo);
      },
    },
  },
});

export const { useUpdateAllowOrderEmailsMutation } = api;
