import { AvailabilityLine } from './AvailabilityLine';

export class EnhancementAvailabilityLine extends AvailabilityLine {
  getAdditionalTitle() {
    return this.displayName;
  }

  getMainAvailabilityTitle() {
    return `Home Store (${this.formattedMaxQuantity})`;
  }

  getNetworkAvailabilityTitle() {
    return `In Network (${this.formattedMaxQuantity})`;
  }
}
