/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import type * as Types from '../../../../../shared/api/graphql/graphql';

export type PercentageAmountFragment = {
  __typename?: 'PercentageAmount';
  max?: Types.Scalars['BigDecimal']['input'] | null;
  min?: Types.Scalars['BigDecimal']['input'] | null;
  isPerQuantity: Types.Scalars['Boolean']['input'];
};

export const PercentageAmountFragmentDoc = `
    fragment PercentageAmount on PercentageAmount {
  max
  min
  isPerQuantity: perQuantity
}
    `;
