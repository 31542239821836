import { css } from '@emotion/react';
import { Box, Icon } from '@partstech/ui';
import { FormTextField } from '@partstech/ui/forms';
import { useCallback, useMemo } from 'react';
import type { VehicleParameters } from '../../../types';
import type { VehicleField } from '../types';
import type { Theme } from '@partstech/ui';

type Props = {
  onFocus: (name: VehicleField) => void;
  onInputChange: (name: VehicleField) => (value: string) => void;
} & VehicleParameters;

type Field = {
  name: VehicleField;
  placeholder: string;
  disabled?: boolean;
};

const styles = {
  fieldWrapper: (disabled?: boolean) => (theme: Theme) => css`
    width: 100%;

    ${theme.breakpoints.down('md')} {
      width: 100%;
      ${disabled && `display: none;`}
    }
  `,
};

export const FormFields = ({ onFocus, onInputChange, year, makeId, modelId, submodelId }: Props) => {
  const handleFocus = useCallback(
    (fieldName: VehicleField) => () => {
      onFocus(fieldName);
    },
    [onFocus]
  );

  const fields = useMemo(
    (): Field[] => [
      {
        name: 'year',
        placeholder: 'Year',
      },
      { name: 'makeId', placeholder: 'Make', disabled: !year },
      { name: 'modelId', placeholder: 'Model', disabled: !makeId },
      { name: 'submodelId', placeholder: 'Submodel', disabled: !modelId },
      { name: 'engineId', placeholder: 'Engine', disabled: !submodelId },
    ],
    [makeId, modelId, submodelId, year]
  );

  return (
    <Box display="flex" flexDirection={{ sm: 'column', md: 'row' }} gap={2} mb={4}>
      {fields.map((field) => (
        <div css={styles.fieldWrapper(field.disabled)} key={field.name}>
          <FormTextField
            {...field}
            width="100%"
            onFocus={handleFocus(field.name)}
            onChange={onInputChange(field.name)}
            autoComplete="off"
            adornmentAfter={
              <button onClick={field.disabled ? undefined : handleFocus(field.name)} type="button">
                <Icon color="defaultText" name="expand_more" size="large" />
              </button>
            }
            adornmentAfterAnimation
          />
        </div>
      ))}
    </Box>
  );
};
