import { useCallback } from 'react';
import { api } from './SubmitCart.generated';
import type { CartOrderFragment } from '../../queries';
import type { CartOrder } from 'models';
import type { SubmitActiveCartMitchell1Payload, SubmittedLabor } from 'shared/api';

type BuyResponse = {
  error: string | null;
  redirectUrl?: string | null;
  orders?: CartOrderFragment[];
  mitchell1Payload?: SubmitActiveCartMitchell1Payload | null;
};

const { useSubmitCartMutation } = api;

export const useSumbitCart = () => {
  const [mutate, status] = useSubmitCartMutation();

  const sumbitCart = useCallback(
    async (orders: CartOrder[], laborItemIds: string[], submittedLabor: SubmittedLabor[]): Promise<BuyResponse> => {
      const res = await mutate({
        submitOrders: orders.map((order) => ({
          customNotes: order.notes,
          orderId: order.id,
          purchaseOrderNumber: order.poNumber || undefined,
          shippingMethodCode: order.shippingMethodCode,
          submitItems: order.items.map((item) => ({ itemId: item.id })),
        })),
        laborItemIds,
        submittedLabor,
      });

      if (!res.data?.submitActiveCart) {
        return {
          error: res.error?.message || 'Something went wrong',
        };
      }

      if ('errorMessage' in res.data.submitActiveCart) {
        return {
          error:
            res.data.submitActiveCart.errorMessage ||
            res.data.submitActiveCart.submitOrdersErrors?.[0]?.errorMessage ||
            'Something went wrong',
        };
      }

      if ('orders' in res.data.submitActiveCart) {
        return {
          error: null,
          redirectUrl: res.data.submitActiveCart.redirectUrl,
          orders: res.data.submitActiveCart.orders,
          mitchell1Payload: res.data.submitActiveCart.mitchell1Payload,
        };
      }

      return {
        error: 'Something went wrong',
      };
    },
    [mutate]
  );

  return [sumbitCart, status] as const;
};
