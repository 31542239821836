import { Typography } from '@partstech/ui';
import type { PaymentCard } from '../../model/PaymentCard';
import type { TypographyProps } from '@partstech/ui';

type Props = {
  card: PaymentCard;
  displayFullCardNumber?: boolean;
  showExpired?: boolean;
  useDotMask?: boolean;
} & TypographyProps;

export const CardName = ({ card, displayFullCardNumber, useDotMask, showExpired, ...props }: Props) => {
  if (displayFullCardNumber) {
    const maskChar = useDotMask ? '•' : '*';
    const maskedPart = maskChar.repeat(4);

    return (
      <Typography {...props}>
        {maskedPart} {maskedPart} {maskedPart} {card.name}
      </Typography>
    );
  }

  return (
    <Typography {...props}>
      Card {showExpired && card.isExpired ? 'has expired ' : 'ending in '}
      <Typography variant="subtitle1" {...props} component="span" data-testid="cardNumber">
        {card.name}
      </Typography>
    </Typography>
  );
};
